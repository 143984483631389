// Config
export const SET_CONFIG_CREDS = "SET_CONFIG_CREDS";
// Main
export const SET_APPLICATIONS = "SET_APPLICATIONS";
export const UPDATE_APPLICATIONS_APP = "UPDATE_APPLICATIONS_APP";
export const UPDATE_APPLICATIONS_THRESHOLD = "UPDATE_APPLICATIONS_THRESHOLD";
export const UPDATE_DPT = "UPDATE_DPT";
export const SET_ME = "SET_ME";
export const SET_USERS = "SET_USERS";
export const SET_SHOW_MENU = "SET_SHOW_MENU";
export const TOGGLE_ACCOUNTS_MENU = "TOGGLE_ACCOUNTS_MENU";
export const MAIN_SET_TENANTINFO = "MAIN_SET_TENANTINFO"; //to be deleted
export const SET_TENANT_LIST = "SET_TENANT_LIST";
export const SET_WIZARD_TYPES = "SET_WIZARD_TYPES";
export const SET_ACCESS_LEVELS = "SET_ACCESS_LEVELS";
export const SET_FAVICON = "SET_FAVICON";
export const SET_MASTER_RECOMMENDED_ACTIONS = "SET_MASTER_RECOMMENDED_ACTIONS";
export const SET_ANALYTICS_DASHBOARD_URL = "SET_ANALYTICS_DASHBOARD_URL";
export const SET_DPH = "SET_DPH";
export const START_DPH = "CLEAR_DPH";
export const ERROR_DPH = "ERROR_DPH";
export const SET_NOTIFICATION_FREQUENCIES = "SET_NOTIFICATION_FREQUENCIES";
// Project
export const SET_APPDATA = "SET_APPDATA";
export const SET_DPT = "SET_DPT";
export const SET_FEATURES = "SET_FEATURES";
export const SET_APIHUB_DETAIL = "SET_APIHUB_DETAIL";
export const SET_RESOURCE_META = "SET_RESOURCE_META";
export const SET_TENANTINFO = "SET_TENANTINFO";
export const CHANGE_DESCRIPTION = "CHANGE_DESCRIPTION";
export const SET_TENANTCREDS = "SET_TENANTCREDS";
export const SET_ENV_WEBSOCKET_CERTS = "SET_ENV_WEBSOCKET_CERTS";
export const SET_RESOURCETYPES = "SET_RESOURCETYPES";
export const CLEAR_PROJECT_DATA = "CLEAR_PROJECT_DATA";
export const SET_USAGE = "SET_USAGE";
export const SET_EXPLORER_DATA = "SET_EXPLORER_DATA";
export const SET_INVALID_DATA = "SET_INVALID_DATA";
export const SET_INVALID_COUNT = "SET_INVALID_COUNT";
export const SET_TRANSFORMERS = "SET_TRANSFORMERS";
export const SET_LAST_BULK_UPLOAD = "SET_LAST_BULK_UPLOAD";
export const SET_BULK_UPLOADS = "SET_BULK_UPLOADS";
export const SET_CUSTOM_ALLOWED_FIELDS = "SET_CUSTOM_ALLOWED_FIELDS";
export const SET_CUSTOM_METADATA = "SET_CUSTOM_METADATA";
export const CLEAR_BULK_UPLOADS = "CLEAR_BULK_UPLOADS";
export const SET_DATA_TABS_SELECTED = "SET_DATA_TABS_SELECTED";
export const SET_TRIGGER_WEBHOOKS = "SET_TRIGGER_WEBHOOKS";
export const SET_WEBHOOK_SUBSCRIPTIONS = "SET_WEBHOOK_SUBSCRIPTIONS";
export const SET_TRANS_ENVIRONMENTS = "SET_TRANS_ENVIRONMENTS";
export const SET_ENVIRONMENTS_OVERVIEW = "SET_ENVIRONMENTS_OVERVIEW";
export const SET_ENV_CREDS = "SET_ENV_CREDS";
export const SET_SELECTED_ENV = "SET_SELECTED_ENV";
export const SET_ADAPTER_SECRET = "SET_ADAPTER_SECRET";
export const SET_WORKSPACE_PERMISSIONS = "SET_WORKSPACE_PERMISSIONS";
export const SET_TENANT_WIZARD_TYPES = "SET_TENANT_WIZARD_TYPES";
export const SET_WORKSPACE_TRANSFORMER_LOGS = "SET_WORKSPACE_TRANSFORMER_LOGS";
export const SET_WORKSPACE_TRANSFORMER_LOGS_100 =
  "SET_WORKSPACE_TRANSFORMER_LOGS_100";
export const SET_WORKSPACE_TRANSFORMER_LOGS_COUNT =
  "SET_WORKSPACE_TRANSFORMER_LOGS_COUNT";
export const SET_RECOMMENDED_ACTIONS = "SET_RECOMMENDED_ACTIONS";
export const SET_ALL_DEPLOYMENTS = "SET_ALL_DEPLOYMENTS";
export const SET_DATA_MAPPINGS = "SET_DATA_MAPPINGS";
export const SET_MAPPER_COMMENTS = "SET_MAPPER_COMMENTS";
// ENV
export const SET_MONITORING_PREFERENCES = "SET_MONITORING_PREFERENCES";
export const CLEAR_MONITORING_PREFERENCES = "CLEAR_MONITORING_PREFERENCES";
export const SESSION_ENVIRONMENT_SELECTION = "SESSION_ENVIRONMENT_SELECTION";
// Transformer
export const SET_TRANSFORMER_VERSIONS = "SET_TRANSFORMER_VERSIONS";
export const CLEAR_TRANSFORMER_VERSIONS = "CLEAR_TRANSFORMER_VERSIONS";
export const SET_TRANSFORMER_EMAIL = "SET_TRANSFORMER_EMAIL";
export const ADD_NOTIFICATION_EMAIL = "ADD_NOTIFICATION_EMAIL";
export const DELETE_NOTIFICATION_EMAIL = "DELETE_NOTIFICATION_EMAIL";
export const SET_ENABLE_TERMINATE_JOB = "SET_ENABLE_TERMINATE_JOB";
export const SET_TRANSFORMER_CONTEXT = "SET_TRANSFORMER_CONTEXT";
export const SET_TRANSFORMER_SHOWSAVED = "SET_TRANSFORMER_SHOWSAVED";
export const SET_TRANSFORMER_LOGS_COUNT = "SET_TRANSFORMER_LOGS_COUNT";
export const SET_TRANSFORMER_LOGS = "SET_TRANSFORMER_LOGS";
export const SET_ALL_TRANSFORMER_LOGS = "SET_ALL_TRANSFORMER_LOGS";
export const SET_TRIGGER = "SET_TRIGGER";
export const SET_ALL_TRIGGERS = "SET_ALL_TRIGGERS";
export const CLEAR_TRANSFORMER = "CLEAR_TRANSFORMER";
export const SET_EXECUTION_LOGS = "SET_EXECUTION_LOGS";
export const SET_ONGOING_EXECUTION_LOGS = "SET_ONGOING_EXECUTION_LOGS";
export const SET_ONGOING_EXECUTION_LOG_COUNT =
  "SET_ONGOING_EXECUTION_LOG_COUNT";
export const SET_EXECUTION_COMPLETED = "SET_EXECUTION_COMPLETED";
export const SET_EXECUTION_ERROR = "SET_EXECUTION_ERROR";
export const SET_EXECUTION_ERROR_MESSAGE = "SET_EXECUTION_ERROR_MESSAGE";
export const SET_SELECTED_TRANSFORMER_LOGS = "SET_SELECTED_TRANSFORMER_LOGS";
export const SET_SELECTED_TRANSFORMER_LOGS_DEV =
  "SET_SELECTED_TRANSFORMER_LOGS_DEV";

export const SET_SELECTED_TRANSFORMER_LOGS_ERROR =
  "SET_SELECTED_TRANSFORMER_LOGS_ERROR";
export const SET_WIZARDS = "SET_WIZARDS";
export const SET_TRANS_ENVIRONMENT_NAME = "SET_TRANS_ENVIRONMENT_NAME";
export const SET_TRANSFORMER_DEPLOY_STATUS = "SET_TRANSFORMER_DEPLOY_STATUS";
export const SET_TRANSFORMER_DEPLOYMENTS = "SET_TRANSFORMER_DEPLOYMENTS";
export const SET_CONSOLE_OPEN_STATUS = "SET_CONSOLE_OPEN_STATUS";
export const SET_CONNECTEDEXP_DATA = "SET_CONNECTEDEXP_DATA";
export const SET_CONNECTEDEXP_COMMENTS = "SET_CONNECTEDEXP_COMMENTS";
export const SET_ALL_COMMENTS = "SET_ALL_COMMENTS";
export const SET_EXECUTING_TRANSFORMERS = "SET_EXECUTING_TRANSFORMERS";
export const SET_ONGOING_LOGS_BY_BASEID = "SET_ONGOING_LOGS_BY_BASEID";
export const SET_ONGOING_LOGCOUNT_BY_BASEID = "SET_ONGOING_LOGCOUNT_BY_BASEID";
export const SET_SHOW_APP_EXIT_PROMPT = "SET_SHOW_APP_EXIT_PROMPT";
export const SET_EXTENDED_PROPERTIES = "SET_EXTENDED_PROPERTIES";

// Datafeed
export const SET_FEEDLIST = "SET_FEEDLIST";
export const SET_OTHER_TENANT = "SET_OTHER_TENANT";
export const SET_OTHER_APP = "SET_OTHER_APP";
export const SET_INVITED_FEED = "SET_INVITED_FEED";
export const SET_DATAFEED_SHOWSAVED = "SET_DATAFEED_SHOWSAVED";
export const CLEAR_DATAFEEDS = "CLEAR_DATAFEEDS";
// Wizard (not inside actual wizard: just config stuff)
export const CLEAR_WIZARD = "CLEAR_WIZARD";
export const SET_WIZ_VERSIONS = "SET_WIZ_VERSIONS";
export const SET_WIZ_SAVED_CONFIGURATION = "SET_WIZ_SAVED_CONFIGURATION";
export const SET_WIZ_BUNDLES = "SET_WIZ_BUNDLES";
export const SET_WIZ_MAPPINGS = "SET_WIZ_MAPPINGS";
export const CLEAR_INTEGRATION = "CLEAR_INTEGRATION";
//Events
export const SET_WEBHOOK_TYPES = "SET_WEBHOOK_TYPES";
export const SET_WEBHOOK = "SET_WEBHOOK";
export const SET_WEBHOOK_SUBS = "SET_WEBHOOK_SUBS";
export const SET_WEBHOOK_TIMESERIES = "SET_WEBHOOK_TIMESERIES";
export const SET_WEBHOOKURL_SUBS = "SET_WEBHOOKURL_SUBS";
export const SET_TRIGGERS = "SET_TRIGGERS";
export const SET_SUMMARY_TRIGGERS = "SET_SUMMARY_TRIGGERS";
export const START_SUMMARY_TRIGGERS = "START_SUMMARY_TRIGGERS";
export const SET_DETAILS_TRIGGERS = "SET_DETAILS_TRIGGERS";
export const START_DETAILS_TRIGGERS = "START_DETAILS_TRIGGERS";
export const SET_ACTIVE_TRIGGERS = "SET_ACTIVE_TRIGGERS";
export const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER";
