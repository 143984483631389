import React, { Component } from 'react'
import { Table, Button, Modal } from 'react-bootstrap'

import moment from 'moment'
import CommentBar from './commentbar'
import images from '../images'
import alltypes from './alltypes'
import HistoryBox from '../utils/historyBox'

const { Body, Title, Header, Footer } = Modal

function getColorByBgColor(bgColor) {
  if (!bgColor) {
    return ''
  }
  return parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2 ? '#000' : '#fff'
}

export default class DetailsWrap extends Component {
  render() {
    const { expId } = this.props.match.params
    const { connectedExperienceData } = this.props.redux.project
    const data =
      connectedExperienceData &&
      connectedExperienceData.find(r => r.id === parseInt(expId))

    if (data) {
      return <Details {...this.props} />
    } else {
      return (
        <div style={{ margin: 80 }}>
          <img alt="spinner" src={images.ajax_loader} />
        </div>
      )
    }
  }
}

class Details extends Component {
  constructor() {
    super()
    this.state = {
      selecteditem: null,
      uploadedimage: null,
      warndelete: false,
      data: null,
      isRecipe: true,
      modal: false,
      selectedReport: null
    }

    this.sendToRecipe = this.sendToRecipe.bind(this)
    this.sendToWiz = this.sendToWiz.bind(this)
    this.sendToEdit = this.sendToEdit.bind(this)
    this.showModal = this.showModal.bind(this)
    this.selectReport = this.selectReport.bind(this)
  }

  componentWillMount() {
    const { expId, appId } = this.props.match.params
    const { actions } = this.props
    const { project } = this.props.redux
    const { connectedExperienceData, environments } = project
    actions.callGetExpComments(project.tenantInfo.TenantId, appId, expId)

    const data = connectedExperienceData.find(r => r.id === parseInt(expId))

    const allrecipes = project && project.transformers
    const allwizards = project && project.wizards

    const brokenrecipes = []

    data.recipeBaseIds.map(r => {
      const exists =
        allrecipes && allrecipes.find(a => parseInt(a.BaseId) === r)
      if (!exists) brokenrecipes.push(r)
    })

    if (brokenrecipes.length > 0) {
      brokenrecipes.map(r => {
        const marked = data.recipeBaseIds.findIndex(f => f === r)
        data.recipeBaseIds.splice(marked, 1)
      })
    }

    const brokenwizards = []

    data.wizardGuids.map(r => {
      const exists =
        allwizards && allwizards.find(a => a.ProductizedIntegrationGuid === r)
      if (!exists) brokenwizards.push(r)
    })

    if (brokenwizards.length > 0) {
      brokenwizards.map(r => {
        const marked = data.wizardGuids.findIndex(f => f === r)
        data.wizardGuids.splice(marked, 1)
      })
    }

    const brokenreports = []

    data.reports.map(r => {
      const exists =
        environments && environments.find(e => e.id === r.environmentId)
      if (!exists) brokenreports.push(r)
    })

    if (brokenreports.length > 0) {
      brokenreports.map(r => {
        const marked = data.reports.findIndex(
          f => f.environmentId === r.environmentId
        )
        data.reports.splice(marked, 1)
      })
    }

    this.setState({
      data: data,
      selectedReport: 0
    })
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.clearExpComments()
  }

  sendToRecipe(a, e) {
    this.setState({
      selecteditem: e,
      isRecipe: true
    })
    this.showModal()
  }

  selectReport(a, i) {
    this.setState({ selectedReport: i })
  }

  sendToWiz(a, e) {
    this.setState({
      selecteditem: e,
      isRecipe: false
    })
    this.showModal()
  }

  sendAway(q, id) {
    const { appId } = this.props.match.params

    if (this.state.isRecipe) {
      this.props.history.push(`/a/${appId}/tf/${id}`)
    } else {
      this.props.history.push(`/a/${appId}/wiz/${id}`)
    }
  }

  showModal() {
    let modal = this.state.modal
    modal = !modal
    this.setState({ modal })
  }

  sendToEdit() {
    const { appId, expId } = this.props.match.params
    this.props.history.push(`/a/${appId}/ca/${expId}/edit`)
  }

  render() {
    const styles = {
      icon: {
        float: 'left',
        width: 26,
        height: 26,
        display: 'block',
        marginRight: '4px',
        marginTop: -4
      },
      detailsrow: {},
      warningrow: {},
      detailsrowselected: {
        background: '#F0F8FF'
      },
      modalicon: {
        float: 'left',
        height: 40,
        display: 'block',
        marginRight: 4,
        marginTop: -7
      }
    }
    const { selecteditem, data, isRecipe } = this.state
    const { redux, actions } = this.props
    const { project } = redux
    const { allDeployments, transformerLogs } = project && project
    const allrecipes = project && project.transformers && [...project.transformers]
    const allwizards = project && project.wizards && [...project.wizards]
    const allenvironments = project && project.environments

    const selectedreport = data && data.reports[this.state.selectedReport]
    const envdetails =
      selectedreport &&
      allenvironments &&
      allenvironments.find(
        f => f.environmentId === selectedreport.environmentId
      )

    const lastModified = data && data.lastModified
    const lastModifiedBy = data && data.lastModifiedBy

    if (!(project && project.connectedExperienceData)) return <div />

    const selectedrecipe =
      selecteditem &&
      isRecipe &&
      allrecipes.find(f => f.BaseId === selecteditem)
    const selectedwizard =
      selecteditem &&
      !isRecipe &&
      allwizards.find(f => f.ProductizedIntegrationGuid === selecteditem)

    const selectedIsDeployed =
      allDeployments &&
      envdetails &&
      allDeployments.find(
        f => f.environmentId === envdetails.id && f.baseId === selecteditem
      )
    const selectedLogDetails =
      transformerLogs &&
      transformerLogs.find(
        f =>
          parseInt(f.executionMetadata.baseId) === selecteditem &&
          f.environmentId === envdetails.id
      )

    const selectedWizIsDeployed =
      allDeployments &&
      selectedwizard &&
      envdetails &&
      allDeployments.find(
        f =>
          f.environmentId === envdetails.id &&
          f.baseId === selectedwizard.TransformerBaseId
      )
    const selectedWizLogDetails =
      transformerLogs &&
      selectedwizard &&
      transformerLogs.find(
        f =>
          parseInt(f.executionMetadata.baseId) ===
            parseInt(selectedwizard.TransformerBaseId) &&
          f.environmentId === envdetails.id
      )

    return (
      <div className="parent-env">
      <div ref="detailsbody" style={{ width: '74vw' }}>
        <div>
          <div
            style={{
              margin: '50px 0 15px 0',
              fontSize: '20px',
              display: 'inline-block'
            }}>
            {data && data.name}
          </div>
          <div
            style={{ display: 'inline-block', marginTop: 60, float: 'right' }}>
            <Button
              bsSize="small"
              bsStyle="default"
              style={{ marginRight: 10, marginTop: 22 }}
              onClick={this.sendToEdit}>
              Edit Experience
            </Button>
          </div>
          <div>
            Reports sent to <b>{data && data.type}</b>
            {data && data.type ? (
              <img
                style={{ marginLeft: 10, height: 24, marginTop: -5 }}
                role="presentation"
                src={alltypes.find(f => f.name === data.type).logo}
              />
            ) : null}
          </div>

          {/*tabs for multi-report experiences*/}
          <div
            style={{
              marginLeft: 10,
              marginBottom: -8,
              marginTop: 10,
              height: data && data.reports && data.reports.length > 1 ? 56 : 0,
              overflow: 'hidden'
            }}>
            {data && data.reports && data.reports.length > 1
              ? data.reports.map((o, i) => {
                  const isSelected = this.state.selectedReport === i
                  const envdetails =
                    allenvironments &&
                    allenvironments.find(
                      f => f.environmentId === o.environmentId
                    )
                  return (
                    <div
                      key={i}
                      className={
                        isSelected
                          ? 'selected-exp-details-tab'
                          : 'exp-details-tab'
                      }
                      onClick={() => this.selectReport(null, i)}
                      style={{
                        display: 'inline-block',
                        cursor: 'pointer',
                        borderRadius: '2px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        background: isSelected
                          ? envdetails && envdetails.color && envdetails.color
                          : envdetails &&
                            envdetails.color &&
                            envdetails.color + 'b9',
                        color:
                          envdetails &&
                          envdetails.color &&
                          getColorByBgColor(envdetails.color),
                        padding: 10,
                        textAlign: 'center',
                        height: 60,
                        width:
                          data && data.reports && data.reports.length > 7
                            ? 70
                            : 130,
                        fontWeight: 'bold',
                        margin: 5,
                        marginTop: 20,
                        marginLeft: 0,
                        marginRight: 0,
                        marginBottom: -30
                      }}>
                      <span>
                        {envdetails && envdetails.name && envdetails.name}
                      </span>
                    </div>
                  )
                })
              : null}
          </div>

          <div style={{ padding: 8, marginBottom: 16 }}>
            <div
              style={{
                borderRadius: '2px',
                border: '1px solid #888',
                padding: 4,
                background: '#eee'
              }}>
              <div style={{ height: 'auto', background: '#ffffffb4' }}>
                <div
                  style={{ paddingTop: 6, paddingBottom: 6, paddingLeft: 6 }}>
                  <span style={{ fontSize: 16 }}>
                    <span
                      style={{
                        borderRadius: '2px',
                        background: envdetails && envdetails.color,
                        color:
                          envdetails && getColorByBgColor(envdetails.color),
                        fontWeight: 'bold',
                        marginLeft: 5,
                        marginRight: 5,
                        padding: 2
                      }}>
                      &nbsp;{envdetails && envdetails.name}&nbsp;
                    </span>
                    <span>to</span>
                    <span>
                      <a
                        style={{
                          fontWeight: 'lighter',
                          marginLeft: 5,
                          marginRight: 5,
                          textDecoration: 'underline',
                          color: '#337ab7',
                          cursor: 'pointer'
                        }}
                        href={selectedreport && selectedreport.url}
                        target="_blank">
                        {selectedreport && selectedreport.url}
                      </a>
                    </span>
                  </span>
                  <HistoryBox redux={redux} type="Experience"
                    lastModified={lastModified} lastModifiedBy={lastModifiedBy}
                    wrapStyle={{margin:-1, padding:0, height:28}}
                    boxStyle={{top:26, right:1}}
                  />
                </div>
              </div>
              <div>
                {(data &&
                  data.recipeBaseIds &&
                  data.recipeBaseIds.length > 0) ||
                (data && data.wizardGuids && data.wizardGuids.length > 0) ? (
                  <Table style={{ background: '#eee', color: '#000' }}>
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Status</th>
                        <th>Last Run</th>
                        <th>Deployed</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.recipeBaseIds !== null &&
                      data.recipeBaseIds.length > 0
                        ? data.recipeBaseIds.map((i, key) => {
                            //recipes

                            const elementdetails =
                              allrecipes && allrecipes.find(f => f.BaseId === i)
                            const isDeployed =
                              allDeployments &&
                              envdetails &&
                              allDeployments.find(
                                f =>
                                  f.environmentId === envdetails.id &&
                                  f.baseId === i
                              )
                            const logdetails =
                              transformerLogs &&
                              envdetails &&
                              transformerLogs.find(
                                f =>
                                  parseInt(f.executionMetadata.baseId) === i &&
                                  f.environmentId === envdetails.id
                              )

                            return (
                              <tr
                                key={key}
                                style={
                                  isDeployed &&
                                  isDeployed.environmentId === envdetails.id
                                    ? styles.detailsrow
                                    : styles.warningrow
                                }
                                className="details-row-name"
                                onClick={() => this.sendToRecipe(null, i)}>
                                <td>
                                  <img
                                    role="presentation"
                                    src={images.codeicon}
                                    style={styles.icon}
                                  />

                                  {elementdetails && elementdetails.Name
                                    ? elementdetails.Name
                                    : null}
                                  <img
                                    style={{
                                      filter: 'invert(100%)',
                                      width: 20,
                                      float: 'right'
                                    }}
                                    className="recipe-view-details"
                                    role="presentation"
                                    src={images.magnifier}
                                  />
                                </td>
                                <td
                                  style={{
                                    fontWeight: 'bold',
                                    color:
                                      logdetails === undefined
                                        ? '#000'
                                        : logdetails && logdetails.succeeded
                                          ? 'green'
                                          : 'red'
                                  }}>
                                  {logdetails === undefined ? (
                                    '-'
                                  ) : (
                                    <span>
                                      {logdetails && logdetails.succeeded
                                        ? 'Success'
                                        : 'Failed'}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {logdetails === undefined
                                    ? 'N/A'
                                    : logdetails &&
                                      moment(logdetails.startTime).format(
                                        'MMM Do, h:mm a'
                                      )}
                                </td>
                                <td>
                                  {isDeployed === undefined
                                    ? null
                                    : isDeployed &&
                                      isDeployed.environmentId ===
                                        envdetails.id && (
                                        <div style={{ width: 'auto' }}>
                                          <div
                                            style={{
                                              display: 'inline-block',
                                              verticalAlign: 'middle',
                                              background: '#222',
                                              borderRadius: 15,
                                              padding: 2,
                                              width: 20,
                                              height: 20
                                            }}
                                          />
                                        </div>
                                      )}
                                </td>
                              </tr>
                            )
                          })
                        : null}

                      {data.wizardGuids !== null && data.wizardGuids.length > 0
                        ? data.wizardGuids.map((i, key) => {
                            //wizards
                            const elementdetails = allwizards.find(
                              f => i === f.ProductizedIntegrationGuid
                            )
                            const isDeployed =
                              allDeployments &&
                              elementdetails &&
                              envdetails &&
                              allDeployments.find(
                                f =>
                                  f.environmentId === envdetails.id &&
                                  f.baseId === elementdetails.TransformerBaseId
                              )
                            const logdetails =
                              transformerLogs &&
                              elementdetails &&
                              envdetails &&
                              transformerLogs.find(
                                f =>
                                  parseInt(f.executionMetadata.baseId) ===
                                    parseInt(
                                      elementdetails.TransformerBaseId
                                    ) && f.environmentId === envdetails.id
                              )

                            return (
                              <tr
                                key={key}
                                style={styles.detailsrow}
                                className="details-row-name"
                                onClick={() => this.sendToWiz(null, i)}>
                                <td>
                                  <img
                                    role="presentation"
                                    src={images.ic_web_black_48dp}
                                    style={styles.icon}
                                  />
                                  {elementdetails && elementdetails.Name
                                    ? elementdetails.Name
                                    : null}

                                  <img
                                    style={{
                                      filter: 'invert(100%)',
                                      width: 20,
                                      float: 'right'
                                    }}
                                    className="recipe-view-details"
                                    role="presentation"
                                    src={images.magnifier}
                                  />
                                </td>
                                <td
                                  style={{
                                    fontWeight: 'bold',
                                    color:
                                      logdetails === undefined
                                        ? '#000'
                                        : logdetails && logdetails.succeeded
                                          ? 'green'
                                          : 'red'
                                  }}>
                                  {logdetails === undefined ? (
                                    '-'
                                  ) : (
                                    <span>
                                      {logdetails && logdetails.succeeded
                                        ? 'Success'
                                        : 'Failed'}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {logdetails === undefined
                                    ? 'N/A'
                                    : logdetails &&
                                      moment(logdetails.startTime).format(
                                        'MMM Do, h:mm a'
                                      )}
                                </td>
                                <td>
                                  {isDeployed === undefined
                                    ? null
                                    : isDeployed &&
                                      isDeployed.environmentId ===
                                        envdetails.id && (
                                        <div style={{ width: 'auto' }}>
                                          <div
                                            style={{
                                              display: 'inline-block',
                                              verticalAlign: 'middle',
                                              background: '#222',
                                              borderRadius: 15,
                                              padding: 2,
                                              width: 20,
                                              height: 20
                                            }}
                                          />
                                        </div>
                                      )}
                                </td>
                              </tr>
                            )
                          })
                        : null}
                    </tbody>
                  </Table>
                ) : (
                  <div style={{ textAlign: 'center', margin: 20 }}>
                    <span>No connected data</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <CommentBar {...this.props} 
            commentList={project.connectedExperienceComments}
            callPostComment={actions.callPostExpComment}
            callDeleteComment={actions.callDeleteExpComment}
            containerId={this.props.match.params.expId}
          />
            
        </div>

        {selecteditem ? (
          <Modal show={this.state.modal} onHide={this.showModal} backdrop="static">
            <Header>
              <Title>
                <img
                  role="presentation"
                  src={
                    this.state.isRecipe
                      ? images.codeicon
                      : images.ic_web_black_48dp
                  }
                  style={styles.modalicon}
                />
                {selectedrecipe ? selectedrecipe.Name : null}
                {selectedwizard ? selectedwizard.Name : null}
              </Title>
            </Header>

            <Body style={{ maxHeight: 460, overflowY: 'auto' }}>
              <div style={{ paddingTop: 5 }}>
                {selectedrecipe && selectedrecipe.Description !== '' ? (
                  <div>
                    <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                      Description
                    </span>
                    {selectedrecipe && (
                      <div style={{ padding: 20, paddingTop: 5 }}>
                        {selectedrecipe.Description}
                      </div>
                    )}
                  </div>
                ) : null}

                {selectedLogDetails && (
                  <div>
                    <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                      Status
                    </span>
                    <div
                      style={{
                        padding: 20,
                        paddingTop: 5,
                        color: selectedLogDetails.succeeded ? 'green' : 'red'
                      }}>
                      {selectedLogDetails.succeeded ? 'Success' : 'Failed'}
                    </div>
                  </div>
                )}

                {selectedWizLogDetails && (
                  <div>
                    <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                      Status
                    </span>
                    <div
                      style={{
                        padding: 20,
                        paddingTop: 5,
                        color: selectedWizLogDetails.succeeded ? 'green' : 'red'
                      }}>
                      {selectedWizLogDetails.succeeded ? 'Success' : 'Failed'}
                    </div>
                  </div>
                )}

                {selectedLogDetails && (
                  <div>
                    <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                      Start Time
                    </span>
                    <div style={{ padding: 20, paddingTop: 5 }}>
                      {selectedLogDetails.startTime &&
                        moment(selectedLogDetails.startTime).format(
                          'MMM Do, h:mm a'
                        )}
                    </div>
                  </div>
                )}

                {selectedWizLogDetails && (
                  <div>
                    <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                      Start Time
                    </span>
                    <div style={{ padding: 20, paddingTop: 5 }}>
                      {selectedWizLogDetails.startTime &&
                        moment(selectedWizLogDetails.startTime).format(
                          'MMM Do, h:mm a'
                        )}
                    </div>
                  </div>
                )}

                {selectedLogDetails && (
                  <div>
                    <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                      Run Time
                    </span>
                    <div style={{ padding: 20, paddingTop: 5 }}>
                      {selectedLogDetails.timer &&
                        selectedLogDetails.timer + ' seconds'}
                    </div>
                  </div>
                )}

                {selectedWizLogDetails && (
                  <div>
                    <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                      Run Time
                    </span>
                    <div style={{ padding: 20, paddingTop: 5 }}>
                      {selectedWizLogDetails.timer &&
                        selectedWizLogDetails.timer + ' seconds'}
                    </div>
                  </div>
                )}

                {selectedLogDetails &&
                  selectedLogDetails.errorMessage && (
                    <div>
                      <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                        Error Message
                      </span>
                      <div style={{ padding: 20, paddingTop: 5 }}>
                        {selectedLogDetails.errorMessage}
                      </div>
                    </div>
                  )}

                {selectedWizLogDetails &&
                  selectedWizLogDetails.errorMessage && (
                    <div>
                      <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                        Error Message
                      </span>
                      <div style={{ padding: 20, paddingTop: 5 }}>
                        {selectedWizLogDetails.errorMessage}
                      </div>
                    </div>
                  )}

                {selectedwizard || selectedrecipe ? (
                  <div>
                    <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                      Last Modified
                    </span>
                    {selectedrecipe ? (
                      <div style={{ padding: 20, paddingTop: 5 }}>
                        {moment(selectedrecipe.LastModified).format(
                          'MMM Do, h:mm a'
                        )}
                      </div>
                    ) : null}
                    {selectedwizard ? (
                      <div style={{ padding: 20, paddingTop: 5 }}>
                        {moment(selectedwizard.LastModified).format(
                          'MMM Do, h:mm a'
                        )}
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {selectedrecipe &&
                  !selectedLogDetails && (
                    <div>
                      {selectedIsDeployed &&
                      selectedIsDeployed.environmentId === envdetails.id ? (
                        <span
                          style={{
                            marginLeft: 10,
                          }}>
                          This item is deployed but
                          has not run on the "{envdetails && envdetails.name}"
                          environment yet.
                        </span>
                      ) : (
                        <span
                          style={{
                            marginLeft: 10,
                          }}>
                          This item is not deployed on the "{
                            envdetails && envdetails.name
                          }" environment.
                        </span>
                      )}
                      <div style={{ padding: 20, paddingTop: 5 }} />
                    </div>
                  )}

                {selectedwizard &&
                  !selectedWizLogDetails && (
                    <div>
                      {selectedWizIsDeployed &&
                      selectedWizIsDeployed.environmentId === envdetails.id ? (
                        <span
                          style={{
                            marginLeft: 10,
                          }}>
                          This item is deployed but
                          has not run on the "{envdetails.name}"
                          environment yet.
                        </span>
                      ) : (
                        <span
                          style={{
                            marginLeft: 10,
                          }}>
                          This item is not deployed on the "{
                            envdetails.name
                          }" environment.
                        </span>
                      )}
                      <div style={{ padding: 20, paddingTop: 5 }} />
                    </div>
                  )}
              </div>
            </Body>
            <Footer>
              <Button
                style={{ float: 'left' }}
                bsStyle="default"
                onClick={
                  selectedrecipe
                    ? () => this.sendAway(null, selectedrecipe.BaseId)
                    : () =>
                        this.sendAway(null, selectedwizard.TransformerBaseId)
                }>
                {isRecipe ? <span>Edit Recipe</span> : <span>Edit Wizard</span>}
              </Button>
            </Footer>
          </Modal>
        ) : null}
      </div>
      </div>
    )
  }
}
