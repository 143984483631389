import React, { Component, useCallback } from "react";
import BigCalendar, { Views } from "react-big-calendar";
import ScheduleMod from "./scheduleMod";
import moment from "moment";
import images from "../images";

import "rc-time-picker/assets/index.css";

BigCalendar.momentLocalizer(moment);

class ScheduleCalendarView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      editMode: false,
      selectedEvent: null,
      viewMode: "week",
      expand: false,
      monthIsActive: false,
      weekIsActive: true,
      dayIsActive: false,
      todayIsActive: true,
      backIsActive: false,
      nextIsActive: false,
      toolbar: null,
      date: moment().toDate(),
    };

    this.handleModal = this.handleModal.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
  }

  componentWillMount() {
    const { actions, redux, match, environment } = this.props;
    const { project } = redux && redux;
    const { params } = match;
    const { appId } = params;
    const { TenantId } = project && project.tenantInfo;

    const { id } = environment;

    if (id) {
      actions.callGetTriggerSummary(appId, TenantId, id);
      actions.callGetTriggerDetailsWithoutFilter(appId, TenantId, id);
    } else {
      actions.callGetTriggerSummary(appId, TenantId);
      actions.callGetTriggerDetailsWithoutFilter(appId, TenantId);
    }
  }

   handleNavigateNext = async (date, view) => {
    try {
      this.onNavigate(date, view);
    } catch (error) {
      console.log(error);
    }
  
    this.setState({
      date: date,
    });
  };

  handleModal() {
    let { showModal } = this.state;
    showModal = !showModal;
    this.setState({ showModal });
  }

  onNavigate(date, view) {
    const { actions, redux, match, environment} = this.props;
    const { params } = match;
    const { appId } = params;
    
    const { project } = redux && redux;
    const { TenantId } = project && project.tenantInfo;

    const { id } = environment;
    const today = moment(date);
    
    let start, end;

    if (view === "week") {
      start = today.startOf("week");
      end = moment(start).endOf("week");
    } else if (view === "month") {
      start = today.startOf("month");
      end = moment(start).endOf("month");
    } else if (view === "day") {
      start = today.startOf("day");
      end = moment(start).endOf("day");
    }

    start = start.format('YYYY-MM-DDTHH:mm:ss.SSS');
    end = end.format('YYYY-MM-DDTHH:mm:ss.SSS');

    id ? 
      actions.callGetTriggerDetailsWithoutFilter(appId, TenantId, id, start, end) :
      actions.callGetTriggerDetailsWithoutFilter(appId, TenantId, null, start, end);
  }

  toggleEditMode = () => {
    let { editMode } = this.state;
    editMode = !editMode;
    this.setState({ editMode });
  };

  resetCalendarState = (editMode) => {
    this.setState({
      editMode,
      selectedEvent: null,
    });
  };

  selectEvent = (e) => {
    const { appId } = this.props.match.params;

    if (!e.status) {
      // find event from summaryTriggers based on events baseid
      const { summaryTriggers } = this.props.redux.events;
      const matchingEvent = summaryTriggers.find(event => e.baseId === event.baseId);

      this.setState({ editMode: true, selectedEvent: matchingEvent });
      this.handleModal();
    } else {
      // go to monitorTransformer
      // this.props.history.push(e.status);
      window.open(e.status, "_blank");
    }
  };

  setView = (e) => {
    this.setState({ viewMode: e }, () =>
      this.onNavigate(new Date(), this.state.viewMode)
    );
  };

  Event = ({ event }) => {
    return (
      <div style={{ textAlign: "center" }}>
        <span>{event.title}</span>
      </div>
    );
  };

  eventStyleGetter = (event, start, end, isSelected) => {
    let border, backgroundColor, color, opacity;

    color = "#000";
    backgroundColor = "white";

    if (event.status) {
      if (event.executionStatus === "Succeeded") {
        opacity = 0.8;
        border = `2px solid green`;
      } else {
        opacity = 0.8;
        border = `2px solid red`;
      }
    } else {
      border = `1px solid gray`;
      backgroundColor = "#c4def6";

      if (event.isSchedulesPaused) {
        backgroundColor = "#ddd";
        color = "#000";
        border = "1px dashed #000";
      }
    }

    const style = {
      fontSize: 11,
      width: "fit-content",
      maxWidth: "100%",
      backgroundColor,
      color,
      opacity,
      border,
      display: "block",
    };

    return {
      style: style,
    };
  };

  render() {
    const { events } = this.props.redux;
    const { expand } = this.state;
    const {detailsTriggersLoading, detailsTriggers, summaryTriggers } = events;

    return (
      detailsTriggersLoading ?
          <div style={{fontSize: 20, display: "flex", alignItems: "center",  height: "80vh" , justifyContent: "center", gap: 10}} >
              <img alt="spinner" src={images.ajax_loader} height="20"/> Loading...
          </div> :
          <div>
            <BigCalendar
              view={this.state.viewMode}
              eventPropGetter={this.eventStyleGetter}
              onView={this.setView}
              onNavigate={this.handleNavigateNext}
              views={["month", "week", "day"]}
              defaultView="week"
              events={detailsTriggers.map(event => ({
                start: new Date(event.executionTime),
                end: new Date(event.executionTime),
                title: event.transformerName,
                ...event,
              }))}
              showMultiDayTimes={true}
              date={this.state.date}
              onSelectEvent={(event) => this.selectEvent(event)}
              style={{ height: "100vh" }}
            />

            <ScheduleMod
              resetCalendarState={this.resetCalendarState}
              toggleEditMode={this.toggleEditMode}
              selectedEvent={this.state.selectedEvent}
              editMode={this.state.editMode}
              show={this.state.showModal}
              hide={this.handleModal}
              {...this.props}
            />
          </div>
    );
  }
}

export default ScheduleCalendarView;
