import React, { Component } from 'react';
import _columns from './columns'
import {ResizableBox} from 'react-resizable';

const styles = {
  cell:{
    height:27,
    textAlign: 'left',
    position: 'relative',
    fontWeight: 'bold',
    color: 'rgb(84, 105, 141)',
    width: 160,
    display:'inline-block',
    border: '1px solid #DDD',
    background:'whitesmoke',
    boxSizing:'border-box',
    lineHeight:'22px',
    verticalAlign:'top',
  },
  text:{
    paddingTop:3,
    paddingLeft:8,
    cursor:'pointer'
  },
}

export default class MapperHeader extends Component {

  render() {
    const {onWidthChange, widths, sort, sortByColumn, sortDescending} = this.props
    let totalWidth = 0
    widths.forEach((w)=> totalWidth += w)

    return (<div style={{width:totalWidth,height:27,position:'relative'}}>
      {widths.map((w,i)=>{
        const _c = _columns[i]
        let title = _c.name
        if(i===sortByColumn){
          title += sortDescending ? ' ⬇' : ' ⬆'
        }
        return <div style={{...styles.cell, width:w}} key={i} 
          className="mapper-header-cell">
          <ResizableBox className="box" width={w} height={27} axis="x"
            onResize={(e,d)=>onWidthChange(i, d.size.width)}
            minConstraints={[_c.width, 27]}>
            <div className="mapper-header-cell-text" 
              onClick={()=>sort(i)}>
              {title}
            </div>
          </ResizableBox>
        </div>
      })}
    </div>)
  }
}

