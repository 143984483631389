import React, { Component } from "react";
import ReactGA from "react-ga";

import images from "../images";
import MonitorWorkspace from "./monitorWorkspace";
import Activity from "./quickAction";

import DomPurify from "dompurify";
import MarkdownIt from "markdown-it";

let codemirror = null;
if (typeof window !== "undefined" && typeof window.navigator !== "undefined") {
  codemirror = require("react-codemirror2");
  require("codemirror/mode/markdown/markdown");
  require("codemirror/mode/javascript/javascript");
}

export default class DashboardTab extends Component {
  constructor() {
    super();
    this.state = {
      editDescription: false,
      description: null,
      cmLoaded: false,
      savingProjectDescription: false,
    };
    this.md = new MarkdownIt();
    this.changeDescription = this.changeDescription.bind(this);
    this.toggleEditDescription = this.toggleEditDescription.bind(this);
    this.updateDescription = this.updateDescription.bind(this);
    this.chooseActivity = this.chooseActivity.bind(this);
  }

  componentWillMount() {
    const { project } = this.props.redux;
    const { appData } = project;
    this.setState({ description: appData.description });

    // this.props.updateTab()
  }

  toggleEditDescription(bool) {
    if (bool && !this.state.cmLoaded) {
      //this.loadCM();
    }
    this.setState({ editDescription: bool });
  }

  changeDescription(a, b, c) {
    this.setState({ description: c });
  }

  createMarkup(desc) {
    return {
      __html: DomPurify.sanitize(desc),
    };
  }

  updateDescription() {
    const { redux, match } = this.props;
    const { params } = match;
    const { project } = redux;
    this.setState({ savingProjectDescription: true });
    this.props.actions
      .callUpdateApp(
        project.tenantInfo.TenantId,
        {
          description: this.state.description,
        },
        params.appId
      )
      .then(() => {
        this.setState({
          savingProjectDescription: false,
          editDescription: false,
        });
      });
  }

  chooseActivity = (activity) => {
    let event = "Quick Action: " + activity.name;
    ReactGA.ga("send", "event", {
      eventCategory: "QuickActions",
      eventAction: "Click",
      eventLabel: event,
    });
    window.Intercom("trackEvent", event);
    if (activity.actionType === "wizard") {
      EE.emit("choose-workspace", {
        why: "wizard",
        arg: activity.actionArg,
      });
    } else if (activity.actionType === "elevio") {
      window._elev.openArticle(parseInt(activity.actionArg));
    } else if (activity.actionType === "template") {
      EE.emit("choose-workspace", {
        why: "template",
        arg: activity.actionArg,
      });
    } else if (activity.actionType === "external-link") {
      window.open(activity.actionArg, '_blank', 'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes');
    } else {
      console.log("no wiz type id");
    }
  };

  render() {
    const { redux } = this.props;
    const { main } = redux;
    const { project } = redux;
    const { appData } = project;
    const { elevio_category_id } = project.appData;
    let description;
    if (this.state.description) {
      description = this.createMarkup(this.md.render(this.state.description));
    }
    const CodeMirror = codemirror && codemirror.Controlled;

    return (
      <div className="overview-tab-content">
        <div
          style={{
            ...styles.section,
            width: 860,
            display: "block",
            overflow: "visible",
          }}
        >
          <div style={styles.header}>
            <div
              style={{
                ...styles.title,
                width: 218,
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 8,
                height: 50,
                borderRight: "1px solid #ccc",
              }}
            >
              Quick Actions
            </div>
            <div style={styles.elevioSearch}>
              <elevio-element
                data-type="search"
                id="elevio-envelope"
              ></elevio-element>
            </div>
          </div>
          {main.masterQuickActions &&
            main.masterQuickActions.length > 0 && (
              <div style={{ padding: "20px 10px 10px 10px" }}>
                {main.masterQuickActions.map((a, i) => {
                  return (
                    <Activity
                      key={i}
                      {...this.props}
                      active={
                        a.actionType === "recipe" && this.state.isCreatingRecipe
                      }
                      activity={a}
                      onChoose={this.chooseActivity}
                    />
                  );
                })}
              </div>
            )}
        </div>
        <div
          style={{
            ...styles.section,
            width: 860,
            display: "block",
            overflow: "visible",
          }}
        >
          <div style={styles.header}>
            <div
              style={{
                ...styles.title,
                position: "absolute",
                top: 0,
                left: 0,
                height: 50,
                width: "100%",
              }}
            >
              Monitoring
            </div>
          </div>
          <MonitorWorkspace {...this.props} />
        </div>

        <div
          style={{
            ...styles.section,
            width: 860,
            display: "block",
            overflow: "visible",
          }}
        >
          <div style={styles.header}>
            <div
              style={{
                ...styles.title,
                position: "absolute",
                top: 0,
                left: 0,
                height: 50,
                width: "100%",
              }}
            >
              Project Description
              {appData && appData.identityIsAdmin && (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    verticalAlign: "top",
                  }}
                >
                  {!this.state.editDescription ? (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => this.toggleEditDescription(true)}
                    >
                      <img
                        src={images.create}
                        style={{ width: 20 }}
                        alt="create"
                      />
                    </div>
                  ) : (
                    <div>
                      {this.state.savingProjectDescription ? (
                        <img
                          alt="spinner"
                          src={images.ajax_loader_small}
                          style={{
                            position: "absolute",
                            filter: "invert(100%)",
                            top: 4,
                            left: 10,
                          }}
                          height="10"
                        />
                      ) : (
                        <div>
                          <div
                            className="description-icon"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 10,
                              cursor: "pointer",
                            }}
                            onClick={() => this.updateDescription()}
                          >
                            <img
                              src={images.done}
                              style={{ width: 20 }}
                              alt="clear"
                            />
                          </div>
                          <div
                            className="description-icon"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 40,
                              cursor: "pointer",
                            }}
                            onClick={() => this.toggleEditDescription(false)}
                          >
                            <img
                              src={images.ic_clear_black_48dp}
                              style={{ width: 20 }}
                              alt="clear"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {project.transformerLogs && (
            <div>
              <div style={{ position: "relative" }}>
                {/*}
        <div style={{margin:'30px 0 8px 0', fontSize:'18px', 
          display:'inline-block',lineHeight:'18px'}}>
          Workspace Description
        </div>
      */}
              </div>

              <div
                style={{
                  marginBottom: 10,
                  display: this.state.editDescription ? "block" : "none",
                  height: "auto",
                }}
              >
                {CodeMirror && (
                  <CodeMirror
                    value={this.state.description}
                    options={{
                      lineNumbers: true,
                      theme: "default",
                      mode: "markdown",
                    }}
                    onBeforeChange={this.changeDescription}
                  />
                )}
                <div style={{ margin: 10 }}>
                  <a href="http://commonmark.org/help/" target="blank">
                    Formatting Help
                  </a>
                </div>
              </div>

              <div
                style={{
                  padding: "8px 12px",
                  display: this.state.editDescription ? "none" : "block",
                }}
              >
                <div dangerouslySetInnerHTML={description} />
              </div>
            </div>
          )}
        </div>
        {elevio_category_id > 0 && (
          <div
            style={{
              ...styles.section,
              width: 860,
              display: "block",
              overflow: "visible",
            }}
          >
            <div style={styles.header}>
              <div
                style={{
                  ...styles.title,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: 50,
                  width: "100%",
                }}
              >
                Project Documentation
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <elevio-element
                data-type="category"
                data-id={elevio_category_id}
              ></elevio-element>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  content: {
    marginLeft: 280,
    paddingRight: 20,
    paddingTop: 20,
    height: "100%",
    overflowY: "auto",
  },
  header: {
    width: "100%",
    marginBottom: 0,
    borderBottom: "1px solid #cccccc",
    position: "relative",
    height: 51,
  },
  title: {
    fontSize: 16,
    padding: "15px 18px 15px 18px",
    fontWeight: 500,
    display: "inline-block",
  },
  section: {
    background: "#f1f1f1",
    margin: 20,
    marginLeft: 0,
    fontSize: 13,
    color: "#333333",
    border: "1px solid #aaa",
    borderRadius: 4,
    position: "relative",
    overflow: "hidden",
    boxSizing: "content-box",
    verticalAlign: "top",
    display: "inline-block",
  },
  elevioSearch: {
    width: 643, //'calc(100% - 218px)',
    position: "absolute",
    left: 217,
    top: 0,
    zIndex: 7,
  },
  sectionContent: {
    overflowY: "scroll",
    paddingBottom: 18,
    height: 340,
    width: 420,
  },
};