import React, { Component } from 'react'
import Helmet from 'react-helmet';
import Header from '../header/header'

class FailedLogin extends Component {

  render() {
    return (
      <div>
        <Helmet title="Lingk Sign Up" />
        <Header
          publicPage={true}
          {...this.props}
        />
        <div style={styles.card}>
          <p>Please sign up for a <a href="http://www.lingk.io/pricing" target="_blank">Free Trial with Lingk</a></p>
          <p style={{margin:16}}><strong>or</strong></p>
          <p>Contact your Lingk Administrator to join an existing Organization</p>
        </div>
      </div>
    )
  }
}

const styles = {
  card: {
    background:'white',
    width:350,
    height:180,
    boxShadow:'rgb(150, 150, 150) 1px 1px 6px',
    margin:'80px auto',
    padding:'35px 30px',
    textAlign:'center'
  }
}

export default FailedLogin
