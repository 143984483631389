import React, { Component } from "react";
import d3 from "d3/d3";
import moment from "moment";
import ReactTooltip from "react-tooltip";

import MonitorTransformerResult from "./monitortransformerResult";
import images from "../images";
export default class MonitorTransformer extends Component {
  constructor() {
    super();
    this.state = {
      xScale: d3.time
        .scale()
        .domain([
          new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
          new Date(),
        ])
        .range([0, 430]),
      coords: { label: "", values: [{ x: new Date(), y: 0 }] },
      resultElements: [],
      selectedEnvironment: { name: "All Environments" },
      expand: false,
      preload: true,
      openPopUp: false,
    };
  }

  componentDidMount() {
    const { actions, redux, match } = this.props;
    const { params } = match;
    const { transId } = params;
    const { project } = redux;
    if (project && project.tenantInfo && project.tenantInfo.TenantId)
    actions
      .callGetTransformerLogs(project.tenantInfo.TenantId, transId, 1, 1)
      .then((data) => {
        data = data[0];
        if (data && data.externalExecutionId) {
          actions.callGetSelectedTransformerLogs(
            project.tenantInfo.TenantId,
            data.externalExecutionId,
            data.errorMessage,
            data.executionGUID
          );
          this.forceUpdate();
        }
      });
  }

  formatDate(date) {
    let splitstr = date.split('T')
    let splitDate = splitstr[0].split('-')
    return splitDate[2] + splitDate[1] + splitDate[0]
  }

  downloadLogsFromS3(execution) {
    console.log("execution", execution)
    const { actions, match, redux } = this.props;
    const { transformer } = this.props.redux;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    const envId = transformer.deployments.length > 0 && transformer.deployments[0].environmentId
    return actions.downloadExecutionLogsFromS3(
      project.tenantInfo.TenantId,
      appId,
      envId,
      this.formatDate(execution.finishTime),
      execution.transId,
      execution.executionGUID
    );
  };

  toggleExpand = () => {
    let { expand } = this.state;
    expand = !expand;
    this.setState({ expand });
  };
  setRef = (ref) => {
    this.inputRef = ref;
  };
  populateLogs(data) {
    const bothVals = [];
    const successVals = [];
    const failVals = [];
    const bothNums = [];
    const successNums = [];
    const failNums = [];
    const zoom = 25;
    let coords;
    for (let i = 0; i < zoom; i++) {
      bothNums[i] = 0;
      successNums[i] = 0;
      failNums[i] = 0;
    }
    const resultElements = [];
    if (data[0]) {
      const max = moment(data[0].startTime).unix();
      const min = moment(new Date().getTime() - 24 * 60 * 60 * 1000).unix();
      const tick = (max - min) / (zoom - 1);
      for (let i = 0; i < data.length; i++) {
        bothNums[Math.floor((moment(data[i].startTime).unix() - min) / tick)]++;
        if (data[i].succeeded) {
          successNums[
            Math.floor((moment(data[i].startTime).unix() - min) / tick)
          ]++;
        } else {
          failNums[
            Math.floor((moment(data[i].startTime).unix() - min) / tick)
          ]++;
        }
        resultElements.push(
          <MonitorTransformerResult
            {...this.props}
            startTime={data[i].startTime}
            guid={data[i].executionGUID}
            completed={data[i].completed}
            succeeded={data[i].succeeded}
            error={data[i].errorMessage}
            timer={data[i].timer}
            triggerType={data[i].triggerType}
            key={i}
            externalExecutionId={data[i].externalExecutionId}
            environmentId={data[i].environmentId}
            toggleExpand={this.toggleExpand}
            expand={this.state.expand}
            preload={false}
            scrollToMe={this.scrollToMe}
            rowsRead={data[i].recordsRead}
            versionNumber={data[i].recipeVersionNumber}
            openPopUp={this.state.openPopUp}
            handlePopUp={() => this.setState({ openPopUp: false })}
            setRef={this.setRef}
            transId={this.props.match.params.transId}
          />
        );
      }
      for (let i = 0; i < zoom; i++) {
        bothVals.push({
          x: new Date(moment.unix(Math.floor(min + tick * i)).format()),
          y: bothNums[i],
        });
        successVals.push({
          x: new Date(moment.unix(Math.floor(min + tick * i)).format()),
          y: successNums[i],
        });
        failVals.push({
          x: new Date(moment.unix(Math.floor(min + tick * i)).format()),
          y: failNums[i],
        });
      }
      //var scale = d3.time.scale().domain([Moment(data[data.length-1].startTime).toDate(), Moment(data[0].startTime).toDate()]).range([0, 500])
      coords = [
        { label: "All Results", values: bothVals },
        { label: "Successes", values: successVals },
        { label: "Errors", values: failVals },
      ];
    } else {
      coords = this.state.coords;
    }
    return { coords, resultElements };
  }

  scrollToMe = (item) => {
    this.scrollTo(item.parentNode.parentNode, 30, item.offsetTop - 93);
  };

  scrollTo = (element, scrollDuration, scrollTarget) => {
    const initial = element.scrollTop,
      distance = scrollTarget - initial;
    const step = distance / scrollDuration;
    var scrollCount = initial,
      count = scrollDuration;
    function go() {
      scrollCount += step;
      element.scrollTop = Math.round(scrollCount);
      count--;
      if (count === 0) return;
      window.requestAnimationFrame(go);
    }
    if (step !== 0) {
      window.requestAnimationFrame(go);
    }
  };

  render() {
    const { transformer, project } = this.props.redux;
    let coords = null;
    let resultElements = null;
    const tooltipLine = (label, data) => {
      return label + ": " + data.y;
    };
    if (
      !(
        transformer.versions &&
        transformer.versions[0] &&
        transformer.monitorLogs
      )
    ) {
      if (
        transformer.savedConfiguration &&
        transformer.savedConfiguration.title
      ) {
        return (
          <div className="main-tab-content">
            <br />
            <span>No transformer logs available at this time</span>
          </div>
        );
      }
      return (
        <div className="ajax-loader">
          <img alt="spinner" src={images.ajax_loader} />
        </div>
      );
    }
    if (transformer.monitorLogs[0]) {
      let logs = transformer.monitorLogs;
      if (
        this.state.selectedEnvironment &&
        this.state.selectedEnvironment.environmentId
      ) {
        logs = transformer.monitorLogs.filter((l) => {
          return (
            l.environmentId === this.state.selectedEnvironment.environmentId
          );
        });
      }
      const results = this.populateLogs(logs);
      coords = results.coords;
      resultElements = results.resultElements;
    }

    return (
      <div
        // className="last-recipe-console"
        ref={(ref) => (this.scroller = ref)}
      >
        <ReactTooltip place={"bottom"} />
        <div
          style={{
            marginBottom: 1,
            position: "relative",
            backgroundColor: "#fef7e0",
            marginRight: 10,
            fontSize: 14,
            width: "100%",
            padding: "6px 8px 8px 5px",
            color: "white",
            color: "#e37400",
            borderBottom: "1px solid #fdd663",
            borderTop: "1px solid #fdd663"
          }}
        >
          <div
            style={{ display: "inline-block", width: "8%", marginLeft: "1%" }}
          >
            Date
          </div>
          <div
            style={{ display: "inline-block", width: "8%", marginLeft: "1%" }}
          >
            Environment
          </div>
          <div
            style={{
              display: "inline-block",
              width: "8%",
              wordWrap: "break-word",
              marginLeft: "1%",
            }}
          >
            Version Number
          </div>
          {/*<div style={{ display:'inline-block', width:'8%', marginLeft:'1%' }}>
                  Trigger Type
              </div>*/}
          <div
            style={{
              display: "inline-block",
              width: "8%",
              wordWrap: "break-word",
              marginLeft: "2%",
            }}
          >
            Completed
          </div>
          <div
            style={{
              display: "inline-block",
              width: "8%",
              wordWrap: "break-word",
              marginLeft: "1%",
            }}
          >
            Succeeded
          </div>
          <div
            style={{
              display: "inline-block",
              width: "35%",
              wordWrap: "break-word",
              marginLeft: "1%",
            }}
          >
            Error
          </div>
          <div
            style={{
              display: "inline-block",
              width: "5%",
              wordWrap: "break-word",
              marginLeft: "1%",
            }}
          >
            Rows Read
          </div>
          <div
            style={{ display: "inline-block", width: "3%", marginLeft: "2%" }}
          >
            Timer
          </div>

          <div
            style={{
              display: "inline-block",
              width: "7%",
              position: "absolute",
              paddingLeft: 20,
            }}
          >
            {
              transformer.monitorLogs.length > 0 &&
              <img                
                src={images.ic_file_download_black_48dp}
                height={23}
                data-tip={"Download Execution logs"}
                style={{ opacity: "0.6", marginLeft: 3, marginRight: 10, cursor: "pointer"}}                
                onClick={() => {
                  this.downloadLogsFromS3(transformer.monitorLogs[0])
                }}
              />
            }

            <img
              src={images.OpenInNew}
              height={23}
              data-tip={"Open in a new tab"}
              onClick={() => this.setState({ openPopUp: true })}
            />
          </div>
        </div>
        {resultElements}
      </div>
    );
  }
}
