import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

import images from "../images";
import * as helpers from "../utils/connectorsHelper";
class CredBar extends Component {
  constructor() {
    super();
    this.state = {
      checking: false,
      checkResult: null,
      oauthUrl: null,
      gettingOauthUrl: null,
      metadata: null,
      modalOpened: false,
      checkError: null,
      isSaving: false,
      showDownload: false,
    };
  }

  componentWillMount() {
    const { creds, redux, env, providerConfig } = this.props;
    const isIntro = true;
    const { project } = redux;
    const envId = env.environmentId;
    if (!(creds && creds.wasJustAdded)) {
      let doTesting = helpers.TestConnection(
        providerConfig,
        creds.credentialsJson
      );
      if (doTesting) {
        if (!providerConfig.noCheckConnectionYet) {
          this.check(creds && creds.credentialsJson, isIntro);
        }
      } else {
        this.setState({
          checking: false,
          checkResult: "404 Error",
          metadata: null,
        });
      }
    }
    let data = null;
    data =
      project.appCerts &&
      project.appCerts.find((t) => {
        return t.EnvID === envId;
      });
    if (data) {
      this.setState({ showDownload: true });
    }
  }

  check = (creds, isIntro) => {
    this.setState({
      checking: true,
      checkResult: "",
      metadata: null,
      checkError: null,
    });
    const { actions, match, redux, env, providerConfig } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    const { metadataEndpoint, skipCheckConnection } = providerConfig;
    if (!skipCheckConnection && metadataEndpoint) {
      actions
        .tryCheckConnection(
          project.tenantInfo.TenantId,
          appId,
          providerConfig,
          creds,
          env,
          this.props.connectorKey
        )
        .then(() => {
          let checkResult = "200 OK";
          this.setState({ checkResult, checking: false });
        });
    } else {
      actions
        .checkProviderMetadata(
          project.tenantInfo.TenantId,
          appId,
          providerConfig,
          env,
          creds,
          isIntro
        )
        .then((metadata) => {
          let checkResult = "200 OK";
          if (providerConfig.type === "LocalFile") {
            if (metadata && metadata.length === 0) {
              checkResult = "";
            }
            this.setState({ fileSchema: metadata });
          }
          this.setState({ checkResult, metadata, checking: false });
        })
        .catch((error) => {
          this.setState({
            checking: false,
            checkResult: "404 Error",
            metadata: null,
          });
          if (error.response && error.response.data) {
            let err = error.response.data;
            if (providerConfig.metadataApi === "TEST_CONN_API_URL") {
              err = error.response.data.error;
            }
            this.setState({ checkError: err });
          }
        });
    }
  };

  render() {
    const {
      providerConfig,
      onClick,
      creds,
      connectorKey,
      providerLogo,
      isMisconfigured,
      singleMisconfiguredConnectors } = this.props;
    const { checking, checkResult } = this.state;
    return (
      // TODO: Finalize styling
      <tr onClick={onClick}
        style={isMisconfigured ? { backgroundColor: "#F7D1CD", } : {}}
        className={isMisconfigured ? "singleConnection-misconfigured" : "singleConnection"}
      // className="singleConnection"
      >
        <td className="cell-center" style={{ width: "4%" }}>
          {" "}
          {providerLogo && providerLogo.fields && providerLogo.fields.logo && (
            <img
              style={{
                marginTop: 3,
                marginLeft: 5,
                height: 20,
                width: 20,
              }}
              src={providerLogo.fields.logo.fields.file.url}
            ></img>
          )}
        </td>
        <td>
          {creds.connectionName ? (
            <span>{creds.connectionName}</span>
          ) : (
            <span>{providerConfig.label}</span>
          )}
        </td>
        <td>
          <span>{connectorKey}</span>
        </td>
        <td style={{ width: "15%" }}>
          {providerConfig.readerName == "Reader" ? (
            <div className="chip">
              <svg
                style={{ width: "18px" }}
                className="chip-image"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                />
              </svg>
              Source
            </div>
          ) : null}
          {providerConfig.writerName == "Writer" ? (
            <div className="chip" style={{ marginLeft: 10 }}>
              <svg
                style={{ width: "18px" }}
                className="chip-image"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                />
              </svg>
              Destination
            </div>
          ) : null}
        </td>
        <td style={{ width: "15%" }}>
          <ReactTooltip place={"right"} className="tool-tip" />
          {creds && creds.defaultConnection ? (
            <div className="chip">
              <svg
                style={{ width: "18px" }}
                className="chip-image"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                />
              </svg>
              Default
            </div>
          ) : isMisconfigured ? (
            <div className="warning" data-tip={creds.credentialType === 'SalesforceAuthCode' ? `Please select one default connection for Salesforce connector` : `Please select one default connection for ${creds.credentialType} connector`} style={{ color: "red" }}>
              <svg className="warning-image" fill="#EA5A5A" height="20" viewBox="0 0 24 24" width="20">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
              </svg>
              Action Required
            </div>
          ) : singleMisconfiguredConnectors ? (
            <div className="warning" data-tip={`Please mark this connection as a default connection.`} style={{ color: "red" }}>
              <svg className="warning-image" fill="#EA5A5A" height="20" viewBox="0 0 24 24" width="20">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
              </svg>
              Action Required
            </div>
          ) : null}
          {creds && creds.ApiAccessible ? (
            <div className="chip" style={{ marginLeft: 10 }}>
              <svg
                style={{ width: "18px" }}
                className="chip-image"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                />
              </svg>
              API accessible
            </div>
          ) : null}
        </td>
        {!providerConfig.noCheckConnectionYet && (
          <td className="cell-center" style={{ width: "6%" }}>
            <EnvCheckIcons
              checking={checking}
              result={checkResult}
              singleMisconfiguredConnectors={singleMisconfiguredConnectors} />
          </td>
        )}
      </tr>
    );
  }
}

const EnvCheckIcons = ({ result, checking, singleMisconfiguredConnectors }) => {
  return (
    <div>
      {checking ? (
        <div>
          <img alt="spinner" src={images.ajax_loader} />
        </div>
      ) : result ? (
        <span>
          {result === "200 OK" &&
            !singleMisconfiguredConnectors ? (
            <svg fill="#019644" height="24" viewBox="0 0 24 24" width="24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
            </svg>
          ) : (
            <svg fill="#EA5A5A" height="24" viewBox="0 0 24 24" width="24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
            </svg>
          )}
        </span>
      ) : (
        <span />
      )}
    </div>
  );
};

export default CredBar;
