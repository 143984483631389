import React from 'react'
import images from '../images'

const OngoingRecipeStep = ({ step, count, mod }) => {
  return (
    <div className="ongoing-recipe-step">
      {!step.completed && <img alt="spin" src={images.spinner} />}
      {!step.completed || (step.completed && step.succeeded) ? (
        <span className="ongoing-recipe-step-text"
          style={step.completed ? { left: 16 } : { fontWeight: 'bold' }}>
          <span className="ongoing-recipe-step-statement">{`${
            step.statement
          }`}</span>
          {count && (
            <span style={{ float: 'right' }}>
              {step.sortOrder + 1 + mod}/{count + mod}
            </span>
          )}
        </span>
      ) : (
        <span
          style={{ left: 16, fontWeight: 'bold', color: '#eb5959' }}
          className="ongoing-recipe-step-text">
          {step.result || 'Error'}
        </span>
      )}
    </div>
  )
}

export default OngoingRecipeStep
