import React, { Component, Fragment } from "react";

import {
  Button,
  DropdownButton,
  Dropdown,
  MenuItem,
  Tabs,
  Tab,
  Modal,
  ModalFooter,
} from "react-bootstrap";
import moment from "moment";
import YAML from "yaml";
import DataProfileFieldStyle from "../../styles/dataprofilefieldstyle";
import EditTransConsole from "./edittransConsole";
import CheckInMod from "./checkinmod";
import SaveOverwriteMod from "./saveoverwritemod";
import images from "../images";
import ExitCheckMod from "./exitCheckMod";
import DeployMod from "./deploymod";
import { debounce } from "lodash";
import Editor, { DiffEditor } from "@monaco-editor/react";
import Trigger from "./trigger";
import MonitorTransformer from "../recipe/monitortransformer";
import VersionsList from "./versionsList";
import * as indexDb from "../../db/recipesDOA";
import ReactTooltip from "react-tooltip";
import CustomTabs from "./tabs";
const { Body, Title, Header, Footer } = Modal;
import MetaExplore from "./metaExplore";
import { format } from "sql-formatter";
import { SparkSQL, cleanSql } from "dt-sql-parser";
import Loading from "../header/loading";

let skipEditWarn = true;
let version;
const parser = new SparkSQL();

export default class TransformerWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      warn: false,
      curVer: null,
      languageVersion: null,
      consoleMode: "",
    };
    this.select = this.select.bind(this);
    this.warnState = this.warnState.bind(this);
    this.changeVersionOnTop = this.changeVersionOnTop.bind(this);
    this.liftRecipeVersion = this.liftRecipeVersion.bind(this);
    this.getRecipeVersion = this.getRecipeVersion.bind(this);
    this.getLanguageVersion = this.getLanguageVersion.bind(this);
  }

  warnState() {
    this.setState({ selected: 0 });
  }

  changeVersionOnTop(v) {
    this.setState({ languageVersion: v });
  }

  getLanguageVersion() {
    const { languageVersion } = this.state;
    if (languageVersion) {
      return languageVersion;
    } else {
      return false;
    }
  }

  liftRecipeVersion(v) {
    this.setState({ curVer: v });
  }

  getRecipeVersion() {
    const { curVer } = this.state;
    if (curVer) {
      return curVer;
    } else {
      return false;
    }
  }

  select(selected, path) {
    this.setState({ selected });
    this.props.history.push(path);
  }

  render() {
    const { transformer } = this.props.redux;
    if (
      transformer &&
      transformer.versions &&
      transformer.versions[0] &&
      transformer.versions[0].Name
    ) {
      return (
        <EditTransformer
          {...this.props}
          getRecipeVersion={this.getRecipeVersion}
          liftRecipeVersion={this.liftRecipeVersion}
          getLanguageVersion={this.getLanguageVersion}
          changeVersionOnTop={this.changeVersionOnTop}
          warnState={this.warnState}
          consoleMode={this.state.consoleMode}
          languageVersion={this.state.languageVersion}
        />
      );
    } else {
      return (
        <div className="ajax-loader">
          <img alt="spinner" src={images.ajax_loader} />
        </div>
      );
    }
  }
}
const editorRef = React.createRef();
const monacoRef = React.createRef();

class EditTransformer extends Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      sqlQuery: "",
      showSqlEditor: false,
      showSaveButton: false,
      recipeAsJob: true,
      curVersion: 1,
      editoryaml: null,
      executionTab: [],
      selectedOption: "sameVersion",
      viz: false,
      lastHighlightedLine: 0,
      languageVersion: "2.0",
      saving: false,
      selectedEnvironment: null,
      selectedTemplate: "",
      availableTemplates: [],
      lastConfig: "",
      autosavebool: true,
      showcheckin: false,
      showVersions: false,
      reRender:0,
      lastSave: null,
      overwrite: false,
      showOverwrite: false,
      showExitCheck: false,
      exitWarn: false,
      editWarn: false,
      curTheme: null,
      showDeployMod: false,
      isExecuting: false,
      viewKey: "editor",
      envCreds: null,
      exploreMeta: false,
      processingType: "batch",
      lastProcessingType: "batch",
      disableToggle: false,
      showSchedule: false,
      showLog: false,
      showScroll: true,
      indexedRecipe: null,
      showExitPrompt: false,
      jobType: [{ type: "Run as Job" }, { type: "Run as Event" }],
      showConsole: false,
      consoleMode: "",
      versionOne: null,
      versionTwo: null,
      showDiff: false,
      comment: "",
      highlightSave: false,
      fullscreen: false,
      data: [],
      activeIndex: null,
      canUndo: false,
      canRedo: false,
      schemaFolded: false,
      disposeRef: null,
      showSchemaButton: false,
      showTemplateSelection: false,
    };

    this.handleRun = this.handleRun.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitOnLeave = this.handleSubmitOnLeave.bind(this);
    this.autoSave = this.autoSave.bind(this);
    this.changeVersion = this.changeVersion.bind(this);
    this.updateDPT =  this.updateDPT.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    // this.changeTheme = this.changeTheme.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleAutoSaveSwitch = this.handleAutoSaveSwitch.bind(this);
    this.handleShowCheckIn = this.handleShowCheckIn.bind(this);
    this.handleCheckIn = this.handleCheckIn.bind(this);
    this.handleCloseCheckIn = this.handleCloseCheckIn.bind(this);
    this.handleTransVersionChange = this.handleTransVersionChange.bind(this);
    this.handleFinalDeploy = this.handleFinalDeploy.bind(this);
    this.handleToggleOverwrite = this.handleToggleOverwrite.bind(this);
    this.handleToggleExit = this.handleToggleExit.bind(this);
    this.handleExit = this.handleExit.bind(this);
    this.handleOverwrite = this.handleOverwrite.bind(this);
    this.handlePullLatest = this.handlePullLatest.bind(this);
    this.updateLastSaveState = this.updateLastSaveState.bind(this);
    this.warnDeployed = this.warnDeployed.bind(this);
    this.handleDeployMod = this.handleDeployMod.bind(this);
    this.handleDeployAndVersion = this.handleDeployAndVersion.bind(this);
    this.handleDiffVerOne = this.handleDiffVerOne.bind(this);
    this.handleSQLEditorChange = this.handleSQLEditorChange.bind(this);
    this.handleSaveChangesSQL = this.handleSaveChangesSQL.bind(this);
    this.handleSQLEditorDidMount = this.handleSQLEditorDidMount.bind(this);
    this.handleSQLEditorWillMount = this.handleSQLEditorWillMount.bind(this);
    this.handleSQLEditorWillDispose =
      this.handleSQLEditorWillDispose.bind(this);
  }

  handleSQLEditorDidMount = (editor, monaco) => {
    sqlEditorRef.current = editor;
    sqlMonacoRef.current = monaco;
    let value = editor.getValue();
    this.validateSQL(value);

    monaco.languages.registerDocumentFormattingEditProvider("sql", {
      provideDocumentFormattingEdits: function (model, options) {
        const errors = parser.validate(model.getValue());
        let parseble = false;
        if (errors && errors.length == 0) {
          parseble = true;
        }

        let formatted = format(model.getValue(), {
          indent: " ".repeat(options.tabSize),
          language: "spark",
          uppercase: false,
          linesBetweenQueries: 2,
        });
        return [
          {
            range: model.getFullModelRange(),
            text: parseble ? formatted : model.getValue(),
          },
        ];
      },
    });
    // check for changes after loading data to new instance
    let searchScope = editorRef.current.getSelection();
    let selectedQuery = editorRef.current
      .getModel()
      .getValueInRange(searchScope);
    if (selectedQuery != editor.getValue()) {
      this.setState({ showSaveButton: true });
    }
  };

  handleSQLEditorWillMount = (monaco) => {
    const localstored = window.localStorage.getItem("recipeTheme");
    if (localstored) {
      import(`monaco-themes/themes/${localstored}.json`).then((data) => {
        monaco.editor.defineTheme("monokai", data);
        monaco.editor.setTheme("monokai");
      });
    }
  };

  handleSQLEditorWillDispose = (model) => {
    this.setState({ sqlQuery: "" });
    this.setState({ showSaveButton: false });
    this.setState({ showSqlEditorModal: false });
  };

  handleSQLFormat = () => {
    let editor = sqlEditorRef.current;
    let currentModel = editor.getModel();

    let text = editor.getValue();
    let range = currentModel.getFullModelRange();

    // trigger query formating with placement
    this.handleSqlQueryPlacement(
      format(text, {
        language: "spark",
        uppercase: false,
        linesBetweenQueries: 2,
      }),
      range,
      currentModel
    );
  };

  async handleSQLEditorChange(value, event) {
    // enable save
    this.setState({ showSaveButton: true });
    this.debounceValidateSQL(value);
  }

  validateSQL = () => {
    let editor = sqlEditorRef.current;
    let monaco = sqlMonacoRef.current;
    let currentModel = editor.getModel();

    const errors = parser.validate(editor.getValue());
    if (errors && errors.length > 0) {
      let markers = [];
      // Config for handling syntax error
      markers = errors.map((e, i) => {
        let marker = {};
        if (e) {
          marker["startLineNumber"] = e.startLine;
          marker["startColumn"] = e.startCol;
          marker["endLineNumber"] = e.endLine;
          marker["endColumn"] = e.endCol;
          marker["message"] = e.message;
          marker["severity"] = monaco.MarkerSeverity.Error;
          marker["key"] = i;
        }
        return marker;
      });
      monaco.editor.setModelMarkers(currentModel, "owner", markers);
    } else {
      monaco.editor.setModelMarkers(currentModel, "owner", null);
    }
  };

  debounceValidateSQL = debounce(this.validateSQL, 1000);

  findNearestParentStatement = (model, range) => {
    // finding previous Match for parent
    let iPosition = {
      column: range.startColumn,
      lineNumber: range.startLineNumber,
    };
    let updatedRange = model.findPreviousMatch(
      "(query)|(statement)",
      iPosition,
      true,
      true,
      null,
      true
    ).range;
    return updatedRange;
  };

  handleSaveChangesSQL() {
    // get current data from yaml-editor
    let searchScope = editorRef.current.getSelection();
    let selectedQuery = editorRef.current
      .getModel()
      .getValueInRange(searchScope);

    let currentModel = editorRef.current.getModel();
    let currentSelectionRange = currentModel.findMatches(
      selectedQuery,
      searchScope,
      false,
      true,
      null,
      true
    )[0].range;

    // Find parent step
    let rangeFromParentStatement = this.findNearestParentStatement(
      currentModel,
      {
        ...currentSelectionRange,
      }
    );

    // value from which parent starts
    // Ex: `- statement` - 3
    let parentOffset = rangeFromParentStatement.startColumn;

    // get complete text from parent step
    let completeStepData = currentModel.getValueInRange({
      ...currentSelectionRange,
      startColumn: 1,
      startLineNumber: rangeFromParentStatement.startLineNumber,
    });

    // Formatting complete data
    /* 
    Example:
    - statement: (dataCount) => SELECT count(*) FROM sampleFile
     
    split complete Text using `=>`:
    ["- statement: (dataCount) ", "SELECT count(*) FROM sampleFile"]
    
    */
    let splitedArray = completeStepData.split("=>");

    // 1. Place yaml part
    let textArray = [];

    // Offset values to be prefixed
    let firstOffset,
      queryOffset = 0;

    if (splitedArray[0].indexOf("query") > -1) {
      firstOffset = parentOffset > 2 ? parentOffset - 1 : 0;
      queryOffset = parentOffset + 1;

      // TODO: check key for single line of statement
      textArray[0] = " ".repeat(firstOffset) + "query: |"; // format in case of query
    } else {
      firstOffset = parentOffset > 2 ? parentOffset - 3 : 0;
      queryOffset = parentOffset + 3;

      textArray[0] = " ".repeat(firstOffset) + "- statement: |"; // format in case of statment
      textArray[1] =
        " ".repeat(parentOffset + 1) +
        splitedArray[0].match(/([(])+([a-zA-Z0-9_])+([)])/gi) +
        " =>";
    }

    // 2. SQL Query part
    let updatedQuery = sqlEditorRef.current.getValue();
    let splittedSQL = format(updatedQuery, {
      language: "spark",
      uppercase: false,
      linesBetweenQueries: 2,
    }).split("\n");

    for (var i = 0; i < splittedSQL.length; i++) {
      splittedSQL[i] = " ".repeat(queryOffset) + splittedSQL[i];
    }

    let newData = [...textArray, ...splittedSQL];
    newData = newData.join("\n");

    let newRange = {
      ...currentSelectionRange,
      startColumn: 1,
      startLineNumber: rangeFromParentStatement.startLineNumber,
    };

    this.handleSqlQueryPlacement(newData, newRange, currentModel);
    this.setState({ showSqlEditorModal: false });
    this.setState({ showSaveButton: false });
  }

  // helper to place sqlQuery into model
  handleSqlQueryPlacement = (formatedText, range, model) => {
    model.pushEditOperations(
      [],
      [
        {
          range: range,
          text: formatedText,
        },
      ]
    );
  };

  showSqlEditorModal = () => {
    let showSqlEditor = this.state.showSqlEditor;
    if (!showSqlEditor) {
      // Modal Open
      editorRef.current.focus();
      let searchScope = editorRef.current.getSelection();
      let selectedQuery;

      // validation before opening modal
      try {
        selectedQuery = editorRef.current
          .getModel()
          .getValueInRange(searchScope);

        // must contain `select` and `from` in the query
        // if (
        //   selectedQuery.toLowerCase().indexOf('select') != -1 || selectedQuery.toLowerCase().indexOf('insert') || selectedQuery.toLowerCase().indexOf('update') || selectedQuery.toLowerCase().indexOf('drop') &&
        //   selectedQuery.toLowerCase().indexOf('from') != -1
        // ) {

        // let errors = parser.validate(selectedQuery) // Check for validation error
        // if (errors && errors.length) {
        // Allowing invalid query to be pass-on to sql-editor

        // // alert(`Please select a valid query! \n"${errors[0].message}"`)
        // return
        // }
        // } else { // keywords not found
        // alert("Select Complete Query!")
        // return
        // }
      } catch (TypeError) {
        // catch typeError
      }

      this.setState({ showSaveButton: false });
      this.setState({
        sqlQuery: format(selectedQuery, {
          language: "spark",
          uppercase: false,
          linesBetweenQueries: 2,
        }),
      });
    }

    // alter values
    showSqlEditor = !showSqlEditor;
    this.setState({ showSqlEditor });
    this.setState({ showSaveButton: false });
  };

  setFullscreen() {
    let { fullscreen } = this.state;
    fullscreen = !fullscreen;

    this.setState({ fullscreen });
  }

  changeConsoleMode(e) {
    this.setState({ consoleMode: e });
  }
  async componentWillMount() {
    const { actions, redux, match } = this.props;
    const { project, transformer, main } = redux;
    const identity = main.me;
    if (identity.role === "tech-support") {
      this.setState({ showTemplateSelection: true });
    }
    let recipe = await indexDb.GetRecipeByBaseId(
      transformer.versions[0].BaseId
    );
    console.log("browser stored recipe info", recipe);
    let a = window.localStorage.getItem("notIndexed");
    window.localStorage.removeItem("notIndexed");
    if (
      (recipe &&
        recipe.length > 0 &&
        moment(recipe[0].LastModified).valueOf() <
          moment(transformer.versions[0].LastModified).valueOf()) ||
      a
    ) {
      this.setState({
        indexedRecipe: transformer.versions[0],
      });
    } else {
      this.setState({
        indexedRecipe: recipe[0],
        highlightSave: recipe && recipe[0] && recipe[0].edited,
      });
    }
    const { sessionEnvironment } = this.props.redux.environment;
    skipEditWarn = true;

    let selectedEnv;

    if (sessionEnvironment) {
      const selectedEnvironment =
        project.environments &&
        project.environments.find(
          (f) => f.environmentId === sessionEnvironment
        );
      selectedEnv = selectedEnvironment;
    } else {
      if (project.environments && project.environments.length === 1) {
        selectedEnv = project.environments[0];
      }
    }
    if (selectedEnv) {
      this.setState({ selectedEnvironment: selectedEnv });
    }

    let editorContent;
    if (transformer.versions) {
      editorContent = transformer.versions[0];
    }
    this.setState({
      curVersion: editorContent.Version,
      languageVersion: editorContent.LanguageVersion || "2.0",
      lastConfig: editorContent.Config,
      lastSave: moment(editorContent.LastModified).valueOf(),
    });

    if (!this.props.getLanguageVersion()) {
      this.props.changeVersionOnTop(
        transformer.versions[0].LanguageVersion || "2.0"
      );
    }

    if (!this.props.getRecipeVersion()) {
      this.props.liftRecipeVersion(transformer.versions[0].Version);
    }
    const { params } = match;
    const baseId = params.transId;
    const executing =
      transformer &&
      transformer.executingTransformers.includes(parseInt(baseId));
    if (executing) {
      this.changeConsoleMode("runResults");
      this.props.actions.setConsoleOpenStatus(true);
    }
    this.intervalGetRecipe = setInterval(() => {
      if (document.hasFocus()) {
      } else {
        // recipe is opened in some another Tab and not focused
        indexDb.GetRecipeByBaseId(baseId).then((recipe) => {
          if (editorRef && editorRef.current && recipe.length > 0) {
            const config = editorRef.current.getValue();
            if (config != recipe[0].Config) {
              editorRef.current.setValue(recipe[0].Config);
              this.setState({
                lastconfig: config,
                autosavebool: true,
                lastSave: recipe[0].LastModified,
              });
            }
          }
        });
      }
    }, 1000);
  }
  versionIsDeployed = () => {
    const { curVersion } = this.state;
    const { transformer } = this.props.redux;
    const thisTransformer =
      transformer.versions &&
      transformer.versions.find((ver) => ver.Version === curVersion);
    if (thisTransformer) {
      const versionIsDeployed =
        transformer &&
        transformer.deployments &&
        transformer.deployments.find((d) => {
          return d.transId === thisTransformer.Id;
        });
      return versionIsDeployed;
    } else {
      return false;
    }
  };

  checkVerIsDeployed = (curVersion) => {
    const { transformer } = this.props.redux;
    const thisTransformer =
      transformer.versions &&
      transformer.versions.find((ver) => ver.Version === curVersion);

    const versionIsDeployed =
      transformer &&
      transformer.deployments &&
      transformer.deployments.find((d) => {
        return d.transId === thisTransformer && thisTransformer.Id;
      });
    return versionIsDeployed;

    return true;
  };
  curVersionIsLatest = () => {
    const { curVersion } = this.state;
    const { transformer } = this.props.redux;

    //check if version is the latest
    const curVersionIsLatest =
      transformer.versions && transformer.versions[0].Version === curVersion;

    return curVersionIsLatest;
  };
  checkVersionIsLatest = (curVersion) => {
    const { transformer } = this.props.redux;

    //check if version is the latest
    const curVersionIsLatest =
      transformer.versions && transformer.versions[0].Version === curVersion;

    return curVersionIsLatest;
  };

  handleToggleComment() {
    const versionIsDeployed = this.versionIsDeployed();
    if (versionIsDeployed || !this.curVersionIsLatest()) {
      return;
    }
    let selectedText = editorRef.current
      .getModel()
      .getValueInRange(editorRef.current.getSelection());
    if (selectedText == "") {
      return;
    }
    // persist our current focus
    editorRef.current.focus();
    let searchScope = editorRef.current.getSelection();

    let currentModel = editorRef.current.getModel();
    let range = currentModel.findMatches(
      selectedText,
      searchScope,
      false,
      true,
      null,
      true
    )[0].range;
    // in case of incomplete selection!
    let optimizedRange = {
      startLineNumber: range.startLineNumber,
      startColumn: 1, // grab from starting of line

      endLineNumber: range.endLineNumber,
      endColumn: range.endColumn,
    };
    let newSelection = currentModel.getValueInRange(optimizedRange);
    let textArray = newSelection.split("\n");
    for (var i = 0; i < textArray.length; i++) {
      if (textArray[i].match("#")) {
        textArray[i] = textArray[i].replace("#", ""); // when comment exists
      } else if (textArray[i].length != 0) {
        // ignore empty line if any
        textArray[i] = "#" + textArray[i]; // when comment not exists
      }
    }
    let commentedValue = textArray.join("\n");
    currentModel.pushEditOperations(
      [],
      [
        {
          range: optimizedRange,
          text: commentedValue,
        },
      ]
    );
  }

  selectedEnvironmentIsDeployedToCurVer = () => {
    const { selectedEnvironment, curVersion } = this.state;
    const { transformer } = this.props.redux;

    const thisTransformer =
      transformer.versions &&
      transformer.versions.find((ver) => ver.Version === curVersion);
    if (thisTransformer && transformer && transformer.deployments) {
      const selectedEnvironmentIsDeployedToCurVer =
        selectedEnvironment &&
        transformer.deployments.find((d) => {
          return (
            d.transId === thisTransformer.Id &&
            selectedEnvironment.environmentId === d.environmentId
          );
        });
      return selectedEnvironmentIsDeployedToCurVer;
    } else {
      return false;
    }
  };

  thisTransformer = (version) => {
    const { transformer } = this.props.redux;
    const { curVersion } = this.state;

    let thisTransformer;

    if (version) {
      thisTransformer =
        transformer.versions &&
        transformer.versions.find((ver) => ver.Version === version);
    } else {
      thisTransformer =
        transformer.versions &&
        transformer.versions.find((ver) => ver.Version === curVersion);
    }

    return thisTransformer;
  };

  routerWillLeave(nextLocation) {
    const config = editorRef.current.getValue();
    const { lastConfig, exitWarn, autosavebool } = this.state;

    //if deployed, dont warn. this is temporary,
    //until we refactor saving for deployed recipes

    const selectedEnvironmentIsDeployedToCurVer =
      this.selectedEnvironmentIsDeployedToCurVer();

    //if someone else saved in the last 10 seconds,
    //this save will take priority without warning only here

    if (!selectedEnvironmentIsDeployedToCurVer && this.curVersionIsLatest()) {
      if (autosavebool) {
        if (lastConfig !== config) {
          this.handleSubmitOnLeave();
          return true;
        }
      } else {
        if (!exitWarn) {
          if (lastConfig !== config) {
            this.setState({ exitWarn: true });
            this.props.warnState();
            this.handleToggleExit();
            return false;
          } else {
            return true;
          }
        }
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    window.onunload = (event) => {
      window.localStorage.setItem("notIndexed", true);
    };

    const { actions, redux, match } = this.props;
    const { project } = redux && redux;
    const { params } = match;

    if (project && project.tenantInfo) {
      actions.getUsersForWorkspace(project.tenantInfo.TenantId, params.appId);
    }
    const { main } = redux;
    const identity = main.me;
    if (identity.role == "tech-support") {
      actions.getDataMechanicsTemplates().then((data) => {
        if (data && data.length > 0) {
          let templates = [];
          data.map((template) => {
            templates.push(template.Name);
          });
          this.setState({ availableTemplates: templates });
        }
      });
    }
    if (this._isMounted) {
      const versionIsDeployed = this.versionIsDeployed();
      if (versionIsDeployed || !this.curVersionIsLatest()) {
        this.setState({ disableToggle: true });
      } else if (!versionIsDeployed) {
        this.setState({ disableToggle: false });
      }
      const { transformer } = this.props.redux;
      this.setState({
        versionOne: transformer.versions[0],
        versionTwo:
          transformer.versions.length == 1
            ? transformer.versions[0]
            : transformer.versions[1],
      });
      window.addEventListener("beforeunload", this.beforeunload.bind(this), {
        capture: true,
      });
      //autosave every 10 seconds
      this.interval = setInterval(this.autoSave, 10000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.intervalGetRecipe);
    // this.unregisterLeaveHook();
    // monacoRef.current.editor.getModels().dispose();

    this.setState({ showExitPrompt: false });
    window.removeEventListener("beforeunload", this.beforeunload.bind(this), {
      capture: true,
    });
    this._isMounted = false;
  }
  beforeunload(e) {
    if (this.state.showExitPrompt && location.pathname.startsWith("/a")) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  handleExit() {
    this.handleToggleExit();
  }

  handleDeployMod() {
    let { showDeployMod } = this.state;

    showDeployMod = !showDeployMod;

    this.setState({ showDeployMod });
  }
  handleSchemasFold() {
    let { schemaFolded } = this.state;
    this.setState({ schemaFolded: !schemaFolded });
    if (this.state.disposeRef) {
      this.state.disposeRef();
    }

    if (schemaFolded) {
      let unfoldAll = editorRef.current.getAction("editor.unfoldAll");
      unfoldAll.run();
      editorRef.current.setScrollPosition({ scrollTop: 0 });
    } else {
      let schemaPositions = editorRef.current
        .getModel()
        .findMatches("schema:", true, false, true, null, true);
      let schemasRefPositions = editorRef.current
        .getModel()
        .findMatches("schemas:", true, false, true, null, true);
      if (schemasRefPositions && schemasRefPositions.length > 0) {
        schemaPositions.push(...schemasRefPositions);
      }
      const foldingContrib = editorRef.current.getContribution(
        "editor.contrib.folding"
      );
      if (schemaPositions && schemaPositions.length > 0) {
        foldingContrib.getFoldingModel().then((t) => {
          let st = t.regions._startIndexes;
          let et = t.regions._endIndexes;
          let folded = [];
          // folding all schemas
          schemaPositions.map((p) => {
            let schemaStartIndex = st.indexOf(p.range.startLineNumber);
            let schemaStart = p.range.startLineNumber;
            let schemaEnd = et[schemaStartIndex];
            folded.push({
              start: schemaStart,
              end: schemaEnd,
              kind: monacoRef.current.languages.FoldingRangeKind.Region,
            });
          });

          let fields = [];

          // default fold/unfold
          for (let i = 0, j = 0; i < st.length, j < et.length; i++, j++) {
            fields.push({
              start: st[i],
              end: et[j],
            });
          }
          const { dispose } =
            monacoRef.current.languages.registerFoldingRangeProvider("yaml", {
              provideFoldingRanges: function (model, context, token) {
                folded.push(...fields);
                return folded;
              },
            });
          this.setState({ disposeRef: dispose }); // to close the registered provider
          const foldRegions = editorRef.current.getAction(
            "editor.foldAllMarkerRegions"
          );
          foldRegions.run();
          editorRef.current.setScrollPosition({ scrollTop: 0 });
        });
      }
    }
  }

  // changeTheme(k, v) {
  //   this.setState({ curTheme: v });
  //   import(`monaco-themes/themes/${v}.json`).then((data) => {
  //     monaco.editor.defineTheme("monokai", data);
  //     monaco.editor.setTheme("monokai");
  //   });

  //   window.localStorage.setItem("recipeTheme", v);
  // }

  handleFinalDeploy(andVersion) {
    const config = editorRef.current.getValue();
    this.handleDeployMod();
    //don't deploy empty recipe
    if (config !== "") {
      skipEditWarn = false;
      //this.setState({ versionIsDeployed: true });
      this.handleSubmit();
      const { curVersion, selectedEnvironment, templateName } = this.state;
      const { actions, match, redux } = this.props;
      const { params } = match;
      const { transformer, project } = redux;
      const trans =
        transformer &&
        transformer.versions.find((ver) => ver.Version === curVersion);
      const env =
        project &&
        project.environments.find((e) => e.name === selectedEnvironment.name);
      const baseId = params.transId;
      actions
        .deployTransformerToEnvironment(
          project.tenantInfo.TenantId,
          params.appId,
          baseId,
          trans.Id,
          env.environmentId,
          templateName
        )
        .then(() => {
          if (andVersion) {
            //this.setState({ versionIsDeployed: false });
            this.handleSubmit(true, true);
          }

          if (this.versionIsDeployed()) {
            this.setState({ disableToggle: true });
          }
          return;
        });
    } else {
      alert("You cannot deploy an empty recipe.");
    }
  }

  // added maxDataProcTime to save it from overriding to 00:00:00
  callNewVersionTransformerNoStateSaving(config, version, id) {
    const { transformer } = this.props.redux;
    const templateName = this.state.selectedTemplate;
    const environ = transformer.versions[0].Environ;
    const name = transformer.versions[0].Name;
    const baseid = transformer.versions[0].BaseId;
    const description = transformer.versions[0].Description;
    const maxDataProcTime = transformer.versions[0].maxDataProcTime;
    const eventObj = transformer.eventContext;
    const path = eventObj.eventData.fullPath;
    const dir = (path && path.substring(0, path.lastIndexOf("/"))) || "";
    const filename = (path && path.substring(path.lastIndexOf("/") + 1)) || "";
    const languageVersion = this.state.languageVersion;
    var processingType = this.state.processingType || "batch";
    //create additional callUpdateTransformer action that doesn't get updated
    return this.props.actions.callUpdateTransformer(
      {
        // transformer data
        name,
        config,
        environ,
        version,
        baseid,
        id,
        description,
        languageVersion,
        processingType,
        templateName,
        maxDataProcTime,
      },
      {
        // triggerData
        baseid,
        directory: dir,
        action: eventObj.eventData.action,
        filename,
        eventdate: eventObj.eventData.eventDate,
        type: eventObj.eventData.type,
      },
      this.props.match.params.appId,
      this.props.match.params.transId
    );
  }

  // added maxDataProcTime to save it from overriding to 00:00:00
  callNewVersionTransformer(config, version, id, saveComment) {
    this.setState({ saving: true });
    const { transformer } = this.props.redux;
    const templateName = this.state.selectedTemplate;
    const environ = transformer.versions[0].Environ;
    const name = transformer.versions[0].Name;
    const baseid = transformer.versions[0].BaseId;
    const description = transformer.versions[0].Description;
    const maxDataProcTime = transformer.versions[0].maxDataProcTime;
    const eventObj = transformer.eventContext;
    const path = eventObj.eventData.fullPath;
    const dir = (path && path.substring(0, path.lastIndexOf("/"))) || "";
    const filename = (path && path.substring(path.lastIndexOf("/") + 1)) || "";
    const languageVersion = this.state.languageVersion;
    let p = transformer.versions[0].processingType;
    var processingType = p || "batch";
    let comment;
    if (saveComment) comment = this.state.comment;
    return this.props.actions
      .callUpdateTransformer(
        {
          // transformer data
          name,
          config,
          environ,
          version,
          baseid,
          id,
          description,
          languageVersion,
          processingType,
          comment,
          templateName,
          maxDataProcTime,
        },
        {
          // triggerData
          baseid,
          directory: dir,
          action: eventObj.eventData.action,
          filename,
          eventdate: eventObj.eventData.eventDate,
          type: eventObj.eventData.type,
        },
        this.props.match.params.appId,
        this.props.match.params.transId
      )
      .then((trans) => {
        this.setState({ saving: false, curVersion: version, comment: "" });
        indexDb.UpdateRecipeEditflag(baseid, trans.LastModified).then(() => {
          this.props.showUnsaved(transformer.versions[0]);
        });
      })
      .then(() => {
        this.updateLastSaveState(version);
        return true;
      });
  }

  registerYamlFolder() {
    //modified from fold/markdown
    window.CodeMirror.registerHelper("fold", "yaml", function (cm, start) {
      var maxDepth = 100;

      /*function isHeader(lineNo) {
        var tokentype = cm.getTokenTypeAt(window.CodeMirror.Pos(lineNo, 0))
        return tokentype && /\bmeta\b/.test(tokentype)
      }*/

      function headerLevel(lineNo, line, nextLine) {
        var match = line && line.match(/^-+/);
        if (match) return match[0].length;
        //match = nextLine && nextLine.match(/^[=\-]+\s*$/);
        //if (match && isHeader(lineNo + 1)) return nextLine[0] == "=" ? 1 : 2;
        return maxDepth;
      }

      var firstLine = cm.getLine(start.line),
        nextLine = cm.getLine(start.line + 1);
      var level = headerLevel(start.line, firstLine, nextLine);
      if (level === maxDepth) return undefined;

      var lastLineNo = cm.lastLine();
      var end = start.line,
        nextNextLine = cm.getLine(end + 2);
      while (end < lastLineNo) {
        if (headerLevel(end + 1, nextLine, nextNextLine) <= level) break;
        ++end;
        nextLine = nextNextLine;
        nextNextLine = cm.getLine(end + 2);
      }

      return {
        from: window.CodeMirror.Pos(start.line, firstLine.length),
        to: window.CodeMirror.Pos(end, cm.getLine(end).length),
      };
    });
  }

  handleDeployAndVersion() {
    this.handleFinalDeploy(true);
  }

  warnDeployed(reload) {
    if (skipEditWarn) {
      skipEditWarn = false;
      return false;
    }

    if (this.versionIsDeployed() || !this.curVersionIsLatest()) {
      return true;
    }

    return false;
  }

  // setEditorYaml(reload, theme) {
  //   const config = this.config;
  //   let editoryaml = this.state.editoryaml;
  //   const { transformer } = this.props.redux;
  //   const persistContent = editoryaml && this.state.editoryaml.getValue();

  //   if (reload) {
  //     editoryaml.toTextArea();
  //     skipEditWarn = true;
  //   }

  //   if (editoryaml == null || reload) {
  //     editoryaml = window.CodeMirror.fromTextArea(config, {
  //       mode: "yaml",
  //       theme: theme ? theme : this.state.curTheme,
  //       lineNumbers: true,
  //       readOnly: false,
  //       tabSize: 2,
  //       indentUnit: 2,
  //       indentWithTabs: false,
  //       viewportMargin: Infinity,
  //       foldGutter: true,
  //       gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
  //     });
  //     this.registerYamlFolder();

  //     editoryaml.setSize(null, "100%");

  //     let _appendSpace = false,
  //       _isLeading = true,
  //       _isTrailing = false,
  //       _isEmptyLine = false,
  //       _trailingOffset = null;
  //     editoryaml.addOverlay(
  //       {
  //         name: "invisibles",
  //         token: function (stream, state) {
  //           let ch,
  //             trailing,
  //             ateCode = false,
  //             tokenStyle = "";

  //           // Start of line: reset state
  //           if (stream.sol()) {
  //             _isLeading = true;
  //             _isTrailing = false;
  //             _isEmptyLine = false;

  //             _trailingOffset = stream.string.length;
  //             trailing = stream.string.match(/[ \t\u00A0]+$/);
  //             if (trailing) {
  //               _trailingOffset -= trailing[0].length;
  //               // Everything is whitespace
  //               if (_trailingOffset === 0) {
  //                 _isEmptyLine = true;
  //               }
  //             }
  //           }

  //           // Peek ahead one character at a time
  //           // Wrapping the assignment in a Boolean makes JSLint happy
  //           while (Boolean((ch = stream.peek()))) {
  //             if (ch === " " || ch === "\t" || ch === "\xA0") {
  //               if (ateCode) {
  //                 // Return now to mark all code seen so far as not necessary to highlight
  //                 return null;
  //               }
  //               // Eat the whitespace
  //               stream.next();

  //               // Test if this is a trailing whitespace
  //               if (!_isLeading && !_isTrailing) {
  //                 _isTrailing = stream.pos >= _trailingOffset;
  //               }

  //               // CodeMirror merges consecutive tokens with the same style
  //               // There's a setting called "flattenSpans" to prevent that, but it's for the whole editor*
  //               // So instead we simply append a space character to the style every other time
  //               // This disables CodeMirror's string comparison while having no effect on the CSS class
  //               // *changed in https://github.com/marijnh/CodeMirror/commit/221a1e4070d503f4597f7823e4f2cf68ba884cdf
  //               _appendSpace = !_appendSpace;

  //               tokenStyle += "whitespace-";
  //               tokenStyle += _isEmptyLine
  //                 ? "empty-line-"
  //                 : _isLeading
  //                 ? "leading-"
  //                 : _isTrailing
  //                 ? "trailing-"
  //                 : "";
  //               tokenStyle +=
  //                 ch === " " ? "space" : ch === "\xA0" ? "nonbrk-space" : "tab";
  //               tokenStyle += _appendSpace ? " " : "";

  //               return tokenStyle;
  //             } else {
  //               stream.next();
  //               ateCode = true;
  //               _isLeading = false;
  //             }
  //           }
  //           return null;
  //         },
  //       }
  //       // {
  //       //   opaque: false,
  //       //   priority: 1
  //       // }
  //     );

  //     editoryaml.addKeyMap({
  //       Tab: (cm) => {
  //         if (cm.somethingSelected()) {
  //           const sel = editoryaml.getSelection("\n");
  //           // Indent only if there are multiple lines selected, or if the selection spans a full line
  //           if (
  //             sel.length > 0 &&
  //             (sel.indexOf("\n") > -1 ||
  //               sel.length === cm.getLine(cm.getCursor().line).length)
  //           ) {
  //             cm.indentSelection("add");
  //             return;
  //           }
  //         }

  //         if (cm.options.indentWithTabs) cm.execCommand("insertTab");
  //         else cm.execCommand("insertSoftTab");
  //       },
  //       "Shift-Tab": (cm) => cm.indentSelection("subtract"),
  //     });

  //     editoryaml.on("beforeChange", (cm, change) => {
  //       if (change.origin === "undo" && cm.doc.isClean()) {
  //         change.cancel();
  //       }
  //       if (this.warnDeployed(reload)) {
  //         change.cancel();
  //       }
  //     });

  //     editoryaml.on("change", (cm) => {
  //       this.clearValidation();
  //     });
  //   } // end first time only

  //   let editorContent;

  //   if (this.props.getRecipeVersion()) {
  //     const trans =
  //       transformer &&
  //       transformer.versions.find(
  //         (ver) => ver.Version === this.props.getRecipeVersion()
  //       );

  //     editorContent = trans && trans.Config;
  //   } else {
  //     editorContent = transformer.versions[0].Config;
  //   }

  //   if (reload) {
  //     editoryaml.getDoc().setValue(persistContent);
  //     this.setState({ editoryaml });
  //   } else {
  //     editoryaml.getDoc().setValue(editorContent);
  //     this.setState({ editoryaml });
  //   }
  // }

  clearValidation = () => {
    if (this.state.parsingErrors) {
      this.setState({ parsingErrors: null });
    }
    if (this.errorMark) {
      this.errorMark.clear();
    }
  };

  validateYaml = (value) => {
    const doc = YAML.parseDocument(editorRef.current.getValue());
    const models = monacoRef.current.editor.getModels();
    const thisTransformer = this.thisTransformer();
    let model = models.find((m) =>
      m._associatedResource.path.includes(thisTransformer.BaseId)
    );
    if (doc && doc.errors.length > 0) {
      let markers = [];
      markers = doc.errors.map((e, i) => {
        let marker = {};
        if (e.source.rangeAsLinePos) {
          marker["startLineNumber"] = e.source.rangeAsLinePos.start.line;
          marker["startColumn"] = e.source.rangeAsLinePos.start.col;
          marker["endLineNumber"] = e.source.rangeAsLinePos.end.line;
          marker["endColumn"] = e.source.rangeAsLinePos.end.col;
          marker["message"] = e.message;
          marker["severity"] = monacoRef.current.MarkerSeverity.Error;
          marker["key"] = i;
        }
        return marker;
      });
      monacoRef.current.editor.setModelMarkers(model, "owner", markers);
    } else {
      monacoRef.current.editor.setModelMarkers(model, "owner", null);
    }
  };

  debounceValidateYaml = debounce(this.validateYaml, 1000);

  // updateYaml = (yaml) => {
  //   const { editoryaml } = this.state;
  //   editoryaml.getDoc().setValue(yaml);
  //   this.setState({ editoryaml });
  // };

  autoSave() {
    const { actions, match } = this.props;
    const { params } = match;
    let notDeployed = true;
    if (this.selectedEnvironmentIsDeployedToCurVer()) {
      notDeployed = false;
    }
    if (this.state.autosavebool && notDeployed && this.curVersionIsLatest()) {
      const lastconfig = this.state.lastConfig;
      const processingtype = this.state.processingType;
      const config = editorRef.current && editorRef.current.getValue();
      const { transformer } = this.props.redux;
      actions.showAppExitPrompt(false); // disable exit prompt
      if (config !== "" && lastconfig !== config) {
        this.setState({
          lastConfig: config,
          saving: true,
          lastProcessingType: processingtype,
        });

        actions.callGetTransformerVersions(params.transId).then((data) => {
          const { lastSave } = this.state;
          if (this.selectedEnvironmentIsDeployedToCurVer()) {
            this.setState({ autosavebool: false, saving: false });
          }
          if (
            lastSave &&
            moment(data.LastModified).valueOf() > lastSave &&
            document.hasFocus()
          ) {
            this.setState({
              showOverwrite: true,
              autosavebool: false,
              saving: false,
            });
          } else {
            if (!this.selectedEnvironmentIsDeployedToCurVer()) {
              // Get latest version
              if (config) {
                const ver = this.state.curVersion;
                const newid = transformer.versions[0].Id;
                this.callNewVersionTransformer(config, ver, newid).then(() => {
                  this.updateLastSaveState(ver);
                });
              }
            }
          }
        });
      }
    }
  }

  updateLastSaveState(version) {
    let thisTransformer;

    if (version) {
      thisTransformer = this.thisTransformer(version);
    } else {
      thisTransformer = this.thisTransformer();
    }
    const lastSave = moment(thisTransformer.LastModified).valueOf();

    this.props.liftRecipeVersion(version);
    this.setState({ lastSave });
  }

  handleSubmitOnLeave() {
    const config = editorRef.current.getValue();
    const { transformer } = this.props.redux;
    if (
      transformer &&
      !transformer.executingTransformers.includes(
        parseInt(this.props.match.params.transId)
      )
    ) {
      if (config) {
        const ver = this.state.curVersion;
        const newid = transformer.versions[0].Id;
        this.callNewVersionTransformerNoStateSaving(config, ver, newid);
      }
    }
  }
  showSchedulesModal = () => {
    let showSchedule = this.state.showSchedule;
    showSchedule = !showSchedule;
    this.setState({ showSchedule });
  };
  handleUndo() {
    try {
      editorRef.current.getModel().undo();
      let str1 = editorRef.current && editorRef.current.getValue();
      let str2 = this.props.redux.transformer.versions[0].Config;
      if (str1) {
        let n = str1.localeCompare(str2);
        if (n == 0) {
          this.setState({
            canUndo: false,
            canRedo: true,
            highlightSave: false,
          });
        }
      }
    } catch (e) {}
  }

  handleRedo() {
    try {
      editorRef.current.getModel().redo();
    } catch (e) {}
  }

  // persist the recipe to db
  handleSubmit(newVer, overwrite) {
    this.setState({ savingFile: true });
    const { actions, match } = this.props;
    const { params } = match;
    const config = editorRef.current.getValue();
    const lastconfig = this.state.lastConfig;
    if (config !== null && config !== "") {
      this.setState({ saving: true });
    }

    actions.showAppExitPrompt(false); // disable exit prompt
    actions.callGetTransformerVersions(params.transId).then((data) => {
      this.setState({
        savingFile: false,
        highlightSave: false,
        canUndo: false,
        canRedo: false,
      });
      const { transformer } = this.props.redux;
      const { lastSave } = this.state;
      if (
        moment(data.LastModified).valueOf() > lastSave &&
        lastSave &&
        !overwrite
      ) {
        this.setState({
          showOverwrite: true,
          saving: false,
          autosavebool: false,
        });
      } else {
        if (lastconfig !== config && config !== "") {
          this.setState({ lastConfig: config });
        }
        if (config) {
          let ver = this.state.curVersion;
          let newid = transformer.versions[0].Id;
          if (newVer && newVer === true) {
            this.callNewVersionTransformer(config, ver, newid, true).then(
              () => {
                const verarray = transformer.versions.length + 1;
                ver = Math.trunc(verarray);
                newid = 0;
                this.callNewVersionTransformer(config, ver, newid).then(() =>
                  this.updateLastSaveState(ver)
                );
              }
            );
          } else {
            this.callNewVersionTransformer(config, ver, newid).then(() =>
              this.updateLastSaveState(ver)
            );
          }
        } else {
          alert("You cannot save an empty recipe.");
        }
      }
    });
  }

  startRecipeDirect = () => {
    const { selectedEnvironment, selectedTemplate } = this.state;
    if (!selectedEnvironment) {
      alert("Please select an environment");
      return;
    }
    let a = this.state.data.find((d) => d.tab == "Recipe Running...");
    if (!a) {
      this.handleSubmit();
      const { config, project, transformer } = this.props.redux;
      let data = {
        action: "create",
        fullPath: `/${config.FTP_DIR}${project.tenantInfo.Key}/${project.appData.uniquekey}/sample.zip`,
        eventDate: new Date().toISOString(),
        type: "file",
        ...(selectedEnvironment && {
          environmentName: selectedEnvironment.name,
        }),
        ...(selectedEnvironment && {
          environmentId: String(selectedEnvironment.environmentId),
        }),
      };
      if (selectedTemplate != "") {
        data["templateName"] = selectedTemplate;
      }
      this.startRecipe(
        JSON.stringify(data),
        selectedEnvironment,
        this.thisTransformer()
      );
    } else {
      null;
    }
  };

  handleTabClosable(runTab) {
    let closeable = false;
    this.setState({
      data: this.state.data.map((el) =>
        el.id === runTab.id ? { ...el, closeable } : el
      ),
    });
  }
  startRecipe = (inp, env, thisTrans) => {
    let eventData;
    let jsonError = false;
    const { transformer } = this.props.redux;
    const { selectedEnvironment } = this.state;

    //make sure the json is good
    const id = new Date().valueOf();
    let b = this.state.data.find((d) => d.tab == "Event Context");

    let resultsTab = this.state.data.find((d) => d.tab == "Results");
    let newData = [...this.state.data];
    if (resultsTab && resultsTab.id) {
      //remove results tab if present
      newData = this.state.data.filter((item) => item.id !== resultsTab.id);
    }
    if (b && b.id) {
      //replace event context tab with recipe running tab
      newData = this.state.data.filter((item) => item.id !== b.id);
    }
    try {
      eventData = JSON.parse(inp);
    } catch (error) {
      jsonError = error;
    }

    if (jsonError) {
      alert(
        `There's an issue with your Event Context JSON formatting.\n\n${jsonError}`
      );
    }
    //if json is good, go ahead
    else {
      this.props.actions
        .clearOngoingExecutionData(parseInt(this.props.match.params.transId))
        .then(() => {
          this.props.actions.setExecutingTransformer(
            parseInt(this.props.match.params.transId),
            true
          );
          this.setState({
            recipeAsJob: true,
            showConsole: true,
            consoleMode: "runResults",
            isExecuting: true,
            data: newData.concat({
              tab: "Recipe Running...",
              component: (
                <EditTransConsole
                  {...this.props}
                  recipeAsJob={true}
                  changeConsoleMode={this.changeConsoleMode}
                  setExecutingFlag={this.setExecutingFlag}
                  mode={"runResults"}
                  handleCancel={this.handleCancel}
                  transformer={transformer.versions[0]}
                  env={selectedEnvironment}
                  handleRecipeComplete={() =>
                    this.setState({ recipeAsJob: false })
                  }
                  ongoingExecutionLogs={transformer.ongoingExecutionLogs}
                />
              ),
              id: id,
              closeable: false,
            }),
            activeIndex: newData.length,
          });
          const config = editorRef.current.getValue();
          if (!this.versionIsDeployed()) {
            this.callNewVersionTransformer(
              config,
              this.state.curVersion,
              thisTrans.Id
            );
          }
          const event = JSON.parse(inp);
          event.environmentName = env.name;
          event.environmentId = String(env.environmentId);
          this.props.actions
            .callPostEventContext(
              { eventData: event },
              this.props.match.params.transId
            )
            .then(() => {
              this.executeRecipe(inp, thisTrans);
            });
        });
    }
  };

  executeRecipe(inp, thisTrans) {
    const startedAt = new Date();
    const { project } = this.props.redux;
    const newQuery = this.createNewQuery(inp, thisTrans.Id);
    const { actions } = this.props;

    actions.callExecuteTransformer(
      project.tenantInfo.TenantId,
      thisTrans.Id,
      newQuery.query,
      newQuery.externalExecutionId,
      startedAt, // for calculation how long exec took.
      parseInt(this.props.match.params.transId)
    );
  }

  createNewQuery(eventData, transId) {
    let query = "";
    let eventObj = JSON.parse(eventData);
    const d = new Date();
    const milliSeconds = d.getTime();
    if (eventObj && eventObj.data) {
      eventObj = eventObj.data;
    }
    const externalExecutionId = "" + transId + milliSeconds;
    query += `action=${eventObj.action}\\&`;
    query += `path=${eventObj.fullPath}\\&`;
    query += `at=${eventObj.eventDate}\\&`;
    query += `type=${eventObj.type}\\&`;
    query += `externalExecutionId=${externalExecutionId}`;
    return { query, externalExecutionId };
  }

  handleRun() {
    let eventContextTab = this.state.data.find((d) => d.tab == "Event Context");
    if (eventContextTab && eventContextTab.id) {
      let newData = this.state.data.filter(
        (item) => item.id !== eventContextTab.id
      );
      this.setState({
        data: newData,
        activeIndex: newData.length - 1,
      });
      return;
    }
    const { selectedEnvironment } = this.state;
    const { transformer } = this.props.redux;
    if (!selectedEnvironment) {
      alert("Please select an environment");
      return;
    }
    this.handleSubmit(); // save a recipe
    const thisTransformer = this.thisTransformer();

    const config = editorRef.current.getValue();
    //don't deploy empty recipe
    if (config !== "") {
      this.props.actions.setConsoleOpenStatus(true);
      const id = new Date().valueOf();
      this.setState({
        recipeAsJob: false,
        showConsole: true,
        consoleMode: "eventContext",
        data: this.state.data.concat({
          tab: "Event Context",
          component: (
            <EditTransConsole
              {...this.props}
              recipeAsJob={false}
              changeConsoleMode={this.changeConsoleMode}
              setExecutingFlag={this.setExecutingFlag}
              startRecipe={(a, b) => this.startRecipe(a, b, thisTransformer)}
              mode="eventContext"
              handleCancel={this.handleCancel}
              transformer={transformer.versions[0]}
              env={selectedEnvironment}
              handleRecipeComplete={() => this.setState({ recipeAsJob: false })}
            />
          ),
          id: id,
          closeable: true,
        }),
        activeIndex: this.state.data.length,
      });
    } else {
      alert("You cannot run an empty recipe.");
    }
  }

  handleOptionChange(changeEvent) {
    if (changeEvent.target.value === "newVersion") {
      this.setState({
        selectedOption: changeEvent.target.value,
      });
    } else {
      this.setState({
        selectedOption: changeEvent.target.value,
      });
    }
  }

  handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    monacoRef.current = monaco;

    editor.onDidChangeCursorSelection((e) => {
      let selectedText = editorRef.current
        .getModel()
        .getValueInRange(editorRef.current.getSelection());

      // check if text is selected
      if (selectedText != "") {
        this.setState({ querySelected: true });
      } else {
        this.setState({ querySelected: false });
      }
    });

    let schemaPositions = editor
      .getModel()
      .findMatches("schema:", true, false, true, null, true);
    let schemasRefPositions = editor
      .getModel()
      .findMatches("schemas:", true, false, true, null, true);
    if (
      (schemaPositions && schemaPositions.length > 0) ||
      (schemasRefPositions && schemasRefPositions.length > 0)
    ) {
      this.setState({ showSchemaButton: true });
    } else {
      this.setState({ showSchemaButton: false });
    }

    this.validateYaml(null);
    monaco.languages.registerDocumentFormattingEditProvider("yaml", {
      provideDocumentFormattingEdits: function (model, options, token) {
        const doc = YAML.parseDocument(model.getValue());
        let parseble = false;
        if (doc && doc.errors.length == 0) {
          parseble = true;
        }
        return [
          {
            range: model.getFullModelRange(),
            text: parseble
              ? YAML.stringify(YAML.parse(model.getValue(), options))
              : model.getValue(),
          },
        ];
      },
    });
  };
  async handleEditorChange(value, event) {
    let undo = editorRef.current.getModel().canUndo();
    let redo = editorRef.current.getModel().canRedo();
    if (this.state.canUndo != undo) {
      this.setState({ canUndo: undo });
    }
    if (this.state.canRedo != redo) {
      this.setState({ canRedo: redo });
    }

    const config = editorRef.current.getValue();
    const lastconfig = this.state.lastConfig;
    if (config != lastconfig) {
      // exitPrompt Only visible if changes are not saved!
      this.setState({ showExitPrompt: true });
      this.props.actions.showAppExitPrompt(true);
    }

    this.debounceValidateYaml(value);
    let cur;
    if (version != null) {
      cur = this.checkVersionIsLatest(version);
    } else {
      cur = this.curVersionIsLatest();
    }
    if (cur) {
      if (this.props.redux.transformer.versions[0] && version != null) {
        let n = value.localeCompare(
          this.props.redux.transformer.versions[0].Config
        ); //dont update index db
        if (n == 0) {
          return;
        }
      }
      const { indexedRecipe } = this.state;
      this.setState({ highlightSave: true });
      if (indexedRecipe && indexedRecipe.id) {
        indexDb.UpdateRecipeConfig(indexedRecipe.id, value).then((data) => {
          if (data && data[0]) this.props.showUnsaved(data[0]);
        });
      } else {
        let recipe = await indexDb.GetRecipeByBaseId(
          this.props.redux.transformer.versions[0].BaseId
        );
        indexDb.UpdateRecipeConfig(recipe[0].id, value).then((data) => {
          if (data && data[0]) this.props.showUnsaved(data[0]);
        });
      }
    }
  }
  handleEditorWillMount = (monaco) => {
    const localstored = window.localStorage.getItem("recipeTheme");
    if (localstored) {
      import(`monaco-themes/themes/${localstored}.json`).then((data) => {
        monaco.editor.defineTheme("monokai", data);
        monaco.editor.setTheme("monokai");
      });
    }
  };

  handleCheckIn(deployAndVer) {
    //this.setState({ versionIsDeployed: false });
    this.handleSubmit(true);
    if (!deployAndVer) {
      this.handleCloseCheckIn();
    }
    if (!this.curVersionIsLatest()) {
      this.setState({ disableToggle: true });
    } else {
      this.setState({ disableToggle: false });
    }
  }

  handleAutoSaveSwitch() {
    let autosavebool = this.state.autosavebool;
    autosavebool = !autosavebool;
    this.setState({ autosavebool });
  }

  handleShowCheckIn() {
    this.setState({ showcheckin: true, editWarn: false, comment: "" });
  }
  handleShowVersions() {
    this.setState((prevState) => ({
      reRender: prevState.reRender + 1,
      showVersions: true,
      editWarn: false
    }));
  }

  handleTemplateChange(e) {
    this.setState({ selectedTemplate: e });
  }

  handleCloseCheckIn() {
    this.setState({ showcheckin: false });
  }

  handleToggleOverwrite() {
    let showOverwrite = this.state.showOverwrite;
    showOverwrite = !showOverwrite;
    this.setState({ showOverwrite });
  }

  handleToggleExit() {
    let showExitCheck = this.state.showExitCheck;
    showExitCheck = !showExitCheck;
    this.setState({ showExitCheck });
  }

  handleOverwrite() {
    this.setState({ overwrite: true, showOverwrite: false });
    this.handleSubmit(null, true);
  }

  handlePullLatest() {
    const { actions, match } = this.props;
    const { params } = match;
    this.setState({
      overwrite: true,
      showOverwrite: false,
      autosavebool: true,
    });
    actions.callGetTransformerVersions(params.transId).then((trans) => {
      this.setState({
        indexedRecipe: trans,
        lastConfig: trans.Config,
        lastSave: moment(trans.LastModified).valueOf(),
      });
    });
  }

  handleTransVersionChange() {
    let { languageVersion } = this.state;

    if (languageVersion === "2.0") {
      languageVersion = "3.0";
    } else {
      languageVersion = "2.0";
    }

    this.setState({ languageVersion });
    this.props.changeVersionOnTop(languageVersion);
  }

  handleCancel(close) {
    // close bool is difference between close and minimize
    if (close) {
      this.setState({ showConsole: false });
      this.props.actions.setConsoleOpenStatus(false);
      window.setTimeout(() => {
        this.props.actions.clearExecutionPlan(this.props.match.params.transId);
        this.changeConsoleMode(null);
      }, 200);
      this.props.actions.setExecutingTransformer(
        parseInt(this.props.match.params.transId),
        false
      );
    } else {
      this.changeConsoleMode("minimize");
    }
  }

  reopenConsole() {
    this.changeConsoleMode("minimize");
  }

  async updateDPT(vId, data){
      const ok = confirm(`You are setting to new max-data-proc-time value for [${vId}] - ${data.thresholdHours} hours and ${data.thresholdMinutes} minutes, Confirm?`)
      if (ok){
        console.log("Updating DPT")
        this.props.actions.callUpdateTransformerDPT(
            {
              thresholdHours: data.thresholdHours,
              thresholdMinutes: data.thresholdMinutes
            },
            this.props.match.params.appId,
            this.props.match.params.transId,
            vId
        ).then(() => {
          console.log("Updated DPT")
        })
      }
      this.setState({ showVersions: false });
  }

  async changeVersion(v) {
    const { transformer } = this.props.redux;
    if (this.checkVerIsDeployed(v) || !this.checkVersionIsLatest(v)) {
      this.setState({ disableToggle: true, showVersions: false });
    } else {
      this.setState({ disableToggle: false, showVersions: false });
    }
    skipEditWarn = true;
    version = v;

    const trans =
      transformer && transformer.versions.find((ver) => ver.Version === v);
    const curVersionIsLatest =
      transformer.versions && transformer.versions[0].Version === trans.Version;

    if (curVersionIsLatest) {
      let recipe = await indexDb.GetRecipeByBaseId(
        transformer.versions[0].BaseId
      );
      editorRef.current.setValue(recipe[0].Config);
    } else {
      // monacoRef.current.Uri.parse(v);
      editorRef.current.setValue(trans.Config);
    }
    if (trans) {
      this.setState({
        curVersion: v,
        editWarn: true,
        lastConfig: trans.Config,
        saving: false,
        languageVersion: trans.LanguageVersion || "2.0",
      });
      const payloadForTabs = trans.LanguageVersion || "2.0";

      this.props.changeVersionOnTop(payloadForTabs);
      this.props.liftRecipeVersion(v);
      this.updateLastSaveState(v);
    }
  }

  setExecutingFlag = (isExecuting) => {
    this.setState({ isExecuting });
  };

  getColorByBgColor(bgColor) {
    if (!bgColor) {
      return "";
    }
    return parseInt(bgColor.replace("#", ""), 16) > 0xffffff / 2
      ? "#000"
      : "#fff";
  }

  swapJobType = () => {
    let data = [...this.state.jobType];
    let temp = data[0];
    data[0] = data[1];
    data[1] = temp;
    this.setState({ jobType: data });
  };

  getEnvCreds = (env) => {
    const { actions, redux, match } = this.props;
    const { params } = match;
    const { project } = redux;
    const skipRedux = true; // dont put creds in reducer
    if (env && env.name && project && project.tenantInfo) {
      actions
        .getAllCredentialsForEnvironment(
          project.tenantInfo.TenantId,
          params.appId,
          env.name,
          skipRedux
        )
        .then((envCreds) => {
          this.setState({
            envCreds: envCreds.filter(
              (c) => c.credentialType !== "AdapterSecret"
            ),
          });
        });
    }
  };

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedEnvironment } = this.state;
    const { sessionEnvironment } = this.props.redux.environment;
    const { project, transformer } = this.props.redux;
    if (prevProps.exploreMeta != this.props.exploreMeta) {
      let metadataTab = this.state.data.find(
        (d) => d.tab == "Metadata Explorer"
      );
      if (metadataTab && metadataTab.id) {
        let newData = this.state.data.filter(
          (item) => item.id !== metadataTab.id
        );
        this.setState({
          data: newData,
          activeIndex: newData.length - 1,
        });
      } else {
        const id = new Date().valueOf();
        this.setState({
          data: this.state.data.concat({
            tab: "Metadata Explorer",
            component: (
              <MetaExplore
                visible={this.props.exploreMeta}
                redux={this.props.redux}
                envCreds={this.state.envCreds}
                actions={this.props.actions}
                selectedEnvironment={selectedEnvironment}
                close={() => this.setState({ exploreMeta: false })}
              />
            ),
            id: id,
            closeable: true,
          }),
          activeIndex: this.state.data.length,
        });
      }
    }
    let runTab = this.state.data.find((d) => d.tab == "Recipe Running...");
    let resultsTab = this.state.data.find((d) => d.tab == "Results");
    let executionLogTab = this.state.data.find((d) => d.tab == "Execution Log");
    if (!executionLogTab && this.state.showLog) {
      this.setState({ showLog: false });
    }
    if (resultsTab && resultsTab.id && transformer.executionCompleted) {
      let index = this.state.data.findIndex((d) => d.tab === "Results");
      if (this.state.activeIndex != index)
        this.setState({ activeIndex: index });
    }
    if (runTab && runTab.id && transformer.executionCompleted) {
      if (resultsTab && resultsTab.id) {
      } else {
        let tab = "Results";
        let closeable = true;
        let newData = this.state.data.map((el) =>
          el.id === runTab.id ? { ...el, tab, closeable } : el
        );
        this.setState({
          data: newData,
        });
      }
    }

    if (
      transformer &&
      transformer.executingTransformers &&
      transformer.executingTransformers.length > 1
    ) {
      let runTab = this.state.data.find((d) => d.tab == "Recipe Running...");
      if ((runTab && runTab.id) || (resultsTab && resultsTab.id)) {
      } else {
        const id = new Date().valueOf();
        this.setState({
          recipeAsJob: true,
          showConsole: true,
          consoleMode: "runResults",
          isExecuting: true,
          data: this.state.data.concat({
            tab: "Recipe Running...",
            component: (
              <EditTransConsole
                {...this.props}
                recipeAsJob={true}
                changeConsoleMode={this.changeConsoleMode}
                setExecutingFlag={this.setExecutingFlag}
                mode={"runResults"}
                handleCancel={this.handleCancel}
                transformer={transformer.versions[0]}
                env={selectedEnvironment}
                handleRecipeComplete={() =>
                  this.setState({ recipeAsJob: false })
                }
                ongoingExecutionLogs={transformer.ongoingExecutionLogs}
              />
            ),
            id: id,
            closeable: false,
          }),
          activeIndex: this.state.data.length,
        });
      }
    }
    if (this.state.data && this.state.data.length > 0) {
      // to rerender ongoing execution logs component
      if (
        prevProps.redux.transformer.ongoingExecutionLogs !=
          transformer.ongoingExecutionLogs ||
        prevProps.redux.transformer.executionErrorMessage !=
          transformer.executionErrorMessage ||
        prevProps.redux.transformer.executionCompleted !=
          transformer.executionCompleted ||
        prevProps.redux.transformer.disableTerminate !=
          transformer.disableTerminate
      ) {
        let index = this.state.data.findIndex(
          (d) => d.tab === "Recipe Running..."
        );
        if (runTab && runTab.id) {
          let component = (
            <EditTransConsole
              {...this.props}
              recipeAsJob={this.state.recipeAsJob}
              changeConsoleMode={this.changeConsoleMode}
              setExecutingFlag={this.setExecutingFlag}
              mode={"runResults"}
              handleCancel={this.handleCancel}
              transformer={transformer.versions[0]}
              env={selectedEnvironment}
              handleRecipeComplete={() => this.setState({ recipeAsJob: false })}
              ongoingExecutionLogs={transformer.ongoingExecutionLogs}
            />
          );
          this.setState({
            data: this.state.data.map((el) =>
              el.id === runTab.id ? { ...el, component } : el
            ),
          });
          if (this.state.activeIndex != index) {
            this.setState({ activeIndex: index });
          }
        }
      } else if (
        // to rerender execution logs / history component
        prevProps.redux.transformer.monitorLogs[0] !=
          transformer.monitorLogs[0] ||
        prevProps.redux.transformer.selectedTransformerLogs !=
          transformer.selectedTransformerLogs
      ) {
        if (executionLogTab && executionLogTab.id) {
          let index = this.state.data.findIndex(
            (d) => d.tab === "Execution Log"
          );
          let component = (
            <MonitorTransformer
              {...this.props}
              showLastRecipeLog={true}
              recipeId={this.props.match.params.transId}
            />
          );
          let i;
          this.setState({
            data: this.state.data.map((el) =>
              el.id === executionLogTab.id ? { ...el, component } : el
            ),
            activeIndex: index,
          });
        }
      } else if (
        prevState.selectedEnvironment != this.state.selectedEnvironment
      ) {
        let metadataTab = this.state.data.find(
          (d) => d.tab == "Metadata Explorer"
        );
        if (metadataTab && metadataTab.id) {
          let component = (
            <MetaExplore
              visible={this.props.exploreMeta}
              redux={this.props.redux}
              envCreds={this.state.envCreds}
              actions={this.props.actions}
              selectedEnvironment={this.state.selectedEnvironment}
              close={() => this.setState({ exploreMeta: false })}
            />
          );
          let i;
          this.setState({
            data: this.state.data.map((el) =>
              el.id === metadataTab.id ? { ...el, component } : el
            ),
          });
        }
        let eventContextTab = this.state.data.find(
          (d) => d.tab == "Event Context"
        );
        if (eventContextTab && eventContextTab.id) {
          const thisTransformer = this.thisTransformer();
          let component = (
            <EditTransConsole
              {...this.props}
              recipeAsJob={false}
              changeConsoleMode={this.changeConsoleMode}
              setExecutingFlag={this.setExecutingFlag}
              startRecipe={(a, b) => this.startRecipe(a, b, thisTransformer)}
              mode="eventContext"
              handleCancel={this.handleCancel}
              transformer={transformer.versions[0]}
              env={selectedEnvironment}
              handleRecipeComplete={() => this.setState({ recipeAsJob: false })}
            />
          );
          let i;
          this.setState({
            data: this.state.data.map((el) =>
              el.id === eventContextTab.id ? { ...el, component } : el
            ),
          });
        }
      }
    }

    // switch recipe from file bar
    if (
      prevProps.redux.transformer.versions[0].BaseId !=
      transformer.versions[0].BaseId
    ) {
      let config = transformer.versions[0].Config;
      if (config.includes("schema:") || config.includes("schemas:")) {
        this.setState({ showSchemaButton: true });
      } else if (this.state.showSchemaButton) {
        this.setState({ showSchemaButton: false });
      }
      if (prevState.disposeRef) {
        prevState.disposeRef();
      }
      monaco.editor.getModels().forEach((model) => model.dispose());

      let unfoldAll = editorRef.current.getAction("editor.unfoldAll");
      unfoldAll.run();

      version = null;
      let recipe = await indexDb.GetRecipeByBaseId(
        transformer.versions[0].BaseId
      );
      let indexedRecipe;
      let highlightSave;
      console.log("browser stored recipe info", recipe);
      if (
        recipe &&
        recipe.length > 0 &&
        moment(recipe[0].LastModified).valueOf() <
          moment(transformer.versions[0].LastModified).valueOf()
      ) {
        indexedRecipe = transformer.versions[0];
        highlightSave = false;
      } else {
        indexedRecipe = recipe[0];
        highlightSave = recipe[0].edited;
      }
      if (executionLogTab && executionLogTab.id) {
        this.setState({
          data: this.state.data.filter(
            (item) => item.id !== executionLogTab.id
          ),
        });
      }
      this.props.liftRecipeVersion(indexedRecipe.Version);
      this.setState({
        indexedRecipe: indexedRecipe,
        highlightSave: highlightSave,
        languageVersion: indexedRecipe.LanguageVersion || "2.0",
        lastConfig: indexedRecipe.Config,
        lastSave: moment(transformer.versions[0].LastModified).valueOf(),
        curVersion: indexedRecipe.Version,
        lastConfig: indexedRecipe.Config,
        versionOne: indexedRecipe,
        versionTwo:
          transformer.versions.length == 1
            ? transformer.versions[0]
            : transformer.versions[1],
        showDiff: false,
        comment: "",
        schemaFolded: false,
      });
    }
    if (prevState.selectedEnvironment === null && sessionEnvironment) {
      const se =
        project.environments &&
        project.environments.find(
          (f) => f.environmentId === sessionEnvironment
        );
      if (se) {
        this.getEnvCreds(se);
      }
      this.setState({ selectedEnvironment: se });
    } else if (
      sessionEnvironment !=
      (prevState.selectedEnvironment && prevState.selectedEnvironment.id)
    ) {
      const se =
        project.environments &&
        project.environments.find(
          (f) => f.environmentId === sessionEnvironment
        );
      if (se) {
        this.getEnvCreds(se);
        this.setState({ selectedEnvironment: se });
      }
    }
  }
  showMetaData = () => {
    let metadataTab = this.state.data.find((d) => d.tab == "Metadata Explorer");
    if (metadataTab && metadataTab.id) {
      let newData = this.state.data.filter(
        (item) => item.id !== metadataTab.id
      );
      this.setState({
        data: newData,
        activeIndex: newData.length - 1,
      });
    } else {
      const id = new Date().valueOf();
      this.setState({
        data: this.state.data.concat({
          tab: "Metadata Explorer",
          component: (
            <MetaExplore
              visible={this.props.exploreMeta}
              redux={this.props.redux}
              envCreds={this.state.envCreds}
              actions={this.props.actions}
              selectedEnvironment={this.state.selectedEnvironment}
              close={() => this.setState({ exploreMeta: false })}
            />
          ),
          id: id,
          closeable: true,
        }),
        activeIndex: this.state.data.length,
      });
    }
  };
  addExecutionLog = () => {
    let executionLogsTab = this.state.data.find(
      (d) => d.tab == "Execution Log"
    );
    if (executionLogsTab && executionLogsTab.id) {
      //already present then remove
      let newData = this.state.data.filter(
        (item) => item.id !== executionLogsTab.id
      );
      this.setState({
        data: newData,
        activeIndex: newData.length - 1,
        showLog: false,
      });
    } else {
      let newData = [...this.state.data];
      let resultsTab = this.state.data.find((d) => d.tab == "Results");
      if (resultsTab && resultsTab.id) {
        //remove results tab if present
        newData = this.state.data.filter((item) => item.id !== resultsTab.id);
      }

      const id = new Date().valueOf();
      this.setState({
        showLog: true,
        data: newData.concat({
          tab: "Execution Log",
          component: (
            <MonitorTransformer
              {...this.props}
              showLastRecipeLog={true}
              recipeId={this.props.match.params.transId}
            />
          ),
          id: id,
          closeable: true,
        }),
        activeIndex: newData.length,
      });
    }
  };

  async handleDiffVerOne(t) {
    const { transformer } = this.props.redux;
    let recipe = await indexDb.GetRecipeByBaseId(
      transformer.versions[0].BaseId
    );
    this.setState({ indexedRecipe: recipe[0], showDiff: !this.state.showDiff });
  }
  render() {
    const { match, actions, redux } = this.props;
    const { params } = match;
    const { versionOne, versionTwo } = this.state;
    const { project, transformer } = this.props.redux;
    let a = [...this.props.redux.transformer.versions];
    a.map((v) => (v.ShowVersion = "Version " + v.Version.toString()));
    const { selectedEnvironment, showDiff, showLog, isExecuting } = this.state;
    const versionIsDeployed = this.versionIsDeployed();
    const curVersionIsLatest = this.curVersionIsLatest();
    const selectedEnvironmentIsDeployedToCurVer =
      this.selectedEnvironmentIsDeployedToCurVer();
    const thisTransformer = this.thisTransformer();
    const isStreaming = this.state.languageVersion === "3.0";
    let aIsLatest, bIsLatest;
    if (this.state.showDiff) {
      aIsLatest =
        transformer.versions &&
        transformer.versions[0].Version === versionOne.Version;
      bIsLatest =
        transformer.versions &&
        transformer.versions[0].Version === versionTwo.Version;
    }
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          overflowY: "hidden",
          width: "100%",
          marginTop: 45,
          background: "white",
        }}
      >
        <ReactTooltip place={"bottom"} />

        <div
          style={{
            marginBottom: 11,
            display: "inline-block",
            minWidth: "100%",
            position: "absolute",
            top: 10,
            background: "white",
            borderBottom: "1px #dadce0 solid",
            height: "33px",
          }}
        >
          <div
            style={{
              top: 2,
              left: 5,
              position: "absolute",
            }}
            className="nav-bars"
            aria-disabled={!this.state.canUndo}
            className={!this.state.canUndo ? "is-disabled" : ""}
          >
            <img
              alt="small-spinner"
              src={images.Undo}
              className="undo-redo-recipe"
              height="20"
              onClick={() => this.handleUndo()}
            />
          </div>

          <div
            style={{
              top: 2,
              left: 26,
              position: "absolute",
              borderRight: "1px #dadce0 solid",
              width: 30,
            }}
            className="nav-bars"
            aria-disabled={!this.state.canRedo}
            className={!this.state.canRedo ? "is-disabled" : ""}
          >
            <img
              alt="small-spinner"
              src={images.Redo}
              className="undo-redo-recipe"
              height="20"
              onClick={() => this.handleRedo()}
              style={{ marginRight: 7 }}
            />
          </div>

          <div
            style={{
              top: 2,
              left: 66,
              position: "absolute",
            }}
            aria-disabled={showDiff}
            className={showDiff ? "is-disabled" : ""}
          >
            {this.state.savingFile ? (
              <img
                alt="small-spinner"
                src={images.ajax_loader}
                height="8"
                style={{ marginLeft: 20 }}
              />
            ) : (
              <div
                className="nav-bars"
                style={{ display: "flex", top: "-2px", position: "absolute" }}
              >
                <Button
                  onClick={this.handleSubmit}
                  style={{
                    color: "#5f6368",
                    height: 29,
                    padding: "2px 7px",
                  }}
                  disabled={!this.state.highlightSave}
                >
                  <img alt="small-spinner" src={images.SaveFile} height="20" />
                </Button>
                <div
                  style={{
                    border: "1px solid rgba(111, 112, 112, 0.33)",
                    padding: "2px",
                    height: 29.4,
                  }}
                  className="dropdown-button-wrap"
                >
                  <DropdownButton
                    bsSize="small"
                    bsStyle="default"
                    style={{ background: "white", marginLeft: 2 }}
                    className="save-recipe"
                    title={""}
                    id="save-recipe"
                  >
                    <MenuItem
                      onClick={this.handleAutoSaveSwitch}
                      eventKey="1"
                      className="testing-menu"
                    >
                      {this.state.autosavebool === true ? "Disable" : "Enable"}{" "}
                      Autosave
                    </MenuItem>
                  </DropdownButton>
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              borderRight: "1px #dadce0 solid",
              left: 138,
              position: "absolute",
              width: this.state.jobType[0].type == "Run as Job" ? 140 : 150,
              top: 0,
              display: "flex",
            }}
          >
            <div className="dropdown-button-wrap">
              <Button
                style={{
                  color: this.state.isExecuting ? "#1967d2" : "#5f6368",
                  fontWeight: this.state.isExecuting ? "bold" : null,
                  background: this.state.isExecuting ? "#d2e3fc" : null,
                  height: 29,
                  padding: "2px 7px",
                }}
                onClick={() => {
                  this.state.jobType[0].type == "Run as Job"
                    ? this.startRecipeDirect()
                    : this.handleRun();
                }}
                disabled={this.state.isExecuting || this.state.showDiff}
              >
                <img
                  alt="small-spinner"
                  src={images.StartJob}
                  height="20"
                  style={{ marginTop: -3 }}
                />
                {this.state.jobType[0].type}
              </Button>
            </div>
            <div
              style={{
                border: "1px solid rgba(111, 112, 112, 0.33)",
                padding: "2px",
                height: 29.4,
              }}
              className="dropdown-button-wrap"
            >
              <DropdownButton
                onClick={() => this.swapJobType()}
                bsSize="small"
                bsStyle="default"
                style={{ background: "white", marginLeft: 3 }}
                className="save-recipe"
                title={""}
                id="run-recipe"
              >
                <MenuItem
                  onClick={() => {
                    this.state.jobType[0].type == "Run as Job"
                      ? this.startRecipeDirect()
                      : this.handleRun();
                  }}
                  className="nav-bars"
                  disabled={this.state.selectedEnvironment ? false : true}
                >
                  <strong>{this.state.jobType[1].type}</strong>
                </MenuItem>
              </DropdownButton>
            </div>
          </div>
          {false ? (
            <div style={{ display: "inline-block" }}>
              <DropdownButton
                bsStyle="info"
                title="Test"
                id="run-recipe-btn"
                bsSize="small"
                style={{ marginRight: 20, width: 80 }}
                noCaret
                id="dropdown-no-caret"
              >
                <MenuItem
                  href="https://console.aws.amazon.com/sqs/home"
                  target="_blank"
                  rel="noopener noreferrer"
                  eventKey="1"
                >
                  Amazon Web Services
                </MenuItem>

                <MenuItem
                  href="https://console.cloud.google.com/cloudpubsub/topicList"
                  target="_blank"
                  rel="noopener noreferrer"
                  eventKey="2"
                >
                  Google Cloud Platform
                </MenuItem>
              </DropdownButton>
            </div>
          ) : (
            <div
              style={{
                left: this.state.jobType[0].type == "Run as Job" ? 290 : 300,
                position: "absolute",
                width: 122,
              }}
              onClick={() => this.addExecutionLog()}
            >
              <Button
                style={{
                  color: this.state.showLog ? "#1967d2" : "#5f6368",
                  fontWeight: this.state.showLog ? "bold" : null,
                  background: this.state.showLog ? "#d2e3fc" : null,
                  height: 29,
                  padding: "2px 7px",
                  width: 107,
                }}
                disabled={this.state.isExecuting}
                className="nav-bars"
              >
                Execution Log
              </Button>
            </div>
          )}

          <div
            style={{
              left: this.state.jobType[0].type == "Run as Job" ? 407 : 417,
              position: "absolute",
            }}
            onClick={() => this.handleDiffVerOne()}
          >
            <Button
              style={{
                color: "#5f6368",
                width: 80,
                height: 29,
                padding: "2px 7px",
                color: this.state.showDiff ? "#1967d2" : "#5f6368",
                fontWeight: this.state.showDiff ? "bold" : null,
                background: this.state.showDiff ? "#d2e3fc" : null,
              }}
              className="nav-bars"
            >
              Compare
            </Button>
          </div>
          <div
            style={{
              left: this.state.jobType[0].type == "Run as Job" ? 496 : 506,
              position: "absolute",
              borderRight: "1px #dadce0 solid",
              width: 93,
            }}
            onClick={() => this.showMetaData()}
            data-tip={
              !selectedEnvironment
                ? "Select an environment for access to Metadata"
                : null
            }
          >
            <Button
              style={{
                color: "#5f6368",
                width: 80,
                height: 29,
                padding: "2px 7px",
                color: this.state.showDiff ? "#1967d2" : "#5f6368",
                fontWeight: this.state.showDiff ? "bold" : null,
                background: this.state.showDiff ? "#d2e3fc" : null,
              }}
              className="nav-bars"
              disabled={!selectedEnvironment}
            >
              Metadata
            </Button>
          </div>
          <div
            style={{
              top: -1,
              left: this.state.jobType[0].type == "Run as Job" ? 600 : 610,
              position: "absolute",
              display: "grid",
            }}
            data-tip={"Show or hide the side scroll"}
          >
            <Button
              active={this.state.showScroll}
              style={{ height: 30, padding: "2px 7px", width: 35 }}
              outline="true"
              color="primary"
              onClick={() =>
                this.setState({ showScroll: !this.state.showScroll })
              }
            >
              <img src={images.PicInPic} style={{ height: 19, width: 19 }} />
            </Button>
          </div>
          <div
            style={{
              top: -1,
              left: this.state.jobType[0].type == "Run as Job" ? 645 : 655,
              position: "absolute",
              display: "grid",
            }}
            data-tip={"Comment or Uncomment the highlighted text"}
          >
            <Button
              active={this.state.schemaFolded}
              style={{ height: 30, padding: "2px 7px", width: 35 }}
              outline="true"
              color="primary"
              onClick={() => this.handleToggleComment()}
            >
              <img src={images.Comments} style={{ height: 19, width: 19 }} />
            </Button>
          </div>
          <div
            style={{
              top: -1,
              left: this.state.jobType[0].type == "Run as Job" ? 690 : 700,
              position: "absolute",
              display: "grid",
            }}
            data-tip={"Expand or Collapse the schemas"}
          >
            {this.state.showSchemaButton && (
              <Button
                active={this.state.schemaFolded}
                style={{ height: 30, padding: "2px 7px", width: 35 }}
                outline="true"
                color="primary"
                onClick={() => this.handleSchemasFold()}
              >
                <img
                  src={images.SchemaInPic}
                  style={{ height: 19, width: 19 }}
                />
              </Button>
            )}
          </div>
          <div
            style={{
              top: -1,
              left: this.state.jobType[0].type == "Run as Job" ? 735 : 745,
              position: "absolute",
              display: "grid",
            }}
            data-tip={
              !this.state.querySelected
                ? "Select SQL Query To Use This Feature! "
                : "Edit selected sql query"
            }
          >
            <Button
              active={this.state.showSqlEditor}
              style={{ height: 30, padding: "2px 7px", width: 85 }}
              outline="true"
              color="primary"
              onClick={() => this.showSqlEditorModal()}
              disabled={!this.state.querySelected}
            >
              Edit Query
            </Button>
          </div>
          <div
            style={{ position: "absolute", right: 9, top: 0 }}
            aria-disabled={showDiff}
            className={showDiff ? "is-disabled" : ""}
          >
            {this.state.showTemplateSelection && (
              <div
                style={{
                  display: "inline-block",
                  marginRight: 10,
                  position: "relative",
                }}
                className="dropdown-button-wrap"
              >
                <DropdownButton
                  title={
                    this.state.selectedTemplate != ""
                      ? this.state.selectedTemplate.toUpperCase()
                      : "Select Template"
                  }
                  id={"template-dropdown"}
                  onSelect={(e) => {
                    this.handleTemplateChange(e);
                  }}
                >
                  {this.state.availableTemplates.length > 0 ? (
                    this.state.availableTemplates.map((t) => {
                      return (
                        <MenuItem
                          key={t}
                          eventKey={t}
                          active={this.state.selectedTemplate === t}
                        >
                          {t.toUpperCase()}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem
                      key={"loading"}
                      disabled={true}
                      eventKey={"loading"}
                    >
                      <Loading />
                    </MenuItem>
                  )}
                </DropdownButton>
              </div>
            )}
            <div
              style={{
                display: "inline-block",
                marginRight: 10,
                position: "relative",
              }}
              className="dropdown-button-wrap"
            >
              <Button
                id="run-recipe-btn"
                bsSize="small"
                style={{ width: 89 }}
                bsStyle="default"
                onClick={() => this.handleShowVersions()}
              >
                Version {this.state.curVersion}
              </Button>
            </div>
            <div
              style={{
                display: "inline-block",
                position: "relative",
                marginRight: 10,
              }}
              // aria-disabled={showDiff}
              // className={showDiff ? "is-disabled" : ""}
            >
              <Button
                id="run-recipe-btn"
                bsSize="small"
                style={{ width: 89 }}
                bsStyle={
                  !curVersionIsLatest || versionIsDeployed
                    ? "default"
                    : "default"
                }
                disabled={!curVersionIsLatest}
                onClick={this.handleShowCheckIn}
              >
                New Version
              </Button>
            </div>
            {selectedEnvironment && !selectedEnvironmentIsDeployedToCurVer && (
              <Button
                bsSize="small"
                bsStyle="primary"
                style={{ width: 80, display: "inline-block", marginRight: 10 }}
                onClick={this.handleDeployMod}
                disabled={
                  Object.keys(transformer.isDeploying).find(
                    (r) => params.transId === r
                  )
                    ? true
                    : false
                }
              >
                <span>Deploy</span>
              </Button>
            )}
            <div
              style={{
                display: "inline-block",
                marginRight: 10,
                position: "relative",
              }}
            >
              <Button
                id="run-recipe-btn"
                bsSize="small"
                style={{ width: 89 }}
                bsStyle={"default"}
                onClick={this.showSchedulesModal}
              >
                Schedule
              </Button>
            </div>
          </div>
        </div>

        {this.state.showSchedule && (
          <Modal
            show={this.state.showSchedule}
            onHide={this.showSchedulesModal}
            dialogClassName="recipe-schedule-section"
            backdrop="static"
          >
            <Header closeButton>
              <Title style={{ fontWeight: "bold" }}>Schedules</Title>
            </Header>
            <Body style={{ height: 460 }}>
              <Trigger
                {...this.props}
                onSave={this.showSchedulesModal}
                availableTemplates={this.state.availableTemplates}
              />
            </Body>
          </Modal>
        )}

        {this.state.showSqlEditor && (
          <Modal
            show={this.state.showSqlEditor}
            onHide={this.showSqlEditorModal}
            dialogClassName="recipe-schedule-section"
            centered
            restoreFocus
            backdrop="static"
          >
            <Header closeButton>
              <Title style={{}}>Edit Query</Title>
              <Button
                bsSize="small"
                bsStyle="primary"
                style={{
                  height: 29,
                  top: 10,
                  right: 50,
                  position: "absolute",
                  padding: "2px 7px",
                }}
                variant="secondary"
                onClick={this.handleSQLFormat}
              >
                Format
              </Button>
            </Header>
            <Body style={{ height: 350 }}>
              <Editor
                defaultLanguage="sql"
                value={
                  this.state.sqlQuery
                    ? this.state.sqlQuery
                    : "No query selected!"
                }
                loading="Loading..."
                onChange={this.handleSQLEditorChange}
                onMount={this.handleSQLEditorDidMount}
                beforeMount={this.handleSQLEditorWillMount}
                onWillDisposeModel={this.handleSQLEditorWillDispose}
                options={{
                  scrollBeyondLastLine: false,
                  tabSize: 2,
                  formatOnPaste: true,
                  formatOnType: true,
                  autoIndent: "full",
                }}
              />
            </Body>
            <ModalFooter>
              <Button
                style={{
                  height: 29,
                  padding: "2px 7px",
                }}
                variant="secondary"
                onClick={this.showSqlEditorModal}
              >
                Close
              </Button>
              <Button
                className={this.state.showSaveButton ? "" : "disabled"}
                variant="primary"
                style={{
                  color: "#fff",
                  background: "#1967d2",
                  height: 29,
                  padding: "2px 7px",
                }}
                onClick={this.handleSaveChangesSQL}
              >
                Save
              </Button>
            </ModalFooter>
          </Modal>
        )}

        <CheckInMod
          show={this.state.showcheckin}
          hide={this.handleCloseCheckIn}
          handleCheckIn={this.handleCheckIn}
          nextVer={transformer.versions.length + 1}
          curVersion={this.state.curVersion}
          editWarn={this.state.editWarn}
          handleCommentChange={(e) => this.setState({ comment: e })}
          comment={this.state.comment}
        />
        <VersionsList
          key={this.state.reRender}
          show={this.state.showVersions}
          hide={() => this.setState({ showVersions: false })}
          editWarn={this.state.editWarn}
          allVers={transformer.versions}
          switchVersion={this.changeVersion}
          updateDPT={this.updateDPT}
          redux={this.props.redux}
        />

        <DeployMod
          show={this.state.showDeployMod}
          hide={this.handleDeployMod}
          envinfo={selectedEnvironment}
          handleDeploy={this.handleFinalDeploy}
          handleDeployAndVersion={this.handleDeployAndVersion}
        />

        <SaveOverwriteMod
          show={this.state.showOverwrite}
          hide={this.handleToggleOverwrite}
          handleOverwrite={this.handleOverwrite}
          diff={thisTransformer}
          redux={this.props.redux}
          handlePullLatest={this.handlePullLatest}
        />

        <ExitCheckMod
          show={this.state.showExitCheck}
          hide={this.handleToggleExit}
          handleExit={this.handleExit}
        />
        {this.state.showDiff && (
          <div
            style={{
              marginTop: 42,
              border: "2px solid #c1c1c1",
              padding: "1",
              display: "flex",
            }}
          >
            <div
              style={{
                borderRight: "2px #dadce0 solid",
                width: "48.5%",
              }}
            >
              <div style={{ left: "23%", position: "absolute" }}>
                <DropdownButton
                  onClick={() => this.setState({ editWarn: false })}
                  bsSize="small"
                  bsStyle="default"
                  id="version1"
                  style={{ background: "white", width: 110 }}
                  title={
                    this.state.versionOne && this.state.versionOne.ShowVersion
                  }
                >
                  {this.props.redux.transformer.versions.map((t, i) => {
                    const selected =
                      this.state.versionOne &&
                      this.state.versionOne.Version == t.Version;
                    return (
                      <MenuItem
                        className={DataProfileFieldStyle.linkStyles}
                        key={t.Version}
                      >
                        <label htmlFor="two" className="block">
                          <input
                            type="checkbox"
                            id="two"
                            checked={selected ? true : false}
                            onClick={(e) => {
                              this.setState({
                                versionOne: t,
                              });
                            }}
                            value={t.ShowVersion}
                            className="m-3"
                            defaultChecked
                          />
                          <span style={{ marginLeft: 5 }}>{t.ShowVersion}</span>
                        </label>
                      </MenuItem>
                    );
                  })}
                </DropdownButton>
              </div>
            </div>
            <div style={{ width: "48.5%" }}>
              <div style={{ marginLeft: "42%", position: "relative" }}>
                <DropdownButton
                  onClick={() => this.setState({ editWarn: false })}
                  bsSize="small"
                  bsStyle="default"
                  style={{ background: "white", width: 110 }}
                  id="version2"
                  title={
                    this.state.versionTwo && this.state.versionTwo.ShowVersion
                  }
                >
                  {this.props.redux.transformer.versions.map((t, i) => {
                    const selected =
                      this.state.versionTwo &&
                      this.state.versionTwo.Version == t.Version;
                    return (
                      <MenuItem
                        className={DataProfileFieldStyle.linkStyles}
                        key={t.Version}
                      >
                        <label htmlFor="two" className="block">
                          <input
                            type="checkbox"
                            id="two"
                            checked={selected ? true : false}
                            onClick={(e) => {
                              this.setState({
                                versionTwo: t,
                              });
                            }}
                            value={t.ShowVersion}
                            className="m-3"
                            defaultChecked
                          />
                          <span style={{ marginLeft: 5 }}>{t.ShowVersion}</span>
                        </label>
                      </MenuItem>
                    );
                  })}
                </DropdownButton>{" "}
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            height: " 90vh",
            marginTop: this.state.showDiff ? null : 45,
          }}
        >
          <div
            style={{
              width: "100%",
              position: "relative",
            }}
          >
            {versionIsDeployed && !showDiff ? (
              <div
                className="readonly-banner"
                style={{ background: "#e5f5ff" }}
              >
                <img
                  alt="small-spinner"
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginTop: 3,
                  }}
                  src={images.webhooks}
                  height="23"
                />
                <div
                  style={{
                    marginLeft: 10,
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  <div>
                    Deployed -{" "}
                    {curVersionIsLatest ? (
                      <span>Create a new version to edit.</span>
                    ) : (
                      <span>Select the latest version to edit.</span>
                    )}
                  </div>
                </div>
              </div>
            ) : !curVersionIsLatest && !showDiff ? (
              <div className="readonly-banner">
                <img
                  alt="small-spinner"
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginTop: 3,
                  }}
                  src={images.webhooks}
                  height="23"
                />
                <div
                  style={{
                    marginLeft: 10,
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  <div>
                    Read-Only - <span>This is an old version.</span>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.showDiff &&
            this.state.versionOne &&
            this.state.versionTwo ? (
              <DiffEditor
                height="90vh"
                language="yaml"
                beforeMount={this.handleEditorWillMount}
                original={
                  aIsLatest
                    ? this.state.indexedRecipe.Config
                    : this.state.versionOne && this.state.versionOne.Config
                }
                modified={
                  bIsLatest
                    ? this.state.indexedRecipe.Config
                    : this.state.versionTwo && this.state.versionTwo.Config
                }
                options={{
                  readOnly: true,
                  minimap: {
                    enabled: false,
                  },
                }}
              />
            ) : (
              <div
                style={{
                  position: "relative",
                  height: "100%",
                }}
              >
                {/* {this.state.querySelected ? (
                  <div
                    onClick={() => this.showSqlEditorModal()}
                    style={{
                      // display: "inline-block",
                      // marginRight: 10,
                      position: "absolute",
                      top: 30,
                      right: 20,
                      zIndex: 20,
                      opacity: 1,
                      transition: "opacity 0.3s",
                    }}
                  >
                    <Button
                      bsSize="medium"
                      bsStyle="primary"
                    >
                      Edit SQL Query
                    </Button>
                  </div>
                ) : null} */}
                <Editor
                  height="90vh"
                  defaultLanguage="yaml"
                  path={this.props.redux.transformer.versions[0].BaseId.toString()}
                  value={
                    this.state.indexedRecipe
                      ? this.state.indexedRecipe.Config
                      : this.props.redux.transformer.versions[0].Config
                  }
                  onMount={this.handleEditorDidMount}
                  onChange={this.handleEditorChange}
                  beforeMount={this.handleEditorWillMount}
                  // theme={"vs-dark"}
                  options={
                    !curVersionIsLatest || versionIsDeployed
                      ? {
                          readOnly: true,
                          minimap: {
                            enabled: this.state.showScroll,
                          },
                        }
                      : {
                          readOnly: false,
                          minimap: {
                            enabled: this.state.showScroll,
                          },
                        }
                  }
                />
              </div>
            )}
          </div>
          {this.state.data && this.state.data.length > 0 && (
            <CustomTabs
              data={this.state.data}
              activeIndex={this.state.activeIndex}
              handleOnCloseTab={(id, newIndex) => {
                try {
                  if (
                    this.state.data &&
                    this.state.data.length > 1 &&
                    newIndex == -1
                  ) {
                    this.setState({
                      data: this.state.data.filter((item) => item.id !== id),
                      activeIndex: 0,
                    });
                  } else {
                    this.setState({
                      data: this.state.data.filter((item) => item.id !== id),
                      activeIndex: newIndex,
                    });
                  }
                } catch (e) {
                  null;
                }
              }}
              fullscreen={this.state.fullscreen}
              recipeAsJob={this.state.recipeAsJob}
              handleClosable={(runTab) => this.handleTabClosable(runTab)}
            />
          )}
        </div>
      </div>
    );
  }
}

const sqlEditorRef = React.createRef();
const sqlMonacoRef = React.createRef();
