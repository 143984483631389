const HomeStyles = {
  mainStyles: {
    marginTop: '50px',
    marginLeft: '30px',
    marginRight: '30px',
  },
  titleStyles: {
    fontSize: '22px',
    float: 'left',
    marginBottom: '5px',
  },
  loginBoxStyles: {
    width: '100px',
    height: '100px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  wrapperStyles: {
    float: 'left',
    marginRight: '50px',
  },
  SubTitleStyles: {
    marginTop: 5,
    marginBottom: 5,
    color: '#909090',
  },
  mainStylesApp: {
    marginTop: '60px',
  },
  wrapper: {
    width: '100%',
    overflow: 'hidden',
  },
  left: {
    width: '220px',
    float: 'left',
  },
  right: {
    marginLeft: '210px',
    marginRight: '10px',
  },
  rightEdge: {
    float: 'right',
    marginBottom: '10px',
  },
  feed: {
    marginLeft: '10px',
    marginRight: '20px',
  },
  connectionIconStyles: {
    width: '60px',
    height: '60px',
    marginBottom: 5,
  },
  connectionContainerStyles: {
    float: 'left',
    padding: 10,
  },
  connectionListStyles: {
    width: '100%',
    height: '120px',
  },
  buttonContainerStyles: {
    float: 'left',
    padding: 10,
    marginTop: 10,
  },
};

export default HomeStyles;
