import React, { Component } from 'react';
import images from '../images';
import moment from 'moment'

export default class BoxWizard extends Component {

  constructor() {
    super();
    
    this.state = {
      isDeleting: null,
    }
    this.click = this.click.bind(this);
  }

 click(){
  const { appId, typeId, piGuid, baseId } = this.props
        this.props.history.push({
        pathname:`/a/${appId}/wiz/${baseId}`,
        state: {piGuid, appId, typeId}
      })
  }

  render(){

  const styles = {
    box:{
      marginBottom: 16,
      overflow: 'hidden',
      marginRight: 16,
      height: 140,
      cursor: 'pointer',
      float: 'left',
      paddingBottom: 5,
      padding: 12,
      display: 'inline-block',
      verticalAlign:'middle',
      width: 220,
      position: 'relative',
    },
    deletingbox:{
      marginBottom: 16,
      overflow: 'hidden',
      background: '#ccc',
      marginRight: 16,
      height: 140,
      cursor: 'pointer',
      float: 'left',
      paddingBottom: 5,
      padding: 12,
      display: 'inline-block',
      verticalAlign:'middle',
      width: 220,
      position: 'relative',
    },
    name:{
      width: 'calc(100% - 24px)',
      textDecoration: 'underline',
      color:'#337ab7',
      fontWeight:'bold',
      maxHeight: 38,
      overflow: 'hidden'
    },
    environ:{
      position: 'absolute',
      backgroundColor: 'rgb(128, 224, 192)',
      padding: '2px 4px',
      right: '9px'
    },
    lastModified:{
      overflow: 'hidden',
        whiteSpace:'nowrap',
        paddingTop: 6,
        color: '#404040',
        fontSize:12,
        width:'100%',
    },
    icons: {
      position: 'absolute',
      textAlign: 'center',
      left:0,
      top: 86, width:138,
    },
    options:{
        position: 'absolute',
        zIndex: 2,
        top: 100,
        right:-90,
      },

  }

   

  const {appId, name, typeId, piGuid, baseId, lastModified, wiz, configs, isDeleting, obj, handleSelectEdit, warnBeforeDelete} = this.props
  const isReverse = configs && configs
    .find(c=>c.Key==='direction' && c.Value==='reverse') ? true : false
  let srcType = wiz.sourceType
  let destType = wiz.destinationType
  if(isReverse){
    srcType = wiz.destinationType
    destType = wiz.sourceType
  }

  return (
    <div style={isDeleting === baseId ? styles.deletingbox : styles.box} className="connexp-box"
      onClick={()=>this.click(null)}>
      <div className="connexp-box-contents" style={styles.name}>
        {name}
      </div>

      <div className="wiz-box-icon" style={styles.icons}>
        <Icon className="connexp-box-contents" type={srcType} style={{position: 'absolute', top: 4, left: 75}} />
        <div
          className="connexp-box-contents"
          role="presentation"
          style={{ display: 'inline-block', width: 28, height: 46, backgroundSize: 'contain', backgroundRepeat: 'no-repeat',backgroundPosition: 'center', backgroundImage: `url("${images.arrow}")` }}
        />
        <Icon className="connexp-box-contents" type={destType} style={{position: 'absolute', top: 4, left: 75}} />
      </div>

      <div className="connexp-box-contents" style={styles.lastModified}>
        <strong className="connexp-box-contents">{moment(lastModified).format('MMM Do Y, h:mm a')}</strong>
      </div>

      
        {isDeleting && isDeleting === piGuid ? 
            <div
            style={{position: 'absolute',
                  zIndex: 2,
                  top: 104,
                  right:18}}>
           <img alt="spinner" height="20" src={images.ajax_loader} />
           </div>
           :

          <div style={styles.options} 
              className="options-box">
              <div>
                <div
                  className="edit-pencil"
                  style={{
                    textAlign: 'center',
                    verticalAlign: 'top',
                    display:'inline-block',
                  }}
                  onClick={(e) =>
                    handleSelectEdit(null, baseId, piGuid, typeId, appId, e)
                  }>
                  <img
                    role="presentation"
                    src={images.edit}
                    style={{
                      height: 30,
                      filter: 'invert(100%)'
                    }}
                  />
                </div>

                <div
                  style={{
                    textAlign:'center',
                    cursor: 'pointer',
                    verticalAlign: 'top',
                    display:'inline-block',
                  }}>

              <svg  onClick={(e)=>warnBeforeDelete(null, obj, e)} className="delete-experience" height="30" width="30" viewBox="14 14 50 50">
                    <path style={{strokeWidth:2, strokeLinecap: 'round'}}
                      d="M 25.3333,23.75L 50.6667,23.75C 51.5411,23.75 51.8541,27.3125 51.8541,27.3125L 24.1458,27.3125C 24.1458,27.3125 24.4589,23.75 25.3333,23.75 Z M 35.625,19.7917L 40.375,19.7917C 40.8122,19.7917 41.9583,20.9378 41.9583,21.375C 41.9583,21.8122 40.8122,22.9584 40.375,22.9584L 35.625,22.9584C 35.1878,22.9584 34.0416,21.8122 34.0416,21.375C 34.0416,20.9378 35.1878,19.7917 35.625,19.7917 Z M 27.7083,28.5L 48.2916,28.5C 49.1661,28.5 49.875,29.2089 49.875,30.0834L 48.2916,53.8334C 48.2916,54.7078 47.5828,55.4167 46.7083,55.4167L 29.2917,55.4167C 28.4172,55.4167 27.7083,54.7078 27.7083,53.8334L 26.125,30.0834C 26.125,29.2089 26.8339,28.5 27.7083,28.5 Z M 30.0833,31.6667L 30.4792,52.25L 33.25,52.25L 32.8542,31.6667L 30.0833,31.6667 Z M 36.4167,31.6667L 36.4167,52.25L 39.5833,52.25L 39.5833,31.6667L 36.4167,31.6667 Z M 43.1458,31.6667L 42.75,52.25L 45.5208,52.25L 45.9167,31.6667L 43.1458,31.6667 Z " />
              </svg>  
              
              </div>
              </div>
          </div>

          }

          <div className="recipe-batch-stream" style={{background: '#337ab7b1'}}>
                <img src={images.ic_web_black_48dp}
                      height="24"
                      alt="batch" 
                      className="rec-wiz-icony"/>     
              </div> 

    </div>
  );
}
}

const Icon = (props) => {
  const {type} = props
  return (<div
    role="presentation"
    style={{ display: 'inline-block', width: 46, height: 46, backgroundSize: 'contain', backgroundRepeat: 'no-repeat',backgroundPosition: 'center', backgroundImage: `url("${images[type]}")` }}
  />)
}

// const Img = (props) => {
//   const {type, cardSpacing} = props
//   return (<img
//     className="connexp-box-contents"
//     role="presentation"
//     width="28px"
//     style={{ display: 'inline-block', marginRight: cardSpacing || 22 }}
//     src={images[type]}
//   />)
// }
