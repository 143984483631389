import React from "react";
import images from "../images";
import { Button, Modal } from "react-bootstrap";
import { If, Then, Else } from "react-if";

const { Body } = Modal;

const SubSuccessModal = (props) => {
  const { modal, triggerModal, subKey, selectedPlan, isTrial, orgName } = props;
  return (
    <Modal
      show={modal}
      onHide={triggerModal}
      backdrop="static"
      keyboard={false}
    >
      <Body style={{ height: "fit-content" }}>
        <div
          style={{
            marginTop: 0,
            textAlign: "center",
            padding: 40,
            paddingBottom: 0,
          }}
        >
          <img
            alt="logo"
            src={images.logo_black}
            style={{ marginBottom: 6 }}
            height="36"
          />

          <h3>Welcome to the Lingk Cloud Data Integration Platform!</h3>
          <br />
          <span>Your Organization, </span><strong>{orgName}</strong><span> in Lingk has been set up.</span>
          <br />
          <span>Your order confirmation email is on the way.</span>

          <div style={{ padding: 10, margin: 30, marginTop: 10 }}>
            <a href={`/?subscriber=${subKey}`}>
              <Button bsStyle="primary" bsSize="large" style={{ height: 55, borderRadius: 50, paddingLeft: 80, paddingRight: 80, border: "#6C63FF", background: "#6C63FF", color: "#fff" }}>
                <b>Activate User Account</b>
              </Button>
            </a>
          </div>
        </div>
      </Body>
    </Modal>
  );
};

export default SubSuccessModal;
