import React, { Component } from "react";
import { Button } from "react-bootstrap";
import WebhookItems from "./webhookItems";
import ConfirmDeleteModal from "../utils/confirmDeleteModal";

export default class Webhooks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      selectedForDelete: null,
    };

    this.sendToCreate = this.sendToCreate.bind(this);
    this.warnBeforeDelete = this.warnBeforeDelete.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
    this.sendToEdit = this.sendToEdit.bind(this);
  }

  componentWillMount() {
    const { actions } = this.props;
    const { project } = this.props.redux;

    actions.callGetAllObjectTypes(
      project.tenantInfo.Key,
      project.appData.uniquekey
    );
  }

  warnBeforeDelete(a, e) {
    this.setState({ selectedForDelete: e });
    this.triggerModal();
  }

  triggerModal() {
    let modal = this.state.modal;
    modal = !modal;
    this.setState({ modal });
  }

  sendToEdit(a, e, sys, admin) {
    if (!sys) {
      if (admin) {
        const { appId } = this.props.match.params;
        this.props.history.push(`/a/${appId}/events/obj/${e}/edit`);
      }
    }
  }

  deleteWebhook() {
    const { selectedForDelete } = this.state;

    this.setState({
      isDeleting: selectedForDelete.id,
      selectedForDelete: null,
    });
    this.triggerModal();
  }

  sendToCreate() {
    const { appId } = this.props.match.params;
    this.props.history.push(`/a/${appId}/events/obj/new`);
  }

  render() {
    const { webhookTypes } = this.props.redux.events;
    const { identityIsAdmin } = this.props.redux.project.appData;
    const { appId } = this.props.match.params;

    const objects = [];
    const privateObjects = [];
    const BrickFTPObjects = [];
    const DedupObjects = [];
    
    const sample = {
      name: "File Upload Event",
      private: false,
    };
    BrickFTPObjects.push(
      <WebhookItems
        sendToEdit={this.sendToEdit}
        object={sample}
        key={3982783}
        sys={true}
      />
    );

    webhookTypes &&
      webhookTypes.map((o, i) => {
        if (!o.private) {
          objects.push(
            <WebhookItems
              sendToEdit={this.sendToEdit}
              key={i}
              index={i}
              object={o}
              sys={true}
            />
          );
        } else if (o.name.startsWith("dedup.")) {
          DedupObjects.push(
            <WebhookItems
              sendToEdit={this.sendToEdit}
              key={i}
              index={i}
              object={o}
              identityIsAdmin={identityIsAdmin}
              sys={true}
            />
          );
        } else {
          privateObjects.push(
            <WebhookItems
              sendToEdit={this.sendToEdit}
              key={i}
              index={i}
              object={o}
              identityIsAdmin={identityIsAdmin}
              sys={false}
            />
          );
        }
      });

    return (
      <div className="overview-tab-content">
        <div
          style={{
            margin: "10px 0px 0px 0px",
            fontSize: "20px",
            display: "inline-block",
          }}
        >
          Manage Objects
        </div>
        <div className="main-create-btn-env">
          <Button
            bsSize="small"
            bsStyle="primary"
            disabled={!identityIsAdmin}
            onClick={this.sendToCreate}
          >
            New Object
          </Button>
        </div>

        <div style={{ position: "relative", marginLeft: 0, marginTop: -19 }}>
          <div className="object-list" style={{ marginRight: 30 }}>
            <div style={{ fontSize: 16, marginBottom: 8 }}>
              Event REST API Objects
            </div>
            {privateObjects.length > 0 ? (
              privateObjects
            ) : (
              <span style={{ fontSize: 12 }}>&nbsp;&nbsp;&nbsp;None</span>
            )}
          </div>

          <div className="object-list" style={{ marginRight: 30 }}>
            <div style={{ fontSize: 16, marginBottom: 8 }}>
              BrickFTP Objects
            </div>
            {BrickFTPObjects.length > 0 ? BrickFTPObjects : <span>None.</span>}
          </div>

          <div className="object-list">
            <div style={{ fontSize: 16, marginBottom: 8 }}>System Objects</div>
            {objects.length > 0 ? objects : <span>None.</span>}
          </div>
        </div>
        <div className="object-list" style={{ marginRight: 30 }}>
          <div style={{ fontSize: 16, marginBottom: 8 }}>Dedup Objects</div>
          {DedupObjects.length > 0 ? DedupObjects : <span>None.</span>}
        </div>

        <ConfirmDeleteModal
          modalState={this.state.modal}
          triggerModal={this.triggerModal}
          nameOfTarget={
            this.state.selectedForDelete !== null &&
            this.state.selectedForDelete.name
          }
          deleteTarget={this.deleteWebhook}
        />
      </div>
    );
  }
}
