import React, { Component } from "react";
import images from "../images";
import { Table, Button, Modal, Checkbox, FormControl } from "react-bootstrap";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const { Header, Body, Footer, Title } = Modal;
class EmailMonitoring extends Component {
  constructor() {
    super();
    this.state = {
      success: false,
      failure: false,
      partialSuccess: false,
      newEmail: "",
      newEmailList: [],
      validationError: "",
      isSaving: false,
      isDeleting: false,
      showManager: false,
    };
  }

  componentWillMount() {
    // load up saved config here
    const { redux } = this.props;
    const { environment } = redux;
    const { monitoring } = environment;
    if (monitoring && monitoring.id) {
      let success = false;
      let failure = false;
      let partialSuccess = false;
      if (monitoring.success) success = true;
      if (monitoring.failure) failure = true;
      if (monitoring.partialSuccess) {
        partialSuccess = true;
      }
      const emails = monitoring.emailaddresses.split(",").filter((e) => e);
      this.setState({ success, failure, partialSuccess, newEmailList: emails });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearEnvironmentMonitoringPreferences();
  }
  componentDidMount() {
    this.setBoxMinHeight();
  }

  setBoxMinHeight = () => {
    const boxMinHeight = window.getComputedStyle(this.boxRef).height;
    this.props.setBoxMinHeight(boxMinHeight);
  };

  addNewEmail = () => {
    if (this.state.newEmailList.includes(this.state.newEmail)) {
      this.validationError("Email address already added");
    } else {
      if (emailRegex.test(this.state.newEmail)) {
        const newEmailList = [...this.state.newEmailList];
        newEmailList.push(this.state.newEmail);
        this.setState({ newEmailList, newEmail: "" }, () => {
          this.setBoxMinHeight();
        });
      } else {
        this.validationError("Please enter a valid email address");
      }
    }
  };

  removeEmail = (email) => {
    const newEmailList = [...this.state.newEmailList];
    var index = newEmailList.indexOf(email);
    if (index > -1) {
      newEmailList.splice(index, 1);
      this.setState({ newEmailList }, () => {
        this.setBoxMinHeight();
      });
    }
  };

  save = () => {
    this.setState({ isSaving: true });
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { newEmailList, success, failure, partialSuccess } = this.state;
    const { appId } = params;
    const { project } = redux;
    //'{"emailaddresses":"user1@lingk.io,user2@lingk.io", "environmentid":40, "eventtype":2, "tenantid":8,"appid":10095}'
    let emails = "";
    newEmailList.forEach((e, i) => {
      emails += e;
      if (i !== newEmailList.length - 1) {
        emails += ",";
      }
    });
    let eventtype = 3;
    if (success) eventtype = 0;
    if (failure) eventtype = 1;
    if (partialSuccess) eventtype = 1;
    if (success && failure) eventtype = 2;
    actions
      .updateMonitoringPreferences({
        tenantid: project.tenantInfo.TenantId,
        appid: parseInt(appId),
        emailaddresses: emails,
        environmentid: env.id,
        success: success,
        failure: failure,
        partialSuccess: partialSuccess,
        eventtype,
      })
      .then(() => {
        this.setState({ isSaving: false });
        actions.getMonitoringPreferences(
          project.tenantInfo.TenantId,
          appId,
          env.id
        );
        // actions.getAllCredentialsForEnvironment(
        //   project.tenantInfo.TenantId,
        //   appId,
        //   env.name
        // );
      });
  };

  delete = () => {
    this.setState({ isDeleting: true });
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { newEmailList, success, failure } = this.state;
    const { appId } = params;
    const { project } = redux;
    actions
      .deleteMonitoringPreferences({
        tenantid: project.tenantInfo.TenantId,
        appid: parseInt(appId),
        environmentid: env.id,
      })
      .then(() => {
        this.setState({
          isDeleting: false,
          newEmailList: [],
          success: false,
          failure: false,
          partialSuccess: false,
        });
      });
  };

  showManagNotification = () => {
    this.setState({ showManager: true });
  };

  validationError = (e) => {
    this.setState({ validationError: e });
    setTimeout(() => {
      this.setState({ validationError: "" });
    }, 3500);
  };
  hideModal = () => {
    this.setState({ showManager: false });
  };
  render() {
    const {
      success,
      failure,
      newEmail,
      newEmailList,
      validationError,
      isSaving,
      isDeleting,
      showManager,
      partialSuccess,
    } = this.state;
    const { boxHeight, redux } = this.props;
    const { environment } = redux;
    const { monitoring } = environment;
    let emails = null;
    if (monitoring && monitoring.id && monitoring.emailaddresses) {
      emails = monitoring.emailaddresses.split(",").filter((e) => e);
    }
    return (
      <div className="email-monitoring">
        <div ref={(ref) => (this.boxRef = ref)}>
          <div>
            <span style={{ fontWeight: "bold", padding: 6 }}>
              Notifications
            </span>
            <Button
              bsStyle="default"
              bsSize="small"
              style={{
                width: 115,
                fontWeight: "bold",
                float: "right",
                marginTop: -7,
              }}
              onClick={() => {
                this.showManagNotification();
              }}
            >
              <img
                src={images.create}
                style={{ marginRight: 10, height: 24 }}
              ></img>
              Manage
            </Button>
          </div>
          <div
            style={{
              background: "#EEE",
              width: "100%",
              marginTop: 15,
            }}
          >
            <Table bordered>
              <tbody>
                <tr>
                  <td style={{ width: "40vw" }}>
                    <span style={{ marginTop: 5 }}>
                      Error Notification Emails
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "40vw" }}>
                    {failure && emails && emails.length > 0 ? (
                      emails.map((ne, i) => (
                        <div key={i}>
                          <span className="email-monitoring-address">{ne}</span>
                          <br />
                        </div>
                      ))
                    ) : (
                      <div>
                        <span>No notifications set</span>
                        <br />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40vw" }}>
                    <span style={{ marginTop: 5 }}>
                      Success Notification Emails
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "40vw" }}>
                    {success && emails && emails.length > 0 ? (
                      emails.map((ne, i) => (
                        <div key={i}>
                          <span className="email-monitoring-address">{ne}</span>
                          <br />
                        </div>
                      ))
                    ) : (
                      <div>
                        <span>No notifications set</span>
                        <br />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40vw" }}>
                    <span style={{ marginTop: 5 }}>
                      Partial Success Notification Emails
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "40vw" }}>
                    {partialSuccess && emails && emails.length > 0 ? (
                      emails.map((ne, i) => (
                        <div key={i}>
                          <span className="email-monitoring-address">{ne}</span>
                          <br />
                        </div>
                      ))
                    ) : (
                      <div>
                        <span>No notifications set</span>
                        <br />
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>

        <Modal
          show={showManager}
          onHide={() => this.hideModal()}
          dialogClassName="notification-manage-section"
          backdrop="static"
        >
          <Header closeButton>
            <Title style={{ fontWeight: "bold" }}>Email Monitoring</Title>
          </Header>
          <Body style={{ background: "#eee" }}>
            <div style={{ paddingLeft: 20, paddingTop: 15 }}>
              <div style={{ marginBottom: -5 }} className="labelz">
                <strong>Send email alert on:</strong>
              </div>
              <Checkbox
                checked={this.state.success}
                onChange={() => this.setState({ success: !success })}
              >
                Successful Jobs
              </Checkbox>
              <Checkbox
                checked={this.state.failure}
                onChange={() => this.setState({ failure: !failure })}
              >
                Failed Jobs
              </Checkbox>
              <Checkbox
                checked={this.state.partialSuccess}
                onChange={() =>
                  this.setState({ partialSuccess: !partialSuccess })
                }
              >
                Partially Successful Jobs
              </Checkbox>
            </div>

            <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
              <div style={{ marginTop: 10 }} className="labelz">
                <strong>Recipient email addresses:</strong>
              </div>
              {newEmailList && newEmailList.length > 0 && (
                <ul
                  style={{ listStyle: "none", paddingLeft: 6, marginBottom: 4 }}
                >
                  {newEmailList.map((ne, i) => {
                    return (
                      <li key={i} style={{ height: 20 }}>
                        <X onClick={() => this.removeEmail(ne)} />
                        <span className="email-monitoring-address">{ne}</span>
                      </li>
                    );
                  })}
                </ul>
              )}
              <div>
                <FormControl
                  type="input"
                  placeholder="Add new email address"
                  value={newEmail}
                  onChange={(e) => this.setState({ newEmail: e.target.value })}
                  style={{ width: 200, height: 25, display: "inline-block" }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.addNewEmail(newEmail);
                    }
                  }}
                />
                <Button
                  bsSize="xsmall"
                  bsStyle="primary"
                  disabled={!newEmail}
                  style={{ verticalAlign: "top", margin: "1px 0 0 4px" }}
                  onClick={() => this.addNewEmail(newEmail)}
                >
                  Add
                </Button>
                {validationError && (
                  <div style={{ fontSize: "11px", color: "#ea5a5a" }}>
                    {validationError}
                  </div>
                )}
              </div>
              {/* <Button bsStyle="primary" onClick={this.save}
              style={{margin:'18px 0', width:133}}>
              {!isSaving ? 'Save Preferences' : 
              <img alt="small-spinner" src={images.ajax_loader_small} height="8" />}
            </Button> */}
              {/* <Button bsStyle="danger" onClick={this.delete}
              style={{margin:'18px 0px', width:59}}>
              {!isDeleting ? 'Clear' : 
              <img alt="small-spinner" src={images.ajax_loader_small} height="8" />}
            </Button> */}
            </div>
          </Body>
          <Footer>
            <Button
              bsStyle="primary"
              onClick={() => this.hideModal()}
              style={{ float: "left" }}
            >
              Cancel
            </Button>
            <Button
              bsStyle="primary"
              onClick={this.save}
              style={{ float: "right" }}
            >
              {!isSaving ? (
                "Save"
              ) : (
                <img
                  alt="small-spinner"
                  src={images.ajax_loader_small}
                  height="8"
                />
              )}
            </Button>
          </Footer>
        </Modal>
      </div>
    );
  }
}

const X = ({ onClick }) => {
  return (
    <svg
      height="12"
      viewBox="0 0 60 60"
      className="remove-email-monitoring-address"
      onClick={onClick}
    >
      <path
        style={{ strokeWidth: 5, strokeLinecap: "round" }}
        d="M 15.674663,15.572746 L 44.587629,44.485711 M 45.118838,15.420972 L 15.522889,45.016920"
      />
    </svg>
  );
};

export default EmailMonitoring;
