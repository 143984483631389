import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import jwtDecode from "jwt-decode";
import ExtendToken from "../components/utils/extendtoken";
import Header from "../components/header/header";
import initElevio from "../helpers/elevio";
import { connect } from "react-redux";
class LoggedIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayingTokenModal: false,
      secondsToExpire: 0, // resets to 30 when secondsToExpire < 30.
    };
  }
  componentDidMount() {
    if (this.props.auth && this.props.auth.loggedIn()) {
      this.interval = setInterval(() => {
        const token = this.props.auth.getToken();
        if (token) {
          const decodedToken = jwtDecode(token);
          const currentUnix = moment().format("X");
          const expireAt = moment.unix(decodedToken.exp);
          const secondsToExpire =
            Number(expireAt.format("X")) - Number(currentUnix);
          if (secondsToExpire < 300) {
            // 5 min left to expire.
            this.setState({
              displayingTokenModal: true,
              secondsToExpire,
            });
          } else if (this.state.displayingTokenModal) {
            this.setState({
              displayingTokenModal: false,
            });
          }
          if (secondsToExpire < 0) {
            this.props.logout();
          }
        }
      }, 1000);
    } else {
      // this.props.logout();
    }
    if (this.props.auth && this.props.auth.loggedIn()) {
      const { actions } = this.props;
      actions.callGetMainTenantList();
      actions.callGetMe();
      actions.callGetUsers();
      actions.callGetApps();
      actions.callGetAccessLevels();
      actions.callGetMasterRecommendedActions();
      actions.callGetProductizedIntegrationTypesForUser();
      initElevio(actions);
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { auth, redux, logout } = this.props;
    const { main } = redux;
    const { displayingTokenModal, secondsToExpire } = this.state;
    const profile = auth.getProfile();
    const { config } = redux;
    const { INTERCOM_APP_ID } = config;
    if (!profile) return <div />;

    window.Intercom("boot", {
      app_id: INTERCOM_APP_ID,
      name: profile.name,
      email: profile.email,
      created_at: new Date().getTime(),
    });

    // find 'sfoauth' in url to bypass header render
    let isAuthWindow = false;
    const pathArray = this.props.location.pathname.split("/");

    if (
      (pathArray &&
        (pathArray.includes("sfoauth") || pathArray.includes("dsoauth"))) ||
      pathArray.includes("details") ||
      pathArray.includes("sfjobs")
    ) {
      isAuthWindow = true;
    }
    return (
      <div id="page" className={displayingTokenModal ? "overlay" : ""}>
        {displayingTokenModal ? (
          <ExtendToken
            secondsToExpire={secondsToExpire}
            auth={auth}
            logout={logout}
          />
        ) : null}

        {main && !isAuthWindow && (
          <Header
            username={profile.name}
            userpic={profile.picture}
            logout={logout}
            {...this.props}
          />
        )}
      </div>
    );
  }
}

LoggedIn.propTypes = {
  logout: PropTypes.func,
  redux: PropTypes.object,
  auth: PropTypes.object,
};

export default connect()(LoggedIn);
