import React, { useState, useEffect, useRef } from "react";
import { Fragment } from "react";
import { Collapse } from "react-collapse";
import images from "../images";

export default (props) => {
  const [openedProp, setOpenedProp] = useState({});
  const { dependencies } = props;
  function handleOpen(keyName) {
    if (openedProp == keyName) {
      setOpenedProp({});
    } else {
      setOpenedProp(keyName);
    }
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function populateEV() {
    let a = [];
    Object.keys(dependencies).map(function (keyName, keyIndex) {
      let deps = dependencies[keyName];
      if (!_.isEmpty(deps)) {
        a.push(
          <Fragment>
            <span onClick={() => handleOpen(keyName)}>
              {" "}
              {openedProp == keyName ? (
                <img src={images.ExpandMore} role="presentation" />
              ) : (
                <img src={images.ExpandLess} role="presentation" />
              )}
              {capitalizeFirstLetter(keyName)}
            </span>
            <Collapse isOpened={openedProp == keyName}>
              {Object.keys(deps).map(function (intkeyName, intkeyIndex) {
                return (
                  <div style={{ marginLeft: 20 }}>
                    {" "}
                    <img
                      src={images.Bullet}
                      role="presentation"
                      style={{ height: 10, marginTop: -2, marginRight: 2 }}
                    />
                    <span style={{ fontSize: 12 }} key={intkeyIndex}>
                      {deps[intkeyName]}
                    </span>
                    <br />
                  </div>
                );
              })}
            </Collapse>
          </Fragment>
        );
      }
    });
    return a;
  }
  return <div style={{ paddingLeft: 10 }}>{populateEV()}</div>;
};
