import React, { PureComponent } from "react";
import BEMHelper from "react-bem-helper";
import { If, Then } from "react-if";
import { generateCronExpression, parseCronExpression } from "./utils";
import cronsTrue from "cronstrue";
import noop from "lodash/noop";
import PeriodicallyTab from "./components/PeriodicallyTab";
import PeriodicallyFrameTab from "./components/PeriodicallyFrameTab";
import FixedTimeTab from "./components/FixedTimeTab";

// import './cron-builder.styl'

var parser = require("cron-parser");

const styleNameFactory = new BEMHelper("cron-builder");

const components = [PeriodicallyTab, PeriodicallyFrameTab, FixedTimeTab];

const getActiveTabIndex = (props) => {
  const { cronExpression } = props;
  const parsedExpression = parseCronExpression(cronExpression);
  if (parsedExpression.hours.includes("-")) {
    return 1;
  } else {
    return 0;
  }
};

export default class CronBuilder extends PureComponent {
  static defaultProps = {
    cronExpression: "* * * * *",
    showResult: true,
    onChange: noop,
  };

  constructor(props, ctx) {
    super(props, ctx);
    const activeIndex = "0";
    this.state = {
      activeIndex,
      Component: components[activeIndex],
      generatedExpression: "",
      myVar: "",
    };
    this.generateExpression = this.generateExpression.bind(this);
  }
  componentDidMount() {
    this.setState({
      generatedExpression: this.props.cronExpression,
    });
  }
  generateExpression = () => {
    const { onChange, getData } = this.props;
    this.setState(
      {
        generatedExpression: generateCronExpression(
          this.presetComponent.getExpression()
          ),
        },
      () => {
        onChange(this.state.generatedExpression);
        getData({
          data: this.state.myVar,
          cronString: cronsTrue.toString(this.state.generatedExpression),
        });
      }
    );
  };

  selectTab = (activeIndex) => {
    return () => {
      this.setState({
        activeIndex,
        Component: components[activeIndex],
      });
    };
  };

  render() {
    const { cronExpression, showResult } = this.props;
    const { activeIndex, Component, generatedExpression, getData } = this.state;

    return (
      <div {...styleNameFactory()}>
        {this.selectTab(0)}

        <Component
          styleNameFactory={styleNameFactory}
          ref={(component) => (this.presetComponent = component)}
          expression={parseCronExpression(cronExpression)}
          generateExpression={this.generateExpression}
        />
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            {...styleNameFactory("action")}
            onClick={this.generateExpression}
            data-action
          >
            Preview your Schedule
          </button>
        </div>
     
      </div>
    );
  }
}

