import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";

import "react-tabs/style/react-tabs.css";
import Switch from "react-switch";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";

import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import images from "../images";
import { Link } from "react-router-dom";
import EditSchedule from "./EditSchedule";

const columns = [
  { name: "transformerName", title: "Recipe Name" },
  { name: "environmentName", title: "Environment" },
  { name: "days", title: "Day of Week" },
  { name: "frequency", title: "Frequency" },
  {
    name: "nextRun",
    title: "Next Run",
  },
  { name: "lastRun", title: "Last Run" },
  { name: "lastRunDuration", title: "Last Run Duration" },
  { name: "status", title: "Status" },
  { name: "actions", title: "Actions" },
];

const SummaryScheduleListView = ({ props, environment }) => {
  const { actions, redux, match } = props;
  const { project, events } = redux && redux;
  const { params } = match;
  const { appId } = params;
  const { TenantId } = project && project.tenantInfo;

  useEffect(() => {
    const { id } = environment;

    !id
      ? actions.callGetTriggerSummary(appId, TenantId)
      : actions.callGetTriggerSummary(appId, TenantId, id);
  }, [environment]);

  const tableHeader = ({ column, children, ...restProps }) => (
    <TableHeaderRow.Content column={column} {...restProps}>
      <strong>{children}</strong>
    </TableHeaderRow.Content>
  );

  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [pageSizes] = useState([10, 20, 50]);
  const [pageSize, setPageSize] = useState(10);
  const [isSummaryFilter, setIsSummaryFilter] = useState(false);
  const [isPausedColumns] = useState(["nextRun"]);
  const [recipeColumns] = useState(["transformerName"]);
  const [dayColumns] = useState(["days"]);
  const [linkColumns] = useState(["status"]);
  const [editColumns] = useState(["actions"]);
  const [columnWidths, setColumnWidths] = useState([
    { columnName: "transformerName", width: 250 },
    { columnName: "environmentName", width: 170 },
    { columnName: "days", width: 200 },
    { columnName: "frequency", width: 250 },
    { columnName: "nextRun", width: 180 },
    { columnName: "lastRun", width: 180 },
    { columnName: "lastRunDuration", width: 180 },
    { columnName: "status", width: 180 },
    { columnName: "actions", width: 120 },
  ]);

  const handleEdit = (row) => {
    setSelectedEvent(row);
    setIsModal(true);
  };

  const isPausedFormmatter = ({ row, value }) =>
    row.isSchedulesPaused ? (
      <strong style={{ color: "darkgray" }}>Paused</strong>
    ) : (
      value
    );

  const PausedEnvProvider = (props) => (
    <DataTypeProvider formatterComponent={isPausedFormmatter} {...props} />
  );

  const dayFormatter = ({ value }) => (
    <div role="group" className="btn-group">
      {daysOfWeek.map((day, index) => (
        <li
          key={index}
          outline="true"
          color="primary"
          style={{
            padding: "7px",
            lineHeight: 1,
            fontSize: "12px",
            fontWeight: value?.includes(index) ? 600 : 0,
          }}
          className={
            value?.includes(index) ? "btn btn-default active" : "btn btn-default"
          }
        >
          {day}
        </li>
      ))}
    </div>
  );

  const linkFormatter = ({ row ,value }) => 
    value ? (
      <Link to={value} target="_blank" rel="noopener noreferrer">
        {row.executionStatus}
      </Link>
    ) : (
      "Scheduled"
    );


  const recipeFormatter = ({ value, row }) =>
    value ? (
      <Link to={row.recipe} target="_blank" rel="noopener noreferrer">
        {value}
      </Link>
    ) : (
      "Scheduled"
    );

  const editFormatter = ({ row }) => {
    return <a onClick={() => handleEdit(row)}>Edit</a>;
  };

  const DayProvider = (props) => (
    <DataTypeProvider formatterComponent={dayFormatter} {...props} />
  );

  const LinkProvider = (props) => (
    <DataTypeProvider formatterComponent={linkFormatter} {...props} />
  );

  const RecipeProvider = (props) => (
    <DataTypeProvider formatterComponent={recipeFormatter} {...props} />
  );

  const EditProvider = (props) => (
    <DataTypeProvider formatterComponent={editFormatter} {...props} />
  );

  return (
    <div style={{ marginRight: 20 }}>
      <div style={{ display: "flex" }}>
        <h4 style={{ margin: 0, padding: 0 }}>Summary View</h4>
        <label
          htmlFor="summaryView"
          style={{ marginLeft: "auto", marginRight: 10 }}
        >
          <span style={{ marginRight: 5 }}>Enable Filter</span>
          <Switch
            checked={isSummaryFilter}
            onChange={() => setIsSummaryFilter(!isSummaryFilter)}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={17}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={17}
            width={33}
            className="react-switch"
            id="summaryView"
          />
        </label>
      </div>

      <Paper style={{ marginBottom: 40 }}>
        {events.summaryTriggersLoading ? (
          <div style={{ padding: 20, textAlign: "center", fontWeight: 600 }}>
            Loading...
          </div>
        ) : (
          <Grid rows={events.summaryTriggers} columns={columns}>
            {/* sorting */}
            <SortingState />
            <IntegratedSorting />

            {/* paging */}
            <PagingState
              defaultCurrentPage={0}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />

            <PagingPanel pageSizes={pageSizes} />

            <IntegratedPaging />

            {/* filtering */}
            {isSummaryFilter ? <FilteringState defaultFilters={[]} /> : null}
            {isSummaryFilter ? <IntegratedFiltering /> : null}

            {/* data formatter */}
            <DayProvider for={dayColumns} />
            <LinkProvider for={linkColumns} />
            <EditProvider for={editColumns} />
            <RecipeProvider for={recipeColumns} />
            <PausedEnvProvider for={isPausedColumns} />
            <Table />

            <TableColumnResizing
              defaultColumnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
            />

            <TableHeaderRow
              showSortingControls
              contentComponent={tableHeader}
            />
            {isSummaryFilter ? <TableFilterRow /> : null}
          </Grid>
        )}
      </Paper>

      <EditSchedule
        isModal={isModal}
        setIsModal={setIsModal}
        selectedEvent={selectedEvent}
        {...props}
      />
    </div>
  );
};

export default SummaryScheduleListView;
