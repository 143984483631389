import { type } from "jquery";
import * as types from "../actions/types";

const initialState = {
  apihubDetail: {},
  appData: null,
  tenantInfo: null,
  tenantCreds: null,
  resourceTypes: {},
  transformers: null,
  wizards: null,
  usage: [],
  features: [],
  lastBulkUpload: null,
  allBulkUploads: null,
  explorerData: [],
  resourceMeta: [],
  customAllowedFields: null,
  customMetadata: null,
  invalidExplorerData: [],
  invalidCount: 0,
  dataTabsSelected: 0,
  environments: null,
  environmentCredentials: null,
  permissions: null,
  availableWizardTypes: null,
  transformerLogs: null,
  transformerLogsCount: 0,
  recommendedActions: null,
  connectedExperienceData: null,
  connectedExperienceComments: null,
  allComments: null,
  allDeployments: null,
  dataMappings: null,
  dataMappingComments: null,
  envOverview: [],
  selectedEnv: null,
  showExitPrompt: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_APPDATA:
      const { data } = action;
      return {
        ...state,
        appData: data,
      };
    case types.SET_WORKSPACE_PERMISSIONS:
      return {
        ...state,
        permissions: action.data
          .filter((p) => p.Identity && p.Identity.id > 0)
          .sort((a, b) => {
            if (a.Identity.username < b.Identity.username) return -1;
            if (a.Identity.username > b.Identity.username) return 1;
            return 0;
          }),
      };
    case types.SET_FEATURES:
      return {
        ...state,
        features: action.data,
      };
    case types.SET_APIHUB_DETAIL:
      return {
        ...state,
        apihubDetail: action.data,
      };
    case types.SET_TENANT_WIZARD_TYPES:
      return {
        ...state,
        availableWizardTypes: action.data,
      };
    case types.SET_RESOURCE_META:
      return {
        ...state,
        resourceMeta: action.data,
      };
    case types.CHANGE_DESCRIPTION:
      const appData = { ...action.appData };
      appData.description = action.value;
      return {
        ...state,
        appData,
      };
    case types.SET_TENANTINFO:
      return {
        ...state,
        tenantInfo: action.data,
      };
    case types.SET_TENANTCREDS:
      return {
        ...state,
        tenantCreds: action.data,
      };

    case types.SET_RESOURCETYPES:
      const resData = {};
      for (let i = 0; i < action.data.length; ++i) {
        resData[action.data[i].Name] = 1;
      }
      return {
        ...state,
        resourceTypes: resData,
      };
    case types.SET_USAGE:
      const usage = action.data.resourceCounts;
      return {
        ...state,
        usage,
      };
    case types.SET_EXPLORER_DATA:
      return {
        ...state,
        explorerData: action.data.data,
      };
      break;
    case types.SET_INVALID_DATA:
      return {
        ...state,
        invalidExplorerData: action.data,
      };
      break;
    case types.SET_CONNECTEDEXP_DATA:
      return {
        ...state,
        connectedExperienceData: action.data,
      };
    case types.SET_CONNECTEDEXP_COMMENTS:
      return {
        ...state,
        connectedExperienceComments: action.data,
      };
    case types.SET_MAPPER_COMMENTS:
      return {
        ...state,
        dataMappingComments: action.data,
      };
    case types.SET_DATA_MAPPINGS:
      return {
        ...state,
        dataMappings: action.data,
      };
    case types.SET_ANALYTICS_DASHBOARD_URL:
      return {
        ...state,
        dashboard: action.data,
      };
    case types.SET_ALL_COMMENTS:
      return {
        ...state,
        allComments: action.data,
      };
    case types.SET_INVALID_COUNT:
      return {
        ...state,
        invalidExplorerCount: action.count,
      };
      break;
    case types.SET_TRANSFORMERS:
      return {
        ...state,
        // transformers: action.data,
        transformers: action.data.sort((a, b) => {
          if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
          if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
          return 0;
        }),
      };
    case types.SET_WIZARDS:
      return {
        ...state,
        wizards: action.data || [],
      };
    case types.SET_LAST_BULK_UPLOAD:
      return {
        ...state,
        lastBulkUpload: action.data,
      };
    case types.SET_BULK_UPLOADS:
      const oldData =
        (action.allBulkUploads && action.allBulkUploads.data) || [];
      const newData = action.data.data;
      const updatedData = oldData.concat(newData);
      const allBulkUploads = {
        count: action.data.count,
        data: updatedData,
      };
      return {
        ...state,
        allBulkUploads,
      };
    case types.CLEAR_BULK_UPLOADS:
      return {
        ...state,
        allBulkUploads: [],
      };
    case types.SET_CUSTOM_ALLOWED_FIELDS:
      return {
        ...state,
        customAllowedFields: action.data.allowedFields,
      };
    case types.SET_CUSTOM_METADATA:
      return {
        ...state,
        customMetadata: action.metadata,
      };
    case types.SET_DATA_TABS_SELECTED:
      return {
        ...state,
        dataTabsSelected: action.i,
      };
    case types.SET_TRANS_ENVIRONMENTS:
      return {
        ...state,
        environments: action.data,
      };

    case types.SET_ENVIRONMENTS_OVERVIEW:
      return {
        ...state,
        envOverview: state.envOverview
          .filter((p) => p.EnvId !== action.data.EnvId)
          .concat(action.data),
      };

    case types.SET_ENV_CREDS:
      return {
        ...state,
        environmentCredentials:
          action.data &&
          action.data.sort((a, b) => {
            if (a.providerLabel < b.providerLabel) return -1;
            if (a.providerLabel > b.providerLabel) return 1;
            return 0;
          }),
      };
    case types.SET_SELECTED_ENV:
      return {
        ...state,
        selectedEnv: action.data,
      };
    case types.SET_WORKSPACE_TRANSFORMER_LOGS:
      return {
        ...state,
        transformerLogs: action.data,
      };
    case types.SET_WORKSPACE_TRANSFORMER_LOGS_100:
      return {
        ...state,
        transformerLogs100: action.data,
      };
    case types.SET_WORKSPACE_TRANSFORMER_LOGS_COUNT:
      return {
        ...state,
        transformerLogsCount: action.data,
      };
    case types.SET_RECOMMENDED_ACTIONS:
      return {
        ...state,
        recommendedActions: action.data,
      };
    case types.SET_ALL_DEPLOYMENTS:
      return {
        ...state,
        allDeployments: action.data,
      };
    case types.CLEAR_PROJECT_DATA:
      return {
        ...initialState,
      };
    case types.SET_SHOW_APP_EXIT_PROMPT:
      return {
        ...state,
        showExitPrompt: action.data,
      };
    default:
      return state;
  }
};
