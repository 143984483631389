import React, { Component, PropTypes } from 'react';


export default class Loading extends Component {
    constructor() {
        super();
        this.state = {
            i: '.'
        };
    }

    componentDidMount() {
        this.interval = setInterval(()=>{
            this.setState({i: this.state.i.length < 15 ? this.state.i + '.' : '.'})
        },200); 
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        const style = {
            color: "#637E54",
            zIndex:1003
        }

        return(
            <div style={style}>
                {this.state.i}
            </div>
        )
    }
}