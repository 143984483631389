import ReactGA from "react-ga";
import images from "../components/images";
import moment from "moment";
import jwtDecode from "jwt-decode";
import isValidPath from "is-valid-path";
import Auth0Lock from "auth0-lock";

export default class AuthService {
  constructor(clientId, domain, actions, history) {
    const options = {
      theme: {
        logo: images.logo_small,
        primaryColor: "#6C63FF",
      },
      configurationBaseUrl: "https://cdn.auth0.com",
      languageDictionary: {
        title: "",
        signUpTerms:
          'By signing up, you agree to our <br/><a href="http://www.lingk.io/privacy-policy" target="_new">Privacy Policy</a> and <a href="http://www.lingk.io/terms-of-use" target="_new">Terms of Service</a>',
        socialLoginInstructions: `Need an account? <a href="https://www.lingk.io/requestdemo">Request a demo</a> today`,
      },
      //closable: false,
      auth: {
        redirectUrl: window.location.origin, // + '?r=1',
        responseType: "token",
      },
      allowLogin: true,
      allowSignUp: false,
      additionalSignUpFields: [],
    };

    this.actions = actions;
    this.history = history;

    const templateEntrance = this.getUrlParameterByName("template");
    if (templateEntrance) {
      ReactGA.ga("send", "event", {
        eventCategory: "User",
        eventAction: "Visit",
        eventLabel: "User has visited app from templates.",
      });
    }

    const subscriberEntrance = this.getUrlParameterByName("subscriber");
    if (subscriberEntrance) {
      options.allowSignUp = true;
      options.allowLogin = true;
      (options.initialScreen = "signUp"),
        (options.auth.params = {
          subscriber: subscriberEntrance,
          signup: "true",
        });
      ReactGA.ga("send", "event", {
        eventCategory: "User",
        eventAction: "Visit",
        eventLabel: "First visit by new subscriber.",
      });
    }

    const accessLevel = this.getUrlParameterByName("access");
    if (accessLevel) {
      options.allowSignUp = true;
      options.allowLogin = false;

      options.auth.params = { access: accessLevel, signup: "true" };
      ReactGA.ga("send", "event", {
        eventCategory: "User",
        eventAction: "Visit",
        eventLabel: "New user has visited app, from free trial form.",
      });
    }

    const invitationKey = this.getUrlParameterByName("invitationKey");
    if (invitationKey) {
      options.allowSignUp = true;
      options.allowLogin = true;
      options.initialScreen = "signUp";
      options.auth.params = { invitationkey: invitationKey, signup: "true" };
      ReactGA.ga("send", "event", {
        eventCategory: "User",
        eventAction: "Visit",
        eventLabel: "New potential user has visited app, with invitation code.",
      });
    }

    // if (!invitationKey && !subscriberEntrance){
    //   options.additionalSignUpFields.push({
    //     name: 'tenant',
    //     placeholder: 'organization name',
    //     icon:'https://s3-us-west-2.amazonaws.com/data-protect-config/org1.svg',
    //     validator: (address) => {
    //       return {
    //         valid: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(address),
    //         hint: 'Do not use special characters in Organization Name' // optional
    //       };
    //     }
    //   })
    // }

    // add pathname into auth0 redirect state for deeplinking on sign in
    const pathname = window.location.pathname + window.location.search;
    if (pathname && pathname.length > 1) {
      options.auth = options.auth || {};
      options.auth.params = options.auth.params || {};
      options.auth.params.state = btoa(pathname);
      //options.auth.params.state this is where you store url extensions
    }

    // Configure Auth0
    this.lock = new Auth0Lock(clientId, domain, options);

    // Add callback for lock `authenticated` event
    this.lock.on("authenticated", this._doAuthentication.bind(this));
    // add signup callback
    this.lock.on("signup submit", this._signedUp.bind(this));
    // add signin callback
    //this.lock.on('signin submit', this._signedIn.bind(this));
    // binds login functions to keep this context
    this.login = this.login.bind(this);
  }
  _isPublicPage(redirectUrl) {
    return redirectUrl.includes("/datamapping/");
  }
  _doAuthentication(authResult) {
    // Async loads the user profile data
    this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
      if (error) {
        console.log("Error loading the Profile", error);
      } else {
        ReactGA.ga("send", "event", {
          eventCategory: "User",
          eventAction: "Login",
          eventLabel: `${profile.email} logged in.`,
          dimension1: profile.user_id,
        });

        this.setToken(authResult.idToken);
        this.setProfile(profile);

        const { state } = authResult;
        if (state) {
          try {
            const redirectUrl = atob(state);
            //decode the state coming thru Auth0
            if (
              redirectUrl &&
              (redirectUrl.includes("/sfoauth") ||
                redirectUrl.includes("/dsoauth") ||
                redirectUrl.includes("/details") ||
                redirectUrl.includes("/sfjobs") ||
                redirectUrl.includes("/user"))
            ) {
              this.history.push(redirectUrl);
            } else if (redirectUrl && redirectUrl.includes("template")) {
              this.history.push(redirectUrl);
            } else if (redirectUrl && redirectUrl.includes("subscriber")) {
              const subKey = redirectUrl.replace("/?subscriber=", "");
              this.actions
                .addNewSubscriberExistingUserToTenant(subKey)
                .then((a) => {
                  this.history.push(redirectUrl);
                });
            } else if (redirectUrl && redirectUrl.includes("register")) {
              this.history.push("/");
            } else if (
              redirectUrl &&
              isValidPath(redirectUrl) &&
              !this._isPublicPage(redirectUrl)
            ) {
              this.history.push(redirectUrl);
            } else {
              this.history.push("/");
            }
          } catch (e) {
            this.history.push("/");
          }
        }
      }
    });
  }
  _signedUp() {
    ReactGA.ga("send", "event", {
      eventCategory: "User",
      eventAction: "Signup",
      eventLabel: "New user attempted to sign up!",
    });
  }
  _signedIn() {
    ReactGA.ga("send", "event", {
      eventCategory: "User",
      eventAction: "Signin",
      eventLabel: "New user attempted to sign in!",
    });
  }
  login(options) {
    // Call the show method to display the widget.
    localStorage.removeItem("userToken");
    const pathname = window.location.pathname;
    if (pathname && pathname.length > 1) {
      window.location.path = "/";
    }
    this.lock.show(options || {});
  }
  logout() {
    // Clear user token and profile data from local storage
    window.Intercom("shutdown");
    localStorage.removeItem("userToken");
    localStorage.removeItem("profile");
    window._elev.logoutUser();
  }
  loggedIn() {
    // Checks if there is a saved token and it's still valid
    //return !!this.getToken();
    if (this.getToken()) {
      const decodedToken = jwtDecode(this.getToken());
      const currentUnix = moment().format("X");
      const expireAt = moment.unix(decodedToken.exp);
      const secondsToExpire =
        Number(expireAt.format("X")) - Number(currentUnix);
      return secondsToExpire > 0;
    }
    return false;
  }

  localStorageIsSupported(storage) {
    try {
      const key = "testing_testing_1_2_3_";
      storage.setItem(key, key);
      storage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem("userToken", idToken);
  }
  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("userToken");
  }
  setProfile(profile) {
    // Saves profile data to local storage
    localStorage.setItem("profile", JSON.stringify(profile));
  }
  getProfile() {
    // Retrieves the profile data from local storage
    const profile = localStorage.getItem("profile");
    return profile ? JSON.parse(localStorage.profile) : {};
  }
  getUrlParameterByName(queryName) {
    const url = window.location.href;
    const name = queryName.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  // getStateByName(queryName) {
  //   const { state } = this.props.location.state.subscriber
  //   const name = queryName.replace(/[\[\]]/g, '\\$&')
  //   const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  //   const results = regex.exec(url)
  //   if (!results) return null
  //   if (!results[2]) return ''
  //   return decodeURIComponent(results[2].replace(/\+/g, ' '))
  // }
}

/* AUTH0 RULE



function (user, context, callback) {

  var CLIENTS_ENABLED = [''];

  // run only for the specified clients
  if (CLIENTS_ENABLED.indexOf(context.clientID) === -1) {
    return callback(null, user, context);
  }

  // initialize user_metadata
  user.user_metadata = user.user_metadata || {};
  
  // update scope so token is not so large
  context.accessToken.scope = ['openid','email','user_metadata','picture'];

  // if it is the first login (hence the `signup`) 
  if (user.user_metadata.signedUp!==true){ //&& user.identities[0].isSocial) {
    
    var isSignup = context.request.query.signup || context.request.body.signup;
    if(isSignup!=='true'){ // for social login faking a signup
      console.log("UNAUTHORIZED");
      return callback(new UnauthorizedError("Please sign up through http://www.lingk.io/pricing"));
    }
    
    var state = context.request.query.state || context.request.body.state;
    var invitation = context.request.query.invitation || context.request.body.invitation;
    if(invitation){
      user.user_metadata.invitation = invitation;
    }
    
    var access = context.request.query.access || context.request.body.access;
    console.log(access);
    if(access){
      user.user_metadata.access = access;
    }
    request.post('https://lingk-int.auth0.com/oauth/token', {
      json:{
        grant_type: 'client_credentials',
        client_id: '',
        client_secret: '',
        audience: 'https://dataprotectapi.lingkapps.com/v1/newuser'
      }
    }, function(e,r,b) {
      if (!e && r.statusCode === 200) {
        //'https://dataprotectapi.lingkapps.com/v1/newuser'
        console.log({
          userId: user.user_id,
          email: user.email,
          metadata: user.user_metadata
        });
        request.post('https://dataprotectapi.lingkapps.com/v1/newuser', {
          headers:{
            'Authorization':'Bearer ' + b.access_token
          },
          json:{
            userId: user.user_id,
            email: user.email,
            metadata: user.user_metadata
          }
        }, function (error, response, body) {
            if (!error && response.statusCode === 200) {
              console.log("SUCCESS",response.statusCode);
              console.log(user, context);
  
              // UPDATE USER to avoid double call
              user.user_metadata.signedUp = true;
              auth0.users.updateUserMetadata(user.user_id, user.user_metadata)
              .then(function(){
                 callback(null, user, context);
              })
              .catch(function(err){
                 return callback(err);
              });
              // add callback here with new user metadata
            } else if(!error) {
              console.log(response.statusCode);
              return callback(new UnauthorizedError("Error posting to lingk"));
            } else {
              console.log(error);
              return callback(new UnauthorizedError("Error posting to lingk")); 
            }
        });
      } else {
        console.log("Error getting client credentials");
        return callback(new UnauthorizedError("Error getting client credentials"));
      }
    });
  } else {
    // not a signup
    callback(null, user, context);
  }
  //console.log(user,context);
  //callback(null, user, context);
}



*/
