import React from "react";
import { useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import MonthlyUsageBarChart from "./monthlyHistory";
const { Body, Title, Header, Footer } = Modal;

const HistoryModal = ({ isOpen, setIsOpen, data }) => {
  return (
    isOpen && (
      <div>
        <Modal
          bsSize="large"
          show={true}
          onHide={() => setIsOpen(false)}
          backdrop="static"
        >
          <Header closeButton>
            <Title>
              <strong>DPH History</strong>
            </Title>
          </Header>
          <Body>
            <div style={{ padding: "10px" }}>
            <MonthlyUsageBarChart monthlyDPH={data && data.monthlyDPH} licensedPeriodDPH={data && data.licensedPeriodDPH} />
            </div>
          </Body>
          <Footer>
            <Button
              bsSize="small"
              bsStyle="primary"
              style={{ display: "inline-block" }}
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
          </Footer>
        </Modal>
      </div>
    )
  );
};

export default HistoryModal;
