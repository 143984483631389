import images from '../images'

const alltypes = [
      {
        name: 'Ethos Analytics',
        logo: `${images.Ethos}`,
        value: 'ethos analytics'
      },
      {
        name: 'Google Spreadsheet',
        logo: `${images.globe}`,
        value: 'google spreadsheet'
      },
      {
        name: 'PowerBI',
        logo: `${images.globe}`,
        value: 'PowerBI'
      },
      {
        name: 'Salesforce',
        logo: `${images.Salesforce}`,
        value: 'salesforce'
      },
      {
        name: 'Tableau',
        logo: `${images.globe}`,
        value: 'tableau'
      },
      {
        name: 'Other',
        value: 'other'
      },
]

export default alltypes