import React, { Component } from "react";
import images from "../images";
import {
  Button,
  DropdownButton,
  FormGroup,
  FormControl,
  MenuItem,
  Modal,
} from "react-bootstrap";
const { Body, Title, Header, Footer } = Modal;
import ConfirmDeleteModal from "../utils/confirmDeleteModal";
import _ from "lodash";
import Connectors from "../environments/Connector";
import Switch from "react-switch";
import * as indexDb from "../../db/recipesDOA";
import ReactTooltip from "react-tooltip";
import { hours, minutes } from "../../data/time";

const styles = {
  r: {
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  envBox: {
    border: "1px solid black",
    marginRight: 4,
    marginTop: 3,
    marginLeft: 5,
    height: 20,
    width: 20,
  },
  checkBox: {
    marginRight: 4,
    marginTop: 3,
    marginLeft: 5,
    height: 20,
    width: 20,
  },
  options: {
    borderRadius: "50%",
    width: "25px",
    background: "#d2cece",
    backgroundImage: `url("${images.MoreOptions}")`,
    height: 27,
    marginTop: -3,
    marginRight: -7,
  },
};

export default class FilesBar extends Component {
  constructor() {
    super();
    this.someRefName = React.createRef();

    this.state = {
      environ: null,
      orderAlpha: false,
      orderType: false,
      searchTerm: "",
      environDropDownOpened: false,
      environDropDownForceOpen: false,
      selectedForDelete: null,
      isDeleting: null,
      modal: false,
      sortType: 0,
      showWiz: true,
      showRec: true,
      selectedRecipe: null,
      recipeEdit: false,
      recipeToEdit: null,
      recipeRename: false,
      showPropertiesModal: false,
      copyofRecipe: null,
      copyRecipeName: "",
      refresh: true,
      selectedEnvironment: null,
      showNewConnector: false,
      modeCheck: false,
      name: "",
      tag: "",
      description: "",
      processingType: "batch",
      threasholdHours: "",
      threasholdMinutes: "",
      indexedRecipes: null,
      showEditConnector: false,
      envCreds: null,
      offsetTop: 0,
      offsetLeft: 0,
      hoveredRecipe: null,
      notificationEmail: {},
      emailText: "",
      emailValidation: "",
    };
    this.inputRefs = [];

    this.setRef = (ref) => {
      this.inputRefs.push(ref);
    };
    this.newTransformer = this.newTransformer.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.sortAlphabetically = this.sortAlphabetically.bind(this);
    this.sortTimeWise = this.sortTimeWise.bind(this);
    this.sortType = this.sortType.bind(this);
    this.sortTrans = this.sortTrans.bind(this);

    this.selectEnviron = this.selectEnviron.bind(this);
    this.onToggleEnviron = this.onToggleEnviron.bind(this);

    this.deleteRecipe = this.deleteRecipe.bind(this);
    this.handleSelectEdit = this.handleSelectEdit.bind(this);
    this.warnBeforeDelete = this.warnBeforeDelete.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
    this.addEmail = this.addEmail.bind(this);
    this.deletEmail = this.deletEmail.bind(this);
    this.getThreasholdHours = this.getThreasholdHours.bind(this);
    this.getThreasholdMinutes = this.getThreasholdMinutes.bind(this);
  }

  onToggleEnviron(v) {
    window.setTimeout(() => {
      if (!this.state.environDropDownForceOpen) {
        this.setState({ environDropDownOpened: v });
      }
    }, 10);
  }

  async componentWillMount() {
    const { appId } = this.props.match.params;
    const { main, transformer } = this.props.redux;
    const { project } = main;
    let recipes = await indexDb.GetAllRecipes(appId);

    if (
      transformer &&
      transformer.versions &&
      transformer.versions.length > 0
    ) {
      this.setState({
        indexedRecipes: recipes,
        name: transformer.versions[0].Name,
        description: transformer.versions[0].Description,
        tag: transformer.versions[0].Environ,
        processingType: transformer.versions[0].ProcessingType,
        threasholdHours: this.getThreasholdHours(
          transformer.versions[0].maxDataProcTime
        ),
        threasholdMinutes: this.getThreasholdMinutes(
          transformer.versions[0].maxDataProcTime
        ),
      });
    }
    this.forceUpdate();
  }

  handlePropertiesChange(r) {
    const { transformer } = this.props.redux;
    const { actions } = this.props;

    if (r) {
      actions.callGetTransformersEmails(r.BaseId);

      const threasholdHours = this.getThreasholdHours(r.maxDataProcTime);
      const threasholdMinutes = this.getThreasholdMinutes(r.maxDataProcTime);

      this.setState({
        showPropertiesModal: true,
        name: r.Name,
        description: r.Description,
        tag: r.Environ,
        processingType: r.processingType,
        threasholdHours: threasholdHours,
        threasholdMinutes: threasholdMinutes,
        notificationEmail: transformer.notificationEmail.emails,
        recipeEdit: false,
        modeCheck: r.processingType == "stateless" ? true : false,
      });
    }
  }

  getThreasholdHours(date) {
    if (date) {
      const time = date.split("T")[1];
      const hours = time.split(":")[0];
      return hours;
    } else return "00";
  }

  getThreasholdMinutes(date) {
    if (date) {
      const time = date.split("T")[1];
      const minutes = time.split(":")[1];
      return minutes;
    } else return "00";
  }

  addEmail() {
    const { actions } = this.props;
    const email = this.state.emailText;

    actions.addNotificationEmail(email);
    this.setState({ emailText: "" });
  }

  deletEmail(id) {
    const { actions } = this.props;
    actions.deleteNotificationEmail(id);
  }

  changeName = (e) => {
    const searchTerm = e;
    this.setState({ searchTerm });
  };

  handleChange = (modeCheck) => {
    if (modeCheck) this.setState({ processingType: "stateless" });
    else this.setState({ processingType: "batch" });
    this.setState({ modeCheck });
  };

  selectEnviron() {
    this.setState({ environDropDownForceOpen: true });
    window.setTimeout(() => {
      this.setState({ environDropDownForceOpen: false });
    }, 100);
  }
  handleClick(event) {
    if (!event.target.checked || event.target.value === "ALL") {
      this.setState({ environ: null, showWiz: true });
    } else {
      this.setState({ environ: event.target.value, showWiz: false });
    }
  }
  newTransformer() {
    this.props.history.push({
      pathname: "/a/" + this.props.match.params.appId + "/tf/new",
    });
  }

  sortTrans() {
    let { sortType } = this.state;
    sortType++;
    if (sortType > 1) {
      sortType = 0;
    }
    this.setState({ sortType });
  }

  sortAlphabetically() {
    this.setState({ orderAlpha: true, orderType: false });
  }

  sortTimeWise() {
    this.setState({ orderAlpha: false, orderType: false });
  }

  sortType() {
    this.setState({ orderType: true });
  }

  handleSelectEdit(a, e, c) {
    c.stopPropagation();
    const { appId } = this.props.match.params;
    this.props.history.push(`/a/${appId}/tf/${e}/info`);
  }

  triggerModal() {
    let modal = this.state.modal;
    modal = !modal;
    this.setState({ modal });
  }

  deleteRecipe() {
    const { appId } = this.props.match.params;
    const { project } = this.props.redux;
    const target =
      this.state.selectedForDelete && this.state.selectedForDelete.BaseId;
    const piGuid =
      this.state.selectedForDelete &&
      this.state.selectedForDelete.ProductizedIntegrationGuid;

    if (piGuid) {
      this.props.actions.callDeleteWizard(
        project.tenantInfo.TenantId,
        appId,
        piGuid
      );
    } else {
      this.props.actions.callDeleteTransformer(appId, target);
      indexDb.DeleteSingleRecipe(target).then(() => {
        indexDb.GetAllRecipes(appId).then((recipes) => {
          this.setState({ indexedRecipes: recipes });
        });
      });
    }

    this.triggerModal();
    this.setState({ selectedForDelete: null, isDeleting: target });
  }
  async changeRecipe(r) {
    this.props.history.push({
      pathname: `/a/${r.appId}/tf/${r.BaseId}`,
    });
  }
  async componentDidUpdate(prevProps, prevState) {
    const { env, redux, match } = this.props;
    const { params } = match;
    const { project, transformer } = redux;
    if (this.props.unSaved != prevProps.unSaved) {
      let recipes = await indexDb.GetAllRecipes(project.appData.Id);
      this.setState({ indexedRecipes: recipes });
    }
    const { environments } = project;
    const { appId } = params;

    if (this.state.selectedRecipe) {
      if (
        transformer.versions &&
        transformer.versions[0].BaseId != this.state.selectedRecipe.BaseId
      ) {
        this.setState({
          selectedRecipe: transformer.versions[0],
        });
      }
    } else if (transformer.versions && transformer.versions[0]) {
      this.setState({
        selectedRecipe: transformer.versions[0],
      });
    }
    const { sessionEnvironment } = this.props.redux.environment;
    if (prevState.selectedEnvironment == null && sessionEnvironment) {
      const se =
        project.environments &&
        project.environments.find(
          (f) => f.environmentId === sessionEnvironment
        );
      if (se && project.tenantInfo && project.tenantInfo.TenantId) {
        this.setState({ selectedEnvironment: se });
        this.props.actions.getAllCredentialsForEnvironment(
          project.tenantInfo.TenantId,
          appId,
          se.name
        );
      }
    } else if (
      sessionEnvironment !=
      (prevState.selectedEnvironment && prevState.selectedEnvironment.id)
    ) {
      const se =
        project.environments &&
        project.environments.find(
          (f) => f.environmentId === sessionEnvironment
        );
      if (se) {
        this.setState({ selectedEnvironment: se });
        // this.getEnvCreds(se);

        this.props.actions.getAllCredentialsForEnvironment(
          project.tenantInfo.TenantId,
          appId,
          se.name
        );
      }
    }
  }

  showEditrecipe = (e, recipe, index) => {
    let targetY, targetX;
    if (this.inputRefs && this.inputRefs.length > 0) {
      if (this.inputRefs[index].getBoundingClientRect()) {
        targetY = this.inputRefs[index].getBoundingClientRect().top;
        targetX = this.inputRefs[index].getBoundingClientRect().left;
      }
    }

    e.stopPropagation();
    if (
      this.state.recipeToEdit != null &&
      recipe.Id == this.state.recipeToEdit.Id
    ) {
      this.setState({ recipeEdit: false, recipeToEdit: null });
    } else {
      this.setState({
        recipeEdit: true,
        recipeToEdit: recipe,
        offsetTop: targetY + 35,
        offsetLeft: targetX + 180,
      });
    }
  };
  saveTransformer(toUpdate) {
    if (toUpdate != "name") this.setState({ showPropertiesModal: false });
    const { transformer, project } = this.props.redux;
    const { appId, transId } = this.props.match.params;
    const str = this.state.name;
    let a = str.substring(str.length - 3);
    let name;
    if (a != ".lr") {
      name = this.state.name + ".lr";
    } else {
      name = this.state.name;
    }

    let maxDataProcTime;

    if (toUpdate === "name") {
      const hours = this.getThreasholdHours(
        transformer.versions[0].maxDataProcTime
      );

      const minutes = this.getThreasholdMinutes(
        transformer.versions[0].maxDataProcTime
      );

      maxDataProcTime = `0001-01-01T${hours}:${minutes}:00Z`;
    } else {
      maxDataProcTime = `0001-01-01T${this.state.threasholdHours}:${this.state.threasholdMinutes}:00Z`;
    }

    this.props.actions
      .callUpdateTransformerInfo(
        {
          name: name,
          config: transformer.versions[0].Config,
          environ: this.state.tag,
          version: transformer.versions[0].Version,
          baseid: transformer.versions[0].BaseId,
          id: transformer.versions[0].Id,
          description: this.state.description,
          languageVersion: transformer.versions[0].LanguageVersion || "2.0",
          processingType: this.state.processingType,
          maxDataProcTime: maxDataProcTime,
        },
        appId,
        transId
      )
      .then(
        () =>
          transformer.notificationEmail.emails.length &&
          this.props.actions.callUpdateTransformerEmailList(
            {
              emails: transformer.notificationEmail.emails,
            },
            transId
          )
      )
      .then(() => {
        indexDb
          .UpdateRecipeName(
            transformer.versions[0].BaseId,
            name,
            this.state.tag,
            this.state.description,
            this.state.processingType,
            `0001-01-01T${this.state.threasholdHours}:${this.state.threasholdMinutes}:00Z`
          )
          .then(() => {
            this.setState({
              recipeToEdit: null,
              name: "",
              recipeRename: false,
            });
            indexDb.GetAllRecipes(project.appData.Id).then((recipes) => {
              this.setState({ indexedRecipes: recipes });
            });
          });
      });
  }
  changeNameInput = (e) => {
    this.setState({ name: e.target.value });
  };
  onKeyUpCopy = (event, r) => {
    if (event.charCode === 13) {
      this.makeACopy(r);
    }
  };
  onKeyUp = (event) => {
    if (event.charCode === 13) {
      this.saveTransformer("name");
    }
  };
  changeDescriptionInput = (e) => {
    this.setState({ description: e.target.value });
  };
  changeTagInput = (e) => {
    this.setState({ tag: e.target.value });
  };
  getRecipeName = (string) => {
    if (string && string.length > 25) {
      return this.state.hoveredRecipe == string
        ? string.slice(0, 25) + ".."
        : string.slice(0, 28) + "..";
    } else return string;
  };
  warnBeforeDelete = (a, e, c) => {
    c.stopPropagation();
    this.setState({ selectedForDelete: e, recipeEdit: false });
    this.triggerModal();
  };
  makeACopy = (r) => {
    const { project } = this.props.redux;
    const name = this.state.copyRecipeName;
    if (name == "") {
      alert("Cannot save an empty recipe");
      this.setState({ copyRecipeName: "", copyofRecipe: null });
      return;
    }
    const config = r.Config;
    const description = r.description;

    this.props.actions
      .callCreateTransformer(
        {
          name,
          config,
          description,
          environ: "",
          version: 1,
          languageVersion: "2.0",
          processingType: "batch",
        },
        this.props.match.params.appId
      )
      .then((r) => {
        indexDb.AddRecipe(r).then(() => {
          indexDb.GetAllRecipes(project.appData.Id).then((recipes) => {
            this.setState({ indexedRecipes: recipes });
          });

          this.setState({ copyofRecipe: null, copyRecipeName: "" });
        });
      });
  };

  handleExecutionPageofRecipe = (e, baseId) => {
    e.stopPropagation();
    const { appId } = this.props.match.params;
    this.props.history.push({
      pathname: `/a/${appId}/health`,
      search: `?recipe=${baseId}`,
    });
  };
  componentDidMount() {
    const { transformer } = this.props.redux;
    if (transformer && transformer.versions) {
      this.setState({ selectedRecipe: transformer.versions[0] });
    }
  }
  getEnvCreds = (env) => {
    const { actions, redux, match } = this.props;
    const { params } = match;
    const { project } = redux;
    const skipRedux = true; // dont put creds in reducer
    actions
      .getAllCredentialsForEnvironment(
        project.tenantInfo.TenantId,
        params.appId,
        env.name,
        skipRedux
      )
      .then((envCreds) => {
        this.setState({
          envCreds: envCreds.filter(
            (c) => c.credentialType !== "AdapterSecret"
          ),
        });
      });
  };
  toggleHover = (i) => {
    this.setState({
      hoveredRecipe: i ? i.Name : null,
    });
  };
  render() {
    const { redux, match } = this.props;
    const { project, transformer } = redux;
    const { params } = match;
    const { searchTerm, selectedEnvironment } = this.state;
    const { appId } = params;

    let transRows = [];
    let connectorRows = [];
    const environs = {};
    const adapterSecretCred =
      project.environmentCredentials &&
      project.environmentCredentials.find(
        (ec) => ec.credentialType === "AdapterSecret"
      );
    const adapterSecret = adapterSecretCred && adapterSecretCred.credentials;
    if (
      project.environmentCredentials &&
      project.environmentCredentials.length > 0
    ) {
      const objs = [...project.environmentCredentials];
      for (let i = 0; i < objs.length; i++) {
        if (objs[i].providerLabel !== "EnvironmentVariables")
          connectorRows.push(
            <div
              key={i}
              onClick={() => this.setState({ showEditConnector: true })}
              className="filebar-connector-cell"
              style={{ width: 200 }}
            >
              <div style={{ ...styles.r, width: "86%" }}>
                <span
                  style={{
                    fontSize: 12,
                    // fontFamily: "Google Sans",
                    marginLeft: 2,
                  }}
                >
                  {" "}
                  {objs[i].providerLabel}
                </span>
              </div>
            </div>
          );
      }
    }
    let objs;
    if (this.state.indexedRecipes && this.state.indexedRecipes.length > 0) {
      objs = [...this.state.indexedRecipes];
    } else if (project.transformers && project.transformers.length > 0) {
      objs = [...project.transformers];
    }
    if (objs && objs.length > 0) {
      objs = objs.filter((t) => !t.GeneratedFromPiTypeId);

      for (let i = 0; i < objs.length; i++) {
        const isSelected =
          (this.state.selectedRecipe && this.state.selectedRecipe.Id) ==
          objs[i].Id;
        const recipeEdit =
          (this.state.recipeToEdit && this.state.recipeToEdit.Id) == objs[i].Id;
        if (!objs[i].GeneratedFromPiTypeId) {
          const searched =
            searchTerm === "" ||
            objs[i].Name.toLowerCase().includes(searchTerm.toLowerCase());
          let unSaved = objs[i].edited;
          if (searched) {
            transRows.push(
              <div
                key={i}
                ref={this.setRef}
                onClick={() => this.changeRecipe(objs[i])}
                className="filebar-connector-cell"
                style={{
                  background: isSelected ? "#e8f0fe" : "",
                }}
                onMouseEnter={() => this.toggleHover(objs[i])}
                onMouseLeave={() => this.toggleHover(null)}
              >
                {unSaved && (
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    style={{ marginTop: 3 }}
                  >
                    <circle fill="#EA8600" cx="5.5" cy="5.5" r="5.5"></circle>
                    <circle fill="#FEDFC8" cx="5.5" cy="5.5" r="4.5"></circle>
                  </svg>
                )}

                {this.state.recipeRename && recipeEdit ? (
                  <div>
                    <input
                      defaultValue={this.state.name}
                      className="form-control"
                      type="text"
                      style={{ width: 170, height: 28 }}
                      onChange={this.changeNameInput}
                      value={this.state.name}
                      maxLength="50"
                      onKeyPress={this.onKeyUp}
                      onBlur={() =>
                        this.setState({
                          recipeRename: false,
                          recipeEdit: false,
                        })
                      }
                    />
                  </div>
                ) : (
                  <span
                    data-tip={objs[i].Name}
                    style={{
                      fontSize: 13,
                      marginLeft: 2,
                      color: isSelected ? "#2975e8" : "",
                      ...styles.r,
                    }}
                  >
                    {" "}
                    {this.getRecipeName(objs[i].Name)}
                  </span>
                )}
                {recipeEdit && this.state.recipeEdit ? (
                  <div
                    style={styles.options}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ recipeEdit: false, recipeToEdit: null });
                    }}
                  ></div>
                ) : (
                  <img
                    className="more-option-connector-cell"
                    style={{
                      float: "right",
                      background: "#dfe0e0",
                      marginRight: -5,
                    }}
                    src={images.MoreOptions}
                    onClick={(e) => this.showEditrecipe(e, objs[i], i)}
                  />
                )}
              </div>
            );

            if (
              this.state.copyofRecipe &&
              this.state.copyofRecipe.Id == objs[i].Id
            ) {
              transRows.push(
                <div>
                  <input
                    autoFocus
                    className="form-control"
                    type="text"
                    style={{ width: 178, height: 35 }}
                    onChange={(e) =>
                      this.setState({ copyRecipeName: e.target.value })
                    }
                    value={this.state.copyRecipeName}
                    maxLength="50"
                    onKeyPress={(e) => this.onKeyUpCopy(e, objs[i])}
                    onBlur={() =>
                      this.setState({ copyofRecipe: null, copyofRecipe: null })
                    }
                  />
                </div>
              );
            }
          }
        }
        //}
        environs[objs[i].Environ] = 1;
      }
    }

    if (project && project.transformers && project.transformers.length < 1) {
      transRows = (
        <div className="none-env" style={{ marginTop: -10 }}>
          <span style={{ fontSize: 16 }}>No Recipes.</span>
          <br />
          <Button
            bsSize="small"
            bsStyle="primary"
            style={{ marginTop: 6 }}
            onClick={this.newTransformer}
          >
            New Recipe
          </Button>
        </div>
      );
    } else if (transRows < 1) {
      transRows = (
        <div style={{ textAlign: "center", marginTop: 30 }}>
          <span style={{ margin: "auto", fontSize: 16 }}>No match</span>
        </div>
      );
    }
    return (
      <div
        style={{
          marginTop: 35,
          paddingTop: 15,
          paddingBottom: 50,
          width: "222px",
          marginLeft: 55,
          background: "white",
          borderRight: "1px #dadce0 solid",
        }}
      >
        <div
          style={{
            display: "inline-block",
            overflow: "unset",
            background: "white",
            width: 200,
            width: "100%",
            height: "93vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <div style={{ height: 40, border: "1px #dadce0 solid" }}>
              <span className="files-header" style={{ fontWeight: "bold" }}>
                Recipes
              </span>
              <img
                src={images.RecipeBarAdd}
                style={{ margin: 4, float: "right", height: 26 }}
                onClick={this.newTransformer}
              />
            </div>
            <FormGroup
              controlId="formBasicText"
              style={{ marginBottom: "unset" }}
            >
              <FormControl
                type="text"
                maxLength="50"
                autoComplete="on"
                placeholder="Search"
                style={{ width: 220, height: 32 }}
                value={this.state.searchTerm}
                onChange={(e) => this.changeName(e.target.value)}
              />
            </FormGroup>
            {project.transformers ? (
              <div style={{ overflowY: "auto", maxHeight: "50vh" }}>
                {transRows}
              </div>
            ) : (
              <div className="ajax-loader">
                <img alt="spinner" src={images.ajax_loader} />
              </div>
            )}

            {this.state.recipeEdit && (
              <div
                style={{
                  width: 172,
                  height: "auto",
                  minWidth: 192,
                  display: "grid",
                  position: "absolute",
                  background: "white",
                  zIndex: 9,
                  top: this.state.offsetTop,
                  left: this.state.offsetLeft,
                }}
              >
                <span
                  className="recipe-edit-cell"
                  onClick={() =>
                    this.setState({
                      recipeRename: true,
                      recipeEdit: false,
                      name: this.state.recipeToEdit.Name,
                    })
                  }
                >
                  Rename
                </span>
                <span
                  className="recipe-edit-cell"
                  onClick={() =>
                    this.handlePropertiesChange(this.state.recipeToEdit)
                  }
                >
                  Properties
                </span>
                <span
                  className="recipe-edit-cell"
                  onClick={(e) =>
                    this.warnBeforeDelete(null, this.state.recipeToEdit, e)
                  }
                >
                  Delete
                </span>
                <span
                  className="recipe-edit-cell"
                  onClick={(e) =>
                    this.setState({
                      copyofRecipe: this.state.recipeToEdit,
                      recipeToEdit: null,
                      recipeEdit: false,
                    })
                  }
                >
                  Make a Copy
                </span>
                <span
                  className="recipe-edit-cell"
                  onClick={(e) =>
                    this.handleExecutionPageofRecipe(
                      e,
                      this.state.recipeToEdit.BaseId
                    )
                  }
                >
                  Execution History
                </span>
              </div>
            )}

            {this.state.showPropertiesModal && (
              <div style={{ width: 1000 }}>
                <Modal
                  show={this.state.showPropertiesModal}
                  onHide={() =>
                    this.setState({
                      showPropertiesModal: false,
                    })
                  }
                  dialogClassName="recipe-description"
                  backdrop="static"
                >
                  <Header closeButton>
                    <Title>
                      <strong>{this.state.name}</strong>
                    </Title>
                  </Header>
                  <Body style={{ margin: 20 }}>
                    <div style={{ display: "flex", gap: 20 }}>
                      <div style={{ flex: 1 }}>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: 25 }}>
                            <label htmlFor="env">Tag</label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              onChange={this.changeTagInput}
                              value={this.state.tag}
                              maxLength="15"
                            />
                          </div>
                          <label htmlFor="icon-switch">
                            <label>Processing Type</label>
                            <br />
                            <Switch
                              checked={this.state.modeCheck}
                              onChange={this.handleChange}
                              height={30}
                              width={90}
                              handleDiameter={20}
                              offColor="#729BC4"
                              onColor="#729BC4"
                              uncheckedIcon={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 12,
                                    paddingRight: 2,
                                    color: "#ffffff",
                                  }}
                                >
                                  Batch
                                </div>
                              }
                              checkedIcon={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 12,
                                    paddingRight: 2,
                                    color: "#ffffff",
                                  }}
                                >
                                  Stateless
                                </div>
                              }
                              className="react-switch"
                              id="icon-switch"
                            />
                          </label>
                        </div>
                        <br />
                        <div>
                          <label htmlFor="description">Description</label>
                          <br />
                          <textarea
                            className="form-control"
                            type="text"
                            onChange={this.changeDescriptionInput}
                            value={this.state.description}
                            style={{
                              width: 400,
                              height: 200,
                              marginBottom: 20,
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ flex: 1 }}>
                        <div>
                          <label>Recipe Duration Notification</label>
                          <br />
                          <div style={{ display: "flex", gap: 20 }}>
                            <div>
                              Hours
                              <select
                                name="thresholdHours"
                                id="hours"
                                placeholder="hours"
                                value={this.state.threasholdHours}
                                onChange={(e) => {
                                  this.setState({
                                    threasholdHours: e.target.value,
                                  });

                                  if (e.target.value == 12) {
                                    this.setState({ threasholdMinutes: "00" });
                                  }
                                }}
                                className="form-control"
                                style={{ width: 100 }}
                              >
                                {hours.map((value, index) => {
                                  return (
                                    <option value={value} key={index}>
                                      {value}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div>
                              Minutes
                              <select
                                name="thresholdMinutes"
                                id="minutes"
                                placeholder="minutes"
                                value={this.state.threasholdMinutes}
                                disabled={this.state.threasholdHours == 12}
                                onChange={(e) =>
                                  this.setState({
                                    threasholdMinutes: e.target.value,
                                  })
                                }
                                className="form-control"
                                style={{ width: 100 }}
                              >
                                {minutes.map((value, index) => {
                                  return (
                                    <option value={value} key={index}>
                                      {value}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                          Emails
                          <br />
                          <div style={{ display: "flex" }}>
                            <input
                              className="form-control"
                              type="email"
                              onChange={(e) => {
                                this.setState({ emailText: e.target.value });
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") this.addEmail();
                              }}
                              value={this.state.emailText}
                            />
                            <Button
                              bsSize="small"
                              bsStyle="primary"
                              style={{ marginLeft: "25px" }}
                              onClick={this.addEmail}
                            >
                              Add Email
                            </Button>
                          </div>
                          {transformer.notificationEmail.validationMessage && (
                            <div style={{ fontSize: "11px", color: "#ea5a5a" }}>
                              {transformer.notificationEmail.validationMessage}
                            </div>
                          )}
                          <div
                            style={{
                              border: "1px solid gray",
                              marginTop: 20,
                              height: 200,
                              overflowY: "scroll",
                            }}
                          >
                            {!transformer.notificationEmail.loading &&
                              transformer.notificationEmail.emails.map(
                                (value, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        margin: 0,
                                        padding: 8,
                                        borderBottom: "1px solid lightgray",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        {" "}
                                        <Button
                                          bsSize="small"
                                          bsStyle="primary"
                                          onClick={() => this.deletEmail(index)}
                                          style={{
                                            borderRadius: 50,
                                            width: 25,
                                            height: 25,
                                            padding: 3,
                                            border: "1px solid gray",
                                          }}
                                        >
                                          ✖
                                        </Button>
                                        <p style={{ margin: 5 }}>{value}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Body>
                  <Footer>
                    <Button
                      bsSize="small"
                      bsStyle="primary"
                      onClick={() => this.saveTransformer("properties")}
                      style={{ display: "inline-block" }}
                    >
                      Save
                    </Button>
                  </Footer>
                </Modal>
              </div>
            )}
          </div>
          {this.state.selectedEnvironment && (
            <div
              style={{
                width: "100%",
                // height: "40vh",
                // overflowY: "scroll",
              }}
            >
              <div style={{ border: "1px #dadce0 solid", height: 40 }}>
                <span
                  style={{
                    marginLeft: 10,
                    fontSize: 14,
                    display: "inline-block",
                    marginTop: 10,
                    // fontFamily: "Google Sans",
                    fontWeight: "bold",
                  }}
                >
                  Connections
                </span>
                <img
                  src={images.RecipeBarAdd}
                  style={{ marginTop: 6, float: "right", height: 26 }}
                  onClick={() =>
                    this.props.history.push({
                      pathname: `/a/${appId}/env/${selectedEnvironment.id}`,
                    })
                  }
                />
                <ReactTooltip place={"bottom"} />
                <Button
                  bsSize="xs"
                  name="options"
                  bsStyle="default"
                  style={{
                    width: 30,
                    float: "right",
                    marginTop: 8,
                    marginRight: 2,
                  }}
                  active={this.props.exploreMeta}
                  onClick={() => this.props.handleEMClick()}
                  data-tip={"Explore Connector Metadata"}
                >
                  <svg
                    style={{ marginTop: 3, marginBottom: -2 }}
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                  >
                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </Button>
              </div>
              {project.environmentCredentials &&
              project.environmentCredentials.length > 0 ? (
                <div
                  style={{
                    overflowY: "auto",
                    maxHeight: "25vh",
                  }}
                >
                  {connectorRows}
                </div>
              ) : (
                <div style={{ margin: "40px 80px" }}>
                  <img alt="spinner" src={images.ajax_loader} />
                </div>
              )}
            </div>
          )}

          {this.state.showNewConnector && (
            <Connectors
              {...this.props}
              adapterSecret={adapterSecret}
              newConnector={true}
              env={this.state.selectedEnvironment}
              hideNewConnector={() =>
                this.setState({
                  showNewConnector: false,
                })
              }
            />
          )}

          <ConfirmDeleteModal
            modalState={this.state.modal}
            triggerModal={this.triggerModal}
            nameOfTarget={
              this.state.selectedForDelete !== null &&
              this.state.selectedForDelete.Name
            }
            deleteTarget={this.deleteRecipe}
          />
        </div>
      </div>
    );
  }
}
