import React, { Component } from "react";
import Helmet from "react-helmet";
import AccountsList from "../components/main/accountsList";
import GetStarted from "../components/main/getStarted";
import CreateFirstTenant from "../components/main/createFirstTenant";
import InActiveTenant from "../components/main/inActiveTenant";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as projectActions from "../shared/redux/actions/projectActions";
import * as mainActions from "../shared/redux/actions/mainActions";
import * as transformerActions from "../shared/redux/actions/transformerActions";
import * as datafeedActions from "../shared/redux/actions/datafeedActions";
import * as wizardActions from "../shared/redux/actions/wizardActions";
import * as environmentActions from "../shared/redux/actions/environmentActions";
import * as eventsActions from "../shared/redux/actions/eventsActions";
import * as indexDb from "../db/recipesDOA";

class Main extends Component {
  constructor(props) {
    super(props);
  } /*componentDidMount() {
    const { auth, actions } = this.props;
    const { main } = this.props.redux;
    if (auth.getToken() && !main.appList) {
      actions.callGetApps();
    }
  }*/

  doTransition() {
    this.props.history.push("/newaccount");
  }

  callback(data) {
    console.log(
      "%ccallback",
      "color: #47AAAC; font-weight: bold; font-size: 13px;"
    ); //eslint-disable-line no-console
    console.log(data); //eslint-disable-line no-console
  }

  componentWillMount() {
    const { redux } = this.props;
    const { main } = redux;
    main.accessLevels.forEach((al) => {
      if (al.accessKey) {
        document.body.classList.remove(`${al.accessKey}-elevio`);
      }
    });
  }

  render() {
    const { main } = this.props.redux;
    const { project } = main;
    let appId = window.localStorage.getItem("clearApp");
    if (appId != "" && appId) {
      indexDb.DeleteProjectRecipes(appId);
      window.localStorage.removeItem("clearApp");
    }

    if (window.localStorage && window.localStorage.getItem("userToken")) {
      if (main.me && main.me.accepted && main.me.isActive) {
        var activeTenantList = new Array();
        if (main.tenantList && main.tenantList.length > 0) {
          for (var tenant of main.tenantList) {
            if (tenant.isActive) {
              activeTenantList.push(tenant);
            }
          }
          if (activeTenantList.length == 0) {
            return (
              <div>
                <InActiveTenant {...this.props} />
              </div>
            );
          }
        }
        if (main.tenantList && main.appList) {
          return (
            <div
              style={{
                marginTop: 50,
                height: "calc(100% - 50px)",
                background: "#dfe0e0",
              }}
            >
              <Helmet title="Homepage" />
              <div
                style={{ width: "100%", overflow: "hidden", height: "100%" }}
              >
                <AccountsList {...this.props} />
                <GetStarted {...this.props} />
              </div>
              <div
                id="intercom-step"
                style={{
                  position: "absolute",
                  right: "50px",
                  bottom: "75px",
                  width: "1px",
                  height: "1px",
                  backgroundColor: "rgba(0, 0, 0, 0.0)",
                }}
              />
              {main.tenantList.length === 0 || main.appList.length === 0 ? (
                <CreateFirstTenant
                  {...this.props}
                  tenantList={activeTenantList}
                />
              ) : null}
            </div>
          );
        }
        return <div />;
      } else if (main.me && "accepted" in main.me && !main.me.accepted) {
        // user exists in Auth0 but not in our system (error state)
        const { auth } = this.props;
        const profile = auth.getProfile();
        return (
          <div style={{ textAlign: "center", marginTop: 80 }}>
            <p>
              Error loading data for <strong>{profile.email}</strong>
            </p>
            <p>Please contact Lingk support</p>
          </div>
        );
      } else if (main.me && !main.me.isActive) {
        return (
          <div style={{ textAlign: "center", marginTop: 80 }}>
            The User is no longer active.
            <br />
            Contact your organization administrator to regain access to
            projects. <br />
            For additional help, please contact Lingk support using the "Help"
            tab on the right of the screen.
          </div>
        );
      }
      return <div />;
    }
    return <div />;
  }
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = (state) => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
