import React from 'react';
import logoStyles from '../main/wizardLogoStyles'
import images from '../images';

const Activity = ({ activity, onChoose, redux, active }) => {
  let wiz = null
  if (activity.actionType === 'wizard') {
    wiz = redux.main.wizards && redux.main.wizards.find(w => w.typeId === parseInt(activity.actionArg))
  }
  return (<div className="recommended-activity" onClick={() => onChoose(activity)}>
    <p style={{ color: active ? 'grey' : 'black' }}>{activity.name}</p>
    {activity.actionType === 'wizard' && wiz ? <div style={{ position: 'relative', height: 45 }}>
      <div style={{ position: 'absolute', textAlign: 'center', width: 155 }}>
        <Img type={wiz.sourceType} cardSpacing={wiz.cardSpacing} />
        <span style={{ position: 'absolute', top: 17, left: 71 + (wiz.cardArrowOffset || 0) }}>
          →
        </span>
        <Img type={wiz.destinationType} cardSpacing="0" />
      </div>
    </div> :
      <div style={{ width: '100%', textAlign: 'center' }}>
        {
          activity.actionType === 'elevio' ? activity.name === 'Recipe Library' ? <RecipeLibIcon /> : activity.name === 'Live Chat' ? <ChatIcon /> : <IdeaIcon /> :

            activity.actionType === 'external-link' && activity.name === 'Certification' ? <CertificateIcon /> :
              <RecipeIcon template={activity.actionType === 'template'} />
        }
      </div>
    }
  </div>)
}

const Img = (props) => {
  const { type, cardSpacing } = props
  return (<img
    role="presentation"
    width={(logoStyles[type] && logoStyles[type].width) || 48}
    height={(logoStyles[type] && logoStyles[type].height) || 48}
    style={{ display: 'inline-block', marginRight: cardSpacing || 22 }}
    src={images[type]}
  />)
}

const RecipeIcon = ({ template }) => {
  return (<div className="recipe-activity-icon">
    {template && <div style={{ width: 100 }} />}
    <div style={{ width: 60 }} />
    <div style={{ width: 100 }} />
    <div style={{ width: 50 }} />
    <div style={{ width: 80 }} />
    {!template && <div style={{ width: 100 }} />}
  </div>)
}

const ChatIcon = () => {
  return <img src={images['Chat']} height="45" />
}

const RecipeLibIcon = () => {
  return <img src={images['RecipeLib']} height="45" />
}

const IdeaIcon = () => {
  return <img src={images['idea']} height="45" />
}

const CertificateIcon = () => {
  return <img src={images['Certificate']} height="45" />
}

export default Activity
