import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import wizard from '@lingk/sync/build/reducer'
// Import reducers below
import config from './reducers/config';
import project from './reducers/project';
import main from './reducers/main';
import transformer from './reducers/transformer';
import datafeed from './reducers/datafeed';
import environment from './reducers/environment';
import events from './reducers/events';


export default combineReducers({
  // Add reducers below
  form: formReducer,
  routing: routerReducer,
  config,
  main,
  project,
  transformer,
  datafeed,
  wizard,
  environment,
  events,
});
