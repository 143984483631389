import React, { Component } from "react";

import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import images from "../images";
import { createClient } from "contentful";
import createDOMPurify from "dompurify";
import * as indexDb from "../../db/recipesDOA";

const DOMPurify = createDOMPurify(window);

const nameregex = /[A-Za-z0-9]/;

const contentfulClient = createClient({
  accessToken:
    "0f243ce9285ce061d169b5d337e547755168c3972d67dc957df8362b504ccdfd",
  space: "0qhqhn3xauc3",
});

const RECIPE_CONTENT_TYPE_ID = "recipe";

export default class NewTransformer extends Component {
  constructor() {
    super();

    this.state = {
      name: "",
      nameChanged: false,
      description: "",
      tag: "",
      searchTerm: "",
      isCreatingRecipe: false,
      selectedTemplate: "Default",
      selectedCategories: [],
      contentfulTemplates: [],
      defaultTemplate: `# This recipe converts some static JSON to an Excel file.
# To use this recipe, click Run!

# CONNECTORS specify what data will be pulled into the in-memory database during processing
connectors:
# Source data
-
    name: sourceLeadData
    type: json
    properties:
      jsonObject: >
       [
         { "leadId": "1", "firstName": "John", "lastName": "Thomas", "leadScore": 88 },
         { "leadId": "2", "firstName": "Anne", "lastName": "Jacobs", "leadScore": 32 }
       ]

# Target file
-
    name: excelFile
    type: localFileWriter
    format: excelFormat
    properties:
      fileName: "myFirstSpreadsheet.xlsx"

# FORMATS specify how files should be processed as they are being read or written to
formats:
-
    name: excelFormat
    type: excel
    properties:
      worksheets: "data"
      useHeader: "true"

# STATEMENTS specify how the file should be processed while in memory      
statements:
# NOTE: You can add trasformations into your
  - statement: |
      (transformedData) => select 
              leadId           lead_id, 
              ucase(firstName) first_name,
              lastName         last_name,
              leadScore        lead_score
            from sourceLeadData
  - statement: print transformedData 

# count the data
  - statement: (dataCount) => select count(*) from transformedData
  - statement: print dataCount 

# insert the data
  - statement: INSERT transformedData INTO excelFile  

# Add more statements to convert, join, aggregrate, transform, and integrate your data`,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    //this.chooseTemplate = this.chooseTemplate.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
    this.changeTemplate = this.changeTemplate.bind(this);
    this.changeName = this.changeName.bind(this);
    this.setPreview = this.setPreview.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  // chooseTemplate = (template) => {
  //   const {redux, actions, match.params} = this.props
  //   const {project} = redux

  //     if(!this.state.isCreatingRecipe){
  //       this.setState({isCreatingRecipe:true})
  //       actions.createRecipeFromTemplate(
  //         project.tenantInfo.TenantId, match.params.appId, template.actionArg
  //       ).then((r)=>{
  //         this.setState({isCreatingRecipe:false})
  //         this.props.history.push(`/a/${match.params.appId}/tf/${r.BaseId}/?quick=true`)
  //       })
  //     }
  // }

  componentDidMount() {
    const contentfulTemplates = [];

    const template =
      this.props.location.state &&
      this.props.location.state.template &&
      this.props.location.state.template;
    contentfulClient
      .getEntries({
        include: 3,
        content_type: RECIPE_CONTENT_TYPE_ID,
        order: "fields.title",
      })
      .then((entries) => {
        if (entries) {
          entries.items.map((o) => {
            o.fields.template = o.fields.RecipeContent ? o.fields.RecipeContent.replace("```\n", "") : recipeContentnew_plaintext.replace("```\n", "");
            o.fields.template = o.fields.RecipeContent ? o.fields.RecipeContent.replace("\n```", "") : recipeContentnew_plaintext.replace("```\n", "");
            contentfulTemplates.push(o);
          });
        }
      })
      .then(() => {
        if (
          template &&
          contentfulTemplates.find((f) => f.sys.id === template)
        ) {
          const preselectTemplate = contentfulTemplates.find(
            (f) => f.sys.id === template
          );
          if (preselectTemplate) {
            this.setState({
              selectedTemplate: preselectTemplate.fields.title,
              name: preselectTemplate.fields.title,
              contentfulTemplates,
            });
          }
        } else {
          this.setState({ contentfulTemplates });
        }

        this.setPreview();
      });
  }

  handleSort(e) {
    let { selectedCategories } = this.state;

    const cats = [
      // "Salesforce",
      // "Canvas",
      // "Ellucian",
      "Beginner",
      "Intermediate",
      "Advanced",
      "Batch",
      "Streaming",
    ];

    if (!selectedCategories.includes(e)) {
      selectedCategories.map((o, i) => {
        if (e === cats[0] || e === cats[1] || e === cats[2]) {
          if (o === cats[0] || o === cats[1] || o === cats[2]) {
            selectedCategories.splice(i, 1);
          }
        } else if (e === cats[3] || e === cats[4] || e === cats[5]) {
          if (o === cats[3] || o === cats[4] || o === cats[5]) {
            selectedCategories.splice(i, 1);
          }
        } else if (e === cats[6] || e === cats[7]) {
          if (o === cats[6] || o === cats[7]) {
            selectedCategories.splice(i, 1);
          }
        }
      });

      selectedCategories.push(e);
    } else {
      const i = selectedCategories.findIndex((f) => f === e);
      selectedCategories.splice(i, 1);
    }

    this.setState({ selectedCategories });
  }

  setTemplates(contentfulTemplates) {
    this.setState({ contentfulTemplates });
  }

  changeTemplate(e) {
    let { nameChanged, name } = this.state;

    const selectedTemplate = e;

    if (!nameChanged) {
      name = selectedTemplate;
    }

    this.setState({ selectedTemplate, name });
    this.setPreview(e);
  }

  getValidationState() {
    if (nameregex.test(this.state.name)) return "success";
    else return "error";
  }

  setPreview(newtemplate) {
    const config = this.config;
    const { contentfulTemplates, defaultTemplate } = this.state;
    let { templatePreview } = this.state;

    let selectedTemplate;

    if (newtemplate) selectedTemplate = newtemplate;
    else selectedTemplate = this.state.selectedTemplate;

    if (templatePreview) {
      templatePreview.toTextArea();
    }

    templatePreview = window.CodeMirror.fromTextArea(config, {
      mode: "yaml",
      lineNumbers: true,
      readOnly: true,
      tabSize: 2,
      indentUnit: 2,
      indentWithTabs: false,
      viewportMargin: Infinity,
      foldGutter: true,
      gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    });

    templatePreview.setSize("", "75vh");

    if (selectedTemplate !== "Default") {
      const template = contentfulTemplates.find(
        (f) => f.fields.title === selectedTemplate
      );
      const templatecontent = template.fields.template;

      templatePreview.getDoc().setValue(templatecontent);
    } else {
      templatePreview.getDoc().setValue(defaultTemplate);
    }

    this.setState({ templatePreview });
  }

  changeName(e) {
    let { name, nameChanged } = this.state;

    if (!nameChanged) {
      nameChanged = true;
    }

    name = e;

    this.setState({ name, nameChanged });
  }

  handleSubmit() {
    if (this.getValidationState() === "error") {
      alert("Recipe name must include letters or numbers");
    } else {
      const { selectedTemplate, contentfulTemplates, defaultTemplate } =
        this.state;

      let config = defaultTemplate;

      if (selectedTemplate !== "Default") {
        const template = contentfulTemplates.find(
          (f) => f.fields.title === selectedTemplate
        );

        config = template.fields.template;
      }

      const name = this.state.name.trim() + ".lr";
      const tag = this.state.tag.trim();
      const description = this.state.description.trim();

      if (!name) {
        return;
      }

      this.setState({ isCreatingRecipe: true });

      this.props.actions
        .callCreateTransformer(
          {
            name,
            config,
            description,
            environ: tag,
            version: 1,
            languageVersion: "2.0",
            processingType: "batch",
          },
          this.props.match.params.appId
        )
        .then((r) => {
          indexDb.AddRecipe(r);
          this.props.history.push({
            pathname: `/a/${this.props.match.params.appId}/tf/${r.BaseId}`,
          });
        });
    }
  }

  render() {
    const categories = [
      // "Salesforce",
      // "Canvas",
      // "Ellucian",
      "Beginner",
      "Intermediate",
      "Advanced",
      "Batch",
      "Streaming",
    ];

    const {
      contentfulTemplates,
      selectedCategories,
      isCreatingRecipe,
      searchTerm,
    } = this.state;

    const templateItems = [];
    const allCatTypes =
      (selectedCategories.includes(categories[0]) ||
        selectedCategories.includes(categories[1]) ||
        selectedCategories.includes(categories[2])) &&
      (selectedCategories.includes(categories[3]) ||
        selectedCategories.includes(categories[4]));
    //   selectedCategories.includes(categories[5])) &&
    // (selectedCategories.includes(categories[6]) ||
    //   selectedCategories.includes(categories[7]));
    const fsllCatTypes = false;
    const llptCatTypes =
      (selectedCategories.includes(categories[0]) ||
        selectedCategories.includes(categories[1]) ||
        selectedCategories.includes(categories[2])) &&
      (selectedCategories.includes(categories[3]) ||
        selectedCategories.includes(categories[4]));
    const fsptCatTypes = false;

    const salesCount = [];
    const cnvsCount = [];
    const ellucCount = [];
    const begCount = [];
    const intCount = [];
    const advCount = [];
    const batchCount = [];
    const streamCount = [];

    if (contentfulTemplates.length > 0) {
      for (let i = -1; i < contentfulTemplates.length; i++) {
        if (i < 0) {
          templateItems.push(
            <ListGroupItem
              className="template-list-item"
              key={i + 1 + Math.random()}
              active={this.state.selectedTemplate === "Default"}
              style={{
                border: "none",
                background:
                  this.state.selectedTemplate === "Default" ? "#eee" : null,
                color:
                  this.state.selectedTemplate === "Default" ? "#000" : null,
              }}
              onClick={() => this.changeTemplate("Default")}
            >
              (Default)
            </ListGroupItem>
          );
        } else {
          // if (contentfulTemplates[i].fields.featuredSystem) {
          //   let fs = contentfulTemplates[i].fields.featuredSystem;
          //   for (let i = 0; i < fs.length; i++) {
          //     if (fs[i] == "Salesforce") salesCount.push(1);
          //     if (fs[i] == "Canvas") cnvsCount.push(1);
          //     if (fs[i] == "Ellucian") ellucCount.push(1);
          //   }
          // }

          if (contentfulTemplates[i].fields.learningLevel === "Beginner")
            begCount.push(1);
          else if (
            contentfulTemplates[i].fields.learningLevel === "Intermediate"
          )
            intCount.push(1);
          else if (contentfulTemplates[i].fields.learningLevel === "Advanced")
            advCount.push(1);

          if (contentfulTemplates[i].fields.processingType === "Batch")
            batchCount.push(1);
          else if (contentfulTemplates[i].fields.processingType === "Streaming")
            streamCount.push(1);

          let fsIncluded;
          if (contentfulTemplates[i].fields.featuredSystem) {
            let fs = contentfulTemplates[i].fields.featuredSystem;
            for (let i = 0; i < fs.length; i++) {
              if (selectedCategories.includes(fs[i])) {
                fsIncluded = true;
                break;
              }
            }
          }
          let included;
          if (allCatTypes) {
            included =
              selectedCategories.includes(
                contentfulTemplates[i].fields.learningLevel
              ) &&
              selectedCategories.includes(
                contentfulTemplates[i].fields.processingType
              );
          }
          // else if (fsllCatTypes) {
          //   included =
          //     selectedCategories.includes(
          //       contentfulTemplates[i].fields.learningLevel
          //     ) && fsIncluded;
          // } else if (fsptCatTypes) {
          //   included =
          //     selectedCategories.includes(
          //       contentfulTemplates[i].fields.processingType
          //     ) && fsIncluded;
          // } else if (llptCatTypes) {
          //   included =
          //     selectedCategories.includes(
          //       contentfulTemplates[i].fields.learningLevel
          //     ) &&
          //     selectedCategories.includes(
          //       contentfulTemplates[i].fields.processingType
          //     );
          // }
          else {
            included =
              selectedCategories.includes(
                contentfulTemplates[i].fields.learningLevel
              ) ||
              selectedCategories.includes(
                contentfulTemplates[i].fields.processingType
              ) ||
              fsIncluded;
          }

          const skip = selectedCategories.length < 1;
          const searched =
            searchTerm === "" ||
            contentfulTemplates[i].fields.title
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            contentfulTemplates[i].fields.template
              .toLowerCase()
              .includes(searchTerm.toLowerCase());

          if (searched && (skip || included)) {
            templateItems.push(
              <ListGroupItem
                key={i + Math.random()}
                className="template-list-item"
                style={{
                  border: "none",
                  background:
                    this.state.selectedTemplate ===
                      contentfulTemplates[i].fields.title
                      ? "#eee"
                      : null,
                  color:
                    this.state.selectedTemplate ===
                      contentfulTemplates[i].fields.title
                      ? "#000"
                      : null,
                }}
                active={
                  contentfulTemplates &&
                  this.state.selectedTemplate ===
                  contentfulTemplates[i].fields.title
                }
                onClick={() =>
                  this.changeTemplate(contentfulTemplates[i].fields.title)
                }
              >
                {contentfulTemplates && contentfulTemplates[i].fields.title}
              </ListGroupItem>
            );
          }
        }
      }
    }

    const countItems = [
      // salesCount,
      // cnvsCount,
      // ellucCount,
      begCount,
      intCount,
      advCount,
      batchCount,
      streamCount,
    ];

    const categoryItems = [];

    categoryItems.push(
      <div className="list-divider" key={876534 + Math.random()}>
        <span className="">Learning Level</span>
      </div>
    );

    for (let i = 0; i < categories.length; i++) {
      const selected = selectedCategories.includes(categories[i]);
      if (i === 3) {
        categoryItems.push(
          <div
            className="list-divider"
            style={{ marginTop: 1, borderTop: "1px solid #aaa" }}
            key={i + Math.random()}
          >
            <span className="">Processing Type</span>
          </div>
        );
      }

      // if (i === 6) {
      //   categoryItems.push(
      //     <div
      //       className="list-divider"
      //       style={{ marginTop: 1, borderTop: "1px solid #aaa" }}
      //       key={i + Math.random()}
      //     >
      //       <span className="">Processing Type</span>
      //     </div>
      //   );
      // }

      categoryItems.push(
        <ListGroupItem
          key={i + Math.random()}
          className="template-list-item"
          style={{
            border: "none",
            background: selected ? "#286090" : null,
            color: selected ? "#eee" : null,
          }}
          active={selected}
          onClick={() => this.handleSort(categories[i])}
        >
          {categories[i]} ({countItems[i].length})
        </ListGroupItem>
      );
    }

    const currentTemplate =
      contentfulTemplates.length > 0 &&
      contentfulTemplates.find(
        (f) => f.fields.title === this.state.selectedTemplate
      );

    // let allset = false
    // if (nameregex.test(this.state.name)){
    //   allset=true
    // }

    /*}
    <MenuItem value='File Upload Event' key={329857698236}
              onClick={()=>this.setState({objectType: 'File Upload Event', eventType:'File Upload Event', objectTypeChanged:true})}
              active={objectType==='File Upload Event'}>
              File Upload Event
             </MenuItem> 
             )

  */

    //console.log(contentfulTemplates)

    return (
      <div className="parent-env" style={{ marginTop: 66 }}>
        <div className="main-tab-content">
          <div
            style={{
              float: "left",
              marginRight: 0,
              marginBottom: 20,
              position: "relative",
            }}
          >
            <div style={{ paddingTop: 12 }}>
              <div style={{ display: "inline-block" }}>
                <ControlLabel>Recipe Name</ControlLabel>
                <br />
                <FormGroup controlId="formBasicText" style={{ height: 30 }}>
                  <FormControl
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    style={{ width: 290 }}
                    placeholder=""
                    value={this.state.name}
                    onChange={(e) => this.changeName(e.target.value)}
                  />
                </FormGroup>
              </div>

              <div
                style={{
                  marginLeft: 10,
                  marginBottom: 10,
                  marginRight: 10,
                }}
              >
                <Button
                  style={{ marginLeft: 10, float: "right", width: 80 }}
                  bsStyle="primary"
                  bsSize="small"
                  disabled={isCreatingRecipe}
                  onClick={() => this.handleSubmit()}
                >
                  {isCreatingRecipe ? (
                    <span>
                      <img
                        alt="small-spinner"
                        src={images.ajax_loader_small}
                        height="8"
                      />
                    </span>
                  ) : (
                    <span>Create</span>
                  )}
                </Button>
                <Button
                  style={{ marginLeft: 10, float: "right" }}
                  bsStyle="default"
                  bsSize="small"
                  disabled={isCreatingRecipe}
                  onClick={() =>
                    this.props.history.push(
                      `/a/${this.props.match.params.appId}/tf`
                    )
                  }
                >
                  Cancel
                </Button>
              </div>
              <br />
              <br />
              {/*}

        <ControlLabel >Tag</ControlLabel><br/>
        <FormGroup
            style={{height: 30}}>
            <FormControl
              type="text"
              id="tagtext"
              maxLength="15"
              autoComplete="off"
              placeholder="" 
              value={this.state.tag}
              onChange={(e)=>this.setState({tag:e.target.value})}  /> 
            </FormGroup>

        <ControlLabel >Description</ControlLabel><br/>
        <FormGroup
            controlId="formBasicText"
            style={{height: 30}}>
            <FormControl
              type="text"
              componentClass="textarea"
              placeholder="" 
              value={this.state.description}
              onChange={(e)=>this.setState({description:e.target.value})}  /> 
            </FormGroup><br/>
          */}
              {/* 
              // hiding categories from new recipes page
              <div style={{ display: "inline-block" }}>
                <ControlLabel>Categories</ControlLabel>
                {selectedCategories.length > 0 && (
                  <span
                    className="clear-cats"
                    onClick={() => this.setState({ selectedCategories: [] })}
                  >
                    <svg
                      style={{ marginTop: -2 }}
                      height="18"
                      viewBox="0 0 60 60"
                      className="delete-cred-bar"
                    >
                      <path
                        style={{ strokeWidth: 5, strokeLinecap: "round" }}
                        d="M 15.674663,15.572746 L 44.587629,44.485711 M 45.118838,15.420972 L 15.522889,45.016920"
                      />
                    </svg>
                  </span>
                )}
                <br />
                <div className="template-list" style={{ width: 160 }}>
                  <ListGroup
                    bsStyle="default"
                    title="Categories"
                    bsSize="small"
                  >
                    {categoryItems}
                  </ListGroup>
                </div>
              </div> */}

              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginBottom: 130,
                }}
              >
                <ControlLabel>Template ({templateItems.length})</ControlLabel>
                <br />
                <div
                  style={{
                    position: "absolute",
                    top: 25,
                    left: 0,
                    zIndex: 40,
                    borderBottom: "1px solid #222",
                  }}
                >
                  <FormControl
                    type="text"
                    placeholder="Search title or syntax"
                    style={{
                      width: 280,
                      height: 40,
                      borderRadius: 0,
                      border: "none",
                    }}
                    value={searchTerm}
                    onChange={(e) =>
                      this.setState({ searchTerm: e.target.value })
                    }
                  />
                </div>
                <div
                  className="template-list"
                  style={{ marginTop: 40, height: 575 }}
                >
                  <ListGroup bsStyle="default" title="Templates" bsSize="small">
                    {templateItems}
                  </ListGroup>
                </div>
              </div>

              {/*}
      <div style={{display:'inline-block', float: 'right'}}>
      <div style={{marginTop:-19}}>
      <strong className="labelz" style={{fontSize:'11px'}}>
      Show&nbsp;
      </strong>
      </div>
      <DropdownButton id="webhook-subscriptions" placeholder="Events" bsStyle="default"
                      title={templateType}>
            <MenuItem value='All' key={32357698236}
              onClick={()=>this.handleSort('All')}
              active={templateType==='All'}>
              All
             </MenuItem> 
             <MenuItem value='Basic' key={323572698236}
              onClick={()=>this.handleSort('Basic')}
              active={templateType==='Basic'}>
              Basic
             </MenuItem>        
      </DropdownButton>
      </div>
      <br/>
      <br/>
    */}

              {/*}
      <div style={{marginRight:20}}>
            
             <Button style={{marginLeft: 10, float:'right', width: 80}}
            bsStyle='primary'
            disabled={isCreatingRecipe}
            onClick={()=>this.handleSubmit()}>
             {isCreatingRecipe?    
             <span><img alt="small-spinner" src={images.ajax_loader_small} height="8" /></span>
             :
             <span>Create</span>
             }
             </Button>
             <Button style={{marginLeft: 10, float:'right'}}
            bsStyle='default'
            disabled={isCreatingRecipe}
            onClick={()=>this.props.history.push(`/a/${this.props.match.params.appId}/tf`)}>
             Cancel
             </Button>
        </div>
      */}
            </div>
          </div>
          {/*marginTop should be 26 or 114  left: 576*/}
          <div style={{ display: "inline-block", marginTop: 12 }}>
            <ControlLabel>Description</ControlLabel>
            {currentTemplate ? (
              <div
                style={{
                  maxWidth: "70vw",
                  height: "60px",
                  maxHeight: "60px",
                  marginTop: 8,
                  padding: 2,
                }}
              // dangerouslySetInnerHTML={{
              //   __html: DOMPurify.sanitize(
              //     currentTemplate.fields.description
              //   ),
              // }}
              >
                {currentTemplate.fields.description}
              </div>
            ) : (
              <>
                <br />
                No Description
                <br />
                <br />
                <br />                
              </>
            )}
            <br />
            <div className="templates-preview">
              <ControlLabel>Preview</ControlLabel>
              <div
                style={{
                  padding: "4px 8px 4px 8px",
                  background: "#666",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "#eee", fontWeight: "bold" }}>
                  {this.state.selectedTemplate} Template
                </span>
              </div>
              <div>
                {contentfulTemplates.length > 0 ? (
                  <textarea
                    className="recipe-preview"
                    type="text"
                    ref={(r) => {
                      this.config = r;
                    }}
                  />
                ) : (
                  <img
                    alt="spinner"
                    className="template-loader"
                    src={images.ajax_loader}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
