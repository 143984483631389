export default {
    IS_INTEGRATION: false,
    NODE_ENV: "production",
    __CLIENT__: true,
    __DEVELOPMENT__: false,
    __DEVTOOLS__: false,
    AUTH0_CLIENT_ID: "H7UkYM4UQ4jDMCA0c6424khkm6noNzOH",
    AUTH0_DOMAIN: "auth.lingk.io",
    HOME_URL: "https://app.lingk.io",
    API_URL: "https://app.lingkapis.com/v1/",
    DATA_API_URL: "https://internal.lingkapis.com/v1/",
    EXT_DATA_API_URL: "https://www.lingkapis.com/v1/",
    FTP_URL: "sftp://lingk.brickftp.com/",
    FTP_DIR: "organizations/",
    BULK_UPLOAD_UI_URL: "https://lingk.brickftp.com/",
    PS: "/ps/",
    INTERCOM_APP_ID: "c4na38dm",
    TRANS_API_URL: "https://transformer.lingkapis.com/v1/",
    TRANS_API_URL2: "http://transformer-api-production-1544243396.us-east-1.elb.amazonaws.com/v1/",
    SF_AGENT_API_URL: "https://salesforceagent.lingkapis.com/v1/",
    MONITOR_API_URL: "https://monitoring.lingkapis.com/v1/",
    TEST_CONN_API_URL: "https://test-connections.lingkapis.com/v1/",
    GOOGLE_CAPTCHA_KEY: "6LeycBcmAAAAAMmQZ2M0jWdaxmHRoJrjy01anNbS",
    RECIPE_MONITORING_API_URL: "https://recipe-monitoring.lingkapis.com/v1/",
};