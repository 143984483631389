import React, { Component } from "react";
import Helmet from "react-helmet";
import images from "../images";
import { DropdownButton, MenuItem, Button } from "react-bootstrap";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";
const queryString = require("query-string");

class DSOauthRedirect extends Component {
  constructor() {
    super();
    this.state = {
      text: "Completing Authorization Process",
      showAccountSelect: false,
      selectedAccount: null,
      accounts: null,
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    this.interval = setInterval(() => {
      const { text } = this.state;
      if (text.length < 48) {
        this.setState({ text: text + "." });
      } else {
        this.setState({ text: "Completing Authorization Process" });
      }
    }, 200);
    const parsed = queryString.parse(this.props.location.search);
    const authCode = parsed.code;
    const authState = parsed.state;
    const stateArray = authState.split(" ");
    if (authCode || authState) {
      actions
        .postDSAuthCode(authCode, stateArray[0], parseInt(stateArray[2], 10), "")
        .then((accounts) => {
          clearInterval(this.interval);
          if (accounts && accounts.length > 1) {
            this.setState({
              showAccountSelect: true,
              accounts: accounts,
              text: "Please select an Account",
            });
          } else {
            this.setState({
              text: "Authorization Complete! Close this window.",
            });
          }
          window.opener.postMessage(
            { lingkOauthMessage: "complete" },
            window.opener.location
          );
        })
        .catch(() => {
          clearInterval(this.interval);
          this.setState({ text: "Authorization Failed. Please Retry." });
          window.opener.postMessage(
            { lingkOauthMessage: "failed" },
            window.opener.location
          );
        });
    } else {
      clearInterval(this.interval);
      this.setState({ text: "Authorization Failed. Please Retry." });
      window.opener.postMessage(
        { lingkOauthMessage: "failed" },
        window.opener.location
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  accountChangeHandle(e) {
    this.setState({ selectedAccount: e });
  }
  postAccount() {
    const parsed = queryString.parse(this.props.location.search);
    const authCode = parsed.code;
    const authState = parsed.state;
    const stateArray = authState.split(" ");
    this.setState({
      showAccountSelect: false,
      text: "Completing Authorization Process",
    });
    this.intervalAccount = setInterval(() => {
      const {text} = this.state
      if (text.length < 48) {
        this.setState({ text: text + "." });
      } else {
        this.setState({ text: "Completing Authorization Process" });
      }
    }, 200);
    this.props.actions.postDSAuthCode(authCode, stateArray[0], parseInt(stateArray[2], 10), this.state.selectedAccount)
    .then(()=>{
      clearInterval(this.intervalAccount);
      this.setState({
        showAccountSelect: false,
        text: "Authorization Complete! Close this window.",
      });
      window.opener.postMessage(
        { lingkOauthMessage: "complete" },
        window.opener.location
      )
    }) 
    .catch(() => {
      clearInterval(this.interval);
      this.setState({ text: "Authorization Failed. Please Retry." });
      window.opener.postMessage(
        { lingkOauthMessage: "failed" },
        window.opener.location
      );
    });       
 

  }
  render() {
    const { showAccountSelect, selectedAccount, accounts } = this.state;
    return (
      <div style={{ paddingTop: 50 }}>
        <Helmet title="Oauth Redirect" />
        <div style={{ padding: 40 }}>
          <img
            role="presentation"
            width="28"
            height="28"
            src={images["DocuSign"]}
          />
          <span style={{ marginLeft: 14 }}>{this.state.text}</span>
          {showAccountSelect ? (
            <div style={{display:'inline'}}>
              <DropdownButton
                title={selectedAccount || "Select"}
                bsStyle="default"
                bsSize="small"
                className="environments-dropdown"
                style={{ width: 150, fontWeight: "bold", marginLeft:10 }}
                id="accounts"
              >
                {accounts.map((p, i) => {
                  return (
                    <MenuItem
                      value={selectedAccount}
                      key={i}
                      onClick={() => this.accountChangeHandle(p)}
                    >
                      {p}
                    </MenuItem>
                  );
                })}
              </DropdownButton>
              <Button
                bsSize="small"
                bsStyle="primary"
                onClick={() =>
                  this.postAccount()
                }
                style={{
                  height: 25,
                  marginTop: 2,
                  verticalAlign: "top",
                  padding: "1px 10px",
                  marginLeft: 5,
                }}
              >
                Continue
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = state => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DSOauthRedirect);