import * as types from "../actions/types";
//get images just to set initial favicon (fixme)

const initialState = {
  appList: null,
  tenantList: null,
  me: {},
  users: [],
  showMenu: false,
  accountsMenu: false,
  wizards: null,
  providers: null,
  accessLevels: null,
  masterQuickActions: null,
  maxDataProcTime: null,
  dphLoading: false,
  dataProcessingHours: {},
  notificationFrequencies: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_WIZARD_TYPES:
      const { providers, tenantAdoptions, providerProperties } = action.data;
      const wizards = action.data.types
        .map((wiz) => {
          return {
            ...wiz,
            source: providers.find((p) => p.type === wiz.sourceType),
            destination: providers.find((p) => p.type === wiz.destinationType),
            adopted:
              tenantAdoptions &&
              tenantAdoptions.find((ta) => ta.IntegrationTypeId === wiz.typeId)
                ? true
                : false,
          };
        })
        .sort((x, y) => x.isReleased - y.isReleased || x.adopted - y.adopted)
        .reverse();
      return {
        ...state,
        wizards,
        providers: providers
          .sort((a, b) => {
            if ((a.label || a.type) < (b.label || b.type)) return -1;
            if ((a.label || a.type) > (b.label || b.type)) return 1;
            return 0;
          })
          .map((p) => {
            return {
              ...p,
              properties: providerProperties.filter(
                (pp) => p.type === pp.providerType
              ),
            };
          }),
      };
    case types.SET_APPLICATIONS:
      return {
        ...state,
        appList: action.data,
      };
    case types.SET_ACCESS_LEVELS:
      return {
        ...state,
        accessLevels: action.data,
      };
    case types.UPDATE_APPLICATIONS_APP:
      const appList = action.appList;
      const i = appList.findIndex((a) => String(a.Id) === action.appId);
      appList[i] = { ...appList[i], ...action.data };

      return {
        ...state,
        appList,
      };
    case types.SET_ME:
      return {
        ...state,
        me: action.data,
      };
    case types.SET_USERS:
      return {
        ...state,
        users: action.data
          .filter((u) => u.Identity && u.Identity.id > 0)
          .map((u) => {
            return {
              ...u.Identity,
              admin: u.isAdmin,
              tenantid: u.tenantId,
            };
          }),
      };
    case types.SET_SHOW_MENU:
      return {
        ...state,
        showMenu: action.bool,
      };
    case types.SET_MASTER_RECOMMENDED_ACTIONS:
      return {
        ...state,
        masterQuickActions: action.data,
      };
    case types.TOGGLE_ACCOUNTS_MENU:
      return {
        ...state,
        accountsMenu: !state.accountsMenu,
      };
    case types.SET_TENANT_LIST:
      return {
        ...state,
        tenantList:
          action.data &&
          action.data.sort((a, b) => {
            if (a.Key < b.Key) return -1;
            if (a.Key > b.Key) return 1;
            return 0;
          }),
      };
    case types.START_DPH:
      return {
        ...state,
        dataProcessingHours: {},
        dphLoading: true,
      };
    case types.SET_DPH:
      return {
        ...state,
        dataProcessingHours: action.data,
        dphLoading: false,
      };
    case types.ERROR_DPH:
      return {
        ...state,
        dataProcessingHours: {},
        dphLoading: false,
      };
    case types.SET_NOTIFICATION_FREQUENCIES:
      return {
        ...state,
        notificationFrequencies: action.data,
      };
    default:
      return state;
  }
};
