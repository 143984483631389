import React, {Component} from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import CredSection from "@lingk/sync/build/credentialSections";
import * as inputs from "@lingk/sync/build/lingk";
import images from "../images";
import { Modal, Button, FormControl } from "react-bootstrap";
const { Header, Body, Footer, Title } = Modal;
import * as util from "@lingk/sync/build/metadataFunctions";
import * as helpers from "../utils/connectorsHelper";
class ConnectorSection extends Component {
  constructor() {
    super();
    this.state = {
      oauthUrl: null,
      dsOauthUrl: null,
      gettingOauthUrl: null,
      metadata: null,
      showMetaData: false,
      checking: false,
      checkResult: null,
      modalOpened: false,
      isSaving: false,
      showDownload: false,
      checkError: null,
      refreshing: false,
      disableMarkConnection: false,
      firstConnectionOfaType: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.creds) {
      this.setState({
        checkError: null,
        checking: false,
        checkResult: "",
        metadata: null,
      });
      // this.check(nextProps.creds && nextProps.creds.credentialsJson, true, nextProps.providerConfig)
    }
  }


  componentWillMount() {
    const { creds, redux, env, providerConfig, newConnector } = this.props;
    const isIntro = true;
    const { project } = redux;
    const envId = env.environmentId;
    if (!newConnector && !providerConfig.noCheckConnectionYet && creds) {
      let doTesting = helpers.TestConnection(
        providerConfig,
        creds.credentialsJson
      );
      if (doTesting) {
        this.check(creds && creds.credentialsJson, isIntro, providerConfig);
      } else {
        this.setState({
          checking: false,
          checkError: "set fields for testing the connection",
          metadata: null,
        });
        this.props.stopSaveLoader();
      }
    }
    if (creds && creds.credentialType) {
      let a =
        project.environmentCredentials &&
        project.environmentCredentials.filter(
          (p) => p.credentialType == creds.credentialType
        );
      if (a.length == 1 && creds.defaultConnection) {
        this.setState({ disableMarkConnection: true });
      }
    } else {
      let a =
        project.environmentCredentials &&
        project.environmentCredentials.filter(
          (p) => p.credentialType == providerConfig.credentialType
        );
      if (a.length == 0) {
        this.setState({
          disableMarkConnection: true,
          firstConnectionOfaType: true,
        });
      }
    }
  }

  getEnvCreds = () => {
    const { env, redux, match, actions } = this.props;
    const { params } = match;
    const { project } = redux;
    const { appId } = params;
    this.props.hideNewConnector();
    actions.getAllCredentialsForEnvironment(
      project.tenantInfo.TenantId,
      appId,
      env.name
    );
  };

  getOauthCreds = (data) => {
    const { actions, redux, env } = this.props;
    const { project } = redux;
    const { tenantInfo } = project;
    const tenantId = tenantInfo.TenantId;
    const appId = project.appData.Id;
    const { environmentId } = env;
    let credsData = {
      rootUrl: data.rootUrl,
      clientId: data.clientId,
      clientSecret: data.clientSecret,
      authenticationType: "oauth",
      bvaToken: data.bvaToken,
    };
    let orgCreds = this.props.creds;

    let originalKey;
    if (orgCreds && "key" in orgCreds) {
      originalKey = orgCreds.key;
    } else {
      originalKey = this.props.connectorKey;
    }
    let bvaData = {
      root_url: data.rootUrl,
      client_id: data.clientId,
      client_secret: data.clientSecret,
      scope: data.bvaToken && data.bvaToken.scope ? data.bvaToken.scope : "",
      connectionKey: this.props.connectorKey,
    };
    var promise = new Promise((resolve, reject) => {
      actions
        .createAuthAppNGetCode(
          tenantId,
          appId,
          environmentId,
          originalKey,
          "brightspace",
          bvaData
        )
        .then((data) => {
          resolve(data);
        })
        .catch(() => {
          reject(Error("Promise rejected"));
        });
    });
    return promise;
  };

  getOAuthCredsZoom = (data) => { 
    const { actions, redux, env } = this.props;
    const { project } = redux;
    const { tenantInfo } = project;
    const tenantId = tenantInfo.TenantId;
    const appId = project.appData.Id;
    const { environmentId } = env;

    let orgCreds = this.props.creds;

    let originalKey;
    if (orgCreds && "key" in orgCreds) {
      originalKey = orgCreds.key;
    } else {
      originalKey = this.props.connectorKey;
    }
    
    let zoomRequest = {
      root_url: data.rootUrl,
      client_id: data.clientId,
      client_secret: data.clientSecret,
      scope: data.scope,
      connectionKey: this.props.connectorKey,
    };
    
    var promise = new Promise((resolve, reject) => {
      actions
        .createAuthAppNGetCode(
          tenantId,
          appId,
          environmentId,
          originalKey,
          "zoom",
          zoomRequest
        )
        .then((data) => {
          resolve(data);
        })
        .catch(() => {
          reject(Error("Promise rejected"));
        });
    });
    return promise;
  };

  getOAuthCredsCanvasOAuth2 = (data) => {
    const { actions, redux, env } = this.props;
    const { project } = redux;
    const { tenantInfo } = project;
    const tenantId = tenantInfo.TenantId;
    const appId = project.appData.Id;
    const { environmentId } = env;

    let orgCreds = this.props.creds;

    let originalKey;
    if (orgCreds && "key" in orgCreds) {
      originalKey = orgCreds.key;
    } else {
      originalKey = this.props.connectorKey;
    }

    let canvasOAuth2Request = {
      root_url: data.rootUrl,
      client_id: data.clientId,
      client_secret: data.clientSecret,
      scope: data.scope,
      connectionKey: this.props.connectorKey,
    };

    return new Promise((resolve, reject) => {
      actions
          .createAuthAppNGetCode(
              tenantId,
              appId,
              environmentId,
              originalKey,
              "canvas",
              canvasOAuth2Request
          )
          .then((data) => {
            resolve(data);
          })
          .catch(() => {
            reject(Error("Promise rejected"));
          });
    });
  };

  checkConnection = (creds) => {
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;

    const providerConfig = this.props.providerConfig;
    const { metadataEndpoint, skipCheckConnection, credentialType, type } =
      providerConfig;
    actions.setSingleCredJson(
      creds,
      type,
      credentialType,
      this.props.connectorKey
    );

    this.setState({
      checking: true,
      checkResult: "",
      metadata: null,
      checkError: null,
    });

    if (creds.credentialType && creds.credentialType == "two-Factor Auth") {
      creds.credentialType = "2fa";
    }
    if (metadataEndpoint) {
      actions
        .tryCheckConnection(
          project.tenantInfo.TenantId,
          appId,
          providerConfig,
          creds,
          env,
          this.props.connectorKey
        )
        .then(() => {
          let checkResult = "200 OK";
          this.setState({ checkResult, checking: false });
          this.props.enableNext();
        })
        .catch((error) => {
          this.setState({
            checking: false,
            checkResult: "404 Error",
          });
        });
    }
  };

  fetchFileSchemas = (paginate) => {
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { project } = redux;
    const tenantId = project.tenantInfo.TenantId;
    const appId = params.appId;
    return actions
      .callFetchFileSchemas(tenantId, appId, env.name, paginate)
      .then((result) => {
        return result
      });
  }

  postCredentials = (
    creds,
    APIAccess,
    defaultConnection,
    wizard,
    firstConnectionOfaType
  ) => {
    let showConfirm = false;
    if (this.props.creds) {
      if (this.props.creds.defaultConnection != defaultConnection) {
        showConfirm = true;
      }
    } else if (defaultConnection) {
      showConfirm = true;
    }
    if (firstConnectionOfaType) {
      showConfirm = false;
    }
    if (showConfirm) {
      if (
        !confirm(
          "Changing default connection will impact the scheduled/future recipes." +
            "Are you sure you want to continue?"
        )
      ) {
        this.props.stopSaveLoader();
        return;
      }
    }
    const { env, redux, match, actions, providerConfig, newUserExperience } =
      this.props;
    const { params } = match;
    const { project } = redux;
    const { appId } = params;
    let orgCreds = this.props.creds;
    let originalKey;
    if (orgCreds && "key" in orgCreds) {
      originalKey = orgCreds.key;
    } else {
      originalKey = this.props.connectorKey;
    }
    if (creds.credentialType && creds.credentialType == "two-Factor Auth") {
      creds.credentialType = "2fa";
    }
    actions
      .postCredentials(
        project.tenantInfo.TenantId,
        appId,
        providerConfig,
        creds,
        env.environmentId,
        APIAccess,
        originalKey,
        this.props.connectorName,
        this.props.connectorKey,
        defaultConnection,
        wizard
      )
      .then(() => {
        if (!providerConfig.noCheckConnectionYet) {
          let doTesting = helpers.TestConnection(providerConfig, creds);
          if (!doTesting) {
            this.setState({
              checking: false,
              checkError: "set fields for testing the connection",
              metadata: null,
            });
            this.props.stopSaveLoader();
          } else {
            let manualCheck = newUserExperience ? false : true;
            this.check(creds, false, providerConfig, manualCheck, wizard);
          }
        } else if (!newUserExperience) {
          this.props.stopSaveLoader();
          this.props.hideNewConnector();
          actions.getAllCredentialsForEnvironment(
            project.tenantInfo.TenantId,
            appId,
            env.name
          );
        }
      });
  };

  check = (creds, isIntro, providerConfig, manualCheck, wizard) => {
    this.setState({
      checking: true,
      checkResult: "",
      metadata: null,
      checkError: null,
    });
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    if (
      creds &&
      creds.credentialType &&
      creds.credentialType == "two-Factor Auth"
    ) {
      creds.credentialType = "2fa";
    }
    const { metadataEndpoint, skipCheckConnection, credentials, type } =
      providerConfig;
    if (!skipCheckConnection && metadataEndpoint) {
      this.setState({ showMetaData: true });
      actions
        .tryCheckConnection(
          project.tenantInfo.TenantId,
          appId,
          providerConfig,
          creds,
          env,
          this.props.connectorKey
        )
        .then(() => {
          this.props.stopSaveLoader();
          let checkResult = "200 OK";
          this.props.enableNext();
          this.props.handleCheckData(wizard);

          this.setState({ checkResult, checking: false });
          if (manualCheck) {
            this.props.hideNewConnector();
            actions.getAllCredentialsForEnvironment(
              project.tenantInfo.TenantId,
              appId,
              env.name
            );
          }
        })
        .catch((error) => {
          this.setState({
            checking: false,
            checkResult: "404 Error",
            metadata: null,
          });
          if (error.response && error.response.data) {
            let err = error.response.data;
            if (providerConfig.metadataApi === "TEST_CONN_API_URL") {
              err = error.response.data.error;
            }
            this.setState({ checkError: err });
            this.props.stopSaveLoader();
          }
        });
    } else {
      actions
        .checkProviderMetadata(
          project.tenantInfo.TenantId,
          appId,
          providerConfig,
          env,
          creds,
          isIntro
        )
        .then((metadata) => {
          let checkResult = "200 OK";
          this.props.enableNext();
          this.props.handleCheckData(wizard);

          if (providerConfig.type === "LocalFile") {
            if (metadata && metadata.length === 0) {
              checkResult = "";
            }
            actions
              .checkProviderMetadataCount(
                project.tenantInfo.TenantId,
                appId,
                providerConfig,
                env,
                creds,
              )
              .then((response) => {
                this.setState({ fileSchemaCount: response.count })
                this.setState({ fileSchema: metadata });
              });
          }
          this.setState({
            checkResult,
            metadata,
            checking: false,
            checkError: "",
          });
          this.props.stopSaveLoader();
          if (manualCheck) {
            this.props.hideNewConnector();
            actions.getAllCredentialsForEnvironment(
              project.tenantInfo.TenantId,
              appId,
              env.name
            );
          }
        })
        .catch((error) => {
          this.setState({
            checking: false,
            checkResult: "404 Error",
            metadata: null,
          });
          if (error.response && error.response.data) {
            let err = error.response.data;
            if (providerConfig.metadataApi === "TEST_CONN_API_URL") {
              err = error.response.data.error;
            }
            this.setState({ checkError: err });
            this.props.stopSaveLoader();
          }
        });
    }
  };

  setCsvFields = (meta, contents, providerType) => {
    this.setState({ checking: true, checkResult: "", metadata: null });
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { project } = redux;
    const tenantId = project.tenantInfo.TenantId;
    const tenantKey = project.tenantInfo.Key;
    const appId = params.appId;
    const appKey = project.appData.uniquekey;
    return actions
      .setCsvFields(
        tenantId,
        appId,
        tenantKey,
        appKey,
        meta,
        contents,
        providerType,
        env.environmentId
      )
      .then((d) => {
        this.setState({
          checking: false,
          checkResult: "200 OK",
        });
        this.check(d.guids, false, this.props.providerConfig);
      })
      .catch(() => {
        this.setState({
          checking: false,
          checkResult: "404 Error",
          metadata: null,
        });
      });
  };

  deleteFlatFile = (fileName) => {
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { project } = redux;
    const tenantId = project.tenantInfo.TenantId;
    const appId = params.appId;
    return actions
      .deleteFlatFile(tenantId, appId, env.environmentId, fileName)
      .then(() => {
        this.check({}, true, this.props.providerConfig);
      });
  };

  clearCsvFields = () => {
    this.setState({ checking: false, checkResult: "", metadata: null });
  };

  seeFields = (target) => {
    const { redux, match, env, creds } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project, config } = redux;

    const endpoint =
      creds && creds.credentialsJson && creds.credentialsJson.rootUrl;
    const x = window.open();
    x.document.open("", "_blank");
    // x.document.write(makeContent(config.TRANS_API_URL, project.tenantInfo.TenantId, appId, endpoint, target.endpointSuffix, target.name, localStorage.getItem('userToken'), env.name));
    x.document.title = `Preview Data (${target.name})`;
    x.document.close();
    x.focus();
  };

  downloadcerts = () => {
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { project } = redux;
    const { appId } = params;
    actions.downloadcerts(
      project.tenantInfo.TenantId,
      appId,
      env.environmentId
    );
  };

  refreshMetdata = () => {
    this.setState({ refreshing: true });
    const { actions, match, redux, env, providerConfig, creds } = this.props;
    const { params } = match;
    const { project } = redux;
    const { appId } = params;
    actions
      .checkProviderMetadata(
        project.tenantInfo.TenantId,
        appId,
        providerConfig,
        env,
        creds && creds.credentialsJson,
        true,
        this.props.connectorKey,
        this.props.connectorName
      )
      .then(() => {
        this.setState({ refreshing: false });
      });
  };

  gencerts = () => {
    let gencert = true;
    if (this.state.showDownload) {
      gencert = confirm(
        "Certs have already been generated. Do you want to regenerate?"
      );
    }
    if (gencert) {
      this.setState({ isSaving: true });
      const { actions, match, redux, env, providerConfig } = this.props;
      const { params } = match;
      const { project } = redux;
      const { appId } = params;
      actions
        .gencerts(project.tenantInfo.TenantId, appId, env.environmentId)
        .then(() => {
          this.postCredentials({}, false, true, false, true);
          this.setState({ isSaving: false, showDownload: true });
        });
    }
  };

  downloadFileFromAws = (file) => {
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    return actions.downloadFileFromAws(
      project.tenantInfo.TenantId,
      appId,
      project.tenantInfo.Key,
      project.appData.uniquekey,
      env.id,
      file
    );
  };
  getWebsocketAgentCerts = () => {
    const { env, redux, match, actions } = this.props;
    const { params } = match;
    const { project } = redux;
    const { appId } = params;
    actions
      .getWebsocketAgentCerts(project.tenantInfo.TenantId, appId, env.id)
      .then((data) => {
        if (data && data.TenantId > 0 && data.AppId > 0) {
          this.setState({ showDownload: true });
        }
      });
  };

  checkOauthCreds = (phase) => {
    const { actions, match, redux, env, providerConfig } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    if (phase === "start") {
      actions.clearOauthCreds(providerConfig.type);
    } else {
      actions
        .checkOauthCreds(
          project.tenantInfo.TenantId,
          appId,
          env.name,
          providerConfig.type,
          this.props.connectorKey
        )
        .then((c) => {
          this.check(c.credentialsJson, true, this.props.providerConfig);
        });
    }
  };

  generateNewAdapterSecret = () => {
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    actions
      .generateNewAdapterSecret(project.tenantInfo.TenantId, appId, env.name)
      .then((c) => {
        actions.setAdapterSecret(c.credentials);
      });
  };

  getDocuSignOauthUrl = (type) => {
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    this.setState({ gettingDSOauthUrl: true, dsOauthUrl: "" });
    actions
      .getDocuSignOauthUrl(
        project.tenantInfo.TenantId,
        appId,
        "f249774c-4731-48f7-9fbd-0d79f241fcb2",
        type,
        env.name,
        this.props.connectorKey
      )
      .then((res) => {
        this.setState({ gettingDSOauthUrl: false, dsOauthUrl: res.url });
      });
  };
  getOauthUrl = (type) => {
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    this.setState({ gettingOauthUrl: true, oauthUrl: "" });
    actions
      .getOauthUrl(
        project.tenantInfo.TenantId,
        appId,
        "9090b61412a74d97ad9ed02c5fx7e705",
        type,
        env.name,
        this.props.connectorKey
      )
      .then((res) => {
        this.setState({ gettingOauthUrl: false, oauthUrl: res.url });
      });
  };

  saveCreds = (wizard) => {
    this.credSec.saveCreds(wizard);
  };

  disableOptionsWhenEmpty = () => {
    this.props.disable()
    this.props.disableDelete()
  }

  resetOptionsNoChange = () => {
    this.props.disable()
    this.props.enableDelete()
  }

  enableOptions = () => {
    this.props.enable()
    this.props.enableDelete()
  }

  changeConnecterKey = (e) => {
    this.props.setConnectorKey(e.target.value);
    const { redux } = this.props;
    const { project } = redux;
    const { environmentCredentials } = project;
    if (environmentCredentials && environmentCredentials.length > 0) {
      environmentCredentials.find((o) => o.key === e.target.value)
        ? this.resetOptionsNoChange()
        // prevent empty value
        : e.target.value.length > 0 ? this.props.enable() : this.disableOptionsWhenEmpty();
    }
  };

  changeConnecterName = (e) => {
    // prevent empty value
    e.target.value.length > 0 ? this.enableOptions() : this.disableOptionsWhenEmpty();
    this.props.setconnectorName(e.target.value);
  };
  render() {
    const {
      redux,
      providerConfig,
      creds,
      adapterSecret,
      newConnector,
      connectorKey,
      connectorName,
    } = this.props;
    const { checking, checkResult, fileSchema, fileSchemaCount, checkError } = this.state;
    const { project } = redux;
    const elevio_category_id = providerConfig.elevioArticleId;
    return (
      <div
        style={{
          width: newConnector ? "99%" : "100%",
          background: "white",
          marginTop: 12,
          height: "80vh",
        }}
      >
        <div
          style={{
            marginTop: 8,
            display: "flex",
            width: 500,
            left: "19.5%",
            position: "relative",
          }}
        >
          <div>
            <span>Name</span>
            <FormControl
              type="text"
              placeholder="key"
              required={true}
              style={{ width: 200, marginTop: 2 }}
              onChange={this.changeConnecterName}
              value={connectorName}
              inputRef={(ref) => {
                this.inputRef = ref;
              }}
            />{" "}
          </div>
          {providerConfig.type != "LocalFile" && (
            <div style={{ marginLeft: 10 }}>
              <span>Key</span>
              <FormControl
                type="text"
                placeholder="key"
                required={true}
                style={{ width: 200, marginTop: 2 }}
                onChange={this.changeConnecterKey}
                value={connectorKey}
                inputRef={(ref) => {
                  this.inputRef = ref;
                }}
              />{" "}
            </div>
          )}
        </div>
        {elevio_category_id ? (
          <div
            style={{
              float: "right",
              marginTop: -57,
              border: "1px solid #dfe0e0",
            }}
          >
            {" "}
            <div
              style={{
                width: "35vw",
                height: "80vh",
                float: "right",
                overflow: "auto",
                zIndex: "7",
                border: "1px solid #dfe0e0",
                padding: 15,
              }}
            >
              <elevio-element
                data-type="article"
                data-id={elevio_category_id}
              ></elevio-element>{" "}
            </div>
          </div>
        ) : null}
        <CSSTransitionGroup
          transitionName="env-creds"
          transitionEnterTimeout={250}
          transitionLeaveTimeout={250}
        >
          <div className="env-creds">
            {checkError && (
              <div className="check-metadata-error">
                <div className="close-check-metadata-error">
                  <svg
                    className="close-check-metadata-error-x"
                    viewBox="0 0 60 60"
                    onClick={() => this.setState({ checkError: null })}
                    height="14"
                  >
                    <path
                      style={{ strokeWidth: 5, strokeLinecap: "round" }}
                      d="M 15.674663,15.572746 L 44.587629,44.485711 M 45.118838,15.420972 L 15.522889,45.016920"
                    />
                  </svg>
                </div>
                {checkError}
              </div>
            )}
            <CredSection
              ref={(instance) => {
                this.credSec = instance;
              }}
              misconfiguredConnectors={this.props.misconfiguredConnectors}
              multipleConnectors={this.props.multipleConnectors}
              config={providerConfig}
              checkOauthCreds={this.checkOauthCreds}
              checking={checking}
              wizard={this.state}
              tenantKey={project.tenantInfo.Key}
              accountKey={project.appData.uniquekey}
              adapterSecret={adapterSecret}
              creds={
                creds && creds.credentialsJson ? creds.credentialsJson : {}
              }
              connectionInfo={creds}
              check={this.checkConnection}
              setCsvFields={this.setCsvFields}
              getOauthUrl={this.getOauthUrl}
              deleteFlatFile={this.deleteFlatFile}
              getDocuSignOauthUrl={this.getDocuSignOauthUrl}
              generateNewAdapterSecret={this.generateNewAdapterSecret}
              envCheckResult={checkResult}
              inputs={inputs}
              fileSchema={fileSchema}
              fileSchemaCount={fileSchemaCount}
              fetchFileSchemas={this.fetchFileSchemas}
              clearCsvFields={this.clearCsvFields}
              downloadFileFromAws={this.downloadFileFromAws}
              gencerts={this.gencerts}
              downloadcerts={this.downloadcerts}
              postCredentials={this.postCredentials}
              getOauthCreds={this.getOauthCreds}
              getOAuthCredsZoom={this.getOAuthCredsZoom}
              getOAuthCredsCanvasOAuth2={this.getOAuthCredsCanvasOAuth2}
              getEnvCreds={this.getEnvCreds}
              newConnector={newConnector}
              getWebsocketAgentCerts={this.getWebsocketAgentCerts}
            />
          </div>
        </CSSTransitionGroup>
      </div>
    );
  }
}

const PreviewModal = ({
  opened,
  hideModal,
  config,
  resources,
  seeFields,
  showMetaData,
}) => {
  const isFromAdapter =
    config &&
    (config.metadataEndpoint === "testconnection" ||
      config.metadataEndpoint === "adaptermetadata");

  return (
    <Modal show={opened} onHide={hideModal} backdrop="static">
      <Header closeButton>
        <Title>{"Available " + config.label + " Resources"}</Title>
      </Header>
      <Body>
        <div
          style={{ overflow: "scroll", maxHeight: "69vh", padding: "1em 2em" }}
        >
          <div>
            {resources ? (
              <div>
                <span className="labelz">
                  Available {config.type} Data Objects
                  {isFromAdapter && <span>&nbsp;(click to preview data):</span>}
                </span>
                <ul style={{ listStyle: "none", paddingLeft: 5 }}>
                  {resources &&
                    resources.map((rsc, i) => {
                      return (
                        <li
                          key={i}
                          style={{
                            paddingLeft: rsc.parentRef
                              ? rsc.parentRef.length * 12 + 10
                              : 10,
                          }}
                        >
                          {isFromAdapter ? (
                            <a
                              onClick={() => seeFields(rsc, config.type)}
                              style={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            >
                              {rsc.name}
                            </a>
                          ) : (
                            <div>{rsc.name}</div>
                          )}
                        </li>
                      );
                    })}
                </ul>
              </div>
            ) : showMetaData ? (
              <img alt="spinner" src={images.ajax_loader} />
            ) : null}
          </div>
        </div>
      </Body>
      <Footer>
        <Button bsStyle="primary" onClick={hideModal}>
          Done
        </Button>
      </Footer>
    </Modal>
  );
};

export default ConnectorSection;
