export function prettyExpression(currentCronExp) {
  let updatedcron = currentCronExp;
  var runInterval = false;
  var hour = currentCronExp.split(" ")[1];
  if (currentCronExp.split(" ")[5] == "*") runInterval = true;
  // Check if time range is in decreasing order
  if (hour.includes("-")) {
    if (hour.includes("/")) {
      var [range, inc] = hour.split("/");
      var [a, b] = range.split("-");
      if (parseInt(a) > parseInt(b)) {
        if (a == 23) {
          var parsedHr = "0-" + b + "/" + inc + "," + "23";
        } else {
          var parsedHr = "0-" + b + "/" + inc + "," + a + "-23/" + inc;
        }
      } else if (parseInt(a) == parseInt(b)) {
        var parsedHr = a + "/" + inc;
      }
    } else {
      var [a, b] = hour.split("-");
      if (parseInt(a) > parseInt(b)) {
        var parsedHr = "0-" + b + "," + a + "-23";
      } else if (parseInt(a) == parseInt(b)) {
        var parsedHr = a;
      }
    }
  } else if (hour.includes("/")) {
    var [range, inc] = hour.split("/");
    var [a, b] = range.split("-");
    if (a == 23) {
      var parsedHr = "23";
    }
  }
  if (parsedHr) {
    var arr = currentCronExp.split(" ");
    arr[1] = parsedHr;
    updatedcron = arr.slice(0, 5).join(" ");
  } else {
    updatedcron = currentCronExp.split(" ").slice(0, 5).join(" ");
  }

  return updatedcron;
}

export function formatCronString(cronString) {
  let slicedCronString;

  // removing last index
  const cronArr = cronString.split(",");

  // dont remove last index if array lengh is 1
  if (cronArr.length === 1) {
    slicedCronString = cronArr[0];
  } else {
    slicedCronString = cronArr.slice(0, -1).join(",");
  }

  let parsedString = slicedCronString;

  // Replace every occurrence of "Every" with a blank string
  parsedString = parsedString.replace(/every/gi, "🔁");

  // Replace every occurrence of "Between" with a blank string
  parsedString = parsedString.replace(/between/gi, "");

  // Replace every occurrence of "and" with "-"
  parsedString = parsedString.replace(/and/gi, "-");

  // Replace every occurrence of "hours" with "H"
  parsedString = parsedString.replace(/hours/gi, "H");
  parsedString = parsedString.replace(/hour/gi, "H");

  // Replace every occurrence of "minutes" with "M"
  parsedString = parsedString.replace(/minutes/gi, "M");
  parsedString = parsedString.replace(/minute/gi, "M");

  return parsedString;
}
