import * as types from "../actions/types";

const initialState = {
  webhookTypes: null,
  webhooks: null,
  webhookSubscriptions: null,
  webhookUrlSubscriptions: null,
  webhookSubTimeseries: null,
  allTriggers: null,
  summaryTriggers: [],
  summaryTriggersLoading: null,
  detailsTriggers: [],
  totalDetailsTriggers: 0,
  detailsTriggersLoading: null,
  activeTriggers: 0,
  activeFilter: "thisWeek",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_WEBHOOK_TYPES:
      return {
        ...state,
        webhookTypes: action.data.data.filter(
          (f) =>
            f.name.substr(0, 5) !== "ceds." && f.name !== "lingk.api.bulkupload"
        ),
      };
    case types.SET_WEBHOOK:
      return {
        ...state,
        webhooks: action.data,
      };
    case types.SET_WEBHOOK_SUBS:
      return {
        ...state,
        webhookSubscriptions: action.data,
      };
    case types.SET_TRIGGERS:
      return {
        ...state,
        allTriggers: action.data,
      };
    case types.START_SUMMARY_TRIGGERS:
      return {
        ...state,
        summaryTriggersLoading: true,
        summaryTriggers: null,
      };
    case types.SET_SUMMARY_TRIGGERS:
      return {
        ...state,
        summaryTriggersLoading: false,
        summaryTriggers: action.data,
      };
    case types.START_DETAILS_TRIGGERS:
      return {
        ...state,
        detailsTriggersLoading: true,
        detailsTriggers: null,
        totalDetailsTriggers: 0,
      };
    case types.SET_DETAILS_TRIGGERS:
      return {
        ...state,
        detailsTriggersLoading: false,
        detailsTriggers: action.detailsTriggers,
        totalDetailsTriggers: action.totalDetailsTriggers,
      };
    case types.SET_ACTIVE_TRIGGERS:
      return {
        ...state,
        activeTriggers: action.data,
      };
    case types.SET_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: action.data,
      };
    case types.SET_WEBHOOKURL_SUBS:
      return {
        ...state,
        webhookUrlSubscriptions: action.data,
      };
    case types.SET_WEBHOOK_TIMESERIES:
      return {
        ...state,
        webhookSubTimeseries: action.data,
      };
    default:
      return state;
  }
};
