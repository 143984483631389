import React, { Component } from "react";
import { Button } from "react-bootstrap";
import EditTransRunResult from "./edittransRunResult";
import images from "../images";

export default class EditTransConsole extends Component {
  constructor() {
    super();
    this.state = {
      editorjson: null,
      executingFlag: false,
      eventData: null,
      fullscreen: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.setFullscreen = this.setFullscreen.bind(this);
    this.closeWindow = this.closeWindow.bind(this);
  }

  componentDidMount() {
    const { transformer, config, project } = this.props.redux;
    const { env, mode, recipeAsJob } = this.props;
    if (recipeAsJob) {
      this.setState({ executingFlag: true });
    }
    //recommended actions don't have eventContext! fixme
    const queryString = require("query-string");
    const sentFromQuickAction = queryString.parse(
      this.props.location.search.quick
    );
    let data;
    if (
      mode === "eventContext" ||
      sentFromQuickAction ||
      Object.keys(transformer.eventContext.eventData).length === 0
    ) {
      if (Object.keys(transformer.eventContext.eventData).length === 0) {
        data = {
          action: "create",
          fullPath: `/${config.FTP_DIR}${project.tenantInfo.Key}/${project.appData.uniquekey}/sample.zip`,
          eventDate: new Date().toISOString(),
          type: "file",
          ...(env && { environmentName: env.name }),
          ...(env && { environmentId: String(env.environmentId) }),
        };
        this.setEditorJson(data);
        this.props.actions.setTransformerContext({ eventData: data });
      } else {
        data = { ...transformer.eventContext.eventData };
        if (env && env.name) {
          this.setEditorJson({
            ...data,
            environmentName: env.name,
            environmentId: env.environmentId,
          });
        }
      }
    }

    this.setState({ eventData: data });
  }

  componentWillReceiveProps(newProps) {
    const { transformer } = this.props.redux;
    const { env, mode } = this.props;
    const { eventData } = this.state;

    const isNewEnv =
      newProps.env && newProps.env.name && newProps.env.environmentId;

    if (eventData && isNewEnv) {
      setTimeout(() => {
        this.setEditorJson({
          ...eventData,
          environmentName: newProps.env.name,
          environmentId: String(newProps.env.environmentId),
        });
      }, 10);
    } else if (
      (transformer.eventContext &&
        transformer.eventContext.eventData &&
        isNewEnv) ||
      (mode === null && newProps.mode === "eventContext")
    ) {
      setTimeout(() => {
        this.setEditorJson({
          ...transformer.eventContext.eventData,
          environmentName: newProps.env.name,
          environmentId: String(newProps.env.environmentId),
        });
      }, 10); // so this does not run simultaneously with componentDidMount
    }
  }

  setFullscreen() {
    let { fullscreen } = this.state;
    fullscreen = !fullscreen;

    this.setState({ fullscreen });
  }

  stopRunningJob() {
    const { actions, transformer, env } = this.props;

    var trans_id = transformer.Id;
    var env_id = env.environmentId;
    actions.callStopExecuteTransformer(trans_id, env_id);
    this.props.handleRecipeComplete();
  }

  handleChange(a) {
    let eventData = this.state.eventData;
    try {
      eventData = JSON.parse(a.getValue());
    } catch (error) {
      // console.log(error)
    }

    this.setState({ eventData });
  }
  componentDidUpdate() {
    if (
      this.props.mode === "runResults" &&
      !this.props.redux.transformer.executionCompleted
    ) {
      if (!this.state.executingFlag) {
        this.setState({ executingFlag: true });
      }
    }
  }

  closeWindow(close) {
    this.props.handleCancel(close);
    this.setState({ fullscreen: false });
  }

  setEditorJson(eventData) {
    const eventvars = this.eventvars;
    let editorjson = this.state.editorjson;

    if (editorjson) {
      editorjson.toTextArea();
    }

    if (eventvars) {
      editorjson = window.CodeMirror.fromTextArea(eventvars, {
        tabSize: 10,
        indentUnit: 2,
        indentWithTabs: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        viewportMargin: Infinity,
        mode: "application/ld+json",
        lineWrapping: true,
        lineNumbers: true,
        autoRefresh: true,
      });
      // editorjson.setSize(null, 300)
      editorjson.addKeyMap({
        Tab: function (cm) {
          if (cm.somethingSelected()) {
            editorjson.refresh();
            const sel = editorjson.getSelection("\n");
            // Indent only if there are multiple lines selected, or if the selection spans a full line
            if (
              sel.length > 0 &&
              (sel.indexOf("\n") > -1 ||
                sel.length === cm.getLine(cm.getCursor().line).length)
            ) {
              cm.indentSelection("add");
              return;
            }
          }
          if (cm.options.indentWithTabs) cm.execCommand("insertTab");
          else cm.execCommand("insertSoftTab");
        },
        "Shift-Tab": function (cm) {
          cm.indentSelection("subtract");
        },
      });

      editorjson.on("change", (a) => {
        this.handleChange(a);
        //this.setState({eventData:change})
      });
    } // end first one
    if (editorjson) {
      editorjson.getDoc().setValue(JSON.stringify(eventData, null, 2));
    }

    /*setTimeout(()=>{
      editorjson.refresh();
    }, 10);*/
    this.setState({ editorjson });
    // this.setState({ eventData })
  }

  parseResults(data) {
    const transResult = [];
    const kMaxResultSize = 100;
    const resultSize =
      data.length > kMaxResultSize ? kMaxResultSize : data.length;
    for (let k = 0; k < resultSize; k++) {
      transResult.push(
        <EditTransRunResult
          result={data[k].result}
          timer={data[k].timer}
          startTime={data[k].startTime}
          statement={data[k].statement}
          extendedProperties={data[k].extendedProperties}
          externalExecutionId={data[k].externalExecutionId}
          key={k}
          env={this.props.env.environmentId}
          appId={this.props.match.params.appId}
          stepGuid={data[k].stepGuid}
        />
      );
    }
    return transResult;
  }

  render() {
    const { ongoingExecutionLogs } = this.props;
    const { transformer } = this.props.redux;
    const { executingFlag } = this.state;
    if (!transformer) return <div />;
    let height, buttons, opacity;
    switch (this.props.mode) {
      case "eventContext":
        height = "360px";
        opacity = 1;
        buttons = (
          <Button
            bsSize="small"
            bsStyle="primary"
            onClick={() => {
              this.setState({ executingFlag: true });
              this.props.setExecutingFlag(false);
              this.props.startRecipe(
                this.state.editorjson.getDoc().getValue(),
                this.props.env
              );
            }}
          >
            <img alt="small-spinner" src={images.start_job} height="20" />
            &nbsp; Start Recipe
          </Button>
        );
        break;
      case "runResults":
        if (this.state.fullscreen) height = "78%";
        else height = "247px";
        opacity = 1;
        buttons = !transformer.executionCompleted ? (
          <Button
            disabled={transformer.disableTerminate}
            bsSize="small"
            bsStyle="primary"
            style={{ marginLeft: 10, width: 115 }}
            onClick={() => this.stopRunningJob()}
          >
            <img
              alt="small-spinner"
              src={
                this.state.fullscreen ? images.cancel_job : images.cancel_job
              }
              height="20"
            />
            &nbsp; Stop Recipe
          </Button>
        ) : null;

        break;
      case "minimize":
        height = "50px";
        opacity = executingFlag ? 0.6 : 0.9;
        buttons = (
          <div>
            <div style={styles.title}>
              {executingFlag ? (
                <span>Recipe Running...</span>
              ) : (
                <span>Job Complete</span>
              )}
            </div>
            <Button
              bsSize="small"
              bsStyle="primary"
              style={{ width: 46 }}
              onClick={() => this.props.changeConsoleMode("runResults")}
            >
              <img
                alt="small-spinner"
                src={images.minimize}
                style={{ transform: "rotate(180deg)" }}
                height="16"
              />
            </Button>
          </div>
        );
        break;
      default:
        height = "0px";
        opacity = 0;
        buttons = (
          <div>
            <div
              style={{
                display: "inline-block",
                fontWeight: "bold",
                color: "white",
                marginRight: "15px",
                paddingBottom: 10,
              }}
            >
              Event Context
            </div>
            <br />
          </div>
        );
    }

    const count = transformer.ongoingExecutionLogCount;
    const areOngoingLogs =
      transformer.ongoingExecutionLogs &&
      transformer.ongoingExecutionLogs.length > 0;
    const logsToShow = [];
    for (let i = 0; i < transformer.ongoingExecutionLogs.length; i++) {
      const s = transformer.ongoingExecutionLogs[i];
      if (s.completed) {
        logsToShow.push(s);
        if (!s.succeeded) {
          break;
        }
      } else {
        // push the first uncompleted one as well
        logsToShow.push(s);
        break;
      }
    }

    // if (this.scroller && logsToShow.length > 8 && this.state.executingFlag) {
    //   setTimeout(() => {
    //     this.scroller.scrollTop = this.scroller.scrollHeight;
    //   }, 100);
    // }

    // recipe NOT completed
    let RESULTS = (
      <div className="loading">
        <OngoingRecipeStep
          count={count}
          step={{
            statement: "Starting Recipe",
            completed: areOngoingLogs,
            succeeded: areOngoingLogs,
            sortOrder: -1,
          }}
        />
        {logsToShow.map((s, i) => {
          return <OngoingRecipeStep step={s} key={i} count={count} />;
        })}
      </div>
    );
    // if the recipe has completed...
    if (transformer && transformer.executionCompleted) {
      setTimeout(() => {
        // this.props.handleRecipeComplete()
        if (this.state.executingFlag) {
          this.setState({ executingFlag: false });
          this.props.setExecutingFlag(false);
          // this.scroller.scrollTop = 0;
        }
      }, 500);
      const data = this.parseResults(transformer.executionLogs);
      RESULTS = (
        <div>
          <div style={styles.resultsWrapper}>
            <div
              style={{
                display: "inline-block",
                width: "33%",
                marginLeft: "1%",
              }}
            >
              <span>Statement:</span>
            </div>
            <div style={{ display: "inline-block", width: "53%" }}>
              <span>Result:</span>
            </div>
            <div style={{ display: "inline-block", width: "13%" }}>
              <span>Timer:</span>
            </div>
          </div>
          {data}
          {transformer.executionError ? (
            <div style={styles.errorWrap}>
              <div style={styles.error}>
                {transformer.executionErrorMessage}
              </div>
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div>
        {buttons && (
          // <div style={{ width: "100%" }}>
          //   <div className="recipe-console-buttons-wrapper">
          <div style={styles.buttons}>{buttons}</div>
          //   </div>
          // </div>
        )}
        <div
          className={
            this.props.mode === "eventContext"
              ? "recipe-event-content-wrapper"
              : "recipe-console-content-wrapper"
          }
          style={{
            height: "100%",
          }}
        >
          <div
            style={
              this.props.mode === "eventContext"
                ? styles.recipeEventContent
                : styles.recipeConsoleContent
            }
            ref={(ref) => (this.scroller = ref)}
          >
            {this.props.mode === "runResults" && (
              <div>
                {/* this.state.executionTab */}
                <form className="form-horizontal-yaml">{RESULTS}</form>
              </div>
            )}
            {this.props.mode === "eventContext" && (
              <div className="edittrans-console-codemirror-wrap">
                <textarea
                  className="form-control-yaml"
                  type="text"
                  ref={(r) => (this.eventvars = r)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const OngoingRecipeStep = ({ step, count }) => {
  return (
    <div className="ongoing-recipe-step">
      {!step.completed && <img alt="spin" src={images.spinner} />}
      {!step.completed || (step.completed && step.succeeded) ? (
        <span
          style={step.completed ? { left: 16 } : { fontWeight: "bold" }}
          className="ongoing-recipe-step-text"
        >
          <span className="ongoing-recipe-step-statement">{`${step.statement}`}</span>
          {count && (
            <span style={{ float: "right" }}>
              {step.sortOrder + 2}/{count + 1}
            </span>
          )}
        </span>
      ) : (
        <span
          style={{ left: 16, fontWeight: "bold", color: "#eb5959" }}
          className="ongoing-recipe-step-text"
        >
          {step.result || "Error"}
        </span>
      )}
    </div>
  );
};

const styles = {
  title: {
    display: "inline-block",
    fontWeight: "bold",
    color: "white",
    marginRight: "15px",
  },
  buttons: {
    margin: 10,
  },
  errorWrap: {
    position: "relative",
    float: "left",
    width: "100%",
    padding: "0px 1px",
  },
  error: {
    background: "#EE99AA",
    color: "white",
    fontWeight: "bold",
    padding: "4px 8px",
    width: "100%",
  },
  recipeConsoleContent: {
    margin: "-5px 0px",
    height: "100%",
    transition: "all .35s",
    // overflowY: "scroll",
    outline: "1px solid #rgb(205, 211, 224) solid 1px",
    backgroundColor: "white",
    minHeight: "50vh",
  },
  recipeEventContent: {
    margin: "-5px 0px",
    backgroundColor: "white",
    outline: "1px solid #rgb(205, 211, 224) solid 1px",
  },
  resultsWrapper: {
    marginBottom: 1,
    position: "relative",
    float: "left",
    backgroundColor: "#fef7e0",
    color: "#e37400",
    borderBottom: "1px solid #fdd663",
    marginRight: 10,
    fontSize: 14,
    width: "100%",
    padding: "6px 8px 8px 5px",
  },
};
