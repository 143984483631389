import React, { Component, Fragment } from "react";

import Helmet from "react-helmet";
import images from "../images";
import RecurlyForm from "./recurlyForm";
import SubSuccessModal from "./subSuccessModal";
import { Button, DropdownButton, MenuItem, Form, Col, Row, Grid, FormGroup, ControlLabel, FormControl, HelpBlock } from "react-bootstrap";
import { If, Then, Else } from "react-if";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";
import OrderSummary from "./orderSummary";
function getAllIndexes(arr, val) {
  var indexes = [],
    i = -1;
  while ((i = arr.indexOf(val, i + 1)) != -1) {
    indexes.push(i);
  }
  return indexes;
}

class RegisterPublic extends Component {
  constructor() {
    super();
    this.recurlyForm = React.createRef();

    this.state = {
      selectedPlan: null,
      selectedAddons: [],
      additions: [],
      recurlyAddons: null,

      recurlyPlans: null,

      subFailed: false,

      reviewModal: false,
      creatingOrg: false,

      //fields
      orgInput: "",
      firstName: "",
      lastName: "",
      emailOne: "",
      emailTwo: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      postal: "",
      country: "",
      captcha: "",
      docuSignConnectors: [
        { key: "DocuSign Pre-Fill Extension", value: false },
        { key: "DocuSign Connector for Ellucian Colleague", value: false },
        { key: "DocuSign Connector for Oracle PeopleSoft", value: false },
        { key: "DocuSign Connector for Workday Student", value: false },
        { key: "DocuSign Connector for CampusNexus", value: false },
        { key: "DocuSign Connector for Jenzabar", value: false },
        { key: "Other", value: false },
      ],
      couponCode: "",
      currency: "USD",
      couponState: null,
      posting: false,
      modal: false,
      subKey: "",
      disableCoupon: false,
      requireBilling: true,
      jobTitle: "",
      showBillingInfo: false,
      userAddons: [],
    };

    this.submitForm = this.submitForm.bind(this);
  }

  componentWillMount() {
    // plans are hardcoded here to avoid wait if recurly api response time is slow
    // uncomment below to hook up proper api call to recurly for plans
    const { match, actions } = this.props;
    const { path } = match;

    actions.getRecurlyPlans().then((resp) => {
      let recurlyPlans = [];
      let selectedPlan;
      for (let i = 0; i < resp.length; i++) {
        recurlyPlans[i] = resp[i];
        if (path.includes("Trial") && resp[i].Code == "trial") {
          selectedPlan = resp[i];
          this.setState({ requireBilling: false });
        } else if (path.includes("Year") && resp[i].Code == "tiered-yearly") {
          selectedPlan = resp[i];
        } else if (
          resp[i].Code == "tiered-monthly" &&
          !path.includes("Year") &&
          !path.includes("Trial")
        ) {
          selectedPlan = resp[i];
        }
      }

      const queryString = require("query-string");
      const parsed = queryString.parse(this.props.location.search);
      const planCode = parsed.plan;
      selectedPlan.Label = "New Organization Signup for Lingk Platform";

      if (planCode) {
        selectedPlan.QueryParam = planCode;
        switch (planCode) {
          case "ds":
            selectedPlan.Label =
              "DocuSign Edition" + "(" + selectedPlan.Name + ")";
            break;
          case "sf":
            selectedPlan.Label =
              "Salesforce Edition" + "(" + selectedPlan.Name + ")";
            break;
        }
      }

      this.props.actions.getRecurlyAddons(selectedPlan.Code).then((d) => {
        this.setState({
          userAddons: ["DPU"],
          recurlyAddons: d,
          selectedPlan: selectedPlan,
          recurlyPlans: recurlyPlans,
          selectedAddons: d,
        });
      });
    });
  }

  setFormRef = (ref) => {
    this.form = ref;
  };

  getCoupon = () => {
    let { disableCoupon } = this.state;
    if (disableCoupon) {
      this.setState({
        couponCode: "",
        couponState: null,
        disableCoupon: false,
      });
      return;
    }

    this.props.actions.getRecurlyCoupon(this.state.couponCode).then((a) => {
      if (a === "Invalid") {
        this.setState({ couponState: a });
      } else {
        this.setState({ couponState: a, disableCoupon: true });
      }
    });
  };

  updateCoupon = (e) => {
    if (this.state.disableCoupon) {
      return;
    }

    this.setState({ couponCode: e });
  };

  addAddon = (selectedOne) => {
    let a = [...this.state.userAddons];
    const index = a.indexOf(selectedOne);
    if (index > -1) {
      a.splice(index, 1);
    } else {
      a.push(selectedOne);
    }
    this.setState({ userAddons: a });
  };

  handleAddons = (addon) => {
    let { selectedAddons, additions } = this.state;

    if (!selectedAddons.includes(addon)) {
      selectedAddons.push(addon);
      additions.push(addon.Name);
    } else {
      while (getAllIndexes(additions, addon.Name).length > 0) {
        const indexes = getAllIndexes(additions, addon.Name);
        additions.splice(indexes[0], 1);
      }

      const i = selectedAddons.findIndex((f) => f.Code === addon.Code);
      selectedAddons.splice(i, 1);
    }
    this.setState({ selectedAddons, additions });
  };

  reviewOrder = () => {
    this.setState((prev) => {
      return { reviewModal: !prev };
    });
  };

  showBillingInfo() {
    this.setState({ showBillingInfo: true });
    let data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      company: this.state.orgInput,
      Phone: this.state.phone,
      email: this.state.emailOne,
      Title: this.state.jobTitle,
    };
    this.props.actions.createSFLeads(data);
  }
  submitForm(token, err) {
    if (!this.state.requireBilling) {
      const { firstName, lastName, orgInput, emailOne, phone, jobTitle, captcha } =
        this.state;
      let errors = [];
      if (firstName == "") {
        errors.push("first name");
      }
      if (lastName == "") {
        errors.push("last name");
      }
      if (orgInput == "") {
        errors.push("organization name");
      }
      if (emailOne == "") {
        errors.push("email");
      }
      if (phone == "") {
        errors.push("phone");
      }
      if (jobTitle == "") {
        errors.push("job title");
      }
      if (captcha == "") {
        errors.push("captcha");
      }
      if (errors.length > 0) {
        alert("Please fill in " + errors);
        return;
      }
      this.createNewSubscription("");
    } else {
      let errors = [];
      if (err) {
        err.fields?.map((e) => {
          if (e == "number") errors.push("card number");
          else if (e == "month") errors.push("card month");
          else if (e == "year") errors.push("card year");
          else if (e == "first_name") errors.push("first name");
          else if (e == "last_name") errors.push("last name");
        });
      }
      const {
        address1,
        city,
        orgInput,
        emailOne,
        postal,
        phone,
        country,
        jobTitle,
        captcha
      } = this.state;
      if (orgInput == "") {
        errors.push("organization name");
      }
      if (phone == "") {
        errors.push("phone");
      }
      if (emailOne == "") {
        errors.push("email");
      }
      if (address1 == "") {
        errors.push("address");
      }
      if (city == "") {
        errors.push("city");
      }
      if (country == "") {
        errors.push("country");
      }
      if (postal == "") {
        errors.push("postal");
      }
      if (jobTitle == "") {
        errors.push("job title");
      }
      if (captcha == "") {
        errors.push("captcha");
      }
      if (errors.length > 0) {
        alert("Please fill in " + errors);
        return;
      }

      this.setState({ posting: true });
      this.createNewSubscription(token.id);
    }
  }

  createNewSubscription = (token, errors) => {
    this.setState({ subFailed: false });

    const { accessLevels, me } = this.props.redux.main;
    const {
      selectedPlan,
      selectedAddons,
      additions,
      currency,
      couponCode,
      firstName,
      lastName,
      orgInput,
      emailOne,
      emailTwo,
      phone,
      jobTitle,
    } = this.state;

    const userProfile = JSON.parse(localStorage.getItem("profile"));
    const accessKey =
      userProfile &&
      userProfile.user_metadata &&
      userProfile.user_metadata.access;
    let level = 1;
    if (accessKey) {
      const accessLevel = accessLevels.find((al) => accessKey === al.accessKey);
      if (accessLevel) {
        level = accessLevel.level;
      }
    }
    const queryString = require("query-string");
    const parsed = queryString.parse(this.props.location.search);
    const planCode = parsed.plan;
    if (planCode && planCode == "ds") {
      level = 12;
    } else if (planCode && planCode == "sf") {
      level = 7;
    }
    const addons = [];
    let a = this.state.userAddons;
    this.state.recurlyAddons.map((o) => {
      const index = a.indexOf(o.Name);
      if (index > -1) {
        addons.push({ addonCode: o.Code, addonQuantity: 1 });
      }
    });
    console.log("addons", addons);
    const data = {
      orgName: orgInput,
      addOns: addons,
      planCode: selectedPlan.Code,
      currency: currency, // fixme
      accountEmail: emailOne,
      accountFirstName: firstName,
      accountLastName: lastName,
      token: token != "" ? token : null,
      couponCode: couponCode,
      level: level,
      phone: phone,
      jobTitle: jobTitle,
    };

    if (emailOne !== emailTwo) {
      alert("Email address fields must match.");
      this.setState({ posting: false });
      return;
    }
    // if (!phone || !this.validatePhone(phone)) {
    if (!phone) {
      alert("Please specify valid phone number.");
      this.setState({ posting: false });
      return;
    }

    if (me.username) {
      this.props.actions.createRecurlyPurchaseLoggedIn(data).then((p) => {
        if (Array.isArray(p) && p[0].Message) {
          if (p[0].Field.includes("email")) {
            alert(emailOne + " is not a valid email address.");
          } else if (p[0].Field.includes("coupon")) {
            alert(couponCode + " is not a valid coupon.");
          } else {
            alert(p[0].Message);
          }
          this.setState({ posting: false });
          return;
        }

        this.props.actions.callGetMainTenantList();
        this.props.history.replace(`/?subscriber=loggedin`);
      });
    } else {
      this.props.actions.createRecurlyPurchase(data).then((p) => {
        if (Array.isArray(p) && p[0].Message) {
          if (p[0].Field.includes("email")) {
            alert(emailOne + " is not a valid email address.");
          } else if (p[0].Field.includes("coupon")) {
            alert(couponCode + " is not a valid coupon.");
          } else {
            alert(p[0].Message);
          }
          this.setState({ posting: false });
          return;
        }
        this.setState({ posting: false, modal: true, subKey: p });
      });
    }
  };

  triggerModal() {
    let modal = this.state.modal;
    modal = !modal;
    this.setState({ modal });
  }

  handleSelectPlan = (e) => {
    const selectedPlan = this.state.recurlyPlans.find((f) => f.Code === e.Code);
    this.setState({ selectedPlan, recurlyAddons: null });
    this.props.actions.getRecurlyAddons(selectedPlan.Code).then((data) => {
      this.setState({ recurlyAddons: data, selectedAddons: [], additions: [] });
    });
  };

  updateForm = (type, e) => {
    this.setState({ [type]: e });
  };
  validatePhone(inputtxt) {
    const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (inputtxt.match(phoneno)) {
      return true;
    }
    return false;
  }

  subtractAdditions = (e) => {
    let { userAddons } = this.state;
    const target = userAddons.findIndex((f) => f === e);
    const quantity = getAllIndexes(userAddons, e);
    if (target !== -1 && quantity.length > 1) {
      userAddons.splice(target, 1);
      this.setState({ userAddons });
    }
  };

  addAdditions = (e) => {
    let { userAddons } = this.state;
    userAddons.push(e);
    this.setState({ userAddons });
  };

  render() {
    const {
      recurlyPlans,
      selectedPlan,
      recurlyAddons,
      selectedAddons,
      orgInput,
      firstName,
      lastName,
      phone,
      emailOne,
      emailTwo,
      posting,
      disableCoupon,
      subFailed,
      couponState,
      subKey,
      modal,
      additions,
      couponCode,
      jobTitle,
      docuSignConnectors,
      showBillingInfo,
      userAddons,
    } = this.state;

    const enableContinue =
      orgInput != "" &&
      phone != "" &&
      firstName != "" &&
      lastName != "" &&
      emailOne != "" &&
      emailTwo != "" &&
      jobTitle != "";
    const plans = [];
    const price =
      selectedPlan &&
      (selectedPlan.UnitAmountInCents.USD / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

    let totalPrice = selectedPlan && selectedPlan.UnitAmountInCents.USD;

    if (recurlyPlans) {
      recurlyPlans.map((o, i) => {
        const cost = (o.UnitAmountInCents.USD / 100).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        plans.push(
          <MenuItem
            value={o.Name}
            key={i}
            onClick={() => this.handleSelectPlan(o)}
          >
            {o.Name} {cost}
          </MenuItem>
        );
      });

      // plans.push(
      //     <MenuItem divider />
      //   )
      // plans.push(
      //     <li>
      //     <a key="sendtopricingpage" target="_blank" href="https://www.lingk.io/pricing/"><span style={{color: '#337ab7', fontWeight:'normal'}}>View All Plans</span></a>
      //     </li>
      //   )
    }

    if (selectedAddons && selectedAddons.length > 0) {
      selectedAddons.sort((a, b) => {
        if (a.UnitAmountInCents.USD < b.UnitAmountInCents.USD) return -1;
        else if (a.UnitAmountInCents.USD > b.UnitAmountInCents.USD) return 1;
        return 0;
      });

      selectedAddons.map((o) => {
        let price = o.UnitAmountInCents.USD;
        const quantity = getAllIndexes(additions, o.Name);
        if (quantity !== -1) {
          price = price * quantity.length;
          totalPrice += price;
        }
      });
    }

    const discount =
      couponState &&
      couponState.discount_type &&
      couponState.discount_type === "percent"
        ? (100 - couponState.discount_percent) / 100
        : 1;
    const fixeddiscount =
      couponState &&
      couponState.discount_type &&
      couponState.discount_type === "dollars"
        ? couponState.discount_in_cents.USD
        : 0;

    totalPrice = totalPrice * discount - fixeddiscount;

    if (totalPrice < 0) {
      totalPrice = 0;
    }

    return (
      <div
        className="main"
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100vh"
        }}
      >
        {/* Left-Side */}
        <div
          className="left"
          style={{
            width: "50%",
            backgroundColor: "#fff",
            lineHeight: 1.5,
            alignContent: "center",
            justifyContent: "center",
            verticalAlign: "center",
            padding: "1rem",
            paddingTop: "250px",
          }}
        >
          <div className="lingk-logo">
            <img
              src={images.logo_black}
              height="80"
              style={{
                marginTop: 12,
                marginLeft: 150,
                cursor: "pointer",
              }}
              role="presentation"
            />
          </div>
          <div
            className="lingk-Text"
            style={{
              paddingTop: "80px",
              marginLeft: 150,
              fontWeight: "bold",
              paddingBottom: "100%"
            }}
          >
            <h1 style={{ fontSize: "50px" }}>
              Data integration, <br />
              the easy way.
            </h1>
          </div>
        </div>

        {/* Right-Side */}
        <div
          className="right"
          style={{
            width: "50%",
            backgroundColor: "#eaeaff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "2rem",
            paddingBottom: "2rem"
          }}
        >
          <div
            style={{
              background: "#fff",
              borderRadius: "8px",
              boxShadow: "0px 0px 6px 4px #00000014",
              color: "black",
              width: "580px",
              height: "490px",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              color: "black",
              paddingRight: "2rem",
              paddingLeft: "2rem",
              paddingTop: "3rem",
              paddingBottom: "3rem",
            }}
          >
            <RecurlyForm
              config={this.props.redux.config}
              ref={this.recurlyForm}
              isSubForm={true}
              subtractAdditions={this.subtractAdditions}
              addAdditions={this.addAdditions}
              spinnerOpts={spinnerOpts}
              additions={additions}
              couponCode={couponCode}
              discount={discount}
              updateCoupon={this.updateCoupon}
              getCoupon={this.getCoupon}
              couponState={couponState}
              disableCoupon={disableCoupon}
              updateForm={this.updateForm}
              firstName={firstName}
              lastName={lastName}
              emailOne={emailOne}
              emailTwo={emailTwo}
              phone={phone}
              orgInput={orgInput}
              jobTitle={jobTitle}
              submitForm={this.submitForm}
              setFormRef={this.setFormRef}
              selectedPlan={selectedPlan}
              selectedAddons={selectedAddons}
              handleAddons={this.handleAddons}
              recurlyAddons={recurlyAddons}
              requireBilling={this.state.requireBilling}
              showBillingInfo={this.state.showBillingInfo}
              handleShowBillingInfo={(value) =>
                this.setState({ showBillingInfo: value })
              }
              form={this.form}
              userAddons={userAddons}
            />
          </div>
        </div>
        <SubSuccessModal
          selectedPlan={selectedPlan}
          modal={modal}
          triggerModal={this.triggerModal}
          subKey={subKey}
          isTrial={!this.state.requireBilling}
          orgName={this.state.orgInput}
        />
      </div>
    );
  }
}

const styles = {
  main: {
    display: "inline-block",
    verticalAlign: "top",
    textAlign: "center",
    height: "calc(100%-50px)",
    width: "100%",
    marginTop: 40,
  },
  required: {
    color: "red",
  },
};

const spinnerOpts = {
  lines: 11, // The number of lines to draw
  length: 30, // The length of each line
  width: 12, // The line thickness
  radius: 25, // The radius of the inner circle
  scale: 0.2, // Scales overall size of the spinner
  corners: 1, // Corner roundness (0..1)
  color: "#444", // CSS color or array of colors
  fadeColor: "transparent", // CSS color or array of colors
  speed: 4, // Rounds per second
  rotate: 0, // The rotation offset
  animation: "spinner-line-fade-quick", // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  zIndex: 0, // The z-index (defaults to 2000000000)
  className: "spinner", // The CSS class to assign to the spinner
  top: "50%", // Top position relative to parent
  left: "50%", // Left position relative to parent
  //shadow: '0 0 1px transparent', // Box-shadow for the lines
  position: "absolute", // Element positioning
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = (state) => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPublic);
