import React, { Component } from "react";
import PropTypes from "prop-types";

import Helmet from "react-helmet";
import { head } from "../config";
import AuthService from "../helpers/authservice";
import Login from "../pages/login";
import LoggedIn from "../pages/loggedIn";
import ReactGA from "react-ga";
import FailedLogin from "../components/utils/failedLogin";
import environment from "environment";
import { withRouter } from "react-router-dom";
class AppWrapper extends Component {
  constructor(props) {
    super(props);
    const { AUTH0_CLIENT_ID, AUTH0_DOMAIN } = environment;
    if (window && window.document) {
      this.auth = new AuthService(
        AUTH0_CLIENT_ID,
        AUTH0_DOMAIN,
        props.actions,
        props.history
      );
    }
    this.logout = this.logout.bind(this);
  }

  logout() {
    this.props.history.push("/");
    const profile = this.auth.getProfile();
    const { user_id, email } = profile;
    this.auth.logout();
    ReactGA.ga("send", "event", {
      eventCategory: "User",
      eventAction: "Logout",
      eventLabel: `${email} logged out.`,
      dimension1: user_id,
    });
    this.forceUpdate();
  }
  render() {
    const { actions, redux, children, location } = this.props;
    const { main } = redux;
    const publicPage =
      window.location &&
      window.location.href &&
      (window.location.href.includes("/datamapping/") ||
        window.location.href.includes("/register"));
    const queryString = require("query-string");

    const parsed = queryString.parse(this.props.location.search);
    if ((this.auth && this.auth.loggedIn()) || publicPage) {
      return (
        <div style={{ height: "100%" }}>
          <Helmet {...head} />

          {
            <LoggedIn
              auth={this.auth}
              logout={this.logout}
              {...this.props}
              publicPage={publicPage}
            >
              {" "}
              {this.props.children}
            </LoggedIn>
          }
          {React.isValidElement(children) &&
          ((main && main.accessLevels) || publicPage)
            ? React.cloneElement(children, {
                redux,
                actions,
                auth: this.auth,
              })
            : null}
        </div>
      );
    } else if (parsed && parsed === "1") {
      // redirect from Auth0 error, no user token, signup/login failed
      return <FailedLogin {...this.props} />;
    }
    // visit the site for first time
    return (
      <Login auth={this.auth} {...this.props}>
        {this.props.children}
      </Login>
    );
  }
}

AppWrapper.propTypes = {
  redux: PropTypes.object,
  children: PropTypes.node,
};
export default withRouter(AppWrapper);
