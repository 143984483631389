import React, {Component} from 'react'
import { Table, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import images from '../images'

const icons={
  none: <svg fill="#bbc5d7" height="19" viewBox="0 0 24 24" width="24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M12 8V4l8 8-8 8v-4H4V8z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>,
  trim: <svg fill="#54698d" height="16" viewBox="0 0 24 24" width="24"
  xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none" />
    <circle cx="6" cy="18" fill="none" r="2" />
    <circle cx="12" cy="12" fill="none" r=".5" />
    <circle cx="6" cy="6" fill="none" r="2" />
    <path d="M9.64 7.64c.23-.5.36-1.05.36-1.64 0-2.21-1.79-4-4-4S2 3.79 2 6s1.79 4 4 4c.59 0 1.14-.13 1.64-.36L10 12l-2.36 2.36C7.14 14.13 6.59 14 6 14c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4c0-.59-.13-1.14-.36-1.64L12 14l7 7h3v-1L9.64 7.64zM6 8c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm0 12c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm6-7.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5.5.22.5.5-.22.5-.5.5zM19 3l-6 6 2 2 7-7V3z" />
  </svg>,
  split: <svg fill="#54698d" height="19" viewBox="0 0 24 24" width="24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M14 4l2.29 2.29-2.88 2.88 1.42 1.42 2.88-2.88L20 10V4zm-4 0H4v6l2.29-2.29 4.71 4.7V20h2v-8.41l-5.29-5.3z" />
  </svg>,
  concat: <svg fill="#54698d" height="19" viewBox="0 0 24 24" width="24"
    xmlns="http://www.w3.org/2000/svg">
    <g style={{transformOrigin:'12px 12px 0px',transform:'rotate(90deg)',
      WebkitTransformOrigin:'12px 12px 0px',WebkitTransform:'rotate(90deg)'}}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M17 20.41L18.41 19 15 15.59 13.59 17 17 20.41zM7.5 8H11v5.59L5.59 19 7 20.41l6-6V8h3.5L12 3.5 7.5 8z" />
    </g>
  </svg>,
  date: <svg fill="#54698d" height="18" viewBox="0 0 24 24" width="24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>,
  sigma: <svg fill="#54698d" height="19" viewBox="0 0 24 24" width="24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M18 4H6v2l6.5 6L6 18v2h12v-3h-7l5-5-5-5h7z" />
  </svg>,
  link: <svg fill="#54698d" height="19" viewBox="0 0 24 24" width="24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
  </svg>,
  bool: <svg fill="#54698d" height="19" viewBox="0 0 24 24" width="24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>,
  lookup:<svg fill="#54698d" height="19" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path d="M24 24H0V0h24v24z" id="a" />
    </defs>
    <clipPath id="b">
      <use overflow="visible" href="#a" />
    </clipPath>
    <path clipPath="url(#b)" d="M2.5 4v3h5v12h3V7h5V4h-13zm19 5h-9v3h3v7h3v-7h3V9z" />
  </svg>,
  ifnull:<svg fill="#54698d" height="19" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12.45 16h2.09L9.43 3H7.57L2.46 16h2.09l1.12-3h5.64l1.14 3zm-6.02-5L8.5 5.48 10.57 11H6.43zm15.16.59l-8.09 8.09L9.83 16l-1.41 1.41 5.09 5.09L23 13l-1.41-1.41z" />
  </svg>,
}

class MapTable extends Component {

  constructor(){
    super()
    this.state={
      showApiNames:false,
      showToggle:false,
      loaded:false,
      value:1
    }
  }

  componentWillMount(){
    this.recurseCheckIfMappings()
  }

  // this is for backward compatability, so old configs without labels will still work
  recurseCheckIfMappings(){
    const {mapping} = this.props
    if(mapping){
      const hasLabels = mapping && mapping.mappings.every((m)=>{
        return m.propertyFromLabel && m.propertyToLabel
      })
      if(hasLabels){
        this.setState({showToggle:true, loaded:true})
      } else {
        this.setState({showApiNames:true, loaded:true})
      }
    } else {
      setTimeout(()=>{
        this.recurseCheckIfMappings()
      },150)
    }
  }

  handleToggle = (e) => {
    this.setState({showApiNames:e==='system'})
  }

  render(){
    const { mapping, bundles } = this.props
    const {showApiNames, showToggle, loaded} = this.state
    const resourceGroups = bundles && bundles.bundles.map((b,i)=>{
      return {
        name: b.name,
        label: b.resourceLabel,
        resources: b.resources.map((rsc)=>{
          return {
            name: rsc.resourceName,
            label: rsc.resourceLabel,
            provider: rsc.provider
          }
        }),
        mappings: mapping && mapping.mappings.filter(m=>m.bundleIndex === i)
      }
    })

    const hasMappings = resourceGroups && resourceGroups.length > 0

    return(
      <div style={{position:'relative'}}>
        <br />
        <div style={{fontSize:'16px'}}>Integration Mappings</div>
        {showToggle && <div style={{position:'absolute', top:13, right:2, userSelect:'none'}}>
          <span className="labelz" 
            style={{marginTop:2,verticalAlign:'middle',display:'inline-block'}}>
            Labels:</span>&nbsp;&nbsp;
          <ToggleButtonGroup
            type="radio" bsSize="xsmall" name="options"
            value={showApiNames?'system':'friendly'} 
            onChange={this.handleToggle}>
            <ToggleButton value={'friendly'}>Friendly</ToggleButton>
            <ToggleButton value={'system'}>System</ToggleButton>
          </ToggleButtonGroup>
        </div>}

        {loaded && bundles && mapping ? <Table condensed hover style={{background:'white'}}>
          <thead className="mapping-table-header">
            {hasMappings ? <tr>
              <th>Source</th>
              <th>Mapping</th>
              <th>Transform</th>
              <th>Target</th>
            </tr> : <tr><th>Mappings</th></tr>}
          </thead>
          {hasMappings ? <tbody>
            {resourceGroups.map((group, i)=>
              <tr key={i}>
                <td>
                  <div style={{minHeight:group.resources.length*19}}>
                    {group.resources && group.resources.map((rsc, ii)=>{
                      const mapz = group.mappings && group.mappings
                        .filter(m=>m.resourceFromName===rsc.name)
                      const fieldNum = mapz ? mapz.length : 0
                      const rscName = showApiNames ? rsc.name : rsc.label
                      return(
                        <div key={ii} style={{
                          marginTop: ii!==0 ? 10 : 0,
                          height: fieldNum*19,
                          minHeight: 18
                        }}>
                          <strong>
                            {rsc.provider==='salesforceReader' ?
                              `${rscName.split(' - ')[0]} (Salesforce)` :
                              rscName.split(' - ')[0]
                            }
                          </strong>
                        </div>
                      )
                    })}
                  </div>
                </td>
                <td>
                  <div>
                    {group.resources && group.resources.map((rsc, iii)=>{
                      const mapz = group.mappings && group.mappings
                        .filter(m=>m.resourceFromName===rsc.name).sort((a)=>{
                          if(a.isExternalKeyMapping===true) return -1
                          return 1
                        })
                      
                      return(
                        <div key={iii} style={{
                          marginTop: iii!==0 ? 10 : 0,
                          minHeight: 18
                        }}>
                          {mapz && mapz.map((m, iv)=>{
                            const propFrom = !showApiNames && m.propertyFromLabel ? 
                              m.propertyFromLabel : m.propertyFromName
                            const propTo = !showApiNames && m.propertyToLabel ? 
                              m.propertyToLabel : m.propertyToName
                            return (<div key={iv} style={{height:19}}>
                              <div className="mapping-entry">{`${propFrom} → ${propTo}`}</div>
                            </div>)
                          })}
                        </div>
                      )
                    })}
                  </div>
                </td>
                <td>
                  <div>
                    {group.resources && group.resources.map((rsc, iii)=>{
                      const mapz = group.mappings && group.mappings
                        .filter(m=>m.resourceFromName===rsc.name).sort((a)=>{
                          if(a.isExternalKeyMapping===true) return -1
                          return 1
                        })
                      return(
                        <div key={iii} style={{
                          marginTop: iii!==0 ? 10 : 0,
                          minHeight: 18
                        }}>
                          {mapz && mapz.map((m, iv)=>
                            <div key={iv} style={{height:19,width:24,marginLeft:27}}>
                              {(m.transformations && m.transformations[0] && icons[m.transformations[0].type]) || icons['none']}
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </td>
                <td>
                  <strong style={{fontSize:16, whiteSpace:'wrap'}}>
                    {showApiNames ? group.name : group.label}
                  </strong>
                </td>
              </tr>
            )}
          </tbody> :
          <tbody>
            <tr>
              <td>
                No mappings yet. Click "Edit Configuration" to finish your integration.
              </td>
            </tr>
          </tbody>}
        </Table> :
        <div style={{margin:12}}>
          <img alt="spinner" src={images.ajax_loader} />
          <br /><br />
        </div>}
      </div>
    )
  }
}

export default MapTable
