import React, { Component } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import {FormControl, Button} from 'react-bootstrap'
import images from '../images'
import clipboard from './clipboard'

const styles = {
  header:{
    display:'inline-block',
    marginLeft:14,
    verticalAlign: 'bottom',
  },
  text:{
    maxWidth:900
  }
}

const Email = () => <div>
  <img
    role="presentation"
    width="48"
    height="48"
    src={images['Email']}
  />
  <h4 style={styles.header}>Get notified of your integration result via email</h4>
</div>

const Ethos = () => <div>
  <div style={{background:'white', display:'inline-block', padding:'10px', borderRadius:'50%'}}>
    <img
      role="presentation"
      width="48"
      height="48"
      src={images['Ethos']}
    />
  </div>
  <h4 style={{...styles.header, verticalAlign:'middle'}}>Setting up your Ellucian Ethos Instance</h4>
  <br /><br />
  <div style={styles.text}>
    <div>The Ellucian Ethos Integration Validator will test your Ellucian Ethos Integration implementation and send an email of the results. You can save the validator configuration and run after data changes or upgrades.</div>
    <br />
    <div>Here are the steps to validate your implementation:</div>
    <ol>
      <li>Environments: Create an environment</li>
      <li>Data Scenario: Select your Data Scenario to validate</li>
      <li>Object Mapping: Configure any additional Entities to include in the validation</li>
      <li>Deploy: Choose to run once or schedule</li>
    </ol>
  </div>
</div>

const Lingk = () => <div />

const LingkAdapter = props => <div>
  <img
    role="presentation"
    width="66"
    height="30"
    src={images['LingkAdapter']}
  />
  <h4 style={styles.header}>Setting up your Lingk Adapter Instance</h4>
  <div style={styles.text}>
    <div>The instructions to install and configure the Lingk Adapter are available in the <a href="https://guide.lingk.io/lingkrestadapter/lingkrestadapter.html"
      target="_blank" rel="noopener noreferrer">Help Center</a>.</div>
    <Adapter provider="Lingk Adapter" {...props} />
  </div>
</div>

const Banner = props => <div>
  <img
    role="presentation"
    width="66"
    height="48"
    src={images['Banner']}
  />
  <h4 style={styles.header}>Setting up your Banner Instance</h4>
  <div style={styles.text}>
    <div>To connect to Banner, the Lingk Adapter needs to be installed. The instructions to install and configure the Lingk adapter are available in the <a href="https://guide.lingk.io/lingkrestadapter/lingkrestadapter.html"
      target="_blank" rel="noopener noreferrer">Help Center</a>.</div>
    <Adapter provider="Banner" {...props} />
  </div>
</div>

const Moodle = () => <div>
  <img
    role="presentation"
    width="66"
    height="48"
    src={images['Moodle']}
  />
  <h4 style={styles.header}>Connect to Moodle</h4>
  <div style={styles.text}>
    <div>To connect to Moodle, set enter you Root Url and credentials into an Environment.</div>
  </div>
</div>

const Colleague = props => <div>
  <img
    role="presentation"
    width="66"
    height="48"
    src={images['Colleague']}
  />
  <h4 style={styles.header}>Setting up your Colleague Instance</h4>
  <div style={styles.text}>
    <div>To connect to Colleague, the Lingk Adapter needs to be installed. The instructions to install and configure the Lingk adapter are available in the <a href="https://guide.lingk.io/lingkrestadapter/lingkrestadapter.html"
      target="_blank" rel="noopener noreferrer">Help Center</a>.</div>
    <Adapter provider="Colleague" {...props} />
  </div>
</div>

const Blackboard = () => <div>
  <img
    role="presentation"
    width="60"
    height="48"
    src={images['Blackboard']}
  />
  <h4 style={styles.header}>Connecting your Blackboard Instance</h4>
  <div style={styles.text}>
    <div>To connect to Blackboard, visit the <a href="https://developer.blackboard.com/"
      target="_blank" rel="noopener noreferrer">Blackboard Developer Portal</a>. You will enter your Client Key and Client Secret in step 1 of the Integration Wizard.</div>
  </div>
</div>

const Canvas = () => <div>
  <img
    role="presentation"
    width="48"
    height="48"
    src={images['Canvas']}
  />
  <h4 style={styles.header}>Connecting your Canvas Instance</h4>
  <div style={styles.text}>
    <div>To connect to Canvas, visit the <a href="https://canvas.instructure.com/doc/api/index.html" target="_blank" rel="noopener noreferrer">Canvas Developer Page</a>. You will enter your Client Key and Client Secret in step 1 of the Integration Wizard.</div>
  </div>
</div>

const PeopleSoft = props => <div>
  <img
    role="presentation"
    width="131"
    height="30"
    src={images['PeopleSoft']}
  />
  <h4 style={styles.header}>Setting up your PeopleSoft Instance</h4>
  <div style={styles.text}>
    <div>To connect to PeopleSoft, the Lingk Adapter needs to be installed. The instructions to install and configure the Lingk Adapter are available in the <a href="https://guide.lingk.io/lingkrestadapter/lingkrestadapter.html"
      target="_blank" rel="noopener noreferrer">Help Center</a>.</div>
    <Adapter provider="PeopleSoft" {...props} />
  </div>
</div>

const RESTAPI = () => <div />

const LocalFile = () => <div>
  <img
    role="presentation"
    width="48"
    height="48"
    src={images['FlatFile']}
  />
  <h4 style={styles.header}>Upload CSV files from your computer.</h4>
  <div style={styles.text}>
    You can upload local CSV files in step in the Environment step of this wizard. Your files will be stored for 48 hours before being deleted from our system.
  </div>
</div>

const SFTP = () => <div>
  <img
    role="presentation"
    width="48"
    height="48"
    src={images['SFTP']}
  />
  <h4 style={styles.header}>Setting up a connection to your SFTP server.</h4>
  <div style={styles.text}>
    Make sure you have CSV files in the SFTP directory that you specify in the wizard. To support file names that change over time (such as timestamps in the file name), you can set File Patterns using wildcards (*) in the File Processing step.
  </div>
</div>

const Salesforce = () => <div>
  <img
    role="presentation"
    width="60"
    height="48"
    src={images['Salesforce']}
  />
  <h4 style={styles.header}>Setting up your Salesforce Instance</h4>
  <div style={styles.text}>
    We encourage the use of the HEDA package in Salesforce, and we provide automatic integration scenarios that map directly to HEDA objects and fields.
  </div>
</div>

export {
  Banner, 
  Colleague, 
  Blackboard, 
  PeopleSoft, 
  RESTAPI, 
  Canvas, 
  LingkAdapter, 
  Lingk,
  Ethos, 
  LocalFile, 
  SFTP,
  Salesforce,
  Email,
  Moodle
}

class Adapter extends Component {

  constructor(){
    super()
    this.state = {
      yourName:'',
      bannerAdminName:'',
      yourUsage:'',
      showForm:true,
      messageCopied:false,
    };
  }

  sendMessage = () => {
    clipboard.copy({
      'text/html': this.message.innerHTML
    })
    this.setState({messageCopied:true})
    setTimeout(()=>{
      this.setState({messageCopied:false})
    },4000)
  }

  changeInput = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  render(){
    const {provider} = this.props
    return(
      <div>
        <br />
        <strong>Complete this form to send a message to your {provider} Administrator:</strong>
        <br />
        <div style={{background:'white',width:725}}>
          <div className="banner-admin-form-collapse" onClick={()=>this.setState({showForm:!this.state.showForm})}>
            {`${this.state.showForm ? 'Skip' : 'Show'} This Message`}
          </div>
          <CSSTransitionGroup transitionName="intro-form"
              transitionEnterTimeout={250} transitionLeaveTimeout={250}>
            {this.state.showForm ?
              <div className="banner-admin-form intro-form">
                <div className="banner-admin-form-inputs">
                  <strong>1. Who are you?</strong>
                  <br />
                  <FormControl label="Your Name" name="yourName" onChange={this.changeInput}
                    value={this.state.yourName} style={{width:265}} />
                  <br />
                  <strong>2. Who is your {provider} Administrator?</strong>
                  <br />
                  <FormControl label={`${provider} Administrator Name`} name="bannerAdminName"
                    onChange={this.changeInput} value={this.state.bannerAdminName}
                    style={{width:265}} />
                  <br />
                  <strong>3. I am going to use the {provider} Adapter for...</strong>
                  <br />
                  <FormControl label="Your Usage" name="yourUsage"
                    onChange={this.changeInput} value={this.state.yourUsage}
                    style={{width:265}} />
                  <br />
                  <Button bsStyle="primary" onClick={this.sendMessage}>
                    Copy Message
                  </Button>
                  {this.state.messageCopied && <span style={{marginLeft:12}}>
                    Message copied!
                  </span>}
                </div>
                <div className="banner-admin-form-textarea">
                  <span className="labelz">Message</span><br />
                  <section className="banner-admin-form-message"
                    ref={(ref)=>{ this.message=ref }}>
                    <span>{'Hi '}
                      {this.state.bannerAdminName ? <span>{this.state.bannerAdminName}</span> : <strong>[{provider} Administrator Name]</strong>}{','}<br /><br />
                      {`We have purchased Lingk for ${provider} package to connect ${provider} to Salesforce. `}
                      {`I will be using the ${provider} Adapter in order to `}
                      {this.state.yourUsage ? <span>{this.state.yourUsage}</span> : <strong>[Your Usage]</strong>}
                      {'.'}<br /><br />
                      {'Included with the Lingk app is the Lingk Adapter:'}</span>
                    <ul style={{listStyle:'initial', paddingLeft:24}}>
                      <li>The Lingk Adapter runs on one of your servers.</li>
                      <li>Installation is easy and secure using Docker containers.</li>
                      <li>{provider} queries for standard data feeds are provided with the installation and can be configured as needed in the Adapter UI.</li>
                      <li>The Lingk Adapter will need external internet connectivity so Lingk can access the data.</li>
                    </ul><span><br />
                      {'Further instructions can be found at: '}</span>
                    <a href="https://guide.lingk.io/lingkrestadapter/lingkrestadapter.html" target="_blank" rel="noopener noreferrer">https://guide.lingk.io</a>
                    <br /><br />
                    {'Thank You,'}
                    <br />
                    {this.state.yourName ? <span>{this.state.yourName}</span> : <strong>[Your Name]</strong>}
                  </section>
                </div>
              </div>
            : null }
          </CSSTransitionGroup>
        </div>
        <br /><br />
      </div>
    )
  }
}
