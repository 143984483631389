import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import moment from "moment";

const timeFilters = [
  { label: "Past Hour", key: "pastHour" },
  { label: "Past 24 Hours", key: "past24Hours" },
  { label: "This Week", key: "thisWeek" },
  { label: "Past Week", key: "pastWeek" },
  { label: "Date Range", key: "dateRange" },
];

export default (props) => {
  let filter = timeFilters.find((t) => t.key == props.parentTimeFilter);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(filter.label);
  const { defaultStartTime, defaultEndTime } = props;
  let st = new Date(defaultStartTime);
  let et = new Date(defaultEndTime);

  let std = new Date(st.getFullYear(), st.getMonth(), st.getDate());
  let etd = new Date(et.getFullYear(), et.getMonth(), et.getDate());

  const [startDate, setStartDate] = useState(std);
  const [endDate, setEndDate] = useState(etd);
  const [fromTime, setFromTime] = useState(
    moment().hour(st.getHours()).minute(st.getMinutes())
  );
  const [toTime, setToTime] = useState(
    moment().hour(et.getHours()).minute(et.getMinutes())
  );
  const [showDatePicker, setShowDatePicker] = useState(false);

  function handleTimeFilter(t) {
    setSelectedTimeFilter(t);
    if (t == "Date Range") {
      setShowDatePicker(true);
    }
  }
  useEffect(() => {
    if (selectedTimeFilter == "Date Range") {
      setShowDatePicker(true);
    }
  }, []);
  function handleApplyFilter() {
    let filter = timeFilters.find((t) => t.label == selectedTimeFilter);
    if (filter.key == "dateRange") {
      let startTime = new Date(fromTime);
      startDate.setHours(startTime.getHours());
      startDate.setMinutes(startTime.getMinutes());
      let endTime = new Date(toTime);
      endDate.setHours(endTime.getHours());
      endDate.setMinutes(endTime.getMinutes());
      let d = startDate.toString();
      let d2 = endDate.toString();
      var index = d.lastIndexOf(":") + 3;
      props.applyDateFilter(d.substring(0, index), d2.substring(0, index));
      return;
    }
    props.applyFilter(filter.key);
  }
  return (
    <div
      style={{
        width: 280,
        height: "auto",
        minWidth: 192,
        display: "grid",
        position: "absolute",
        background: "white",
        zIndex: 120,
      }}
    >
      {timeFilters.map((t) =>
        showDatePicker && t.label == "Date Range" ? (
          <span className="time-filter-cell" style={{ height: 200 }}>
            <span>
              <input
                className="form-control-checkbox"
                type="radio"
                checked={selectedTimeFilter === t.label}
                onChange={() => handleTimeFilter(t.label)}
              />
              {t.label}
            </span>
            <br />
            <div style={{ marginTop: 20 }}>
              <div>
                From
                <br />
                <div style={{ display: "flex" }}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                  <TimePicker
                    style={{ width: 100 }}
                    showSecond={false}
                    format={"h:mm a"}
                    use12Hours
                    value={fromTime}
                    className="time-picker"
                    onChange={(value) => setFromTime(value)}
                  />
                </div>
              </div>
              <div>
                To
                <br />
                <div style={{ display: "flex" }}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate}
                  />
                  <TimePicker
                    style={{ width: 100 }}
                    showSecond={false}
                    className="time-picker"
                    onChange={(value) => setToTime(value)}
                    format={"h:mm a"}
                    use12Hours
                    value={toTime}
                  />
                </div>
              </div>
            </div>
          </span>
        ) : (
          <span className="time-filter-cell">
            <input
              className="form-control-checkbox"
              type="radio"
              checked={selectedTimeFilter === t.label}
              onChange={() => handleTimeFilter(t.label)}
            />
            {t.label}
          </span>
        )
      )}
      <div style={{ padding: 10, display: "flex", background: "#b8b8b8" }}>
        <Button
          bsStyle="default"
          style={{
            height: 30,
            verticalAlign: "top",
            padding: "1px 10px",
            width: 100,
          }}
          onClick={() => {
            handleApplyFilter();
          }}
        >
          Apply
        </Button>
        <Button
          bsStyle="default"
          style={{
            height: 30,
            verticalAlign: "top",
            padding: "1px 10px",
            width: 100,
            marginLeft: 10,
          }}
          onClick={() => props.closePopUp()}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
