import React, { Component } from "react";
import { Button } from "react-bootstrap";
import images from "../images";
import moment from "moment";

const titleStyles = {
  fontSize: "22px",
  float: "left",
  marginBottom: "5px",
};

export default class SubscriptionSettings extends Component {
  componentDidMount() {
    if (this.props.tenantInfo && this.props.tenantInfo.Key) {
      this.props.history.push({
        search: `?subscription=${this.props.tenantInfo.Key}`,
      });
      return;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.tenantInfo != prevProps.tenantInfo) {
      this.props.history.push({
        search: `?subscription=${this.props.tenantInfo.Key}`,
      });
    }
  }
  componentWillUnmount() {
    this.props.history.push(`/user`);
  }
  render() {
    const {
      subMode,
      subModeSelected,
      subscription,
      subAddons,
      reactivateSubscription,
      reactivateExpiredSubscription,
      redemptions,
      reactivating,
    } = this.props;

    let percentdiscount = 1;
    let fixeddiscount = 0;

    const redList =
      redemptions &&
      redemptions.map((o, i) => {
        if (o.discount_type === "percent") {
          percentdiscount = (100 - o.discount_percent) / 100;
        }
        if (o.discount_type === "dollars") {
          fixeddiscount += o.discount_in_cents.USD;
        }

        return (
          <div key={i} style={{ listStyle: "none" }}>
            {o.name}&nbsp;
            {o.discount_type === "percent" && (
              <span>({o.discount_percent}% OFF)</span>
            )}
            {o.discount_type === "dollars" && (
              <span>
                (-
                {(o.discount_in_cents.USD / 100).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
                )
              </span>
            )}
            {o.discount_type === "free_trial" && (
              <span>Free Trial Extension</span>
            )}
          </div>
        );
      });

    const addonList =
      subscription &&
      subscription.addOns &&
      subscription.addOns.map((o, i) => {
        const a = subAddons && subAddons.find((f) => f.Code === o.Code);
        return (
          <div key={i}>
            {a && a.Name} ({o.Quantity})
          </div>
        );
      });
    const currentTermStartedAt =
      subscription && moment(subscription.currentTermStartedAt).format("LL");
    const currentTermEndsAt =
      subscription && moment(subscription.currentTermEndsAt).format("LL");
    const currentPeriodStartedAt =
      subscription && moment(subscription.currentPeriodStartedAt).format("LL");
    const currentPeriodEndsAt =
      subscription && moment(subscription.currentPeriodEndsAt).format("LL");

    //const createdAt = subscription && moment(subscription.activatedAt).format("LLLL")
    const trialEnds =
      subscription && moment(subscription.trialEndsAt).format("LLLL");
    const nextPeriod =
      subscription && moment(subscription.currentPeriodEndsAt).format("LLLL");
    let planTotal = subscription && subscription.unitAmountInCents;

    subscription &&
      subscription.addOns &&
      subscription.addOns.map((o) => {
        planTotal += o.UnitAmountInCents * o.Quantity;
      });

    const canceled =
      subscription && subscription.state && subscription.state === "canceled";
    const expired =
      subscription && subscription.state && subscription.state === "expired";

    planTotal = planTotal * percentdiscount - fixeddiscount;

    const billingAmount = planTotal / 100 > 0 ? planTotal / 100 : 0; // subscription && subscription.totalAmountInCents //

    return (
      <div style={{ position: "relative" }}>
        <div style={titleStyles}>Subscription</div>
        <br />
        <br />
        {subscription ? (
          <div className="">
            <div className="">
              {subscription.plan ? (
                <div style={{ fontSize: 16 }}>
                  <div className="">
                    <b>Plan</b>
                    <div className="sub-details">
                      {subscription.plan.Name}&nbsp;&nbsp;
                      <span>
                        <a href="https://www.lingk.io/pricing/" target="blank_">
                          View Pricing Page
                        </a>
                      </span>
                    </div>
                  </div>

                  <div style={{ verticalAlign: "top" }}>
                    <div>
                      <b>Custom Options</b>
                    </div>
                    <div className="sub-details">
                      {addonList ? (
                        <div style={{ display: "inline-block" }}>
                          {addonList}
                        </div>
                      ) : (
                        "None"
                      )}
                    </div>
                  </div>

                  <div className="">
                    <b>Status</b>
                    <div className="sub-details">
                      <span
                        style={{
                          color: canceled
                            ? "red"
                            : expired
                            ? "#FF8C00"
                            : "green",
                        }}
                      >
                        {subscription.state}&nbsp;&nbsp;
                      </span>
                      {!reactivating ? (
                        <span>
                          <span>
                            {canceled && (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => reactivateSubscription()}
                              >
                                <span>Resume</span>
                              </a>
                            )}
                          </span>
                          <span>
                            {expired && (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => reactivateExpiredSubscription()}
                              >
                                <span>Reactivate</span>
                              </a>
                            )}
                          </span>
                        </span>
                      ) : (
                        <span>
                          <img alt="spinner" src={images.ajax_loader} />
                        </span>
                      )}
                    </div>
                  </div>

                  {/* <div className="">
          <b>Free Trial Ends</b>
          <div className="sub-details">{trialEnds}
          </div>
          </div> */}
                  <div className="" style={{ display: "flex" }}>
                    <b>Current Period</b>&nbsp;&nbsp;&nbsp;
                    <div className="sub-details">
                      {currentPeriodStartedAt} - {currentPeriodEndsAt}
                    </div>
                  </div>

                  {/* <div className="">
                    <b>Next Billing Period</b>
                    <div className="sub-details">{nextPeriod}</div>
                  </div> */}
                  <div className="" style={{ display: "flex" }}>
                    <b>Current Term</b>&nbsp;&nbsp;&nbsp;
                    <div className="sub-details">
                      {currentTermStartedAt} - {currentTermEndsAt}
                    </div>
                  </div>

                  {redList && redList.length > 0 && (
                    <div className="">
                      <b>Discount</b>
                      <div className="sub-details">{redList}</div>
                    </div>
                  )}

                  <div className="" style={{ display: "flex" }}>
                    <b>Billing Amount</b>&nbsp;&nbsp;&nbsp;
                    <div className="sub-details">
                      {billingAmount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}{" "}
                      USD
                    </div>
                  </div>
                </div>
              ) : (
                <div className="">This organization is not a subscriber.</div>
              )}
            </div>

            <br />

            {subscription.plan ? (
              <div style={{ width: "100%" }}>
                {/* <Button
                  style={{ display: "inline-block", marginRight: 10 }}
                  bsSize="small"
                  bsStyle={subModeSelected === "edit" ? "primary" : "default"}
                  onClick={() => subMode("edit")}
                >
                  Update Plan
                </Button> */}
                <Button
                  style={{ display: "inline-block", marginRight: 10 }}
                  bsSize="small"
                  bsStyle={
                    subModeSelected === "billing" ? "primary" : "default"
                  }
                  onClick={() => subMode("billing")}
                >
                  Update Billing
                </Button>
                {/* <Button
                  style={{ display: "inline-block", marginRight: 10 }}
                  bsSize="small"
                  disabled={expired || canceled}
                  bsStyle={
                    subModeSelected === "services" ? "primary" : "default"
                  }
                  onClick={() => subMode("services")}
                >
                  Get Services
                </Button> */}

                <Button
                  style={{ display: "inline-block", marginRight: 10 }}
                  bsSize="small"
                  bsStyle={
                    subModeSelected === "invoices" ? "primary" : "default"
                  }
                  onClick={() => subMode("invoices")}
                >
                  My Invoices
                </Button>
              </div>
            ) : (
              <div>
                <a href="https://www.lingk.io/requestdemo">
                  <Button
                    style={{ display: "inline-block" }}
                    bsSize="large"
                    bsStyle="primary"
                  >
                    Get Subscription
                  </Button>
                </a>
              </div>
            )}
          </div>
        ) : (
          <div>
            {/*loading...*/}
            <img alt="spinner" src={images.ajax_loader} />
          </div>
        )}

        <br />
      </div>
    );
  }
}
