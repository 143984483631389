import React, { Component } from "react";
import ConnectedAnalytics from "./connectedanalytics";
import Overview from "./overview";
import NewConnection from "./newconnection";
import DetailsWrap from "./details";
import { Route, Switch } from "react-router-dom";

class ConnectedAnalyticsParent extends Component {
  render() {
    return (
      <ConnectedAnalytics redux={this.props.redux} actions={this.props.actions}>
        <Switch>
          <Route render={() => <Overview {...this.props} />} />
          <Route
            name="newexperience"
            path="/a/:appId/ca/new"
            component={NewConnection}
          />
          <Route
            name="experiencedetails"
            path="/a/:appId/ca/:expId/details"
            component={DetailsWrap}
          />
          <Route
            name="editexperience"
            path="/a/:appId/ca/:expId/edit"
            component={NewConnection}
          />
        </Switch>
      </ConnectedAnalytics>
    );
  }
}
export default ConnectedAnalyticsParent
