import React, { Component } from "react";
import TimePicker from "rc-time-picker";
import images from "../images";
import {
  Button,
  FormGroup,
  ControlLabel,
  Checkbox,
  FormControl,
  DropdownButton,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import moment from "moment";
import range from "lodash/range";
import DataProfileFieldStyle from "../../styles/dataprofilefieldstyle";
import SettingsStyle from "../../styles/settingsstyle";
import CronBuilder from "../CronBuilder";
import Select from "../CronBuilder/components/components/Select";
import { If, Then, Else } from "react-if";

function getColorByBgColor(bgColor) {
  if (!bgColor) {
    return "";
  }
  return parseInt(bgColor.replace("#", ""), 16) > 0xffffff / 2
    ? "#000"
    : "#fff";
}

const urlregex =
  /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
const fileregex = /[-a-zA-Z0-9_.@()-]+\.[^.]+$/;

const toOptions = (_values) => {
  return _values.map(String).map((value) => ({
    value,
    label: value,
  }));
};

const recurringMinutes = (_values) => {
  var values1 = [];
  for (var prop in _values) {
    if (_values[prop] % 5 == 0) {
      values1.push({ value: _values[prop].toString(), label: _values[prop] });
    }
  }
  return values1;
};

var cronTimeZoneOptions = [];
cronTimeZoneOptions.push({
  value: "UTC",
  label: "(GMT+00:00) UTC-Coordinated Universal Time",
});
const recurringHoursOptions = toOptions(range(1, 24));
const recurringMinutesOptions = recurringMinutes(range(5, 60));

const timeZones = () => {
  let tzs = require("../recipe/timezones.json");
  return tzs;
};
export default class NewSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      targetType: "recipe",
      eventType: "webhook",
      sysSelected: false,
      webhookurl: "",
      selecteditem: [],
      modal: false,
      objectType: null,
      curVersion: null,
      selectedTab: 0,
      selectedDeployment: null,
      selectedRecipe: null,
      selectedEnvironment: null,
      subscriptionName: "",
      filepattern: "",
      abcMode: true,
      isCreating: false,
      selectedTemplate: "",

      selectedSource: "",
      dir: "",
      isfile: false,
      isrecurring: false,
      iswebhook: false,

      eventdate: "",
      type: "",
      action: "",
      filename: "",
      objectTypeChanged: false,
      frequency: "",
      frequencyUnit: "Hours",
      dailySchedule: moment().hour(6).minute(0),
      isSavingTrigger: false,

      //modal switches page 1 / 2
      modalSwitch: false,
      cronExpression: "0 9-17/1 * * 1-5 *",
      cronExplain: "",
      timeZone: moment.tz.guess(),
      startingAt: null,
    };

    this.selectData = this.selectData.bind(this);
    this.showModal = this.showModal.bind(this);
    this.sendAway = this.sendAway.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleToggleEventType = this.handleToggleEventType.bind(this);
    this.handleSubNameChange = this.handleSubNameChange.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.changeVersion = this.changeVersion.bind(this);
    this.selectDeployment = this.selectDeployment.bind(this);
    this.cancelCreation = this.cancelCreation.bind(this);
    this.createSubscription = this.createSubscription.bind(this);
    this.changeListMode = this.changeListMode.bind(this);

    this.handleSourceChange = this.handleSourceChange.bind(this);
  }

  componentWillMount() {

    if (this.props.triggerMode) {
      const { selectedEvent } = this.props;
      if (selectedEvent) {
        this.setState({
          selectedTemplate: selectedEvent.templateName,
        });
      }

      if (this.props.editMode) {
        if (selectedEvent && selectedEvent.repeatingSchedule) {
          this.setState({
            modalSwitch: true,
            selectedDeployment: selectedEvent && selectedEvent,
            frequency:
              selectedEvent && selectedEvent.repeatingSchedule.frequency,
            frequencyUnit:
              selectedEvent &&
              selectedEvent.repeatingSchedule.frequencyUnit + "s",
            selectedSource: "isrepeating",
            startingAt: selectedEvent.repeatingSchedule.startingAt
              ? moment
                  .utc(selectedEvent.repeatingSchedule.startingAt, "HH:mm")
                  .tz(moment.tz.guess())
              : null,
          });
        } else if (selectedEvent && selectedEvent.dailySchedule) {
          this.setState({
            modalSwitch: true,
            selectedDeployment: selectedEvent && selectedEvent,
            selectedSource: "isdaily",
            timeZone: selectedEvent.dailySchedule.timeZone || "UTC",
            dailySchedule: selectedEvent.dailySchedule.timeZone
              ? moment
                  .utc(selectedEvent.dailySchedule.timeUTC, "HH:mm")
                  .tz(selectedEvent.dailySchedule.timeZone)
              : moment(selectedEvent.dailySchedule.timeUTC, "HH:mm"),
          });
        } else if (selectedEvent && selectedEvent.cronExpression) {
          this.setState({
            selectedSource: "iscron",
            modalSwitch: true,
            selectedDeployment: selectedEvent && selectedEvent,
            cronExplain: selectedEvent && selectedEvent.cronExplain,
            cronExpression: selectedEvent && selectedEvent.cronExpression,
          });
        }
      } else {
        this.setState({ selectedSource: "isdaily" });
      }
    }
  }

  componentWillUnmount() {
    if (this.props.triggerMode) {
      this.props.resetCalendarState(false);
    }
  }

  handleTemplateChange(e) {
    this.setState({ selectedTemplate: e })
  }

  cancelCreation() {
    const { appId } = this.props.match.params;
    this.props.history.push(`/a/${appId}/events`);
  }

  changeListMode() {
    let { abcMode } = this.state;
    abcMode = !abcMode;
    this.setState({ abcMode });
  }

  handleSubNameChange(e) {
    this.setState({ subscriptionName: e });
  }

  selectTab(e) {
    this.setState({ selectedTab: e });
  }

  handleSourceChange(s) {
    if (s == "isdaily") {
      this.setState({ timeZone: moment.tz.guess() });
    }
    this.setState({
      iswebhook: s === "iswebhook",
      isfile: s === "isfile",
      isrecurring: s === "isdaily" || s === "isrepeating" || s === "iscron",
      selectedSource: s,
    });
  }

  onTimePick = (v) => {
    this.setState({ dailySchedule: v });
  };

  onPickStartinAt = (v) => {
    this.setState({ startingAt: v });
  };

  changeTimeZone = (e) => {
    this.setState({ timeZone: e.value });
  };

  onPickTimeUnit = (v) => {
    this.setState({ frequencyUnit: v });
  };

  changeFrequency = (e) => {
    this.setState({ frequency: e.value });
  };

  deleteUnunsedWebhookSubscriptionsForEnv(envId) {
    const { redux, actions } = this.props;
    const { transformer, project } = redux;
    transformer.webhookSubscriptions.forEach((subscription) => {
      const a = subscription.deliveryUrl.split("/");
      const subEnvId = a[a.length - 1];
      if (String(envId) === subEnvId || a.length < 9) {
        actions.callDeleteWebhookSubscription(
          project.tenantInfo.Key,
          project.appData.uniquekey,
          subscription.subscriptionGuid
        );
      }
    });
  }

  initSaveTrigger = async () => {
    //await document.getElementsByClassName("cron-builder__action")[0].click()
    let schedule = {};
    if (this.state.selectedSource === "isdaily") {
      let userSpecifiedTZ = moment(this.state.dailySchedule).tz(
        this.state.timeZone
      );
      let dailySdl = this.state.dailySchedule.toString();
      let timeUTC = userSpecifiedTZ.toString();
      var res = dailySdl.replace(
        dailySdl.slice(
          dailySdl.indexOf("GMT") + 3,
          dailySdl.indexOf("GMT") + 8
        ),
        timeUTC.slice(timeUTC.indexOf("GMT") + 3, timeUTC.indexOf("GMT") + 8)
      );
      schedule = {
        dailySchedule: {
          timeUTC: moment.utc(new Date(res)).format("HH:mm"),
          timeZone: this.state.timeZone,
        },
      };
    } else if (this.state.selectedSource === "isrepeating") {
      schedule = {
        repeatingSchedule: {
          frequency: parseInt(this.state.frequency, 10),
          frequencyUnit: this.state.frequencyUnit
            .toLowerCase()
            .substring(0, this.state.frequencyUnit.length - 1),
        },
      };
      const { startingAt } = this.state;
      if (startingAt) {
        schedule.repeatingSchedule.startingAt = moment
          .utc(new Date(startingAt))
          .format("HH:mm");
      }
    } else if (this.state.selectedSource === "iscron") {
      schedule = {
        cronexpression: this.state.cronExpression,
        cronexplain: this.state.cronExplain,
      };
    }

    this.saveTrigger(null, schedule);
  };

  clearTrigger = () => {
    const { actions, redux, match } = this.props;
    const { project } = redux && redux;
    const { params } = match;
    const { appId } = params;
    const { TenantId } = project && project.tenantInfo;

    const { selectedDeployment } = this.state;

    this.setState({ isSavingTrigger: true });
    this.props.actions
      .callClearTrigger(
        selectedDeployment.baseId,
        selectedDeployment.environmentId
      )
      .then(() => {
        actions.callGetTriggerSubscriptions(appId).then(() => {
          actions.callGetTriggerDetailsWithoutFilter(appId, TenantId);
          this.props.triggerModal();
        });
      });
  };

  getValidationState() {
    if (urlregex.test(this.state.webhookurl)) return "success";
    else return "error";
    return null;
  }

  getValidationStateFilename() {
    if (fileregex.test(this.state.filepattern)) return "success";
    else return "error";
    return null;
  }

  createSubscription() {
    const { redux, match, actions } = this.props;
    const { params } = match;
    const { project, config } = redux;
    const { objectType, selectedDeployment, targetType, webhookurl } =
      this.state;
    const tenantId = project.tenantInfo.TenantId;
    const { appId } = params;

    if (this.state.eventType === "File Upload Event") {
      const filepattern = this.state.filepattern;
      if (!filepattern) {
        alert("Something went wrong (no file pattern)");
        return;
      }
      this.saveTrigger(filepattern);
    } else if (this.state.eventType === "webhook") {
      if (!objectType) {
        alert("Something went wrong (no object type)");
        return;
      }

      if (targetType === "recipe" || targetType === "wizard") {
        const verbFilter = "*";
        const data = {
          objectTypeFilter: objectType,
          verbFilter,
          deliveryUrl:
            config.TRANS_API_URL +
            `${tenantId}/${appId}/webhooks/environments/${selectedDeployment.environmentId}`,
        };
        this.setState({ isCreating: true });
        actions
          .callCreateWebhookSubscription(
            project.tenantInfo.TenantId,
            appId,
            selectedDeployment.environmentId,
            selectedDeployment.baseId,
            data
          )
          .then(() => {
            this.props.history.push(`/a/${appId}/events`);
            this.clearUIState();
          });
      } else if (targetType === "webhook") {
        const verbFilter = "*";
        const data = {
          objectTypeFilter: objectType,
          verbFilter,
          deliveryUrl: webhookurl,
        };
        this.setState({ isCreating: true });
        actions
          .callCreateURLWebhookSubscription(
            project.tenantInfo.TenantId,
            appId,
            data
          )
          .then(() => {
            this.props.history.push(`/a/${appId}/events`);
            this.clearUIState();
          });
      }
    }
  }

  selectFileUpload = () => {
    const { targetType } = this.state;

    if (targetType === "webhook") {
      this.setState({
        objectType: "File Upload Event",
        sysSelected: false,
        eventType: "File Upload Event",
        targetType: "wizard",
        webhookurl: "",
        objectTypeChanged: true,
      });
    } else {
      this.setState({
        objectType: "File Upload Event",
        sysSelected: false,
        eventType: "File Upload Event",
        objectTypeChanged: true,
      });
    }
  };

  selectSysObject = (itemname) => {
    this.setState({
      objectType: itemname,
      eventType: "webhook",
      targetType: "webhook",
      selectedDeployment: null,
      selectedRecipe: null,
      selectedEnvironment: null,
      sysSelected: true,
      objectTypeChanged: true,
    });
  };

  //used for file upload events

  saveTrigger(filepattern, schedule) {
    const { project, config } = this.props.redux;
    const { appId } = this.props.match.params;
    const { selectedDeployment, selectedSource, selectedTemplate } = this.state;
    const { actions } = this.props;
    const { TenantId } = project && project.tenantInfo;

    const isfile = selectedSource === "isfile";
    const isrecurring =
      selectedSource === "isdaily" ||
      selectedSource === "isrepeating" ||
      selectedSource === "iscron";
    const iswebhook = selectedSource === "iswebhook";

    const directory = this.state.dir
      ? this.state.dir
      : `/${config.FTP_DIR}${project.tenantInfo.Key}/`;
    const eventdate = this.state.eventdate
      ? this.state.eventdate
      : new Date().toISOString();
    const action = this.state.action ? this.state.action : "create";
    const filename = this.state.filename ? this.state.filename : "sample.zip";
    const type = this.state.type ? this.state.type : "file";
    this.setState({ isSavingTrigger: true });
    this.props.actions
      .callSaveTrigger(
        project.tenantInfo.TenantId,
        {
          baseid: selectedDeployment.baseId,
          isfile,
          isrecurring,
          iswebhook,
          ...(filepattern && {
            directory,
            filepattern,
            action,
            filename,
            eventdate,
            type,
          }),
          ...schedule,
          environmentId: this.state.selectedDeployment.environmentId,
          templateName: selectedTemplate
        },
        selectedDeployment.baseId
      )
      .then(() => {
        this.clearUIState();
        this.setState({ isSavingTrigger: false });
        if (this.props.triggerMode) {
          this.props.triggerModal();
          actions.callGetTriggerSubscriptions(appId);
          actions.callGetTriggerDetailsWithoutFilter(appId, TenantId);
          actions.callGetTriggerSummary(appId, TenantId);
          actions.callGetActiveSchedules(appId, TenantId);
        } else {
          this.props.history.push(`/a/${appId}/events`);
        }
      })
      .catch((error) => {
        alert(error.response.data);
        this.setState({ isSavingTrigger: false });
      });
  }

  // handleFilePatternChange = (e) => {
  //   this.setState({ filepattern: e.target.value });
  // }

  selectDeployment(e, object, env) {
    const { selectedDeployment } = this.state;
    const { allTriggers } = this.props.redux.events;

    if (allTriggers && allTriggers.length > 0) {
      const selectedTrigger = allTriggers.filter(function (el) {
        return el.EnvironmentId == e.environmentId
          && el.BaseId == e.baseId
      })
      if (selectedTrigger && selectedTrigger.length > 0) {
        this.setState({
          selectedTemplate: selectedTrigger[0].templateName,
          modalSwitch: true
        })
      }
    }

    if (selectedDeployment) {
      if (
        selectedDeployment.transId === e.transId &&
        selectedDeployment.environmentId === env.id
      ) {
        this.setState({
          selectedDeployment: null,
          selectedRecipe: null,
          selectedEnvironment: null,
          modalSwitch: false
        });
      } else {
        this.setState({
          selectedDeployment: e,
          selectedRecipe: object,
          selectedEnvironment: env,
          modalSwitch: true
        });
      }
    } else {
      this.setState({
        selectedDeployment: e,
        selectedRecipe: object,
        selectedEnvironment: env,
        modalSwitch: true
      });
    }
  }

  checkTrigger = () => {
    const data = this.findTriggerForEnvIdOrNot(
      this.state.selectedDeployment.environmentId
    );
    if (data) {
      if (data.isFile) {
        this.setState({
          isfile: true,
          dir: data.Directory,
          filepattern: data.FilePattern,
          eventdate: data.EventDate,
          action: data.Action,
          type: data.Type,
          filename: data.FileName,
          templateName: data.templateName
        });
        this.handleSourceChange("isfile");
      } else if (data.IsWebhook) {
        this.handleSourceChange("iswebhook");
      } else {
        this.clearUIState();
      }
    } else {
      this.clearUIState();
    }
  };

  findTriggerForEnvIdOrNot = (envId) => {
    const { transformer } = this.props.redux;
    let data = null;
    if (envId) {
      data =
        transformer.allTriggers &&
        transformer.allTriggers.find((t) => {
          return t.EnvironmentId === envId;
        });
    } else {
      data =
        transformer.allTriggers &&
        transformer.allTriggers.find((t) => {
          return t.EnvironmentId === 0;
        });
    }
    return data;
  };

  clearUIState = () => {
    this.setState({
      dir: "",
      selectedSource: null,
      selectedDeployment: null,
      webhookurl: "",
      filepattern: "",
      objectType: null,
      objectTypeChanged: false,
      frequency: "",
      frequencyUnit: "",
      dailySchedule: moment().hour(0).minute(0),
      startingAt: null,
      selectedTemplate: "",
    });
  };

  changeVersion(v) {
    const { transformer } = this.props.redux;
    const trans =
      transformer && transformer.versions.find((ver) => ver.Version === v);
    if (trans) {
      this.setState({
        curVersion: v,
      });
    }
  }

  selectData(a, e, event) {
    const { actions } = this.props;
    const selecteditem = this.state.selecteditem;
    let newitem = selecteditem;

    if (event.target.classList.value !== "recipe-view-preview") {
      if (e.ProductizedIntegrationGuid) {
        if (selecteditem[0] !== e.ProductizedIntegrationGuid) {
          newitem = [];
          newitem.push(e.ProductizedIntegrationGuid);

          this.clearUIState();
        } else {
          newitem = [];
        }
      } else {
        if (selecteditem[0] !== e.BaseId) {
          newitem = [];
          newitem.push(e.BaseId);

          this.clearUIState();
        } else {
          newitem = [];
          actions.clearTransformer();
        }
      }

      this.setState({
        selecteditem: newitem,
        selectedDeployment: null,
        selectedRecipe: null,
        selectedEnvironment: null,
      });
    }
  }

  handleToggle(e) {
    this.setState({ targetType: e });

    if (e === "webhook") {
      this.setState({
        selectedDeployment: null,
        selectedRecipe: null,
        selectedEnvironment: null,
      });
    } else {
      this.setState({ webhookurl: "" });
    }
  }

  handleToggleEventType(e) {
    this.setState({ eventType: e, objectType: null, filepattern: "" });
  }

  sendAway(q, id) {
    const { appId } = this.props.match.params;
    const { selecteditem } = this.state;

    if (selecteditem && selecteditem.Configuration) {
      this.props.history.push(`/a/${appId}/wiz/${id}`);
    } else if (selecteditem) {
      this.props.history.push(`/a/${appId}/tf/${id}`);
    }
  }

  showModal() {
    let modal = this.state.modal;
    modal = !modal;
    this.setState({ modal });
  }

  render() {
    const styles = {
      icon: {
        height: 24,
        marginTop: -3,
        marginRight: 3,
        display: "inline-block",
      },
      iconNoFilter: {
        height: 24,
        marginTop: -3,
        marginRight: 3,
        display: "inline-block",
      },
      countericonrecipe: {
        height: 30,
        filter: "invert(80%)",
      },
      countericonwizard: {
        height: 30,
        filter: "invert(80%)",
      },
      modalicon: {
        float: "left",
        height: 40,
        display: "block",
        marginRight: 4,
        filter: "invert(80%)",
        marginTop: -7,
      },
      eventTab: {},
      selectedTab: {
        color: "#fff",
        background: "#444",
      },
      currentPhase: {
        fontSize: 18,
        fontWeight: "bold",
      },
      mutedPhase: {
        fontSize: 18,
      },
      iconRecipe: {
        filter: "invert(0%)",
        height: 18,
        marginTop: -3,
        marginRight: 3,
        display: "inline-block",
      },
      iconInvert: {
        filter: "invert(100%)",
        height: 18,
        marginTop: -3,
        marginRight: 3,
        display: "inline-block",
      },
      subscriber: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      subscriberUrl: {
        textDecoration: "underline",
        wordBreak: "break-all",
      },
    };

    const { project, config, transformer, events } = this.props.redux;
    const { availableTemplates } = this.props;
    const {
      modalSwitch,
      selecteditem,
      filepattern,
      objectType,
      selectedRecipe,
      selectedEnvironment,
      selectedDeployment,
      webhookurl,
      abcMode,
    } = this.state;

    let selectedItemDetails =
      project &&
      project.wizards &&
      project.wizards.find(
        (f) => f.ProductizedIntegrationGuid === selecteditem[0]
      );

    let sortedDeployments = project &&
      project.allDeployments && [...project.allDeployments];

    const targets = [];
    const targetsWiz = [];
    var dailyTimeZoneOptions = timeZones();

    //categorize objects for dropdown

    const systemObject = [];
    const restObject = [];
    const brickftpObject = [];

    const timeUnitOptions = ["Minutes", "Hours"];

    systemObject.push(
      <MenuItem key={785623596784} header>
        System
      </MenuItem>
    );
    restObject.push(
      <MenuItem key={52635978102} header>
        Event REST API
      </MenuItem>
    );
    brickftpObject.push(
      <MenuItem key={8079652384341} header>
        BrickFTP
      </MenuItem>
    );
    brickftpObject.push(
      <MenuItem
        value="File Upload Event"
        key={329857698236}
        onClick={() => this.selectFileUpload()}
        active={objectType === "File Upload Event"}
      >
        File Upload Event
      </MenuItem>
    );

    if (events && events.webhookTypes && events.webhookTypes.length > 0) {
      events.webhookTypes.map((item, i) => {
        if (!item.private) {
          if (!item.name.includes("transformer")) {
            systemObject.push(
              <MenuItem
                value={item.name}
                key={i}
                onClick={() => this.selectSysObject(item.name)}
                active={objectType === item.name}
              >
                {item.name}
              </MenuItem>
            );
          } else {
            systemObject.push(
              <MenuItem
                value={item.name}
                key={i}
                onClick={() => this.selectSysObject(item.name)}
                active={objectType === item.name}
              >
                {item.name + " (legacy)"}
              </MenuItem>
            );
          }
        } else {
          restObject.push(
            <MenuItem
              value={item.name}
              key={i}
              onClick={() =>
                this.setState({
                  objectType: item.name,
                  sysSelected: false,
                  eventType: "webhook",
                  objectTypeChanged: true,
                })
              }
              active={objectType === item.name}
            >
              {item.name}
            </MenuItem>
          );
        }
      });
    }

    restObject.push(<MenuItem key={1649034} divider />);
    brickftpObject.push(<MenuItem key={1398671244} divider />);

    if (!abcMode) {
      sortedDeployments &&
        sortedDeployments.sort((a, b) => {
          if (a.environmentId < b.environmentId) return -1;
          else if (a.environmentId > b.environmentId) return 1;
          return 0;
        });
    } else {
      sortedDeployments &&
        sortedDeployments.sort((a, b) => {
          if (a.baseId < b.baseId) return -1;
          else if (a.baseId > b.baseId) return 1;
          return 0;
        });
    }

    sortedDeployments &&
      sortedDeployments.map((o, i) => {
        const transinfo =
          project &&
          project.transformers &&
          project.transformers.find((f) => f.BaseId === o.baseId);

        const envinfo =
          project &&
          project.environments &&
          project.environments.find((f) => f.id === o.environmentId);

        if (transinfo) {
          if (!transinfo.GeneratedFromPi) {
            targets.push(
              <div
                key={i}
                className={
                  selectedDeployment &&
                  selectedDeployment.transId === o.transId &&
                  selectedDeployment.environmentId === o.environmentId
                    ? "target-item selected-target"
                    : "target-item"
                }
                onClick={() => this.selectDeployment(o, transinfo, envinfo)}
              >
                <div style={{ display: "inline-block", marginRight: 4 }}>
                  <span style={styles.subscriber}>
                    {transinfo && transinfo.Name}
                  </span>
                  &nbsp;
                  <span style={{ display: "inline-block", fontSize: 10 }}>
                    ver. {o.transformerVersion}
                  </span>
                </div>

                <div style={{ display: "inline-block", width: "fit-content" }}>
                  <span style={{ fontSize: 12, display: "inline-block" }}>
                    -&nbsp;
                    <span
                      style={{
                        padding: "1px 5px 1px 5px",
                        fontWeight: "bold",
                        borderRadius: 2,
                        background: envinfo && envinfo.color,
                        color: getColorByBgColor(envinfo && envinfo.color),
                      }}
                    >
                      {envinfo && envinfo.name}
                    </span>
                    -&nbsp;
                    <span
                      style={{
                        padding: "1px 5px 1px 5px",
                        fontWeight: "bold",
                        borderRadius: 2,
                        background: "#6D92B8",
                        color: "#ffffff",
                      }}
                    >
                      {transinfo.ProcessingType}
                    </span>
                  </span>
                </div>
                {/*}
                      <div style={{position: 'absolute', background: '#404040',left: 0, bottom: 0, width: 22, height: 30}}>
                      <div  style={{marginTop: 6, marginRight: -2, textAlign: 'center'}}>
                      <img
                      className="subscriber-icon"
                      role="presentation"
                      src={images.description}
                      style={styles.iconRecipe}
                      />
                      </div>
                      
                      </div>  
                      */}
              </div>
            );
          } else {
            targetsWiz.push(
              <div
                key={i}
                className={
                  selectedDeployment &&
                  selectedDeployment.transId === o.transId &&
                  selectedDeployment.environmentId === o.environmentId
                    ? "target-item selected-target"
                    : "target-item"
                }
                onClick={() => this.selectDeployment(o, transinfo, envinfo)}
              >
                <div style={{ display: "inline-block", marginRight: 4 }}>
                  <span style={styles.subscriber}>
                    {transinfo && transinfo.Name}
                  </span>
                  &nbsp;
                  <span style={{ display: "inline-block", fontSize: 10 }}>
                    ver. {o.transformerVersion}
                  </span>
                </div>

                <div style={{ display: "inline-block", width: "fit-content" }}>
                  <span style={{ fontSize: 12, display: "inline-block" }}>
                    -&nbsp;
                    <span
                      style={{
                        padding: "1px 5px 1px 5px",
                        fontWeight: "bold",
                        borderRadius: 2,
                        background: envinfo && envinfo.color,
                        color: getColorByBgColor(envinfo && envinfo.color),
                      }}
                    >
                      {envinfo && envinfo.name}
                    </span>
                  </span>
                </div>
                {/*}
                      <div style={{position: 'absolute', background: '#404040',left: 0, bottom: 0, width: 22, height: 30}}>
                      <div  style={{marginTop: 6, marginRight: -2, textAlign: 'center'}}>
                      <img
                      className="subscriber-icon"
                      role="presentation"
                      src={images.description}
                      style={styles.iconRecipe}
                      />
                      </div>
                      
                      </div> 
                      */}
              </div>
            );
          }
        }
      });

    let dir = this.state.dir;
    if (!dir) {
      dir =
        "/" +
        config.FTP_DIR +
        project.tenantInfo.Key +
        "/" +
        project.appData.uniquekey;
    }

    let allset = false;

    if (
      (this.getValidationState() === "success" &&
        objectType &&
        objectType !== "File Upload Event") ||
      (selectedDeployment &&
        objectType &&
        objectType !== "File Upload Event") ||
      (selectedDeployment && this.getValidationStateFilename() === "success")
      // || this.getValidationState() === 'success'
      // && this.getValidationStateFilename() === 'success'
    ) {
      allset = true;
    }

    if (!selectedItemDetails) {
      selectedItemDetails =
        project &&
        project.transformers &&
        project.transformers.find((f) => f.BaseId === selecteditem[0]);
    }

    const versionItems = [];
    if (transformer.versions) {
      const versions = transformer.versions;

      for (let i = 0; i < versions.length; i++) {
        versionItems.push(
          <MenuItem
            eventKey={versions[i].Version}
            key={versions[i].Version + "" + i + Math.random()}
            className={DataProfileFieldStyle.linkStyles}
            active={this.state.curVersion === versions[i].Version}
          >
            <strong>{versions[i].Version}</strong>&nbsp;&nbsp;
            <span>-</span>&nbsp;&nbsp;
            <span>{moment(versions[i].LastModified).format("l")}</span>
          </MenuItem>
        );
      }
    }

    return (
      <div
        className={
          !this.props.triggerMode ? "overview-tab-content-sub" : "sub-modal-content"
        }
      >
        {/* Heading Section Starts */}
        {!this.props.triggerMode ? (
          // Subscriptions
          <div
            style={{
              margin: "10px 0px 0px 0px",
              fontSize: "20px",
              display: "inline-block",
            }}
          >
            New Subscription
          </div>
        ) : (
          // Schedules
          <div style={{ height: 20 }}>
            <div
              style={{
                position: "fixed",
                marginTop: -12,
                paddingTop: 14,
                background: "#ddd",
                zIndex: 1000,
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  margin: "10px 0px 0px 0px",
                  fontSize: "20px",

                  display: "inline-block",
                }}
              >
                {!this.props.editMode ? (
                  <span>New Schedule</span>
                ) : (
                  <span>Edit Schedule</span>
                )}
              </div>
              <div style={{ display: "inline-block", marginLeft: 200 }}>
                {!modalSwitch ? (
                  <div>
                    <Button
                      style={{ marginLeft: 10 }}
                      bsStyle="default"
                      bsSize="small"
                      className="sub-create-button"
                      onClick={() => this.props.triggerModal()}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{ marginLeft: 10, marginRight: 10 }}
                      bsStyle="primary"
                      bsSize="small"
                      className="sub-create-button"
                      disabled={!this.state.selectedDeployment}
                      onClick={() =>
                        this.setState({ modalSwitch: !modalSwitch })
                      }
                    >
                      Next
                    </Button>
                  </div>
                ) : (
                  <div>
                    {this.props.editMode ? (
                      <div>
                        <Button
                          style={{ marginLeft: 10 }}
                          bsStyle="default"
                          bsSize="small"
                          className="sub-create-button"
                          disabled={this.state.isSavingTrigger}
                          onClick={() => this.props.triggerModal()}
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{ marginLeft: 10 }}
                          bsStyle="primary"
                          bsSize="small"
                          className="sub-create-button"
                          disabled={
                            !this.state.selectedDeployment ||
                            !this.state.selectedSource ||
                            this.state.isSavingTrigger
                          }
                          onClick={this.initSaveTrigger}
                        >
                          Save
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button
                          style={{ marginLeft: 10 }}
                          bsStyle="default"
                          bsSize="small"
                          className="sub-create-button"
                          disabled={this.state.isSavingTrigger}
                          onClick={() =>
                            this.setState({ modalSwitch: !modalSwitch })
                          }
                        >
                          Back
                        </Button>
                        <Button
                          style={{ marginLeft: 10 }}
                          bsStyle="primary"
                          bsSize="small"
                          className="sub-create-button"
                          disabled={
                            !this.state.selectedDeployment ||
                            !this.state.selectedSource ||
                            this.state.isSavingTrigger
                          }
                          onClick={this.initSaveTrigger}
                        >
                          Create
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Heading Section Ends */}

        <br />

        {/*start of marginleft envelope*/}
        <div style={{
          height: "calc(100%-40px)",
          transform: modalSwitch && !this.props.triggerMode ? "translateY(-120px)" : null
        }}>

          {/* start of select recipe */}
          <div
            className="sub-create-area"
            style={{ transform: modalSwitch ? "translateX(-514px)" : null }}
          >
            <div style={{ height: "100%" }}>
              <div>

                {/* start of select target: Editor / Wizard / Webhook */}
                {!modalSwitch && (
                  <div
                    style={{ display: "block", marginBottom: 20 }}
                    className="rec-wiz-toggler"
                  >
                    <ToggleButtonGroup
                      type="radio"
                      bsSize="small"
                      name="options"
                      value={this.state.targetType}
                      onChange={(e) => this.handleToggle(e)}
                    >
                      <ToggleButton
                        style={{ background: "#f1f1f1" }}
                        disabled={this.state.sysSelected}
                        value={"recipe"}
                      >
                        Editor
                      </ToggleButton>
                      <ToggleButton
                        style={{ background: "#f1f1f1" }}
                        disabled={this.state.sysSelected}
                        value={"wizard"}
                      >
                        Wizard
                      </ToggleButton>
                      {!this.props.triggerMode && (
                        <ToggleButton
                          style={{ background: "#f1f1f1" }}
                          disabled={this.state.eventType === "File Upload Event"}
                          value={"webhook"}
                        >
                          Webhook
                        </ToggleButton>
                      )}
                    </ToggleButtonGroup>
                  </div>
                )}
                {/* end of select target*/}


                {/* start of controls as per target */}
                {this.state.targetType === "recipe" ||
                this.state.targetType === "wizard" ? (
                  <div>
                    <div style={{ display: "block" }}>
                      {this.state.targetType === "recipe" && (
                        <ControlLabel>
                          Recipe Deployments ({targets && targets.length})
                        </ControlLabel>
                      )}

                      {this.state.targetType === "wizard" && (
                        <ControlLabel>
                          Recipe Deployments ({targetsWiz && targetsWiz.length})
                        </ControlLabel>
                      )}
                    </div>

                    <div
                      style={{
                        float: "right",
                        marginRight: 75,
                        marginTop: -25,
                      }}
                    >
                      <Button
                        bsSize="xsmall"
                        style={{ background: "#eee" }}
                        onClick={this.changeListMode}
                      >
                        <img
                          src={images.ic_sort_grey600_48dp}
                          style={SettingsStyle.iconStyles}
                          alt="num"
                        />
                        {abcMode ? "ID" : "Environment"}
                      </Button>
                    </div>

                    <div
                      style={{
                        position: "relative",
                        height: 400,
                        width: 450,
                        overflowY: "auto",
                        float: "left",
                        marginBottom: 10,
                      }}
                    >
                      {this.state.targetType === "recipe" && (
                        <div
                          style={{
                            width: "fit-content",
                            height: "100%",
                            overflowY: "auto",
                          }}
                        >
                          {targets && targets.length > 0 ? (
                            targets
                          ) : (
                            <span>No deployed recipes (editor)</span>
                          )}
                        </div>
                      )}

                      {this.state.targetType === "wizard" && (
                        <div
                          style={{
                            width: "fit-content",
                            height: "100%",
                            overflowY: "auto",
                          }}
                        >
                          {targetsWiz && targetsWiz.length > 0 ? (
                            targetsWiz
                          ) : (
                            <span>No deployed recipes (wizard)</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  //If target type is Webhook URL
                  <div style={{ width: 390 }}>
                    <div style={{ display: "inline-block", marginRight: 10 }}>
                      <ControlLabel>Webhook URL</ControlLabel>
                      <br />
                      <FormGroup
                        controlId="formBasicText"
                        style={{ height: 30 }}
                        validationState={this.getValidationState()}
                      >
                        <FormControl
                          type="text"
                          style={{ width: 390 }}
                          placeholder="Insert url"
                          value={this.state.webhookurl}
                          onChange={(e) => {
                            this.setState({ webhookurl: e.target.value });
                          }}
                        />
                        <FormControl.Feedback />
                      </FormGroup>
                    </div>
                  </div>
                )} {/* end of controls as per target */}
              </div>
              {/* start of target config */}
              {modalSwitch && (
                <div
                  className={
                    this.props.triggerMode
                      ? "sub-type-env-trigger"
                      : "sub-type-env"
                  }
                >
                  <div
                    style={{ width: 450, marginBottom: 10, marginTop: 10 }}
                    className="rec-wiz-toggler"
                  >
                    <div style={{ marginBottom: 40 }}>
                      <hr />
                      <ControlLabel>Selected Deployment</ControlLabel>
                      <br />
                      <div style={{ marginTop: 5 }}>
                        {webhookurl ? (
                          <div
                            style={{
                              position: "relative",
                              width: 356,
                              wordBreak: "break-all",
                              paddingBottom: 5,
                            }}
                          >
                            <div style={{ position: "absolute", left: 0 }}>
                              <img
                                role="presentation"
                                src={images.webhookurl}
                                style={{
                                  height: 24,
                                  marginTop: -2,
                                  marginRight: 4,
                                }}
                              />
                              <span style={{ fontSize: 11 }}>{webhookurl}</span>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {selectedRecipe ? (
                              <div
                                style={{
                                  position: "relative",
                                  width: 356,
                                  paddingBottom: 5,
                                }}
                              >
                                <div style={{ marginLeft: 0, fontSize: 13 }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    {selectedRecipe.Name}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  position: "relative",
                                  width: 356,
                                  paddingBottom: 5,
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                  }}
                                >
                                  <img
                                    role="presentation"
                                    src={images.codeicon}
                                    style={styles.icon}
                                  />
                                </div>
                                <div style={{ marginLeft: 28, fontSize: 13 }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    {selectedDeployment && selectedDeployment.transformerName}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <br />
                      <strong>Template Name</strong>
                      <br />
                      {availableTemplates && availableTemplates.length > 0 &&
                        <div
                          style={{
                            display: "inline-block",
                            marginRight: 10,
                            position: "relative",
                          }}
                          className="dropdown-button-wrap"
                        >
                          {<DropdownButton
                            title={this.state.selectedTemplate != "" ? this.state.selectedTemplate.toUpperCase() : "Select Template"}
                            style={{ fontSize: 12, fontWeight: "bold" }}
                            id={"template-dropdown"}
                            onSelect={(e) => { this.handleTemplateChange(e) }}
                          >
                            {availableTemplates.length > 0 ? (availableTemplates.map((t) => {
                              return (<MenuItem key={t} eventKey={t} active={this.state.selectedTemplate === t}>{t.toUpperCase()}</MenuItem>)
                            })) : (<MenuItem key={"loading"} disabled={true} eventKey={"loading"}><Loading /></MenuItem>)}
                          </DropdownButton>}
                        </div>
                      }
                    </div>

                    {!this.props.triggerMode ? (
                      <div>
                        {/* if subscriptions modal */}
                        <div style={{ marginTop: 30 }}>
                          <ControlLabel>Event</ControlLabel>
                          <br />
                        </div>

                        <div style={{ marginTop: 0 }}>
                          <form className="form-horizontal-yaml">
                            <DropdownButton
                              id="webhook-subscriptions"
                              placeholder="Events"
                              bsStyle="default"
                              title={objectType || "Select Object Type"}
                            >
                              {restObject}
                              {brickftpObject}
                              {systemObject}
                            </DropdownButton>
                          </form>
                          {this.state.eventType === "File Upload Event" ? (
                            <div
                              className="file-upload-form"
                              style={{ width: 370, height: 190, marginTop: 20 }}
                            >
                              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                Rules:
                              </span>
                              <div style={{ marginTop: 5 }}>
                                <strong
                                  className="labelz"
                                  style={{ fontSize: "11px" }}
                                >
                                  Type
                                </strong>
                                <FormGroup
                                  controlId="formBasicText"
                                  style={{ height: 30 }}
                                >
                                  <FormControl
                                    type="input"
                                    placeholder=""
                                    value="Filter by Value"
                                    style={{ width: 356 }}
                                    disabled
                                  />
                                </FormGroup>

                                <strong
                                  className="labelz"
                                  style={{ fontSize: "11px" }}
                                >
                                  File Pattern
                                </strong>
                                <FormGroup
                                  controlId="formBasicText"
                                  style={{ height: 30 }}
                                  validationState={this.getValidationStateFilename()}
                                >
                                  <FormControl
                                    type="input"
                                    placeholder="File Pattern"
                                    value={filepattern}
                                    style={{ width: 356 }}
                                    onChange={(e) =>
                                      this.setState({
                                        filepattern: e.target.value,
                                      })
                                    }
                                  />
                                  <FormControl.Feedback />
                                </FormGroup>
                                <p
                                  style={{
                                    fontSize: 12,
                                    wordBreak: "break-all",
                                    marginLeft: 2,
                                    marginTop: 1,
                                    marginBottom: -10,
                                  }}
                                >
                                  Directory:&nbsp;{dir}/
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div style={{ height: 20 }} />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "100%",
                        overflowY: "auto"
                      }}>
                        {/* if trigger modal */}
                        <div
                          style={{
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <label htmlFor="Trigger Events">
                            Automate Your Integration
                          </label>
                          <br />
                          <Checkbox
                            onChange={() => this.handleSourceChange("isdaily")}
                            checked={this.state.selectedSource === "isdaily"}
                          >
                            Daily Schedule
                          </Checkbox>
                          <Checkbox
                            onChange={() =>
                              this.handleSourceChange("isrepeating")
                            }
                            checked={this.state.selectedSource === "isrepeating"}
                          >
                            Repeating Schedule
                          </Checkbox>
                          <Checkbox
                            onChange={() => this.handleSourceChange("iscron")}
                            checked={this.state.selectedSource === "iscron"}
                          >
                            Advanced
                          </Checkbox>
                        </div>
                      </div>
                    )}
                  </div>

                  {modalSwitch && !this.props.triggerMode ? (
                    <div className="sub-button-envelope">
                      <Button
                        style={{ marginRight: 10, float: "left" }}
                        bsStyle="default"
                        bsSize="small"
                        className="sub-create-button"
                        disabled={this.state.isCreating}
                        onClick={() =>
                          this.setState({ modalSwitch: !modalSwitch })
                        }
                      >
                        Back
                      </Button>

                      <Button
                        style={{ marginRight: 10, float: "left" }}
                        bsStyle="primary"
                        bsSize="small"
                        className="sub-create-button"
                        disabled={!allset}
                        onClick={() => this.createSubscription()}
                      >
                        {this.state.isCreating ? (
                          <span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                              alt="small-spinner"
                              src={images.ajax_loader_small}
                              height="8"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                        ) : (
                          <span>Create Subscription</span>
                        )}
                      </Button>
                    </div>
                  ) : this.props.editMode ? (
                    <div style={{ marginTop: 40 }}>
                      <Button
                        bsSize="small"
                        bsStyle="danger"
                        disabled={this.state.isSavingTrigger}
                        onClick={this.clearTrigger}
                      >
                        Delete Schedule
                      </Button>
                    </div>
                  ) : null}
                </div>
              )}  {/* end of target config */}
            </div>
          </div>
          {/* end of select recipe */}

          {/* display schedule configuration here*/}
          {this.props.triggerMode && modalSwitch && (
            <div
              style={{
                marginLeft: "30vw",
                width: "40vw",
                marginTop: "-60px",
                border: "1px solid grey",
                padding: "1rem"
              }}
            >
              <label htmlFor="Trigger Events">
                Schedule Configuration
              </label>
              <br />
              {this.state.selectedSource === "isrepeating" && (
                <div style={{ display: "flex", marginRight: 100 }}>
                  <div style={{ width: 148 }}>
                    <span className="labelz">Every</span>
                    <br />
                    <If
                      condition={
                        this.state.frequencyUnit == "hours" ||
                        this.state.frequencyUnit == "Hours"
                      }
                    >
                      <Then>
                        <Select
                          options={recurringHoursOptions}
                          onChange={this.changeFrequency}
                          value={this.state.frequency}
                        />
                      </Then>
                      <Else>
                        <Select
                          options={recurringMinutesOptions}
                          onChange={this.changeFrequency}
                          value={this.state.frequency}
                        />
                      </Else>
                    </If>
                  </div>
                  <div style={{ marginLeft: 16 }}>
                    <span className="labelz">Time Unit</span>
                    <br />
                    <DropdownButton
                      value={this.state.frequencyUnit}
                      bsStyle="default"
                      id="frequency-unit"
                      title={this.state.frequencyUnit || "Time Unit"}
                    >
                      {timeUnitOptions.map((unit, i) => (
                        <MenuItem
                          value={unit}
                          key={i}
                          onClick={() => this.onPickTimeUnit(unit)}
                          active={this.state.frequencyUnit === unit}
                        >
                          {unit}
                        </MenuItem>
                      ))}
                    </DropdownButton>
                  </div>
                  <div style={{ marginLeft: 10, display: "grid" }}>
                    <span className="labelz">
                      Start first time at
                    </span>
                    <TimePicker
                      showSecond={false}
                      defaultValue={moment().hour(0).minute(0)}
                      className="time-picker"
                      onChange={this.onPickStartinAt}
                      format={"h:mm a"}
                      use12Hours
                      value={this.state.startingAt}
                    />
                  </div>
                </div>
              )}

              {this.state.selectedSource === "isdaily" && (
                <div style={{ display: "flex" }}>
                  <span className="labelTZ">Time</span>
                  <br />
                  <div style={{ marginTop: 21, marginRight: 8, marginLeft: -26 }}>
                    <TimePicker
                      showSecond={false}
                      defaultValue={moment().hour(0).minute(0)}
                      className="time-picker"
                      onChange={this.onTimePick}
                      format={"h:mm a"}
                      use12Hours
                      value={this.state.dailySchedule}
                      disabled={false}
                    />
                  </div>
                  <div>
                    <span className="labelTZ">Time Zone</span>
                    <br />
                    <div style={{ width: 200, fontSize: 10 }}>
                      <Select
                        options={dailyTimeZoneOptions}
                        onChange={this.changeTimeZone}
                        value={this.state.timeZone}
                      />
                    </div>
                  </div>
                  <br />
                </div>
              )}

              {this.state.selectedSource === "iscron" && (
                <div style={{}}>
                  <br />
                  <CronBuilder
                    cronExpression={this.state.cronExpression}
                    onChange={(value) => {
                      this.setState({ cronExpression: value });
                      this.handleSourceChange("iscron");
                    }}
                    showResult={true}
                    getData={(cron) => {
                      this.setState({ cronExplain: cron.cronString });
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {/*end of marginLeft envelope*/}
        </div>

        {/*

          <Modal
                  show={ this.state.modal }
                  onHide={ this.showModal }
                  >
                  <Header>
                  <Title>
                   <img  role="presentation"
                src={selectedItemDetails && selectedItemDetails.Configuration ? images.description : images.ic_view_quilt_white_48dp}
                style={styles.modalicon}
                    />
                   {selectedItemDetails ? selectedItemDetails.Name : null} 
                   </Title>
                  </Header>

                    <Body>
                    <div style={{marginTop: 10, marginBottom: 10}}>
                    <span style={{marginLeft:10, fontWeight: 'bold'}}>Description</span>
                    {selectedItemDetails && 
                      <div style={{padding: 20, paddingTop: 5}}>
                      {selectedItemDetails.Description}
                      </div>
                       }
                    <span style={{marginLeft:10, fontWeight: 'bold'}}>Last Modified</span>
                    {selectedItemDetails ? 
                      <div style={{padding: 20, paddingTop: 5}}>
                      {moment(selectedItemDetails.LastModified).format('MMM Do, h:mm a')}
                      </div>
                       : null}
                       </div>
                    </Body>
                    <Footer>
                    <Button bsStyle="default" style={{float:'left'}} onClick={selectedItemDetails && selectedItemDetails.Configuration ? () => this.sendAway(null, selectedItemDetails.TransformerBaseId) : () => this.sendAway(null, selectedItemDetails.BaseId) }>
                    {selectedItemDetails && selectedItemDetails.Configuration ?  
                    <span>Edit Wizard</span> :
                    <span>Edit Recipe</span>}
                    </Button>
                    </Footer>
                  </Modal>
*/}
      </div>
    );
  }
}
