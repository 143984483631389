import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Switch from "react-switch";
import "react-tabs/style/react-tabs.css";
import {
  CustomPaging,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { Link } from "react-router-dom";


const columns = [
  { name: "executionTime", title: "Execution Date/Time" },
  { name: "transformerName", title: "Recipe Name" },
  { name: "environmentName", title: "Environment Name" },
  {
    name: "status",
    title: "Status",
  },
];

const filters = [
  { key: "lastMonth", value: "Last Month" },
  { key: "lastWeek", value: "Last Week" },
  { key: "yesterday", value: "Yesterday" },
  { key: "today", value: "Today" },
  { key: "tomorrow", value: "Tomorrow" },
  { key: "thisWeek", value: "This Week" },
  { key: "nextWeek", value: "Next Week" },
  { key: "thisMonth", value: "This Month" },
  { key: "nextMonth", value: "Next Month" },
];

const DetailScheduleListView = ({ props, environment }) => {
  const { actions, redux, match } = props;
  const { project, events } = redux && redux;
  const { params } = match;
  const { appId } = params;
  const { TenantId } = project && project.tenantInfo;

  const { totalDetailsTriggers, detailsTriggers, activeFilter } = events;

  const tableHeader = ({ column, children, ...restProps }) => (
    <TableHeaderRow.Content column={column} {...restProps}>
      <strong>{children}</strong>
    </TableHeaderRow.Content>
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [linkColumns] = useState(["status"]);
  const [pageSizes] = useState([5, 10, 20, 50, 100]);
  const [isDetailFilter, setIsDetailFilter] = useState(false);
  
  useEffect(() => {
    setCurrentPage(0);
    actions.changeActiveFilter("thisWeek");
    fetchData(currentPage, pageSize);
  }, [environment]);

  const handleFilterChange = (filter) => {
    const { key } = filter;
    setCurrentPage(0);
    
    actions.changeActiveFilter(key);
    fetchData(0, pageSize);
  };


  const LinkProvider = (props) => (
    <DataTypeProvider formatterComponent={linkFormatter} {...props} />
  );

  const linkFormatter = ({ row ,value }) => 
    value ? (
      <Link to={value} target="_blank" rel="noopener noreferrer">
        {row.executionStatus}
      </Link>
    ) : (
      "Scheduled"
    );
  
  const handlePageChange = (page) => {
    fetchData(page, pageSize);
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    fetchData(currentPage, size);
    setPageSize(size);
  };

  const fetchData = (currentPage, pageSize) => {
      const { id } = environment;
      
      !id
      ? actions.callGetTriggerDetails(appId, TenantId, null, currentPage + 1, pageSize)
      : actions.callGetTriggerDetails(appId, TenantId, id, currentPage + 1, pageSize);
  }

  return (
    <div style={{ marginRight: 20 }}>
      <div style={{ display: "flex" }}>
        <h4 style={{ margin: 0, padding: 0 }}>Detail View</h4>
        <label
          htmlFor="detailView"
          style={{ marginLeft: "auto", marginRight: 10 }}
        >
          <span style={{ marginRight: 5 }}>Enable Filter</span>
          <Switch
            checked={isDetailFilter}
            onChange={() => setIsDetailFilter(!isDetailFilter)}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={17}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={17}
            width={33}
            className="react-switch"
            id="detailView"
          />
        </label>
      </div>

      {/* filters */}
      <ul
        className="d-flex flex-column"
        style={{
          marginTop: 5,
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div role="group" className="btn-group">
          {filters.map((f) => (
            <li
              key={f.key}
              outline="true"
              color="primary"
              type="button"
              className={
                activeFilter === f.key
                  ? "btn btn-default active"
                  : "btn btn-default"
              }
              onClick={() => handleFilterChange(f)}
            >
              {f.value}
            </li>
          ))}
        </div>
      </ul>

      <Paper style={{ marginBottom: 40 }}>
        {events.detailsTriggersLoading ? (
          <div style={{ padding: 20, textAlign: "center", fontWeight: 600 }}>
            Loading...
          </div>
        ) : (
          <Grid rows={detailsTriggers} columns={columns}>
            {/* sorting */}
            <SortingState />
            <IntegratedSorting />

            {/* paging */}
              <PagingState
              currentPage={currentPage}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              pageSize={pageSize}
            />
              
            <CustomPaging totalCount={totalDetailsTriggers} />
            <PagingPanel pageSizes={pageSizes} />

            {/* filtering */}
            {isDetailFilter ? <FilteringState defaultFilters={[]} /> : null}
            {isDetailFilter ? <IntegratedFiltering /> : null}

            <LinkProvider for={linkColumns} />
            <Table />

            <TableHeaderRow
              showSortingControls
              contentComponent={tableHeader}
            />
            {isDetailFilter ? <TableFilterRow /> : null}
          </Grid>
        )} 
 
      </Paper>
    </div>
  );
};

export default DetailScheduleListView;
