import React, { Component } from 'react';
import { Table, Button } from 'react-bootstrap';
import Toggle from 'react-toggle'
import images from '../images';
import ReactTooltip from "react-tooltip";
import UserInviteModal from './userInviteModal'

export default class UserPicker extends Component {
  constructor() {
    super();
    this.state = {
      updatingUserAdminId: null,
      updatingUserAdminState: null,
      deletingUserId: null,
      invitingUsers: false,
    };
    this.updateUserAdmin = this.updateUserAdmin.bind(this)
    this.removeUser = this.removeUser.bind(this)
  }

  updateUserAdmin(e, userId) {
    const { actions, redux, match } = this.props
    const { params } = match
    const { project } = redux
    const appData = project.appData
    this.setState({ updatingUserAdminId: userId, updatingUserAdminState: e.target.checked })
    actions.updateUserAdminState(
      appData.tenantid, appData.Id, userId, e.target.checked
    ).then(() => {
      this.setState({ updatingUserAdminId: null, updatingUserAdminState: null })
    })
  }
  removeUser(userId) {
    const { actions, redux, match } = this.props
    const { params } = match
    const { project } = redux
    this.setState({ deletingUserId: userId })
    actions.removeUserFromWorkspace(project.tenantInfo.TenantId, params.appId, userId)
      .then(() => {
        this.setState({ deletingUserId: null })
      })
  }
  render() {
    const { redux } = this.props
    const { project, main } = redux
    const { updatingUserAdminId, updatingUserAdminState } = this.state
    return (
      <div>
        <div style={{ margin: '10px 0 8px 0', display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
          <div style={{
            fontSize: '18px', display: 'inline-block', verticalAlign: 'top', lineHeight: '30px'
          }}>
            Team
          </div>
          <Button bsSize="small" bsStyle="primary"
            style={{ marginRight: 0 }}
            onClick={() => this.setState({ invitingUsers: true })}>
            Invite Members
          </Button>
        </div>
        <div className="user-picker-table-wrap"
          style={{ background: project.permissions ? '#EEE' : 'transparent' }}>
          {project.permissions ? <Table bordered>
            <thead>
              <tr>
                <th>Members</th>
                <th>Role</th>
                {project.appData.identityIsAdmin && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {project.permissions.map((p, i) => {
                const isUpdatingAdmin = p.Identity && p.Identity.id === updatingUserAdminId
                return p.Identity && p.Identity.id > 0 ? (<tr key={p.Identity.id}>
                  <td style={{ lineHeight: '30px' }}>{p.Identity.username}</td>
                  <td>
                    <ReactTooltip place={"right"} className="tool-tip" />
                    <div data-tip={!project.appData.identityIsAdmin ? "You don't have admin access to update role!" : null}
                      style={{ display: "flex", justifyItems: "center", justifyContent: "center" }}>
                      <Toggle
                        className="custom-toggle-btn"
                        checked={isUpdatingAdmin ? updatingUserAdminState : p.isAdmin}
                        icons={{
                          checked: <span className="toggle-label toggle-label-admin">admin</span>,
                          unchecked: <span className="toggle-label toggle-label-user">user</span>
                        }}
                        onChange={e => this.updateUserAdmin(e, p.Identity.id)}
                        disabled={!project.appData.identityIsAdmin || p.Identity.id === main.me.id}
                      />
                    </div>
                  </td>
                  {project.appData.identityIsAdmin && <td>
                    <div style={{ display: "flex", justifyItems: "center", justifyContent: "center" }}>
                      <Button bsSize="small" bsStyle="primary"
                        style={{ height: 25, lineHeight: '15px', width: 66 }}
                        onClick={() => this.removeUser(p.Identity.id)}
                        disabled={p.Identity.id === main.me.id}>
                        {this.state.deletingUserId !== p.Identity.id ? 'Remove' :
                          <img alt="small-spinner" src={images.ajax_loader_small} height="8" />}
                      </Button>
                    </div>
                  </td>}
                </tr>) : <span key={i} />
              })}
            </tbody>
          </Table> : <div style={{ marginBottom: 20, marginLeft: 4 }}>
            <img alt="spinner" src={images.ajax_loader} />
          </div>}
        </div>

        {this.state.invitingUsers && <UserInviteModal {...this.props}
          onClose={() => this.setState({ invitingUsers: false })}
          tenantId={project.appData.tenantid}
          selectedAppId={project.appData.Id} // new account setting flow
          identityIsAdmin={project.appData.identityIsAdmin}
        />}
      </div>
    );
  }
}
