import React from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
const InvoiceLineItem = (props) => {
  const items = props.item.lineItems;
  let filteredItems = items.filter((i) => i.Origin == "usage_add_on");
  const i = items.map((o, i) => {
    const unitAmountInCents = (o.UnitAmountInCents / 100).toLocaleString(
      "en-US",
      { style: "currency", currency: "USD" }
    );
    const prev = items[i - 1];
    let subtotal;
    if (prev) {
      subtotal = o.TotalInCents + prev.subtotal;
    } else subtotal = o.TotalInCents;
    o.subtotal = subtotal;
    const discountInCents = (o.DiscountInCents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    const totalInCents = (o.TotalInCents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    const taxInCents = (o.TaxInCents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    const subtotalInCents = (subtotal / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

    const startDate = moment(o.StartDate).format("LL");
    const endDate = moment(o.EndDate).format("LL");
    return (
      <tr
        key={i}
        style={styles.invoiceLineItemContent}
        className="invoice-line-item-contents"
      >
        <td>
          {startDate} - {endDate}
        </td>
        <td>{o.Description}</td>
        <td>{o.Quantity}</td>
        <td>{totalInCents}</td>
        <td>{subtotalInCents}</td>
        {/* <div className="">
      Description: {o.Description && <span>{o.Description}</span>}
      </div>
      <div className="">
      Start Date: {startDate && <span>{startDate}</span>}
      </div>
      <div className="">
      End Date: {endDate && <span>{endDate}</span>}
      </div>
      <div className="">
      State: {o.State && <span>{o.State}</span>}
      </div>
      <div className="">
      Unit Amount: {unitAmountInCents && <span>{unitAmountInCents} {o.Currency}</span>}
      </div>
      <div className="">
      Tax Amount: {taxInCents && <span>{taxInCents} {o.Currency}</span>}
      </div>
      <div className="">
      Discount Amount: {discountInCents && <span>{discountInCents} {o.Currency}</span>}
      </div>
      <div style={{fontWeight:'bold'}}>
      Total Amount: {totalInCents && <span>{totalInCents} {o.Currency}</span>}
      </div> */}
      </tr>
    );
  });

  return (
    <div>
      {items && items.length > 0 && (
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>{i}</tbody>
        </Table>
      )}
    </div>
  );
};

const styles = {
  invoiceLineItemContent: {
    paddingBottom: 5,
    marginBottom: 5,
    position: "relative",
  },
  invoiceLineItem: {
    border: "1px solid #bbb",
    padding: 10,
    marginBottom: 20,
  },
};

export default InvoiceLineItem;
