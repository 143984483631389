import React, { Component } from "react";

import EnvironmentDropdown from "../utils/EnvironmentDropdown";

export default class EnvironmentHeader extends Component {
  constructor() {
    super();
    this.state = {
      selectedEnvironment: null,
    };
  }
  changeEnvironment(env) {
    this.setState({
      selectedEnvironment: env,
      //   editWarn: true,
      //   envCreds:null
    });

    // this.getEnvCreds(env)
  }

  render() {
    const { selectedEnvironment } = this.state;
    const { actions } = this.props;
    const { project, transformer } = this.props.redux;
    const { sessionEnvironment } = this.props.redux.environment;
    let showThisEnvironmentName;
    if (project.environments)
      showThisEnvironmentName = project.environments.find(
        (e) => e.id == sessionEnvironment
      );
    return (
      <EnvironmentDropdown
        showThisEnvironmentName={showThisEnvironmentName&& showThisEnvironmentName.name}
        uiEnvironment={selectedEnvironment}
        versions={transformer.versions}
        deployments={transformer.deployments}
        environments={project.environments}
        // disabled={!(project.environments && project.environments.length > 0)}
        disabled={false}
        onSelect={(env) => this.changeEnvironment(env)}
        actions={actions}
      />
    );
  }
}
