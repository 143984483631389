import React, { Component } from 'react';

import { Table, Button } from 'react-bootstrap';
import Toggle from 'react-toggle';
import images from '../images';
import ReactTooltip from "react-tooltip";
import TenantInviteModal from './tenantInviteModal'

const titleStyles = {
  fontSize: '22px',
  float: 'left',
  marginBottom: '5px',
}
const dialogStyles = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  width: 300,
  height: 180,
  marginTop: -90,
  marginLeft: -150,
  boxSizing: 'border-box',
  borderRadius: 3,
  padding: '2rem',
  backgroundColor: '#fff',
  display: 'block',
  boxShadow: '0 1px 15px 0 rgba(0,0,0,.1),0 0 0 0 transparent',
  zIndex: 2000,
  border: '1px solid #c9d7df',
}

export default class UserSettings extends Component {

  constructor() {
    super();
    this.state = {
      userIdToBeDeleted: null,
      deletingUser: false,
      //tenantInfo:null,
      invitingUsers: false
    }
    this.callEditUser = this.callEditUser.bind(this);
    this.callDeleteUser = this.callDeleteUser.bind(this);
  }

  componentDidMount() {
    //this.props.actions.callGetUsers();
    this.props.actions.setShowMenu(false)
    // this.props.actions.callGetTenantInfo(this.props.tenantId)
    // .then((ti)=>{
    //   this.setState({tenantInfo: ti[0]})
    // })
  }

  callDeleteUser(userId) {
    this.setState({ userIdToBeDeleted: userId })
  }

  actuallyDeleteUser() {
    this.setState({ deletingUser: true })
    this.props.actions.callDeleteUser(
      this.props.tenantId, this.state.userIdToBeDeleted
    ).then(() => {
      this.setState({ userIdToBeDeleted: null, deletingUser: false })
    })
  }

  // componentWillReceiveProps(newProps){
  //   if(this.props.tenantId!==newProps.tenantId){
  //     this.setState({tenantInfo:null})
  //     this.props.actions.callGetTenantInfo(newProps.tenantId)
  //     .then((ti)=>{
  //       this.setState({tenantInfo: ti[0]})
  //     })
  //   }
  // }

  callEditUser(userid) {
    this.props.history.push({
      pathname: '/edituser',
      state: {
        user: userid,
        tenantId: this.props.tenantId
      }
    })
  }

  updateUserAdmin(e, userId) {
    const { actions } = this.props
    this.setState({ updatingUserAdminId: userId, updatingUserAdminState: e.target.checked })
    actions.updateTenantAdminState(
      this.props.tenantId, userId, e.target.checked
    ).then(() => {
      this.setState({ updatingUserAdminId: null, updatingUserAdminState: null })
    })
  }

  render() {
    const { main } = this.props.redux
    const { users, tenantInfo } = this.props
    const { updatingUserAdminState, updatingUserAdminId } = this.state
    const tableRows = [];
    const tableHeaderRow = [];
    const allowedProps = ['username', 'admin'];

    if (users === [] || !users || users === null
      || typeof users === 'undefined') {
    } else {
      const colArray = [];
      const dataObjHeader = users[0];
      for (const prop in dataObjHeader) {
        if (dataObjHeader.hasOwnProperty(prop) && allowedProps.indexOf(prop) >= 0) {
          colArray.push(<th key={prop}>
            {prop === 'username' ? 'Members' : prop === 'admin' ? 'Role' : prop}
          </th>);
        }
      }
      if (this.props.isAdmin) {
        colArray.push(<th key="delete">Action</th>);
      }
      tableHeaderRow.push(<tr key="colArray">{colArray}</tr>);

      for (let ii = 0; ii < users.length; ++ii) {
        const user = users[ii];
        const dataArray = [];
        for (const prop in user) {
          if (user.hasOwnProperty(prop) && allowedProps.indexOf(prop) >= 0) {
            if (prop === 'admin') {
            } else {
              dataArray.push(<td key={prop + ii}>{user[prop]}</td>);
            }
          }
        }
        const isUpdatingAdmin = user.id === updatingUserAdminId
        dataArray.push(<td key="edit_" style={{
          paddingLeft: this.props.isAdmin ? "7%" : "9%",
        }}>
          <ReactTooltip place={"right"} className="tool-tip" />
          <div data-tip={!this.props.isAdmin ? "You don't have admin access to update role!" : null}>
            <Toggle
              className="custom-toggle-btn"
              checked={isUpdatingAdmin ? updatingUserAdminState : user.admin}
              icons={{
                checked: <span className="toggle-label toggle-label-admin">admin</span>,
                unchecked: <span className="toggle-label toggle-label-user">user</span>
              }}
              onChange={e => this.updateUserAdmin(e, user.id)}
              disabled={user.id === main.me.id || !this.props.isAdmin}
            />
          </div>
        </td>);
        if (this.props.isAdmin) {
          dataArray.push(<td key="delete_" style={{
            display: "flex",
            justifyContent: "center",
            justifyItems: 'center',
          }}><Button bsSize="small" bsStyle="primary"
            onClick={this.callDeleteUser.bind(null, user['id'])}
            disabled={user.id === main.me.id}>
              Remove</Button></td>);
        }
        tableRows.push(<tr key={ii + '_array'}>{dataArray}</tr>);
      }
    }
    if (this.props.isAdmin) {
      return (
        <div style={{ position: 'relative' }}>
          {this.state.userIdToBeDeleted ? <div className="overlay-100" /> : null}
          {tenantInfo && <div style={{ position: 'absolute', left: 0 }}>
            Org Name: <strong>{tenantInfo.Name}</strong>
          </div>}

          {tenantInfo && <div style={{ position: 'absolute', right: 0 }}>
            Org Key: <strong>{tenantInfo.Key}</strong>
          </div>}
          <br /><br />
          <div style={{
            display: "flex", justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "stretch",
            alignContent: "stretch",
          }}>
            <div>
              <h5><label htmlFor="manage">Manage Team</label></h5>
            </div>
            <div style={{}}>
              <Button bsSize="small" bsStyle="primary" style={{}}
                onClick={() => this.setState({ invitingUsers: true })}>
                Invite Members
              </Button>
            </div>
          </div>
          <Table bordered responsive>
            <thead>
              {tableHeaderRow}
            </thead>
            <tbody>{tableRows}</tbody>
          </Table>
          <br /><br />
          {this.state.userIdToBeDeleted ?
            <div style={dialogStyles}>
              <h4>
                Are you sure you want to remove this User?
              </h4>
              <hr />
              <div style={{ marginTop: '1rem' }}>
                <Button
                  bsStyle="primary"
                  onClick={() => this.setState({ userIdToBeDeleted: null })}
                  disabled={this.state.deletingUser}
                >
                  Cancel
                </Button>
                <Button bsStyle="danger"
                  style={{ marginLeft: '.5rem', float: 'right', width: 78 }}
                  onClick={() => this.actuallyDeleteUser()}
                  disabled={this.state.deletingUser}
                >
                  {!this.state.deletingUser ? 'Remove' :
                    <img alt="small-spinner" src={images.ajax_loader_small} height="8" />}
                </Button>
              </div>
            </div>
            : null}
          {this.state.invitingUsers && <TenantInviteModal {...this.props}
            onClose={() => this.setState({ invitingUsers: false })}
            tenantId={this.props.tenantId} users={users} />}
        </div>
      );
    }
    return (
      <div style={{ position: 'relative' }}>
        {tenantInfo && <div style={{ position: 'absolute', left: 0 }}>
          Org Name: <strong>{tenantInfo.Name}</strong>
        </div>}

        {tenantInfo && <div style={{ position: 'absolute', right: 0 }}>
          Org Key: <strong>{tenantInfo.Key}</strong>
        </div>}
        <br /><br />
        <Table bordered responsive>
          <thead>
            {tableHeaderRow}
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      </div>
    );
  }
}
