import head from "lodash/head";
import values from "lodash/values";
import get from "lodash/get";
import moment from "moment";
import { EVERY, HOURS, MINUTES } from "../data/constants";

export const toggleMultiple = (value) => {
    if(value instanceof Array) {
        return head(value)
  } else {
        return [value]
  }
};

export const toOptions = (_values) => {
  return _values.map(String).map((value) => ({
    value,
        label: value
    }))
};

export const toggleDateType = (value) => {
    return value === MINUTES ? HOURS : MINUTES
};

export const parseTimeValue = (value) => {
    if(value instanceof Array) {
        return value.map(parseTimeValue)
  }
  switch (value) {
        case '*':
            return '1';
    default:
      return value;
  }
};

export const isMultiple = (value) => value instanceof Array;

export const ensureMultiple = (value, multiple) => {
    if(multiple && !isMultiple(value)) {
        return toggleMultiple(value)
  }
    if(!multiple && isMultiple(value)) {
        return toggleMultiple(value)
  }
    return value
};

export const getValues = (value) => value.map((option) => option.value);

export const getValue = (value) => {
    return get(value, 'value') || value
};

export const generateCronExpression = (expression) => {
    return values(expression).join(' ')
};

export const splitMultiple = (value, field = undefined) => {
    if(value.includes(',')) {
        return value.split(',')
  }
    if(value.includes('/')) {
        return value
  }
    if(value.includes('-') && field === HOURS) {
        return value
  }
    if(value === EVERY) {
        return value
  }
    return value
};

export const replaceEvery = (value) => {
    return value
};

export const parseCronExpression = (expression) => {
    const [minutes, hours, dayOfMonth, month, dayOfWeek, year] = expression.split(' '); 
  const defaultExpression = {
    minutes: EVERY,
    hours: EVERY,
    dayOfMonth: EVERY,
    month: EVERY,
    dayOfWeek: EVERY,
        year: year
  };
  return Object.assign(defaultExpression, {
    minutes: replaceEvery(splitMultiple(minutes)),
    hours: replaceEvery(splitMultiple(hours, HOURS)),
    dayOfMonth: splitMultiple(dayOfMonth),
    month: splitMultiple(month),
    dayOfWeek: splitMultiple(dayOfWeek),
        year: splitMultiple(year)
    })
};

export const addLeadingZero = (el) => `0${el}`.slice(-2);

export const addLeadingZeroToOption = (option) => {
    const {value, label} = option;
  return {
    label: addLeadingZero(label),
        value
    }
};

export const defaultTo = (item, defaultItem) => {
    return (item === EVERY || !item) ? defaultItem : item
};

export const rangeHoursToSingle = (hours) => {
    if(hours instanceof Array) {
        return hours
  }
  return hours.split("-")[0];
};

// fetch start date and end date for scheduler based on active filter
export const dateRanges = (filter) => {
  const days = {
    today: [moment().startOf("day"), moment().endOf("day")],
    yesterday: [
      moment().subtract(1, "days").startOf("day"),
      moment().subtract(1, "days").endOf("day"),
    ],
    tomorrow: [
      moment().add(1, "days").startOf("day"),
      moment().add(1, "days").endOf("day"),
    ],
    thisWeek: [moment().startOf("week"), moment().endOf("week")],
    lastWeek: [
      moment().subtract(1, "week").startOf("week"),
      moment().subtract(1, "week").endOf("week"),
    ],
    nextWeek: [
      moment().add(1, "week").startOf("week"),
      moment().add(1, "week").endOf("week"),
    ],
    thisMonth: [moment().startOf("month"), moment().endOf("month")],
    lastMonth: [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
    nextMonth: [
      moment().add(1, "months").startOf("month"),
      moment().add(1, "months").endOf("month"),
    ],
  };

  const [start, end] = days[filter].map((date) => date.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z');
  return [start, end];
};
