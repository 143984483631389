import React, { Component, Fragment } from "react";

import CloseableTabs from "react-closeable-tabs";
import { Resizable } from "re-resizable";

const style = {
  position: "absolute",
  bottom: "0%",
  background: "white",
  border: "1px solid #dadce0",
};
const enable = {
  top: true,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};
export default class RecipeEditorTabs extends Component {
  render() {
    const { data, activeIndex, handleOnCloseTab, fullscreen, recipeAsJob } =
      this.props;
    return (
      data &&
      data.length > 0 && (
        <Resizable
          style={style}
          defaultSize={{
            width: "99.1%",
            height: "62%",
          }}
          enable={enable}
        >
          <CloseableTabs
            tabPanelColor="white"
            data={data}
            onCloseTab={handleOnCloseTab}
            activeIndex={activeIndex}
            tabPanelClass="tab-panel-class"
            tabContentClass="tab-content-class"
            mainClassName="main-lower-editor-pane"
          />
        </Resizable>
      )
    );
  }
}
