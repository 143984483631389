import head from "lodash/head";
import values from "lodash/values";
import get from "lodash/get";
import { MINUTES, HOURS, EVERY } from "../data/constants";
import moment from "moment";

export const toggleMultiple = (value) => {
  if (value instanceof Array) {
    return head(value);
  } else {
    return [value];
  }
};

export const toOptions = (_values) => {
  return _values.map(String).map((value) => ({
    value,
    label: value,
  }));
};

export const toggleDateType = (value) => {
  return value === MINUTES ? HOURS : MINUTES;
};

export const parseTimeValue = (value) => {
  if (value instanceof Array) {
    return value.map(parseTimeValue);
  }
  switch (value) {
    case "*":
      return "1";
    case "0":
      return "1";
    default:
      return value;
  }
};

export const isMultiple = (value) => value instanceof Array;

export const ensureMultiple = (value, multiple) => {
  if (multiple && !isMultiple(value)) {
    return toggleMultiple(value);
  }
  if (!multiple && isMultiple(value)) {
    return toggleMultiple(value);
  }
  return value;
};

export const getValues = (value) => value.map((option) => option.value);

export const getValue = (value) => {
  return get(value, "value") || value;
};

export const generateCronExpression = (expression) => {
  return values(expression).join(" ");
};

export const  splitMultiple = (value, field = undefined) => {
  const hoursRegex = /^(\d{1,2})-(\d{1,2})(?:(?:\/(?:(?!\1$)\d{1,2}))?,|$)/;
  const rangePattern = /^(?!0$)(\d+-(\d+\/\d+)?|\d+\/\d+|\d+-\d+)$/;

  if (rangePattern.test(value) && (field === HOURS || field === MINUTES)) {
    return convertUtcToLocalHours(value);
  }


  if (hoursRegex.test(value) && (field === HOURS || field === MINUTES)) {
    const expressions = value.split(",");

    const firstValues = [];
    const secondValues = [];

    expressions.forEach((expression) => {
      const [firstValue, secondValue] = expression.split("-");
      firstValues.push(firstValue);
      secondValues.push(secondValue);
    });

    const utcRange = `${firstValues[0]}-${secondValues[1]}`;
    return convertUtcToLocalHours(utcRange);
  }


  if (value.includes(",")) {
    return value.split(",");
  }
  if (value.includes("/")) {
    return value;
  }
  if (value.includes("-") && field === HOURS) {
    return value;
  }
  if (value === EVERY) {
    return value;
  }
  return [value];
};

const convertUtcToLocalHours = (utcTimeRange) => {
  const [timeRange, stepValue] = utcTimeRange.split("/");
  const [startHourStr, endHourStr] = timeRange.split("-");
  const startHour = parseInt(startHourStr, 10);
  const endHour = parseInt(endHourStr, 10);

  const localDate = new Date();

  localDate.setUTCHours(startHour); 
  const localStartHour = localDate.getHours();
  localDate.setUTCHours(endHour);
  const localEndHour = localDate.getHours();

  return stepValue
    ? `${localStartHour}-${localEndHour}/${stepValue}`
    : `${localStartHour}-${localEndHour}`;
};

export const replaceEvery = (value) => {
  if (typeof value === "string") {
    return value.replace("*/", "");
  }
  return value;
};

export const parseCronExpression = (expression) => {
  const [minutes, hours, dayOfMonth, month, dayOfWeek, year] =
    expression.split(" ");
  const defaultExpression = {
    minutes: EVERY,
    hours: EVERY,
    dayOfMonth: EVERY,
    month: EVERY,
    dayOfWeek: EVERY,
    year: EVERY,
  };

  if (!(minutes.includes("/") || hours.includes("/"))) {
    const { localHours, localMinutes } = convertUtcToLocaleTime(hours, minutes);

    return Object.assign(defaultExpression, {
      minutes: splitMultiple(localMinutes, MINUTES),
      hours: splitMultiple(localHours, HOURS),
      dayOfMonth: splitMultiple(dayOfMonth),
      month: splitMultiple(month),
      dayOfWeek: splitMultiple(dayOfWeek),
      year: year,
    });

  } else {
    return Object.assign(defaultExpression, {
      minutes: splitMultiple(minutes, MINUTES),
      hours: splitMultiple(hours, HOURS),
      dayOfMonth: splitMultiple(dayOfMonth),
      month: splitMultiple(month),
      dayOfWeek: splitMultiple(dayOfWeek),
      year: year,
    });
  }
};

const convertUtcToLocaleTime = (utcHours, utcMinutes) => {
  const localTimezone = moment.tz.guess();
  const utcTime = moment.utc().hours(utcHours).minutes(utcMinutes);
  const localTime = utcTime.tz(localTimezone);
  const localHours = localTime.hours().toString();
  const localMinutes = localTime.minutes().toString();
  return { localHours, localMinutes };
};

export const addLeadingZero = (el) => `0${el}`.slice(-2);

export const addLeadingZeroToOption = (option) => {
  const { value, label } = option;
  return {
    label: addLeadingZero(label),
    value,
  };
};

export const defaultTo = (item, defaultItem) => {
  return item === EVERY || !item ? defaultItem : item;
};

export const rangeHoursToSingle = (hours) => {
  if (hours instanceof Array) {
    return hours;
  }
  return hours.split("-")[0];
};
