import { PureComponent } from "react";
import pick from "lodash/pick";
import moment, { min } from "moment";
import { MINUTES, EVERY } from "../data/constants";
import { ensureMultiple, defaultTo } from "../utils";

const handleConvertToUTC = (hour, minute) => {
  const localDate = new Date();

  localDate.setHours(hour);
  localDate.setMinutes(minute);

  const calculatedUtcHour = localDate.getUTCHours();
  const calculatedUtcMinute = localDate.getUTCMinutes();

  return [calculatedUtcHour, calculatedUtcMinute];
};

function convertRangeToUTC(rangeHours) {
  const [startHourStr, endHourStr] = rangeHours.split("-");
  const startHour = parseInt(startHourStr, 10);
  const endHour = parseInt(endHourStr, 10);

  const localDate = new Date();

  localDate.setHours(startHour);

  const utcStartHour = localDate.getUTCHours();

  localDate.setHours(endHour);

  const utcEndHour = localDate.getUTCHours();

  return utcStartHour + "-" + utcEndHour;
}

export const checkTimeInput = (timeInput, hour = null) => {
  let expression;
  const [startTime, endTime] = timeInput.split("-").map(Number);

  if (startTime > endTime) {
    console.log("Invalid time input:", timeInput);

    if (hour) {
      expression = `${startTime}-23/${hour.slice(2)},0-${endTime}/${hour.slice(
        2
      )}`;
    } else {
      expression = `${startTime}-23,0-${endTime}`;
    }
  } else {
    if (hour) {
      expression = `${timeInput}/${hour.slice(2)}`;
    } else {
      expression = timeInput;
    }
  }
  return expression;
};

export const ensureEveryOn = (value, multiple) => {
  const process = (item) => {
    if (item === EVERY) {
      return item;
    }
    return item;
  };
  if (value instanceof Array) {
    return value.map(process);
  } else {
    return process(value);
  }
};

export function ensureValue(value) {
  if (Array.isArray(value) && !value.length) {
    return [EVERY];
  }
  if (!value) {
    return EVERY;
  }
  return value;
}

export function ensureArrayValue(value) {
  if (value.length) {
    return value;
  }
  return [EVERY];
}

export default class PresetTab extends PureComponent {
  constructor(props) {
    super(props);
    const { expression } = props;
    let { minutes, hours, dayOfMonth, month, year } = expression;
    let rangeHours = "9-17";
    let runFrequency = false;
    let elementCheck = false;
    let startAtCheck = false;
    let endAtCheck = false;
    let activeTab;
    let frequencyUnit = "hours";
    let recurringHours = !hours.includes("/") ? "1" : hours;
    let recurringMinutes = !minutes.includes("/") ? "5" : minutes;
    let fixedHours =
      hours.includes("/") || minutes.includes("/") ? moment().hour() : hours;
    let fixedMinutes =
      minutes.includes("/") || hours.includes("/")
        ? moment().minute()
        : minutes;
    let startingfixedMinutes;
    if (hours.includes("/") || minutes.includes("/")) {
      activeTab = "multiple";
      var minutesMultiple = false;
      if (typeof hours == "string") {
        if (hours.includes("-") && hours.includes("/")) {
          var [range, hour] = hours.split("/");
          recurringHours = "*/" + hour.toString();
          rangeHours = range.toString();
          runFrequency = true;
          elementCheck = true;
          if (minutes && minutes.length > 0) startingfixedMinutes = minutes[0];
        } else if (minutes.includes("*/")) {
          frequencyUnit = "minutes";
          if (hours.includes("-")) {
            rangeHours = hours;
            runFrequency = true;
            elementCheck = true;
          }
        }
      }
    } else if (year.includes("*")) {
      var minutesMultiple = true;
      activeTab = "singleAndMultiple";
    } else {
      var minutesMultiple = true;
      activeTab = "single";
    }
    const [hoursFrom, hoursTo] = rangeHours.split("-");
    this.state = {
      ...expression,
      hourUnit: "",
      activeTime: MINUTES,
      minutesMultiple,
      recurringMinutes,
      fixedMinutes,
      fixedHours,
      recurringHours,
      hoursFrom,
      hoursTo,
      frequencyUnit,
      runFrequency,
      rangeHours,
      elementCheck,
      activeTab,
      startingfixedMinutes,
      startDate:
        dayOfMonth.includes("*") || month.includes("*") || year.includes("*")
          ? new Date().toISOString().slice(0, 10)
          : new Date(new Date().setFullYear(year, month - 1, dayOfMonth))
              .toISOString()
              .slice(0, 10),
      selectedDate:
        dayOfMonth.includes("*") || month.includes("*") || year.includes("*")
          ? new Date()
          : new Date(new Date().setFullYear(year, month - 1, dayOfMonth)),
      dailySchedule: moment().hour(fixedHours).minute(fixedMinutes),
    };
  }

  changeFrequency = (value, generate) => {
    const unit = this.state.frequencyUnit;
    if (unit === "hours") {
      this.setState(
        {
          recurringHours: ensureValue(value),
        },
        generate
      );
    } else {
      this.setState(
        {
          recurringMinutes: ensureValue(value),
        },
        generate
      );
    }
  };

  changeFrequencyStartingMinute = (value, generate) => {
    const unit = this.state.frequencyUnit;
    this.setState(
      {
        startingfixedMinutes: value,
      },
      generate
    );
  };

  onPickTimeUnit = (e, v, generate) => {
    v = v.toLowerCase();

    const unit = v;
    if (unit === "hours") {
      this.setState(
        {
          recurringHours: ensureValue("*/1"),
          frequencyUnit: v,
        },
        generate
      );
    } else {
      this.setState(
        {
          recurringMinutes: ensureValue("*/5"),
          frequencyUnit: v,
        },
        generate
      );
    }
  };

  selectMinutes = (value, generate) => {
    if (value.length == 0) {
      this.setState(
        {
          fixedMinutes: "0",
        },
        generate
      );
    } else {
      this.setState(
        {
          fixedMinutes: ensureValue(value),
        },
        generate
      );
    }
  };

  selectHours = (value, generate) => {
    if (value.length == 0) {
      this.setState(
        {
          fixedHours: "9",
        },
        generate
      );
    } else {
      this.setState(
        {
          fixedHours: ensureValue(value),
        },
        generate
      );
    }
  };

  selectDayOfWeek = (value, generate) => {
    this.setState(
      {
        recurringDayOfWeek: ensureArrayValue(value),
      },
      generate
    );
  };

  selectDayOfMonth = (value, generate) => {
    this.setState(
      {
        recurringDayOfMonth: ensureArrayValue(value),
      },
      generate
    );
  };

  selectMonth = (value, generate) => {
    this.setState(
      {
        recurringMonth: ensureArrayValue(value),
      },
      generate
    );
  };

  changeDateType = (e, generate) => {
    if (this.state.activeTab == e) {
      return;
    }
    const { state } = this;
    const { activeTime } = state;
    const field = activeTime && activeTime.toLowerCase();
    const key = `${field}Multiple`;
    const value = !this.state[key];
    this.setState(
      {
        [key]: value,
        [field]: ensureMultiple(state[field], value),
        activeTab: e,
      },
      generate
    );
  };

  getExpression() {
    const { state } = this;
    const {
      recurringHours,
      rangeHours,
      fixedHours,
      minutesMultiple,
      fixedMinutes,
      startingfixedMinutes,
      recurringMinutes,
      activeTab,
    } = state;
    if (activeTab == "single") {
      const [utcHours, utcMinutes] = handleConvertToUTC(
        fixedHours,
        fixedMinutes
      );

      return {
        minutes: utcMinutes,
        hours: utcHours,
        ...pick(state, [
          "fixedDayOfMonth",
          "fixedMonth",
          "fixedDayOfWeek",
          "fixedYear",
        ]),
      };
    } else if (activeTab == "singleAndMultiple") {
      const [utcHours, utcMinutes] = handleConvertToUTC(
        fixedHours,
        fixedMinutes
      );

      return {
        minutes: utcMinutes,
        hours: utcHours,
        ...pick(state, [
          "recurringDayOfMonth",
          "recurringMonth",
          "recurringDayOfWeek",
        ]),
        year: "*",
      };
    } else {
      if (this.state.runFrequency) {
        // starting minutes
        if (this.state.frequencyUnit == "hours") {
          if (this.state.elementCheck || startingfixedMinutes > 0) {
            const utcRangeHours = convertRangeToUTC(rangeHours);
            const updatedRange = checkTimeInput(utcRangeHours, recurringHours);

            return {
              minutes: startingfixedMinutes || 0,
              hours: updatedRange,
              ...pick(state, [
                "recurringDayOfMonth",
                "recurringMonth",
                "recurringDayOfWeek",
              ]),
              year: "*",
            };
          }
          // timeframe
        } else if (this.state.frequencyUnit == "minutes") {
          if (this.state.elementCheck) {
            const utcRangeHours = convertRangeToUTC(rangeHours);
            const updatedRange = checkTimeInput(utcRangeHours);

            return {
              minutes: recurringMinutes,
              hours: updatedRange,
              ...pick(state, [
                "recurringDayOfMonth",
                "recurringMonth",
                "recurringDayOfWeek",
              ]),
              year: "*",
            };
          }
        }
      }
      //  no need to convert here to utc, because only handles minute part which can't not be converted to utc
      return {
        min: recurringMinutes,
        hours: recurringHours,
        ...pick(state, [
          "recurringDayOfMonth",
          "recurringMonth",
          "recurringDayOfWeek",
        ]),
        year: "*",
      };
    }
  }
}
