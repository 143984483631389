import React, { Component } from 'react';
import moment from 'moment'

export default class Comment extends Component {
  constructor() {
    super();
  }


  render(){
    const { comment, user, commentId, deleting, deleteComment, time, me} = this.props
 
    return(
      <div style={{background: '#fff', borderBottom: '1px solid #222', display: deleting === commentId ? 'none' : 'block'}}>
      <div style={{ 
      marginBottom:6, marginTop: 3, padding: 2, paddingLeft:2, paddingTop: 2, paddingRight: 2}}>
        <div style={{color: '#222', padding: 5}}>
          <div style={{display: 'inline-block', float: 'right'}}>
          <span style={{fontSize:'10px'}}>
              {moment(time).format('MMM Do, h:mm a')}
          </span>
      {me.username === user ?
        <svg style={{float: 'right', marginTop:-2, marginRight: -3}} height="18" viewBox="0 0 60 60" className="delete-cred-bar"
        onClick={()=>deleteComment(null,commentId)}>
              <path style={{strokeWidth:5, strokeLinecap: 'round'}}
                d="M 15.674663,15.572746 L 44.587629,44.485711 M 45.118838,15.420972 L 15.522889,45.016920" />
        </svg>  
        :
        null
      }

            
               
          </div>

      <div style={{fontSize: '15px', fontWeight: 'bold', display: 'inline-block'}}>
      <span>{user}</span>  
      </div>
      </div>
      <div style={{fontSize: '14px', width: '300px', marginLeft: 20, borderRadius: '5px', padding: '4px', margin: 2}}>
      {comment}
      </div>
      </div>
      </div>
      )
    }
}

