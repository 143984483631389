import CronParser from 'cron-parser';
import { formatCronString, prettyExpression } from '../../../components/utils/cron';
import cronsTrue from "cronstrue";
import { dateRanges } from '../../../utils';
import moment from "moment";
import 'moment-timezone';
import * as types from './types';
import { parseCronExpression } from '../../../components/CronBuilder/utils';
import {isValidCron} from "cron-validator";

export function callGetAllObjectTypes(tenantKey, appKey) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api.get(`${tenantKey}/${appKey}/objecttypes/all`, {
        endpoint: DATA_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_WEBHOOK_TYPES,
          data: data,
        });
      });
  };
}

export function callGetOwnedObjectTypes(tenantKey, appKey) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api.get(`${tenantKey}/${appKey}/objecttypes/owned`, {
        endpoint: DATA_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_WEBHOOK_TYPES,
          data: data,
        });
      });
  };
}

export function callGetTriggerSubscriptions(appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.get(`getalltriggers/${appId}`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_TRIGGERS,
          data: data,
        });
      });
  };
}

export function changeActiveFilter(key) {
  return (dispatch, getState, api) => {
    dispatch({
      type: types.SET_ACTIVE_FILTER,
      data: key,
    });
  };
}

export function callGetTriggerSummary(appId, tenantId, envId = null) {
  return (dispatch, getState, api) => {
    const timezone = moment.tz.guess();
    dispatch({
      type: types.START_SUMMARY_TRIGGERS,
    });

    let url = `tenants/${tenantId}/apps/${appId}/getTriggerSummary?timeZone=${timezone}${envId ? `&envId=${envId}` : ""}`;
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(url, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        const updatedArray = data.map((item) => {
          // additional calculation
          let days, status, frequency, recipe;

          if (item.externalExecutionId) {
            status = `/a/${appId}/health/details?executionGuid=${item.externalExecutionId}&env=${item.environmentId}`;
          }

          recipe = `/a/${appId}/tf/${item.baseId}`;

          // update the cron expression
          if (item.cronExpression) {
            let updatedCron = prettyExpression(item.cronExpression);
            const parsedCron = parseCronExpression(updatedCron);
            const cronObject = Object.values(parsedCron);
            const newCron = cronObject.join(' ');
            try {
              // retrieving days of schedules
              const isValid = isValidCron(updatedCron, { seconds: false });
              if (!isValid) {
                // Pre-process the cron expression
                updatedCron = updatedCron.replace(/0-0\/1/g, '0');
              }
              const interval = CronParser.parseExpression(updatedCron);
              const dayOfWeek = interval._fields.dayOfWeek;
              // removing day value which is greater than 6
              days = dayOfWeek.filter((day) => day < 7);

              // retriving frequency of schedules
              const cronDescription = cronsTrue.toString(newCron);
              frequency = formatCronString(cronDescription);
            } catch (error) {
              console.log("Error in parsing cron expression", error);
            }
          } else {
            frequency = item.frequency;
            days = [0, 1, 2, 3, 4, 5, 6];
          }

          const updatedItem = {
            ...item,
            days: days,
            status: status,
            frequency: frequency,
            recipe: recipe,
          };

          return updatedItem;
        });

        dispatch({
          type: types.SET_SUMMARY_TRIGGERS,
          data: updatedArray,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SET_SUMMARY_TRIGGERS,
          data: null,
        });
      });
  };
}

export function callGetActiveSchedules(appId, tenantId, envId = null) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    let url = `tenants/${tenantId}/apps/${appId}/getTriggerSummary${
      envId ? `?envId=${envId}` : ""
    }`;
    return api
      .get(url, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        const activeSchedules = data.filter((item) => !item.isSchedulesPaused);

        dispatch({
          type: types.SET_ACTIVE_TRIGGERS,
          data: activeSchedules.length,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SET_ACTIVE_TRIGGERS,
          data: 0,
        });
      });
  };
}

export function callGetTriggerDetails(appId, tenantId, envId = null, currentPage, pageSize) {
  return (dispatch, getState, api) => {
    const timezone = moment.tz.guess();
    const { events } = getState();
    const [start, end] = dateRanges(events.activeFilter);

    let url = `tenants/${tenantId}/apps/${appId}/getTriggerDetails?startDate=${start}&endDate=${end}${currentPage ? `&currentPage=${currentPage}` : ""}${pageSize ? `&pageSize=${pageSize}` : ""}${envId ? `&envId=${envId}` : ""}&timeZone=${timezone}`;

    dispatch({
      type: types.START_DETAILS_TRIGGERS,
    });
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(url, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        const { totalRecordCount, triggerDetails } = data;

        // additional calculation
        const updatedArray = triggerDetails.map((item) => {
          let status;

          if (item.externalExecutionId) {
            status = `/a/${appId}/health/details?executionGuid=${item.externalExecutionId}&env=${item.environmentId}`;
          }

          const updatedItem = {
            ...item,
            status: status,
          };

          return updatedItem;
        });

        dispatch({
          type: types.SET_DETAILS_TRIGGERS,
          totalDetailsTriggers: totalRecordCount,
          detailsTriggers: updatedArray,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SET_DETAILS_TRIGGERS,
          totalDetailsTriggers: 0,
          detailsTriggers: null,
        });
      });
  };
}

export function callGetTriggerDetailsWithoutFilter(appId, tenantId, envId, startDate, endDate) {
  return (dispatch, getState, api) => {
    const timezone = moment.tz.guess();

  let start = startDate ? startDate + "Z" : moment().startOf("week").format('YYYY-MM-DDTHH:mm:ss.SSS') + "Z";
  let end = endDate ? endDate + "Z" : moment().endOf("week").format('YYYY-MM-DDTHH:mm:ss.SSS') + "Z";

  let url = `tenants/${tenantId}/apps/${appId}/getTriggerDetails?startDate=${start}&endDate=${end}${envId ? `&envId=${envId}` : ""}&timeZone=${timezone}`;

    dispatch({
      type: types.START_DETAILS_TRIGGERS,
    });
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(url, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        const { totalRecordCount, triggerDetails } = data;

        // additional calculation
        const updatedArray = triggerDetails.map((item) => {
          let status;

          if (item.externalExecutionId) {
            status = `/a/${appId}/health/details?executionGuid=${item.externalExecutionId}&env=${item.environmentId}`;
          }

          const updatedItem = {
            ...item,
            status: status,
          };

          return updatedItem;
        });

        dispatch({
          type: types.SET_DETAILS_TRIGGERS,
          totalDetailsTriggers: totalRecordCount,
          detailsTriggers: updatedArray,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SET_DETAILS_TRIGGERS,
          totalDetailsTriggers: 0,
          detailsTriggers: null,
        });
      });
  };
}


export function callClearTriggerSubscription(transBaseId, envId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.get(`environments/${envId}/cleartrigger/${transBaseId}`, {
      endpoint: TRANS_API_URL,
    })
  };
}

// Create an event to be delivered by the webhook mechanism
export function createNewEvent(tenantKey, appKey, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api.post(`${tenantKey}/${appKey}/events`, {
      endpoint: DATA_API_URL,
      data: data
    })
  }
}

export function createObjectType(tenantKey, appKey, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api.post(`${tenantKey}/${appKey}/objecttypes`, {
        endpoint: DATA_API_URL,
      data: data
      })
      .then(() => {
        dispatch(callGetAllObjectTypes(tenantKey, appKey));
      });
  };
}

export function updateObjectType(tenantKey, appKey, data, name) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api.put(`${tenantKey}/${appKey}/objecttypes/${name}`, {
        endpoint: DATA_API_URL,
      data: data
      })
      .then(() => {
        dispatch(callGetAllObjectTypes(tenantKey, appKey));
      });
  };
}

export function deleteObjectType(tenantKey, appKey, name) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api.delete(`${tenantKey}/${appKey}/objecttypes/${name}`, {
        endpoint: DATA_API_URL,
      })
      .then(() => {
        dispatch(callGetAllObjectTypes(tenantKey, appKey));
      });
  };
}

export function callGetSubscriptionTimeseries(tenantKey, appKey, subscriptionGuid) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api.get(`${tenantKey}/${appKey}/webhooks/subscriptions/${subscriptionGuid}/timeseries`, {
          endpoint: DATA_API_URL,
    })
      .then((data) => {
        dispatch({
          type: types.SET_WEBHOOK_TIMESERIES,
          data: data,
        });
      });
  };
}


export function callCreateURLWebhookSubscription(tenantId, appId, subscriptionData) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    console.log(subscriptionData)
    return api.post(`${tenantId}/${appId}/webhooks/subscriptions`, {
        endpoint: TRANS_API_URL,
      data: subscriptionData
      })
      .then(() => {
      dispatch(callGetWebhookSubscriptions(tenantId, appId))
      });
  };
}

// export function callGetURLWebhookSubscriptions(tenantId, appId) {
//   return (dispatch, getState, api) => {
//     const { config } = getState();
//     const { TRANS_API_URL } = config;
//     return api.get(`${tenantId}/${appId}/webhooks/subscriptions`, {
//       endpoint: TRANS_API_URL,
//     })
//     .then((data) => {
//       dispatch({
//         type: types.SET_WEBHOOKURL_SUBS,
//         data: data
//       });
//     });
//   };
// }

export function callCreateWebhookSubscription(tenantId, appId, envId, baseId, subscriptionData) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.post(`${tenantId}/${appId}/webhooks/environments/${envId}/subscriptions/${baseId}`, {
          endpoint: TRANS_API_URL,
      data: subscriptionData
    })
      .then(() => {
      dispatch(callGetWebhookSubscriptions(tenantId, appId))
      });
  };
}

export function clearWebhookSubscriptions() {
  return (dispatch) => {
    dispatch({
      type: types.SET_WEBHOOK_SUBS,
      data: null,
        })
    dispatch({
      type: types.SET_WEBHOOKURL_SUBS,
      data: null,
        })
  }
}

export function callGetWebhookSubscriptions(tenantId, appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.get(`${tenantId}/${appId}/webhooks/subscriptions`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_WEBHOOK_SUBS,
        data: data
        });
      });
  };
}

export function callDeleteWebhookSubscription(tenantId, appId, subscriptionGuid) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.delete(`${tenantId}/${appId}/webhooks/subscriptions/${subscriptionGuid}`, {
          endpoint: TRANS_API_URL,
    })
      .then(() => {
      dispatch(callGetWebhookSubscriptions(tenantId, appId))
      });
  };
}

