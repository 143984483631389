import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SettingsMenu from "./settingsmenu";
import HeaderStyle from "../../styles/headerstyle";
import images from "../images";
import EnvironmentHeader from "./environmentHeader";

const editionStyle = {
  float: "right",
  marginRight: 40,
  marginTop: 19,
  fontSize: "12px",
  textTransform: "uppercase",
};

const Header = (props) => {
  const {
    userpic,
    username,
    logout,
    redux,
    actions,
    displayingTokenModal,
    publicPage,
  } = props;
  const { project, main, transformer } = redux;
  // HeaderStyle.userPicStyles.backgroundImage = `url(${userpic})`
  const edition =
    window.location &&
    window.location.pathname.split("/").includes("a") &&
    project &&
    project.tenantInfo &&
    project.tenantInfo.Level &&
    main &&
    main.accessLevels &&
    main.accessLevels.find((al) => al.level === project.tenantInfo.Level);

  const handleClick = () => {
    actions.setShowMenu(!props.redux.main.showMenu);
  };

  let headerColor = "#6C63FF";
  if (
    project &&
    project.tenantInfo &&
    project.tenantInfo.Level &&
    main &&
    main.accessLevels &&
    main.accessLevels.find((al) => al.level === project.tenantInfo.Level) &&
    project.appData &&
    project.appData.name &&
    project.tenantInfo &&
    window.location &&
    window.location.pathname.split("/").includes("a")
  ) {
    headerColor = main.accessLevels.find(
      (al) => al.level === project.tenantInfo.Level
    ).color;
  }

  //#2b552b
  //#2F4F4F
  //#1f3804

  const handleDropdownClick = (baseId, appId) => {
    this.props.history.push(`/a/${appId}/tf/${baseId}`);
  };

  let running = [];

  if (
    project &&
    project.transformers &&
    transformer &&
    transformer.executingTransformers.length > 0
  ) {
    transformer.executingTransformers.map((o, i) => {
      const deets = project && project.transformers.find((f) => f.BaseId === o);
      if (deets) {
        running.push(deets);
      }
    });
  }

  const topbarStyles = {
    position: "fixed",
    top: 0,
    backgroundColor: headerColor || "#6C63FF",
    height: 50,
    width: "100%",
    zIndex: "10",
    opacity: displayingTokenModal ? ".75" : "1",
    pointerEvents: displayingTokenModal ? "none" : "all",
  };

  const { me } = props.redux.main;
  const pathArray = props.location.pathname.split("/");
  const showLogo =
    props.location.pathname === "/" ||
    pathArray[1] === "register" ||
    pathArray[1] === "services" ||
    pathArray[1] === "datamapping" ||
    pathArray[1] === "swagger" ||
    pathArray[1] === "user";
  let lastElement = pathArray[pathArray.length - 1];
  const editorScreen =
    props.location.pathname.includes("tf") &&
    Number.isInteger(parseInt(lastElement));
  let appId;
  if (
    props.redux.project &&
    props.redux.project.appData &&
    props.redux.project.appData.Id
  )
    appId = props.redux.project.appData.Id;
  return (
    <div style={topbarStyles}>
      <header style={HeaderStyle.headerStyles}>
        {showLogo && (
          <Link to="/">
            <img
              src={images.logo}
              height="30"
              style={HeaderStyle.logoStyles}
              role="presentation"
            />
          </Link>
        )}
        {me && me.username && (
          <div style={HeaderStyle.accountStyles} key={0}>
            <div onClick={handleClick} className="user-pic">
              <div
                style={{
                  ...HeaderStyle.userPicStyles,
                  backgroundImage: `url(${userpic})`,
                }}
              >
                <img
                  alt="gear"
                  id="user-pic"
                  src={images.ic_settings_white_48dp}
                  height="24"
                  className="user-pic-gear-icon"
                />
              </div>
            </div>

            <div
              className="elevio-hook-for-sub"
              style={{
                position: "absolute",
                right: 30,
                top: 31,
                height: 5,
                width: 5,
              }}
            />
            <div style={HeaderStyle.usernameStyles}>{username}</div>
            {editorScreen && (
              <div style={HeaderStyle.envStyles}>
                <Link to={{ pathname: `/a/${appId}/env` }}>
                  <span
                    style={{
                      marginRight: 10,
                      fontWeight: "bold",
                      textDecoration: "underline",
                      color: "white",
                    }}
                  >
                    Environments
                  </span>
                </Link>

                <EnvironmentHeader {...props} />
              </div>
            )}

            {/*}
          {running.length > 0 &&  
          <div style={{float: 'right', position: 'relative', marginRight: 15,marginTop: 15}}>
          
          <DropdownButton
              bsStyle="primary"
              style={{height: 24, paddingTop:2, float: 'left'}}
              title={running.length}
              disabled={transformer.versions || !project.transformers ? true : false}
              noCaret
              pullRight
              id="dropdown-no-caret"
            >
            
            {
              running.map((o,i)=>{
              return(
              <MenuItem key={i} eventKey={o.BaseId} onClick={()=>handleDropdownClick(o.BaseId, o.appId)}>{o.Name}</MenuItem>
                    )
                  })
            }
            </DropdownButton>
          </div>
          }
        */}

            <SettingsMenu
              actions={actions}
              logout={logout}
              show={main.showMenu}
              identity={redux.main.me}              
            />
            {edition && edition.title && (
              <div style={editionStyle}>{edition.title}</div>
            )}
          </div>
        )}
      </header>
    </div>
  );
};

Header.propTypes = {
  userpic: PropTypes.string,
  username: PropTypes.string,
  logout: PropTypes.func,
};

export default Header;
