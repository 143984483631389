import React, { Component } from "react";

export default class CrossOriginVerification extends Component {
  componentWillMount() {
    const { config } = this.props.redux;

    let clientId = "4tzju8voTOAS3bynmUCLyWuiioIdyJpX";
    let domain = "lingk.auth0.com";

    const isIntegration = config.IS_INTEGRATION;

    if (isIntegration) {
      clientId = "Qwk1HiBiyKuJnuqyXakzJ8PFrJRxTEYj";
      domain = "auth.lingk.io"; //'lingk-int.auth0.com'
    }

    // const redirectUri = this.props.location.query.redirect_uri

    // console.log(redirectUri)

    const auth0 = new window.auth0.WebAuth({
      clientID: clientId,
      domain: domain
    });

    auth0.crossOriginVerification();

  }

  render() {
    return (
      <div></div>
    )
  }
}
