import React, { Component } from 'react';
import { Button } from 'react-bootstrap';


import ContentWsStyles from '../../styles/contentwsstyle';

export default class TransformerSettings extends Component {

  constructor() {
    super();
    this.state = {
      name:'',
      description:'',
      env:'',
      showDeleteDialog:false
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.deleteRecipe = this.deleteRecipe.bind(this);
    this.changeNameInput = this.changeNameInput.bind(this);
    this.changeDescriptionInput = this.changeDescriptionInput.bind(this);
    this.changeEnvInput = this.changeEnvInput.bind(this);
  }

  componentWillMount() {
    const { transformer } = this.props.redux
    this.setState({
      name: transformer.versions[0].Name,
      description: transformer.versions[0].Description,
      env: transformer.versions[0].Environ
    })
  }

  saveTransformer() {
    const { transformer } = this.props.redux
    const { appId, transId } = this.props.match.params
    this.props.actions.callUpdateTransformerInfo(
      {
        name: this.state.name,
        config: transformer.versions[0].Config,
        environ: this.state.env,
        version: transformer.versions[0].Version,
        baseid: transformer.versions[0].BaseId,
        id: transformer.versions[0].Id,
        description: this.state.description,
        languageVersion: transformer.versions[0].LanguageVersion || '2.0'
      },
      appId,
      transId
    )
  }

  handleSave() {
    this.saveTransformer()
  }

  handleDelete(){
    /*const { trigger } = this.props.redux.transformer
    if(trigger.IsFile || trigger.IsRecurring || trigger.IsWebhook){
      alert('A Recipe with an active Trigger cannot be deleted. Please clear Recipe Trigger before deleting')
      return;
    }*/
    this.setState({showDeleteDialog:true})
  }

  deleteRecipe(){
    const { appId, transId } = this.props.match.params
    this.props.actions.callDeleteTransformer(appId, transId)
    this.props.history.push(`/a/${appId}/tf`)
  }

  changeNameInput(e) {
    this.setState({name:e.target.value})
  }

  changeDescriptionInput(e) {
    this.setState({description:e.target.value})
  }

  changeEnvInput(e) {
    this.setState({env:e.target.value})
  }

  render() {
    const { transformer } = this.props.redux
    if (!transformer.versions[0]) {
      return <div />;
    }
    const styles = {
      close: {
        position: 'absolute',
        right: 10,
        cursor: 'pointer',
      },
      dialog: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: 300,
        height: 180,
        marginTop: -90,
        marginLeft: -150,
        boxSizing: 'border-box',
        borderRadius: 3,
        padding: '2rem',
        backgroundColor: '#fff',
        display: 'block',
        boxShadow: '0 1px 15px 0 rgba(0,0,0,.1),0 0 0 0 transparent',
        zIndex: 2000,
        border: '1px solid #c9d7df',
      },
      scrollingDiv: {
        overflow: 'auto',
        height: 300,
      },
    };
    return (
      <div className="main-tab-content" style={{paddingBottom:50}}>
        {/*this.state.showDeleteDialog ? <div className="overlay-100" />: null */}
        <br />
        <div style={ContentWsStyles.fullWrapper}>
          <div>
            <label htmlFor="name">Recipe Name*</label><br />
            <input className="form-control" type="text" onChange={this.changeNameInput}
               value={this.state.name} maxLength="50" />
          </div>
          <br />
          <div>
            <label htmlFor="env">Tag</label><br />
            <input className="form-control" type="text" onChange={this.changeEnvInput}
                 value={this.state.env} maxLength="15" />
          </div>
          <br />
          <div>
            <label htmlFor="description">Description</label><br />
            <textarea className="form-control" type="text" onChange={this.changeDescriptionInput}
                 value={this.state.description} style={{width:500, height:200}}/>
          </div>
          <br />
          <Button bsSize="small" bsStyle="primary" onClick={this.handleSave}
            style={{display:'inline-block'}}>
              Save
          </Button>
          { transformer.showSaved ? <div style={{display:'inline-block',marginLeft:12}}>Recipe Updated</div> : null }

{/*}
          <Button bsSize="small" bsStyle="danger" onClick={this.handleDelete} style={{marginLeft: transformer.showSaved ? 40 : 154, width:97}}>
              Delete Recipe
          </Button>
        </div>
        {this.state.showDeleteDialog ?
          <div style={styles.dialog}>
            <h4>
              Are you sure you want to delete this Recipe?
            </h4>
            <hr />
            <div style={{ marginTop: '1rem' }}>
              <Button
                bsStyle="primary"
                onClick={() => this.setState({showDeleteDialog: false})}
              >
                Cancel
              </Button>
              <Button bsStyle="danger"
                style={{ marginLeft: '.5rem', float:'right' }}
                onClick={this.deleteRecipe}
              >
                Delete
              </Button>
            </div>
          </div>
        : null}

      */}
      </div>
      </div>
    );
  }
}
