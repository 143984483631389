import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as projectActions from "../shared/redux/actions/projectActions";
import * as mainActions from "../shared/redux/actions/mainActions";
import * as transformerActions from "../shared/redux/actions/transformerActions";
import * as datafeedActions from "../shared/redux/actions/datafeedActions";
import * as wizardActions from "../shared/redux/actions/wizardActions";
import * as environmentActions from "../shared/redux/actions/environmentActions";
import * as eventsActions from "../shared/redux/actions/eventsActions";
import Account from "../pages/account";
import images from "../components/images";

//import AccountSettings     from '../src/components/account/accounttabs';
//import ApiHubDetail        from '../src/components/account/apihubdetail';

import Environments from "../components/environments/environments";
import EditProperties from "../components/account/editproperties";
import RecipeTabParent from "../components/recipe/recipetabParent";

//import Environments        from '../src/components/account/environments';
//--------------------------------
// Recipe Components
//--------------------------------
import Transformers from "../components/recipe/transformers";
import NewTransformer from "../components/recipe/newtransformer";
import NewTransformerIntg from "../components/recipe/newTransfomerIntg";

import ChooseRecipePath from "../components/recipe/chooseRecipePath";
import StepExtendedProperties from "../components/recipe/executions/extendedProperties";
import FailedResult from "../components/recipe/executions/extendedPropsFailedResult";
import SucceededResult from "../components/recipe/executions/extendedPropsSuccessResult";

// Data Sync Components
//--------------------------------
import NewDataFeed from "../components/datasync/newdatafeed";
//--------------------------------
// Wizard Components
//--------------------------------
import WizardList from "../components/wizard/wizardList";
import WizardFormWrapper from "../components/wizard/wizardFormWrapper";

import NewWizard from "../components/wizard/newWizard";
import Calendar from "../components/events/calendar";

import MapperList from "../components/mapper/mapperList";
import Mapper from "../components/mapper/mapper";
//--------------------------------
// Connected Experience Components
//--------------------------------
import OverviewTabsParent from "../components/account/overviewtabsParent";
// import RecipeTabParent from "../components/recipe/recipetabParent";

import EventsHomeParent from "../components/events/eventsHomeParent";
import ConnectedAnalyticsParent from "../components/analytics/connectedanalyticsParent";
import WizardTabParent from "../components/wizard/wizardParent";

import MonitorTransformer from "../components/recipe/monitorTransformerDevExp";
import MonitorTransformerDetails from "../components/recipe/monitorTransPopUp";
import GenericNotFound from "../components/pageNotFound";
class AccountParent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasAccess: true,
    };
  }
  componentDidMount() {
    const { redux, match } = this.props;
    const { params } = match;
    const { appId } = params;
    const { main } = redux;
    const { appList } = main;
    let appIdInt = parseInt(appId, 10);
    let targetApp;
    if (appList && appList.length > 0) {
      targetApp = appList.find((a) => a.Id == appIdInt);
      if (!targetApp) {
        this.setState({ hasAccess: false });
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { redux, match } = this.props;
    const { params } = match;
    const { appId } = params;
    const { main } = redux;
    const { appList } = main;
    let appIdInt = parseInt(appId, 10);
    if (prevProps.redux.main.appList != appList) {
      let targetApp;
      if (appList && appList.length > 0) {
        targetApp = appList.find((a) => a.Id == appIdInt);
        if (!targetApp) {
          this.setState({ hasAccess: false });
        } else {
          this.setState({ hasAccess: true });
        }
      }
    }
  }
  render() {
    const { hasAccess } = this.state;
    return (
      <div>
        {hasAccess ? (
          <Switch>
            <Route
              path="/a/:appId/health/sfjobs"
              exact
              render={(routerProps) => (
                <StepExtendedProperties
                  {...routerProps}
                  redux={this.props.redux}
                  actions={this.props.actions}
                />
              )}
            />
            <Route
              path="/a/:appId/health/sfjobs/successful"
              render={(routerProps) => (
                <SucceededResult
                  {...routerProps}
                  redux={this.props.redux}
                  actions={this.props.actions}
                />
              )}
            />
            <Route
              path="/a/:appId/health/sfjobs/failed"
              render={(routerProps) => (
                <FailedResult
                  {...routerProps}
                  redux={this.props.redux}
                  actions={this.props.actions}
                />
              )}
            />
            <Account {...this.props}>
              <Switch>
                <Route
                  path="/a/:appId/dash"
                  render={(routerProps) => (
                    <OverviewTabsParent
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />
                <Route
                  name="environments"
                  path="/a/:appId/env"
                  render={() => <Environments {...this.props} />}
                />
                <Route
                  name="environments"
                  path="/a/:appId/env/:envId"
                  render={() => <Environments {...this.props} />}
                />
                <Route
                  name="database"
                  path="/a/:appId/db/new"
                  render={() => <NewDataFeed {...this.props} />}
                />
                <Route
                  name="transformers"
                  path="/a/:appId/tf"
                  exact
                  render={(routerProps) => (
                    <Transformers
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />
                <Route
                  path="/a/:appId/health/details"
                  exact
                  render={(routerProps) => (
                    <MonitorTransformerDetails
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />
                <Route
                  path="/a/:appId/health"
                  exact
                  render={(routerProps) => (
                    <MonitorTransformer
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />
                <Route
                  path="/a/:appId/health/:externalExecutionId"
                  exact
                  render={(routerProps) => (
                    <MonitorTransformer
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />

                <Route
                  name="choosepath"
                  path="/a/:appId/tf/path"
                  render={(routerProps) => (
                    <ChooseRecipePath
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />

                <Route
                  name="transformers"
                  path="/a/:appId/tf/new"
                  render={() => <NewTransformer {...this.props} />}
                />
                <Route
                  name="transformers"
                  path="/a/:appId/tf/integrations"
                  render={() => <NewTransformerIntg {...this.props} />}
                />
                <Route
                  name="recipe"
                  path={`${this.props.match.path}/tf/:transId`}
                  render={(routerProps) => (
                    <RecipeTabParent
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />

                <Route
                  name="wizards"
                  exact
                  path="/a/:appId/wiz"
                  render={(routerProps) => (
                    <WizardList
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />
                <Route
                  name="wizard"
                  path="/a/:appId/wiz/new"
                  render={(routerProps) => (
                    <NewWizard
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />
                <Route
                  name="wizard"
                  path="/a/:appId/wiz/start/:wizardID"
                  render={(routerProps) => (
                    <WizardFormWrapper
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />
                <Route
                  name="recipe"
                  path="/a/:appId/wiz/:transId"
                  render={(routerProps) => (
                    <WizardTabParent
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />
                <Route
                  name="connectedexperience"
                  path="/a/:appId/ca"
                  render={(routerProps) => (
                    <ConnectedAnalyticsParent
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />

                <Route
                  name="events"
                  path="/a/:appId/events"
                  render={(routerProps) => (
                    <EventsHomeParent
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />
                <Route
                  name="calendar"
                  path="/a/:appId/schedule"
                  render={() => <Calendar {...this.props} />}
                />

                <Route
                  name="mappings"
                  exact
                  path="/a/:appId/map"
                  render={(routerProps) => (
                    <MapperList
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />
                <Route
                  name="mappings"
                  path="/a/:appId/map/:mapperId"
                  render={(routerProps) => (
                    <Mapper
                      {...routerProps}
                      redux={this.props.redux}
                      actions={this.props.actions}
                    />
                  )}
                />
              </Switch>
            </Account>
          </Switch>
        ) : (
          <GenericNotFound />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountParent);
