import * as types from '../actions/types';

const initialState = {
  feedList: [],
  otherTenants: {},
  otherApps:{},
  showSaved: false,
  invitedFeed: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_FEEDLIST:
      return {
        ...state,
        feedList: action.data,
      };
    case types.SET_OTHER_TENANT:
      let otherTenants = { ...action.otherTenants };
      otherTenants[action.tenantId] = action.data[0];
      return {
        ...state,
        otherTenants,
      };
    case types.SET_OTHER_APP:
      let otherApps = { ...action.otherApps };
      otherApps[action.appId] = action.data;
      return {
        ...state,
        otherApps,
      };
    case types.SET_DATAFEED_SHOWSAVED:
      return {
        ...state,
        showSaved: action.bool,
      };
    case types.SET_INVITED_FEED:
      return {
        ...state,
        invitedFeed: action.data,
      };
    case types.CLEAR_DATAFEEDS:
      return {
        ...initialState,
      };
    default: return state;
  }
};
