const BoxAppStyles = {
  projectStyles: {
    height: '100%',
    width: 150,
    float: 'left',
    marginRight: 20,
    display: 'block',
  },
  projectAppStyles: {
    marginBottom: 4,
    height: '100%',
    width: '100%',
    float: 'left',
    marginRight: 10,
    display: 'block',
    paddingRight: 8,
    paddingBottom: 5,
  },
  executionPlanBoxStyles: {
    marginBottom: 0,
    height: '100%',
    width: '100%',
    float: 'left',
    marginRight: 10,
    display: 'block',
    paddingRight: 8,
    paddingBottom: 5,
  },
  executionPlanContentStyles: {
    marginBottom: 1,
    position: 'relative',
    float: 'left',
    backgroundColor: '#EFEFEF',
    marginRight: 10,
    overflow: 'hidden',
    fontSize: 14,
    display: 'block',
    width: '100%',
    paddingTop: 6,
    paddingRight: 8,
    paddingLeft: 8,
    paddingBottom: 5,
  },
  boxStyles: {
    marginBottom: 15,
    position: 'relative',
    float: 'left',
    backgroundColor: 'white',
    marginRight: 15,
    overflow: 'visible',
    fontSize: 14,
    height: 150,
    display: 'block',
    paddingTop: '6px',
    width: 150,
  },
  boxAppStyles: {
    marginBottom: 4,
    position: 'relative',
    float: 'left',
    backgroundColor: 'white',
    marginRight: 10,
    overflow: 'hidden',
    fontSize: 14,
    display: 'block',
    width: '100%',
    paddingTop: 6,
    paddingRight: 8,
    paddingLeft: 8,
    paddingBottom: 5,
    WebkitBoxShadow: '2px 2px 6px 0px rgba(94,94,94,0.5)',
    MozBoxShadow: '2px 2px 6px 0px rgba(94,94,94,0.5)',
    boxShadow: '2px 2px 6px 0px rgba(94,94,94,0.5)',
  },
  boxAppStyles2: {
    marginBottom: 4,
    position: 'relative',
    float: 'left',
    backgroundColor: 'white',
    marginRight: 10,
    overflow: 'hidden',
    fontSize: 14,
    display: 'block',
    width: '100%',
    paddingTop: 6,
    paddingRight: 8,
    paddingLeft: 8,
    paddingBottom: 5,
  },
  boxProjectHelpStyles: {
    marginTop: 10,
    position: 'relative',
    float: 'left',
    backgroundColor: 'white',
    marginRight: 10,
    overflow: 'hidden',
    fontSize: 14,
    height: 280,
    display: 'block',
    paddingTop: '5px',
    width: '100%',
  },
  iconStyles: {
    width: 19,
    height: 19,
    verticalAlign: 'top',
  },

  apiNameStyles: {
    paddingBottom: 6,
    color: 'black',
    width: 150,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  appNameStyles: {
    width: '70%',
    float: 'left',
  },
  datafeedNameStyles: {
    color: 'black',
    width: 175,
    textAlign: 'left',
    marginLeft: 10,
  },
  tenantNameStyles: {
    color: 'black',
    width: 175,
    textAlign: 'left',
    marginLeft: 10,
    fontSize: 11,
    paddingBottom: 10,
  },
  appManageStyles: {
    backgroundColor: '#dfe0e0',
    overflow: 'visible',
    fontSize: 14,
    height: 25,
    border: '0px',
    width: 175,
    paddingTop: 5,
    position: 'absolute',
    left: 0,
    bottom: 0,
    textAlign: 'center',
  },
  resourceNamesStyles: {
    overflow: 'visible',
    fontSize: 12,
    border: '0px',
    width: 175,
    left: 0,
    bottom: 90,
    textAlign: 'left',
    marginLeft: 10,
  },
  appConnectionStyles: {
    overflow: 'visible',
    fontSize: 12,
    height: 25,
    border: '0px',
    paddingTop: 5,
    position: 'absolute',
    left: 0,
    bottom: 90,
    width: 150,
    textAlign: 'center',
  },
  appLastModifiedStyles: {
    overflow: 'hidden',
    border: '0px',
    paddingBottom: 6,
    paddingRight: 6,
    color: '#404040',
    fontSize: 12,
    textAlign: 'right',
    float: 'right',
    width: '100%',
  },
  appResourceStyles: {
    overflow: 'visible',
    fontSize: 12,
    height: 25,
    border: '0px',
    paddingTop: 5,
    position: 'absolute',
    left: 0,
    bottom: 60,
    width: 175,
    textAlign: 'center',
  },
  connectedAppBoxStyles: {
    width: '70%',
    overflow: 'hidden',
    float: 'left',
  },
  connectedAppBoxLeftStyles: {
    width: 900,
    float: 'left',
    color: 'black',
    fontSize: 12,
  },
  connectedAppBoxRightStyles: {
    float: 'right',
    paddingTop: 3,
  },
  smallnumber: {
    color: '#aaaaaa',
    fontSize: 11,
  },
  category: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 1,
    paddingBottom: 1,
    backgroundColor: '#dddddd',
    fontSize: 12,
  },
  feedTenantStyles: {
    width: '80%',
    float: 'left',
    marginBottom: '3px',
  },
  feedNameStyles: {
    paddingTop: 6,
    textDecoration: 'underline',
  },
  linkStyles: {
    textDecoration: 'underline',
  },
  scoreCircle: {
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    background: 'blue',
    marginLeft: '3px',
    marginRight: '3px',
    marginTop: '3px',
  },
  descriptionStyles: {
    width: '100%',
    fontSize: '12px',
    marginTop: '3px',
  },
  connectedAppBoxButtonContainerStyles: {
    float: 'right',
    paddingTop: '3px',
    clear: 'right',
  },
  TagContainerStyles: {
    float: 'left',
    fontSize: 12,
    marginTop: 3,
    color: '#404040',
  },
  connectedAppBoxScoreStyle: {
    display: 'flex',
    width: '70%',
    clear: 'left',
    color: '#404040',
    fontSize: 12,
    marginTop: 3,
  },
  feedFromStyles: {
    width: 200,
    float: 'left',
  },
  color: {
    backgroundColor: '#777777',
  },
  environ: {
    backgroundColor: '#80E0C0',
    padding: '2px 4px',
  },
};

export default BoxAppStyles;
