export const head = {
  titleTemplate: 'Lingk - %s',
  meta: [
    { name: 'description', content: 'A ' },
    { charset: 'utf-8' },
    { property: 'og:site_name', content: 'dataProtect' },
    { property: 'og:image', content: '/logo.jpg' },
    { property: 'og:locale', content: 'en_US' },
    { property: 'og:title', content: 'dataProtect' },
    { property: 'og:description', content: 'dataProtect' },
    { property: 'og:card', content: 'summary' },
    { property: 'og:site', content: '@dataProtect' },
    { property: 'og:creator', content: '@dataProtect' },
    { property: 'og:image:width', content: '200' },
    { property: 'og:image:height', content: '200' },
  ],
};
