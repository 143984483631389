import React from "react";
import {Button} from "react-bootstrap";
import {findNotificationFrequency} from "./utils";
import UsageDoughnutChart from "./usageDoughnutChart";

const CardMonthly = ({
  data = [],
  setType,
  setShowHistoryModal = null,
  setEditModal = null,
  notificationFrequency,
}) => {
  // handlers

  const name = "Monthly";

  const {
    months,
    notificationEmails,
  } = data;

  const {
    month,
    dataProcHours,
    usedDataProcHours,
    usedDataProcHoursPercentage,
    availableDataProcHours,
    availableDataProcHoursPercentage,
    threshold,
    thresholdPercentage,
    thresholdValueHours,
    thresholdRemainingHours,
    notificationFrequencyId,
  } = months && months.length > 0 && months[months.length - 1];

  return (
    <div onClick={() => setType(name)}>
      <div className="dph-card">
          <span className="dph-card__primary-text dph-card__primary-text__fieldset">
            <strong>Monthly Summary</strong>
          </span>
        {/* information row */}
        <div className="dph-card__info-row">
          <div className="dph-card__secondary-text">
            <span><strong>🗓️ Month: </strong>{`(${month})`}</span>
            <br/>
            <br/>
            <div style={{width: '300px'}}>
              <UsageDoughnutChart dataProcHours={dataProcHours}
                                  usedDataProcHours={usedDataProcHours}
                                  usedDataProcHoursPercentage={usedDataProcHoursPercentage}
                                  availableDataProcHours={availableDataProcHours}
                                  availableDataProcHoursPercentage={availableDataProcHoursPercentage}
                                  threshold={threshold}
                                  thresholdPercentage={thresholdPercentage}
                                  thresholdValueHours={thresholdValueHours}
                                  thresholdRemainingHours={thresholdRemainingHours}/>
            </div>
             <div style={{
               fontSize: "15px",
               justifyContent: "center",
               display: "flex",
               alignItems: "center",
             }}>
              {/* history */}
               {name === "Monthly" ? (
                   <Button
                       onClick={() => setShowHistoryModal(true)}
                       bsStyle="link"
                   >
                     <strong>Show History</strong>
                   </Button>
               ) : null}
            </div>
          </div>
          <fieldset style={{
            "border": "1px solid gray",
            marginBottom: "1em",
            minWidth: "410px",
            padding: "1em",
            borderColor: "darkgray",
            borderRadius: "5px",
            borderRight: "1px solid #666",
          }}>
            <legend style={{borderBottom: "none",fontSize:"18px", width:"25%"}}>Notifications</legend>
            <span className="dph-card__secondary-text">
              Threshold set at
              <strong> {thresholdPercentage}%</strong> of{" "}
              <strong> {dataProcHours}</strong> hours at {" "}
            </span>
            <span className="dph-card__label">
              {findNotificationFrequency(notificationFrequency, notificationFrequencyId)}
            </span>
            <br/>
            <br/>
            <label>Emails</label>
            {/* emails */}
            <div className="dph-card-email_container">
              {notificationEmails &&
                  notificationEmails.map((email, index) => (
                      <span key={index} className="dph-card__email">
                {email}
              </span>
                  ))}
            </div>
            {/* edit */}
            <Button
                onClick={() => setEditModal(true)}
                bsStyle="primary"
            >
              Edit
            </Button>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default CardMonthly;
