import React, { Component } from 'react';
import { Button } from 'react-bootstrap'
import MapperBox from './mapperBox'



export default class MapperList extends Component {

  constructor() {
    super();
    this.state = { 
      selectedEnv: null,
    };
  }

  render() {
    const {redux, match} = this.props
    const {params} = match
    const {project} = redux
    return (
 
      <div className="parent-env">
      <div className="main-tab-content" 
      style={{paddingTop:65,paddingRight:12,width:'100%',height:'100%'}}>


      <div>
        <div
          style={{
            margin: '0px 0px 15px 0px',
            fontSize: '20px',
            display: 'inline-block'
          }}>
          Data Mappings
        </div>


        {!project.dataMappings || 
        (project.dataMappings && project.dataMappings.length===0) ? 
        <div
        className="none-env" style={{marginTop:4}}>
        <span style={{ fontSize: 16 }}>No Data Mappings.</span>
        <br /> 
        <Button
          bsSize="small"
          bsStyle="primary"
          style={{ marginTop: 6 }}
          onClick={() => {
            this.props.history.push(`/a/${params.appId}/map/0`)
          }}>
          New Mapping
        </Button>
      </div> :
        <div className="main-create-btn-env">
        <Button bsSize="small" bsStyle="primary"
        style={{ marginTop: 0 }}
        onClick={() => {this.props.history.push(`/a/${params.appId}/map/0`)
          }}>
          New Mapping
        </Button>
        </div>
      }

      </div>
      
      {project.dataMappings && project.dataMappings.map((m,i)=>{
        return <MapperBox key={i} mapper={m} appId={params.appId} history={this.props.history}/>
      })}

    </div>
      
    </div>)
  }
}

