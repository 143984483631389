import React, { Component } from "react";
import MetadataDropdown from "@lingk/sync/build/metadataDropdown";
import * as inputs from "@lingk/sync/build/lingk";
import * as util from "@lingk/sync/build/metadataFunctions";
import { DropdownButton, MenuItem } from "react-bootstrap";
import images from "../images";
import clipboard from "../utils/clipboard";

class MetadataMenu extends Component {
  constructor() {
    super();
    this.state = {
      selectedProvider: null,
      selectedObject: null,
      selectedField: null,
    };
  }

  /*componentDidMount() {
    //document.addEventListener("contextmenu", this._handleContextMenu);
    document.addEventListener("click", this._handleClick);
    document.addEventListener("scroll", this._handleScroll);
  }

  componentWillUnmount() {
    //document.removeEventListener("contextmenu", this._handleContextMenu);
    document.removeEventListener("click", this._handleClick);
    document.removeEventListener("scroll", this._handleScroll);
  }*/

  /*_handleContextMenu = event => {
    event.preventDefault();

    this.setState({ visible: true });

    const clickX = event.clientX;
    const clickY = event.clientY;
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const rootW = this.root.offsetWidth;
    const rootH = this.root.offsetHeight;

    const right = screenW - clickX > rootW;
    const left = !right;
    const top = screenH - clickY > rootH;
    const bottom = !top;

    if (right) {
      this.root.style.left = `${clickX + 5}px`;
    }
    if (left) {
      this.root.style.left = `${clickX - rootW - 5}px`;
    }
    if (top) {
      this.root.style.top = `${clickY + 5}px`;
    }
    if (bottom) {
      this.root.style.top = `${clickY - rootH - 5}px`;
    }
  };*/

  /*_handleClick = event => {
    const { visible } = this.props;
    const wasOutside = this.root && !this.root.contains(event.target)
    //const wasOutside = !(event.path.includes === this.root);
    if (wasOutside && visible) this.props.hide();
  };

  _handleScroll = () => {
    const { visible } = this.props;
    if (visible) this.props.hide();
  };*/

  componentWillReceiveProps(newProps) {
    if (newProps.envCreds === null) {
      this.setState({
        selectedProvider: null,
        selectedProviderLabel: null,
        checkResult: null,
        metadata: null,
        checkError: null,
        selectedObject: null,
        selectedField: null,
      });
    }
  }

  getMetadata = (creds) => {
    this.setState({
      selectedProvider: creds.providerLabel,
      checking: true,
      checkResult: "",
      metadata: null,
      checkError: null,
      selectedObject: null,
      selectedField: null,
    });
    const { actions, redux } = this.props;
    const { project, main } = redux;
    const providerConfig = main.providers.find((p) => {
      return p.credentialType === creds.credentialType;
    });

    this.setState({
      selectedProviderLabel: providerConfig.label || providerConfig.type,
    });
    actions
      .callGetMetadataForWizardRun(
        project.tenantInfo.TenantId,
        project.appData.Id,
        providerConfig,
        creds,
        this.props.selectedEnvironment
      )
      .then((meta) => {
        const metadata = util.metadataFunctions(meta, providerConfig.type);
        this.setState({
          metadata: metadata,
          checking: false,
          checkResult: "200 OK",
        });
      })
      .catch((err) => {
        this.setState({
          checking: false,
          checkResult: "404 Error",
          metadata: null,
        });
        if (err.response && err.response.data) {
          this.setState({ checkError: err.response.data });
        }
      });
  };

  render() {
    const { envCreds, redux, onConnections, selectedEnvironment } = this.props;

    const {
      selectedProviderLabel,
      checking,
      checkResult,
      metadata,
      selectedObject,
      selectedField,
    } = this.state;
    const { main } = redux;

    const providers = envCreds ? [...envCreds] : [];
    providers.sort((a, b) => {
      if (a.providerLabel < b.providerLabel) return -1;
      if (a.providerLabel > b.providerLabel) return 1;
      return 0;
    });

    return (
      <div ref={this.props.innerRef} style={{ height: "34vh" }}>
        {selectedEnvironment && !onConnections && (
          <div
            style={{
              ...styles.envBox,
              background: selectedEnvironment.color,
              color: getColorByBgColor(selectedEnvironment.color),
            }}
          >
            {selectedEnvironment.name}
          </div>
        )}
        <div style={onConnections ? styles.bodyOnConnections : styles.body}>
          <div style={styles.item}>
            <div style={{ display: "inline-block" }}>
              <strong className="labelz-small">Connectors</strong>
              <br />
              <div>
                <DropdownButton
                  id="connectors"
                  placeholder="Connectors"
                  title={selectedProviderLabel || "Connectors"}
                  bsStyle="default"
                  bsSize="small"
                  // dropup
                  style={{ minWidth: 140 }}
                  className="creds-dropdown"
                  disabled={!(providers && providers.length)}
                >
                  {providers &&
                    providers.map((c, i) => {
                      let p = main.providers.find(
                        (pr) => pr.type === c.providerLabel
                      );
                      if (c.providerLabel === "Custom") {
                        p = { label: "Custom" };
                      }
                      return p ? (
                        <MenuItem
                          value={c.providerLabel}
                          key={i}
                          onClick={() => this.getMetadata(c)}
                        >
                          {p.label || p.type}
                        </MenuItem>
                      ) : (
                        <span key={i} />
                      );
                    })}
                </DropdownButton>
                <EnvCheckIcons
                  checking={checking}
                  result={checkResult}
                  style={{ marginLeft: 12 }}
                />
              </div>
            </div>
            {selectedProviderLabel && metadata && (
              <div style={styles.card}>
                <p style={{ textDecoration: "underline" }}>
                  {selectedProviderLabel} Connector
                </p>
                <p style={styles.p}>{metadata && metadata.length} Objects</p>
              </div>
            )}
            {selectedProviderLabel && metadata && <Forward />}
          </div>
          <div style={styles.item}>
            <div style={{ display: "inline-block" }}>
              <strong className="labelz-small">Objects</strong>
              <br />
              <div>
                <MetadataDropdown
                  inputs={inputs}
                  type="neutral"
                  style={styles.metadataDropdown}
                  minWidth={260}
                  style={{
                    width: "auto",
                    maxWidth: "initial",
                    minWidth: 140,
                    fontSize: "12px",
                    textAlign: "left",
                  }}
                  fieldPropLabel={"name"}
                  fieldPropType={"type"}
                  showApiNames={false}
                  onSelect={(v, b, full) => {
                    this.setState({ selectedObject: full });
                  }}
                  values={[metadata]}
                  label={(selectedObject && selectedObject.name) || "Objects"}
                  inputs={inputs}
                  sectionLabelSuffix={"Objects"}
                  sectionLabels={[selectedProviderLabel]}
                  disabled={!metadata}
                  //selectedValues={[fullVal||{}]}
                  autoOpen
                  // dropup
                />
                <EnvCheckIcons
                  checking={false}
                  result={selectedObject ? "200 OK" : ""}
                />
              </div>
            </div>
            {selectedObject && (
              <div style={styles.card}>
                <p style={{ textDecoration: "underline" }}>
                  {selectedObject.name} Object
                </p>
                <div style={styles.p}>
                  <span>System Name: {selectedObject.type}</span>
                  <svg
                    className="copy-svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    onClick={() => {
                      clipboard.copy({
                        "text/plain": selectedField.name,
                      });
                      this.setState({ objectCopied: true });
                      setTimeout(() => {
                        this.setState({ objectCopied: false });
                      }, 3000);
                    }}
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm18-4H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z" />
                  </svg>
                  {this.state.objectCopied && (
                    <div style={styles.copied}>copied!</div>
                  )}
                </div>
                <p>
                  {selectedObject.properties &&
                    selectedObject.properties.length}{" "}
                  Fields
                </p>
              </div>
            )}
            {selectedObject && <Forward />}
          </div>
          <div style={styles.item}>
            <div style={{ display: "inline-block" }}>
              <strong className="labelz-small">Fields</strong>
              <br />
              <div>
                <MetadataDropdown
                  inputs={inputs}
                  type="neutral"
                  style={styles.metadataDropdown}
                  minWidth={260}
                  style={{
                    width: "auto",
                    maxWidth: "initial",
                    minWidth: 140,
                    fontSize: "12px",
                    textAlign: "left",
                  }}
                  fieldPropLabel={"label"}
                  fieldPropType={"name"}
                  showApiNames={false}
                  onSelect={(v, b, full) => {
                    this.setState({ selectedField: full });
                  }}
                  values={[selectedObject && selectedObject.properties]}
                  label={(selectedField && selectedField.label) || "Fields"}
                  inputs={inputs}
                  sectionLabelSuffix={"Fields"}
                  sectionLabels={[selectedObject && selectedObject.name]}
                  disabled={!selectedObject}
                  //selectedValues={[fullVal||{}]}
                  autoOpen
                  // dropup
                />
              </div>
            </div>
            {selectedField && (
              <div style={styles.card}>
                <p style={{ textDecoration: "underline" }}>
                  {selectedField.label} Field
                </p>
                <div style={styles.p}>
                  <span>System Name: {selectedField.name}</span>
                  <svg
                    className="copy-svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    onClick={() => {
                      clipboard.copy({
                        "text/plain": selectedField.name,
                      });
                      this.setState({ fieldCopied: true });
                      setTimeout(() => {
                        this.setState({ fieldCopied: false });
                      }, 3000);
                    }}
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm18-4H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z" />
                  </svg>
                  {this.state.fieldCopied && (
                    <div style={styles.copied}>copied!</div>
                  )}
                </div>
                <p>Type: {selectedField.type}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const EnvCheckIcons = ({ result, checking, style }) => {
  return (
    <div
      style={{
        ...style,
        display: "inline-block",
        verticalAlign: "bottom",
        width: 23,
      }}
    >
      {checking ? (
        <img
          alt="spinner"
          src={images.ajax_loader}
          style={{ height: 16, marginBottom: 6 }}
        />
      ) : result ? (
        <span>
          {result === "200 OK" ? (
            <svg fill="#019644" height="20" viewBox="0 0 24 24" width="20">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
            </svg>
          ) : (
            <svg fill="#EA5A5A" height="20" viewBox="0 0 24 24" width="20">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
            </svg>
          )}
        </span>
      ) : (
        <span />
      )}
    </div>
  );
};

function getColorByBgColor(bgColor) {
  if (!bgColor) {
    return "";
  }
  return parseInt(bgColor.replace("#", ""), 16) > 0xffffff / 2
    ? "#000"
    : "#fff";
}

const styles = {
  main: {
    position: "absolute",
    marginLeft: 222,
    transition: "all .35s",
    WebkitTransition: "all .35s",
    zIndex: 108,
    width: "82%",
    color: "white",
    height: 227,
  },
  body: {
    padding: "8px 8px 16px 8px",
    boxShadow: "0px -2px 6px 0px #999999",
    position: "relative",
    height: 192,
    outline: "1px solid rgb(205, 211, 224)",
  },
  bodyOnConnections: {
    padding: "8px 8px 16px 8px",
    position: "relative",
    height: 192,
    outline: "1px solid rgb(205, 211, 224)",
    background: "#eeeeee",
  },
  header: {
    width: "100%",
    position: "relative",
    zIndex: 9,
  },
  title: {
    background: "#d4d4d4",
    boxShadow: "1px -4px 5px -2px #999999",
    color: "#404040",
    display: "inline-block",
    verticalAlign: "top",
    width: "100%",
  },
  titleText: {
    fontSize: "18px",
    padding: "8px 23px 0px 23px",
    display: "inline-block",
    verticalAlign: "top",
  },
  shadow: {
    height: 37,
    width: 8,
    boxShadow: "rgb(153, 153, 153) 4px -1px 5px -2px",
    display: "inline-block",
    verticalAlign: "top",
  },
  menu: {
    position: "fixed",
    background: "white",
    boxShadow: "0px 1px 10px rgba(99,99,99,0.65)",
    zIndex: 99,
  },
  item: {
    padding: "6px 20px 5px 16px",
    width: "33%",
    display: "inline-block",
    verticalAlign: "top",
    position: "relative",
  },
  metadataDropdown: {
    width: 140,
    height: 30,
    textAlign: "left",
    display: "inline-block",
    fontSize: "12px",
  },
  card: {
    height: 100,
    width: "calc(100% - 16px)",
    boxShadow: "0px 0px 10px rgba(99,99,99,0.45)",
    background: "white",
    margin: "8px 8px 0px 0px",
    color: "#404040",
    padding: 10,
  },
  p: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    overflow: "hidden",
    margin: "0 0 10px",
  },
  envBox: {
    display: "inline-block",
    borderRadius: "4px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    padding: "2px 8px",
    textAlign: "center",
    height: 22,
    fontWeight: "bold",
    marginBottom: 8,
    margin: 10,
  },
  copied: {
    fontWeight: "bold",
    fontSize: 10,
    marginLeft: 6,
    marginTop: 3,
    display: "inline-block",
    verticalAlign: "top",
    color: "#54698d",
  },
};

const Forward = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#808080"
      style={{
        position: "absolute",
        right: 0,
        top: 103,
      }}
    >
      <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
};

export default MetadataMenu;
