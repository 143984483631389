import React, { Component } from 'react';
import images from '../images'
import Papa from 'papaparse'
import { Button, Modal } from 'react-bootstrap'
import _columns from './columns'
import Toggle from 'react-toggle'

const { Body } = Modal

export default class ShareModal extends Component {

  downloadCSV = (grid) => {
    const data = grid.map((row)=>{
      return row.map((column,c)=>{
        if(_columns[c].comp==='checkbox'){
          return column.value==='✔'?true:false
        }
        return column.value
      })
    })
    const csv = Papa.unparse(data);
    const blob = new Blob([csv]);
    if (window.navigator.msSaveOrOpenBlob) { // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, `${this.props.title}.csv`);
    } else {
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
      a.download = `${this.props.title}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  render() {
    const { grid, show, onClose, guid, redux } = this.props;
    const { config } = redux;

    const isIntegration = config.IS_INTEGRATION;
    const rootUrl = isIntegration
      ? "https://dataprotect.lingkapps.com"
      : "https://app.lingk.io";
    const url = `${rootUrl}/datamapping/${guid}`;

    return(<Modal show={show} onHide={onClose} backdrop="static">
      <Body style={{height: 'fit-content'}}>
        <div style={{textAlign: 'center', verticalAlign: 'middle',paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20}}>
            <h2>Share your Data Mapping:</h2>

          <div style={{margin:'20px 0'}} className="mapper-share-modal-toggle">
            <div style={{
              display:'inline-block',
              verticalAlign:'top',
              marginRight:12,
              marginTop:2
            }}>Public Sharing:</div>
              <Toggle
                checked={this.props.isPublic}
                className="custom-toggle-btn"
                icons={{
                checked: <span className="toggle-label toggle-label-hide">on</span>,
                unchecked: <span className="toggle-label toggle-label-show">off</span>
                }}
                onChange={this.props.handleToggle}
              />
            </div>

          <div style={{margin:'20px 0'}}>
            {this.props.isPublic ? <a href={url} target="_blank">{url}</a> :
            <span style={styles.disabledLink}>{url}</span>}
            </div>

            <div style={{ marginTop: 20 }}>
              <Button bsStyle="default" onClick={onClose}>
                Close
              </Button>
            <Button bsSize="small" bsStyle="default"
              style={{width:72,marginLeft:20}} 
              onClick={()=>this.downloadCSV(grid)}>
                <span>
                <img src={images.ic_file_download_black_48dp} 
                  style={{height:16,marginRight:5}}/>
                  <span>CSV</span>
                </span>
              </Button>
            </div>
          </div>
        </Body>
    </Modal>)
  }
}

const styles = {
  disabledLink:{
    color:'#555555',
    fontWeight:'bold'
  }
}
