import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AppWrapper from '../layouts/appWrapper';
// Import Redux actions below
import * as projectActions from '../shared/redux/actions/projectActions';
import * as mainActions from '../shared/redux/actions/mainActions';
import * as transformerActions from '../shared/redux/actions/transformerActions';
import * as datafeedActions from '../shared/redux/actions/datafeedActions';
import * as wizardActions from '../shared/redux/actions/wizardActions';
import * as environmentActions from '../shared/redux/actions/environmentActions';
import * as eventsActions from '../shared/redux/actions/eventsActions';
//import * as wizardActions from '../../shared/redux/actions/wizardActions';
/**
 * AppWrapper takes the highest level container, so we can collect collection data here
 * and pass it down to any component we want, to avoid duplications.
 * @ilanus 15/12/16 - 09:02AM
 */

const mapStateToProps = state => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...mainActions,
    ...projectActions,
    ...transformerActions,
    ...datafeedActions,
    ...wizardActions,
    ...environmentActions,
    ...eventsActions,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppWrapper);
