import React, { Component } from "react";
import EventsHome from "./eventsHome";
import Subscriptions from "./subscriptions";
import NewSubscription from "./newSubscription";
import NewWebhook from "./newWebhook";
import Webhooks from "./webhooks";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";

class EventsHomeParent extends Component {
  constructor() {
    super();
    this.state = {
      availableTemplates: []
    }
  }

  componentDidMount() {
    const { actions, redux } = this.props;
    const identity = redux.main.me;
    if (identity.role == "tech-support") {
      actions
        .getDataMechanicsTemplates()
        .then((data) => {
          if (data && data.length > 0) {
            let templates = []
            data.map((template) => {
              templates.push(template.Name)
            })
            this.setState({ availableTemplates: templates })
          }
        })
    }
  }

  render() {
    return (
      <EventsHome {...this.props}>
        <Switch>
          <Route
            exact
            path="/a/:appId/events"
            render={(routerProps) => (
              <Subscriptions
                {...routerProps}
                redux={this.props.redux}
                actions={this.props.actions}
              />
            )}
          />
          <Route
            name="newsub"
            path="/a/:appId/events/sub/new"
            render={(routerProps) => (
              <NewSubscription
                {...routerProps}
                availableTemplates={this.state.availableTemplates}
                redux={this.props.redux}
                actions={this.props.actions}
              />
            )}
          />
          <Route
            exact
            name="webhooks"
            path="/a/:appId/events/obj"
            render={(routerProps) => (
              <Webhooks
                {...routerProps}
                redux={this.props.redux}
                actions={this.props.actions}
              />
            )}
          />

          <Route
            exact
            name="newwebhook"
            path="/a/:appId/events/obj/new"
            render={(routerProps) => (
              <NewWebhook
                {...routerProps}
                redux={this.props.redux}
                actions={this.props.actions}
              />
            )}
          />
          <Route
            name="editwebhook"
            path="/a/:appId/events/obj/:objId/edit"
            render={(routerProps) => (
              <NewWebhook
                {...routerProps}
                redux={this.props.redux}
                actions={this.props.actions}
              />
            )}
          />
        </Switch>
      </EventsHome>
    );
  }
}
const mapStateToProps = (state) => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsHomeParent);
