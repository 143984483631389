import React, { Component } from "react";
import { Button } from "react-bootstrap";
import * as util from "@lingk/sync/build/metadataFunctions";
//import loadData from '@lingk/sync/build/loadData'
//import saveData from '@lingk/sync/build/saveData'
import EditTransRunResult from "../recipe/edittransRunResult";
import OngoingLogStep from "./ongoingLogResult";

const styles = {
  title: {
    display: "inline-block",
    fontWeight: "bold",
    marginRight: "15px",
  },
  buttons: {
    marginLeft: 20,
    // marginTop: 10,
    marginRight: 15,
    marginBottom: 10,
  },
  errorWrap: {
    position: "relative",
    float: "left",
    width: "100%",
    padding: "0px 1px",
  },
  error: {
    background: "#EE99AA",
    color: "white",
    fontWeight: "bold",
    padding: "4px 8px",
    width: "100%",
  },
  logBox: {
    margin: "0px 20px",
    height: "100%",
    outline: "rgb(187, 197, 215) solid 1px",
    backgroundColor: "white",
  },
  logsHeader: {
    marginBottom: 1,
    position: "relative",
    float: "left",
    backgroundColor: "#CCCDCF",
    marginRight: 10,
    fontSize: 14,
    width: "100%",
    padding: "6px 8px 8px 5px",
  },
};

export default class WizardRunConsole extends Component {
  constructor() {
    super();
    this.state = {
      startedRun: false,
      editorjson: null,
      creds: null,
      sourceMetadata: null,
      sourceMetadataError: null,
      destinationMetadata: null,
      destinationMetadataError: null,
      generatedRecipe: null,
      generatedRecipeError: null,
      executingFlag: false,
    };
    this.startRecipe = this.startRecipe.bind(this);
  }

  regenAndStartRecipe() {
    // Auto save
    this.setState({ startedRun: true, executingFlag: true });
    const { actions, redux, wiz, match, env, thisWizard, thisWizardIndex } =
      this.props;
    const { params } = match;
    const { project, transformer } = redux;
    const tenantId = project.tenantInfo.TenantId;
    // THESE ARE FOR SFTP
    const sourceFileSchemaGuid =
      thisWizard.configuration && thisWizard.configuration.sourceFileSchemaGuid;
    const destinationFileSchemaGuid =
      thisWizard.configuration &&
      thisWizard.configuration.destinationFileSchemaGuid;
    // ===================
    actions
      .callGetCredentialsForWizardRun(tenantId, params.appId, wiz, env.name)
      .then((creds) => {
        this.setState({ creds });
        return Promise.all([
          // GET SOURCE METDATA
          actions
            .callGetMetadataForWizardRun(
              tenantId,
              params.appId,
              wiz.source,
              creds[0],
              env,
              sourceFileSchemaGuid
            )
            .then((m) => {
              const sourceMetadata = util.metadataFunctions(m, wiz.source.type);
              this.setState({ sourceMetadata });
              return sourceMetadata;
            })
            .catch((err) => {
              this.setState({
                sourceMetadataError: `Error connecting to ${wiz.source.type}: ${
                  err && err.response && err.response.status
                }`,
              });
              return Promise.reject(err);
            }),
          // GET DESTINATION METDATA
          actions
            .callGetMetadataForWizardRun(
              tenantId,
              params.appId,
              wiz.destination,
              creds[1],
              env,
              destinationFileSchemaGuid
            )
            .then((m) => {
              const destinationMetadata = util.metadataFunctions(
                m,
                wiz.destination.type
              );
              this.setState({ destinationMetadata });
              return destinationMetadata;
            })
            .catch((err) => {
              this.setState({
                destinationMetadataError: `Error connecting to ${
                  wiz.destination.type
                }: ${err && err.response && err.response.status}`,
              });
              return Promise.reject(err);
            }),
        ]);
      })
      .then((metadatas) => {
        if (this.state.startedRun) {
          // CREATE LINGK_EXTERNAL_ID__C in salesforce if needed
          if (
            transformer.bundles &&
            transformer.bundles.bundles &&
            transformer.mapping &&
            transformer.mapping.mappings &&
            env.name
          ) {
            actions.createLingkExternalIds(
              tenantId,
              params.appId,
              transformer.bundles.bundles,
              transformer.mapping.mappings,
              metadatas[1],
              env.name
            );
          }
          // GENERATE RECIPE
          return actions
            .callGenerateRecipeForWizardRun(
              tenantId,
              params.appId,
              thisWizard,
              thisWizardIndex,
              metadatas[0],
              metadatas[1],
              env
            )
            .then((generatedRecipe) => {
              this.setState({ generatedRecipe });
              return actions.callPostEventContext(
                {
                  eventData: {
                    environmentName: env.name,
                    environmentId: String(env.environmentId),
                  },
                },
                params.transId
              );
            })
            .catch((err) => {
              this.setState({
                generatedRecipeError: `Error generating a recipe: ${
                  err && err.response && err.response.status
                }`,
              });
              return Promise.reject(err);
            });
        }
        return Promise.reject("Cancelled");
      })
      .then(() => {
        if (this.state.startedRun) {
          this.executeRecipe(
            tenantId,
            this.state.generatedRecipe.Id,
            this.state.generatedRecipe.BaseId
          );
        }
      });
  }

  startRecipe = () => {
    this.props.actions.setExecutingTransformer(
      parseInt(this.props.match.params.transId),
      true
    );
    this.props.handleRemoveExecutionTab();
    const { redux, thisWizard, env } = this.props;
    const { project } = redux;
    const tenantId = project.tenantInfo.TenantId;
    if (thisWizard.transformerId) {
      this.setState({ executing: true });
      const event = {};
      event.environmentName = env.name;
      event.environmentId = String(env.environmentId);
      this.props.actions
        .callPostEventContext(
          { eventData: event },
          this.props.match.params.transId
        )
        .then(() => {
          this.executeRecipe(
            tenantId,
            thisWizard.transformerId,
            thisWizard.transformerBaseId
          );
        });
    }
  };

  createNewQuery() {
    let query = "";
    const d = new Date();
    const seconds = d.getTime() / 1000;
    const externalExecutionId = Math.round(seconds);
    query += `externalExecutionId=${externalExecutionId}`;
    return { query, externalExecutionId };
  }

  executeRecipe(tenantId, transId, baseId) {
    const startedAt = new Date();
    const newQuery = this.createNewQuery();
    const fromWizard = true;
    this.props.actions.callExecuteTransformer(
      tenantId,
      transId,
      newQuery.query,
      newQuery.externalExecutionId,
      startedAt,
      baseId,
      fromWizard
    );
  }

  parseCredsForProvider(cred, type) {
    if (!cred) return "";
    let s = "";
    switch (type) {
      case "Salesforce":
        s = `(${cred.credentialsJson.userName})`;
        break;
      default:
        s = cred.credentialsJson.rootUrl
          ? `(${cred.credentialsJson.rootUrl})`
          : "";
        break;
    }
    return s;
  }

  handleCancel = () => {
    const { actions } = this.props;
    this.setState({
      startedRun: false,
      sourceMetadata: null,
      sourceMetadataError: null,
      destinationMetadata: null,
      destinationMetadataError: null,
      creds: null,
      generatedRecipe: null,
      generatedRecipeError: null,
    });
    actions.setExecutionLogs([]);
    actions.setExecutionError(false, "");
    actions.setExecutionCompleted(false);
  };

  render() {
    const { env, wiz, redux } = this.props;
    const {
      creds,
      sourceMetadata,
      destinationMetadata,
      generatedRecipe,
      executing,
      sourceMetadataError,
      destinationMetadataError,
      generatedRecipeError,
    } = this.state;
    const { transformer } = redux;
    if (!transformer) return <div />;

    let height, buttons, opacity;

    let mode = "runOnce";
    if (
      transformer.executionCompleted &&
      transformer.executionLogs.length > 0
    ) {
      mode = "runResults";
    }
    switch (mode) {
      case "runOnce":
        height = 292;
        opacity = 1;
        buttons = (
          <div>
            <Button
              bsSize="small"
              bsStyle="primary"
              onClick={this.startRecipe}
              disabled={executing}
            >
              Start Integration
            </Button>
          </div>
        );
        break;
      case "runResults":
        height = 292;
        opacity = 1;
        buttons = (
          <div>
            <div style={styles.title}>Results</div>
            {/* <Button
              bsSize="small"
              bsStyle="primary"
              onClick={this.handleCancel}
            >
              X
            </Button> */}
          </div>
        );
        break;
      default:
        height = 0;
        opacity = 0;
    }

    const count = transformer.ongoingExecutionLogCount;
    const areOngoingLogs =
      transformer.ongoingExecutionLogs &&
      transformer.ongoingExecutionLogs.length > 0;

    const logsToShow = [];
    for (let i = 0; i < transformer.ongoingExecutionLogs.length; i++) {
      const s = transformer.ongoingExecutionLogs[i];
      if (s.completed) {
        logsToShow.push(s);
        if (!s.succeeded) {
          break;
        }
      } else {
        // push the first uncompleted one as well
        logsToShow.push(s);
        break;
      }
    }

    if (this.scroller && logsToShow.length > 8 && this.state.executingFlag) {
      setTimeout(() => {
        this.scroller.scrollTop = this.scroller.scrollHeight;
      }, 100);
    }

    if (transformer.executionCompleted) {
      setTimeout(() => {
        if (this.scroller && this.state.executingFlag) {
          this.setState({ executingFlag: false, executing: false });
          this.scroller.scrollTop = 0;
        }
      }, 500);
    }

    return (
      <div style={{ opacity, width: "100%" }} className="recipe-console-wizard">
        <div style={styles.buttons}>{buttons}</div>
        <div>
          <div style={styles.logBox} ref={(ref) => (this.scroller = ref)}>
            {mode === "runResults" && (
              <div style={{ marginBottom: 2 }}>
                <form className="form-horizontal-yaml">
                  {transformer.executionCompleted && (
                    <div>
                      <div style={styles.logsHeader}>
                        <div
                          style={{
                            display: "inline-block",
                            width: "33%",
                            marginLeft: "1%",
                          }}
                        >
                          <strong>Statement:</strong>
                        </div>
                        <div style={{ display: "inline-block", width: "53%" }}>
                          <strong>Result:</strong>
                        </div>
                        <div style={{ display: "inline-block", width: "13%" }}>
                          <strong>Timer:</strong>
                        </div>
                      </div>
                      {transformer.executionLogs &&
                        transformer.executionLogs.map((l, i) => {
                          if (i < 100)
                            return (
                              <EditTransRunResult
                                result={l.result}
                                timer={l.timer}
                                startTime={l.startTime}
                                statement={l.statement}
                                key={i}
                              />
                            );
                          return <span />;
                        })}
                      {transformer.executionError ? (
                        <div style={styles.errorWrap}>
                          <div style={styles.error}>
                            {transformer.executionErrorMessage}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                </form>
              </div>
            )}
            {mode === "runOnce" && (
              <div>
                <div
                  style={{
                    background: "#EEE",
                    padding: "9px 16px 6px",
                    borderBottom: "1px solid #CCC",
                  }}
                >
                  <span>Run in environment:</span>&nbsp;
                  <strong style={{ fontSize: "15px" }}>{env.name}</strong>
                </div>

                <div className="loading">
                  {this.state.startedRun && (
                    <OngoingLogStep
                      count={count}
                      mod={4}
                      step={{
                        statement: !sourceMetadata
                          ? `Connecting to ${wiz.source.type}`
                          : `Connected to ${
                              wiz.source.type
                            } ${this.parseCredsForProvider(
                              creds && creds[0],
                              wiz.source.type
                            )}`,
                        completed: sourceMetadata || sourceMetadataError,
                        succeeded: sourceMetadata && !sourceMetadataError,
                        sortOrder: -4,
                        result: sourceMetadataError,
                      }}
                    />
                  )}
                  {this.state.startedRun && (
                    <OngoingLogStep
                      count={count}
                      mod={4}
                      step={{
                        statement: !destinationMetadata
                          ? `Connecting to ${wiz.destination.type}`
                          : `Connected to ${
                              wiz.destination.type
                            } ${this.parseCredsForProvider(
                              creds && creds[1],
                              wiz.destination.type
                            )}`,
                        completed:
                          destinationMetadata || destinationMetadataError,
                        succeeded:
                          destinationMetadata && !destinationMetadataError,
                        sortOrder: -3,
                        result: destinationMetadataError,
                      }}
                    />
                  )}
                  {sourceMetadata && destinationMetadata && (
                    <OngoingLogStep
                      count={count}
                      mod={4}
                      step={{
                        statement: !generatedRecipe
                          ? "Generating Recipe"
                          : "Recipe Generated",
                        completed: generatedRecipe || generatedRecipeError,
                        succeeded: generatedRecipe && !generatedRecipeError,
                        sortOrder: -2,
                        result: generatedRecipeError,
                      }}
                    />
                  )}
                  {(generatedRecipe || executing) && (
                    <OngoingLogStep
                      count={count}
                      mod={4}
                      step={{
                        statement: "Starting Recipe",
                        completed: areOngoingLogs,
                        succeeded: areOngoingLogs,
                        sortOrder: -1,
                      }}
                    />
                  )}
                  {logsToShow.map((s, i) => {
                    return (
                      <OngoingLogStep step={s} key={i} count={count} mod={1} />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
