// make sure columns have unique keys!

const _columns = [
  {
    key: 'so',
    name: 'Source Object', 
    mode: 'source',
    isObject: true,
    comp: 'dropdown',
    width: 160,
  },
  {
    key: 'sf',
    name: 'Source Field', 
    mode: 'source',
    isField: true, 
    comp: 'dropdown',
    width: 160,
  },
  {
    key: 'st',
    name: 'Type',
    width: 80,
  },
  {
    key: 'to',
    name: 'Target Object', 
    mode: 'target',
    isObject: true,
    comp: 'dropdown',
    width: 160,
  },
  {
    key: 'tf',
    name: 'Target Field', 
    mode: 'target',
    isField: true, 
    comp: 'dropdown',
    width: 160
  },
  {
    key: 'tt',
    name: 'Type',
    width:80
  },
  {
    key: 'pk',
    name: 'PK',
    comp: 'checkbox',
    width:33
  },
  {
    key: 'pi',
    name: 'PII',
    comp: 'checkbox',
    width:33
  },
  {
    key: 'ns',
    name: 'Notes',
    comp: 'textarea',
    width: 280,
  }
]

export default _columns