import React, { Component } from "react";
import images from "../images";
import HistoryBox from "../utils/historyBox";
import { withRouter } from "react-router-dom";
const MyContext = React.createContext();

class RecipeTabs extends Component {
  constructor(props) {
    super(props);
    const pathArray = props.location.pathname.split("/");

    let sel = 0;
    if (pathArray.length < 6) sel = 0;
    else {
      if (pathArray[5] === "health") sel = 1;
      else if (pathArray[5] === "info") sel = 2;
      else if (pathArray[5] === "deploy") sel = 3;
    }

    this.state = {
      selected: sel,
      warn: false,
      curVer: null,
      languageVersion: null,
      consoleMode: "",
    };
    this.select = this.select.bind(this);
    this.warnState = this.warnState.bind(this);
    this.changeVersionOnTop = this.changeVersionOnTop.bind(this);
    this.getRecipeVersion = this.getRecipeVersion.bind(this);
    this.liftRecipeVersion = this.liftRecipeVersion.bind(this);
    this.getLanguageVersion = this.getLanguageVersion.bind(this);
    this.changeConsoleMode = this.changeConsoleMode.bind(this);
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearTransformer();
  }

  warnState() {
    this.setState({ selected: 0 });
  }

  changeConsoleMode(e) {
    this.setState({ consoleMode: e });
  }

  changeVersionOnTop(v) {
    this.setState({ languageVersion: v });
  }

  getLanguageVersion() {
    const { languageVersion } = this.state;

    if (languageVersion) {
      return languageVersion;
    } else {
      return false;
    }
  }

  liftRecipeVersion(v) {
    this.setState({ curVer: v });
  }

  getRecipeVersion() {
    const { curVer } = this.state;

    if (curVer) {
      return curVer;
    } else {
      return false;
    }
  }

  select(selected, path) {
    this.setState({ selected });
    this.props.history.push(path);
  }

  render() {
    const { children, redux, actions, match } = this.props;
    const { params } = match;
    const warnState = this.warnState;
    const changeVersionOnTop = this.changeVersionOnTop;
    const liftRecipeVersion = this.liftRecipeVersion;
    const getRecipeVersion = this.getRecipeVersion;
    const getLanguageVersion = this.getLanguageVersion;
    const changeConsoleMode = this.changeConsoleMode;
    const { languageVersion, consoleMode } = this.state;
    const { transformer, project } = redux;
    const styles = {
      main: {
        background: "#bbb",
        width: "100%",
        height: "calc(100% - 50px)",
      },
      tab: {
        display: "inline-block",
        height: 42,
        width: 155,
        borderRadius: 2,

        textAlign: "center",
        paddingTop: 12,
        cursor: "pointer",
      },
      tabs: {
        marginLeft: 20,
        marginTop: 50,
      },
    };
    const { appId, transId } = params;
    let items = [];

    if (languageVersion === "3.0") {
      items = [
        { name: "Recipe", path: `/a/${appId}/tf/${transId}` },
        { name: "Monitor", path: `/a/${appId}/tf/${transId}/health` },
        { name: "Details", path: `/a/${appId}/tf/${transId}/info` },
      ];
    } else {
      items = [
        { name: "Recipe", path: `/a/${appId}/tf/${transId}` },
        { name: "Monitor", path: `/a/${appId}/tf/${transId}/health` },
        { name: "Details", path: `/a/${appId}/tf/${transId}/info` },
        {
          name: "Schedule",
          path: `/a/${appId}/tf/${transId}/deploy`,
          className: "deploy-tab",
        },
      ];
    }

    const v = transformer.versions && transformer.versions[0];
    const lastModified = v && v.LastModified;
    const lastModifiedBy = v && v.LastModifiedBy;
    return (
      <div style={{ marginLeft: 200 }}>
        <div style={styles.main}>
          {/* <div style={styles.tabs}>
          {items.map((item, index) =>
            <div onClick={()=>this.select(index, item.path)} style={styles.tab} key={index}
              className={(this.state.selected === index ? 'sel-tabby' : 'tabby') + ` ${item.className}`}>
              {item.name}
            </div>
          )}
          <HistoryBox redux={redux} type="Recipe"
            lastModified={lastModified} lastModifiedBy={lastModifiedBy}
          />
        </div> */}
          {true ? (
            // {transformer.versions &&
            // transformer.versions[0] &&
            // transformer.deployments &&
            // transformer.allTriggers &&
            // transformer.eventContext &&
            // project.tenantInfo &&
            // project.appData
            <div
            //  style={{height:'calc(100% - 42px)'}}
            >
              <MyContext.Provider
                value={{
                  redux,
                  actions,
                  warnState,
                  changeVersionOnTop,
                  liftRecipeVersion,
                  getRecipeVersion,
                  getLanguageVersion,
                  changeConsoleMode,
                  consoleMode,
                }}
              >
                {this.props.children}
              </MyContext.Provider>
            </div>
          ) : (
            <div style={{ height: "calc(100% - 42px)", background: "#dcdfdf" }}>
              <div className="ajax-loader">
                <img alt="spinner" src={images.ajax_loader} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(RecipeTabs);
export { MyContext };
