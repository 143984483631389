import * as types from "./types";

export function callGetWizards(tenantId, accountId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${accountId}/productizedintegrations`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_WIZARDS,
          data,
        });
        return data;
      });
  };
}

export function callGetPiConfig(tenantId, accountId, piGuid) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(
        `${tenantId}/${accountId}/productizedintegrations/${piGuid}/versions`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then((data) => {
        if (data && data.length > 0) {
          const PI = data[0];
          const configs = PI.configuration;
          configs.title = PI.name;
          configs.lastModified = PI.lastModified;
          configs.lastModifiedBy = PI.lastModifiedBy;
          dispatch({
            type: types.SET_WIZ_SAVED_CONFIGURATION,
            data: configs,
          });
          dispatch({
            type: types.SET_WIZ_VERSIONS,
            data,
          });
          //dispatch(callGetTransformerLatestVersion(PI.TransformerBaseId))
          Promise.all([
            dispatch(callGetBundles(tenantId, accountId, PI.bundlePackGuid)),
            dispatch(callGetMapping(tenantId, accountId, PI.schemaMappingGuid)),
          ]);
          return data;
        }
      });
  };
}

export function changePiVersion(tenantId, accountId, PI) {
  return (dispatch) => {
    dispatch({
      type: types.SET_WIZ_BUNDLES,
      data: null,
    });
    dispatch({
      type: types.SET_WIZ_MAPPINGS,
      data: null,
    });
    const configs = PI.configuration;
    configs.title = PI.name;
    dispatch({
      type: types.SET_WIZ_SAVED_CONFIGURATION,
      data: configs,
    });
    return Promise.all([
      dispatch(callGetBundles(tenantId, accountId, PI.bundlePackGuid)),
      dispatch(callGetMapping(tenantId, accountId, PI.schemaMappingGuid)),
    ]);
  };
}

export function callGetBundles(tenantId, accountId, bundlePackGuid) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${accountId}/bundle/${bundlePackGuid}`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_WIZ_BUNDLES,
          data,
        });
        return data;
      });
  };
}

export function callGetMapping(tenantId, accountId, mappingGuid) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${accountId}/schemamappings/${mappingGuid}`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_WIZ_MAPPINGS,
          data,
        });
        return data;
      });
  };
}

export function callDeleteWizard(tenantId, accountId, piGuid) {
  return (dispatch, getState, api) => {
    const { config, project } = getState();
    dispatch({
      type: types.CLEAR_INTEGRATION,
    });
    dispatch({
      type: types.CLEAR_WIZARD,
    });
    dispatch({
      type: types.SET_WIZARDS,
      data:
        project.wizards &&
        project.wizards.filter(
          (pi) => pi.ProductizedIntegrationGuid !== piGuid
        ),
    });
    return api.delete(
      `${tenantId}/${accountId}/productizedintegrations/${piGuid}`,
      {
        endpoint: config.TRANS_API_URL,
      }
    );
  };
}

/*export function startRecipeFromWizard(transId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    let query = '';
    const d = new Date();
    const seconds = d.getTime() / 1000;
    const externalExecutionId = Math.round(seconds);
    //query += `action=create\\&`;
    //query += `path=/organizations/salesforce/00D41000002jQmpEAE/sample.zip\\&`;
    //query += `at=${d.toISOString()}\\&`;
    //query += `type=file\\&`;
    query += `externalExecutionId=${externalExecutionId}`;

    console.log('executing generated recipe', transId)

    return api.get(`execute/${transId}?${query}`, {
      endpoint: config.TRANS_API_URL,
    })
    .then((data) => {
      return {done:data, externalExecutionId}
    })
  };
}

export function callLookupLogDataFromWizard(tenantId, externalExecutionId, index, onFinish) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.get(`${tenantId}/logs/transformers/externalExecutionId/${externalExecutionId}`, {
      endpoint: config.TRANS_API_URL,
    })
    .then((data) => {
      console.log('LOG DATA', data)
      if (!(data[0] && data[0].completed)) {
        if (index < 3600) {
          setTimeout(() => {
            dispatch(
              callLookupLogDataFromWizard(tenantId, externalExecutionId, index+1, onFinish)
            );
          }, 1000);
        } else {
          //dispatch(setExecutionError(true, 'Request timed out, please try again.'));
          onFinish()
        }
      } else {
        if (!(data[0] && data[0].succeeded)) {
          //dispatch(setExecutionError(true, data[0].errorMessage));
          //return { error: true, message: data[0].errorMessage }
          onFinish()
        }
        //return { error: false, message: '' }
        //dispatch(callGetLogSteps(externalExecutionId, startedAt));
        onFinish()
      }
    })
  }
}*/

export function callGetCredentialsForWizardRun(tenantId, appId, wiz, envName) {
  const { source, destination } = wiz;
  return (dispatch) => {
    return Promise.all([
      dispatch(
        tryGetCredentials(
          tenantId,
          appId,
          source.type,
          source.credentials,
          envName
        )
      ),
      dispatch(
        tryGetCredentials(
          tenantId,
          appId,
          destination.type,
          destination.credentials,
          envName
        )
      ),
    ]);
  };
}
function tryGetCredentials(
  tenantId,
  appId,
  credentialType,
  credsConfig,
  envName
) {
  return (dispatch) => {
    if (envName) {
      return dispatch(
        getCredentialForEnvironment(tenantId, appId, credentialType, envName)
      ).catch(() => null);
    }
    return Promise.resolve(null);
  };
}
function getCredentialForEnvironment(tenantId, appId, credentialType, envName) {
  return (dispatch, getState, api) => {
    if (credentialType === "LocalFile") {
      return Promise.resolve({
        credentialType: "LocalFile",
        credentials: "",
        credentialsJson: {},
        providerLabel: "LocalFile",
      });
    }
    const { config } = getState();
    return api.get(
      `${tenantId}/${appId}/credentials/${credentialType}/${envName}`,
      {
        endpoint: config.TRANS_API_URL,
      }
    );
  };
}

export function callGetMetadataForWizardRun(
  tenantId,
  appId,
  provider,
  creds,
  environment,
  fileSchemaGuid
) {
  // FlatFile creds are coming in like this???
  if (!creds) {
    return () => Promise.reject({ response: { status: 404 } });
  }
  return (dispatch) => {
    return dispatch(
      tryCheckConnection(
        tenantId,
        appId,
        environment,
        provider,
        creds,
        fileSchemaGuid
      )
    ).then((r) => {
      // flat file schema (for browser upload guid is in creds)
      let schemaGuid =
        creds && creds.credentialsJson && creds.credentialsJson.schemaGuid;
      // for SFTP guid is in wiz config
      if (fileSchemaGuid) {
        schemaGuid = fileSchemaGuid;
      }
      // this is currently only for SFTP check connection
      /*let resFromCheckConnection = null
      if(r && r.schema){
        resFromCheckConnection = r.schema // skip callGetProviderMetadata network call
      }*/
      return dispatch(
        callGetProviderMetadata(
          tenantId,
          appId,
          provider,
          environment.name,
          schemaGuid,
          creds.credentialsJson,
          creds.key
        )
      );
    });
  };
}
function tryCheckConnection(
  tenantId,
  appId,
  environment,
  providerConfig,
  creds,
  fileSchemaGuid
) {
  const { type, metadataApi, credentialType, skipCheckConnection } =
    providerConfig;
  return (dispatch, getState, api) => {
    if (skipCheckConnection || fileSchemaGuid || isDemoCreds(creds, type)) {
      return Promise.resolve(null);
    }
    const { config } = getState();
    if (metadataApi === "TEST_CONN_API_URL") {
      return api.post(
        `${tenantId}/${appId}/testconnection/${type.toLowerCase()}`,
        {
          endpoint: config.TEST_CONN_API_URL,
          data: {
            credentialType: credentialType,
            credentialsJson: creds,
            providerLabel: type,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else if (metadataApi === "SF_AGENT_API_URL") {
      return api.get(
        `${tenantId}/${appId}/environments/${environment.name}/${creds.key}/testconnection`,
        {
          endpoint: config.SF_AGENT_API_URL,
        }
      );
    }
    return api.post(
      `${tenantId}/${appId}/testconnection/${type.toLowerCase()}`,
      {
        endpoint: config.TRANS_API_URL,
        data: creds,
      }
    );
  };
}

function addLeadingSlashToSftpPath(credsJson) {
  const creds = { ...credsJson };
  if (creds.path && creds.path[0] !== "/") {
    creds.path = "/" + creds.path;
  }
  if (creds.rootUrl) {
    creds.endpoint = creds.rootUrl;
  }
  if (creds.databaseType) {
    creds.databaseType = creds.databaseType.toLowerCase();
  }
  return creds;
}

function callGetProviderMetadata(
  tenantId,
  appId,
  providerConfig,
  envName,
  schemaGuid,
  creds,
  connectionKey
) {
  const { type, metadataEndpoint, metadataApi, credentials, credentialType } =
    providerConfig;
  const isLocalFile = credentialType === "LocalFile";
  const endpoint = creds && creds.rootUrl;
  const isOauth = credentials && credentials[0] === "Oauth";
  if (!metadataEndpoint) {
    return () => Promise.resolve(null);
  }
  return (dispatch, getState, api) => {
    const { config } = getState();
    // 'testconnection ' GET route is for adapter only. Should be called 'adaptermetadata'
    let isAdapter = false;
    let urlEnd = metadataEndpoint;
    if (metadataEndpoint === "adaptermetadata") {
      urlEnd = `adaptermetadata?url=${endpoint}`;
      isAdapter = true;
    }
    /*if(metadataEndpoint==='fileschema'){
      urlEnd = `fileschema?schemaGuid=${schemaGuid}`
    }*/
    if (metadataEndpoint === "schema/swagger/moodle" && creds.swaggerUrl) {
      urlEnd = `schema/swagger/moodle?swaggerUrl=${creds.swaggerUrl}`;
    }
    if (metadataApi === "TEST_CONN_API_URL") {
      const credsJson = addLeadingSlashToSftpPath(creds);
      return api.post(`${tenantId}/${appId}/${metadataEndpoint}`, {
        endpoint: config[metadataApi],
        data: {
          credentialType: credentialType,
          credentialsJson: credsJson,
          providerLabel: type,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
    } else {
      if (isOauth) {
        return api.get(
          `${tenantId}/${appId}/environments/${envName}/${connectionKey}/${urlEnd}`,
          {
            endpoint: config[metadataApi],
          }
        );
      }
      if (isAdapter || isLocalFile || type === "Ethos") {
        return api.get(
          `${tenantId}/${appId}/environments/${envName}/${urlEnd}`,
          {
            endpoint: config[metadataApi],
          }
        );
      }
      return api.get(`${tenantId}/${appId}/${urlEnd}`, {
        endpoint: config[metadataApi],
      });
    }
  };
}
export function callGenerateRecipeForWizardRun(
  tenantId,
  appId,
  thisWizard,
  thisWizardIndex,
  sourceMetadata,
  destinationMetadata,
  environment
) {
  const envName = environment.name;
  const envId = environment.environmentId;
  const piGuid = thisWizard.productizedIntegrationGuid;
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .post(`${tenantId}/${appId}/productizedintegrations/${piGuid}/generate`, {
        endpoint: config.TRANS_API_URL,
        data: {
          sourceSchema: sourceMetadata,
          destinationSchema: destinationMetadata,
          environment: envName,
          environmentId: String(envId),
          piVersionIndex: String(thisWizardIndex),
          ...(thisWizard.id && { updateWizardVersionId: thisWizard.id }),
        },
      })
      .catch(() => {
        return Promise.reject({ response: { status: 422 } });
      });
  };
}

export function createLingkExternalIds(
  tenantId,
  appId,
  bundles,
  mappings,
  metadata,
  envName
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const externalIdlessObjects = [];
    // only run for Salesforce
    bundles.forEach((b) => {
      if (b.provider.toLowerCase() === "salesforce") {
        const rsc = metadata.find((r) => r.type === b.name);
        const externalIdProp =
          rsc &&
          rsc.properties &&
          rsc.properties.find((p) => p.name === "Lingk_External_Id__c");
        // only add to the array if Lingk External Id does not already exist
        if (!externalIdProp || externalIdProp.fake === true) {
          externalIdlessObjects.push(b.name);
        }
      }
    });

    if (externalIdlessObjects.length > 0) {
      return api.post(
        `${tenantId}/${appId}/environments/${envName}/salesforceschema/field`,
        {
          endpoint: config.SF_AGENT_API_URL,
          data: externalIdlessObjects.map((o) => {
            return {
              type: o,
              field: "Lingk_External_Id__c",
            };
          }),
        }
      );
    }
    return Promise.resolve(null);
  };
}

// "fake" credentials for demo purposes
function isDemoCreds(creds, providerType) {
  if (creds && providerType) {
    switch (providerType) {
      case "Blackboard":
        if (
          creds.key === "6c53d83209834b0e982c0984a5c11bdc" &&
          creds.secret === "16d2ba71f8fa4f13a74f2264c47f6244"
        )
          return true;
        return false;
      case "Canvas":
        if (creds.accessToken === "7d4fb8b1afb540049ad2a7268b78da07")
          return true;
        return false;
      default:
        return false;
    }
  } else return false;
}
