import React, { Component } from "react";
import Toggle from "react-toggle";
import images from "../images";
import Favicon from "../utils/react-favicon";

const styles = {
  sidebar: {
    position: "fixed",
    top: 0,
    // backgroundColor: '#202020',
    height: "100%",
    marginTop: 0,
    color: "white",
    zIndex: 1003,
  },
  link: {
    lineHeight: 24,
    width: 75,
    fontSize: 12,
  },
  logo: {
    height: 15,
    display: "inline-block",
    margin: "auto",
    marginTop: -15,
    marginLeft: 10,
  },
  sidenavItem: {
    height: 45,
    marginBottom: 0,
    padding: "12px 0",
    width: 180,
    color: "white",
    borderTop: "1px solid black",
    cursor: "pointer",
    verticalAlign: "top",
  },
  icon: {
    width: 16,
    height: 16,
    display: "inline-block",
    margin: "auto",
    marginRight: 8,
    marginTop: -2,
  },
  sideTitle: {
    padding: 15,
    paddingTop: 4,
    marginBottom: 3,
    color: "#ccc",
    fontSize: 16,
    width: 160,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};
export default class SideNav extends Component {
  constructor() {
    super();
    this.state = {
      selected: 0,

      fold: false,
      unfolded: [],
      currentPage: "",
    };
    this.choose = this.choose.bind(this);
    this.foldDown = this.foldDown.bind(this);
    // this.handleToggle = this.handleToggle.bind(this);
    this.foldSidebar = this.foldSidebar.bind(this);
    this.unFoldSidebar = this.unFoldSidebar.bind(this);
    this.actionPath = this.actionPath.bind(this);
  }

  choose(v, path, action) {
    this.foldDown(v, action);

    if (action) {
      this.setState({ selected: v, currentPage: action[0] });
    } else {
      this.setState({ selected: v, currentPage: "" });
    }
    this.props.history.push(path);
  }

  foldDown(v, action) {
    let { unfolded, selected } = this.state;

    if (action) {
      if (!unfolded.includes(v)) {
        unfolded = [];
        unfolded.push(v);
      } else {
        if (selected === v) {
          const i = unfolded.findIndex((f) => f === v);
          unfolded.splice(i, 1);
        }
      }
    } else {
      unfolded = [];
    }

    this.setState({ unfolded });
  }

  actionPath(path, name, parent) {
    const currentPage = name;
    this.setState({ currentPage, selected: parent });
    this.props.history.push(path);
  }

  foldSidebar() {
    this.setState({ fold: true });
    document.body.classList.add("hide-sidebar");
    window.localStorage.setItem("hideSidebar", "true");
  }

  unFoldSidebar() {
    this.setState({ fold: false });
    document.body.classList.remove("hide-sidebar");
    window.localStorage.setItem("hideSidebar", "false");
  }

  // handleToggle() {
  //   let hidehelpers = this.state.hidehelpers;
  //   hidehelpers = !hidehelpers;
  //   this.setState({ hidehelpers });

  //   if (hidehelpers) {
  //     document.body.classList.add("hide-elevio");
  //     window.localStorage.setItem("hideHelpers", "true");
  //   } else {
  //     document.body.classList.remove("hide-elevio");
  //     window.localStorage.setItem("hideHelpers", "false");
  //   }
  // }

  toHome() {
    this.props.actions.callGetApps();
    this.props.history.push("/");
  }

  componentWillMount() {
    const hidingSidebar = JSON.parse(
      window.localStorage.getItem("hideSidebar")
    );

    if (hidingSidebar) {
      document.body.classList.add("hide-sidebar");
      this.setState({ fold: true });
    } else {
      document.body.classList.remove("hide-sidebar");
      this.setState({ fold: false });
    }

    const pathArray = this.props.location.pathname.split("/");

    if (pathArray.length > 4) {
      if (pathArray[3] === "dash") {
        if (pathArray[4] === "team") {
          this.setState({ currentPage: "Team" });
        } else if (pathArray[4] === "settings") {
          this.setState({ currentPage: "Settings" });
        }
      } else if (pathArray[3] === "events") {
        if (pathArray[4] === "obj") {
          this.setState({ currentPage: "Manage Objects" });
        }
      }
    } else {
      if (pathArray[3] === "dash") {
        this.setState({ currentPage: "Dashboard" });
      } else if (pathArray[3] === "events") {
        this.setState({ currentPage: "Subscriptions" });
      }
    }

    if (pathArray[3] === "db") {
      this.setState({ selected: 7 });
    } else if (pathArray[3] === "ca") {
      this.setState({ selected: 6 });
    } else if (pathArray[3] === "schedule") {
      this.setState({ selected: 5 });
    } else if (pathArray[3] === "events") {
      this.setState({ selected: 4, unfolded: [5] });
    } else if (pathArray[3] === "tf" || pathArray[3] === "wiz") {
      this.setState({ selected: 3 });
    } else if (pathArray[3] === "map") {
      this.setState({ selected: 2 });
    } else if (pathArray[3] === "env") {
      this.setState({ selected: 1 });
    } else if (pathArray[3] === "dash") {
      this.setState({ selected: 0, unfolded: [0] });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    if (location.pathname.includes("health")) {
      if (this.state.selected != 3) {
        this.setState({ selected: 3 });
      }
    } else if (
      location.pathname.includes("tf") ||
      location.pathname.includes("wiz")
    ) {
      if (this.state.selected != 2) {
        this.setState({ selected: 2 });
        this.foldDown(this.state.selected);
      }
    } else if (location.pathname.includes("env")) {
      if (this.state.selected != 1) {
        this.setState({ selected: 1 });
      }
    }
  }

  render() {
    const { project, main, transformer, wizard } = this.props.redux;
    const edition =
      project &&
      project.tenantInfo &&
      project.tenantInfo.Level &&
      main &&
      main.accessLevels &&
      main.accessLevels.find((al) => al.level === project.tenantInfo.Level);
    const { features, environments, appData } = project && project;
    const { fold, unfolded } = this.state;

    const { favicon } = transformer && transformer;
    const logsToShow = [];
    let favLength = 0;
    const ongoingLogs =
      favicon.icon === "wizard"
        ? wizard.ongoingExecutionLogs
        : transformer.ongoingExecutionLogs;
    const additions = {
      recipe: 1,
      wizard: 2, // from inside wizard, adds generate step
      wizardRunOnce: 4, // from wizard Run Once (in transformer). add metadata steps
    };
    for (let i = 0; i < ongoingLogs.length; i++) {
      const s = ongoingLogs[i];
      if (s.completed) {
        logsToShow.push(s);
        if (!s.succeeded) {
          break;
        }
      } else {
        // push the first uncompleted one as well
        logsToShow.push(s);
        break;
      }
    }
    favLength = logsToShow.length + additions[favicon.icon];

    let barcolor = "#00853E";
    if (edition && edition.dark) barcolor = edition.dark;

    let navcolor;
    if (edition && edition.dark) navcolor = edition.dark;

    const { appId } = this.props.match.params;

    const teamlength = project.permissions ? project.permissions.length : "-";

    //const isLocal = window.location && window.location.host==='localhost:3000'

    let items = [
      {
        title: "Overview",
        pathname: `/a/${appId}/dash`,
        action: ["Dashboard", `Team (${teamlength})`, "Settings"],
        shortNames: ["Dash", "Team", "Settings"],
        actionpath: [
          `/a/${appId}/dash`,
          `/a/${appId}/dash/team`,
          `/a/${appId}/dash/settings`,
        ],
        lineheight: "32px",
        image: images.ic_view_quilt_white_48dp,
      },

      {
        title: `Environments`,
        pathname: `/a/${appId}/env`,
        lineheight: "32px",
        image: images.share,
      },

      // {
      //   title: "Mapping Plans",
      //   pathname: `/a/${appId}/map`,
      //   lineheight: "32px",
      //   image: images.ic_apps_white_48dp,
      // },

      //{title: 'Wizards', pathname: `/a/${appId}/wiz`, lineheight: '32px', image: images.ic_web_white_48dp},

      {
        title: `Recipes`,
        pathname: `/a/${appId}/tf`,
        lineheight: "32px",
        image: images.description,
      },
      {
        title: "Executions",
        pathname: `/a/${appId}/health`,
        lineheight: "32px",
        image: images.ExecutionHistoryTab,
      },
      {
        title: "Events",
        pathname: `/a/${appId}/events`,
        action: ["Subscriptions", "Manage Objects"],
        shortNames: ["Subs", "Objects"],
        actionpath: [`/a/${appId}/events`, `/a/${appId}/events/obj`],
        lineheight: "32px",
        image: images.event,
      },

      {
        title: `Schedules`,
        pathname: `/a/${appId}/schedule`,
        lineheight: "32px",
        image: images.schedule,
      },

      {
        title: "Analytics",
        pathname: `/a/${appId}/ca`,
        lineheight: "32px",
        image: images.ic_sort_white_48dp,
      },
    ];

    if (features.find((f) => f.key === "lingkapis")) {
      items.push({
        title: "Lingk APIs",
        pathname: `/a/${appId}/db`,
        lineheight: "32px",
        image: images.piechart,
      });
    }

    return (
      <div>
        <div
          className="sidenav-main"
          onMouseOver={() => this.unFoldSidebar()}
          onMouseLeave={() => this.foldSidebar()}
        >
          <div className="sidebar-flex">
            <div
              onClick={() => this.toHome()}
              className="lingk-upperleft"
              style={{
                margin: 10,
                marginTop: 20,
                marginBottom: 4,
                width: "fit-content",
                fontSize: "18px",
              }}
            >
              <img role="presentation" src={images.lingk} style={styles.logo} />
              {!fold ? (
                <div
                  className="home-button"
                  style={{
                    position: "absolute",
                    fontSize: 11,
                    left: 45,
                    top: -1,
                    fontWeight: "bold",
                  }}
                >
                  <span>HOME</span>
                </div>
              ) : null}
            </div>
            {/*!fold && 
            <span style={{position: 'absolute', color: '#fff',top: 0, left: 50, fontSize: 10}}>Workspace</span>
            */}
            <ul style={{ listStyle: "none", padding: 0 }}>
              {items.map((item, index) => {
                return (
                  <li key={index}>
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      <li
                        onClick={() =>
                          this.choose(index, item.pathname, item.action)
                        }
                        className={
                          this.state.selected === index
                            ? "selected-sidenav-item"
                            : "sidenav-item sidenav-li"
                        }
                        style={{
                          background:
                            this.state.selected === index && navcolor
                              ? navcolor + "b9"
                              : this.state.selected === index
                              ? null
                              : null,
                        }}
                      >
                        <div style={{ paddingLeft: 20, fontSize: "13px" }}>
                          <img
                            role="presentation"
                            src={item.image}
                            style={{
                              width: 16,
                              height: 16,
                              display: "inline-block",
                              margin: "auto",
                              marginRight: 8,
                              marginTop: -2,
                            }}
                          />
                          <div
                            className="tab-titles"
                            style={{ transition: "none", fontWeight: "bold" }}
                          >
                            {item.title}
                          </div>

                          {!fold && (
                            <div
                              className="sidenav-item-carrot"
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginTop: -5,
                                right: 30,
                                height: 1,
                                width: 16,
                                position: "absolute",
                              }}
                            >
                              {item.action && (
                                <img
                                  className="sidenav-item-carrot"
                                  role="presentation"
                                  src={images.chevron_right}
                                  style={{
                                    filter: "invert(60%)",
                                    transform: unfolded.includes(index)
                                      ? "rotate(270deg)"
                                      : "rotate(90deg)",
                                  }}
                                  height="26px"
                                />
                              )}
                              <div
                                className="sidenav-elevio-hook"
                                style={{
                                  position: "absolute",
                                  bottom: -19,
                                  right: -10,
                                }}
                              />
                            </div>
                          )}
                        </div>

                        {item.counter && <div style={{ float: "right" }}></div>}
                      </li>

                      {item.action &&
                        item.action.map((o, i) => {
                          const selected = this.state.currentPage === o;
                          return (
                            <li
                              key={i}
                              className="nested-sidebar"
                              onClick={() =>
                                this.actionPath(item.actionpath[i], o, index)
                              }
                              style={{
                                height: unfolded.includes(index) ? 34 : null,
                                textAlign: fold ? "center" : null,
                              }}
                            >
                              {!fold ? (
                                <div
                                  className="nested-item"
                                  style={{
                                    fontWeight: selected ? "bold" : 100,
                                    color: selected ? "#fff" : null,
                                  }}
                                >
                                  ⌙ {o}
                                </div>
                              ) : (
                                <div
                                  className="nested-item-small"
                                  style={{
                                    fontWeight: selected ? "bold" : 100,
                                    color: selected ? "#fff" : null,
                                    marginTop: 10,
                                  }}
                                >
                                  {item.shortNames[i]}
                                </div>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>

          <Favicon
            url={
              !favicon.icon
                ? ["/favicon.ico"]
                : [images.descriptiontop, images.descriptiontopstar]
            }
            alertCount={favicon.alertCount ? favLength : null}
          />
        </div>
      </div>
    );
  }
}

//alertCount={favicon.alertCount ? (logsToShow.length+1) + '/' + favicon.alertCount : '...'}
