import React, { Component } from 'react';
import images from '../images'
import {Button,DropdownButton,MenuItem} from 'react-bootstrap'
import CouponEntry from '../recurlySubscription/couponEntry'
import PurchasePreviewModal from '../user/purchasePreviewModal'


const titleStyles = {
  fontSize: '22px',
  marginBottom: '5px',
}



function getAllIndexes(arr, val) {
    var indexes = [], i = -1;
    while ((i = arr.indexOf(val, i+1)) != -1){
        indexes.push(i);
    }
    return indexes;
}

export default class SubscriptionEdit extends Component {

  constructor() {
    super();
    this.state={
      recurlyAddons:null,
      selectedPlan:null,
      selectedAddons:[],
      additions:[],
      couponCode: '',
      canceling: false,
      posting: false,
      postingModal:false,
      disableCoupon:false,
      couponState:null,
      applyCoupon: false,
      redeeming: false,
      preview: null,
      previewModal: false,


    }
  }

  componentDidMount(){
    const { subscription, recurlyPlans, subMode } = this.props
    const planCode = subscription && subscription.plan.Code
    const addOns = subscription && subscription.addOns

    if (planCode){
    
      if (planCode.includes('internal')){
        alert("Your current subscription includes a custom discount. Please contact Lingk support to modify your subscription.")
        subMode(null)
        return
      }

      const p = recurlyPlans.find(f=>f.Code===planCode)
      this.props.actions.getRecurlyAddons(planCode)
        .then((d)=>{

            const selectedAddons = []
            const additions = []

            if (addOns) {
                addOns.map((o)=>{
                const a = d.find(f=>f.Code===o.Code)
                selectedAddons.push(a)
                for (let i = 0; i < o.Quantity; i++){
                  additions.push(a.Name)
                }
              })
            }

          this.setState({recurlyAddons:d, 
            selectedPlan: p,
            selectedAddons,
            additions
          })
        })
    }    
}

componentWillMount(){
  const { subscription } = this.props
  const addOns = subscription && subscription.addOns
      const selectedAddons = []
      const additions = []

      addOns && addOns.map((o)=>{
        selectedAddons.push(o)
        for (let i = 0; i < o.Quantity; i++){
          additions.push(o.Code)
        }
      })

      this.setState({selectedAddons, additions})

}

  handleSelectPlan = (e) => {

  const selectedPlan = this.props.recurlyPlans.find(f=>f.Code === e.Code)
  this.setState({selectedPlan, recurlyAddons:null, selectedAddons:[], additions:[]})
    this.props.actions.getRecurlyAddons(selectedPlan.Code)
    .then((data)=>{
      // console.log(data)
      this.setState({recurlyAddons:data})
  })
 
}

handleAddons = (addon) => {
  let { selectedAddons,additions } = this.state

  if (!selectedAddons.includes(addon)){
    selectedAddons.push(addon)
    additions.push(addon.Name)
  }
  else{
      while (getAllIndexes(additions, addon.Name).length > 0){
        const indexes = getAllIndexes(additions, addon.Name)
        additions.splice(indexes[0],1) 
      }

    const i = selectedAddons.findIndex(f=>f.Code===addon.Code)
    selectedAddons.splice(i,1)

  }

  this.setState({selectedAddons,additions})

  }

subtractAdditions = (e) => {
  let { additions } = this.state

    const target = additions.findIndex(f=>f === e)
    const quantity = getAllIndexes(additions, e)

    if (target !== -1 && quantity.length > 1) {
      additions.splice(target, 1)
      this.setState({additions})
    }

}

addAdditions = (e) => {
  let { additions } = this.state

    additions.push(e)

    this.setState({additions})
}

  updateSubscription = (update) => {
    const { subscription, tenantId, isAdmin, switchTenant, billing, redemptions } = this.props
    const { selectedPlan, selectedAddons, additions } = this.state

     const addons = selectedAddons.map((o)=>{
        const quantity = getAllIndexes(additions, o.Name)
        return {
          addonCode: o.Code,
          addonQuantity: quantity.length,
          //unitAmountInCents: o.UnitAmountInCents.USD
        }
      })

     let data = {
      accountCode: subscription.accountCode,
      uuid: subscription.uuid,
      addOns: addons,
      planCode: selectedPlan.Code,
      }

      if (billing && billing.cardType){
        //no preview yet, broken for same sub preview
            if(update){
              this.setState({postingModal:true})
                this.props.actions.updateRecurlySubscription(data)
                .then((resp)=>{
                  if (!resp){
                    console.log('failed')
                    this.setState({postingModal:false})
                    return
                  }
                  else{
                    console.log('success')
                    switchTenant(tenantId, isAdmin)

                  }

                })
            }
            else{

              const previewcoupon = redemptions && redemptions.length > 0 ? redemptions[0].coupon_code : ''

              const data = {
              accountCode: subscription.accountCode,
              uuid: subscription.uuid,
              addOns: addons,
              planCode: selectedPlan.Code,
              couponCode: previewcoupon,
              }
             //  this.setState({posting:true})
             //  this.props.actions.previewRecurlyPurchase(data)
             // .then((p)=>{
              // console.log(p)  
              this.setState({preview: 'p', posting: false, previewModal:true})
              // })
            }
          }
      else {
        alert('Please add a payment method.')
        this.setState({posting: false})
      }
    }

updateCoupon = (e) => {
  if (this.state.disableCoupon){
    return
  }

  this.setState({couponCode:e})
}

  
getCoupon = () => {
  let { disableCoupon } = this.state
  if (disableCoupon){
    this.setState({
      couponCode:'',
      couponState:null,
      disableCoupon: false
    })
    return
  }
  
  this.props.actions.getRecurlyCoupon(this.state.couponCode)
  .then((a)=>{
    // console.log(a)
    if (a==='Invalid'){
      this.setState({couponState: a})
    } else{
      this.setState({couponState: a,
      disableCoupon: true})
    }

    })
    

}

redeem = (couponCode, accountCode, subUUID) => {
  const { tenantId, isAdmin, switchTenant } = this.props
  this.setState({redeeming:true})
  this.props.actions.redeemToRecurlySubscription(couponCode, accountCode, subUUID)
  .then(()=>{
  switchTenant(tenantId, isAdmin)
  })
}

triggerPreviewModal = () => {
  let { previewModal } = this.state
  previewModal = !previewModal
  this.setState({ previewModal })
}

  render() {
    const { selectedPlan, recurlyAddons, selectedAddons, additions, posting, 
      disableCoupon, couponState, couponCode, previewModal, applyCoupon, redeeming, preview, postingModal } = this.state
    const { recurlyPlans, redemptions, subscription } = this.props   
    const addons = []
    const includedAddons = []
    let totalPrice = selectedPlan && selectedPlan.UnitAmountInCents.USD

    const plans = recurlyPlans && recurlyPlans.map((o,i)=>{
      if (subscription.plan.Code.includes('internal')){
          if (!o.Code.includes('service')){
            const cost = (o.UnitAmountInCents.USD/100).toLocaleString("en-US", {style:"currency", currency:"USD"})
            return(
              <MenuItem value={o.Name} key={i}
              onClick={()=>this.handleSelectPlan(o)}>
              <strong>{o.Name} {cost}</strong>
            </MenuItem>
            )
          }
      }
      else if (!o.Code.includes('service') && !o.Code.includes('internal')){
        const cost = (o.UnitAmountInCents.USD/100).toLocaleString("en-US", {style:"currency", currency:"USD"})
        return(
          <MenuItem value={o.Name} key={i}
          onClick={()=>this.handleSelectPlan(o)}>
          <strong>{o.Name} {cost}</strong>
        </MenuItem>
        )
      }
      })

    const interval = selectedPlan && selectedPlan.IntervalLength
    //const price = selectedPlan && (selectedPlan.UnitAmountInCents.USD/100).toLocaleString("en-US", {style:"currency", currency:"USD"})

     if (recurlyAddons) {

      recurlyAddons.sort((a,b)=>{
        if (a.UnitAmountInCents.USD < b.UnitAmountInCents.USD) return -1
        else if (a.UnitAmountInCents.USD > b.UnitAmountInCents.USD) return 1
        return 0
      })

        recurlyAddons.map((o,i)=>{

        const quantity = getAllIndexes(additions, o.Name)
        let price = o.UnitAmountInCents.USD
        const included = !(price > 0) 
        // price = price*discount
        const selected = selectedAddons && selectedAddons.includes(o)
        const showQuantity = o.DisplayQuantityOnHostedPage.Bool === true

        if (!included && quantity.length > 1){
          price = (price*quantity.length)
        }

        let formatPrice = (price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})

        // console.log(o)
        
        if (included){
          includedAddons.push(
          <div key={i} style={{display:'inline-block', color: '#337ab7',fontWeight: 'bold'}}>
              <span htmlFor={`addon-${o.Code}`}>{o.Name}</span>
          </div>
          )
        }

        else {
          addons.push(
          <div key={i} style={{margin:2, marginBottom: 7, display:'block'}}>
          
          <span>
          <input style={{display:'inline-block'}} onChange={()=>{this.handleAddons(o)}} value="1" checked={selected} type="checkbox" data-recurly="addon" name="addon" data-recurly-addon={o.Code} id={`addon-${o.Code}`} />
          </span>
              <span style={{marginLeft: 6, display:'inline-block', width: 'auto'}} htmlFor={`addon-${o.Code}`}>&nbsp;{o.Name}&nbsp;
                </span>

                { showQuantity && selected && 
                  <span className="add-subtract-addon">({quantity ? quantity.length : '1'})&nbsp;&nbsp;
                  <Button style={{marginTop:-3, marginRight: 2, height:20, width: 16}} onClick={()=>this.subtractAdditions(o.Name)}>
                  <img
                  src={images.ic_remove_black}
                  height="18px"
                  style={{marginLeft:-9, marginTop:-14}}
                  />
                  </Button>
                  <Button bsStyle="primary" style={{marginTop:-3, height:20, width: 16 }} onClick={()=>this.addAdditions(o.Name)}>
                  <img
                  src={images.ic_add_white}
                  height="18px"
                  style={{marginLeft:-9, marginTop:-14}}
                  />
                  </Button>
                  </span>
                }
                
                <div style={{float:'right'}}>
                 <span data-recurly="currency_symbol"></span>
                 <span style={{textDecoration: !selected ? 'line-through' : null, fontWeight: selected ? 'bold' : null}} data-recurly="addon_price" data-recurly-addon={o.Code}>
                 {formatPrice} USD</span>
                 </div>       
          </div>
          )
        }
        
      
      })
    }
    

    if (selectedAddons && selectedAddons.length > 0){

        selectedAddons.sort((a,b)=>{
          if (a.UnitAmountInCents.USD < b.UnitAmountInCents.USD) return -1
          else if (a.UnitAmountInCents.USD > b.UnitAmountInCents.USD) return 1
          return 0
        })

    selectedAddons.map((o)=>{
        let price = o.UnitAmountInCents.USD
        const quantity = getAllIndexes(additions, o.Name)
              if (quantity !== -1){
                price = price * quantity.length
                totalPrice+=price
              }
            })

      }

    let percentdiscount = 1
    let fixeddiscount = 0

    redemptions && redemptions.map((o)=>{
      if (o.discount_type === 'percent'){
        percentdiscount = ((100 - o.discount_percent) / 100)
      }
      else if (o.discount_type === 'dollars'){
        fixeddiscount += o.discount_in_cents.USD
      }
    })

    let finalPrice = totalPrice*percentdiscount-fixeddiscount

    if (finalPrice < 0){
      finalPrice = 0
    }

    const redList = redemptions && redemptions.map((o,i)=>{
          return(
          <div key={i} style={{listStyle:'none'}}>
          {o.name}&nbsp;
          {o.discount_type === 'percent' &&
          <span>({o.discount_percent}% OFF)</span>
          }
          {o.discount_type === 'dollars' &&
          <span>(-{(o.discount_in_cents.USD/100).toLocaleString("en-US", {style:"currency", currency:"USD"})})</span>
          }
          {o.discount_type === 'free_trial' &&
          <span>Free Trial Extension</span>
          }
          </div>
          )
        })

    
    return (
      <div style={{position:'relative'}}>
        <div style={titleStyles}>Update Plan</div>
        <div className="">
        View plan details <a target="_blank" href="https://www.lingk.io/pricing/">here</a>.
        </div>
        <br/>
        
      {selectedPlan ? 
        <div>
        <div>
          {selectedPlan &&
            <div>
            <div style={{display:'inline-block', textAlign:'left', width:'100%'}}>
          <span className="labelz" style={{fontSize:'11px'}}>
            Select a Plan
          </span>
          </div>
          <br />
            <DropdownButton bsStyle='default' id="plans" placeholder="Plans"
          title={selectedPlan && selectedPlan.Name || 'Select Plan'}>
            {plans}
          </DropdownButton>
              {/*<span style={{fontSize:16}}>&nbsp;&nbsp;{price} USD</span>*/}
              <div>
              
          {

            // includedAddons.length > 0 ? 

            // includedAddons.map((o,i)=>{
            //   return(
            //     <span key={i}>
            //     {o}
            //     <span>{i !== (includedAddons.length - 1) && <span>,&nbsp;</span>}</span>
            //     </span>)
            // }) 
            // : 
            // <span><img alt="spinner" src={images.ajax_loader} /></span>

          }
          </div>
            </div>
          }
        </div>

        

        <br/>
        <div style={{background: '#f1f1f1',borderRadius: 6, border: '1px solid #aaa', padding:10}}>
        <div style={{marginBottom:10}}><b>Custom Options</b></div>
          {addons ? 
            addons : 
          <div>
          <img alt="spinner" src={images.ajax_loader} />
          </div>
          }
        </div>

        <br/>

        
        
      
      <div style={{marginBottom:6}}><b>Subtotal</b></div>
        <div style={{marginBottom:16}}>
              <span>{(totalPrice/100).toLocaleString("en-US", {style:"currency", currency:"USD"})} USD / {interval > 1 ? <span>{interval} Months</span> : <span>1 Month</span>}</span>
        </div>

      
        <div style={{marginBottom:16}}>
        <div style={{marginBottom:6}}><b>Discount</b></div>
        <div>
        {redList && redList.length > 0 ? redList : <span>None</span>}
        </div>

        

        { applyCoupon ?
        <div>
      <CouponEntry 
          isSubForm={false}
          disableCoupon={disableCoupon}
          couponState={couponState}
          couponCode={couponCode}
          updateCoupon={this.updateCoupon}
          getCoupon={this.getCoupon}
          accountCode={subscription.accountCode}
          subUUID={subscription.uuid}
          redeem={this.redeem}
          redeeming={redeeming}
          />
          </div>
          :
        <div onClick={()=>this.setState({applyCoupon:true})} className="apply-coupon">
        {redList && redList.length > 0 ? <span>+Replace Coupon</span> : <span>+Apply Coupon</span>}
        </div>
          }
        
        </div>

      <div style={{marginBottom:6}}><b>Total Price</b></div>
        <div style={{marginBottom:30}}>
              <span>{(finalPrice/100).toLocaleString("en-US", {style:"currency", currency:"USD"})} USD / {interval > 1 ? <span>{interval} Months</span> : <span>1 Month</span>}</span>
        </div>

        
       <div className="">
        <div style={{width:'100%'}}>
          <Button
            style={{display:'inline-block', width: 120}}
            bsSize="small"
            bsStyle="primary"
            disabled={posting}
            onClick={()=>this.updateSubscription(false)}>
            {posting ? <img alt="spinner" src={images.ajax_loader} /> : <span><b>Payment Preview</b></span>}
          </Button>
          </div>
  
       </div>
       </div>
          
          :
        
          <div>
          {/*loading...*/}
          <img alt="spinner" src={images.ajax_loader} />
          </div>

        }

        <br />

        
        <PurchasePreviewModal
        finalPrice={finalPrice}
        selectedPlan={selectedPlan}
        modal={previewModal}
        message="This amount will be charged to your stored payment method at the beginning of the billing cycle."
        posting={postingModal}
        purchase={this.updateSubscription}
        preview={preview}
        triggerModal={this.triggerPreviewModal}
        />  

      </div>
    );
  }
}


