export const TestConnection = (providerConfig, creds) => {
  let doTesting = false;

  const notRequired =
    (providerConfig.credentials &&
      providerConfig.credentials.length === 1 &&
      providerConfig.credentials[0] === "Oauth") ||
    providerConfig.type === "BrightSpaceValenceApi" ||
    providerConfig.type === "DocuSign" ||
    providerConfig.type == "LocalFile";
  if (notRequired) {
    doTesting = true;
    return doTesting;
  }

  let arrayCreds = providerConfig.credentials;
  if (providerConfig.type == "SFTP") {
    arrayCreds =
      providerConfig &&
      providerConfig.credentials.filter((o) => {
        if (o === "privateKey") {
          let i = "ssh-key";
          let j = "two-Factor Auth";
          if (creds["credentialType"] === i || creds["credentialType"] === j) {
            return true;
          } else {
            return false;
          }
        }
        if (o == "passphrase") { // omitting passphrase for ssh-key
          let i = "ssh-key";
          let j = "two-Factor Auth";
          if (creds["credentialType"] === j) {
            return true;
          } else {
            return false;
          }
        }
        if (o === "password") {
          let i = "ssh-key";
          if (creds["credentialType"] === i) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      });
  }
  if (providerConfig.type == "LingkAdapter" && creds.rootUrl != "") {
    doTesting = true;
  }
  if (arrayCreds) {
    const hasAllKeys = arrayCreds.every((item) => creds.hasOwnProperty(item));
    if (hasAllKeys) {
      doTesting = !Object.values(creds).includes("");
    }
  }

  return doTesting;
};
