import * as types from "./types";

export function callMainProjectInfo(appId, tenantInfo) {
  return (dispatch) =>
    Promise.all([
      dispatch(callGetAppInfo(appId)),
      dispatch(callGetResourceTypes(appId)),
    ]).then(() => {
      dispatch(callOtherProjectInfo(tenantInfo));
      return true;
    });
}

export function callOtherProjectInfo(tenantInfo) {
  return (dispatch, getState) => {
    const { project } = getState();
    return Promise.all([
      dispatch(
        callGetProductizedIntegrationTypesForTenant(tenantInfo.TenantId)
      ),
      dispatch(
        callGetUsage(
          tenantInfo.Key,
          project.appData.uniquekey,
          project.resourceTypes
        )
      ),
      dispatch(
        callGetBulkUploadsOneRecord(tenantInfo.Key, project.appData.uniquekey)
      ),
      dispatch(callGetBulkUploads(0)),
    ]);
  };
}

export function callGetProductizedIntegrationTypesForTenant(tenantId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/integrationtypes/adopted`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_TENANT_WIZARD_TYPES,
          data,
        });
      });
  };
}

export function callGetFeatures(tenantId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get(`${tenantId}/features`, {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_FEATURES,
          data,
        });
      });
  };
}

export function setAppInfo(data, tenantId) {
  return (dispatch, getState) => {
    const { main } = getState();
    const tenant = main.tenantList.find((t) => t.TenantId === tenantId);
    dispatch({
      type: types.SET_TENANTINFO,
      data: tenant,
    });
    dispatch({
      type: types.SET_APPDATA,
      data,
    });
  };
}

export function callGetAppInfo(appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get(`appinfo/${appId}`, {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_APPDATA,
          data,
        });
      });
  };
}

export function callGetThreashold(appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get(`appinfo/${appId}`, {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_APPDATA,
          data,
        });
      });
  };
}

export function callUpdateApp(tenantId, data, appId) {
  return (dispatch, getState, api) => {
    const { config, main } = getState();
    const { API_URL } = config;
    return api
      .post(`${tenantId}/applications/update/${appId}`, {
        endpoint: API_URL,
        data,
      })
      .then((d) => {
        dispatch(callGetAppInfo(appId));
        dispatch({
          type: types.UPDATE_APPLICATIONS_APP,
          data,
          appId,
          appList: main.appList,
        });
        return d;
      });
  };
}

export function callUpdateDPT(tenantId, data, appId) {
  return (dispatch, getState, api) => {
    const { config, main } = getState();
    const { API_URL } = config;
    return api
      .patch(
        `tenants/${tenantId}/apps/${appId}/max-data-proc-time?maxDataProcTime=${data.threasholdHours}:${data.threasholdMinutes}:00`,
        {
          endpoint: API_URL,
        }
      )
      .then((d) => {
        dispatch(callGetAppInfo(appId));
        return d;
      });
  };
}

export function callGetTransformerLogsForWorkspace(
  tenantId,
  appId,
  offset,
  state,
  duration,
  startTime,
  endTime
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    if (duration != "") {
      if (startTime != "" && endTime != "") {
        let uri = `${tenantId}/logs/filtered/transformers/app/${appId}?duration=${duration}&state=${state}&endTime=${endTime}&startTime=${startTime}`;
        const encoded = encodeURI(uri);
        return api
          .get(encoded, {
            endpoint: TRANS_API_URL,
          })
          .then((data) => {
            dispatch({
              type: types.SET_WORKSPACE_TRANSFORMER_LOGS,
              data,
            });
            return data;
          });
      } else {
        return api
          .get(
            `${tenantId}/logs/filtered/transformers/app/${appId}?duration=${duration}&state=${state}`,
            {
              endpoint: TRANS_API_URL,
            }
          )
          .then((data) => {
            dispatch({
              type: types.SET_WORKSPACE_TRANSFORMER_LOGS,
              data,
            });
            return data;
          });
      }
    } else if (state != "") {
      return api
        .get(
          `${tenantId}/logs/filtered/transformers/app/${appId}?offset=${offset}&limit=10&state=${state}`,
          {
            endpoint: TRANS_API_URL,
          }
        )
        .then((data) => {
          dispatch({
            type: types.SET_WORKSPACE_TRANSFORMER_LOGS,
            data,
          });
          return data;
        });
    } else {
      return api
        .get(
          `${tenantId}/logs/transformers/app/${appId}?offset=${offset}&limit=10`,
          {
            endpoint: TRANS_API_URL,
          }
        )
        .then((data) => {
          dispatch({
            type: types.SET_WORKSPACE_TRANSFORMER_LOGS,
            data,
          });
          return data;
        });
    }
  };
}

export function callGetTransformerLogsFirst100Workspace(tenantId, appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/logs/transformers/app/${appId}?limit=100`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_WORKSPACE_TRANSFORMER_LOGS_100,
          data,
        });
        return data;
      });
  };
}

export function callGetTransformerLogsTotalCountForWorkspace(tenantId, appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/count/executions`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_WORKSPACE_TRANSFORMER_LOGS_COUNT,
          data,
        });
        return data;
      });
  };
}

export function getUsersForWorkspace(tenantId, appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/permissions`, {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_WORKSPACE_PERMISSIONS,
          data,
        });
        return data;
      });
  };
}

export function removeUserFromWorkspace(tenantId, appId, userId) {
  return (dispatch, getState, api) => {
    const { config, project } = getState();
    const { API_URL } = config;
    return api
      .delete(`${tenantId}/${appId}/permissions/${userId}`, {
        endpoint: API_URL,
      })
      .then((removedUserId) => {
        const users = [...project.permissions];
        dispatch({
          type: types.SET_WORKSPACE_PERMISSIONS,
          data: users.filter(
            (u) => u.Identity && u.Identity.id !== removedUserId
          ),
        });
        return removedUserId;
      });
  };
}

//connected experience creator
export function callCreateConnectedExp(tenantId, appId, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .post(`${tenantId}/${appId}/connectedexperience`, {
        endpoint: TRANS_API_URL,
        data,
      })
      .then((r) => {
        return dispatch(callGetConnectedExperiences(tenantId, appId)).then(
          () => {
            return r;
          }
        );
      });
  };
}

export function callDeleteConnectedExp(tenantId, appId, expId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .delete(`${tenantId}/${appId}/connectedexperience/${expId}`, {
        endpoint: TRANS_API_URL,
      })
      .then((r) => {
        return dispatch(callGetConnectedExperiences(tenantId, appId)).then(
          () => {
            return r;
          }
        );
      });
  };
}

export function callGetConnectedExperiences(tenantId, appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/connectedexperiences`, {
        endpoint: TRANS_API_URL,
      })
      .then((r) => {
        return dispatch({
          type: types.SET_CONNECTEDEXP_DATA,
          data: r,
        });
      });
  };
}

export function callGetDeploymentsForWorkspace(tenantId, appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/deployments`, {
        endpoint: TRANS_API_URL,
      })
      .then((r) => {
        return dispatch({
          type: types.SET_ALL_DEPLOYMENTS,
          data: r,
        });
      });
  };
}

//comments

export function callGetAllComments(tenantId, appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/connectedexperiencecomments`, {
        endpoint: TRANS_API_URL,
      })
      .then((r) => {
        return dispatch({
          type: types.SET_ALL_COMMENTS,
          data: r,
        });
      });
  };
}

export function callPostExpComment(tenantId, appId, expId, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;

    return api
      .post(`${tenantId}/${appId}/connectedexperience/${expId}/comment`, {
        endpoint: TRANS_API_URL,
        data,
      })
      .then((r) => {
        return dispatch(callGetExpComments(tenantId, appId, expId)).then(() => {
          return r;
        });
      });
  };
}

export function callGetExpComments(tenantId, appId, expId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/connectedexperience/${expId}/comments`, {
        endpoint: TRANS_API_URL,
      })
      .then((r) => {
        return dispatch({
          type: types.SET_CONNECTEDEXP_COMMENTS,
          data: r,
        });
      });
  };
}

export function callDeleteExpComment(tenantId, appId, expId, commentId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .delete(
        `${tenantId}/${appId}/connectedexperience/${expId}/comment/${commentId}`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then((r) => {
        return dispatch(callGetExpComments(tenantId, appId, expId)).then(() => {
          return r;
        });
      });
  };
}

export function clearExpComments() {
  return {
    type: types.SET_CONNECTEDEXP_COMMENTS,
    data: null,
  };
}

export function inviteUsersToWorkspace(tenantId, appId, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .post(`${tenantId}/${appId}/permissions`, {
        endpoint: API_URL,
        data,
      })
      .then(() => {
        return dispatch(getUsersForWorkspace(tenantId, appId)).then((r) => {
          return r;
        });
      });
  };
}

export function updateUserAdminState(tenantId, appId, userId, isAdmin) {
  return (dispatch, getState, api) => {
    const { config, project } = getState();
    const { API_URL } = config;
    return api
      .put(`${tenantId}/${appId}/permissions/${userId}`, {
        endpoint: API_URL,
        data: { isAdmin },
      })
      .then((data) => {
        const permissions = [...project.permissions];
        const u = permissions.find((p) => {
          return p.identityId === userId;
        });
        u.isAdmin = data.isAdmin;
        dispatch({
          type: types.SET_WORKSPACE_PERMISSIONS,
          data: permissions,
        });
        return data;
      });
  };
}

export function callGetApiHubDetail(hubId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get(`apihubs/${hubId}`, {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_APIHUB_DETAIL,
          data,
        });
      });
  };
}

export function callGetResourceMeta(resourceId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get(`resourcemetas/${resourceId}`, {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_RESOURCE_META,
          data,
        });
      });
  };
}

export function clearResourceMeta() {
  return (dispatch) => {
    const data = [];
    dispatch({
      type: types.SET_RESOURCE_META,
      data,
    });
  };
}

export function changeDescription(value) {
  return (dispatch, getState) => {
    const { project } = getState();
    const { appData } = project;
    dispatch({
      type: types.CHANGE_DESCRIPTION,
      value,
      appData,
    });
  };
}

export function callGetTenantCreds(appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get(`tenantcreds/${appId}`, {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_TENANTCREDS,
          data,
        });
      });
  };
}

export function deleteCredentialFromEnvironment(
  tenantId,
  appId,
  providerLabel,
  connectionKey,
  environment
) {
  return (dispatch, getState, api) => {
    const { config, project } = getState();
    // if it was just added then remove it!!!!
    const environmentCredentials = [...project.environmentCredentials];
    const cred = environmentCredentials.find(
      (ec) => ec.providerLabel === providerLabel
    );
    if (cred && cred.wasJustAdded === true) {
      const envCreds = environmentCredentials.filter(
        (ec) => ec.key !== connectionKey
      );
      return dispatch({
        type: types.SET_ENV_CREDS,
        data: envCreds,
      });
    }
    // or delete it from DB
    const { TRANS_API_URL } = config;
    return api
      .delete(
        `${tenantId}/${appId}/credentials/${providerLabel}/${connectionKey}/${environment.id}`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then(() => {
        const creds = [...project.environmentCredentials];
        const envCreds = creds.filter((ec) => ec.key !== connectionKey);
        dispatch({
          type: types.SET_ENV_CREDS,
          data: envCreds,
        });
      });
  };
}

export function callGetResourceTypes(appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get(`resourcetypes/${appId}`, {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_RESOURCETYPES,
          data,
        });
      });
  };
}

export function callGetTransformers(appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`transformers/${appId}`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_TRANSFORMERS,
          data,
        });
      });
    return data;
  };
}

export function showAppExitPrompt(data) {
  return (dispatch, getState, api) => {
    dispatch({
      type: types.SET_SHOW_APP_EXIT_PROMPT,
      data,
    });
  };
}

export function callGetUsage(tenantKey, projectKey, resourceTypes) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api
      .get(`${tenantKey}/${projectKey}/usage`, {
        endpoint: DATA_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_USAGE,
          data,
          resourceTypes,
        });
      });
  };
}

export function callGetExplorerData(baseUrl, param, offset) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api
      .get(`${baseUrl}${param}?limit=25&offset=${offset}`, {
        endpoint: DATA_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_EXPLORER_DATA,
          data,
        });
      });
  };
}

export function clearExplorerData() {
  return (dispatch) => {
    const data = [];
    dispatch({
      type: types.SET_EXPLORER_DATA,
      data,
    });
  };
}

export function callGetInvalidData(baseUrl) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api
      .get(baseUrl, {
        endpoint: DATA_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_INVALID_DATA,
          data: data.data,
        });
        dispatch({
          type: types.SET_INVALID_COUNT,
          count: data.count,
        });
      });
  };
}

export function clearInvalidData() {
  return (dispatch) => {
    const data = [];
    const count = 0;
    dispatch({
      type: types.SET_INVALID_DATA,
      data,
    });
    dispatch({
      type: types.SET_INVALID_COUNT,
      count,
    });
  };
}

export function setLastBulkUpload(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_LAST_BULK_UPLOAD,
      data,
    });
  };
}

export function callGetBulkUploadsOneRecord(tenantKey, projectKey) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api
      .get(`${tenantKey}/${projectKey}/bulkuploads?limit=1`, {
        endpoint: DATA_API_URL,
      })
      .then((data) => {
        dispatch(setLastBulkUpload(data.data));
      });
  };
}

export function callGetBulkUploads(offset) {
  return (dispatch, getState, api) => {
    const { config, project } = getState();
    const { DATA_API_URL } = config;
    const { allBulkUploads } = project;
    if (project && project.tenantInfo) {
      return api
        .get(
          `${project.tenantInfo.Key}/${project.appData.uniquekey}/bulkuploads?limit=50&offset=${offset}`,
          {
            endpoint: DATA_API_URL,
          }
        )
        .then((data) => {
          dispatch({
            type: types.SET_BULK_UPLOADS,
            data,
            allBulkUploads,
          });
        });
    }
  };
}

export function callGetProjectMetadataDefinition(tenantKey, appKey) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api
      .get(`${tenantKey}/${appKey}/projectmetadatadefinition`, {
        endpoint: DATA_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_CUSTOM_ALLOWED_FIELDS,
          data,
        });
      });
  };
}

export function callGetProjectMetadata(tenantKey, appKey) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api
      .get(`${tenantKey}/${appKey}/projectmetadata`, {
        endpoint: DATA_API_URL,
      })
      .then((data) => {
        const metadata = data.metadata;
        dispatch({
          type: types.SET_CUSTOM_METADATA,
          metadata,
        });
      });
  };
}

export function changeProjectMetadata(field, value) {
  return (dispatch, getState, api) => {
    const { project } = getState();
    var metadata = { ...project.metadata };
    metadata[field] = value;
    dispatch({
      type: types.SET_CUSTOM_METADATA,
      metadata,
    });
  };
}

export function callPostProjectMetadata(tenantKey, appKey) {
  return (dispatch, getState, api) => {
    const { config, project } = getState();
    const { DATA_API_URL } = config;
    const data = { metadata: project.customMetadata };
    return api.post(`${tenantKey}/${appKey}/projectmetadata`, {
      endpoint: DATA_API_URL,
      data,
    });
  };
}

export function callGetRecommendedActions(tenantAccessLevel) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`recommendedactions/${tenantAccessLevel}`, {
        endpoint: config.API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_RECOMMENDED_ACTIONS,
          data,
        });
      });
  };
}

export function createRecipeFromTemplate(tenantId, appId, templateId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    let createdRecipe = null;
    return api
      .post(`${tenantId}/${appId}/transformers/template/${templateId}`, {
        endpoint: TRANS_API_URL,
      })
      .then((r) => {
        createdRecipe = r;
        return dispatch(callGetTransformers(appId));
      })
      .then(() => {
        return createdRecipe;
      });
  };
}

export function clearBulkUploads() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_BULK_UPLOADS,
    });
  };
}

export function clearProjectData() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_PROJECT_DATA,
    });
  };
}

export function setDataTabsSelected(i) {
  return (dispatch) => {
    dispatch({
      type: types.SET_DATA_TABS_SELECTED,
      i,
    });
  };
}

export function postDataMapping(tenantId, appId, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .post(`${tenantId}/${appId}/datamapping`, {
        endpoint: config.TRANS_API_URL,
        data,
      })
      .then(() => {
        return dispatch(callGetDataMappings(tenantId, appId));
      });
  };
}

export function updateDataMappingPublicity(
  tenantId,
  appId,
  mapperId,
  isPublic
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .put(`${tenantId}/${appId}/datamapping/${mapperId}`, {
        endpoint: config.TRANS_API_URL,
        data: { isPublic },
      })
      .then(() => {
        return dispatch(callGetDataMappings(tenantId, appId));
      });
  };
}

export function callGetDataMappings(tenantId, appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/datamappings`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        return dispatch({
          type: types.SET_DATA_MAPPINGS,
          data,
        });
      });
  };
}
export function callGetAnalyticsDashboard(tenantId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`analytics/${tenantId}`, {
        endpoint: config.API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_ANALYTICS_DASHBOARD_URL,
          data,
        });
      });
  };
}
export function callDeleteDataMapping(tenantId, appId, mapperId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .delete(`${tenantId}/${appId}/datamapping/${mapperId}`, {
        endpoint: TRANS_API_URL,
      })
      .then(() => {
        return dispatch(callGetDataMappings(tenantId, appId));
      });
  };
}

export function callPostMapperComment(tenantId, appId, mapperId, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;

    return api
      .post(`${tenantId}/${appId}/datamapping/${mapperId}/comment`, {
        endpoint: TRANS_API_URL,
        data,
      })
      .then((r) => {
        return dispatch(callGetMapperComments(tenantId, appId, mapperId)).then(
          () => {
            return r;
          }
        );
      });
  };
}

export function callGetMapperComments(tenantId, appId, mapperId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/datamapping/${mapperId}/comments`, {
        endpoint: TRANS_API_URL,
      })
      .then((r) => {
        return dispatch({
          type: types.SET_MAPPER_COMMENTS,
          data: r,
        });
      });
  };
}

export function callDeleteMapperComment(tenantId, appId, mapperId, commentId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .delete(
        `${tenantId}/${appId}/datamapping/${mapperId}/comment/${commentId}`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then((r) => {
        return dispatch(callGetMapperComments(tenantId, appId, mapperId)).then(
          () => {
            return r;
          }
        );
      });
  };
}

export function clearMapperComments() {
  return {
    type: types.SET_MAPPER_COMMENTS,
    data: null,
  };
}
