import React, { useState, useEffect, useRef } from "react";
import images from "../images";
import CSSTransitionGroup from "react-addons-css-transition-group";
import LogResultTable from "./logResultTable";
import NewWindow from "react-new-window";
import { Link } from "react-router-dom";
import { Fragment } from "react";

function stopRunningJob(e) {
  e.stopPropagation();
  const { actions, redux, environmentId, versionNumber } = props;
  const { transformer } = redux;
  let selectedTransformer = transformer.versions.find(
    (t) => t.Version == parseInt(versionNumber, 10)
  );
  actions.callStopExecuteTransformer(selectedTransformer.Id, environmentId);
}
export default (props) => {
  const refInput = useRef();
  const jobsRef = useRef();
  const { expand, externalExecutionId, redux } = props;
  const { project } = redux;
  const { appData } = project;
  const [logs, setLogs] = useState([]);
  const [openJobsInfo, setOpenJobsInfo] = useState(false);
  const [stepId, setStepId] = useState("");

  const [showLoader, setShowLoader] = useState(true);
  const [openPopUp, setOpenPopUp] = useState(false);
  let currentUrlParams = new URLSearchParams(window.location.search);
  let isWiz =
    props.executionMetadata.generatedFromPi == "" ||
    !props.executionMetadata.generatedFromPi
      ? false
      : true;

  useEffect(() => {
    const {
      actions,
      externalExecutionId,
      redux,
      error,
      handleCloseResultPanel,
    } = props;
    const { project, transformer } = redux;
    if (externalExecutionId) {
      actions
        .callGetSelectedTransformerLogsDEV(
          project.tenantInfo.TenantId,
          externalExecutionId,
          error,
          props.guid
        )
        .then((data) => {
          setShowLoader(false);
          setLogs(data);
        });
    } else {
      handleCloseResultPanel();
    }

    const interval = setInterval(() => {
      if (refInput.current && refInput.current.released && openPopUp) {
        setOpenPopUp(false);
      }
    }, 1000);

    const jobInterval = setInterval(() => {
      if (jobsRef.current && jobsRef.current.released && openJobsInfo) {
        setOpenJobsInfo(false);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      clearInterval(jobInterval);
    };
  }, [openPopUp, openJobsInfo]);

  function addRecipeId() {
    currentUrlParams.set("recipe", props.transId);
    props.setLastQuery();
    props.handleCloseResultPanel();
    props.history.push(
      window.location.pathname + "?" + currentUrlParams.toString()
    );
  }
  function getLogsHome() {
    let timer = new Date(props.timer * 1000).toISOString().substr(11, 11);
    if (timer.substr(0, 2) == "00") {
      timer = timer.substr(3, timer.length);
    }
    if (timer.substr(0, 1) == "0") {
      timer = timer.substr(1, timer.length);
    }
    return (
      <CSSTransitionGroup
        transitionName="logs"
        transitionEnterTimeout={250}
        transitionLeaveTimeout={250}
      >
        {!showLoader ? (
          <div
            className="logs"
            style={{
              maxHeight: expand ? "100%" : 351,
              position: "relative",
            }}
          >
            {logs && logs.length > 0 && (
              <div className="fix-execution">
                <div
                  style={{
                    marginBottom: 1,
                    position: "relative",
                    float: "left",
                    backgroundColor: "#efefef",
                    marginRight: 10,
                    fontSize: 14,
                    width: "100%",
                    padding: "6px 8px 8px 5px",
                  }}
                >
                  <strong>Id:</strong> {props.externalExecutionId}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <strong>Recipe Id:</strong>{" "}
                  <Link
                    to={{
                      pathname: isWiz
                        ? `/a/${props.match.params.appId}/wiz/${props.transId}`
                        : `/a/${props.match.params.appId}/tf/${props.transId}`,
                    }}
                    params={{
                      piGuid: isWiz
                        ? props.executionMetadata.generatedFromPi
                        : null,
                    }}
                  >
                    {props.transId}{" "}
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <strong>Total Timer Id:</strong> {timer} {}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {currentUrlParams.has("recipe") ? null : (
                    <Fragment>
                      <strong>History of the recipe:</strong>&nbsp;
                      <span
                        onClick={() => addRecipeId()}
                        className="span-as-link"
                      >
                        click here
                      </span>
                    </Fragment>
                  )}
                </div>
                <div
                  style={{
                    marginBottom: 1,
                    position: "relative",
                    float: "left",
                    backgroundColor: "#CCCDCF",
                    marginRight: 10,
                    fontSize: 14,
                    width: "100%",
                    padding: "6px 8px 8px 5px",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginLeft: "1%",
                    }}
                  >
                    <strong>Statement:</strong>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      width: "42%",
                      marginLeft: "1%",
                    }}
                  >
                    <strong>Result:</strong>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      width: "11%",
                      marginLeft: "1%",
                    }}
                  >
                    <strong>Rows Read:</strong>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      width: "13%",
                      marginLeft: "1%",
                    }}
                  >
                    <strong>Timer:</strong>
                  </div>
                </div>
              </div>
            )}
            {logs && logs.length > 0 ? (
              <div
                className="logs-scroller"
                style={{
                  maxHeight: expand ? "100%" : 351,
                  marginBottom: 20,
                }}
              >
                <div style={{ height: "100%", marginTop: 71 }}>
                  {logs.map((log, i) => {
                    const isSchema = log.statement.includes("printschema");
                    const isTable =
                      !isSchema &&
                      (log.statement.includes("print") ||
                        log.statement.includes("PRINT"));
                    const jobIds = log.extendedProperties;
                    let logsTimer = new Date(log.timer * 1000)
                      .toISOString()
                      .substr(11, 11);
                    if (logsTimer.substr(0, 2) == "00") {
                      logsTimer = logsTimer.substr(3, logsTimer.length);
                    }
                    if (logsTimer.substr(0, 1) == "0") {
                      logsTimer = logsTimer.substr(1, logsTimer.length);
                    }
                    return (
                      <div className="selected-transformer-result-logs" key={i}>
                        <div
                          style={{
                            display: "inline-block",
                            width: "30%",
                            marginLeft: "1%",
                          }}
                        >
                          {log.statement}
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            width: "42%",
                            wordWrap: "break-word",
                            marginLeft: "1%",
                            verticalAlign: "bottom",
                          }}
                        >
                          {isTable ? (
                            <LogResultTable result={log.result} />
                          ) : isSchema ? (
                            <pre>{log.result}</pre>
                          ) : jobIds && jobIds != "" ? (
                            <span>
                              {`${log.result}`}&nbsp;(
                              <span
                                className="span-as-link"
                                onClick={() => {
                                  setOpenJobsInfo(true);
                                  setStepId(log.stepGuid);
                                }}
                              >
                                details
                              </span>
                              )
                            </span>
                          ) : (
                            `${log.result}`
                          )}
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            width: "11%",
                            marginLeft: "1%",
                          }}
                        >
                          {log.recordsRead > 0 && log.recordsRead}
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            width: "13%",
                            marginLeft: "1%",
                          }}
                        >
                          {logsTimer}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <span>No Data Found.</span>
            )}
          </div>
        ) : (
          <img
            alt="spinner"
            src={images.ajax_loader}
            height="18"
            style={{ margin: 10, marginLeft: 100 }}
          />
        )}
      </CSSTransitionGroup>
    );
  }

  return (
    <div>
      {/* <img
        src={images.OpenInNew}
        height={"20px"}
        onClick={() => setOpenPopUp(true)}
        className="open-in-new"
      /> */}
      {getLogsHome()}
      {openPopUp && (
        <NewWindow
          copyStyles={false}
          name={`Exexcution ${externalExecutionId}`}
          title={`Exexcution ${externalExecutionId}`}
          ref={refInput}
          features={{ noopener: "_blank" }}
          url={`/a/${appData.Id}/health/details?executionGuid=${externalExecutionId}}&env=${props.environmentId}`}
        />
      )}
      {openJobsInfo && (
        <NewWindow
          copyStyles={false}
          ref={jobsRef}
          name={`SF Jobs ${externalExecutionId}`}
          title={`SF Jobs ${externalExecutionId}`}
          features={{ noopener: "_blank" }}
          url={`/a/${appData.Id}/health/sfjobs?step=${stepId}&env=${props.environmentId}`}
        />
      )}
    </div>
  );
};
