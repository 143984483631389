import React, { Component } from 'react';
import {Button, FormControl, FormGroup, Modal, ControlLabel, ToggleButtonGroup, ToggleButton} from 'react-bootstrap'
import RecipeSelector from './recipeselector'
import WizardSelector from './wizardselector'
import moment from 'moment'
import images from '../images'
import ConnExpEnvDropdown from './connexpenvdropdown'
import TypesDropdown from './typesdropdown'

const { Body, Title, Header, Footer } = Modal;

const urlregex = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
//use urlregex.test() to make sure urls are urls - returns true and false


export default class NewConnection extends Component {
  constructor() {
    super();
    this.state = {
          name: '',
          recipeBaseIds:[],
          wizardGuids:[],
          type:'',
          reports:[
                  {
                    environmentId: null,
                    url: ""
                  },
                  ],
          comments: [],
          showRecipes: true,
          modal: false,
          selecteditem: null,
          editmode: false,
          isCreating: false,
    }


    this.selectData = this.selectData.bind(this)
    this.titleCreateNew = this.titleCreateNew.bind(this)
    this.urlCreateNew = this.urlCreateNew.bind(this)
    this.createExp = this.createExp.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.addNewObjToReports = this.addNewObjToReports.bind(this)
    this.removeObjFromReports = this.removeObjFromReports.bind(this)
    this.showModal = this.showModal.bind(this)
    this.sendAway = this.sendAway.bind(this)

    this.pickEnvironment = this.pickEnvironment.bind(this)
    this.selectType = this.selectType.bind(this)
    this.getValidationState = this.getValidationState.bind(this)
  }

componentWillMount(){
   const array = this.props.location.pathname

   const splitarray = array.split("/")

    if(splitarray.includes("edit")){
      this.setState({editmode:true})
    }
}

componentDidMount(){
  const { connectedExperienceData, environments } = this.props.redux.project
  const number = connectedExperienceData && connectedExperienceData.length + 1

  const defaultenv = environments && environments[0]

  if (!this.state.editmode){
        this.setState({name:'Experience #' + number,
                       type:'Salesforce',
                      reports: [
                                {
                                  environmentId: defaultenv.id,
                                  url: '',
                                }
                                ],
                    })
  }
  else{

  const { expId } = this.props.match.params
  const thisexp = connectedExperienceData.find((f)=>f.id===parseInt(expId))
 
  //condition to remove deleted environments
  const brokenreports = []
       
        thisexp.reports.map((r,index)=>{
          const exists = environments.find((e)=>e.id===r.environmentId)
          if (!exists) brokenreports.push(r)
        })


        if (brokenreports.length > 0){
        brokenreports.map((r,i)=>{
          const marked = thisexp.reports.findIndex((f)=>f.environmentId===r.environmentId)
          thisexp.reports.splice(marked,1)
        })
      }

        this.setState({ name:thisexp.name,
                        type:thisexp.type,
                        reports: thisexp.reports,
                        recipeBaseIds:thisexp.recipeBaseIds,
                        wizardGuids:thisexp.wizardGuids,
                        id:thisexp.id,
                        showRecipes: true,
                        modal: false,
                        selecteditem: null,
                        })

  }
  }


titleCreateNew(a,e){
let name = this.state.name
name = e
this.setState({name})
}

urlCreateNew(a,e,index){

let reports = [...this.state.reports]
reports[index].url = e
this.setState({reports})
}


showModal(a){
  let modal = this.state.modal
  modal = !modal
  this.setState({modal})
}


handleToggle(e) {
    this.setState({showRecipes:e==='recipes'})
}

selectType(a,e){
  const type = e
  this.setState({type})
}

pickEnvironment(env, index){
  let reports = [...this.state.reports]
  reports[index].environmentId = env
  this.setState({reports})
}

addNewObjToReports(){
  const reports = [...this.state.reports]

  let envcheckarray = []

  reports.map((r)=>{
    if(!(envcheckarray.includes(r.environmentId))){
       envcheckarray.push(r.environmentId)
    }
  })

  const { environments }  = this.props.redux.project
  const defaultenv = environments && environments[environments.findIndex((f)=>(!envcheckarray.includes(f.environmentId)))]

  const newpair = {
                    environmentId: defaultenv.id,
                    url: ""
                  }
  reports.push(newpair)
  this.setState({reports})
}

removeObjFromReports(index){
  const reports = [...this.state.reports]
  reports.splice(index, 1)
  this.setState({reports})
}

handleCancel(){
  const { appId, expId } = this.props.match.params
  if (!this.state.editmode){
  this.props.history.push(`/a/${appId}/ca`)
}
  else{
  this.props.history.push(`/a/${appId}/ca/${expId}/details`)
  }
}

getValidationState(report,i) {
    if (urlregex.test(report.url)) return 'success'
    else return 'error'
    return null;
  }

sendAway(q,id){
    const {appId} = this.props.match.params
    const {selecteditem} = this.state

    if (selecteditem && selecteditem.Configuration){
      this.props.history.push(`/a/${appId}/wiz/${id}`); 
  }
  else if (selecteditem) {
    this.props.history.push(`/a/${appId}/tf/${id}`);
  }
  }

createExp(z, first){
const { appId } = this.props.match.params
const { project } = this.props.redux

const data = {...this.state}

this.setState({isCreating: true})

this.props.actions.callCreateConnectedExp(project.tenantInfo.TenantId, appId, data)
.then((r)=>{
  if (first){
  this.props.history.push(`/a/${appId}/ca/${r.id}/details?a=true`)
      }
  else{
  this.props.history.push(`/a/${appId}/ca/${r.id}/details`) 
  }
  })
}

selectData(a,e,event){

this.setState({selecteditem: e})

if (event.target.classList.value !== 'recipe-view-preview'){

//if its a wizard
if (e.Configuration){
      const wizardGuids = [...this.state.wizardGuids]
       if(wizardGuids.includes(e.ProductizedIntegrationGuid)){
            let position = wizardGuids.findIndex((f)=>e.ProductizedIntegrationGuid===f)
            wizardGuids.splice(position, 1)
            this.setState({wizardGuids})
        }

       else{
           wizardGuids.push(e.ProductizedIntegrationGuid)
           this.setState({wizardGuids})
              }

    }
    else{
      // if its a recipe
      const recipeBaseIds = [...this.state.recipeBaseIds]
       if(recipeBaseIds.includes(e.BaseId)){
              let position = recipeBaseIds.findIndex((f)=>e.BaseId===f)
              recipeBaseIds.splice(position, 1)
              this.setState({recipeBaseIds})
          }
       else{
             recipeBaseIds.push(e.BaseId)
             this.setState({recipeBaseIds})
                }

          }
        }
      }

  render(){
    


    const styles = {
    icon: {
        marginTop: '-4px',
        marginRight: '150px',
        height: 42,
        float: 'right',
        display: 'block',
          },
    countericonrecipe: {
        height: 30,
            },
    countericonwizard: {
        height: 30,
            },
    modalicon: {
        float: 'left',
        height: 40,
        display: 'block',
        marginRight: 4,
        marginTop:-7
  },
    }

    let allset
    const {reports, name, type, selecteditem} = this.state

    const { project, main, transformer } = this.props.redux
    const allenvironments = project && project.environments
    let fieldcheckarray = [] 
    let envcheckarray = []

    reports.map((r)=>{
      if(!(envcheckarray.includes(r.environmentId))){
         envcheckarray.push(r.environmentId)
      }
    })


    
    reports.map((r)=>{
      if(r.url !== ''
        && urlregex.test(r.url)
        && r.environmentId !== null){
        fieldcheckarray.push('true')
      }
      else {
        fieldcheckarray.push('false') 
        }
      })


    if (name !== ''
        && reports.length > 0
        && !(fieldcheckarray.includes('false'))
        && type){
      allset = true
    }
    else{
      allset = false
    }
    
   
    

    

    
    let transRows = [];
    const environs = {};
    if (project.transformers && project.transformers.length > 0) {
      const objs = [...project.transformers];
      for (let i = 0; i < objs.length; i++) {
          if(!objs[i].GeneratedFromPiTypeId){
            transRows.push(<RecipeSelector obj={objs[i]}
              baseId={objs[i].BaseId}
              selectData={this.selectData}
              isSelected={this.state.recipeBaseIds.includes(objs[i].BaseId)}
              name={objs[i].Name}
              showModal={this.showModal}
              version={objs[i].Version}
              environ={objs[i].Environ}
              deployed={objs[i].Deployed}
              appId={this.props.match.params.appId}
              key={i} lastModified={objs[i].LastModified}
              />);
          }
        environs[objs[i].Environ] = 1;
      }
    }

    if(!(main && main.wizards)) return <div />

    let transRowsWiz = [];
    const types = {};
    if (project.wizards && project.wizards.length > 0) {
      const objs = [...project.wizards];
      for (let i = 0; i < objs.length; i++) {
        const wiz = main.wizards.find(w=>w.typeId===objs[i].IntegrationTypeId)
          transRowsWiz.push(
            <WizardSelector obj={objs[i]}
            selectData={this.selectData}
            isSelected={this.state.wizardGuids.includes(objs[i].ProductizedIntegrationGuid)}
            wiz={wiz}
            baseId={objs[i].ProductizedIntegrationGuid}
            name={objs[i].Name}
            showModal={this.showModal}
            typeId={objs[i].IntegrationTypeId}
            appId={this.props.match.params.appId}
            piGuid={objs[i].ProductizedIntegrationGuid}
            key={i} lastModified={objs[i].LastModified}
            configs={objs[i].Configuration}
          />
          );
        types[wiz.title] = 1;
      }
    }

    return(
      <div className="parent-env">
      <div style={{ maxWidth: 900}}>
{!this.state.editmode ?
      <div style={{margin:'50px 0 15px 0', fontSize:'20px', display:'inline-block'}}>
          Create New Analytics
        </div>
        :
        <div style={{margin:'50px 0 15px 0', fontSize:'20px', display:'inline-block'}}>
          Edit "<span style={{fontWeight:'bold'}}>{name}</span>"
        </div>
      }

      <div style={{ display:'grid', gridGap: '60px', gridTemplateColumns: '470px 1fr'}}>
      {/*master grid*/}
      <div id="left-side-new-exp">
        <div style={{height: 70}}>
          <ControlLabel>Name*</ControlLabel>
          <FormControl
            type="text"
            placeholder="Insert name" 
            value={name} 
            onChange={(e)=>{this.titleCreateNew(null, e.target.value)}} />
          </div>

        {/* type */}
        <div style={{height: 100}}>
        <ControlLabel>Type*</ControlLabel>
        <p>Select your report or dashboard's provider.</p>
        <TypesDropdown 
        selectType={this.selectType}
        type={this.state.type}/> 
            
    </div>
        
      {/*create setup for each obj in reports*/}
      <div style={{marginTop:8}}>
      <ControlLabel>Dashboards*</ControlLabel>
      <p>Provide a link to your report or dashboard, and select an environment.</p>
      {reports && reports.length < 1 ?
        <span style={{color: 'red'}}>None selected.</span> 
        :
        <div>
        {reports ? reports.map((r,i)=>{
          
          const currentenv = allenvironments.find((f)=>f.environmentId===reports[i].environmentId)
            return(
          <div key={i} style={{width: 470, background: '#f6f6f6', borderRadius: '10px', marginBottom: 14, padding: 5, paddingBottom: 0}}>
            <div style={{display:'inline-block', marginRight: 10}}>
            <strong className="labelz" style={{fontSize:'11px'}}>
              Link to Report or Dashboard
            </strong>
            <br />
            <FormGroup
            controlId="formBasicText"
            style={{height: 30}}
            validationState={this.getValidationState(r,i)}>
            <FormControl
              type="text"
              placeholder="Insert url" 
              value={r.url} 
              onChange={(e)=>{this.urlCreateNew(null, e.target.value, i)}} /> 
             <FormControl.Feedback />
            </FormGroup>
            </div>
            <div style={{display:'inline-block'}}>
             <ConnExpEnvDropdown
              reports={reports}
              showThisTitle="Select Environment"
              uiEnvironment={currentenv} versions={transformer.versions}
              deployments={transformer.deployments} environments={project.environments}
              disabled={!(project.environments && project.environments.length>0)}
              onSelect={env=>this.pickEnvironment(env.environmentId, i)}/>  
            </div>
          <div style={{position: 'relative', marginTop: 0}}>
            <svg style={{position: 'absolute', right: 0, top: -77}} height="18" viewBox="0 0 60 60" className="delete-cred-bar"
              onClick={()=>this.removeObjFromReports(i)}>
              <path style={{strokeWidth:5, strokeLinecap: 'round'}}
                d="M 15.674663,15.572746 L 44.587629,44.485711 M 45.118838,15.420972 L 15.522889,45.016920" />
            </svg>  
          </div>
            
          </div>
            )}) : null
          }
          </div>
        }
        </div>
          {/*prevent creation if all env are used*/}
          { envcheckarray.length > allenvironments.length - 1 ? <p style={{fontSize:12}}>All environments selected.</p> :
          <div style={{marginBottom: 40}}>

            <svg className="delete-cred-bar" 
            height="18" viewBox="0 0 24 24" onClick={()=>this.addNewObjToReports()}>
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
            </svg>
          </div>
          }
          </div>


          <div style={{ display:'grid', gridGap: '30px', gridTemplateColumns: '1fr'}}>

          <div style={{ display:'grid', gridGap: '10px', gridTemplateColumns: '1fr'}}>
{/* first row */}
          <div>
            <div>
              <ControlLabel>Select Recipes</ControlLabel>
              <div style={{display:'inline-block', marginLeft: 124}} className="rec-wiz-toggler">
                <ToggleButtonGroup
                    type="radio" bsSize="xsmall" name="options"
                    value={this.state.showRecipes ? 'recipes':'wizards'} 
                    onChange={(e)=>this.handleToggle(e)}>
                    <ToggleButton style={{background:'#f1f1f1'}} value={'recipes'}>Editor</ToggleButton>
                    <ToggleButton style={{background:'#f1f1f1'}} value={'wizards'}>Wizard</ToggleButton>
                  </ToggleButtonGroup>
              </div>


              </div>
              <div className="rec-wiz-picker" style={{height: '340px', overflowY: 'auto', paddingLeft: '5px'}}>
              {this.state.showRecipes ?
                <div>
                  {transRows.length > 0 ? transRows : <span>None</span>}
                </div>
                  :
                  <div>
                  {transRowsWiz.length > 0 ? transRowsWiz : <span>None</span>}
                </div>
                }
                <div style={{height:40}}></div>
              </div>

          </div>
{/* second row */}          
          <div>
          

          <div className="second-column" style={{marginTop:10, display:'grid', gridTemplateColumns: '1fr 200px'}}>
                <div>
                    <ControlLabel>Contents</ControlLabel> 
                    { this.state.recipeBaseIds.length > 0 || this.state.wizardGuids.length > 0 ?
                    <svg style={{marginTop:-2, }} height="18" viewBox="0 0 60 60" className="delete-cred-bar"
                      onClick={()=>this.setState({recipeBaseIds:[], wizardGuids:[]})}>
                      <path style={{strokeWidth:5, strokeLinecap: 'round'}}
                      d="M 15.674663,15.572746 L 44.587629,44.485711 M 45.118838,15.420972 L 15.522889,45.016920" />
                    </svg>  
                    :
                    null
                  }
                    {/*here go the connected data selected*/}
                    <div>
                    {this.state.recipeBaseIds.length < 1 && this.state.wizardGuids.length < 1 ?
                      <span>Select recipes above. (optional)</span>
                      :
                    <div>
                    {this.state.recipeBaseIds.map((obj, i)=>{
                      return(
                      <div style={{display: 'inline-block', 
                      marginRight: 6, height: 40, marginTop: 7}} key={i}>
                      <img  role="presentation"
                      src={images.codeicon}
                      style={styles.countericonrecipe}
                          />

                      </div>
                      )
                    })}
                    {this.state.wizardGuids.map((obj, i)=>{
                      return(
                      <div style={{display: 'inline-block', 
                      marginRight: 3, height: 40, marginTop: 7}} key={i}>
                      <img
                      role="presentation"
                      src={images.ic_web_black_48dp}
                      style={styles.countericonwizard}
                       />
                    </div>
                      )
                    })}
                    
                    </div>
                    }
                    </div>
                    </div>
                    <div style={{width: 220}}>
                    {!this.state.editmode ?
                    <div style={{float: 'right', marginTop:100, marginRight: 50}}>
                      <Button bsStyle="default" bsSize='small' disabled={this.state.isCreating} onClick={this.handleCancel}>
                        Cancel
                     </Button>       
                      <Button style={{marginLeft: 10, width: 80}} bsSize='small' bsStyle='primary'
                      disabled={!allset || this.state.isCreating} onClick={()=>this.createExp(null, true)}
                      >
                        {!this.state.isCreating ? <span>Create</span> : <span><img alt="small-spinner" src={images.ajax_loader_small} height="8" /></span>}
                      </Button>
                    </div>
                    :
                    <div style={{float: 'right', marginTop:100, marginRight: 50}}>
                      <Button bsStyle="default" bsSize='small' disabled={this.state.isCreating}onClick={this.handleCancel}>
                        Cancel
                     </Button>       
                      <Button bsSize='small' style={{marginLeft: 10, width: 80}} bsStyle='primary'
                      disabled={!allset || this.state.isCreating} onClick={()=>this.createExp(null)}
                      >
                         {!this.state.isCreating ? <span>Save</span> : <span><img alt="small-spinner" src={images.ajax_loader_small} height="8" /></span>}
                      </Button>
                     
                    </div>
                  }
                    </div>

         </div>
            
          </div>
          </div>
          <div>
          
          </div>
          </div>
          </div>

        {/* modal below */}

                    <Modal
                  show={ this.state.modal }
                  onHide={ this.showModal }
                  backdrop="static"
                  >
                  <Header>
                  <Title>
                   <img  role="presentation"
                src={selecteditem && selecteditem.Configuration ? images.ic_web_black_48dp : images.codeicon}
                style={styles.modalicon}
                    />
                   {selecteditem ? selecteditem.Name : null} 
                   </Title>
                  </Header>

                    <Body>
                    <div style={{marginTop: 10, marginBottom: 10}}>
                    <span style={{marginLeft:10, fontWeight: 'bold'}}>Description</span>
                    {selecteditem ? 
                      <div style={{padding: 20, paddingTop: 5}}>
                      {selecteditem.Description}
                      </div>
                       : null}
                    <span style={{marginLeft:10, fontWeight: 'bold'}}>Last Modified</span>
                    {selecteditem ? 
                      <div style={{padding: 20, paddingTop: 5}}>
                      {moment(selecteditem.LastModified).format('MMM Do, h:mm a')}
                      </div>
                       : null}
                       </div>
                    </Body>
                    <Footer>
                    <Button bsStyle="default" style={{float:'left'}} onClick={selecteditem && selecteditem.Configuration ? () => this.sendAway(null, selecteditem.TransformerBaseId) : () => this.sendAway(null, selecteditem.BaseId) }>
                    {selecteditem && selecteditem.Configuration ?  
                    <span>Edit Wizard</span> :
                    <span>Edit Recipe</span>}
                    </Button>
                    </Footer>
                  </Modal>
        
       </div>
       </div>
      )

    }
}

