import React, { Component } from "react";
import { Button } from "react-bootstrap";

import MarkdownIt from "markdown-it";
import DomPurify from "dompurify";
import images from "../images";
import DataDetails from "./datadetails";
import ConfirmDeleteModal from "../utils/confirmDeleteModal";
import { hours, minutes } from "../../data/time";

// SKIP ON SERVER-SIDE RENDERING
let codemirror = null;
if (typeof window !== "undefined" && typeof window.navigator !== "undefined") {
  codemirror = require("react-codemirror2");
  require("codemirror/mode/markdown/markdown");
  require("codemirror/mode/javascript/javascript");
}

export default class Settingstab extends Component {
  constructor() {
    super();
    this.state = {
      appName: "",
      uniqueKey: "",
      threasholdHours: "",
      threasholdMinutes: "",
      savingProject: false,
      editDescription: false,
      cmLoaded: false,
      description: null,
      modal: false,
    };
    this.md = new MarkdownIt();
    this.changeDescription = this.changeDescription.bind(this);
    this.createMarkup = this.createMarkup.bind(this);
    this.toggleEditDescription = this.toggleEditDescription.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
  }

  componentWillMount() {
    const { project } = this.props.redux;
    const { appData } = project;

    const threasholdHours = this.getThreasholdHours(appData.maxDataProcTime);

    const threasholdMinutes = this.getThreasholdMinutes(
      appData.maxDataProcTime
    );

    this.setState({
      appName: appData.name,
      threasholdHours: threasholdHours,
      threasholdMinutes: threasholdMinutes,
      uniqueKey: appData.uniquekey,
      description: appData.description,
    });
  }

  getThreasholdHours(date) {
    if (date) {
      const time = date.split("T")[1];
      const hours = time.split(":")[0];
      return hours;
    } else return "00";
  }

  getThreasholdMinutes(date) {
    if (date) {
      const time = date.split("T")[1];
      const minutes = time.split(":")[1];
      return minutes;
    } else return "00";
  }

  changeAppName = () => {
    const { redux, match } = this.props;
    const { params } = match;
    const { project } = redux;
    this.setState({ savingProject: true });
    this.props.actions
      .callUpdateApp(
        project.tenantInfo.TenantId,
        {
          name: this.state.appName,
        },
        params.appId
      )
      .then(() => {
        this.setState({ savingProject: false });
      });
  };

  updateDPT = () => {
    const { redux, match } = this.props;
    const { params } = match;
    const { project } = redux;
    this.setState({ savingDPT: true });
    this.props.actions
      .callUpdateDPT(
        project.tenantInfo.TenantId,
        {
          threasholdHours: this.state.threasholdHours,
          threasholdMinutes: this.state.threasholdMinutes,
        },
        params.appId
      )
      .then(() => {
        this.setState({ savingDPT: false });
      });
  };

  updateDescription = () => {
    const { redux, match } = this.props;
    const { params } = match;
    const { project } = redux;
    this.setState({ savingProjectDescription: true });
    this.props.actions
      .callUpdateDPT(
        project.tenantInfo.TenantId,
        {
          maxDataProcTime: `${this.state.threasholdHours}:${this.threasholdMinutes}:00`,
        },
        params.appId
      )
      .then(() => {
        this.setState({
          savingProjectDescription: false,
          editDescription: false,
        });
      });
  };

  deleteWorkspace = () => {
    const { redux, match } = this.props;
    const { params } = match;
    const { project } = redux;
    this.setState({ deletingWorkspace: true, modal: false });
    this.props.actions
      .callUpdateApp(
        project.tenantInfo.TenantId,
        {
          archived: true,
        },
        params.appId
      )
      .then(() => {
        this.setState({ deletingWorkspace: true });
        this.props.actions.callGetApps().then(() => {
          this.props.history.push("/");
        });
      });
  };

  triggerModal() {
    let modal = this.state.modal;
    modal = !modal;
    this.setState({ modal });
  }

  toggleEditDescription(bool) {
    if (bool && !this.state.cmLoaded) {
      //this.loadCM();
    }
    this.setState({ editDescription: bool });
  }
  createMarkup(desc) {
    return {
      __html: DomPurify.sanitize(desc),
    };
  }

  changeDescription(a, b, c) {
    this.setState({ description: c });
  }

  render() {
    const { redux } = this.props;
    const { project } = redux;

    const deletemessage = `Deleting this project will delete 
                   all environments, recipes, wizards, and other 
                   contents stored inside ${this.state.appName}.`;

    let description;
    if (this.state.description) {
      description = this.createMarkup(this.md.render(this.state.description));
    }

    const CodeMirror = codemirror && codemirror.Controlled;

    return (
      <div className="overview-tab-content" style={{ paddingTop: 16 }}>
        <div style={{ marginTop: 10 }}>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginRight: 20,
            }}
          >
            <label htmlFor="project_name" className="form-control-left">
              Project Name
            </label>
            <br />
            <input
              className="form-control"
              type="text"
              readOnly={project.appData.identityIsAdmin ? false : true}
              value={this.state.appName}
              onChange={(e) => this.setState({ appName: e.target.value })}
              style={{ display: "inline-block", verticalAlign: "top" }}
            />
            {project.appData.identityIsAdmin && (
              <button
                style={{ marginLeft: 8, marginTop: 2, width: 62 }}
                className="btn btn-sm btn-primary"
                onClick={this.changeAppName}
              >
                {!this.state.savingProject ? (
                  "Update"
                ) : (
                  <img
                    alt="spinner"
                    src={images.ajax_loader_small}
                    height="8"
                  />
                )}
              </button>
            )}
          </div>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginRight: 20,
            }}
          >
            <label htmlFor="unique_key" className="form-control-left">
              Unique Key
            </label>
            <br />
            <input
              className="form-control"
              type="text"
              style={{ width: 237 }}
              value={this.state.uniqueKey}
              readOnly
            />
          </div>

          {/*<div style={{display:'inline-block',verticalAlign:'top'}}>
          <label htmlFor="edition" className="form-control-left">Edition</label>
          <br />
          <input className="form-control" type="text" style={{width:237}}
            value="Lingk Enterprise Trial" readOnly />
        </div>
        <div className="edition-info-circle">i</div>*/}
        </div>

        {/* <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            marginRight: 20,
            marginTop: 10,
          }}
        >
          <label htmlFor="" className="form-control-left">
            Recipe Duration Notification
          </label>
          <br />
          <div style={{ display: "flex", gap: 20 }}>
            <div>
              Hours
              <select
                name="thresholdHours"
                id="hours"
                value={this.state.threasholdHours}
                onChange={(e) => {
                  this.setState({ threasholdHours: e.target.value });

                  if (e.target.value == 12) {
                    this.setState({ threasholdMinutes: "00" });
                  }
                }}
                className="form-control"
                style={{ backgroundColor: "#eee", width: 100 }}
              >
                {hours.map((value, index) => (
                  <option value={value} key={index}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div>
              Minutes
              <select
                name="thresholdMinutes"
                id="minutes"
                disabled={this.state.threasholdHours >= 12}
                value={this.state.threasholdMinutes}
                onChange={(e) =>
                  this.setState({ threasholdMinutes: e.target.value })
                }
                className="form-control"
                style={{ backgroundColor: "#eee", width: 100 }}
              >
                {minutes.map((value, index) => (
                  <option value={value} key={index}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <button
                style={{ marginLeft: 0, marginTop: 22, width: 62 }}
                className="btn btn-sm btn-primary"
                onClick={this.updateDPT}
              >
                {!this.state.savingDPT ? (
                  "Update"
                ) : (
                  <img
                    alt="spinner"
                    src={images.ajax_loader_small}
                    height="8"
                  />
                )}
              </button>
            </div>
          </div>
        </div> */}

        {/*}
      <div style={{position:'relative', maxWidth:626}}>
        <div style={{margin:'30px 0 4px 0', fontSize:'15px', 
          display:'inline-block',lineHeight:'15px'}}>
          Description
        </div>
        {project.appData.identityIsAdmin && <div 
          style={{position:'relative', display:'inline-block', verticalAlign:'top'}}>
          { !this.state.editDescription ?
            <div style={{position:'absolute',top:24,left:6,cursor:'pointer'}}
                 onClick={()=>this.toggleEditDescription(true)}>
              <img src={images.create} style={{width:20}} alt="create" />
            </div> :
            <div style={{position:'absolute',top:24,left:6,cursor:'pointer'}}
                 onClick={()=>this.toggleEditDescription(false)}>
              <img src={images.ic_clear_black_48dp} style={{width:20}} alt="clear" />
            </div>
          }
        </div>}
        
      </div>

      <div style={{display:this.state.editDescription ? 'block' : 'none', maxWidth:626}}>
        {CodeMirror && <CodeMirror value={this.state.description}
          options={{lineNumbers:true, theme:'default', mode:'markdown'}} 
          onBeforeChange={this.changeDescription} />}
        <a href="http://commonmark.org/help/" target="blank">Formatting Help</a>
      </div>
      <div style={{background:'#efefef',padding:'8px 12px', maxWidth:626,
        display:this.state.editDescription ? 'none' : 'block'}}>
        <div dangerouslySetInnerHTML={description} />
      </div>
      
      {this.state.editDescription && 
        <div style={{width: 626, marginTop:-19}}>
      {project.appData.identityIsAdmin && <button 
          style={{marginTop:0, float:'right', width:62}}
          className="btn btn-sm btn-primary"
          onClick={this.updateDescription}>
          {!this.state.savingProjectDescription ? 'Update' :
          <img alt="spinner" src={images.ajax_loader_small} height="8" />}
        </button>
      }
      </div>}

*/}
        <DataDetails {...this.props} />

        {project.appData.identityIsAdmin && (
          <Button
            bsStyle="danger"
            bsSize="small"
            disabled={this.state.deletingWorkspace}
            style={{ marginTop: 30 }}
            onClick={() => {
              this.triggerModal();
            }}
          >
            Delete Project
          </Button>
        )}
        <br />

        <ConfirmDeleteModal
          modalState={this.state.modal}
          triggerModal={this.triggerModal}
          nameOfTarget={this.state.appName}
          deleteTarget={this.deleteWorkspace}
          message={deletemessage}
        />
      </div>
    );
  }
}
