import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import history from '../../history'
import images from '../images'

class EventsHome extends Component {

  constructor(props) {
    super(props);
    const pathArray = props.location.pathname.split('/');

    let sel = 0
    if(pathArray.length<5) sel=0
    else {
      if(pathArray[4]==='obj') sel=1
      //else if(pathArray[4]==='calendar') sel=2
    }

    this.state = {
      selected:sel
    };
    this.select = this.select.bind(this);
    this.updateTab = this.updateTab.bind(this);
  }

  componentDidMount(){
    const {actions, redux, match} = this.props
    const {project} = redux
    const {params} = match
  //  actions.getUsersForWorkspace(project.tenantInfo.TenantId, params.appId) // project.permissions
    actions.callGetAllObjectTypes(project.tenantInfo.Key, project.appData.uniquekey)
    actions.callGetDeploymentsForWorkspace(project.tenantInfo.TenantId, params.appId)
  }

  select(selected, path) {
    this.setState({selected})
    history.push(path);
  }

  updateTab(){
    const pathArray = this.props.location.pathname.split('/');

    let sel = 0
    if(pathArray.length<5) sel=0
    else {
      if(pathArray[4]==='obj') sel=1
      //else if(pathArray[4]==='calendar') sel=2
    }

    this.setState({selected:sel})
  }

  render() {
    const { children, redux, actions, match } = this.props;
    const {params} = match
    const {project} = redux
    const updateTab = this.updateTab
    

    const styles = {
      main:{
        background:'#bbb',
        paddingTop:0,
        width:'100%',
        height:'100%'
      },
      tab:{
        display:'inline-block',
        height:42,
        width:200,
        borderRadius: 2,
        textAlign:'center',
        paddingTop:12,
        cursor:'pointer'
      },
      tabs:{
        marginLeft:20,
        marginTop:50,
        height:'100%'
      },
    }

    const { appId } = params;
    const items=[
      {name:'Subscriptions', path:`/a/${appId}/events`},
      {name:'Manage Objects', path:`/a/${appId}/events/obj`},
     // {name:'Schedules', path:`/a/${appId}/events/calendar`},
    ]

    return (
      <div className="parent-env">
      <div className="tab-replacer">
      {/*}
      <div style={styles.main}>
        <div className="styles-tabs">
          {items.map((item, index) =>
            <div onClick={()=>this.select(index, item.path)} style={styles.tab} key={index}
              className={(this.state.selected === index ? 'sel-tabby' : 'overview-tabby') + ` ${item.className}`}>
              {item.name}
            </div>
          )}
        */}
          { project.appData ?
          <div style={{height:'100%',background:'#dcdfdf'}}>
            {React.isValidElement(children) ?
              React.cloneElement(children,
              { redux, actions, updateTab },
            ) : null}
          </div> : <div style={{height:'100%',background:'#dcdfdf'}}>
            <div className="ajax-loader">
              <img alt="spinner" src={images.ajax_loader} />
            </div>
          </div>}
          </div>
        </div>
      
    );
  }
}

export default withRouter(EventsHome)