const SettingsStyle = {
  settingStyles: {
    float: 'right',
    marginRight: 20,
    marginTop: 15,
    cursor: 'pointer',
  },
  iconStyles: {
    width: 19,
    height: 19,
    verticalAlign: 'top',
  },
  overlayStyles: {
    boxSizing: 'border-box',
    borderRadius: 3,
    padding: '10px 0',
    backgroundColor: '#fff',
    whiteSpace: 'nowrap',
    display: 'block',
    boxShadow: '0 1px 15px 0 rgba(0,0,0,.1),0 0 0 0 transparent',
    zIndex: 2000,
    position: 'absolute',
    right: 0,
    top: '100%',
    border: '1px solid #c9d7df',
    width: 400,
  },
  dropdownListItemStyles: {
    display: 'block',
    padding: '5px 16px',
    borderTop: '1px #fff',
    borderBottom: '1px #fff',
    textDecoration: 'none',
    display: '-webkit-box, display: -moz-box, display: -ms-flexbox, display: -webkit-flex, display: flex',
    WebkitAlignItems: 'flex-start',
    msFlexAlign: 'start',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  menuTextStyles: {
    WebkitFlex: 1,
    msFlex: 1,
    flex: 1,
    display: 'inline-block',
    fontSize: 14,
    lineHeight: '20px',
    color: "#467fb7",
    fontWeight: "bold"
  },
  menuTextLightStyles: {
    fontSize: 13,
    lineHeight: '20px',
    color: '#78909c',
    fontWeight: 400,
  },
  menuIconStyles: {
    marginRight: 16,
    marginLeft: 8,
    display: 'inline-block',
  },
};

export default SettingsStyle;
