import React from 'react'
import images from '../images'
import { Button, Modal } from 'react-bootstrap'

const { Body } = Modal


const PurchaseServicesModal = (props) => {

    const { modal, triggerModal } = props

    return (

          <Modal show={modal} onHide={triggerModal} backdrop='static' keyboard={false}>
            <Body style={{height: 'fit-content'}}>
              
            <div style={{marginTop: 0, textAlign:'center',padding:40, paddingBottom: 0}}>
              <img alt="logo" src={images.logo_green} style={{marginBottom:6}} height="36" /> 
              <h3>Your Lingk Services are on the way! </h3>
              <span>Please allow 24 hours for our team to schedule a meeting with you.</span>
              
              <div style={{padding:10, margin:30, marginTop:10}}>
                <Button onClick={()=>{triggerModal()}} bsStyle="primary" bsSize="large">
                <b>OK</b>
                </Button>
              </div>
            </div>
            
            </Body>
          </Modal>
          )
}

export default PurchaseServicesModal
