import React, { Component, Fragment } from "react";
import ReactTooltip from "react-tooltip";
import ConnectorBar from "./ConnectorBar";
import ConnectorSection from "./ConnectorSection";
import CredBar from "./CredBar";
import { Table, Button, Modal } from "react-bootstrap";
import { If, Then } from "react-if";
import { ManageEnvVars } from "./manageEnvVars";
import { createClient } from "contentful";
import images from "../images";
import NewTransformerIntg from "../recipe/newTransfomerIntg";

const { Header, Body, Footer, Title } = Modal;
const contentfulClient = createClient({
  accessToken:
    "0f243ce9285ce061d169b5d337e547755168c3972d67dc957df8362b504ccdfd",
  space: "0qhqhn3xauc3",
});

const CONNECTOR_CONTENT_TYPE_ID = "connector";
class Connectors extends Component {
  constructor() {
    super();
    this.connectorBar = React.createRef();

    this.state = {
      selectedProvider: null,
      editEnvColor: false,
      showConnector: false,
      oauthUrl: null,
      gettingOauthUrl: null,
      metadata: null,
      modalOpened: false,
      isSaving: false,
      showDownload: false,
      disableSave: false,
      disableSaveConnector: false,
      disableDeleteConnector: false,
      connectorKey: "",
      originalConnectorkey: "",
      connectorName: "",
      contentfulTemplates: [],
      showSaveLoader: false,
      disabledNext: true,
      checkedSource: false,
      checkedDestination: false,
      showConnectionsIntg: false,
      modal: false,
      modalShown: 0,
      misconfiguredConnectors: [],
      singleMisconfiguredConnectors: [],
      multipleConnectors: []
    };
  }

  unCamelize(text) {
    return text.replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase())
  }

  componentWillMount() {
    const { newConnector, redux } = this.props;
    const { project, config } = redux;
    if (newConnector) {
      this.setState({ showConnector: true });
    }
    const contentfulTemplates = [];
    const isIntegration = config.IS_INTEGRATION;
    contentfulClient
      .getEntries({
        include: 3,
        content_type: CONNECTOR_CONTENT_TYPE_ID,
        order: "fields.title",
      })
      .then((entries) => {
        entries.items.map((o) => {
          if (o.fields.logo) {
            let imgUrl = o.fields.logo.fields.file.url;
            if (!imgUrl.startsWith("https://")) {
              o.fields.logo.fields.file.url = "https:" + imgUrl;
            }
          }
          if (!isIntegration) {
            if (o.fields.forIntegrationOnly == true) return;
          }
          if (o.fields.displayOnAppEnvironments) contentfulTemplates.push(o);
        });
      })
      .then(() => {
        this.setState({ contentfulTemplates });
      });

    let misconfiguredConnectors = [];
    let singleMisconfiguredConnectors = [];
    let multipleConnectors = [];
    let credentialTypes = []

    // take out all types
    project.environmentCredentials.map((v, i) => {
      if (v.credentialType === "LocalFile" || v.credentialType === "EnvironmentVariables") {
        return true
      }
      if (!credentialTypes.includes(v.credentialType)) {
        credentialTypes.push(v.credentialType)
      }
    });

    for (const type of credentialTypes) {
      let isDefault = []
      for (const cred of project.environmentCredentials) {
        if (type === cred.credentialType) {
          isDefault.push(cred.defaultConnection ? 1 : 0)
        }
      }
      if (isDefault.length === 1 && isDefault.includes(0)) {
        singleMisconfiguredConnectors.push(type)
      }
      if (isDefault.length > 1) {
        multipleConnectors.push(type)
      }
      if (!isDefault.includes(1) && isDefault.length > 1) {
        misconfiguredConnectors.push(type)
      }
    }
    if (singleMisconfiguredConnectors.length > 0) {
      this.setState({
        singleMisconfiguredConnectors
      })
    }
    if (multipleConnectors.length > 0) {
      this.setState({
        multipleConnectors
      })
    }
    if (misconfiguredConnectors.length > 0) {
      let modal = this.state.modal
      modal = !modal
      this.setState({
        misconfiguredConnectors,
        modal
      })
    }
  }
  toggleModal() {
    let modal = this.state.modal
    let modalShown = this.state.modalShown

    modal = !modal

    if (modalShown === 0 && this.state.modal) {
      this.setState({
        modal,
        modalShown: modalShown + 1
      })
    } else {
      this.setState({
        modal,
      })
    }
  }

  normalizeCredentialTypeNames(providerLabel) {
    let providerType = providerLabel;
    if (
      providerType === "AdapterSecret" ||
      providerType === "EnvironmentVariables"
    )
      providerType = null;
    return providerType;
  }

  showConnectorDetails(providerConfig, creds) {
    this.setState({
      selectedProvider: providerConfig.type,
      editEnvColor: false,
      showConnector: true,
      connectorKey: creds.key,
      originalConnectorkey: creds.key,
      connectorName: creds.connectionName || providerConfig.label,
    });
    this.props.handleShowEditConnector();
  }

  showBars(isWizardDest) {
    const { env, redux, match, actions, isWizard } = this.props;
    const { params } = match;
    const { project, main } = redux;
    const { appId } = params;
    const { selectedProvider, showConnectionsIntg } = this.state;
    actions.removeJustAddedCreds();
    this.setState({
      showConnector: false,
    });
    if (showConnectionsIntg) {
      this.setState({ showConnectionsIntg: false });
      this.props.showNewConnectorComponent("writer");
      let creds = project.environmentCredentials.find(
        (e) => e.wizard == "isDestination"
      );
      if (creds) {
        const providerConfig = main.providers.find(
          (p) => p.type === creds.credentialType
        );
        this.setState({
          selectedProvider: providerConfig.type,
          editEnvColor: false,
          showConnector: true,
          connectorKey: creds.key,
          originalConnectorkey: creds.key,
          connectorName: creds.connectionName || providerConfig.label,
        });
      } else {
        this.setState({
          selectedProvider: null,
        });
      }
      return;
    }
    if (isWizardDest) {
      let destCreds = project.environmentCredentials.find(
        (e) => e.wizard == "isDestination"
      );
      if (!destCreds && selectedProvider) {
        this.setState({
          selectedProvider: null,
        });
      } else {
        this.props.showNewConnectorComponent("reader");
        let creds = project.environmentCredentials.find(
          (e) => e.wizard == "isSource"
        );
        if (creds) {
          const providerConfig = main.providers.find(
            (p) => p.type === creds.credentialType
          );
          this.setState({
            selectedProvider: providerConfig.type,
            editEnvColor: false,
            showConnector: true,
            connectorKey: creds.key,
            originalConnectorkey: creds.key,
            connectorName: creds.connectionName || providerConfig.label,
          });
        } else {
          this.setState({
            selectedProvider: null,
          });
        }
      }
      return;
    }
    // home page
    if (isWizard) {
      this.props.showCredBars();
    }
    this.props.hideNewConnector();
    this.props.hideEditConnector();
    actions.getAllCredentialsForEnvironment(
      project.tenantInfo.TenantId,
      appId,
      env.name
    );
  }

  getNewConnectorKey = (connectionKey, i, orginalKey) => {
    const { redux } = this.props;
    const { project } = redux;
    let newConnectionKey = connectionKey;
    let connectorCreds =
      project.environmentCredentials &&
      project.environmentCredentials.find((c) => c.key === newConnectionKey);
    if (connectorCreds) {
      i += 1;
      return this.getNewConnectorKey(orginalKey + i, i, orginalKey);
    }
    return newConnectionKey;
  };

  removerCredentialToEnvironment = () => {
    this.setState({
      selectedProvider: null,
      connectorName: "",
      originalConnectorkey: "",
    });
  };
  addNewCredentialToEnvironment = (selectedProvider) => {
    const { redux } = this.props;
    const { project, main } = redux;

    let connectorCreds =
      project.environmentCredentials &&
      project.environmentCredentials.filter(
        (c) => c.providerLabel === selectedProvider
      );
    const providerConfig = main.providers.find(
      (p) => p.type === selectedProvider
    );
    if (connectorCreds && connectorCreds.length > 0) {
      let newConnectionKey = this.getNewConnectorKey(
        connectorCreds[0].key + connectorCreds.length,
        0,
        connectorCreds[0].key
      );
      this.setState({
        connectorKey: newConnectionKey,
        originalConnectorkey: newConnectionKey,
      });
    } else {
      this.setState({
        connectorKey: providerConfig.type.toLowerCase(),
        originalConnectorkey: providerConfig.type.toLowerCase(),
      });
    }
    this.setState({
      selectedProvider: this.normalizeCredentialTypeNames(selectedProvider),
      connectorName: providerConfig.label,
    });
  };
  handleSaveEnvVars = () => {
    this.props.showCredBars();
    this.wrapperManageEnvVar.saveEnvVars();
  };

  showIntegrations = (isLocal) => {
    if (isLocal) {
      this.props.actions.markLocalFileSrcOrDest("isDestination");
    }
    this.setState({ showConnectionsIntg: true });
    this.props.showNewConnectorComponent("integrations");
  };
  handleGoToDest = (isLocal) => {
    if (isLocal) {
      this.props.actions.markLocalFileSrcOrDest("isSource");
    }
    this.props.showNewConnectorComponent("writer");
    const { redux } = this.props;
    const { project, main } = redux;

    let creds = project.environmentCredentials.find(
      (e) => e.wizard == "isDestination"
    );
    if (creds) {
      const providerConfig = main.providers.find(
        (p) => p.type === creds.credentialType
      );
      this.setState({
        selectedProvider: providerConfig.type,
        editEnvColor: false,
        showConnector: true,
        connectorKey: creds.key,
        originalConnectorkey: creds.key,
        connectorName: creds.connectionName || providerConfig.label,
      });
    } else {
      this.setState({ disabledNext: true, selectedProvider: null });
    }
  };
  handleCheckData = (wizard) => {
    if (wizard == "isSource") {
      this.setState({ checkedSource: true });
    } else if (wizard == "isDestination") {
      this.setState({ checkedDestination: true });
    }
  };
  render() {
    const {
      env,
      redux,
      adapterSecret,
      newConnector,
      warnBeforeDelete,
      showNewConnector,
      showConnectorType,
      newUserExperience,
      isWizard,
      match,
    } = this.props;
    const {
      contentfulTemplates,
      connectorKey,
      originalConnectorkey,
      disableSave,
      disableSaveConnector,
      disableDeleteConnector,
      showSaveLoader,
      disabledNext,
      checkedSource,
      checkedDestination,
      showConnectionsIntg,
      misconfiguredConnectors,
      singleMisconfiguredConnectors,
      multipleConnectors,
    } = this.state;
    const { project, main } = redux;
    const { params } = match;
    const { appId } = params;
    const environmentVariables =
      project.environmentCredentials &&
      project.environmentCredentials.filter((ec) => {
        return ec.credentialType == "EnvironmentVariables";
      });
    const { selectedProvider, showConnector } = this.state;
    const providerConfig = main.providers.find(
      (p) => p.type === selectedProvider
    );
    const isAdapter =
      providerConfig &&
      (providerConfig.metadataEndpoint === "testconnection" ||
        providerConfig.metadataEndpoint === "adaptermetadata");
    const showProviderForm =
      (providerConfig && providerConfig.credentials) || isAdapter;
    const connectorCreds =
      project.environmentCredentials &&
      project.environmentCredentials.find(
        (c) => c.key === originalConnectorkey
      );
    const noCheckConnection =
      providerConfig && providerConfig.noCheckConnectionYet;
    const addCon = selectedProvider ? true : false;

    return (
      <div>
        {showConnector || showNewConnector ? (
          <div style={{ height: "90vh" }}>
            {newUserExperience && (
              <div
                style={{
                  display: "flex",
                  background: "white",
                  height: 40,
                  padding: 9,
                  marginBottom: 10,
                  marginRight: 10,
                  marginLeft: 20,
                }}
              >
                <div
                  className="dot"
                  style={
                    checkedSource
                      ? { background: "#4385f6", color: "white" }
                      : {}
                  }
                >
                  1
                </div>
                <span
                  style={{
                    fontSize: 15,
                    color: showConnectorType == "reader" ? "#6C63FF" : "",
                    fontWeight: showConnectorType == "reader" ? "bold" : "",
                  }}
                >
                  {" "}
                  Connect Source
                </span>
                <div
                  style={{
                    borderTop: "1px solid rgb(223 224 224)",
                    marginLeft: 20,
                    marginRight: 20,
                    width: 80,
                    marginTop: 10,
                  }}
                />
                <div
                  className="dot"
                  style={
                    checkedDestination
                      ? {
                          background: "#4385f6",
                          color: "white",
                        }
                      : {}
                  }
                >
                  2
                </div>

                <span
                  style={{
                    fontSize: 15,
                    color: showConnectorType == "writer" ? "#6C63FF" : "",
                    fontWeight: showConnectorType == "writer" ? "bold" : "",
                  }}
                >
                  {" "}
                  Connect Destination
                </span>
                <div
                  style={{
                    borderTop: "1px solid rgb(223 224 224)",
                    marginLeft: 20,
                    marginRight: 20,
                    width: 80,
                    marginTop: 10,
                  }}
                />
                <div className="dot">3</div>

                <span
                  style={{
                    fontSize: 15,
                    color: showConnectorType == "integrations" ? "#6C63FF" : "",
                    fontWeight:
                      showConnectorType == "integrations" ? "bold" : "",
                  }}
                >
                  {" "}
                  Create a recipe
                </span>
              </div>
            )}
            <img
              alt="spinner"
              src={images.GoBackLeft}
              height="18"
              style={
                newUserExperience
                  ? {
                      position: "absolute",
                      top: "2%",
                      marginLeft: "-6px",
                    }
                  : {}
              }
              onClick={() =>
                this.showBars(
                  newUserExperience && showConnectorType == "writer"
                    ? true
                    : false
                )
              }
            />
            {showConnectionsIntg && <NewTransformerIntg {...this.props} />}
            {!showConnectionsIntg && (
              <Fragment>
                {showNewConnector && !addCon && (
                  <Fragment>
                    <span
                      style={{
                        fontWeight: "bold",
                        position: "relative",
                        left: "35%",
                        fontSize: 17,
                      }}
                    >
                      Add a data{" "}
                      {showConnectorType == "reader" ? "Source" : "Destination"}
                    </span>
                    <ConnectorBar
                      ref={this.connectorBar}
                      {...this.props}
                      providerConfig={providerConfig}
                      scrollToMe={this.scrollToMe}
                      changeConnector={(providerType) =>
                        this.addNewCredentialToEnvironment(providerType)
                      }
                      adapterSecret={adapterSecret}
                    />
                    <Button
                      bsStyle="default"
                      bsSize="small"
                      style={{
                        width: 65,
                        fontWeight: "bold",
                        marginTop: showNewConnector ? 0 : 10,
                        height: 30,
                        marginLeft: 5,
                        position: "fixed",
                        left: "36.8%",
                        bottom: "0.5%",
                      }}
                      onClick={() => {
                        showConnectorType == "reader"
                          ? this.handleGoToDest()
                          : this.showIntegrations();
                      }}
                    >
                      Skip
                    </Button>
                  </Fragment>
                )}
                <If condition={addCon === true}>
                  <Then>
                    {showProviderForm ? (
                      <Fragment>
                        {!newUserExperience && showNewConnector ? (
                          <span
                            style={{
                              fontWeight: "bold",
                              marginLeft: 5,
                            }}
                          >
                            Add a data{" "}
                            {showConnectorType == "reader"
                              ? "Source"
                              : "Destination"}
                          </span>
                        ) : !newUserExperience ? (
                          <span
                            style={{
                              fontWeight: "bold",
                              marginLeft: 5,
                            }}
                          >
                            Edit Connection
                          </span>
                        ) : null}
                        <ConnectorSection
                          misconfiguredConnectors={misconfiguredConnectors}
                          multipleConnectors={multipleConnectors}
                          {...this.props}
                          ref={(instance) => {
                            this.wrapperCredSec = instance;
                          }}
                          providerConfig={providerConfig}
                          creds={connectorCreds}
                          adapterSecret={adapterSecret}
                          selectedProvider={selectedProvider}
                          newConnector={newConnector}
                          disable={() =>
                            this.setState({ disableSaveConnector: true })
                          }
                          disableDelete={() => {
                            this.setState({ disableDeleteConnector: true })
                          }}
                          enable={() =>
                            this.setState({ disableSaveConnector: false })
                          }
                          enableDelete={() => {
                            this.setState({ disableDeleteConnector: false })
                          }}
                          connectorKey={connectorKey}
                          connectorName={this.state.connectorName}
                          setConnectorKey={(e) => {
                            this.setState({ connectorKey: e });
                          }}
                          setconnectorName={(e) => {
                            this.setState({ connectorName: e });
                          }}
                          stopSaveLoader={(e) => {
                            this.setState({ showSaveLoader: false });
                          }}
                          enableNext={(e) => {
                            this.setState({ disabledNext: false });
                          }}
                          handleCheckData={(e) => {
                            this.handleCheckData(e);
                          }}
                        />
                      </Fragment>
                    ) : (
                      <ManageEnvVars
                        {...this.props}
                        ref={(instance) => {
                          this.wrapperManageEnvVar = instance;
                        }}
                        ev={environmentVariables}
                        customForm={true}
                        disable={() => this.setState({ disableSave: true })}
                        enable={() => this.setState({ disableSave: false })}
                      />
                    )}
                  </Then>
                </If>
                <div
                  style={{
                    width: showNewConnector ? "82%" : "63%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {providerConfig && providerConfig.type != "LocalFile" && (
                    <Fragment>
                      <Button
                        bsStyle="default"
                        bsSize="small"
                        disabled={
                          !showProviderForm ? disableSave : disableSaveConnector
                        }
                        style={{
                          width: 155,
                          fontWeight: "bold",
                          marginTop: showNewConnector ? 0 : 10,
                          height: 30,
                          marginLeft: showNewConnector ? 12 : 0,
                        }}
                        onClick={() => {
                          this.setState({ showSaveLoader: true });
                          showProviderForm
                            ? this.wrapperCredSec.saveCreds(
                                newUserExperience
                                  ? showConnectorType == "reader"
                                    ? "isSource"
                                    : "isDestination"
                                  : null
                              )
                            : this.handleSaveEnvVars();
                        }}
                        showSaveLoader={showSaveLoader}
                      >
                        {showSaveLoader ? (
                          <img src={images.ajax_loader} />
                        ) : !showProviderForm ? (
                          "Save Variables"
                        ) : noCheckConnection ? (
                          "Save Connection"
                        ) : (
                          "Save & Test Connection"
                        )}
                      </Button>

                      {!showNewConnector && (
                        <Button
                          bsStyle="default"
                          bsSize="small"
                          disabled={
                            !showProviderForm ? disableSave : disableDeleteConnector
                          }
                          style={{
                            width: 65,
                            fontWeight: "bold",
                            marginTop: showNewConnector ? 0 : 10,
                            height: 30,
                            marginLeft: 5,
                          }}
                          onClick={() => {
                            warnBeforeDelete(
                              connectorCreds.credentialType,
                              this.state.connectorKey,
                              project.environmentCredentials.filter((i) => { return i.providerLabel === connectorCreds.providerLabel }),
                              connectorCreds.defaultConnection,
                            );
                          }}
                        >
                          Delete
                        </Button>
                      )}
                      {newUserExperience && (
                        <Fragment>
                          <Button
                            bsStyle="default"
                            bsSize="small"
                            style={{
                              width: 65,
                              fontWeight: "bold",
                              marginTop: showNewConnector ? 0 : 10,
                              height: 30,
                              marginLeft: 5,
                            }}
                            disabled={disabledNext}
                            onClick={() => {
                              showConnectorType == "reader"
                                ? this.handleGoToDest()
                                : this.showIntegrations();
                            }}
                          >
                            Next
                          </Button>
                          <Button
                            bsStyle="default"
                            bsSize="small"
                            style={{
                              width: 65,
                              fontWeight: "bold",
                              marginTop: showNewConnector ? 0 : 10,
                              height: 30,
                              marginLeft: 5,
                            }}
                            onClick={() => {
                              showConnectorType == "reader"
                                ? this.handleGoToDest()
                                : this.showIntegrations();
                            }}
                          >
                            Skip
                          </Button>
                        </Fragment>
                      )}
                    </Fragment>
                  )}

                  {providerConfig && !newUserExperience && (
                    <Button
                      bsStyle="default"
                      bsSize="small"
                      style={{
                        width: 65,
                        fontWeight: "bold",
                        marginTop: showNewConnector ? 0 : 10,
                        height: 30,
                        marginLeft: 5,
                      }}
                      onClick={() => this.showBars()}
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              </Fragment>
            )}
          </div>
        ) : newUserExperience ? (
          <div className="newConnectionBox">
            <span>
              Now we'll guide you through setup of your first data source
              connector and destination
            </span>
            <Button
              bsStyle="default"
              bsSize="small"
              className="setup-connection"
              onClick={() => this.props.showNewConnectorComponent("reader")}
            >
              Setup connections
              <img
                src={images.ArrowForward}
                alt="arrowForward"
                height="20px"
                style={{ marginLeft: 2 }}
              />
            </Button>
          </div>
        ) : (
          <div style={{ background: "#EEE", width: "100%" }}>
            <Table bordered>
              <thead>
                <tr>
                  <th></th>
                  <th>Connection Name</th>
                  <th>Connection Key</th>
                  <th>Source/Destination</th>
                  <th>Default/API Accessible</th>
                  <th>Connection</th>
                </tr>
              </thead>
              <tbody>
                {project.environmentCredentials.map((c, i) => {
                  const providerType = this.normalizeCredentialTypeNames(
                    c.providerLabel
                  );
                  const providerConfig = main.providers.find(
                    (p) => p.type === providerType
                  );
                  const isSelected = providerType === selectedProvider;
                  if (!providerConfig) {
                    return <span key={"nope_" + c.providerLabel} />;
                  }
                  let providerLogo = contentfulTemplates.find(
                    (c) => c.fields.title == providerConfig.label
                  );
                  return (
                    <CredBar
                      key={i}
                      {...this.props}
                      isSelected={isSelected}
                      providerConfig={providerConfig}
                      creds={c}
                      env={env}
                      connectorKey={c.key}
                      adapterSecret={adapterSecret}
                      onClick={() =>
                        this.showConnectorDetails(providerConfig, c)
                      }
                      providerLogo={providerLogo}
                      deleteConnector={() =>
                        warnBeforeDelete(
                          c.providerLabel,
                          c.key,
                          project.environmentCredentials.filter((i) => { return i.providerLabel === c.providerLabel }),
                          c.defaultConnection
                        )
                      }
                      isMisconfigured={misconfiguredConnectors.includes(c.credentialType) ? true : false}
                      singleMisconfiguredConnectors={singleMisconfiguredConnectors.includes(c.credentialType) || singleMisconfiguredConnectors.includes(c.providerLabel) || singleMisconfiguredConnectors.includes(c.providerType) ? true : false}
                    />
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        {misconfiguredConnectors.length > 0 && this.state.modalShown === 0 && (
          <Modal show={this.state.modal} onHide={this.toggleModal} centered={"true"} backdrop="static">
            <Header>
              <Title style={{ fontWeight: "bold" }}>
                Action Required!
              </Title>
            </Header>
            <Body style={{ maxHeight: 460, overflowY: 'auto' }}>
              <div style={{ padding: 16 }}>
                <ReactTooltip place={"bottom"} />
                <h4 style={{ fontSize: 18 }}>This environment currently has <strong>{misconfiguredConnectors.length}</strong> misconfigured connector type(s).</h4>
                <p style={{ fontSize: 16 }}>Please review following connection(s) and <strong>mark one of the connection as default connection. </strong>
                  (
                  <a
                    className="know-more"
                    style={{ fontSize: 14, textDecorationLine: "underline" }}
                    data-tip={"Using multiple connections in a recipe"}
                    onClick={() => {
                      window.open("https://help.lingk.io/en/articles/331", '_blank', 'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes')
                    }
                    }>
                    <i>know more </i>
                    <span>
                      <img
                        src={images.OpenInNew}
                        height={15}
                      />
                    </span>
                  </a>)</p>
                {misconfiguredConnectors.map((v, i) => {
                  return (
                    <span key={i} style={{ marginLeft: 16, fontSize: 18 }}><strong>- {v === 'SalesforceAuthCode' ? 'Salesforce' : v}</strong><br /></span>
                  )
                })}
              </div>
            </Body>
            <Footer>
              <Button bsStyle="primary" onClick={() => { this.toggleModal() }}>
                OK
              </Button>
            </Footer>
          </Modal>
        )}
      </div>
    );
  }
}

export default Connectors;
