import React, { Component } from "react";
import Activity from "../account/quickAction";
import EE from "../utils/eventEmitter";
import ReactGA from "react-ga";

class GetStarted extends Component {
  constructor() {
    super();
    this.state = {
      hi: "hi",
    };
  }
  componentDidMount() {
    const { redux } = this.props;

    const { main } = redux;
    const { tenantList } = main;
    let tenants = [];
    tenantList.map((t) => tenants.push(t.Key));
    window._elev.on("load", function (_elev) {
      _elev.setUser({
        groups: tenants,
      });
    });
  }
  chooseActivity = (activity) => {
    let event = "Quick Action: " + activity.name;
    ReactGA.ga("send", "event", {
      eventCategory: "QuickActions",
      eventAction: "Click",
      eventLabel: event,
    });
    window.Intercom("trackEvent", event);
    if (activity.actionType === "wizard") {
      EE.emit("choose-workspace", {
        why: "wizard",
        arg: activity.actionArg,
      });
    } else if (activity.actionType === "elevio") {
      window._elev.openArticle(parseInt(activity.actionArg));
    } else if (activity.actionType === "template") {
      EE.emit("choose-workspace", {
        why: "template",
        arg: activity.actionArg,
      });
    } else if (activity.actionType === "external-link") {
      window.open(activity.actionArg, '_blank', 'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes');
    } else {
      console.log("no wiz type id");
    }
  };

  render() {
    const { redux } = this.props;
    const { main } = redux;
    if (!main.masterQuickActions) return <div />;
    //const width = main.masterQuickActions.length*215
    return (
      <div style={styles.content}>
        <div
          style={{
            ...styles.section,
            width: 860,
            display: "block",
            overflow: "visible",
          }}
        >
          <div style={styles.header}>
            <div
              style={{
                ...styles.title,
                width: 218,
                background: "#f5f5f5",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 10,
                height: 50,
                borderRight: "1px solid #ccc",
              }}
            >
              Quick Actions
            </div>
            <div style={styles.elevioSearch}>
              <elevio-element
                data-type="search"
                id="elevio-envelope"
              ></elevio-element>
            </div>
          </div>
          {main.masterQuickActions.length > 0 && (
            <div style={{ padding: "20px 10px 10px 10px" }}>
              {main.masterQuickActions.map((a, i) => {
                return (
                  <Activity
                    key={i}
                    {...this.props}
                    active={
                      a.actionType === "recipe" && this.state.isCreatingRecipe
                    }
                    activity={a}
                    onChoose={this.chooseActivity}
                  />
                );
              })}
            </div>
          )}
        </div>

        <div style={{ width: 940 }}>
          <div style={{ ...styles.section, minHeight: 200, marginBottom: 60 }}>
            <div style={{ ...styles.header, background: "#f5f5f5" }}>
              <div style={styles.title}>Tips & Tricks</div>
            </div>

            <div
              className="custom-elevio-category"
              style={{
                ...styles.sectionContent,
                paddingLeft: 30,
                paddingRight: 30,
              }}
            >
              <elevio-element
                data-type="category"
                data-id="20"
              ></elevio-element>
            </div>
          </div>

          <div style={{ ...styles.section, minHeight: 200, marginLeft: 8 }}>
            <div style={styles.header}>
              <div style={styles.title}>Release Notes</div>
            </div>
            <div
              className="custom-elevio-category"
              style={styles.sectionContent}
            >
              <elevio-element data-type="category" data-id="5"></elevio-element>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  content: {
    marginLeft: 274,
    paddingRight: 20,
    paddingTop: 14,
    height: "100%",
    overflowY: "auto",
  },
  header: {
    width: "100%",
    marginBottom: 3,
    borderBottom: "1px solid #cccccc",
    position: "relative",
    height: 51,
  },
  title: {
    fontSize: 16,
    padding: "15px 18px 15px 18px",
    fontWeight: 500,
    display: "inline-block",
  },
  section: {
    background: "#f5f5f5",
    margin: 10,
    fontSize: 13,
    color: "#333333",
    border: "1px solid #cccccc",
    borderRadius: 4,
    position: "relative",
    overflow: "hidden",
    boxSizing: "content-box",
    verticalAlign: "top",
    display: "inline-block",
  },
  hiddensection: {
    margin: 10,
    fontSize: 13,
    color: "#333333",
    borderRadius: 4,
    position: "relative",
    overflow: "hidden",
    boxSizing: "content-box",
    verticalAlign: "top",
    display: "inline-block",
  },
  elevioSearch: {
    width: 643, //'calc(100% - 218px)',
    position: "absolute",
    left: 217,
    top: 0,
    zIndex: 9,
  },
  sectionContent: {
    overflowY: "auto",
    paddingBottom: 18,
    // height:340,
    width: 420,
  },
};

export default GetStarted;
