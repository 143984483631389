import React, { Component } from 'react';
import images from '../images'
import HistoryBox from '../utils/historyBox'
import {withRouter} from 'react-router-dom'
const MyContext = React.createContext(); 
 class WizardTabs extends Component {

  constructor(props) {
    super(props);
    const pathArray = props.location.pathname.split('/');

    let sel = 0
    if(pathArray.length<6) sel=0
    else {
      if(pathArray[5]==='deploy') sel=1
      else if(pathArray[5]==='health') sel=2
      else if(pathArray[5]==='info') sel=3
    }

    this.state = {
      selected:sel,
      piGuid:null,
      typeId:null
    };
    this.select = this.select.bind(this);
  }

  componentWillMount(){
    const {actions, location, match, redux} = this.props
    const {params}=match
    const { appId, transId } = params
    const {state} = location
    const {project} = redux
    if(parseInt(transId, 10)){
      actions.callGetTransformerLogs(project.tenantInfo.TenantId, transId, "",100)
      actions.callGetDeploymentsForTransformer(appId, transId)
      actions.callGetAllTriggers(transId)
    }
    if(!(state && state.piGuid && state.typeId)){
      // hard refresh
      if(parseInt(transId, 10)){
        actions.callGetTransformerVersions(transId) // this is the baseId
        .then((r)=>{
          this.setState({piGuid:r.GeneratedFromPi, typeId: r.GeneratedFromPiTypeId})
          actions.callGetPiConfig(project.tenantInfo.TenantId, appId, r.GeneratedFromPi)
        })
      } else {
        // transId === 0 means that there is no recipe yet
        this.props.history.push({
          pathname: `/a/${appId}/wiz`
        })
      }
    } else {
      // coming from boxwizard.js
      if(parseInt(transId, 10)){
        actions.callGetTransformerVersions(transId)
      }
      this.setState({piGuid:state.piGuid, typeId: state.typeId})
      actions.callGetPiConfig(project.tenantInfo.TenantId, appId, state.piGuid)
    }
  }

  componentWillUnmount(){
    this.props.actions.clearTransformer();
  }

  select(selected, path) {
    this.setState({selected})
    this.props.history.push(path);
  }

  render() {
    const { children, redux, actions, location } = this.props;
    const {main, project, transformer} = redux
    const styles = {
      main:{
        background:'#bbb',
        width:'100%',
        height:'calc(100% - 50px)'
      },
      tab:{
        display:'inline-block',
        height:42,
        width:155,
        borderRadius: 2,
        textAlign:'center',
        paddingTop:12,
        cursor:'pointer'
      },
      tabs:{
        marginLeft:20,
        marginTop:50,
      },
    }
    const { appId, transId } = this.props.match.params;
    const items=[
      {name:'Configure', path:`/a/${appId}/wiz/${transId}`},
      {name:'Schedule', path:`/a/${appId}/wiz/${transId}/deploy`},
      {name:'Monitor', path:`/a/${appId}/wiz/${transId}/health`},
      //{name:'Settings', path:`/a/${appId}/wiz/${transId}/info`}
    ]
    const {piGuid, typeId} = this.state
    const c = transformer.savedConfiguration
    
    const configureMode = location.pathname.includes('configure') || 
      location.pathname.includes('start')
    let innerHeight = 'calc(100% - 42px)'
    if(configureMode){
      innerHeight = '100%'
    }

    return (
      <div className="parent-env">
      <div style={styles.main}>
        <div style={styles.tabs}>
          {items.map((item, index) =>
            <div onClick={()=>this.select(index, item.path)} style={styles.tab} key={index}
              className={this.state.selected === index ? 'sel-tabby' : 'tabby'}>
              {item.name}
            </div>
          )}
          <HistoryBox redux={redux} type="Wizard"
            lastModified={c && c.lastModified} 
            lastModifiedBy={c && c.lastModifiedBy} 
          />
        </div>
        { piGuid && typeId && project.tenantInfo && project.appData &&
          main.wizards && (transformer.versions || transId==='0') &&
          transformer.wizardVersions ? (
            <div style={{height:innerHeight,background:'#dcdfdf'}}>
              {/* {React.isValidElement(children) ?
                React.cloneElement(children,
                { redux, actions, piGuid, typeId },
              ) : null} */}
      <MyContext.Provider value={{redux, actions, piGuid, typeId}}>
          {this.props.children}
      </MyContext.Provider>
            </div>
        ) : <div style={{height:innerHeight,background:'#dcdfdf'}}>
          <div className="ajax-loader">
            <img alt="spinner" src={images.ajax_loader} />
          </div>
        </div>}
      </div>
      </div>
    );
  }
}
export default withRouter(WizardTabs)
export {MyContext};