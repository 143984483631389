import React, { Component } from "react";

import images from "../images";
import { withRouter } from "react-router-dom";

class OverviewTabs extends Component {
  constructor(props) {
    super(props);
    const pathArray = props.location.pathname.split("/");

    let sel = 0;
    if (pathArray.length < 4) sel = 0;
    else {
      if (pathArray[4] === "team") sel = 1;
      else if (pathArray[4] === "settings") sel = 2;
    }

    this.state = {
      selected: sel,
    };
    this.select = this.select.bind(this);
    this.updateTab = this.updateTab.bind(this);
  }

  componentDidMount() {
    const { actions, redux, match } = this.props;
    const { project } = redux && redux;
    const { params } = match;
    const { appId } = params;
    if (project && project.tenantInfo) {
      const { TenantId } = project.tenantInfo;
      actions.clearWebhookSubscriptions();
      actions.getUsersForWorkspace(project.tenantInfo.TenantId, params.appId); // project.permissions
      // actions.callGetTransformerLogsForWorkspace(TenantId, appId, 0);
      actions.callGetTransformerLogsFirst100Workspace(TenantId, appId);

    }
  }

  updateTab() {
    const pathArray = props.location.pathname.split("/");

    let sel = 0;
    if (pathArray.length < 4) sel = 0;
    else {
      if (pathArray[4] === "team") sel = 1;
      else if (pathArray[4] === "settings") sel = 2;
    }

    this.setState({ selected: sel });
  }

  select(selected, path) {
    this.setState({ selected });
    this.props.history.push(path);
  }

  render() {
    const { children, redux, actions, match } = this.props;
    const { project } = redux;
    const updateTab = this.updateTab;
    const { params } = match;

    const styles = {
      main: {
        background: "#bbb",
        paddingTop: 0,
        width: "100%",
        height: "100%",
      },
      tab: {
        display: "inline-block",
        height: 42,
        width: 200,
        borderRadius: 2,
        textAlign: "center",
        paddingTop: 12,
        cursor: "pointer",
      },
      tabs: {
        marginLeft: 80,
        marginTop: 50,
        height: "100%",
      },
    };

    const teamlength = project.permissions ? project.permissions.length : 0;

    const { appId } = params;
    const items = [
      { name: "Dashboard", path: `/a/${appId}/dash` },
      { name: `Team (${teamlength})`, path: `/a/${appId}/dash/team` },
      { name: "Settings", path: `/a/${appId}/dash/settings` },
    ];

    return (
      <div className="parent-env">
        <div className="tab-replacer">
          {/*}
      <div style={styles.main}>
        <div className="styles-tabs">
          {items.map((item, index) =>
            <div onClick={()=>this.select(index, item.path)} style={styles.tab} key={index}
              className={(this.state.selected === index ? 'sel-tabby' : 'overview-tabby') + ` ${item.className}`}>
              {item.name}
            </div>
          )}

          <HistoryBox redux={redux} type="Workspace"
            lastModified={redux.project.appData.lastmodified} 
            lastModifiedBy={redux.project.appData.lastModifiedBy} 
          />
          
        */}

          {project.appData ? (
            <div style={{ background: "#dcdfdf" }}>
              {React.isValidElement(children)
                ? React.cloneElement(children, { redux, actions, updateTab })
                : null}
            </div>
          ) : (
            <div style={{ height: "100%", background: "#dcdfdf" }}>
              <div className="ajax-loader">
                <img alt="spinner" src={images.ajax_loader} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(OverviewTabs);
