import React from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import alltypes from './alltypes'

function getColorByBgColor(bgColor) {
  if (!bgColor) {
    return ''
  }
  return parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2 ? '#000' : '#fff'
}

const TypesDropdown = props => {
  return (
    <div>
      <DropdownButton
        bsStyle="default"
        bsSize="small"
        style={{ width: 150, fontWeight: 'bold', textAlign: 'left' }}
        id="types"
        title={props.type ? props.type : 'Select type'}
        placeholder="Types"
        className="environments-dropdown">
        {alltypes.map((obj, i) => {
          const name = obj.name
          const isActive = name === props.type
          return (
            <MenuItem
              value={obj.value}
              key={i}
              active={isActive}
              onClick={() => props.selectType(null, name)}>
              <strong>{name}</strong>
            </MenuItem>
          )
        })}
      </DropdownButton>

      {props.type ? (
        <img
          style={{ marginLeft: 14, height: 32 }}
          role="presentation"
          src={alltypes.find(f => f.name === props.type).logo}
        />
      ) : null}
    </div>
  )
}

export default TypesDropdown
