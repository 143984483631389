import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
const { Body } = Modal;

// const CheckInMod = ({show, hide, handleCheckIn, curVersion, nextVer}) => {

function CheckInMod(props) {
  const {
    show,
    hide,
    handleCheckIn,
    curVersion,
    nextVer,
    handleCommentChange,
    comment,
  } = props;
  // const [comment, setComment] = useState("");

  return (
    <div>
      <Modal
        show={show}
        onHide={hide}
        restoreFocus={false}
        dialogClassName="confirm-delete-modal"
        backdrop="static"
      >
        <Body style={{ height: "fit-content" }}>
          <div
            style={{
              textAlign: "center",
              verticalAlign: "middle",
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <h2>Create Version {nextVer}?</h2>
            <div>
              <p>
                The current state will be stored as Version {curVersion}.<br />
                Version {nextVer} will be created for iterations.
              </p>
            </div>
            <div style={{ marginTop: 20 }}>
              <label style={{float: 'left'}}>Version {curVersion} Comment</label>
              <br />
              <textarea
                className="form-control"
                type="text"
                onChange={(e) => handleCommentChange(e.target.value)}
                value={comment}
                style={{ height: 35 }}
              />
            </div>

            <div style={{ marginTop: 20 }}>
              <Button bsStyle="default" onClick={hide}>
                Cancel
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                value="newVersion"
                bsStyle="primary"
                onClick={() => handleCheckIn(false)}
              >
                Create
              </Button>
            </div>
          </div>
        </Body>
      </Modal>
    </div>
  );
}

export default CheckInMod;
