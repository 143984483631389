import React, { Component } from "react";
import { Link } from "react-router-dom";
import SettingsStyle from "../../styles/settingsstyle.js";
import images from "../images";
import Toggle from "react-toggle";

export default class SettingsMenu extends Component {
  constructor() {
    super();
    this.state = {
      hidehelpers: false,
    };
  }
  handleToggle = () => {
    let hidehelpers = this.state.hidehelpers;
    hidehelpers = !hidehelpers;
    this.setState({ hidehelpers });
    if (hidehelpers) {
      document.body.classList.add("hide-elevio");
      window.localStorage.setItem("hideHelpers", "true");
    } else {
      document.body.classList.remove("hide-elevio");
      window.localStorage.setItem("hideHelpers", "false");
    }
  };

  componentWillMount() {
    const hidinghelpers = JSON.parse(
      window.localStorage.getItem("hideHelpers")
    );
    if (hidinghelpers) {
      document.body.classList.add("hide-elevio");
      this.setState({ hidehelpers: true });
    } else {
      document.body.classList.remove("hide-elevio");
      this.setState({ hidehelpers: false });
    }
  }
  render() {
    const { logout } = this.props;
    const style = {
      boxSizing: "border-box",
      borderRadius: 3,
      backgroundColor: "#fff",
      whiteSpace: "nowrap",
      display: "block",
      zIndex: 2000,
      position: "absolute",
      right: 1,
      top: "100%",
      // border: '1px solid #c9d7df',
      width: 225,
      transition: "height 0.15s",
      WebkitTransition: "height 0.15s",
      overflow: "hidden",
      height: this.props.show ? 210 : 0,
      boxShadow: this.props.show
        ? "0 1px 15px 0 rgba(0,0,0,.3),0 0 0 0 transparent"
        : "none",
    };

    const { identity } = this.props;
    const handleHoverOff = () => {
      this.props.actions.setShowMenu(false);
    };
    return (
      <div>
        <div
          id="settings-menu"
          style={style}
          className="menu-dropdown"
          onMouseLeave={handleHoverOff}
        >
          <div style={{ padding: "14px 5px" }}>
            <Link to="/user" style={{ textDecoration: "none" }}>
              <div style={{ padding: "8px", paddingLeft: "0px" }}>
                <div style={SettingsStyle.menuIconStyles}>
                  <img
                    src={images.ic_settings_grey600_48dp}
                    style={SettingsStyle.iconStyles}
                    role="presentation"
                  />
                </div>
                <div id="account-settings" style={SettingsStyle.menuTextStyles}>
                  Account Settings
                </div>
              </div>
            </Link>

            {identity && identity.role == "tech-support" && (
              <Link to="/swagger" style={{ textDecoration: "none" }} target="_blank">
                <div style={{ padding: "8px", paddingLeft: "0px" }}>
                  <div style={SettingsStyle.menuIconStyles}>
                    <img
                      src={images.ic_settings_system_daydream_grey600_48dp}
                      style={SettingsStyle.iconStyles}
                      role="presentation"
                    />
                  </div>
                  <div id="account-settings" style={SettingsStyle.menuTextStyles}>
                    Developer APIs
                  </div>
                </div>
              </Link>
            )}

            <a href="https://apiexplorer.lingkapis.com" target="_new">
              <div style={{ padding: "8px", paddingLeft: "0px" }}>
                <div style={SettingsStyle.menuIconStyles}>
                  <img
                    src={images.ic_settings_system_daydream_grey600_48dp}
                    style={SettingsStyle.iconStyles}
                    role="presentation"
                  />
                </div>
                <div style={SettingsStyle.menuTextStyles}>API Explorer</div>
              </div>
            </a>

            <div
              style={{ padding: "8px", paddingLeft: "0px", display: "flex" }}
            >
              <div className="helpers-toggle">
                <Toggle
                  checked={this.state.hidehelpers}
                  className="custom-toggle-btn"
                  icons={{
                    checked: (
                      <span className="toggle-label toggle-label-hide">
                        hide
                      </span>
                    ),
                    unchecked: (
                      <span className="toggle-label toggle-label-show">
                        show
                      </span>
                    ),
                  }}
                  onChange={this.handleToggle}
                />
              </div>
              <span
                style={{
                  fontSize: 14,
                  color: "rgb(71, 140, 201)",
                  marginLeft: 5,
                  marginTop: 3,
                  fontWeight: "bold",
                }}
              >
                Help icons
              </span>
            </div>
            <a href="#" onClick={logout}>
              <div style={{ padding: "8px", paddingLeft: "0px" }}>
                <div style={SettingsStyle.menuIconStyles}>
                  <img
                    src={images.ic_close_grey600_48dp}
                    style={SettingsStyle.iconStyles}
                    role="presentation"
                  />
                </div>
                <div style={SettingsStyle.menuTextStyles}>Logout</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
