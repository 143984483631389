import React, { Component } from "react";
import { Table, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import JSONbig from "json-bigint";
import Pagination from "react-js-pagination";
class LogResultTable extends Component {
  constructor() {
    super();
    this.state = {
      raw: false,
      recordsFromShow: 0,
      recordsToShow: 9,
      activePage: 1,
      totalRecords: 10,
      showPagination: false,
    };
  }

  handleToggle = (e) => {
    this.setState({ raw: e === "JSON" });
  };

  componentDidMount() {
    const { result } = this.props;
    const rows = result.split("\n").filter((r) => !r.includes("<truncated>"));
    if (rows && rows.length > 0) {
      this.setState({ totalRecords: rows.length });
    }
    if (rows && rows.length > 10) {
      this.setState({ showPagination: true });
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber == 1) {
      this.setState({
        activePage: pageNumber,
        recordsFromShow: 0,
        recordsToShow: 9,
      });
      return;
    }
    this.setState({
      activePage: pageNumber,
      recordsFromShow: pageNumber * 10 - 10,
      recordsToShow: pageNumber * 10 - 1,
    });
  }

  render() {
    const { result } = this.props;
    const { raw } = this.state;
    const rows = result.split("\n").filter((r) => !r.includes("<truncated>"));
    let header = [];
    rows.forEach((r) => {
      let row = {};
      try {
        row = JSON.parse(r);
      } catch (e) {
        row = {};
      }
      Object.keys(row).forEach((k) => {
        if (!header.includes(k)) {
          header.push(k);
        }
      });
    });
    let a = [];
    if (rows && rows.length > 0) {
      for (
        let i = this.state.recordsFromShow;
        i <= this.state.recordsToShow;
        i++
      ) {
        let row = {};
        if (rows[i]) {
          try {
            row = JSONbig.parse(rows[i]);
          } catch (e) {
            row = {};
          }
        }
        if (row && Object.keys(row).length > 0) {
          a.push(
            <tr key={i}>
              {header.map((k, ii) => {
                let val = row[k];
                return (
                  <td key={ii} style={{ whiteSpace: "nowrap" }}>
                    <pre
                      style={{
                        border: "none",
                        background: "transparent",
                        padding: 0,
                      }}
                    >
                      {JSON.stringify(val, null, 2)}
                    </pre>
                  </td>
                );
              })}
            </tr>
          );
        }
      }
    } else {
      a.push(<tr key={i} style={{ height: 0 }} />);
    }

    if (header && header.length > 0) {
      return (
        <div className="logs-printer-wrap">
          <div style={{ display: "flex" }}>
            {this.state.showPagination && !raw && (
              <div
                style={{
                  padding: 4,
                  fontSize: 11,
                  display: "grid",
                  width: "50%",
                }}
              >
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecords}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  hideFirstLastPages={true}
                />
                <span style={{ fontStyle: "oblique" }}>
                  Pages of 10 with a maximum of 100 records
                </span>
              </div>
            )}
            <div
              style={{
                height: 40,
                width: this.state.showPagination && !raw ? "50%" : "100%",
              }}
            >
              <ToggleButtonGroup
                type="radio"
                bsSize="xsmall"
                name="options"
                value={raw ? "JSON" : "Table"}
                onChange={this.handleToggle}
                style={{ float: "right" }}
              >
                <ToggleButton value={"Table"}>Table</ToggleButton>
                <ToggleButton value={"JSON"}>JSON</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          {raw ? (
            <div>{result}</div>
          ) : (
            <div className="logs-printer-horizontal-scroller">
              <Table className="logs-printer-table">
                <thead>
                  <tr>
                    {header.map((h, i) => (
                      <th key={i} style={{ whiteSpace: "nowrap" }}>
                        {h}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>{a}</tbody>
              </Table>
            </div>
          )}
        </div>
      );
    } else {
      return <span>{result}</span>;
    }
  }
}

export default LogResultTable;
