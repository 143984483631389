import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap'
import moment from 'moment'
import Comment from './comment'
import images from '../images'

export default class CommentBar extends Component {
  constructor() {
    super()
    this.state = {
      comment: '',
      username: '',
      userId: 0,
      color: '#404040',
      windowIsUp: false,
      deleting: null
    }

    this.deleteComment = this.deleteComment.bind(this)
    this.handleComment = this.handleComment.bind(this)
    this.postComment = this.postComment.bind(this)
    this.listenForEnter = this.listenForEnter.bind(this)
    this.sizeCommentWindow = this.sizeCommentWindow.bind(this)
  }

  componentWillMount() {
    const { me } = this.props.redux.main
    const { query } = this.props.location

    if (query.comments || query.a) {
      this.setState({
        username: me.username,
        userId: me.id,
        windowIsUp: true
      })
    } else {
      this.setState({
        username: me.username,
        userId: me.id
      })
    }
  }

  handleComment(q, e) {
    let comment = { ...this.state.comment }
    comment = e
    this.setState({ comment })
  }

  postComment() {
    const { project } = this.props.redux
    const { appId } = this.props.match.params
    const data = { ...this.state }

    if (data.comment !== '') {
      this.props.callPostComment(
        project.tenantInfo.TenantId,
        appId,
        this.props.containerId,
        data
      )

      this.setState({ comment: '' })
    }
  }

  deleteComment(a, commentId) {
    const { project } = this.props.redux
    const { appId } = this.props.match.params
    this.setState({ deleting: commentId })

    this.props.callDeleteComment(
        project.tenantInfo.TenantId,
        appId,
        this.props.containerId,
        commentId
      )
      .then(() => this.setState({ deleting: null }))
  }

  sizeCommentWindow() {
    let windowIsUp = this.state.windowIsUp
    windowIsUp = !windowIsUp
    this.setState({ windowIsUp })
  }

  listenForEnter(q, e) {
    if (e.keyCode !== 16 && e.keyCode === 13) {
      this.postComment()
    }
  }

  render() {
    const { me } = this.props.redux.main
    const { commentList } = this.props

    const commentcontainer = commentList || []
    
    commentcontainer.sort((var1, var2) => {
      let a = moment(var1.timeStamp).valueOf(),
        b = moment(var2.timeStamp).valueOf()
      if (a > b) return -1
      if (a < b) return 1
      return 0
    })

    return (
      <div
        style={{ position: 'fixed', right: 205, bottom: 0 }}
        className="analytics-comments">
        <div style={{ background: '#ffffffb9' }}>
          <div
            style={{
              width: '352px',
              padding: 5,
              borderRadius: 2,
              background: '#ffffffb9'
            }}>
            <div
              className="min-max-commentbar"
              onClick={() => this.sizeCommentWindow()}
              style={{
                position: 'relative',
                background: '#000000b3',
                overflow: 'hidden',
                color: '#eee',
                cursor: 'pointer',
                borderRadius: 2,
                padding: 2,
                height: 28,
                width: '100%',
                display: 'inline-block'
              }}>
              <img
                role="presentation"
                src={images.ic_view_headline}
                style={{ position: 'relative', float: 'right', height: '100%' }}
              />
              <div
                style={{
                  position: 'absolute',
                  left: 4,
                  top: 4,
                  width: 'fit-content',
                  height: 19,
                  textAlign: 'center',
                  paddingTop: 1,
                  borderRadius: 2,
                  background: '#fff'
                }}>
                <span
                  role="presentation"
                  style={{
                    fontWeight: 'bold',
                    color: '#000000b3',
                    fontSize: '14px',
                    padding: '0px 3px 0px 3px'
                  }}>
                  {commentcontainer && commentcontainer.length}
                </span>
              </div>
              {this.state.windowIsUp && this.state.windowIsUp ? null : (
                <div style={{ marginLeft: 134, marginTop: 2 }}>
                  <span>Comments</span>
                </div>
              )}
            </div>

            <div
              className="comments-area"
              style={this.state.windowIsUp ? styles.chatup : styles.chatdown}>
              <div style={{ maxHeight: '408px', overflowY: 'auto' }}>
                {!commentList ? (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <img alt="spinner" src={images.ajax_loader} />
                  </div>
                ) : (
                  <div>
                    {commentcontainer && commentcontainer.length > 0 ? (
                      commentcontainer.map((i, key) => {
                        return (
                          <Comment
                            key={key}
                            comment={i.comment}
                            deleting={this.state.deleting}
                            commentId={i.id}
                            me={me}
                            time={i.timeStamp}
                            user={i.username}
                            deleteComment={this.deleteComment}
                          />
                        )
                      })
                    ) : (
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 30,
                          marginBottom: 30
                        }}>
                        <span>No comments</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div style={{ marginTop: 16 }}>
                <FormControl
                  tabIndex="0"
                  style={{ width: '100%' }}
                  placeholder="comment"
                  value={this.state.comment}
                  onKeyDown={e => this.listenForEnter(null, e)}
                  onChange={e => {
                    this.handleComment(null, e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  chatup: {
    maxHeight: '460px',
    overflow: 'hidden',
    opacity: 1
  },
  chatdown: {
    maxHeight: '0px',
    overflow: 'hidden',
    opacity: 0
  }
}
