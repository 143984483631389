import React from "react";
import { Button } from "react-bootstrap";

function convert(value) {
  return Math.floor(value / 60) + ":" + (value % 60 ? value % 60 : "00");
}

const ExtendToken = (props) => {
  const { secondsToExpire, auth, logout } = props;
  let minutesToExpire = convert(secondsToExpire);
  const styles = {
    close: {
      position: "absolute",
      right: 10,
      cursor: "pointer",
    },
    dialog: {
      position: "fixed",
      top: "50%",
      left: "50%",
      width: 500,
      height: 300,
      marginTop: -200,
      marginLeft: -250,
      boxSizing: "border-box",
      borderRadius: 3,
      padding: "2rem",
      backgroundColor: "#fff",
      display: "block",
      boxShadow: "0 1px 15px 0 rgba(0,0,0,.1),0 0 0 0 transparent",
      zIndex: 2000,
      border: "1px solid #c9d7df",
    },
    scrollingDiv: {
      overflow: "auto",
      height: 300,
    },
  };
  let options = {};
  const pathname = window.location.pathname + window.location.search;
  if (pathname && pathname.length > 1) {
    options.auth = options.auth || {};
    options.auth.params = options.auth.params || {};
    options.auth.params.state = btoa(pathname);
    //options.auth.params.state this is where you store url extensions
  }
  return (
    <div>
      <div style={styles.dialog}>
        <h2>Are you still there?</h2>
        <hr />
        <div style={styles.scrollingDiv}>
          <p>
            Your current Lingk session is about to expire. For your security,
            the session will automatically end after 5 minutes of inactivity.
          </p>
          <strong>
            {`Your current session will expire in ${minutesToExpire} minutes.`}
          </strong>
          <hr />
          <div style={{ marginTop: "1rem" }}>
            <Button bsStyle="primary" onClick={() => auth.login(options)}>
              Continue with my session
            </Button>
            <Button style={{ marginLeft: ".5rem" }} onClick={() => logout()}>
              Log out now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtendToken;
