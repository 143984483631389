import React, { Component } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import Toggle from 'react-toggle'
import ReactTooltip from "react-tooltip";
import images from '../images';

//email regex
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default class UserInviteModal extends Component {

  constructor() {
    super();
    this.state = {
      isInvitingUsers: false,
      emailInput: '',
      invitingUserEmails: [],
      invitingUserEmailAdmins: {},
      validationError: ''
    };
    this.removeEmailUser = this.removeEmailUser.bind(this)
    this.updateInvitedEmailUserAdmin = this.updateInvitedEmailUserAdmin.bind(this)
    this.finalInvite = this.finalInvite.bind(this)
    this.addEmailToSendList = this.addEmailToSendList.bind(this)
  }
  finalInvite() {
    const { actions, tenantId } = this.props
    const data = this.state.invitingUserEmails.map((email) => {
      return { email, isAdmin: this.state.invitingUserEmailAdmins[email] }
    })

    this.setState({ isInvitingUsers: true })
    actions.inviteUsersToTenant(tenantId, data)
      .then(() => {
        this.setState({ isInvitingUsers: false })
        this.props.onClose()
      })
  }
  updateInvitedEmailUserAdmin(e, userEmail) {
    const invitingUserEmailAdmins = { ...this.state.invitingUserEmailAdmins }
    invitingUserEmailAdmins[userEmail] = e.target.checked
    this.setState({ invitingUserEmailAdmins })
  }
  removeEmailUser(userEmail) {
    const invitingUserEmails = [...this.state.invitingUserEmails]
    const i = invitingUserEmails.findIndex(iuid => iuid === userEmail)
    invitingUserEmails.splice(i, 1)

    const invitingUserEmailAdmins = { ...this.state.invitingUserEmailAdmins }
    if (invitingUserEmailAdmins[userEmail]) delete invitingUserEmailAdmins[userEmail]

    this.setState({ invitingUserEmails, invitingUserEmailAdmins })
  }
  addEmailToSendList() {
    if (this.state.invitingUserEmails.find(u => u.email === this.state.emailInput)) {
      this.setState({ validationError: 'You have already added this email to the invite list' })
      return
    }
    // ADD IF USER EXISTS IN THE ORG ALREAY
    if (this.props.users.find(u => u.username === this.state.emailInput)) {
      this.setState({ validationError: 'The user is already a member of this Organization' })
      return
    }
    if (emailRegex.test(this.state.emailInput)) {
      const invitingUserEmails = [...this.state.invitingUserEmails]
      invitingUserEmails.push(this.state.emailInput)
      const invitingUserEmailAdmins = { ...this.state.invitingUserEmailAdmins }
      invitingUserEmailAdmins[this.state.emailInput] = false
      this.setState({ invitingUserEmails, emailInput: '', invitingUserEmailAdmins })
    } else {
      this.setState({ validationError: 'Please enter a valid email address' })
    }
  }
  render() {
    const { onClose } = this.props
    return (<div>
      <div className="overlay-100" />
      <div className="lingk-dialog"
        style={{ height: 500, width: 450, marginLeft: -200, marginTop: -200 }}>
        <h4 style={{ marginBottom: 22 }}>
          Invite users to this Organization
        </h4>

        <div style={{ overflowY: 'scroll', height: '71.5%' }}
          className="invite-users-content-wrap">
          <ReactTooltip place={"right"} className="tool-tip" />

          {this.state.invitingUserEmails && this.state.invitingUserEmails.length > 0 && <div>
            <div style={{ maxWidth: "450px" }}>
              <strong className="tool-tip-title">About access roles:</strong>
              <div>
                <p style={{ fontSize: "12px" }}><strong style={{ fontSize: "14px", fontWeight: "bold" }}>Admin: </strong>
                  Admin have the highest level of access to an Organization/Project. <br />ie. Admin can <strong>view/invite/edit/remove</strong> other users having any of the access role.</p>

                <p style={{ fontSize: "12px" }}><strong style={{ fontSize: "14px", fontWeight: "bold" }}>User: </strong>
                  User have the lowest level of access to an Organization/Project. <br />ie. User can only <strong>view</strong> other users and <strong>invite</strong> others with user access role only.</p>
              </div>
            </div>
            <div>Users to invite:</div>
            {this.state.invitingUserEmails.map((userEmail) => {
              const invitedUserIsAdmin = this.state.invitingUserEmailAdmins[userEmail]
              return (<UserToInvite key={userEmail} user={{ email: userEmail }}
                idProp="email" nameProp="email"
                invitedUserIsAdmin={invitedUserIsAdmin} removeUser={this.removeEmailUser}
                updateInvitedUserAdmin={this.updateInvitedEmailUserAdmin} />)
            })}
            <br />
          </div>}

          <div>
            <div>Invite new members by Email:</div>
            <div style={{ marginBottom: 12 }}>
              <FormControl type="text" className="form-control"
                style={{ width: 282, display: 'inline-block', marginRight: 9 }}
                onChange={(e) => {
                  this.setState({
                    emailInput: e.target.value.replace(/ /g, '').toLowerCase(),
                    validationError: ''
                  })
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    this.addEmailToSendList()
                  }
                }}
                value={this.state.emailInput} />
              <Button bsStyle="primary" bsSize="small" disabled={!this.state.emailInput}
                style={{ verticalAlign: 'top', marginTop: 2 }}
                onClick={this.addEmailToSendList}>
                Add
              </Button>
              {this.state.validationError && <div style={{ fontSize: '11px', color: '#ea5a5a' }}>
                {this.state.validationError}
              </div>}
            </div>
          </div>

        </div>

        <hr style={{ position: 'absolute', bottom: '5rem', width: '90%' }} />
        <div style={{ position: 'absolute', width: '90%', bottom: '2rem' }}>
          <Button
            bsStyle="default"
            disabled={this.state.isInvitingUsers}
            onClick={onClose}>
            Cancel
          </Button>
          <Button bsStyle="primary"
            onClick={this.finalInvite}
            disabled={this.state.isInvitingUsers ||
              !(this.state.invitingUserEmails && this.state.invitingUserEmails.length > 0)}
            style={{ marginLeft: '.5rem', float: 'right', width: 60 }}>
            {!this.state.isInvitingUsers ? 'Invite' :
              <img alt="small-spinner" src={images.ajax_loader_small} height="8" />}
          </Button>
        </div>
      </div>
    </div>)
  }
}

const UserToInvite = ({ user, idProp, nameProp, removeUser, updateInvitedUserAdmin, invitedUserIsAdmin }) => {
  return (<div key={user[idProp]} className="invited-tenant-user">
    <div className="invited-tenant-user-remove"
      onClick={() => removeUser(user[idProp])}>
      -
    </div>
    <div className="invited-tenant-user-content">
      <div>{user[nameProp]}</div>
      <div className="invited-tenant-user-admin-toggle">
        <Toggle
          className="custom-toggle-btn"
          checked={invitedUserIsAdmin}
          icons={{
            checked: <span className="toggle-label toggle-label-admin">admin</span>,
            unchecked: <span className="toggle-label toggle-label-user">user</span>
          }}
          onChange={e => updateInvitedUserAdmin(e, user[idProp])}
        />
      </div>
    </div>
  </div>)
}
