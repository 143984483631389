import React from "react";
import { DropdownButton, MenuItem } from "react-bootstrap";

function getColorByBgColor(bgColor) {
  if (!bgColor) {
    return "";
  }
  return parseInt(bgColor.replace("#", ""), 16) > 0xffffff / 2
    ? "#000"
    : "#fff";
}

const EnvironmentsDropdown = (props) => {
  const {
    uiEnvironment,
    environments,
    deployments,
    versions,
    disabled,
    onSelect,
    showThisEnvironmentName,
    showThisTitle,
    wizardVersions,
    includeAllOption,
    hideDeployedVersion,
    actions,
  } = props;
  const uiEnvironmentName = uiEnvironment && uiEnvironment.name;

  const allIsSelected =
    uiEnvironmentName && uiEnvironmentName === "All Environments";

  const innerSelect = (env, deployedTrans) => {
    if (actions) {
      actions.updateSessionEnvironment(env.environmentId);
    }
    onSelect(env, deployedTrans);
  };

  return (
    <span style={{ zIndex: 9 }}>
      <DropdownButton
        id="environments"
        placeholder="Environment"
        title={
          showThisEnvironmentName || uiEnvironmentName || "Select Environment"
        }
        bsStyle="default"
        bsSize="small"
        style={{
          fontWeight: "bold", //width:150,
          ...(uiEnvironment && {
            background: uiEnvironment.color,
          }),
          ...(uiEnvironment && {
            color: getColorByBgColor(uiEnvironment.color),
          }),
        }}
        className="environments-dropdown"
        disabled={disabled}
      >
        {includeAllOption && (
          <MenuItem
            value="all"
            active={allIsSelected}
            onClick={() => onSelect({ name: "All Environments" })}
          >
            <div
              className="env-dropdown-color-box"
              style={{
                background: "white",
                border: "1px solid black",
              }}
            />
            <strong>All</strong>
          </MenuItem>
        )}
        {environments &&
          environments.map((env, i) => {
            const deployment =
              deployments &&
              deployments.find((td) => {
                return env.environmentId === td.environmentId;
              });
            const deployedTrans =
              deployment &&
              versions &&
              versions.find((v) => {
                return deployment.transId === v.Id;
              });
            let deployedVersion = deployedTrans && deployedTrans.Version;
            if (wizardVersions && deployedTrans) {
              wizardVersions.some((wv, idx) => {
                deployedVersion = wizardVersions.length - idx;
                return wv.transformerId === deployedTrans.Id;
              });
            }
            const isActive = env.name === uiEnvironmentName;
            return (
              <MenuItem
                value={env.name}
                key={i}
                active={isActive}
                onClick={() => innerSelect(env, deployedTrans)}
              >
                <div
                  className="env-dropdown-color-box"
                  style={{
                    background: env.color || "#404040",
                    border: isActive ? "1px solid white" : "none",
                  }}
                />
                <strong>{env.name}</strong>
                {deployedVersion && !hideDeployedVersion && (
                  <span>{` - Ver. ${deployedVersion} (deployed)`}</span>
                )}
              </MenuItem>
            );
          })}
      </DropdownButton>
    </span>
  );
};

export default EnvironmentsDropdown;
