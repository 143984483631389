import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";

const titleStyles = {
  fontSize: "22px",
  float: "left",
  marginBottom: "5px",
};

class EditUser extends Component {
  constructor() {
    super();
    this.state = {
      access: null,
      user: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { redux, location } = this.props;
    const { main } = redux;
    const user =
      main.users &&
      main.users.find((u) => {
        return location.state.user === u.id;
      });
    if (user) {
      this.setState({ user });
      if (user.admin) {
        this.setState({ access: "Administrator" });
      } else {
        this.setState({ access: "Developer" });
      }
    } else {
      this.props.history.push("/user");
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const isOwner = this.state.access === "Administrator";
    const isUser = this.state.access === "Developer";
    if (!isOwner && !isUser) {
      return;
    }
    this.props.actions.callUpdateUserInfo(
      this.props.location.state.tenantId,
      this.props.location.state.user,
      isOwner ? "owner" : "user"
    );
    this.props.history.push({
      pathname: "/user",
    });
  }

  render() {
    if (!this.state.user) {
      return <div />;
    }
    return (
      <div>
        <form
          className="form-horizontal"
          onSubmit={this.handleSubmit}
          style={{ marginTop: 75 }}
        >
          <div>
            <div style={titleStyles}>Edit User</div>
            <br />
            <br />
            <h5>
              <label htmlFor="email" className="form-control-left">
                Email
              </label>
            </h5>
            <br />
            <div className="form-control-left">{this.state.user.username}</div>
            <br />
            <br />
            <h5>
              <label htmlFor="access" className="form-control-left">
                Access
              </label>
            </h5>
            <br />
            <div>
              <div style={{ display: "inline-block", verticalAlign: "top" }}>
                <div className="checkbox">
                  <label htmlFor="admin" className="form-control-left">
                    <input
                      className="form-control-left"
                      type="radio"
                      id="r1"
                      name="access"
                      onChange={() =>
                        this.setState({ access: "Administrator" })
                      }
                      checked={this.state.access === "Administrator"}
                    />
                    &nbsp; Administrator
                  </label>
                </div>
                {/*<div className="checkbox" style={{color:"grey"}}>
                    <label className="form-control-left">
                        <input className="form-control-left" disabled type="radio" name="access"
                               defaultChecked={false} />&nbsp;
                        Data Observer
                    </label>
                </div>*/}
                <div className="checkbox">
                  <label htmlFor="dev" className="form-control-left">
                    <input
                      className="form-control-left"
                      type="radio"
                      id="r2"
                      name="access"
                      onChange={() => this.setState({ access: "Developer" })}
                      checked={this.state.access === "Developer"}
                    />
                    &nbsp; Developer
                  </label>
                </div>
                <div className="checkbox">
                  <label htmlFor="ops" className="form-control-left">
                    <input
                      className="form-control-left"
                      type="radio"
                      id="r3"
                      name="access"
                      onChange={() => this.setState({ access: "Operations" })}
                      checked={this.state.access === "Operations"}
                    />
                    &nbsp; Operations
                  </label>
                </div>
                <div className="checkbox">
                  <label htmlFor="support" className="form-control-left">
                    <input
                      className="form-control-left"
                      type="radio"
                      id="r4"
                      name="access"
                      onChange={() => this.setState({ access: "Support" })}
                      checked={this.state.access === "Support"}
                    />
                    &nbsp; Support
                  </label>
                </div>
              </div>
              <div
                style={{
                  display: "inline-block",
                  marginLeft: 12,
                  verticalAlign: "top",
                }}
              >
                <div className="checkbox">
                  -&nbsp;&nbsp; manage data and users
                </div>
                {/*<div className="checkbox" style={{color:"grey"}}>
                    -&nbsp;&nbsp; view data and projects (coming soon)
                </div>*/}
                <div className="checkbox">
                  -&nbsp;&nbsp; build data integrations
                </div>
                <div className="checkbox">
                  -&nbsp;&nbsp; operations and logistics
                </div>
                <div className="checkbox">-&nbsp;&nbsp; support customers</div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <button
                type="submit"
                value="Post"
                className="btn btn-sm btn-primary"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = (state) => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
