import React, { Component } from "react";
import moment from "moment";
import images from "../images";
import CSSTransitionGroup from "react-addons-css-transition-group";
import LogResultTable from "./logResultTable";
import { Button } from "react-bootstrap";
import NewWindow from "react-new-window";
import { Link } from "react-router-dom";

export default class MonitorTransformerResult extends Component {
  constructor() {
    super();
    this.getLogs = this.getLogs.bind(this);
    this.myRef = React.createRef();
    this.jobsRef = React.createRef();
    this.state = {
      openJobsInfo: false,
      stepId: "",
    };
  }

  componentDidMount() {
    const { preload, actions, externalExecutionId, redux, guid, error } =
      this.props;
    const { project } = redux;
    if (preload && externalExecutionId) {
      actions.callGetSelectedTransformerLogs(
        project.tenantInfo.TenantId,
        externalExecutionId,
        error,
        guid
      );
      this.props.scrollToMe(this.wrap);
    }
  }

  getLogs(err, guid) {
    const { actions, externalExecutionId, redux } = this.props;
    const { project } = redux;
    // err only set if > 255 characters
    // if guid=guid, err is set to null
    if (externalExecutionId) {
      actions.callGetSelectedTransformerLogs(
        project.tenantInfo.TenantId,
        externalExecutionId,
        err,
        guid
      );
    }
  }

  componentWillMount() {
    this.interval = setInterval(() => {
      if (
        this.myRef.current &&
        this.myRef.current.released &&
        this.props.openPopUp
      ) {
        this.props.handlePopUp();
      }
    }, 1000);
    this.jobsInterval = setInterval(() => {
      if (
        this.jobsRef.current &&
        this.jobsRef.current.released &&
        this.state.openJobsInfo
      ) {
        this.setState({ openJobsInfo: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.jobsInterval);
  }

  stopRunningJob(e) {
    e.stopPropagation();
    const { actions, redux, environmentId, versionNumber } = this.props;
    const { transformer } = redux;
    let selectedTransformer = transformer.versions.find(
      (t) => t.Version == parseInt(versionNumber, 10)
    );
    actions.callStopExecuteTransformer(selectedTransformer.Id, environmentId);
  }
  render() {
    const { expand, externalExecutionId, openPopUp, redux, environmentId } =
      this.props;
    const { transformer, project } = redux;
    const { appData } = project;
    const { ongoingExecutionLogs } = transformer;
    const { openJobsInfo } = this.state;
    let executing =
      ongoingExecutionLogs &&
      ongoingExecutionLogs.find(
        (t) => t.externalExecutionId == externalExecutionId
      );
    const env =
      project.environments &&
      project.environments.find((e) => {
        return e.environmentId === environmentId;
      });
    let err = this.props.error;
    if (err.length > 255) {
      err = this.props.error
        .substr(0, 255)
        .substr(0, this.props.error.lastIndexOf(" "));
    }
    return (
      <div ref={(ref) => (this.wrap = ref)}>
        <div
          className="selected-transformer-result"
          onClick={() => this.getLogs(this.props.error, this.props.guid)}
        >
          <div
            style={{ display: "inline-block", width: "8%", marginLeft: "1%" }}
          >
            {moment(this.props.startTime).format("MMM Do, h:mm:ss a")}
          </div>
          <div
            style={{ display: "inline-block", width: "8%", marginLeft: "1%" }}
          >
            {env ? (
              <div style={{ width: "100%" }}>
                <div
                  className="env-dropdown-color-box"
                  style={{
                    background: env.color || "#404040",
                    border: "1px solid black",
                    marginRight: 4,
                    marginLeft: 2,
                  }}
                />
                <strong
                  style={{
                    display: "inline-block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                >
                  {env.name}
                </strong>
              </div>
            ) : (
              <span />
            )}
          </div>
          <div
            style={{
              display: "inline-block",
              width: "8%",
              wordWrap: "break-word",
              marginLeft: "1%",
            }}
          >
            {this.props.versionNumber}
          </div>
          {/*<div style={{ display:'inline-block', width:'8%', marginLeft:'1%' }}>
            {this.props.triggerType}
          </div>*/}
          <div
            style={{
              display: "inline-block",
              width: "8%",
              wordWrap: "break-word",
              marginLeft: "2%",
            }}
          >
            {this.props.completed ? (
              "true"
            ) : !executing ? (
              "false"
            ) : (
              <Button
                bsSize="small"
                bsStyle="primary"
                style={{
                  fontSize: 12,
                  padding: 2,
                  marginTop: "-30px",
                  marginLeft: "-11px",
                }}
                onClick={(e) => this.stopRunningJob(e)}
              >
                <img
                  alt="small-spinner"
                  src={images.cancel_job}
                  height="20"
                  style={{ marginRight: 3 }}
                />
                Stop Recipe
              </Button>
            )}
          </div>
          <div
            style={{
              display: "inline-block",
              width: "8%",
              wordWrap: "break-word",
              marginLeft: "1%",
            }}
          >
            {this.props.succeeded ? "true" : "false"}
          </div>
          <div
            style={{
              display: "inline-block",
              width: "35%",
              wordWrap: "break-word",
              marginLeft: "1%",
            }}
          >
            {err}
          </div>
          <div
            style={{
              display: "inline-block",
              width: "5%",
              wordWrap: "break-word",
              marginLeft: "1%",
            }}
          >
            {this.props.rowsRead > 0 && this.props.rowsRead}
          </div>
          <div
            style={{ display: "inline-block", width: "7%", marginLeft: "2%" }}
          >
            {this.props.timer.toFixed(5)}
          </div>
        </div>

        <CSSTransitionGroup
          transitionName="logs"
          transitionEnterTimeout={250}
          transitionLeaveTimeout={250}
        >
          {(transformer.selectedTransformerLogs &&
            transformer.selectedTransformerLogs.length > 0 &&
            transformer.selectedTransformerLogs[0].externalExecutionId ===
              this.props.externalExecutionId) ||
          (transformer.selectedTransformerLogsError &&
            transformer.selectedTransformerLogsError.guid ===
              this.props.guid) ? (
            <div
              className="logs"
              style={{
                maxHeight: expand ? "100%" : 351,
                position: "relative",
                marginBottom: 30,
              }}
            >
              <div
                onClick={this.props.toggleExpand}
                style={{
                  position: "absolute",
                  background: expand ? "#fff" : "#ddd",
                  border: "2px solid #666",
                  width: 46,
                  height: 26,
                  textAlign: "center",
                  right: 18,
                  top: 39,
                  zIndex: 1,
                  cursor: "pointer",
                }}
              >
                <img
                  alt="expand"
                  height="18"
                  src={expand ? images.fold : images.unfold}
                />
              </div>

              <div className="fix-execution">
                <div
                  style={{
                    marginBottom: 1,
                    position: "relative",
                    float: "left",
                    marginRight: 10,
                    fontSize: 14,
                    width: "100%",
                    padding: "6px 8px 8px 5px",
                    background: "white",
                    borderBottom: "1px solid #fdd663",
                  }}
                >
                  <strong>Id:</strong> {this.props.externalExecutionId}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <strong>Recipe Id:</strong> {this.props.transId}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <strong>Total Timer Id:</strong> {this.props.timer.toFixed(5)}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <strong>History of the recipe:</strong>&nbsp;
                  <Link
                    to={`/a/${appData.Id}/health?recipe=${this.props.transId}`}
                  >
                    click here
                  </Link>
                </div>
                <div
                  style={{
                    marginBottom: 1,
                    position: "relative",
                    float: "left",
                    marginRight: 10,
                    fontSize: 14,
                    width: "100%",
                    padding: "6px 8px 8px 5px",
                    backgroundColor: "#fef7e0",
                    color: "#e37400",
                    borderBottom: "1px solid #fdd663",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginLeft: "1%",
                    }}
                  >
                    <span>Statement:</span>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      width: "42%",
                      marginLeft: "1%",
                    }}
                  >
                    <span>Result:</span>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      width: "11%",
                      marginLeft: "1%",
                    }}
                  >
                    <span>Rows Read:</span>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      width: "13%",
                      marginLeft: "1%",
                    }}
                  >
                    <span>Timer:</span>
                  </div>
                </div>
              </div>

              <div className="logs-scroller" style={{ maxHeight: "100%" }}>
                {transformer.selectedTransformerLogsError && (
                  <div>
                    <span style={{ marginTop: 80, display: "inline-block" }}>
                      <strong>Full Error:</strong>
                    </span>
                    <p style={{ wordWrap: "break-word" }}>
                      {transformer.selectedTransformerLogsError.err}
                    </p>
                  </div>
                )}
                {transformer.selectedTransformerLogs &&
                  transformer.selectedTransformerLogs.length > 0 && (
                    <div style={{ height: "100%", marginTop: 70 }}>
                      {transformer.selectedTransformerLogs.map((log, i) => {
                        const isSchema = log.statement.includes("printschema");
                        const isTable =
                          !isSchema &&
                          (log.statement.includes("print") ||
                            log.statement.includes("PRINT"));
                        const jobIds = log.extendedProperties;
                        return (
                          <div
                            className="selected-transformer-result-logs"
                            key={i}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                width: "30%",
                                marginLeft: "1%",
                              }}
                            >
                              {log.statement}
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                width: "42%",
                                wordWrap: "break-word",
                                marginLeft: "1%",
                                verticalAlign: "bottom",
                              }}
                            >
                              {isTable ? (
                                <LogResultTable result={log.result} />
                              ) : isSchema ? (
                                <pre>{log.result}</pre>
                              ) : jobIds && jobIds != "" ? (
                                <span>
                                  {`${log.result}`}&nbsp;(
                                  <span
                                    className="span-as-link"
                                    onClick={() =>
                                      this.setState({
                                        openJobsInfo: true,
                                        stepId: log.stepGuid,
                                      })
                                    }
                                  >
                                    details
                                  </span>
                                  )
                                </span>
                              ) : (
                                `${log.result}`
                              )}
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                width: "11%",
                                marginLeft: "1%",
                              }}
                            >
                              {log.recordsRead > 0 && log.recordsRead}
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                width: "13%",
                                marginLeft: "1%",
                              }}
                            >
                              {log.timer.toFixed(5)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
            </div>
          ) : null}
        </CSSTransitionGroup>
        {openPopUp && (
          <NewWindow
            copyStyles={false}
            name={`Exexcution ${externalExecutionId}`}
            title={`Exexcution ${externalExecutionId}`}
            ref={this.myRef}
            features={{ noopener: "_blank" }}
            url={`/a/${appData.Id}/health/details?executionGuid=${externalExecutionId}&env=${environmentId}`}
          />
        )}
        {openJobsInfo && (
          <NewWindow
            copyStyles={false}
            ref={this.jobsRef}
            name={`SF Jobs ${externalExecutionId}`}
            title={`SF Jobs ${externalExecutionId}`}
            features={{ noopener: "_blank" }}
            url={`/a/${appData.Id}/health/sfjobs?env=${environmentId}&step=${this.state.stepId}`}
          />
        )}
      </div>
    );
  }
}
