import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Button } from 'react-bootstrap'
import logoStyles from '../main/wizardLogoStyles'
import images from '../images'

class NewWizard extends Component {
  constructor() {
    super()
    this.state = {
      source: null,
      destination: null,
      sources: [],
      destinations: []
    }
  }

  componentWillMount() {
    const { project } = this.props.redux
    const sources = []
    const destinations = []
    
    project.availableWizardTypes.forEach(w => {
      if (!sources.includes(w.sourceType)) {
        sources.push(w.sourceType)
      }
      if (!destinations.includes(w.destinationType)) {
        destinations.push(w.destinationType)
      }
    })
    sources.sort((a, b) => {
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })
    destinations.sort((a, b) => {
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })
    this.setState({ sources, destinations })
  }

  go = () => {
    const { project } = this.props.redux
    const app = project.appData
    const wiz = project.availableWizardTypes.find(
      w =>
        w.sourceType === this.state.source &&
        w.destinationType === this.state.destination
    )
    if (wiz) {
      this.props.history.push(`/a/${app.Id}/wiz/start/${wiz.typeId}`)
    } else {
      this.setState({ source: null, destination: null })
    }
  }

  cancel = () => {
    const { project } = this.props.redux
    const app = project.appData
    this.props.history.push(`/a/${app.Id}/tf`)
  }

  render() {
    const { project, main } = this.props.redux

    return (
      <div className="parent-env" style={{marginTop: 50 }}>
      <div className="main-tab-content" style={{ paddingTop:35, paddingBottom:50 }}>

        <Helmet title="Wizard" />

        <div style={styles.chosenSource}>
          <div
            style={{
              ...styles.dottedBox,
              background: this.state.source ? 'white' : 'transparent'
            }}>
            <div
              style={{
                ...styles.chosenImg,
                backgroundImage: `url("${images[this.state.source]}")`
              }}
            />
          </div>
          <div style={styles.arrow} />
          <div
            style={{
              ...styles.dottedBox,
              background: this.state.destination ? 'white' : 'transparent'
            }}>
            <div
              style={{
                ...styles.chosenImg,
                backgroundImage: `url("${images[this.state.destination]}")`
              }}
            />
          </div>
          <Button
            style={{ display: 'inline-block', marginLeft: 40 }}
            bsSize="small"
            bsStyle="default"
            onClick={() => this.cancel()}>
            Cancel
          </Button>
          <Button
            style={{ display: 'inline-block', marginLeft: 10}}
            bsSize="small"
            bsStyle="primary"
            onClick={() => this.go()}
            disabled={!this.state.source || !this.state.destination}>
            Launch Wizard
          </Button>

          
        </div>

        <div className="wiz-circle-env">
        <div style={styles.title}>Data sources</div>
        {this.state.sources.length === 0 && (
          <div style={{ padding: '2px 16px' }}>
            Your Organization does not have any Integration Wizards enabled.
            Please contact Lingk sales to purchase Lingk Speed Wizards.
          </div>
        )}
        <div>
          <div>
            {this.state.sources.map((s, i) => {
              const sourceLogoStyle = logoStyles[s] || {}
              let isMatch = true
              if (this.state.destination) {
                isMatch = project.availableWizardTypes.find(w => {
                  return (
                    w.sourceType === s &&
                    w.destinationType === this.state.destination
                  )
                })
                  ? true
                  : false
              }
              const p = main.providers.find(w => s === w.type)
              return (
                <div key={i} style={styles.wrap}>
                  <div
                    className="wiz-source-chooser"
                    style={isMatch ? {} : styles.disabledChooser}
                    onClick={() => {
                      this.setState({ source: s })
                    }}>
                    <div style={styles.cardTitle}>{(p && p.label) || s}</div>
                    <img
                      role="presentation"
                      width={sourceLogoStyle.width || 48}
                      height={sourceLogoStyle.height || 48}
                      src={images[s]}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          </div>
          </div>

          <div className="wiz-circle-env" style={{borderLeft: '2px solid #aaa'}}>
          <div style={styles.title}>Data targets</div>
          <div>
            {this.state.destinations.map((d, i) => {
              const sourceLogoStyle = logoStyles[d] || {}
              let isMatch = true
              if (this.state.source) {
                isMatch = project.availableWizardTypes.find(w => {
                  return (
                    w.sourceType === this.state.source &&
                    w.destinationType === d
                  )
                })
                  ? true
                  : false
              }
              const p = main.providers.find(w => d === w.destinationType)
              return (
                <div key={i} style={styles.wrap}>
                  <div
                    className="wiz-source-chooser"
                    style={isMatch ? {} : styles.disabledChooser}
                    onClick={() => {
                      this.setState({ destination: d })
                    }}>
                    <div style={styles.cardTitle}>{(p && p.label) || d}</div>
                    <img
                      role="presentation"
                      width={sourceLogoStyle.width || 48}
                      height={sourceLogoStyle.height || 48}
                      src={images[d]}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          </div>
      </div>
      </div>
    )
  }
}

const styles = {
  title: {
    fontSize: 20,
    padding: '1.5rem 2rem 0',
    fontWeight: 500,
    textDecoration: 'underline'
  },
  wrap: {
    display: 'inline-block',
    margin: 16,
    verticalAlign: 'top'
  },
  disabledChooser: {
    opacity: 0.5,
    boxShadow: 'none',
    cursor: 'default',
    color: '#686868'
  },
  cardTitle: {
    textAlign: 'center',
    width: '88%',
    marginBottom: 7,
    marginTop: 5,
    fontWeight: 'bold',
    fontSize: '16px'
  },
  chosenSource: {
    fontSize: 20,
    padding: '1.5rem 2rem 0rem',
    fontWeight: 500,
    height: 115
  },
  dottedBox: {
    border: '2px dashed grey',
    borderRadius: 20,
    width: 90,
    height: 90,
    display: 'inline-block',
    verticalAlign: 'top'
  },
  chosenImg: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: '10%',
    width: '80%',
    height: '80%'
  },
  arrow: {
    display: 'inline-block',
    width: 45,
    height: 93,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    verticalAlign: 'top',
    backgroundImage: `url("${images.arrow}")`
  }
}

export default NewWizard
