import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import 'chart.js/auto';

const UsageDoughnutChart = ({
                                       dataProcHours,
                                       usedDataProcHours,
                                       usedDataProcHoursPercentage,
                                       availableDataProcHours,
                                       availableDataProcHoursPercentage,
                                       threshold,
                                       thresholdValueHours,
                                       thresholdRemainingHours,
                                       thresholdPercentage
                                   }) => {
    if (availableDataProcHoursPercentage < 0) {
        availableDataProcHoursPercentage = 0;
    }

    // Determine colors based on the threshold
    const exceedsThreshold = usedDataProcHours > thresholdValueHours;
    let usedColor = exceedsThreshold ? 'rgba(255, 99, 132, 0.7)' : 'rgba(3, 193, 165, 0.7)';
    if (availableDataProcHoursPercentage <= 0) {
        usedColor = 'rgba(255, 0, 0, 1)';
    }
    const availableColor = 'rgba(204, 204, 204, 0.7)'; // Constant color for available hours

    const data = {
        labels: ['Used Hours', 'Available Hours'],
        datasets: [
            {
                label: 'Hours Usage',
                data: [usedDataProcHoursPercentage, availableDataProcHoursPercentage],
                backgroundColor: [usedColor, availableColor],
                borderWidth: 0,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'start',
                labels: {
                    generateLabels: function () {
                        // Manually create legend items
                        return [
                            {
                                text: 'Used Hours - Under Threshold',
                                fillStyle: 'rgba(3, 193, 165, 0.7)',
                                hidden: false,
                                index: 0
                            },
                            {
                                text: 'Used Hours - Over Threshold',
                                fillStyle: 'rgba(255, 99, 132, 0.7)',
                                hidden: false,
                                index: 1
                            },
                            {
                                text: 'Available Hours - Remaining',
                                fillStyle: 'rgba(204, 204, 204, 0.7)',
                                hidden: false,
                                index: 2
                            },
                            {
                                text: 'Available Hours - Over',
                                fillStyle: 'rgba(255, 0, 0, 1)',
                                hidden: false,
                                index: 3
                            },
                        ];
                    }
                },
                onClick: () => {
                } // Make legend non-clickable
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const label = tooltipItem.label;
                        const value = tooltipItem.raw.toFixed(2);
                        const hours = tooltipItem.dataset.data[tooltipItem.dataIndex] * dataProcHours / 100;
                        if (label === 'Available Hours') {
                            return `${value}% (${hours.toFixed(2)} Hours Remaining)`;
                        }
                        return `${value}% (${hours.toFixed(2)} Hours Used) [Threshold: ${thresholdPercentage}%]`;
                    }
                }
            }
        }
    };

    return <Doughnut data={data} options={options}/>;
};

export default UsageDoughnutChart;
