import React, { useState, useEffect, useRef } from "react";
import images from "../components/images";

export default () => {
  return (
    <div
      style={{
        margin: "100px auto",
        width: 330,
        display: "grid",
        textAlign: "center",
      }}
    >
      <span
        style={{
          fontSize: "15px",
          fontWeight: "bold",
        }}
      >
        You don't have permission to access this page
      </span>
      <img
        style={{
          height: "50px",
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
          marginTop: "10px",
        }}
        src={images.NoAccess}
      />
    </div>
  );
};
