const SideNavStyle = {
  sidebarStylesWide: {
    position: 'fixed',
    top: 0,
    backgroundColor: '#cccdcf',
    height: '100%',
    width: 200,
    marginTop: 50,
    color: 'white',
  },
  sidebarStyles: {
    position: 'fixed',
    top: 0,
    backgroundColor: '#404040',
    height: '100%',
    width: 75,
    marginTop: 0,
    color: 'white',
    zIndex: 1003,
    WebkitBoxShadow: '0px -2px 6px 0px rgba(94,94,94,1)',
    MozBoxShadow: '0px -2px 6px 0px rgba(94,94,94,1)',
    boxShadow: '0px -2px 6px 0px rgba(94,94,94,1)',
  },
  logoContainer: {
    height: 51,
    width: 75,
    border: 0,
    borderBottom: '1px solid black',
    cursor: 'pointer',
    WebkitBoxShadow: '0px -2px 6px 0px rgba(0,0,0,0.8)',
    MozBoxShadow: '0px -2px 6px 0px rgba(0,0,0,0.8)',
    boxShadow: '0px -2px 6px 0px rgba(0,0,0,0.8)',
  },
  logoStyle: {
    width: 50,
    verticalAlign: 'top',
    display: 'block',
    margin: 'auto',
  },
  sidenavStylesWide: {
    width: 180,
    padding: 0,
    marginLeft: 15,
    marginTop: 15,
    height: 'auto',
  },
  sidenavStyles: {
    width: 75,
    padding: 0,
    marginLeft: 15,
    marginTop: 15,
    height: 'auto',
  },

  h4Styles: {
    marginTop: 5,
    marginBottom: 5,
    color: '#333333',
  },

  h5Styles: {
    marginTop: 5,
    marginBottom: 5,
    color: '#909090',
  },

  h6Styles: {
    fontSize: 10,
    marginTop: 5,
    marginBottom: 5,
    color: '#909090',
  },
  sidenavLinkStylesWide: {
    listStyle: 'none',
    padding: 0,
    lineHeight: 24,
    marginTop: 20,
    width: 180,
  },
  sidenavLinkStyles: {
    listStyle: 'none',
    padding: 0,
    lineHeight: 24,
    width: 75,
    fontSize: 12,
  },
  sidenavItemStylesWide: {
    height: 50,
    marginBottom: 15,
    width: 180,
    border: 0,
    color: 'white',
    marginLeft: 10,
  },
  sidenavItemStyles: {
    height: 90,
    marginBottom: 0,
    padding: '20px 0',
    width: 75,
    color: 'white',
    borderBottom: '1px solid black',
    cursor: 'pointer',
  },
  sidenavAStyles: {
    height: 90,
    marginBottom: 0,
    padding: '20px 0',
    width: 75,
    color: 'white',
    cursor: 'pointer',
  },
  sidenavItemDivStyles: {
    fontSize: 14,
    lineHeight: '20px',
    verticalAlign: 'bottom',
    cursor: 'pointer',
    fontWeight: 700,
    textAlign: 'center',
  },
  iconStyles: {
    width: 26,
    height: 26,
    verticalAlign: 'top',
    display: 'block',
    margin: 'auto',
  },
  iconStylesSmall: {
    width: 20,
    height: 20,
    verticalAlign: 'top',
    margin: 'auto',
  },
  tag1Style: {
    width: 'auto',
    float: 'left',
    backgroundColor: '#C0C0C0',
    overflow: 'hidden',
    padding: '2px',
    fontWeight: 'bold',
    color: 'black',
    marginRight: 4,
    marginBottom: 4,
  },
  filterStyles: {
    marginLeft: 0,
    marginTop: 5,
    marginBottom: 5,
    color: 'black',
  },
};

export default SideNavStyle;
