import React, { Component } from "react";
import OverviewTabs from "./overviewtabs";
import DashboardTab from "./dashboardtab";
import WorkspaceTeam from "./teamtab";
import WorkspaceSettings from "./settingstab";
import { Route, Switch } from "react-router-dom";

class OverviewTabsParent extends Component {
  constructor() {
    super();
  }
  render() {
    return (
      <OverviewTabs redux={this.props.redux} actions={this.props.actions}>
        <Switch>
          <Route
            exact
            path="/a/:appId/dash"
            render={(routerProps) => (
              <DashboardTab
                {...routerProps}
                redux={this.props.redux}
                actions={this.props.actions}
              />
            )}
          />
          
          <Route
            path="/a/:appId/dash/team"
            render={(routerProps) => (
              <WorkspaceTeam
                {...routerProps}
                redux={this.props.redux}
                actions={this.props.actions}
              />
            )}
          />
          <Route
            path="/a/:appId/dash/settings"
            render={(routerProps) => (
              <WorkspaceSettings
                {...routerProps}
                redux={this.props.redux}
                actions={this.props.actions}
              />
            )}
          />
        </Switch>
      </OverviewTabs>
    );
  }
}


export default OverviewTabsParent
