import React, { Component } from "react";
import images from "../images";
import { ManageEnvVars } from "./manageEnvVars";
import { Table, Button, Modal } from "react-bootstrap";

const { Header, Body, Footer, Title } = Modal;
class EnvVar extends Component {
  constructor() {
    super();
    this.state = {
      manageEnv: false,
    };
  }
  showManageEnv() {
    this.setState({ manageEnv: true });
  }

  hideManageEnv() {
    this.setState({ manageEnv: false });
  }

  maskString(string) {
    var masked = "";
    if (string && string.length > 0) {
      for (let i = 0; i < string.length; i++) {
        masked = masked.concat("*");
      }
    }
    return masked;
  }

  render() {
    const { environmentVariables } = this.props;
    let tempEnvironmentVariables = null;
    const { manageEnv } = this.state;
    if (environmentVariables && environmentVariables.length > 0) {
      if (
        environmentVariables[0].credentialsJson.envVars &&
        environmentVariables[0].credentialsJson.envVars.length > 0
      ) {
        tempEnvironmentVariables =
          environmentVariables[0].credentialsJson.envVars;
      }
    }
    return (
      <div style={{ marginTop: 20 }}>
        {!manageEnv && (
          <div>
            <div style={{ marginBottom: 13 }}>
              {tempEnvironmentVariables ? (
                <span style={{ fontWeight: "bold", padding: 6 }}>
                  Environment Variables
                </span>
              ) : (
                <span style={{ fontWeight: "bold", padding: 6 }}>
                  No Environment Variables
                </span>
              )}
              <Button
                bsStyle="default"
                bsSize="small"
                style={{
                  width: 115,
                  fontWeight: "bold",
                  // right: "3.5%",
                  // position: "absolute",
                  //   top:'40%'
                  float: "right",

                  marginTop: "-8px",
                }}
                onClick={() => {
                  this.showManageEnv();
                }}
              >
                <img
                  src={images.create}
                  style={{ marginRight: 10, height: 24 }}
                ></img>
                Manage
              </Button>
            </div>
            {tempEnvironmentVariables && (
              <div style={{ background: "#EEE", width: "100%" }}>
                <Table bordered>
                  <tbody>
                    {tempEnvironmentVariables &&
                      tempEnvironmentVariables.map((e, i) => (
                        <tr key={i}>
                          <td style={{ width: "40vw" }}>
                            <span>{e.name}</span>
                          </td>
                          <td style={{ width: "40vw" }}>
                            <span className="text">
                              {e.sensitive ? this.maskString(e.value) : e.value}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        )}

        <Modal
          show={manageEnv}
          onHide={() => this.hideManageEnv()}
          dialogClassName="env-var-section"
          backdrop="static"
        >
          <Header closeButton>
            <Title style={{ fontWeight: "bold" }}>
              Environment Variables (User Generated)
            </Title>
          </Header>
          <Body style={{ background: "#eee" }}>
            <ManageEnvVars
              {...this.props}
              ev={environmentVariables}
              hideManageEnv={() => this.hideManageEnv()}
            />
          </Body>
        </Modal>
      </div>
    );
  }
}

export default EnvVar;
