import React, { useState, useEffect, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import images from "../images";
import MonitorTransformerResult from "./monitortransformerResultDev";
import { useHistory, useLocation } from "react-router-dom";
import Switch from "react-switch";
import { Loading } from "./theme-sources/material-ui/components/loading";
import moment from "moment";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import classNames from "clsx";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Button } from "react-bootstrap";
import NewWindow from "react-new-window";
import { Link } from "react-router-dom";

import {
  Column,
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
  DataTypeProvider,
  DataTypeProviderProps,
  CustomPaging,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  GroupingPanel,
  PagingPanel,
  Table,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  TableRowDetail,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import { Fragment } from "react";
import TimeFilter from "./timeFilter";

const timeFilters = [
  { label: "Past Hour", key: "pastHour" },
  { label: "Past 24 Hours", key: "past24Hours" },
  { label: "This Week", key: "thisWeek" },
  { label: "Past Week", key: "pastWeek" },
  { label: "Date Range", key: "dateRange" },
];
const styles = (theme) => ({
  toggleCell: {
    textAlign: "center",
    textOverflow: "initial",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    width: 200,
  },
  toggleCellButton: {
    verticalAlign: "middle",
    display: "inline-block",
    padding: theme.spacing(1),
    width: 200,
  },
});

const Label = ({ getMessage, column, ...restProps }) => {
  return (
    <TableHeaderRow.SortLabel
      column={column}
      getMessage={() => {
        return (
          <span style={{ fontSize: 15, padding: "2px 3px" }}>
            {column.title}
          </span>
        );
      }}
      {...restProps}
    />
  );
};

const columns = [
  { name: "title", title: "Time" },
  { name: "Name", title: "Recipe" },
  { name: "gearAction", title: "Actions" },
  { name: "environmentId", title: "Environment" },
  {
    name: "recipeVersionNumber",
    title: "Version Number",
  },
  { name: "recordsRead", title: "DPR" },
  { name: "timerToTime", title: "Timer" },
  { name: "errorMessage", title: "Error" },
];

const groupingStateColumnExtensions = [
  { columnName: "title", groupingEnabled: false },
  { columnName: "Name", groupingEnabled: true },
  { columnName: "environmentId", groupingEnabled: false },
  { columnName: "recipeVersionNumber", groupingEnabled: true },
  { columnName: "gearAction", groupingEnabled: false },
  { columnName: "errorMessage", groupingEnabled: true },
  { columnName: "recordsRead", groupingEnabled: true },
  { columnName: "timerToTime", groupingEnabled: true },
];
const jobsFilter = [
  { title: "Partial Successes", key: "partialSuccess" },
  { title: "Successes", key: "success" },
  { title: "Pending", key: "progress" },
  { title: "Errors", key: "error" },
  { title: "All", key: "all" },
];

const DateRowDetail = (props) => {
  const { expanded, row, filter } = props;
  return (
    <span
      className="span-as-link"
      style={expanded ? { background: "#efefef" } : {}}
    >
      {" "}
      {moment(row.startTime).format("MMM Do, h:mm:ss a")}
    </span>
  );
};
const RowDetail =
  (props, setExpandedDetailRowIds, setLastQuery) =>
  ({ row }) => {
    let d = row;
    return (
      <MonitorTransformerResult
        {...props}
        startTime={d.startTime}
        guid={d.executionGUID}
        completed={d.completed}
        succeeded={d.succeeded}
        error={d.errorMessage}
        timer={d.timer}
        triggerType={d.triggerType}
        key={row.id}
        externalExecutionId={d.externalExecutionId}
        environmentId={d.bkupEnv}
        rowsRead={d.recordsRead}
        versionNumber={d.recipeVersionNumber}
        transId={d.BaseId}
        transName={d.Name}
        handleCloseResultPanel={() => setExpandedDetailRowIds([])}
        setLastQuery={() => setLastQuery(null)}
        executionMetadata={d.executionMetadata}
      />
    );
  };

export default (props) => {
  const { actions, match, redux } = props;
  const { project, main } = redux;
  const { params } = match;
  let { tenantInfo } = project;
  const [filters, setFilters] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [defaultColumnWidths] = useState([
    { columnName: "title", width: "13%" },
    { columnName: "Name", width: "17%" },
    { columnName: "gearAction", width: "7%" },
    { columnName: "environmentId", width: "8%" },
    { columnName: "recipeVersionNumber", width: "7%" },
    { columnName: "errorMessage", width: "auto" },
    { columnName: "recordsRead", width: "8%" },
    { columnName: "timerToTime", width: "8%" },
  ]);
  const [resizingMode] = useState("nextColumn");

  const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);
  const [scrollingPage, setScrollingPage] = useState(0);
  const [filterRowVisible, setFilterRowVisible] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastOffset, setLastQuery] = useState(null);
  const [expandedDetailRowIds, setExpandedDetailRowIds] = useState([]);
  const [tabIndex, setTabIndex] = useState(4);
  const [lastTabIndex, setLastTabIndex] = useState(4);
  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState("thisWeek");
  const [lastSelectedTimeFilter, setLastSelectedTimeFilter] =
    useState("thisWeek");
  const refInput = useRef();

  const [pageSizes] = useState([5, 10, 100]);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [lastStartTime, setLastStartTime] = useState(new Date());
  const [lastEndTime, seLastEndTime] = useState(new Date());
  const location = useLocation();
  const history = useHistory();
  const getQueryString = () => currentPage * pageSize;
  const changeExpandedDetailRowIds = (expandedDetailRowIds) => {
    const lastItem = expandedDetailRowIds[expandedDetailRowIds.length - 1];
    let a = [lastItem];
    setExpandedDetailRowIds(a);
  };
  const [filteringStateColumnExtensions, setFilteringState] = useState([
    { columnName: "title", filteringEnabled: false },
  ]);
  const getRowId = (row) => {
    return row.id;
  };

  function stopRunningJob(row) {
    let stopJob = confirm("Are you sure you want to stop the running job");
    if (stopJob) {
      actions.callStopExecuteTransformer(row.transId, row.bkupEnv).then(() => {
        let running = false;
        const newRows = rows.map((r) =>
          r.id === row.id ? Object.assign({}, r, { running }) : r
        );
        setRows(newRows);
      });
    }
  }
  const ToggleCellBase = ({
    style,
    expanded,
    classes,
    onToggle,
    tableColumn,
    tableRow,
    row,
    className,
    ...restProps
  }) => {
    const handleClick = (e) => {
      e.stopPropagation();
      onToggle();
    };
    const { succeeded } = row;
    const { running } = row;
    return (
      <Fragment>
        <TableCell
          className={classNames(classes.toggleCell, className)}
          {...restProps}
          style={{ width: 200, display: "flex" }}
        >
          {running ? (
            <img
              alt="spinner"
              src={images.cancel_job_black}
              height="20"
              width="18"
              style={{ marginTop: 8 }}
              onClick={() => stopRunningJob(row)}
            />
          ) : succeeded == "true" ? (
            <span className="notifyStyle" style={{ background: "green" }} />
          ) : succeeded.includes("partial") ? (
            <span className="notifyStyle" style={{ background: "yellow" }} />
          ) : (
            succeeded == "false" && (
              <span className="notifyStyle" style={{ background: "#d9534f" }}>
                !
              </span>
            )
          )}
          <span
            className={classes.toggleCellButton}
            onClick={handleClick}
            style={{ width: 160, padding: "8px 2px" }}
          >
            <DateRowDetail
              expanded={expanded}
              row={row}
              filter={jobsFilter[tabIndex].key}
            />
          </span>
        </TableCell>
      </Fragment>
    );
  };
  const ToggleCell = withStyles(styles, { name: "ToggleCell" })(ToggleCellBase);

  function getEnv(env) {
    return (
      <div style={{ width: "100%" }}>
        <div
          className="env-dropdown-color-box"
          style={{
            background: env.color || "#404040",
            border: "1px solid black",
            marginRight: 4,
            marginLeft: 2,
          }}
        />
        <span
          style={{
            display: "inline-block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
          }}
        >
          {env.name}
        </span>
      </div>
    );
  }

  function formatDate(date) {
    let splitstr = date.split('T')
    let splitDate = splitstr[0].split('-')
    return splitDate[2] + splitDate[1] + splitDate[0]
  }

  function downloadLogsFromS3(execution) {
    const { actions, match, redux } = props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    return actions.downloadExecutionLogsFromS3(
      project.tenantInfo.TenantId,
      appId,
      execution.bkupEnv,
      formatDate(execution.finishTime),
      execution.transId,
      execution.executionGUID
    );
  };

  function calculateJobTimer(startTime, finishTime) {

    // calculate difference between end time & start time
    const st = new Date(startTime);
    const ft = new Date(finishTime);
  
    // if job is in running state, finish time is set to 0001-01-01T00:00:00Z
    if (finishTime == "0001-01-01T00:00:00Z"){
      return "0:00"
    }

    const diff = ft.getTime() - st.getTime();

    // string conversion from seconds to HH:MM:SS format
    const seconds = diff / 1000;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor((seconds % 3600) % 60);
    let time = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;

    // remove "00" or "0" from starting is exists
    if (time.substr(0, 2) == "00") {
      time = time.substr(3, time.length);
      if (time.substr(0, 1) == "0") {
        time = time.substr(1, time.length);
      }
    }
    return time;
  }

  function loadRowswithData(data, allEnv, recipeName, recipeId, executionGuid) {
    if (data) {
      data.map((t, i) => {
        t.id = i;
        if (recipeId) {
          t.BaseId = recipeId;
        } else {
          t.BaseId = t.executionMetadata.baseId;
        }
        if (recipeName) {
          t.Name = recipeName;
        } else {
          t.Name = t.executionMetadata.recipeName;
        }
        let hours = moment().diff(moment(t.startTime), "hours");
        if (!t.completed && hours < 24) {
          t.running = true;
        }
        t.completed = t.completed.toString();
        if (t.statusText.includes("partial")) {
          t.succeeded = t.statusText;
        } else {
          t.succeeded = t.succeeded.toString();
        }
        if (recipeName) {
          t.bkupName = recipeName;
        } else {
          t.bkupName = t.executionMetadata.recipeName;
        }
        t.bkupEnv = t.environmentId;
        const env =
          allEnv &&
          allEnv.find((e) => {
            return e.environmentId === t.environmentId;
          });
        if (env) {
          t.environmentId = getEnv(env);
        }
        let recipeType =
          t.executionMetadata.generatedFromPi == "" ||
          !t.executionMetadata.generatedFromPi
            ? "tf"
            : "wiz";
        t.gearAction = (
          <div style={{ display: "flex" }}>
            <img
              alt="gear"
              id="user-pic"
              src={images.ic_settings_black_48dp}
              height="24"
              style={{ opacity: "0.6", marginLeft: 3 }}
              className="gear-img"
              onClick={() => {
                props.history.push(
                  recipeId
                    ? `/a/${props.match.params.appId}/${recipeType}/${recipeId}`
                    : `/a/${props.match.params.appId}/${recipeType}/${t.executionMetadata.baseId}`
                );
              }}
            />

            <img
              alt="gear"
              id="user-pic"
              src={images.ic_file_download_black_48dp}
              height="24"
              style={{ opacity: "0.6", marginLeft: 3 }}
              className="gear-img"
              onClick={() => { downloadLogsFromS3(t) }}
            />

            <Link
              to={{
                pathname: `/a/${props.match.params.appId}/health/details`,
                search: `?executionGuid=${t.externalExecutionId}&env=${t.bkupEnv}`,
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={images.OpenInNew}
                height="20px"
                style={{ marginLeft: 2, marginTop: 2 }}
              />
            </Link>
          </div>
        );

        t.timerToTime = calculateJobTimer(t.startTime, t.finishTime)
      });
      setRows(data);
      setTotalCount(data.length);
      if (executionGuid && executionGuid != "") {
        let executionDetail = data.find(
          (t) => t.externalExecutionId == executionGuid
        );
        if (executionDetail) {
          setPageNOpenRow(executionDetail);
        }
      }
    } else {
      setRows([]);
    }
    setLoading(false);
  }
  function fetchRows(allEnv, offset, state, duration, executionGuid) {
    setLastQuery(offset);
    const queryParams = new URLSearchParams(location.search);
    let recipeId = queryParams.get("recipe");
    if (recipeId) {
      setFilterRowVisible(true);

      actions.callGetTransformerVersions(recipeId).then((data) => {
        if (data) {
          let recipeName = data.Name;
          setFilters([{ columnName: "Name", value: recipeName }]);
          setFilteringState([
            { columnName: "Name", filteringEnabled: false },
            { columnName: "title", filteringEnabled: false },
          ]);
          actions
            .callGetTransformerLogs(
              tenantInfo.TenantId,
              recipeId,
              "",
              "",
              0,
              state,
              duration,
              duration == "dateRange" ? startTime : "",
              duration == "dateRange" ? endTime : ""
            )
            .then((data) => {
              loadRowswithData(data, allEnv, recipeName, recipeId);
            })
            .catch(() => setLoading(false));
        }
      });
    } else {
      if (project && project.tenantInfo && project.tenantInfo.TenantId)
        actions
          .callGetTransformerLogsForWorkspace(
            project.tenantInfo.TenantId,
            params.appId,
            offset,
            state,
            duration,
            duration == "dateRange" ? startTime : "",
            duration == "dateRange" ? endTime : ""
          )
          .then((data) => {
            loadRowswithData(data, allEnv, null, null, executionGuid);
          })
          .catch(() => setLoading(false));
    }
  }
  const loadData = (refresh) => {
    const { environments } = project;

    const offset = getQueryString();
    if (
      (selectedTimeFilter == "dateRange" &&
        (startTime !== lastStartTime || endTime != lastEndTime)) ||
      refresh
    ) {
      setLastStartTime(startTime);
      seLastEndTime(endTime);
      setLoading(true);
      fetchRows(
        environments,
        offset,
        jobsFilter[tabIndex].key,
        selectedTimeFilter
      );
    } else if (
      lastOffset == null ||
      lastTabIndex != tabIndex ||
      selectedTimeFilter != lastSelectedTimeFilter
    ) {
      setLastTabIndex(tabIndex);
      setLoading(true);
      setLastSelectedTimeFilter(selectedTimeFilter);
      fetchRows(
        environments,
        offset,
        jobsFilter[tabIndex].key,
        selectedTimeFilter
      );
    }
  };
  function setWeeksFirstNLastDate(label) {
    let arrDate = label.split(" ");
    let endinMon = getMonthFromString(arrDate[3]);
    if (endinMon == -1) {
      let mon = getMonthFromString(arrDate[0]);
      mon = mon - 1;
      let firstDay = new Date();
      let lastDay = new Date();
      firstDay.setDate(arrDate[1]);
      firstDay.setMonth(mon);
      firstDay.setHours(0);
      firstDay.setMinutes(0);
      lastDay.setDate(arrDate[3]);
      lastDay.setMonth(mon);
      lastDay.setHours(0);
      lastDay.setMinutes(0);
      let d = firstDay.toString();
      let d2 = lastDay.toString();
      let index = d.lastIndexOf(":") + 3;
      setStartTime(d.substring(0, index));
      setEndTime(d2.substring(0, index));
    } else {
      let mon = getMonthFromString(arrDate[0]);
      mon = mon - 1;
      let firstDay = new Date();
      let lastDay = new Date();
      firstDay.setDate(arrDate[1]);
      firstDay.setMonth(mon);
      firstDay.setHours(0);
      firstDay.setMinutes(0);
      lastDay.setDate(arrDate[4]);
      lastDay.setMonth(endinMon);
      lastDay.setHours(0);
      lastDay.setMinutes(0);
      let d = firstDay.toString();
      let d2 = lastDay.toString();
      let index = d.lastIndexOf(":") + 3;
      setStartTime(d.substring(0, index));
      setEndTime(d2.substring(0, index));
    }
  }
  function getMonthFromString(mon) {
    var d = Date.parse(mon + "1, 2012");
    if (!isNaN(d)) {
      return new Date(d).getMonth() + 1;
    }
    return -1;
  }

  function setDayFirstNLastDate(label) {
    let arrDate = label.split(" ");
    let mon = getMonthFromString(arrDate[1]);
    mon = mon - 1;
    let firstDay = new Date();
    let lastDay = new Date();
    firstDay.setDate(arrDate[2]);
    firstDay.setMonth(mon);
    firstDay.setHours(0);
    firstDay.setMinutes(1);
    lastDay.setDate(arrDate[2]);
    lastDay.setMonth(mon);
    lastDay.setHours(23);
    lastDay.setMinutes(59);
    let d = firstDay.toString();
    let d2 = lastDay.toString();
    let index = d.lastIndexOf(":") + 3;
    setStartTime(d.substring(0, index));
    setEndTime(d2.substring(0, index));
  }

  function setMonthsFirstNLastDate(label, startTime) {
    let userEvent = new Date(startTime);
    let userEventMon = userEvent.getMonth();
    let arrDate = label.split(" ");
    let mon = getMonthFromString(arrDate[0]);
    mon = mon - 1;
    if (userEventMon != mon) {
      mon = userEventMon;
    }
    var firstDay = new Date(arrDate[1], mon, 1);
    var lastDay = new Date(arrDate[1], mon + 1, 0);
    let d = firstDay.toString();
    let d2 = lastDay.toString();
    let index = d.lastIndexOf(":") + 3;
    setStartTime(d.substring(0, index));
    setEndTime(d2.substring(0, index));
  }
  const rowHasDetails = (row) => row && row.events && row.events.length > 0;
  function setPageNOpenRow(row) {
    let a = row.id / 10;
    let page = Math.ceil(a);
    let rowId = row.id % 10;
    if (row.id % 10 == 0) {
      page = page + 1;
    }
    let a1 = [rowId];
    if (page == 0) {
      setCurrentPage(1);
      setScrollingPage(1);
    } else {
      setCurrentPage(page - 1);
      setScrollingPage(page - 1);
    }
    setExpandedDetailRowIds(a1);
  }
  useEffect(() => {
    let unmounted = false;
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("executionGuid") && !unmounted) {
      setLoading(true);
      setLastQuery(1);
      let executionGuid = queryParams.get("executionGuid");
      let filter = queryParams.get("filter");
      if (filter == null) {
        setSelectedTimeFilter("thisWeek");
      } else {
        setSelectedTimeFilter(filter);
      }
      //clear query params
      queryParams.delete("executionGuid");
      queryParams.delete("filter");
      history.replace({
        search: queryParams.toString(),
      });
      // load data
      if (
        lastOffset == null ||
        lastTabIndex != tabIndex ||
        selectedTimeFilter != lastSelectedTimeFilter
      ) {
        if (filter == "dateRange" && !loading) {
          const { location } = props;
          const { state } = location;
          const { label, view, startTime } = state;
          if (view == "week") {
            setWeeksFirstNLastDate(label);
          } else if (view == "month") {
            setMonthsFirstNLastDate(label, startTime);
          } else if (view == "day") {
            setDayFirstNLastDate(label);
          }
          return;
        }
        const { environments } = project;
        const offset = getQueryString();
        setLastTabIndex(tabIndex);
        fetchRows(
          environments,
          offset,
          jobsFilter[tabIndex].key,
          filter,
          executionGuid
        );
      }
    } else if (!unmounted && !loading) {
      loadData();
    }
    let intervalId;
    if (autoRefresh) {
      intervalId = setInterval(() => {
        loadData(true);
      }, 30000);
    }
    return () => clearInterval(intervalId);
  });

  function handleHomePage() {
    setFilterRowVisible(!filterRowVisible);
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("recipe")) {
      queryParams.delete("recipe");
      history.replace({
        search: queryParams.toString(),
      });
      setLastQuery(null);
      setFilters([]);
      setFilteringState([{ columnName: "title", filteringEnabled: false }]);
      loadData();
      setCurrentPage(0);
    }
  }
  function handleApplyFilter(selectedTimeFilter) {
    setSelectedTimeFilter(selectedTimeFilter);
    setShowPopUp(false);
  }
  function handleApplyDateFilter(start, end) {
    setSelectedTimeFilter("dateRange");
    setShowPopUp(false);
    setStartTime(start);
    setEndTime(end);
  }
  let filter = timeFilters.find((t) => t.key == selectedTimeFilter);
  return (
    <div
      className="parent-env"
      style={{
        marginTop: 44,
        paddingTop: 15,
        paddingBottom: 50,
        paddingRight: 15,
      }}
    >
      <div className="main-tab-content">
        <div style={{ borderBottom: "1px #e0e0e0 solid", height: 35 }}>
          <span style={{ fontSize: "20px" }}>Executions</span>

          <label htmlFor="material-switch" style={{ float: "right" }}>
            <span style={{ marginRight: 10 }}>Add filters</span>
            <Switch
              checked={filterRowVisible}
              onChange={() => handleHomePage()}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="react-switch"
              id="material-switch"
            />
          </label>
        </div>
        <Button
          bsStyle="default"
          style={{
            height: 30,
            verticalAlign: "top",
            padding: "1px 10px",
            minWidth: 100,
          }}
          onClick={() => setShowPopUp(!showPopUp)}
        >
          {filter.label}
          <img
            src={images.BlackDropdown}
            alt="refresh"
            style={{ height: 23, width: 23, marginTop: -2 }}
          />
        </Button>
        <Button
          bsStyle="default"
          bsSize="small"
          style={{
            width: 40,
            height: 30,
            background: "#f9f9ff",
            marginLeft: 7,
            padding: 2,
          }}
          onClick={() => loadData(true)}
        >
          <img
            src={images.refresh_metadeta}
            alt="refresh"
            style={{ height: 23, width: 23 }}
          />
        </Button>
        <label htmlFor="material-switch" style={{ marginLeft: 10 }}>
          <span style={{ marginRight: 5 }}>
            Auto Refresh is {autoRefresh ? `on` : `off`}
          </span>
          <Switch
            checked={autoRefresh}
            onChange={(r) => setAutoRefresh(r)}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={17}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={17}
            width={33}
            className="react-switch"
            id="material-switch"
          />
        </label>
        {showPopUp && (
          <TimeFilter
            applyFilter={handleApplyFilter}
            applyDateFilter={handleApplyDateFilter}
            closePopUp={() => setShowPopUp(false)}
            parentTimeFilter={selectedTimeFilter}
            defaultStartTime={startTime}
            defaultEndTime={endTime}
          />
        )}

        <div dir="rtl" style={{ marginTop: -30 }}>
          <Tabs
            direction={"rtl"}
            defaultIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList>
              {jobsFilter.map((j) => {
                return (
                  <Tab
                    key={j.key}
                    style={{
                      fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
                    }}
                  >
                    {j.title}
                  </Tab>
                );
              })}
            </TabList>
          </Tabs>
        </div>
        <Paper style={{ marginBottom: 40 }}>
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <SortingState
              defaultSorting={[{ columnName: "id", direction: "asc" }]}
            />
            <SelectionState />
            <RowDetailState
              defaultExpandedRowIds={[]}
              expandedRowIds={expandedDetailRowIds}
              onExpandedRowIdsChange={changeExpandedDetailRowIds}
            />

            <FilteringState
              defaultFilters={[]}
              filters={filters}
              onFiltersChange={setFilters}
              columnExtensions={filteringStateColumnExtensions}
            />
            <IntegratedFiltering />

            {filterRowVisible ? <DragDropProvider /> : null}
            {filterRowVisible ? (
              <GroupingState
                defaultGrouping={[]}
                columnExtensions={groupingStateColumnExtensions}
              />
            ) : (
              <GroupingState />
            )}
            {filterRowVisible ? <IntegratedGrouping /> : null}

            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <CustomPaging totalCount={totalCount} />
            <IntegratedSorting />
            <IntegratedPaging />

            <Table />
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
              resizingMode={resizingMode}
            />
            {filterRowVisible ? (
              <TableHeaderRow
                showSortingControls
                sortLabelComponent={Label}
                showGroupingControls
              />
            ) : (
              <TableHeaderRow
                showSortingControls
                sortLabelComponent={Label}
                // cellComponent={CellHeader}
              />
            )}
            {filterRowVisible ? <TableFilterRow /> : null}
            <PagingPanel
              height={100}
              style={{ height: 100 }}
              pageSizes={pageSizes}
            />
            <TableRowDetail
              contentComponent={RowDetail(
                props,
                setExpandedDetailRowIds,
                setLastQuery
              )}
              toggleCellComponent={ToggleCell}
            />
            {filterRowVisible ? <TableGroupRow /> : null}
            {filterRowVisible ? <Toolbar /> : null}

            {filterRowVisible ? <GroupingPanel showGroupingControls /> : null}
          </Grid>
        </Paper>
        {loading && <Loading />}
      </div>
      ;
    </div>
  );
};
