import React from 'react';
import {Bar} from 'react-chartjs-2';
import 'chart.js/auto';


const MonthlyUsageBarChart = ({monthlyDPH, licensedPeriodDPH}) => {
    const months = monthlyDPH.months;
    const labels = months.map(month => `${month.month} (${month.dataProcHours} Hours)`); // Use monthName from monthsData

    const usedDataProcHoursList = months.map(month => month.usedDataProcHours);
    const availableDataProcHoursList = months.map(month => {
        if (month.availableDataProcHours > 0 && month.availableDataProcHours < 1) {
            return 1;
        } else if (month.availableDataProcHours < 0 && month.availableDataProcHours > -1) {
            return -1;
        }
        return month.availableDataProcHours
    });
    const thresholdValueHoursList = months.map(month => month.thresholdValueHours);
    const thresholdPercentageList = months.map(month => month.thresholdPercentage);

    const backgroundColorsUsedDataProcHours = usedDataProcHoursList.map((hours, index) => {
        return hours > thresholdValueHoursList[index] ? 'rgba(255, 99, 132, 0.7)' : 'rgba(3, 193, 165, 0.7)'; // Orange for threshold crossed, Blue otherwise
    });

    const backgroundColorsAvailableDataProcHours = availableDataProcHoursList.map(hours => {
        return hours < 0 ? 'rgba(255, 0, 0, 1)' : 'rgba(204, 204, 204, 0.7)'; // Red for negative available hours, Green for positive
    });

    const data = {
        labels,
        datasets: [
            {
                label: 'Used Hours',
                data: usedDataProcHoursList,
                backgroundColor: backgroundColorsUsedDataProcHours,
                borderWidth: 0,
            },
            {
                label: 'Available Hours',
                data: availableDataProcHoursList,
                backgroundColor: backgroundColorsAvailableDataProcHours,
                borderWidth: 0,
            }
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 20
                },
                title: {
                    display: true,
                    text: 'Hours', // Y-axis label text
                    font: {
                        size: 14
                    }
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    generateLabels: () => {
                        return [
                            {text: 'Used Hours - Under Threshold', fillStyle: 'rgba(3, 193, 165, 0.7)'},
                            {text: 'Used Hours - Over Threshold', fillStyle: 'rgba(255, 99, 132, 0.7)'},
                            {text: 'Available Hours - Remaining', fillStyle: 'rgba(204, 204, 204, 0.7)'},
                            {text: 'Available Hours - Over', fillStyle: 'rgba(255, 0, 0, 1)'}
                        ].map(label => ({
                            ...label,
                            datasetIndex: -1, // Non-existent datasetIndex to disable interaction
                            hidden: false
                        }));
                    }
                },
                onClick: () => {
                }
            },
            tooltip: {
                callbacks: {
                    afterLabel: function (tooltipItem) {
                        if (tooltipItem.datasetIndex === 0) { // Dataset index 0 for 'Used Hours'
                            return 'Threshold: ' + thresholdPercentageList[tooltipItem.dataIndex] + ' %';
                        }
                        return '';
                    }
                }
            }
        }
    };

    return <>
        <Bar data={data} options={options}/>
        <br/>
        <div style={{
            fontSize: "15px",
            justifyContent: "center",
            display: "flex",
            alignItems: "center"
        }}>({licensedPeriodDPH?.startDate} - {licensedPeriodDPH?.endDate})
        </div>
    </>;
};

export default MonthlyUsageBarChart