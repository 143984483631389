import React, { Component } from 'react'
import Helmet from 'react-helmet';
import images from '../images'
import ReactDataSheet from 'react-datasheet';
import {CellRenderer} from './cells'
import Description from './description'
import Header from '../header/header'
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import {sheetToGrid, makeRowColors, mappingsToSheet} from './sheetToGrid'
import MapperHeader from './mapperHeader'
import _columns from './columns'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";
class SharedDataMapping extends Component {

  constructor(){
    super()
    this.state={
      title:'',
      sheet:[[]],
      description:'',
      sourceProvider:'',
      targetProvider:'',
      showApiNames:false,
      noRecord:false,
      headerWidths:[]
    }
  }

  componentDidMount(){
    const {actions, match} = this.props
    const {params} = match
    actions.callGetSharedDataMapping(params.mapperGuid)
    .then((record)=>{
      if(record){
        this.setState({
          sheet:mappingsToSheet(record.mappings),
          title:record.title,
          description: record.description,
          sourceProvider: record.sourceProvider,
          targetProvider: record.targetProvider
        })
      } else {
        this.setState({noRecord:true})
      }
    })
    .catch(()=>{
      this.setState({noRecord:true})
    })

    const headerWidths = _columns.map((c)=>{
      return c.width
    })
    this.setState({headerWidths})
  }

  handleToggle = (e) => {
    this.setState({showApiNames:e==='system'})
  }

  onWidthChange = (i, w) => {
    const headerWidths = [...this.state.headerWidths]
    headerWidths[i] = w
    this.setState({headerWidths}) 
  }

  render() {
    const {title, description, showApiNames, sourceProvider, targetProvider, noRecord, headerWidths} = this.state

    const grid = sheetToGrid(this.state.sheet, showApiNames)
    const rowColors = makeRowColors(grid)

    let totalWidth = 0
    headerWidths.forEach((w)=> totalWidth += w)

    return (
      <div>
        <Helmet title="Data Mapping" />
        <Header
          publicPage={true}
          {...this.props}
        />
        <div style={{padding:'65px 50px'}}>

          <div style={{margin:'0 auto',width:totalWidth}}>

            <div style={{width:totalWidth}}>

              {title ? <div style={{fontSize:'20px', verticalAlign:'top', 
                display:'inline-block', height:45, paddingTop:10, paddingRight:25}}>
                <span>{title}</span>
              </div> : <div className="ajax-loader">
                {noRecord?<div>This Data Mapping is not publically shared.</div> :
                  <img alt="spinner" src={images.ajax_loader} />
                }
              </div>}

              {sourceProvider && <div style={styles.icons}>
                <Icon type={sourceProvider} style={{position: 'absolute', top: 4, left: 75}} />
                <div
                  role="presentation"
                  style={styles.arrow}
                />
                <Icon type={targetProvider} style={{position: 'absolute', top: 4, left: 75}} />
              </div>}

              {title && <div style={{display:'inline-block',marginTop:18,float:'right'}}>
                <strong className="labelz" 
                style={{fontSize:11,verticalAlign:'middle',display:'inline-block'}}>
                Labels:</strong>&nbsp;&nbsp;
                <ToggleButtonGroup
                  type="radio" bsSize="xsmall" name="options"
                  value={this.state.showApiNames?'system':'friendly'} 
                  onChange={this.handleToggle}>
                  <ToggleButton value={'friendly'}>Friendly</ToggleButton>
                  <ToggleButton value={'system'}>System</ToggleButton>
                </ToggleButtonGroup>
              </div>}

            </div>

            <br />

            {title && <div style={{overflowY:'visible',height:'calc(100% - 108px)'}}>
              <div style={{overflowX:'scroll',width:'100%',height:'100%'}}>
                <div style={{width: totalWidth+18}}>
                  <div style={{
                    display: 'inline-block',
                    background:'white',
                    boxShadow: '0 0 6px #ccc',
                    width: totalWidth,
                    verticalAlign:'top'}}>
                    <MapperHeader widths={headerWidths}
                      onWidthChange={this.onWidthChange}
                    />
                    <ReactDataSheet
                      data={grid}
                      valueRenderer={(cell) => cell.value}
                      cellRenderer={(props)=> <CellRenderer {...props} 
                        rowColors={rowColors} readOnly
                        headerWidths={headerWidths}
                      />}
                      onContextMenu={(e, cell) => cell.readOnly ? e.preventDefault() : null}
                    />
                  </div>
                </div>

                {description && <Description description={description} readOnly />}

              </div> {/* end of scroll x div */}
            </div>} {/* end of visible div for dropdowns */}

          </div>

        </div>
      </div>
    )
  }
}

const styles = {
  icons: {
    display:'inline-block',
    textAlign: 'center',
    verticalAlign:'top'
  },
  arrow:{
    display: 'inline-block', 
    width: 28, 
    height: 46, 
    backgroundSize: 'contain', 
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center', 
    backgroundImage: `url("${images.arrow}")`
  }
}

const Icon = (props) => {
  const {type} = props
  let imgType = type
  if(type==='Custom'){
    imgType = 'globe'
  }
  return (<div
    role="presentation"
    style={{ display: 'inline-block', width: 46, height: 46, backgroundSize: 'contain', backgroundRepeat: 'no-repeat',backgroundPosition: 'center', backgroundImage: `url("${images[imgType]}")` }}
  />)
}


const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = state => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SharedDataMapping);