import React, { Component } from 'react';
import _columns from './columns'
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import { DropdownButton, MenuItem, Button, ToggleButtonGroup, ToggleButton, FormControl } from 'react-bootstrap'
import EnvironmentDropdown from '../utils/EnvironmentDropdown'
import * as util from '@lingk/sync/build/metadataFunctions'
import images from '../images'
//import CommentBar from '../analytics/commentbar'

import HistoryBox from '../utils/historyBox'
import Description from './description'
import {CheckboxCell, CellRenderer, MetadataCell, EnvCheckIcons, DeleteRow, InputCell, TextareaCell} from './cells'
import ConfirmDeleteModal from '../utils/confirmDeleteModal'
import ShareModal from './shareModal'
import {sheetToGrid, makeRowColors, mappingsToSheet, sheetToMappings} from './sheetToGrid'
import MapperHeader from './mapperHeader'
import {DraggableCore} from 'react-draggable';

const emptyRow = _columns.map(()=>{
  return {}
})

export default class Mapper extends Component {

  constructor() {
    super();
    this.state = {
      sheet: null,
      selectedEnv: null,
      envCreds:null,
      showApiNames:false,
      title:'New Data Mapping',
      sortByColumn:null,
      sortDescending:true,
      showComments:false,
      description:'',
      mapperGuid:'',
      showShareModal:false,
      headerWidths:[],
      isPublic:false
    }
  }

  componentWillMount(){
    const {redux, match, actions} = this.props
    const {params} = match
    const {appId, mapperId} = params
    const {project} = redux
    const {dataMappings} = project
    const thisMapperId = parseInt(mapperId)
    if(thisMapperId){
      const record = dataMappings && dataMappings.find((m)=>m.id===thisMapperId)
      if(record){
        this.reloadData(record)
      } else {
        this.loadEmpty()
      }
    } else {
      this.loadEmpty()
    }
    if(thisMapperId){
      this.setState({showComments:true})
      actions.callGetMapperComments(project.tenantInfo.TenantId, appId, mapperId)
    }

    const headerWidths = _columns.map((c)=>{
      return c.width
    })
    this.setState({headerWidths})
  }

  componentWillUnmount(){
    const { actions } = this.props
    actions.clearMapperComments()
  }

  loadEmpty = () => {
    const sheet = [emptyRow]
    this.setState({sheet})
  }

  reloadData = (record) => {
    const {redux, actions, match} = this.props
    const {params} = match
    const {project} = redux
    const {environments} = project
    const env = environments.find((e)=>e.environmentId===record.environmentId)
    this.setState({
      selectedEnv:env, loadingCreds:true, title:record.title,
      lastModified: record.lastModified, lastModifiedBy:record.lastModifiedBy,
      description: record.description, mapperGuid:record.mapperGuid,
      isPublic: record.isPublic
    })
    const skipRedux = true // dont put creds in reducer
    actions.getAllCredentialsForEnvironment(project.tenantInfo.TenantId, params.appId, env.name, skipRedux)
    .then((envCreds)=>{
      this.setState({
        sheet:mappingsToSheet(record.mappings),
        loadingCreds:false,
        envCreds:envCreds.filter(c=>c.credentialType!=='AdapterSecret'),
        sortDescending:record.sortDescending,
        sortByColumn:record.sortByColumn===-1?null:record.sortByColumn
      })
      const sourceProvider = envCreds.find((c)=>{
        return c.providerLabel === record.sourceProvider
      })
      const targetProvider = envCreds.find((c)=>{
        return c.providerLabel === record.targetProvider
      })
      if(sourceProvider){
        this.getMetadata(sourceProvider, 'source')
      }
      if(targetProvider){
        this.getMetadata(targetProvider, 'target')
      }
    })
  }

  addRow = () => {
    const sheet = [...this.state.sheet]
    sheet.push(emptyRow)
    this.setState({sheet})
  }

  deleteRow = (i) => {
    const sheet = [...this.state.sheet]
    sheet.splice(i,1)
    this.setState({sheet})
  }

  saveMapping = () => {
    const { actions, match, redux } = this.props
    const {params} = match
    const {project} = redux
    const {selectedEnv, sheet, sourceSelectedProvider, targetSelectedProvider, sortByColumn, sortDescending,title,description,mapperGuid} = this.state
    this.setState({saving:true})  
    const data = {
      title,description,mapperGuid,
      mappings:sheetToMappings(sheet),
      environmentId:selectedEnv.environmentId,
      sourceProvider:sourceSelectedProvider,
      targetProvider:targetSelectedProvider,
      // this needs to be 0 sometimes... make it -1 to be represent null
      sortByColumn:(sortByColumn||sortByColumn===0)?sortByColumn:-1,
      sortDescending:sortDescending,
    }
    const mapperId = parseInt(params.mapperId)
    if(mapperId){
      data.id = mapperId
    }
    actions.postDataMapping(project.tenantInfo.TenantId, params.appId, data)
    .then(()=>{
      this.setState({saving:false})
      if(!mapperId){
        this.props.history.push(`/a/${params.appId}/map`)
      } 
    })
  }

  changeEnvironment = (env) => {
    this.setState({selectedEnv:env,envCreds:null,loadingCreds:true})
    const {actions, match, redux} = this.props
    const {params} = match
    const {appId} = params
    const {project} = redux
    const skipRedux = true // dont put creds in reducer
    actions.getAllCredentialsForEnvironment(project.tenantInfo.TenantId, appId, env.name, skipRedux).then((envCreds)=>{
      this.setState({
        envCreds:envCreds.filter(c=>c.credentialType!=='AdapterSecret'),
        loadingCreds:false
      })
    })
  }

  getMetadata = (creds,mode) => {
    const m = mode.toLowerCase() // "source" or "target"
    this.setState({[`${m}SelectedProvider`]:creds.providerLabel})
    if(creds.providerLabel!=='Custom'){
      this.setState({
        [`${m}Checking`]:true,
        [`${m}CheckResult`]: '',
        [`${m}Metadata`]:null,
        [`${m}CheckError`]:null,
      })
      const {actions, match, redux} = this.props
      const {params} = match
      const {appId} = params
      const {project,main} = redux
      const providerConfig = main.providers.find((p)=>{
        return p.credentialType===creds.credentialType
      })
      this.setState({
        [`${m}SelectedProviderLabel`]:providerConfig.label||providerConfig.type
      })
      actions.callGetMetadataForWizardRun(
        project.tenantInfo.TenantId, appId, providerConfig, creds, this.state.selectedEnv
      )
      .then((meta)=>{
        const metadata = util.metadataFunctions(meta, providerConfig.type)
        this.setState({
          [`${m}Metadata`]:metadata, 
          [`${m}Checking`]:false, 
          [`${m}CheckResult`]:'200 OK'
        })
      })
      .catch((err)=>{
        this.setState({
          [`${m}Checking`]: false, 
          [`${m}CheckResult`]:'404 Error', 
          [`${m}Metadata`]:null
        })
        if(err.response && err.response.data){
          this.setState({[`${m}CheckError`]:err.response.data})
        }
      })
    } else {
      this.setState({[`${m}SelectedProviderLabel`]:'Custom'})
    }
  }

  handleToggle = (e) => {
    this.setState({showApiNames:e==='system'})
  }

  sort = (col) => {
    let sortByColumn = this.state.sortByColumn
    let sortDescending = this.state.sortDescending
    const _c = _columns[col]
    if(_c.isObject || _c.isField){
      if(sortByColumn===col){
        if(sortDescending){ // if its already sorting, switch direction
          sortDescending = false
        } else { // do not sort
          sortByColumn = null
          sortDescending = true
        }
      } else { // pick a new column to sort
        sortByColumn = col
        sortDescending = true
      }
      this.setState({sortByColumn,sortDescending})
    }
  }

  selectedProviderIsCustom = (mode) => {
    const p = this.state[`${mode}SelectedProvider`]
    return p==='Custom' || p==='SFTP' ? true : false
  } 

  deleteMapping = () => {
    const {actions, redux, match} = this.props
    const {params} = match
    const {project} = redux
    const {appId, mapperId} = params
    this.setState({deleting:true})
    actions.callDeleteDataMapping(project.tenantInfo.TenantId, appId, mapperId)
    .then(()=>{
      this.props.history.push(`/a/${appId}/map`)
    })
  }

  handlePublicToggle = () => {
    const {actions, redux, match} = this.props
    const {params} = match
    const {project} = redux
    const {appId, mapperId} = params
    const p = !this.state.isPublic
    this.setState({isPublic:p})
    actions.updateDataMappingPublicity(project.tenantInfo.TenantId, appId, mapperId, p)
  }

  triggerModal = () => {
    this.setState({modal: !this.state.modal})
  }

  onWidthChange = (i, w) => {
    const headerWidths = [...this.state.headerWidths]
    headerWidths[i] = w
    this.setState({headerWidths}) 
  }

  onDragStart = (d,i) => {
    this.setState({currentlyBeingDragged: i, finalPlace:i})
  }
  onDrag = (v) => {
    const overlap = 0
    const height = 27
    // in its own place
    if(v.y > overlap-height && v.y < height-overlap){
      this.finalDelta = 0
    } else if(v.y >= height-overlap){ // dragged down
      for(let j=1; j<this.state.sheet.length; j++){
        if(v.y >= (j*height)-overlap && v.y < ((j+1)*height)-overlap){
          this.finalDelta = j
        }
      }
    } else if(v.y <= overlap-height){ //dragged up
      for(let jj=1; jj<this.state.sheet.length; jj++){
        if(v.y <= (jj * -height)+overlap && v.y > ((jj+1) * -height)+overlap){
          this.finalDelta = -jj
        }
      }
    }
    if(this.finalDelta!==this.state.finalPlace){
      this.setState({finalPlace:this.finalDelta})
    }
  }
  onDragEnd(){
    if(this.finalDelta || this.finalDelta===0){
      const sheet = this.state.sheet.map(row => [...row])
      sheet.splice(this.finalDelta, 0, 
        sheet.splice(this.state.currentlyBeingDragged, 1)[0]
      )
      this.setState({sheet})
      this.finalDelta=null
    }
    this.setState({currentlyBeingDragged:null,finalPlace:null})
  }

  render() {
    const {redux, actions, match} = this.props
    const {params} = match
    const {project, main} = redux
    const uiEnvironment = this.state.selectedEnv
    const bit = ['Source','Target']
    const {showApiNames, loadingCreds, editingName, title, sortByColumn, sortDescending, lastModified, lastModifiedBy, description, envCreds, showShareModal, isPublic,sourceSelectedProvider, targetSelectedProvider, mapperGuid, headerWidths, currentlyBeingDragged, finalPlace} = this.state
    const providers = envCreds ? [...envCreds] : []
    providers.sort((a,b)=>{
      if(a.providerLabel < b.providerLabel) return -1;
      if(a.providerLabel > b.providerLabel) return 1;
      return 0;
    })
    providers.push({
      providerLabel:'Custom',
    })

    let grid = sheetToGrid(this.state.sheet, showApiNames)

    // sort
    if(sortByColumn || sortByColumn===0){
      grid = grid.sort((one,two)=>{
        const a = one[sortByColumn].value
        const b = two[sortByColumn].value
        return sortDescending ? 
          ((a===undefined)-(b===undefined) || +(a>b)||-(a<b)) :
          ((b===undefined)-(a===undefined) || -(a>b)||+(a<b))
      })
    }

    // assign colors
    const rowColors = makeRowColors(grid)

    let totalWidth = 0
    headerWidths.forEach((w)=> totalWidth += w)

    return (<div className="parent-env">
      <div className="main-tab-content"
      style={{width:'100%',height:'100%',paddingTop:65,paddingRight:12}}>
        <div style={{position:'relative'}}>
          <div style={{fontSize:'16px', verticalAlign:'top', 
            display:'inline-block', height:26}}>
            {editingName ? <EditName title={title}
              onChange={(v)=>this.setState({title:v})} /> :
            <span>{title}</span>}
          </div>
          <div className="mapper-edit-name-wrap">
            {editingName ? <div style={{display:'inline-block'}}>
              <svg className="mapper-edit-name" viewBox="0 0 24 24"
                onClick={()=>this.setState({editingName:false})}>
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
              </svg>
            </div> :
            <svg viewBox="0 0 24 24" className="mapper-edit-name"
              onClick={()=>this.setState({editingName:true})}>
              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>}
          </div>
          <HistoryBox redux={redux} type="Data Mapping"
            lastModified={lastModified} lastModifiedBy={lastModifiedBy}
            wrapStyle={{height:28, width:29, paddingTop:0, position:'absolute',right:0}}
            boxStyle={{top:26, right:-4}} iconStyle={{fill:'#666'}}
          />
        </div>

        <div style={{marginTop:10}}>
          <div style={{display:'inline-block'}}>
            <EnvironmentDropdown
              uiEnvironment={uiEnvironment}
              environments={project.environments}
              disabled={!(project.environments && project.environments.length > 0)}
              onSelect={env => this.changeEnvironment(env)}
              actions={actions}
            />
          </div>
          {loadingCreds && <div style={{
              paddingLeft:10, display:'inline-block', verticalAlign:'bottom'
            }}>
            <img alt="spinner" src={images.ajax_loader} 
              style={{height:16, marginBottom:6}}
            />
          </div>}
          {this.state.envCreds && <span>
            {this.state.envCreds.length===0 ? <div style={{
              display:'inline-block',margin:'0 0 5px 12px',verticalAlign:'bottom'}}>
              No Connecters in this Environment
            </div> : 
            <span>
              {bit.map((mode,ii)=>{ // "source" and "target"
                const m = mode.toLowerCase()
                const pLabel = this.state[`${m}SelectedProviderLabel`]
                const checking = this.state[`${m}Checking`]
                const result = this.state[`${m}CheckResult`]
                return(<div style={{display:'inline-block', marginLeft:12}} key={ii}>
                  <EnvCheckIcons checking={checking} result={result} />
                  {providers && providers.length>0 && <div
                    style={{display:'inline-block'}}>
                    <strong className="labelz" style={{fontSize:'11px'}}>
                      {mode} System</strong><br />
                    <DropdownButton id="providers" placeholder="Providers"
                      title={pLabel || "Data Connectors"}
                      bsStyle="default" bsSize="small" style={{width:140}}
                      className="environments-dropdown">
                      {providers && providers.map((c, i)=>{
                        let p = main.providers.find(pr=>pr.type===c.providerLabel)
                        if(c.providerLabel==='Custom'){
                          p = {label: 'Custom'}
                        }
                        return p ? (<MenuItem value={c.providerLabel} key={i}
                          onClick={()=>this.getMetadata(c,mode)}>
                          {p.label || p.type}
                        </MenuItem>) : (<span key={i} />)
                      })}
                    </DropdownButton>
                  </div>}
                </div>)
              })}
              <Button bsSize="small" bsStyle="default"
                style={{width:95,marginLeft:20,paddingLeft:5}} onClick={this.addRow}>
                <img
                  alt="add-row"
                  src={images.ic_add_grey600_48dp}
                  height="16"
                />
                <span style={{marginLeft:6}}>Add Row</span>
              </Button>
              <Button bsSize="small" bsStyle="default"
                style={{width:72,marginLeft:12}} 
                onClick={()=>this.setState({showShareModal:true})}>
                <span>Share</span>
              </Button>
              <Button bsSize="small" bsStyle="primary"
                disabled={!sourceSelectedProvider || !targetSelectedProvider}
                style={{width:99,marginLeft:12}} onClick={this.saveMapping}>
                {this.state.saving ? <img
                    alt="small-spinner"
                    src={images.ajax_loader_small}
                    height="8"
                  /> : 
                  <span>Save Mapping</span>
                }
              </Button>
              <div style={{display:'inline-block',marginLeft:20}}>
                <strong className="labelz" 
                style={{fontSize:11,verticalAlign:'middle',display:'inline-block'}}>
                Labels:</strong>&nbsp;&nbsp;
                <ToggleButtonGroup
                  type="radio" bsSize="xsmall" name="options"
                  value={this.state.showApiNames?'system':'friendly'} 
                  onChange={this.handleToggle}>
                  <ToggleButton value={'friendly'}>Friendly</ToggleButton>
                  <ToggleButton value={'system'}>System</ToggleButton>
                </ToggleButtonGroup>
              </div>
              {params.mapperId !== '0' && <Button bsSize="small" bsStyle="danger"
                style={{width:40,marginLeft:20,height:30}} 
                onClick={this.triggerModal}>
                {this.state.deleting ? <img
                  alt="small-spinner"
                  src={images.ajax_loader_small}
                  height="8"
                /> : <img src={images.ic_delete_white_48dp} 
                style={{height:18,marginRight:5}}/>}
              </Button>}

            </span>}
          </span>}

        </div>

        <br />
        <div style={{overflowY:'visible',height:'calc(100% - 108px)'}}>
          <div style={{overflowX:'scroll',width:'100%',height:'100%'}}>
            <div style={{width: totalWidth+18+18}}>
              <div style={{display:'inline-block',width:18,
                marginTop:27,verticalAlign:'top',position:'relative'}}>
                {grid.map((r,i)=>{
                  if(r && r.length) {
                    return (<DraggableCore key={i} 
                      axis="y"
                      onStart={(e,d)=>this.onDragStart(d,i)}
                      onStop={(e,d)=>this.onDragEnd(d,i)}
                      onDrag={(e,d)=>{
                        if(d.y>0 && d.y<grid.length*27){
                          this.onDrag(d,i)
                        }
                      }}
                      handle=".map-drag-handle">
                      <DragHandle 
                        disabled={(sortByColumn||sortByColumn===0)?true:false}
                      />
                    </DraggableCore>)
                  } else return <span key={i}/>
                })}
              </div>
              <div style={{
                display: 'inline-block',
                background:'white',
                boxShadow: '0 0 6px #ccc',
                width: totalWidth,
                verticalAlign:'top'}}>
                <MapperHeader widths={headerWidths}
                  onWidthChange={this.onWidthChange}
                  sort={this.sort} sortByColumn={sortByColumn}
                  sortDescending={sortDescending}
                />
                <ReactDataSheet
                  data={grid}
                  valueRenderer={(cell) => cell.value}
                  cellRenderer={(props)=> <CellRenderer {...props} 
                    rowColors={rowColors}
                    headerWidths={headerWidths}
                  />}
                  onContextMenu={(e, cell) => cell.readOnly ? e.preventDefault() : null}
                  onCellsChanged={changes => {
                    const sheet = this.state.sheet.map(row => [...row])
                    changes.forEach(({cell, col, value}) => {
                      const {sheetRowIndex} = cell
                      if(cell){
                        const isCustom = this.selectedProviderIsCustom(
                          _columns[col].mode
                        )
                        if(_columns[col].isObject || _columns[col].isField){
                          if(isCustom){
                            sheet[sheetRowIndex][col] = {...sheet[sheetRowIndex][col], 
                              label: value,
                              name: value,
                              type: 'string'
                            }
                          } else {
                            sheet[sheetRowIndex][col] = {...sheet[sheetRowIndex][col], 
                              label: value.label,
                              name: value.name,
                              type: _columns[col].isObject ? 
                                value.type : value.originalType,
                              parents: value.parents
                            }
                          }
                        } else {
                          sheet[sheetRowIndex][col] = {
                            ...sheet[sheetRowIndex][col], value
                          }
                        }
                        if(_columns[col].isObject && !isCustom){
                          // delete field/type is object changed
                          sheet[sheetRowIndex][col+1] = {}
                          sheet[sheetRowIndex][col+2] = {}
                        }
                        this.setState({sheet})
                      }
                    })
                  }}
                  dataEditor={props => {
                    const {sheetRowIndex} = props.cell
                    const column = _columns[props.col]
                    if(column.comp==='dropdown'){
                      if(this.selectedProviderIsCustom(column.mode)){
                        return <InputCell {...props} />
                      }
                      let metadata = this.state[`${column.mode}Metadata`]
                      let provider = this.state[`${column.mode}SelectedProvider`]
                      let disabled = false
                      if(column.isField){
                        const previous = grid[props.row][props.col-1]
                        if(previous.value){
                          const rsc = metadata && metadata.find((o)=>{
                            return previous.value===(showApiNames?o.type:o.name)
                          })
                          if(rsc){
                            metadata = rsc.properties
                            provider = showApiNames?rsc.type:rsc.name
                          } else {
                            disabled = true
                          }
                        } else {
                          disabled = true
                        }
                      }
                      return (<MetadataCell {...props} 
                        metadata={metadata} provider={provider}
                        disabled={disabled} column={column} 
                        fullVal={this.state.sheet[sheetRowIndex][props.col]} 
                        showApiNames={showApiNames} 
                        onDelete={()=>{
                          const sheet = this.state.sheet.map(row => [...row])
                          const _c = _columns[props.col]
                          sheet[sheetRowIndex][props.col] = ''
                          if(_c.isObject){
                            sheet[sheetRowIndex][props.col+1] = ''
                          }
                          this.setState({sheet})
                        }}
                      />)
                    } else if(column.comp==='checkbox'){
                      return <CheckboxCell {...props} />
                    } else if(column.comp==='textarea'){
                      return <TextareaCell {...props} />
                    } else {  
                      return <InputCell {...props} />
                    } 
                  }}
                  rowRenderer={props => {
                    return(<tr style={{
                      opacity:props.row===currentlyBeingDragged?0.6:1,
                      borderBottom:props.row===finalPlace?'2px solid #54698d':'none'
                    }}>
                      {props.children}
                    </tr>)
                  }}
                />
              </div>
              <div style={{display:'inline-block',width:18,
                marginTop:27,verticalAlign:'top'}}>
                {grid.map((r,i)=>{
                  return <DeleteRow key={i} onDelete={()=>this.deleteRow(i)} />
                })}
              </div>
            </div>

            <Description description={description} 
              changeDescription={(a,b,c)=>this.setState({description:c})}
            />
          </div> {/* end of scroll x div */}
        </div> {/* end of visible div for dropdowns */}

        {/*this.state.showComments && <CommentBar {...this.props} 
          commentList={project.dataMappingComments}
          callPostComment={actions.callPostMapperComment}
          callDeleteComment={actions.callDeleteMapperComment}
          containerId={this.props.match.params.mapperId}
        />*/}    
        <ConfirmDeleteModal 
          modalState={this.state.modal}
          triggerModal={this.triggerModal}
          nameOfTarget={this.state.title}
          deleteTarget={this.deleteMapping}
        />   

        <ShareModal show={showShareModal} grid={grid} title={title} guid={mapperGuid}
          onClose={()=>this.setState({showShareModal:false})}
          handleToggle={this.handlePublicToggle} isPublic={isPublic}
        />

      </div>
    </div>)
  }
}

class EditName extends Component {

  componentDidMount(){
    this.inputRef.focus()
  }

  render() {
    const {title, onChange} = this.props
    return (
      <FormControl type="input" label="Data Mapping Name" value={title}
        onChange={(e)=>onChange(e.target.value)}
        style={{width:200,height:25,display:'inline-block'}}
        inputRef={(ref)=>{ this.inputRef=ref }}
        onKeyPress={(e)=>{
          if(e.key==='Enter') {
            onChange(e.target.value)
          }
        }}
      />
    )
  }
}

const DragHandle = (props) => {
  return (<div className="map-drag-handle" {...props}
    style={props.disabled?
      {pointerEvents:'none',opacity:0.3,transform:'scale(1,1)'}:
    {}}>
    <svg width="16" height="16" viewBox="0 0 24 24">
      <path d="M9,3H11V5H9V3M13,3H15V5H13V3M9,7H11V9H9V7M13,7H15V9H13V7M9,11H11V13H9V11M13,11H15V13H13V11M9,15H11V17H9V15M13,15H15V17H13V15M9,19H11V21H9V19M13,19H15V21H13V19Z" />
    </svg>
  </div>)
}

