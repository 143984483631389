/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import "babel-polyfill";
import React from "react";
import ReactGA from "react-ga";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import { syncHistoryWithStore } from "react-router-redux";
import HttpsRedirect from "react-https-redirect";
import createStore from "./shared/redux/create";
import ApiClient from "./shared/apiClient";
import { getRoutes } from "./shared/routes";
import { SETTINGS } from "./shared/settings";
import history from "./history";
import "./intercom";
import "./stylesheets/index.less";
import environment from "environment";
export const db = new Dexie("Recipes");
db.version(1).stores({
  recipes: "++id, BaseId",
});
db.version(2).stores({
  recipes: "++id, appId, BaseId",
});
db.open().catch(function (err) {
  console.error("Failed to open db: " + (err.stack || err));
});

const client = new ApiClient();
const store = createStore(client, history, window.__data);
const historysync = syncHistoryWithStore(history, store);
const { __DEVELOPMENT__, NODE_ENV } = environment;
//-------------------------------
// ApiClient && Root element
//-------------------------------
const dest = document.getElementById("react-root");

let UA_CODE;

if (__DEVELOPMENT__) {
  UA_CODE = SETTINGS.DEVELOPMENT.ANALYTICS.UA;
} else {
  if (store.getState().config.HOME_URL === "https://app.lingk.io") {
    UA_CODE = SETTINGS.PRODUCTION.ANALYTICS.UA;
  } else {
    UA_CODE = SETTINGS.INTEGRATION.ANALYTICS.UA;
  }
}

ReactGA.initialize(UA_CODE, {
  debug: false, //__DEVELOPMENT__ || false,
  titleCase: false,
});

const component = (
  <Router
    history={historysync}
    getUserConfirmation={(message, callback) => {
      // this is called when user sees Prompt to clear appliation's recipes
      const allowTransition = window.confirm(message);
      if (allowTransition) {
        const loc = location.href.split("/");
        let appId = loc.findIndex((a) => a == "a");
        window.localStorage.setItem("clearApp", loc[appId + 1]);
      }
      callback(allowTransition);
    }}
  >
    {getRoutes(store)}
  </Router>
);

if (NODE_ENV !== "production") {
  window.React = React; // enable debugger

  // if (!dest || !dest.firstChild || !dest.firstChild.attributes || !dest.firstChild.attributes['data-react-checksum']) {
  //   console.error('Server-side React render was discarded. Make sure that your initial render does not contain any client-side code.');
  // }
}

ReactDOM.render(
  <Provider store={store} key="provider">
    <div>
      {component}
      {/*<DevTools />*/}
    </div>
  </Provider>,
  document.getElementById("react-root")
);
