const DataProfileFieldStyle = {
  dialogStyles: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    width: 550,
    height: 400,
    marginTop: -200,
    marginLeft: -250,
    boxSizing: 'border-box',
    borderRadius: 3,
    padding: '10px 10px 10px 10px',
    backgroundColor: '#fff',
    whiteSpace: 'nowrap',
    display: 'block',
    boxShadow: '0 1px 15px 0 rgba(0,0,0,.1),0 0 0 0 transparent',
    zIndex: 2000,
    border: '1px solid #c9d7df',
  },
  scrollingDivStyles: {
    overflow: 'auto',
    height: 300,
  },
  closeStyles: {
    position: 'absolute',
    right: 10,
  },
  linkStyles: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  contentStyles: {
    marginTop: '60px',
    marginLeft: '30px',
    overflow: 'auto',
  },
};

export default DataProfileFieldStyle;
