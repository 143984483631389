import React, { Component } from 'react';

export default class InActiveTenant extends Component {
  render(){
    return(
      
      <div style = {{marginTop:80}}>
              <div style={{padding:20, textAlign: 'center'}}>
                    No active organizations and projects are associated with your user.<br />
                    Contact your organization administrator to regain access to projects. <br />
                    For additional help, please contact Lingk support using the "Help" tab on the right of the screen.
              </div>
      </div>
    )
  }
}
