import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import RecipeTabs from "./recipetabs";
import EditTransformer from "./edittrans";
// import MonitorTransformer from "./monitorTransformerTemp";
import TransformerSettings from "./edittransSettings";
import Trigger from "./trigger";
import MainEditor from "./mainEditor";
class RecipeTabParent extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/a/:appId/tf/:transId"
          render={(routerProps) => (
            <MainEditor
              {...routerProps}
              redux={this.props.redux}
              actions={this.props.actions}
            />
          )}
        />
        {/* <Route
          path="/a/:appId/health"
          exact
          render={(routerProps) => (
            <MonitorTransformer
              {...routerProps}
              redux={this.props.redux}
              actions={this.props.actions}
            />
          )}
        /> */}
        <Route
          path="/a/:appId/tf/:transId/deploy"
          render={(routerProps) => (
            <Trigger
              {...routerProps}
              redux={this.props.redux}
              actions={this.props.actions}
            />
          )}
        />

        <Route
          path="/a/:appId/tf/:transId/info"
          render={(routerProps) => (
            <TransformerSettings
              {...routerProps}
              redux={this.props.redux}
              actions={this.props.actions}
            />
          )}
        />
      </Switch>
    );
  }
}
export default RecipeTabParent;
