import React, { Component } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap'
import MarkdownIt from 'markdown-it';
import DomPurify from 'dompurify'
import images from '../components/images'
import yaml from 'js-yaml';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as projectActions from "../shared/redux/actions/projectActions";
import * as mainActions from "../shared/redux/actions/mainActions";
import * as transformerActions from "../shared/redux/actions/transformerActions";
import * as datafeedActions from "../shared/redux/actions/datafeedActions";
import * as wizardActions from "../shared/redux/actions/wizardActions";
import * as environmentActions from "../shared/redux/actions/environmentActions";
import * as eventsActions from "../shared/redux/actions/eventsActions";

//require('../helpers/yaml')

const url = 'https://s3-us-west-2.amazonaws.com/data-protect-config/data-protect-resources.yml'

class Resources extends Component {

  constructor(props){
    super(props)
    this.state={
      obj:null 
    }
    this.md = new MarkdownIt();
  }

  componentWillMount(){
    axios({
      method: 'get',
      url,
    }).then((res) => {
      const obj = yaml.safeLoad(res.data);
      this.setState({obj})
    })
  }

  componentDidMount() {
    this.props.actions.setShowMenu(false)
  }

  createMarkup(desc) {
    return {
      __html: DomPurify.sanitize(desc)
    };
  }

  render() {
    return (
      <div style={{paddingTop:50, width:'100%', height:'100%', overflowY:'scroll'}}>

        <div className="resources-section">
          <h3 style={{marginLeft:49}}>Resources</h3><br />
          <div className="resources-tile-wrap">
            <a className="resources-tile" style={{background:'#ffe598'}}
              href="https://guide.lingk.io" target="_blank" rel="noopener noreferrer">
              User Guide
            </a>
            <a className="resources-tile" style={{background:'#b6d7a8'}}
              href="https://lingk.gitbooks.io/transformer-recipes/content/"
              target="_blank" rel="noopener noreferrer">
              Recipe Guide
            </a>
            <a className="resources-tile" style={{background:'#6c9eec'}}
              href="https://developers.lingk.io"
              target="_blank" rel="noopener noreferrer">
              API Guide
            </a>
          </div>
        </div>

        <div className="resources-section" style={{marginTop:40}}>
          {this.state.obj ? <div style={{width:'calc(100% - 98px)',marginLeft:49}}>
            {Object.keys(this.state.obj).map((ok,ii)=>{
              const o = this.state.obj[ok]
              const header = Object.keys(o[0])
              if (o && !ok.includes('Markdown')) {
                return (<div key={ii} style={{marginTop:16}}>
                  <h5>{ok}</h5>
                  <Table condensed hover 
                    style={{background:'white'}}>
                    <thead className="mapping-table-header">
                      <tr>
                        {header.map((h,iii)=>{
                          return <th key={iii}>{h}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {o.map((f,i)=><tr key={i}>
                        {header.map((h,iiii)=>{
                          if(h==='File'){
                            const a = f[h].split(')[')
                            if (a.length > 1) {
                              return (<td key={iiii}>
                                <a href={a[1].substring(0, a[1].length - 1)} 
                                  target="_blank" rel="noopener noreferrer">
                                  {a[0].substring(1, a[0].length)}
                                </a>
                              </td>)
                            }
                          }
                          return <td key={iiii}>{f[h]}</td>
                        })}
                      </tr>)}
                    </tbody>
                  </Table>
                </div>)
              } else if(o && ok.includes('Markdown')) {
                const html = this.createMarkup(this.md.render(o));
                return (<div dangerouslySetInnerHTML={html} key={ii} 
                  style={{marginTop:16}} />)
              }
              return <span key={ii} />
            })}
          </div> :
          <div style={{marginLeft:49}}>
            <img alt="spinner" src={images.ajax_loader} />
          </div>}
        </div> 
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = state => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
