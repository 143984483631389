import React from "react";
import moment from "moment";
import {Button} from "react-bootstrap";
import {findNotificationFrequency} from "./utils";
import UsageDoughnutChart from "./usageDoughnutChart";

const CardLicensedPeriod = ({
  data = {},
  setType,
  setEditModal = null,
  notificationFrequency,
}) => {
  const name = "LicensedPeriod";
  const licensedPeriodDPH = data.licensedPeriodDPH;
  const tenantDPHAdditionalPurchases = data.tenantDPHAdditionalPurchases;

  const {
    startDate,
    endDate,
    dataProcHours,
    usedDataProcHours,
    usedDataProcHoursPercentage,
    availableDataProcHours,
    availableDataProcHoursPercentage,
    threshold,
    thresholdPercentage,
    thresholdValueHours,
    thresholdRemainingHours,
    notificationEmails,
    notificationFrequencyId,
  } = licensedPeriodDPH;

  // handlers
  const getDphPeriod = () => {
    try {
      return `(${moment(startDate).format("MMM DD, yyyy")} - ${moment(
        endDate
      ).format("MMM DD, yyyy")})`;
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  return (
    <div onClick={() => setType(name)}>
      <div className="dph-card">
      <span className="dph-card__primary-text dph-card__primary-text__fieldset">
        <strong>Licensed Period Summary</strong>
      </span>
        {/* information row */}
        <div className="dph-card__info-row">
          <div className="dph-card__secondary-text">
            <span><strong>🗓️ License Period: </strong> {getDphPeriod()}</span>
            <br/><br/>
            <div style={{width: '300px'}}>
              <UsageDoughnutChart dataProcHours={dataProcHours}
                                                usedDataProcHours={usedDataProcHours}
                                                usedDataProcHoursPercentage={usedDataProcHoursPercentage}
                                                availableDataProcHours={availableDataProcHours}
                                                availableDataProcHoursPercentage={availableDataProcHoursPercentage}
                                                threshold={threshold}
                                                thresholdPercentage={thresholdPercentage}
                                                thresholdValueHours={thresholdValueHours}
                                                thresholdRemainingHours={thresholdRemainingHours}/>
            </div>
            {
                tenantDPHAdditionalPurchases && tenantDPHAdditionalPurchases.map((additionalPurchase, index) => (
                    <div style={{
                      fontSize: "15px",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }} key={index}>
                  <span style={{color: "deepskyblue"}} key={index}>
                   {additionalPurchase.dataProcHours} hours Added on {additionalPurchase.purchasedDate}
                  </span>
                    </div>
                ))
            }
          </div>
          {/* frequency */}
          <fieldset style={{
            "border": "1px solid gray",
            marginBottom: "1em",
            padding: "1em",
            minWidth: "410px",
            borderColor: "darkgray",
            borderRadius: "5px",
            borderRight: "1px solid #666",
          }}>
            <legend style={{borderBottom: "none", fontSize:"18px", width:"25%"}}>Notifications</legend>
            <span className="dph-card__secondary-text">
              Threshold set at
              <strong> {thresholdPercentage}%</strong> of{" "}
              <strong> {dataProcHours}</strong> hours at {" "}
            </span>
            <span className="dph-card__label">
              {findNotificationFrequency(notificationFrequency, notificationFrequencyId)}
            </span>
            <br/>
            <br/>
            <label>Emails</label>
            <div className="dph-card-email_container">
              {
                  notificationEmails &&
                  notificationEmails.map((email, index) => (
                      <span key={index} className="dph-card__email">
                       {email}
                      </span>
                  ))
              }
            </div>
              {/* edit */}
              <Button
                  onClick={() => setEditModal(true)}
                  bsStyle="primary"
              >
                Edit
              </Button>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default CardLicensedPeriod;
