import React, { Component } from "react";
import LogResultTable from "./logResultTable";
import NewWindow from "react-new-window";

export default class EditTransRunResult extends Component {
  constructor() {
    super();
    this.jobsRef = React.createRef();
    this.state = {
      openJobsInfo: false,
      stepId: "",
    };
  }

  componentWillMount() {
    this.jobsInterval = setInterval(() => {
      if (
        this.jobsRef.current &&
        this.jobsRef.current.released &&
        this.state.openJobsInfo
      ) {
        this.setState({ openJobsInfo: false });
      }
    }, 1000);
  }

  render() {
    const isSchema = this.props.statement.includes("printschema");
    const isTable =
      !isSchema &&
      (this.props.statement.includes("print") ||
        this.props.statement.includes("PRINT"));
    let jobIds = this.props.extendedProperties;
    const externalExecutionId = this.props.externalExecutionId;
    const environmentId = this.props.env;
    const { appId } = this.props;
    return (
      <div className="run-transformer-result">
        <div
          style={{ display: "inline-block", width: "30%", marginLeft: "1%" }}
        >
          {this.props.statement}
        </div>
        <div
          style={{
            display: "inline-block",
            width: "51%",
            wordWrap: "break-word",
            marginLeft: "3%",
            verticalAlign: "bottom",
          }}
        >
          {isTable ? (
            <LogResultTable result={this.props.result} />
          ) : isSchema ? (
            <pre>{this.props.result}</pre>
          ) : jobIds && jobIds != "" ? (
            <span>
              {`${this.props.result}`}&nbsp;(
              <span
                className="span-as-link"
                onClick={() =>
                  this.setState({
                    openJobsInfo: true,
                    stepId: this.props.stepGuid,
                  })
                }
              >
                details
              </span>
              {/* <a
                href={`/a/${appId}/health/sfjobs?env=${environmentId}&step=${this.props.stepGuid}`}
                target="blank"
              >
                details
              </a> */}
              )
            </span>
          ) : (
            `${this.props.result}`
          )}
        </div>
        <div
          style={{ display: "inline-block", width: "13%", marginLeft: "2%" }}
        >
          {this.props.timer.toFixed(5)}
        </div>
        {this.state.openJobsInfo && (
          <NewWindow
            copyStyles={false}
            ref={this.jobsRef}
            name={`SF Jobs ${externalExecutionId}`}
            title={`SF Jobs ${externalExecutionId}`}
            features={{ noopener: "_blank" }}
            url={`/a/${appId}/health/sfjobs?step=${this.state.stepId}&env=${environmentId}`}
          />
        )}
      </div>
    );
  }
}
