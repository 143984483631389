import React, { Component } from 'react';
import ContentWsStyles from '../../styles/contentwsstyle';

export default class EditProperties extends Component {

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeInput = this.changeInput.bind(this);
  }

  componentDidMount() {
    const { project } = this.props.redux;
    this.props.actions.callGetProjectMetadataDefinition(project.tenantInfo.Key, project.appData.uniquekey);
    this.props.actions.callGetProjectMetadata(project.tenantInfo.Key, project.appData.uniquekey);
  }

  handleSubmit(e) {
    e.preventDefault()
    const { project } = this.props.redux;
    this.props.actions.callPostProjectMetadata(project.tenantInfo.Key, project.appData.uniquekey)
  }

  changeInput(e){
    this.props.actions.changeProjectMetadata(e.target.name, e.target.value);
  }

  render() {

    const { project } = this.props.redux
    let content = ''
    if(project.customMetadata==null){
      return <div />
    } else if(project.customAllowedFields==null || (project.customAllowedFields && project.customAllowedFields.length===0)) {
      content = (
        <div style={{width:500}}>
          <strong>Need more information about your customers and products?</strong><br />
          <span>Project properties are defined for your account by contacting Lingk support. Once defined, you can edit the values on each project's Properties page.</span><br /><br />
          <strong>Using properties</strong><br />
          <span>Properties are available in all your API calls with the `includeProjectMetadata` parameter. For more information see the Lingk developer documentation.</span>
        </div>
      );
    } else {
      const allowedFields=[]
      const inputs = []
      for(let i=0; i<project.customAllowedFields.length; i++){
        const name=project.customAllowedFields[i]
        const value = project.customMetadata[name] ? project.customMetadata[name] : ''
        allowedFields.push(
          <div style={{padding:9}} key={i}>{name}:</div>
        )
        inputs.push(
          <div style={{margin:4}} key={i}>
            <input className="form-control" type="text" onChange={this.changeInput}
              value={value} name={name} />
          </div>
        )
      }
      content = (
        <form onSubmit={this.handleSubmit}>
          <div style={{display:'table-cell',verticalAlign:'middle'}}>
            {allowedFields}
          </div>
          <div style={{display:'table-cell',verticalAlign:'middle'}}>
            {inputs}
          </div>
          <div style={{marginTop:24}}>
            <button type="submit" value="Post" className="btn btn-sm btn-primary" 
              style={{display:'inline-block'}}>Save</button>
            {project.showSaved ? <div style={{display:'inline-block',marginLeft:24}}>Properties Saved</div> : null}
          </div>
          <br /><br />
          <strong>Using properties</strong><br />
          <p style={{width:365}}>Properties are available in all your API calls with the `includeProjectMetadata` parameter. For more information see the Lingk developer documentation.</p>
        </form>
      )
    }

    return( 
      <div className="main-tab-content">
        <br />
        <div style={ContentWsStyles.fullWrapper}>
          {content}
        </div>
      </div>
    )
  }
}
