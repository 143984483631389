import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom'

import ContentWsStyles from '../../styles/contentwsstyle';

export default class NewDataFeed extends Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  callCreateFeed(name, description, email, usage) {
    const { project } = this.props.redux
    this.props.actions.callCreateDatafeed(
      project.tenantInfo.TenantId, 
      {
        appidfrom: parseInt(this.props.match.params.appId, 10),
        name,
        description,
        email,
        usage,
        key: this.props.redux.config.HOME_URL
      },
      this.props.match.params.appId);
    this.props.history.push('/a/' + this.props.match.params.appId + '/db/feeds')
  }

  validateEmail(email) {
    const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }

  handleSubmit(e) {
    e.preventDefault();

    var name = ReactDOM.findDOMNode(this.refs.name).value.trim();
    var description = ReactDOM.findDOMNode(this.refs.description).value.trim();
    var email = ReactDOM.findDOMNode(this.refs.email).value.trim();
    var usage = ReactDOM.findDOMNode(this.refs.usage).value.trim();

    if (!name || !email) {
      return;
    }

    if (!this.validateEmail(email)) {
      alert('Email should be a valid address.');
      return;
    }

    this.callCreateFeed(name, description, email, usage);
  }

  render() {

    return (<div className="main-tab-content" style={{marginTop:60}}>
      <form onSubmit={this.handleSubmit}>
        <div style={{fontSize:20}}>New Data Feed</div>
        <div style={ContentWsStyles.formElementTitle}>Name</div>
        <div>
          <input className="form-control" type="text" ref="name" />
        </div>

        <div style={ContentWsStyles.formElementTitle}>Describe This Data</div>
        <div>
          <textarea className="form-control-textarea" type="text" ref="description" />
        </div>

        <div style={ContentWsStyles.formElementTitle}>Invite By Email</div>
        <div>
          <input className="form-control"  type="text" ref="email" />
        </div>

        <div style={ContentWsStyles.formElementTitle}>Intended Usage For Data</div>
        <div>
          <select className="form-control" name="usage" ref="usage">
            <option value="Analytics">Analytics</option>
            <option value="Content">Content</option>
            <option value="Curriculum Development">Curriculum Development</option>
            <option value="Distance Learning">Distance Learning</option>
            <option value="Learning Management">Learning Management</option>
            <option value="Marketing Research">Marketing Research</option>
            <option value="Provisioning">Provisioning</option>
            <option value="Rostering">Rostering</option>
            <option value="Student Retention">Student Retention</option>
            <option value="Student Acquisition">Student Acquisition</option>
            <option value="Teaching & Learning">Teaching & Learning </option>
            <option value="Other">Other</option>
          </select>
        </div>
        <br />

        <div>
          <Link to={{pathname: '/a/' + this.props.match.params.appId + '/db/feeds'}}>
            <input type="button" value="Cancel" className="btn btn-sm btn-primary" />
          </Link>&nbsp;&nbsp;
          <button type="submit" value="Post" className="btn btn-sm btn-primary">Save</button>
        </div>
      </form>
    </div>)
  }
}
