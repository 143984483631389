import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import Paper from "@material-ui/core/Paper";
import { PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import _ from "lodash";
import images from "../../images";

import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import { Fragment } from "react";

export default (props) => {
  const { actions, redux, match } = props;
  const { main } = redux;
  const { params } = match;
  const { appList } = main;
  const { appId } = params;
  const [loading, setLoading] = useState(true);

  let tenant;
  if (appList && appList.length > 0) {
    tenant = appList.find((a) => a.Id == appId);
  }
  const [failedResultofJob, setFailedResultofJob] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  let jobId, envId, connectionKey;
  if (queryParams.has("jobId")) {
    jobId = queryParams.get("jobId");
  }
  if (queryParams.has("env")) {
    envId = queryParams.get("env");
  }
  if (queryParams.has("connectionKey")) {
    connectionKey = queryParams.get("connectionKey");
  }
  let keyValues = [];
  let columns = [];
  let headers = [];
  let defaultColumnWidths = [];
  if (failedResultofJob) {
    failedResultofJob.map((r) => {
      let row = r;
      Object.keys(row).forEach((k) => {
        if (!keyValues.includes(k)) {
          keyValues.push(k.toLowerCase());
        }
      });
    });
  }
  keyValues &&
    keyValues.length > 0 &&
    keyValues.map((k) => {
      columns.push({ name: k, title: _.camelCase(k) });
      headers.push({ label: k, key: k });
      defaultColumnWidths.push({ columnName: k, width: 200 });
    });
  const csvreport = {
    data: failedResultofJob,
    headers: headers,
    filename: "SF_Failed_Report.csv",
  };
  useEffect(() => {
    if (tenant && tenant.Id && jobId && envId) {
      actions
        .getFailedResultsofJob(
          tenant.tenantId,
          appId,
          envId,
          connectionKey,
          jobId
        )
        .then((data) => {
          let formattedData = data.map((d) => {
            let toLower = Object.keys(d).map((k) => {
              return { [k.toLowerCase()]: d[k] };
            });
            return Object.assign({}, ...toLower);
          });
          setFailedResultofJob(formattedData);
          setLoading(false);
        });
    }
  }, [tenant]);

  const [pageSizes] = useState([5, 10, 15, 0]);

  return (
    <div>
      <span style={{ fontSize: 14 }} className="sf-header">
        {" "}
        Salesforce Bulk Job Failed Record Results - {jobId}
      </span>
      {loading ? (
        <img alt="spinner" src={images.ajax_loader} height="15" />
      ) : (
        <Fragment>
          <CSVLink
            {...csvreport}
            style={{ position: "absolute", right: "50px", top: "12px" }}
          >
            Download CSV
          </CSVLink>
          <Paper
            className="sf-jobs-table"
            style={{ marginTop: 30, overflowY: "auto", maxHeight: "80vh" }}
          >
            <Grid rows={failedResultofJob} columns={columns}>
              <PagingState defaultCurrentPage={0} defaultPageSize={5} />
              <IntegratedPaging />
              <Table />
              <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />

              <TableHeaderRow />
              <PagingPanel pageSizes={pageSizes} />
            </Grid>
          </Paper>
        </Fragment>
      )}
    </div>
  );
};
