import * as types from "./types";

export function createSFLeads(data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { SF_AGENT_API_URL } = config;
    return api.post("lead/create/noauth", {
      endpoint: SF_AGENT_API_URL,
      data: data,
    });
  };
}

export function callGetApps() {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get("applications", {
        endpoint: API_URL,
      })
      .then((data) => {
        const apps = data.filter((a) => !a.archived);
        dispatch({
          type: types.SET_APPLICATIONS,
          data: apps,
        });
        dispatch(callGetAppsCounts(apps));
        return apps;
      });
  };
}

export function callGetAppsCounts(apps) {
  return (dispatch, getState, api) => {
    const { config, main } = getState();
    const { API_URL } = config;
    return api
      .get("applicationsintegrationscount", {
        endpoint: API_URL,
      })
      .then((data) => {
        const Apps = apps.map((a) => {
          const tenant =
            main &&
            main.tenantList &&
            main.tenantList.find((t) => t.TenantId === a.tenantid);
          return {
            ...a,
            integrationCount:
              data.find((d) => d.appId === a.Id) &&
              data.find((d) => d.appId === a.Id).integrationCount,
            tenantName: tenant && tenant.Name,
            tenantId: tenant && tenant.TenantId,
          };
        });
        dispatch({
          type: types.SET_APPLICATIONS,
          data: Apps,
        });
        return apps;
      });
  };
}

export function callGetAccessLevels() {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get("access_levels", {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_ACCESS_LEVELS,
          data,
        });
      });
  };
}

// includes adoptions for all tenants
export function callGetProductizedIntegrationTypesForUser() {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get("integrationtypes/available", {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_WIZARD_TYPES,
          data,
        });
      });
  };
}

export function callGetMe() {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get("me", {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_ME,
          data,
        });
      });
  };
}

export function callGetUsers() {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get("users", {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_USERS,
          data,
        });
        return data;
      });
  };
}

export function inviteUsersToTenant(tenantId, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .post(`tenants/${tenantId}/permissions`, {
        endpoint: API_URL,
        data,
      })
      .then(() => {
        return dispatch(callGetUsers()).then((r) => {
          return r;
        });
      });
  };
}

export function updateTenantAdminState(tenantId, userId, isAdmin) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .put(`tenants/${tenantId}/permissions/${userId}`, {
        endpoint: API_URL,
        data: { isAdmin },
      })
      .then(() => {
        return dispatch(callGetUsers()).then((r) => {
          return r;
        });
      });
  };
}

export function callGetTenantInfo(tenantId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.get(`tenantinfo/${tenantId}`, {
      endpoint: API_URL,
    });
  };
}

// add user and delete user should call get users again
export function callDeleteUser(tenantId, userId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .delete(`${tenantId}/users/${userId}/remove`, {
        endpoint: API_URL,
      })
      .then(() => {
        dispatch(callGetUsers());
      });
  };
}

export function callCreateUser(tenantId, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .post(`${tenantId}/users/create`, {
        endpoint: API_URL,
        data,
      })
      .then((res) => {
        console.log(res);
        dispatch(callGetUsers());
      });
  };
}

export function callUpdateDPTApplication(tenantId, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;

    return api
      .patch(
        `tenants/${tenantId}/max-data-proc-time?maxDataProcTime=${data.threasholdHours}:${data.threasholdMinutes}:00`,
        {
          endpoint: API_URL,
          data,
        }
      )
      .then((d) => {});
  };
}

export function callUpdateUserInfo(tenantId, user, role) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .put(`${tenantId}/users/${user}/${role}`, {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch(callGetUsers());
      });
  };
}

export function setShowMenu(bool) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SHOW_MENU,
      bool,
    });
  };
}

export function toggleAccountsMenu() {
  return (dispatch) =>
    dispatch({
      type: types.TOGGLE_ACCOUNTS_MENU,
    });
}

export function callUpdateUser(tenantId, userId, role, phone) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .put(`${tenantId}/users/${userId}/${role}/${phone}`, {
        endpoint: API_URL,
      })
      .then(() => {
        dispatch(callGetMe());
      });
  };
}

export function callCreateApp(tenantId, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .post(`applications/create/${tenantId}`, {
        endpoint: API_URL,
        data,
      })
      .then(() => {
        return dispatch(callGetApps());
      });
  };
}

export function callCreateNewTenant(name, level) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .post("newtenant", {
        endpoint: API_URL,
        data: { name, level },
      })
      .then(() => {
        dispatch(callGetProductizedIntegrationTypesForUser());
        return dispatch(callGetMainTenantList());
      });
  };
}

export function callGetMainTenantList() {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get("tenantlist", {
        endpoint: API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_TENANT_LIST,
          data,
        });
        return data;
      });
  };
}

export function callGetSharedDataMapping(mapperGuid) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.get(`datamapping/${mapperGuid}/noauth`, {
      endpoint: TRANS_API_URL,
    });
  };
}

export function getElevioEmailHash(email) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    const encodedEmail = encodeURIComponent(email);
    const URI = `eleviohash?email=${encodedEmail}`;
    return api.get(URI, {
      endpoint: API_URL,
    });
  };
}

export function getRecurlySubByAccount(accountCode) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.get(`recurly/subbyaccount/${accountCode}`, {
      endpoint: API_URL,
    });
  };
}

export function getRecurlyInvoices(accountCode, uuid) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.get(`recurly/invoices/${accountCode}/${uuid}`, {
      endpoint: API_URL,
    });
  };
}

export function getRecurlyPlans() {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api
      .get(`recurly/plans/noauth`, {
        endpoint: API_URL,
      })
      .then((data) => {
        return data;
      });
  };
}

export function getRecurlyCoupon(couponCode) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;

    return api.get(`recurly/coupon/${couponCode}/noauth`, {
      endpoint: API_URL,
    });
  };
}

export function getRecurlyRedemptions(accountCode) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;

    return api.get(`recurly/redemptions/${accountCode}`, {
      endpoint: API_URL,
    });
  };
}

export function getRecurlyAddons(planCode) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;

    return api.get(`recurly/addons/${planCode}/noauth`, {
      endpoint: API_URL,
    });
  };
}

// export function getRecurlySubs() {
//   return (dispatch, getState, api) => {
//     const { config } = getState();
//     const { API_URL } = config;
//     return api.get(`recurly/subs`, {
//       endpoint: API_URL,
//     })
//   };
// }

export function createRecurlyPurchase(data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.post(`recurly/purchase/noauth`, {
      endpoint: API_URL,
      data,
    });
  };
}

export function createRecurlyPurchaseLoggedIn(data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.post(`recurly/purchase`, {
      endpoint: API_URL,
      data,
    });
  };
}

export function recurlyServicesPurchase(data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.post(`recurly/purchase/services`, {
      endpoint: API_URL,
      data,
    });
  };
}

export function previewRecurlyPurchase(data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.post(`recurly/purchase/preview`, {
      endpoint: API_URL,
      data,
    });
  };
}

export function getRecurlyBilling(accountCode) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.get(`recurly/billing/${accountCode}`, {
      endpoint: config.API_URL,
    });
  };
}

export function updateRecurlyBilling(accountCode, token) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.put(`recurly/billing/update/${accountCode}/${token}`, {
      endpoint: config.API_URL,
    });
  };
}

// export function updateRecurlyAccount(accountCode, newCode) {
//   return (dispatch, getState, api) => {
//     const { config } = getState();
//     return api.put(`recurly/account/update/${accountCode}/${newCode}`, {
//       endpoint: config.API_URL,
//     })
//   }
// }

export function clearRecurlyBilling(accountCode) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.put(`recurly/billing/clear/${accountCode}`, {
      endpoint: config.API_URL,
    });
  };
}

export function updateRecurlySubscription(data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.put(`recurly/subscription/update`, {
      endpoint: config.API_URL,
      data,
    });
  };
}

export function cancelRecurlySubscription(uuid) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.put(`recurly/subscription/cancel/${uuid}`, {
      endpoint: config.API_URL,
    });
  };
}

export function reactivateRecurlySubscription(uuid) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.put(`recurly/subscription/reactivate/${uuid}`, {
      endpoint: config.API_URL,
    });
  };
}

export function reactivateExpiredRecurlySubscription(uuid) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.post(`recurly/subscription/reactivate/expired/${uuid}`, {
      endpoint: config.API_URL,
    });
  };
}

export function redeemToRecurlySubscription(couponCode, accountCode, subUUID) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.post(`recurly/redeem/${couponCode}/${accountCode}/${subUUID}`, {
      endpoint: config.API_URL,
    });
  };
}

export function createRecurlyAccount(data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.post(`recurly/newaccount/noauth`, {
      endpoint: API_URL,
      data,
    });
  };
}

export function addNewSubscriberExistingUserToTenant(subscriberKey) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.post(`addnewsubscriberexistingusertotenant/${subscriberKey}`, {
      endpoint: API_URL,
    });
  };
}

export function createNewRecurlyBilling(data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.post(`recurly/newbilling/noauth`, {
      endpoint: API_URL,
      data,
    });
  };
}

export function createRecurlySubscription(data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.post(`recurly/newsubscription/noauth`, {
      endpoint: API_URL,
      data,
    });
  };
}

export function callGetMasterRecommendedActions() {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`recommendedactions/99`, {
        endpoint: config.API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_MASTER_RECOMMENDED_ACTIONS,
          data,
        });
      });
  };
}

export function getSubscriberByKey(key) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.get(`getsubscriberbykey/${key}`, {
      endpoint: config.API_URL,
    });
  };
}

export function getActiveTenantDph(tenantId) {
  return (dispatch, getState, api) => {
    // clearing state before every api execution
    dispatch({
      type: types.START_DPH,
    });

    const { config } = getState();

    return api
      .get(`tenants/${tenantId}/active-dph`, {
        endpoint: config.API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_DPH,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.ERROR_DPH,
        });
      });
  };
}

export function getNotificationFreqeuncies() {
  return (dispatch, getState, api) => {
    const { config } = getState();

    return api
      .get(`notification-frequencies`, {
        endpoint: config.API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_NOTIFICATION_FREQUENCIES,
          data,
        });
      });
  };
}

export function updateTenantDph(data, tenantId, type) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .put(`tenants/${tenantId}/manage-tenant-dph?dph_type=${type}`, {
        endpoint: config.API_URL,
        data,
      })
      .then((data) => {
        dispatch(getActiveTenantDph(tenantId));
      })
      .catch((error) => {
        alert("Reqeust Failed. Please enter valid data");
        console.log(error);
      });
  };
}
