import { resolve } from "path";
import ReactGA from "react-ga";
import { reset } from "redux-form";
import * as types from "./types";

export function callGetTransformerVersions(transBaseId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`transformer/versions/${transBaseId}`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        data.sort((a, b) => {
          if (a.Version < b.Version) return 1;
          if (a.Version > b.Version) return -1;
          return 0;
        });
        dispatch({
          type: types.SET_TRANSFORMER_VERSIONS,
          data,
        });
        return data[0];
      });
  };
}

export function clearTransformerVersions() {
  return (dispatch, getState, api) => {
    // to resolve https://app.breeze.pm/projects/168247/cards/4781174
    // otherwise editor will load old content initially
    dispatch({
      type: types.CLEAR_TRANSFORMER_VERSIONS,
    });
  };
}

export function callGetTransformersEmails(transId) {
  let emails = [];
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;

    dispatch({
      type: types.SET_TRANSFORMER_EMAIL,
      emails: [],
      loading: true,
    });

    return api
      .get(`transformers/${transId}/dpt-emails`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        data.map((item, index) => {
          emails = [...emails, item.email];
        });
        dispatch({
          type: types.SET_TRANSFORMER_EMAIL,
          emails,
          loading: false,
        });
      });
  };
}

export function addNotificationEmail(email) {
  return (dispatch, getState, api) => {
    dispatch({
      type: types.ADD_NOTIFICATION_EMAIL,
      email,
    });
  };
}

export function deleteNotificationEmail(id) {
  return (dispatch, getState, api) => {
    dispatch({
      type: types.DELETE_NOTIFICATION_EMAIL,
      id,
    });
  };
}

export function callPostEventContext(data, transBaseId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.post(`transformers/${transBaseId}/eventdata`, {
      endpoint: TRANS_API_URL,
      data,
    });
  };
}

export function callGetTransformerContext(transBaseId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`transformers/${transBaseId}/eventdata`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_TRANSFORMER_CONTEXT,
          data,
        });
      });
  };
}

export function setTransformerContext(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_TRANSFORMER_CONTEXT,
      data,
    });
  };
}

export function callGetTransformerLogsTotalCount(tenantId, appId, transId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/count/executions/${transId}`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_TRANSFORMER_LOGS_COUNT,
          data,
        });
        return data;
      });
  };
}

export function callGetTransformerLogs(
  tenantId,
  transBaseId,
  transName,
  limit,
  offset,
  state,
  duration,
  startTime,
  endTime
) {
  return (dispatch, getState, api) => {
    if (!offset) {
      offset = 0;
    }
    const { config } = getState();
    const { TRANS_API_URL } = config;
    if ((state && state != "") || (duration && duration != "")) {
      if (startTime && startTime != "" && endTime && endTime != "") {
        let uri = `${tenantId}/logs/transformers/${transBaseId}?duration=${duration}&state=${state}&endTime=${endTime}&startTime=${startTime}`;
        const encoded = encodeURI(uri);
        return api
          .get(encoded, {
            endpoint: TRANS_API_URL,
          })
          .then((data) => {
            dispatch({
              type: types.SET_TRANSFORMER_LOGS,
              data,
            });
            return data;
          });
      } else {
        return api
          .get(
            `${tenantId}/logs/transformers/${transBaseId}?state=${state}&duration=${duration}`,
            {
              endpoint: TRANS_API_URL,
            }
          )
          .then((data) => {
            data.map((d) => {
              d["baseId"] = transBaseId;
              d["Name"] = transName;
            });
            dispatch({
              type: types.SET_TRANSFORMER_LOGS,
              data,
            });
            return data;
          });
      }
    }

    return api
      .get(
        `${tenantId}/logs/transformers/${transBaseId}?limit=${limit.toString()}&offset=${offset}`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then((data) => {
        data.map((d) => {
          d["baseId"] = transBaseId;
          d["Name"] = transName;
        });
        dispatch({
          type: types.SET_TRANSFORMER_LOGS,
          data,
        });
        return data;
      });
  };
}

/*export function callGetTrigger(transBaseId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.get(`gettrigger/${transBaseId}`, {
      endpoint: TRANS_API_URL,
    })
    .then((data) => {
      dispatch({
        type: types.SET_TRIGGER,
        data,
      });
      return data
    });
  };
}*/

export function callGetAllTriggers(transBaseId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`gettriggers/${transBaseId}`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_ALL_TRIGGERS,
          data,
        });
        return data;
      });
  };
}

export function callClearTrigger(transBaseId, envId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`environments/${envId}/cleartrigger/${transBaseId}`, {
        endpoint: TRANS_API_URL,
      })
      .then(() => {
        return dispatch(callGetAllTriggers(transBaseId));
      });
  };
}

export function setShowSaved(bool) {
  return {
    type: types.SET_TRANSFORMER_SHOWSAVED,
    bool,
  };
}

export function setDeployStatus(data, transformerbaseId) {
  const transId = parseInt(transformerbaseId, 10);
  return (dispatch, getState) => {
    const { transformer } = getState();
    const isDeploying = { ...transformer.isDeploying };

    if (data) {
      isDeploying[transId] = data;
    } else {
      delete isDeploying[transId];
    }

    dispatch({
      type: types.SET_TRANSFORMER_DEPLOY_STATUS,
      data: isDeploying,
    });
  };
}

export function setOngoingLogsByBaseId(data, add) {
  return (dispatch, getState) => {
    const { transformer } = getState();
    const ongoingLogsByBaseId = [...transformer.ongoingLogsByBaseId];

    if (add) {
      //if adding
      if (!ongoingLogsByBaseId.find((f) => f.baseId === data.baseId)) {
        ongoingLogsByBaseId.push(data);
      }
      //if updating
      else {
        const objIndex = ongoingLogsByBaseId.findIndex(
          (f) => f.baseId === data.baseId
        );
        ongoingLogsByBaseId.splice(objIndex, 1);
        ongoingLogsByBaseId.push(data);
      }
    } else {
      if (!ongoingLogsByBaseId.find((f) => f.baseId === data.baseId)) {
        const objIndex = ongoingLogsByBaseId.findIndex(
          (f) => f.baseId === data.baseId
        );
        ongoingLogsByBaseId.splice(objIndex, 1);
      }
    }

    dispatch({
      type: types.SET_ONGOING_LOGS_BY_BASEID,
      data: ongoingLogsByBaseId,
    });
  };
}

export function setOngoingLogCountByBaseId(data, add) {
  return (dispatch, getState) => {
    const { transformer } = getState();
    const ongoingLogCountByBaseId = [...transformer.ongoingLogCountByBaseId];

    if (add) {
      //if adding
      if (!ongoingLogCountByBaseId.find((f) => f.baseId === data.baseId)) {
        ongoingLogCountByBaseId.push(data);
      }
      //if updating
      else {
        const objIndex = ongoingLogCountByBaseId.findIndex(
          (f) => f.baseId === data.baseId
        );
        ongoingLogCountByBaseId.splice(objIndex, 1);
        ongoingLogCountByBaseId.push(data);
      }
    } else {
      if (!ongoingLogCountByBaseId.find((f) => f.baseId === data.baseId)) {
        const objIndex = ongoingLogCountByBaseId.findIndex(
          (f) => f.baseId === data.baseId
        );
        ongoingLogCountByBaseId.splice(objIndex, 1);
      }
    }

    dispatch({
      type: types.SET_ONGOING_LOGCOUNT_BY_BASEID,
      data: ongoingLogCountByBaseId,
    });
  };
}

export function EnableTerminateJob(startedAt, transId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`trans/executions/${transId}`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        if (data) {
          var g1 = new Date(data.startTime);
          var g2 = new Date(startedAt);
          if (g1.getTime() < g2.getTime()) {
            dispatch(EnableTerminateJob(startedAt, transId));
          } else {
            dispatch({
              type: types.SET_ENABLE_TERMINATE_JOB,
              data: false,
            });
          }
        } else {
          dispatch(EnableTerminateJob(startedAt, transId));
        }
      });
  };
}
// export function setExexutionTransformerSpecificVersion(transformerbaseId, executionId, starting) {
//   const baseId = parseInt(transformerbaseId,10)

//   return (dispatch, getState) => {
//     const { transformer } = getState()
//     const executingTransformer = [...transformer.executingTransformers]

//     if (starting){
//       if (!executingTransformers.includes(baseId)){
//         executingTransformers.push(baseId)

//       }
//     }
//     else {
//       if (executingTransformers.includes(baseId)){
//         const transIndex = executingTransformers.findIndex(f=>f===baseId)
//         executingTransformers.splice(transIndex, 1)

//       }
//     }

//     dispatch({
//       type:types.SET_EXECUTING_TRANSFORMERS,
//       data:executingTransformers
//     })
//   }
// }
//bool 'starting' decides whether to add or remove the baseId
export function setExecutingTransformer(transformerbaseId, starting) {
  const baseId = parseInt(transformerbaseId, 10);

  return (dispatch, getState) => {
    const { transformer } = getState();
    const executingTransformers = [...transformer.executingTransformers];

    if (starting) {
      if (!executingTransformers.includes(baseId)) {
        executingTransformers.push(baseId);
      }
    } else {
      if (executingTransformers.includes(baseId)) {
        const transIndex = executingTransformers.findIndex((f) => f === baseId);
        executingTransformers.splice(transIndex, 1);
      }
    }

    dispatch({
      type: types.SET_EXECUTING_TRANSFORMERS,
      data: executingTransformers,
    });
  };
}

export function setConsoleOpenStatus(bool) {
  return {
    type: types.SET_CONSOLE_OPEN_STATUS,
    bool,
  };
}

export function callUpdateTransformer(data, triggerData, appId, transBaseId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .post(`transformers/create/${appId}`, {
        endpoint: TRANS_API_URL,
        data,
      })
      .then(() => {
        return dispatch(callGetTransformerVersions(transBaseId));
      });
  };
}

export function callCreateTransformer(data, appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .post(`transformers/create/${appId}`, {
        endpoint: TRANS_API_URL,
        data,
      })
      .then((d) => {
        dispatch(callGetTransformers(appId));
        return d;
      });
  };
}

export function callDeleteTransformer(appId, transId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .delete(`transformer/delete/${transId}`, {
        endpoint: TRANS_API_URL,
      })
      .then(() => {
        dispatch(callGetTransformers(appId));
      });
  };
}

export function callUpdateTransformerInfo(data, appId, transBaseId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .post(`transformers/create/${appId}`, {
        endpoint: TRANS_API_URL,
        data,
      })
      .then(() => {
        dispatch(callGetTransformers(appId));
        dispatch(setShowSaved(true));
        dispatch(callGetTransformerVersions(transBaseId));
        setTimeout(() => {
          dispatch(setShowSaved(false));
        }, 3000);
      });
  };
}

export function callUpdateTransformerDPT(data, appId, transBaseId, vId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
        .put(`transformers/${vId}/max-data-proc-time?maxDataProcTime=${data.thresholdHours}:${data.thresholdMinutes}:00`, {
          endpoint: TRANS_API_URL,
          data,
        })
        .then((response) => {
          dispatch(setShowSaved(true));
          dispatch(callGetTransformers(appId));
          dispatch(callGetTransformerVersions(transBaseId));
          setTimeout(() => {
            dispatch(setShowSaved(false));
          }, 3000);
        });
  };
}

export function callUpdateTransformerEmailList(data, transBaseId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .put(`transformers/${transBaseId}/manage-dpt-emails`, {
        endpoint: TRANS_API_URL,
        data,
      })
      .then((reposnse) => {
        dispatch(callGetTransformersEmails(transBaseId));
      });
  };
}

export function callSaveTrigger(tenantId, triggerData, transBaseId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .post(`${tenantId}/createtrigger`, {
        endpoint: TRANS_API_URL,
        data: triggerData,
      })
      .then(() => {
        return dispatch(callGetAllTriggers(transBaseId));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function callDeployTrigger(data, transBaseId, transId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .put(`transformer/deploy/${transId}`, {
        endpoint: TRANS_API_URL,
        data,
      })
      .then(() => {
        dispatch(callGetTransformerVersions(transBaseId));
        dispatch(setShowSaved(true));
        setTimeout(() => {
          dispatch(setShowSaved(false));
        }, 3000);
      });
  };
}

export function deployTransformerToEnvironment(
  tenantId,
  appId,
  baseId,
  transId,
  envId,
  templateName
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .put(
        `${appId}/transformer/${baseId}/environments/${envId}/deploy/${transId}`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then(() => {
        return dispatch(callGetDeploymentsForTransformer(appId, baseId));
      });
  };
}

export function callGetDeploymentsForTransformer(appId, baseId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${appId}/transformer/${baseId}/deployments`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_TRANSFORMER_DEPLOYMENTS,
          data,
        });
        return data;
      });
  };
}

export function callGetEventObjectTypes(tenantKey, projectKey) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { DATA_API_URL } = config;
    return api
      .get(`${tenantKey}/${projectKey}/objecttypes/all`, {
        endpoint: DATA_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_TRIGGER_WEBHOOKS,
          data: data.data,
        });
      });
  };
}

export function clearTransformer() {
  return (dispatch, getState) => {
    dispatch(setFavicon({ icon: false, alertCount: null }));
    const { transformer } = getState();
    dispatch({
      type: types.CLEAR_TRANSFORMER,
      data: [],
    });
  };
}

export function callGetTransformers(appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`transformers/${appId}`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_TRANSFORMERS, // a project type
          data,
        });
        return data;
      });
  };
}

/* TRANSFORMER EXECUTION */

export function clearOngoingExecutionData(baseId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(setOngoingExecutionLogs([]));
      dispatch(setOngoingExecutionLogCount(null));
      dispatch(setDeployStatus(true, baseId));
      dispatch(setExecutionLogs([]));
      dispatch(setExecutionCompleted(false));
      let bool = false;
      dispatch({
        type: types.SET_EXECUTION_ERROR,
        bool,
      });
      let msg = "";
      dispatch({
        type: types.SET_EXECUTION_ERROR_MESSAGE,
        msg,
      });
      resolve();
    });
  };
}
export function getSummaryofJob(tenantId, appId, envId, connectionKey, jobId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { SF_AGENT_API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/${envId}/${connectionKey}/job/${jobId}/info`, {
        endpoint: SF_AGENT_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_EXTENDED_PROPERTIES,
          data,
        });
        return data;
      })
      .catch((error) => {
        return;
      });
  };
}
export function getFailedResultsofJob(
  tenantId,
  appId,
  envId,
  connectionKey,
  jobId
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { SF_AGENT_API_URL } = config;
    return api
      .get(
        `${tenantId}/${appId}/${envId}/${connectionKey}/job/${jobId}/failedResults`,
        {
          endpoint: SF_AGENT_API_URL,
        }
      )
      .then((data) => {
        return data;
      });
  };
}
export function getSuccessResultsofJob(
  tenantId,
  appId,
  envId,
  connectionKey,
  jobId
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { SF_AGENT_API_URL } = config;
    return api
      .get(
        `${tenantId}/${appId}/${envId}/${connectionKey}/job/${jobId}/successfulResults`,
        {
          endpoint: SF_AGENT_API_URL,
        }
      )
      .then((data) => {
        return data;
      });
  };
}

export function callExecuteTransformer(
  tenantId,
  transId,
  query,
  externalExecutionId,
  startedAt,
  baseId,
  fromWizard
) {
  return (dispatch, getState, api) => {
    dispatch({
      type: types.SET_ENABLE_TERMINATE_JOB,
      data: true,
    });
    dispatch(
      setFavicon({
        icon: fromWizard ? "wizardRunOnce" : "recipe",
        alertCount: null,
      })
    ); //set running icon
    const { config, transformer } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`execute/${transId}?${query}`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        if (data == true) {
          dispatch(setExecutingTransformer(transformer, true));
          dispatch(
            callLookupLogData(
              tenantId,
              externalExecutionId,
              startedAt,
              baseId,
              0,
              transformer,
              fromWizard
            )
          );
        } else {
          dispatch(setExecutionError(true, data.toString(), baseId));
        }
      });
  };
}

export function callStopExecuteTransformer(transId, envId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`stopexecute/${envId}/${transId}`, {
        endpoint: TRANS_API_URL,
      })
      .then(() => {
        setOngoingExecutionLogs([]);
        dispatch({
          type: types.SET_ENABLE_TERMINATE_JOB,
          data: true,
        });
      });
  };
}

export function fetchExecutionDatabyGuid(tenantId, executionGUID) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(
        `${tenantId}/logs/transformers/externalExecutionId/${executionGUID}`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then((data) => {
        return data;
      });
  };
}

export function callLookupLogData(
  tenantId,
  externalExecutionId,
  startedAt,
  baseId,
  index,
  transformer,
  fromWizard
) {
  return (dispatch, getState, api) => {
    console.log("getting execution logs");
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(
        `${tenantId}/logs/transformers/externalExecutionId/${externalExecutionId}`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then((data) => {
        if (data[0]) {
          console.log("Error in executions");
          if (data[0].errorMessage) {
            dispatch(setExecutionError(true, data[0].errorMessage, baseId));
            dispatch(setFavicon({ icon: false, alertCount: null }));
            // dispatch(setOngoingLogsByBaseId({baseId:transformer.versions[0].BaseId}, false));
            dispatch(setExecutingTransformer(baseId, false));
          } else {
            dispatch({
              type: types.SET_ENABLE_TERMINATE_JOB,
              data: false,
            });
            //dispatch(setOngoingLogCountByBaseId({baseId:transformer.versions[0].BaseId, stepCount: data[0].stepCount}, true))
            dispatch(setOngoingExecutionLogCount(data[0].stepCount));
            dispatch(
              setFavicon({
                icon: fromWizard ? "wizardRunOnce" : "recipe",
                alertCount: data[0].stepCount + 1,
              })
            );
            dispatch(
              callGetLogSteps(
                tenantId,
                externalExecutionId,
                startedAt,
                baseId,
                index,
                transformer
              )
            );
          }
        } else {
          if (index < 3600) {
            // go again
            setTimeout(() => {
              dispatch(
                callLookupLogData(
                  tenantId,
                  externalExecutionId,
                  startedAt,
                  baseId,
                  index + 1,
                  transformer,
                  fromWizard
                )
              );
            }, 1000);
          } else {
            dispatch(
              setExecutionError(
                true,
                "Request timed out, please try again.",
                baseId
              )
            );
            dispatch(setFavicon({ icon: false, alertCount: null }));
            // dispatch(setOngoingLogCountByBaseId({baseId:transformer.versions[0].BaseId}, false))
            // dispatch(setOngoingLogsByBaseId({baseId:transformer.versions[0].BaseId}, false))
            dispatch(setExecutingTransformer(baseId, false));
          }
        }
      });
  };
}

export function setFavicon(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_FAVICON,
      data,
    });
  };
}

export function callGetLogSteps(
  tenantId,
  externalExecutionId,
  startedAt,
  baseId,
  index,
  transformer
) {
  return (dispatch, getState, api) => {
    console.log("getting steps logs");
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(
        `${tenantId}/logs/steps/externalExecutionId/${externalExecutionId}`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then((data) => {
        dispatch(setOngoingExecutionLogs(data));
        // dispatch(setOngoingLogsByBaseId({baseId:transformer.versions[0].BaseId,executionLogs:data},true))
        const isFailure = data.find((l) => l.completed && !l.succeeded);
        const lastCompleted =
          data[data.length - 1] && data[data.length - 1].completed;
        const stepsToContinue = data.filter((l) => l.onError == "continue");
        if (isFailure && stepsToContinue) {
          let shouldContinue = stepsToContinue.find(
            (s) => s.stepGuid == isFailure.stepGuid
          );
          if (shouldContinue && shouldContinue.stepGuid != "") {
            isFailure = null;
          }
        }
        if (isFailure || lastCompleted) {
          dispatch(
            callGetLogStepsLast(
              tenantId,
              externalExecutionId,
              startedAt,
              baseId,
              transformer
            )
          );
        } else {
          setTimeout(() => {
            dispatch(
              callGetLogSteps(
                tenantId,
                externalExecutionId,
                startedAt,
                baseId,
                index + 1,
                transformer
              )
            );
          }, 1000);
        }
      });
  };
}

/*
 * dimension1 <== userID
 * dimension2 <== tenantKey
 * dimension3 <== projectKey
 */
export function callGetLogStepsLast(
  tenantId,
  externalExecutionId,
  startedAt,
  baseId,
  transformer
) {
  return (dispatch, getState, api) => {
    const { config, project } = getState();
    const tenantKey = project.tenantInfo.Key;
    const appKey = project.appData.uniquekey;
    let userId = null;
    const profile = JSON.parse(window.localStorage.getItem("profile"));
    if (profile) {
      userId = profile.user_id;
    }
    const name = transformer.versions[0].Name;
    const id = transformer.versions[0].Id;
    const { TRANS_API_URL } = config;
    return api
      .get(
        `${tenantId}/logs/steps/externalExecutionId/${externalExecutionId}`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then((data) => {
        dispatch(setExecutionLogs(data));
        dispatch(setExecutionCompleted(true));
        dispatch(setDeployStatus(false, baseId));
        const elapsed = ((new Date() - startedAt) / 1000).toFixed(2); // how long it took to run the recipe in seconds.
        if (transformer.executionError) {
          ReactGA.ga("send", "event", {
            eventCategory: "Recipes",
            eventAction: "Execution Error",
            eventLabel: `Recipe ${id} (${name}): ${transformer.executionErrorMessage}, operation took: ${elapsed} seconds.`,
            eventValue: parseInt(elapsed, 0),
            dimension1: userId,
            dimension2: tenantKey,
            dimension3: appKey,
          });
        } else {
          ReactGA.ga("send", "event", {
            eventCategory: "Recipes",
            eventAction: "Execution Succeeded",
            eventLabel: `Recipe ${id} (${name}) was successfully executed, operation took: ${elapsed} seconds.`,
            eventValue: parseInt(elapsed, 0),
            dimension1: userId,
            dimension2: tenantKey,
            dimension3: appKey,
          });
        }
      })
      .then(() => {
        dispatch(setFavicon({ icon: false, alertCount: null }));
        //dispatch(setOngoingLogsByBaseId({baseId:transformer.versions[0].BaseId}, false))
      })
      .then(() => {
        dispatch(setExecutingTransformer(baseId, false));
        dispatch(setOngoingExecutionLogs([]));
      }); // set default icon})
  };
}

export function callGetSelectedTransformerLogs(
  tenantId,
  externalExecutionId,
  errMessage,
  errGuid
) {
  return (dispatch, getState, api) => {
    const { config, transformer } = getState();
    if (errMessage && errMessage.length > 255) {
      if (
        transformer.selectedTransformerLogsError &&
        transformer.selectedTransformerLogsError.guid === errGuid
      ) {
        // delete if already
        dispatch({
          type: types.SET_SELECTED_TRANSFORMER_LOGS_ERROR,
          data: null,
        });
      } else {
        dispatch({
          type: types.SET_SELECTED_TRANSFORMER_LOGS_ERROR,
          data: { err: errMessage, guid: errGuid },
        });
      }
    } else {
      dispatch({
        type: types.SET_SELECTED_TRANSFORMER_LOGS_ERROR,
        data: null,
      });
    }
    // if (
    //   transformer.selectedTransformerLogs &&
    //   transformer.selectedTransformerLogs.length > 0 &&
    //   transformer.selectedTransformerLogs[0].externalExecutionId ===
    //     externalExecutionId
    // ) {
    //   dispatch({
    //     type: types.SET_SELECTED_TRANSFORMER_LOGS,
    //     data: null,
    //   });
    //   return;
    // }
    dispatch({
      type: types.SET_SELECTED_TRANSFORMER_LOGS,
      data: null,
    });
    const { TRANS_API_URL } = config;
    return api
      .get(
        `${tenantId}/logs/steps/externalExecutionId/${externalExecutionId}`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then((data) => {
        let logs = [];
        if (data && data.length) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].completed) {
              logs.push(data[i]);
              if (!data[i].succeeded && data[i].onError != "continue") {
                break; // stop on a failed statement
              }
            }
          }
        }
        dispatch({
          type: types.SET_SELECTED_TRANSFORMER_LOGS,
          data: logs,
        });
      });
  };
}

export function callGetSelectedStepLogs(tenantId, stepId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/logs/step/${stepId}`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        return data;
      });
  };
}

export function callGetSelectedTransformerLogsDEV(
  tenantId,
  externalExecutionId,
  errMessage,
  errGuid
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(
        `${tenantId}/logs/steps/externalExecutionId/${externalExecutionId}`,
        {
          endpoint: TRANS_API_URL,
        }
      )
      .then((data) => {
        let logs = [];
        if (data && data.length) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].completed) {
              logs.push(data[i]);
              if (!data[i].succeeded && data[i].onError != "continue") {
                break; // stop on a failed statement
              }
            }
          }
        }
        return logs;
      });
  };
}

export function setExecutionError(bool, errorMessage, baseId) {
  return (dispatch) => {
    dispatch(setDeployStatus(false, baseId));
    dispatch({
      type: types.SET_EXECUTION_ERROR,
      bool,
    });
    dispatch({
      type: types.SET_EXECUTION_ERROR_MESSAGE,
      errorMessage,
    });
    dispatch({
      type: types.SET_EXECUTION_COMPLETED,
      bool: true,
    });
  };
}

export function setOngoingExecutionLogs(data) {
  return {
    type: types.SET_ONGOING_EXECUTION_LOGS,
    data,
  };
}

export function setOngoingExecutionLogCount(data) {
  return {
    type: types.SET_ONGOING_EXECUTION_LOG_COUNT,
    data,
  };
}

export function setExecutionLogs(data) {
  let logs = [];
  if (
    data &&
    data.length &&
    data[data.length - 1].stepAction == "forcefully stopped"
  ) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].completed) {
        logs.push(data[i]);
        if (!data[i].succeeded) {
          continue; // continue on a failed statement to get last statement i.e.Job Killed
        }
      }
    }
  } else if (data && data.length) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].completed) {
        logs.push(data[i]);
        if (!data[i].succeeded) {
          break; // stop on a failed statement
        }
      }
    }
  }
  return {
    type: types.SET_EXECUTION_LOGS,
    data: logs,
  };
}

export function setExecutionCompleted(bool) {
  return {
    type: types.SET_EXECUTION_COMPLETED,
    bool,
  };
}

export function clearExecutionPlan(baseId) {
  return (dispatch) => {
    dispatch(setExecutionLogs([]));
    dispatch(setExecutionError(false, "", baseId));
    dispatch(setExecutionCompleted(false));
  };
}
//
export function clearWizard() {
  return (dispatch) => {
    dispatch(setFavicon({ icon: false, alertCount: null }));
    dispatch({
      type: types.CLEAR_WIZARD,
    });
    dispatch(reset("wizard"));
  };
}

export function postAuthCode(authCode, authCodeRequestGuid, environmentId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { SF_AGENT_API_URL } = config;
    return api.post("authCode", {
      endpoint: SF_AGENT_API_URL,
      data: {
        authCode,
        authCodeRequestGuid,
        ...(environmentId && { environmentId }),
      },
    });
  };
}
export function postBSAuthCode(authCode, authCodeRequestGuid, environmentId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.post("bsAuthCode", {
      endpoint: TRANS_API_URL,
      data: {
        authCode,
        authCodeRequestGuid,
        ...(environmentId && { environmentId }),
      },
    });
  };
}

export function postZoomAuthCode(authCode, authCodeRequestGuid, environmentId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.post("zoomAuthCode", {
      endpoint: TRANS_API_URL,
       data: {
        authCode,
        authCodeRequestGuid,
        environmentId,
      },
    });
  };
}

export function postCanvasOAuth2AuthCode(authCode, authCodeRequestGuid, environmentId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.post("canvasAuthCode", {
      endpoint: TRANS_API_URL,
      data: {
        authCode,
        authCodeRequestGuid,
        environmentId,
      },
    });
  };
}


export function postDSAuthCode(
  authCode,
  authCodeRequestGuid,
  environmentId,
  selectedAccount
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.post("authCode", {
      endpoint: TRANS_API_URL,
      data: {
        authCode,
        authCodeRequestGuid,
        selectedAccount,
        ...(environmentId && { environmentId }),
      },
    });
  };
}

export function createAuthAppNGetCode(
  tenantId,
  appId,
  envId,
  originalKey,
  oauthType,
  data
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.post(
      `${tenantId}/${appId}/env/${envId}/${originalKey}/getAppAuthCode/${oauthType}`,
      {
        endpoint: TRANS_API_URL,
        data,
      }
    );
  };
}
