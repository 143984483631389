import React from "react";
import { If, Then, Else } from "react-if";
import { MINUTES } from "../data/constants";
import { DropdownButton, MenuItem, Button, FormControl } from "react-bootstrap";
import TimePicker from "rc-time-picker";
import Select from "./components/Select";

import {
  isMultiple,
  toggleDateType,
  toOptions,
  rangeHoursToSingle,
} from "../utils";
import range from "lodash/range";
import MultipleSwitcher from "./MultipleSwitcher";
import TimeInput from "./components/TimeInput";
import DateComponent, {
  DayOfWeek,
  DayOfMonth,
  Month,
} from "./components/DateComponent";
import PresetTab from "./PresetTab";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const toHourOptions = (_values) => {
  var values1 = [];
  for (var prop in _values) {
    if (_values[prop] > 11) {
      if (_values[prop] == 12) {
        values1.push({
          value: _values[prop].toString(),
          label: _values[prop] + "PM",
        });
      } else {
        values1.push({
          value: _values[prop].toString(),
          label: _values[prop] - 12 + "PM",
        });
      }
    } else {
      if (_values[prop] == 0) {
        continue
      } else {
        values1.push({
          value: _values[prop].toString(),
          label: _values[prop] + "AM",
        });
      }
    }
  }
  return values1;
};

const toRecurringMinutes = (_values) => {
  var values1 = [];
  for (var prop in _values) {
    if (_values[prop] % 5 == 0) {
      values1.push({ value: "*/" + _values[prop], label: _values[prop] });
    }
  }
  return values1;
};

const tofixedMinutes = (_values) => {
  var values1 = [];
  for (var prop in _values) {
    if (_values[prop] % 5 == 0) {
      if (_values[prop] < 10) {
        values1.push({ value: _values[prop], label: "0" + _values[prop] });
      } else {
        values1.push({ value: _values[prop], label: _values[prop] });
      }
    }
  }
  return values1;
};

const toRecurringHours = (_values) => {
  return _values.map(String).map((value) => ({
    value: "*/" + value,
    label: value,
  }));
};

const hoursOptions = toHourOptions(range(0, 24));
const recurringHoursOptions = toRecurringHours(range(1, 24));
const recurringMinutesOptions = toRecurringMinutes(range(5, 60));
const fixedMinutesOptions = tofixedMinutes(range(0, 60));

const timeUnitOptions = ["Minutes", "Hours"];

const timeInputProps = { style: { minWidth: 75 } };

export default class PeriodicallyTab extends PresetTab {
  constructor(props, ctx) {
    super(props, ctx);
    this.state.recurringDayOfMonth =
      this.state.year == "*" ? this.state.dayOfMonth : "*";
    this.state.recurringDayOfWeek =
      this.state.year == "*" ? this.state.dayOfWeek : "1-5";
    this.state.recurringMonth = this.state.year == "*" ? this.state.month : "*";
    this.state.fixedDayOfWeek = "*";
    if (
      this.state.dayOfMonth.includes("*") ||
      this.state.month.includes("*") ||
      this.state.year.includes("*")
    ) {
      this.state.fixedDayOfMonth = moment().format("DD");
      this.state.fixedMonth = moment().format("MM");
      this.state.fixedYear = moment().format("YYYY");
    } else {
      this.state.fixedDayOfMonth = this.state.dayOfMonth;
      this.state.fixedMonth = this.state.month;
      this.state.fixedYear = this.state.year;
    }

    this.generateExpression = this.generateExpression.bind(this);
  }

  generateExpression() {
    this.props.generateExpression();
  }
  toggleActiveTime = () => {
    this.setState(({ activeTime }) => ({
      activeTime: toggleDateType(activeTime),
    }));
  };

  isMultiple = () => {
    const { activeTime, minutesMultiple } = this.state;
    if (activeTime === MINUTES) {
      if (!minutesMultiple) {
        this.setState({ runFrequency: true });
      } else if (minutesMultiple) {
        this.setState({ runFrequency: false });
      }
      return minutesMultiple;
    }
  };

  handleEndingAt(e) {
    this.setState({
      endAtCheck: e,
    });
    if (e) {
      if (this.state.hoursTo) {
        this.setState(
          {
            rangeHours: this.state.hoursFrom + "-" + this.state.hoursTo,
          },
          this.generateExpression
        );
      } else {
        this.setState(
          {
            rangeHours: this.state.hoursFrom + "-" + "17",
            hoursTo: "17",
          },
          this.generateExpression
        );
      }
    } else {
      this.setState(
        {
          rangeHours: this.state.hoursFrom,
        },
        this.generateExpression
      );
    }
  }
  handleSourceChange(element) {
    const { elementCheck } = this.state;
    this.setState({
      elementCheck: !elementCheck,
    });
    if (this.state.startingfixedMinutes > 0 && elementCheck == true) {
      this.setState(
        {
          rangeHours: "*",
        },
        this.generateExpression
      );
      return;
    }
    if (elementCheck == true) {
      this.setState(
        {
          rangeHours: "",
        },
        this.generateExpression
      );
    } else {
      this.setState(
        {
          rangeHours: this.state.hoursFrom + "-" + this.state.hoursTo,
        },
        this.generateExpression
      );
    }
  }

  onDatePick = (date, generate) => {
    let startDt = new Date(date).toISOString().slice(0, 10);
    var dateObj = new Date(date);
    this.setState(
      {
        startDate: startDt,
        selectedDate: new Date(date),
        fixedDayOfMonth: dateObj.getDate(),
        fixedMonth: dateObj.getMonth() + 1,
        fixedYear: dateObj.getFullYear(),
        fixedDayOfWeek: "*",
      },
      generate
    );
  };

  onHoursChange = (field, generate) => {
    return (value) => {
      const { hoursFrom, hoursTo } = this.state;
      if (field === "hoursFrom") {
        this.setState(
          {
            rangeHours: `${value}-${String(hoursTo)}`,
            hoursFrom: value,
          },
          generate
        );
      } else {
        this.setState(
          {
            rangeHours: `${String(hoursFrom)}-${value}`,
            hoursTo: value,
          },
          generate
        );
      }
    };
  };

  onTimePick = (v, generate) => {
    var dateObj = new Date(v);
    this.setState(
      {
        dailySchedule: v,
        fixedHours: dateObj.getHours(),
        fixedMinutes: dateObj.getMinutes(),
      },
      generate
    );
  };
  render() {
    const { styleNameFactory } = this.props;
    const {
      hoursFrom,
      hoursTo,
      recurringDayOfWeek,
      recurringDayOfMonth,
      recurringMonth,
      recurringHours,
      recurringMinutes,
      activeTab,
    } = this.state;
    let isMultiple = this.isMultiple();
    if (activeTab == "multiple") {
      if (this.state.frequencyUnit == "minutes") {
        this.setState({ recurringHours: "*" });
        if (typeof this.state.recurringMinutes === "string") {
          if (!this.state.recurringMinutes.includes("*/")) {
            this.setState({ recurringMinutes: "*/" + recurringMinutes });
          }
          if (
            this.state.recurringMinutes.slice(2) % 5 != 0 ||
            this.state.recurringMinutes.slice(2) < 5
          ) {
            this.setState({
              recurringMinutes: "*/5",
            });
          }
        }
      }
      if (this.state.frequencyUnit == "hours") {
        this.setState({ recurringMinutes: "0" });
        if (typeof this.state.recurringHours === "string") {
          if (!this.state.recurringHours.includes("*")) {
            this.setState({ recurringHours: "*/" + recurringHours });
          } else if (recurringHours == "*") {
            this.setState({ recurringHours: "*/1" });
          }
        }
      }
    }
    return (
      <div {...styleNameFactory("preset")}>
        <div>
          <MultipleSwitcher
            styleNameFactory={styleNameFactory}
            isMultiple={isMultiple}
            activeTab={this.state.activeTab}
            onChange={(e) => this.changeDateType(e, this.generateExpression)}
          />
          <div {...styleNameFactory("row", "items-end")}>
            {activeTab == "single" && (
              <div>
                <div {...styleNameFactory("label")}>Select Date:</div>
                <DatePicker
                  style={{fontSize: 14}}
                  value={this.state.startDate}
                  onChange={(date) =>
                    this.onDatePick(date, this.generateExpression)
                  }
                  minDate={new Date()}
                  selected={this.state.selectedDate}
                />
              </div>
            )}
            {(activeTab == "single" || activeTab == "singleAndMultiple") && (
              <div>
                <div {...styleNameFactory("label")}>Select Time:</div>
                <TimePicker
                  showSecond={false}
                  className="time-picker"
                  format={"h:mm a"}
                  use12Hours
                  value={this.state.dailySchedule}
                  onChange={(e) => {
                    this.onTimePick(e, this.generateExpression);
                  }}
                />
              </div>
            )}
            {activeTab == "multiple" && (
              <div>
                <div {...styleNameFactory("label")}>Every:</div>
                <If condition={this.state.frequencyUnit == "hours"}>
                  <Then>
                    <TimeInput
                      options={recurringHoursOptions}
                      onChange={(e) => {
                        this.changeFrequency(e, this.generateExpression);
                      }}
                      multi={false}
                      styleNameFactory={styleNameFactory}
                      value={recurringHours}
                    />
                  </Then>
                  <Else>
                    <TimeInput
                      options={recurringMinutesOptions}
                      onChange={(e) => {
                        this.changeFrequency(e, this.generateExpression);
                      }}
                      multi={false}
                      styleNameFactory={styleNameFactory}
                      value={recurringMinutes}
                    />
                  </Else>
                </If>
              </div>
            )}
            {activeTab == "multiple" && (
              <div>
                <div {...styleNameFactory("label")}>Time Unit:</div>
                <DropdownButton
                  value={this.state.frequencyUnit}
                  bsStyle="default"
                  id="frequency-unit"
                  title={this.state.frequencyUnit || "Time Unit"}
                >
                  {timeUnitOptions.map((unit, i) => (
                    <MenuItem
                      value={unit}
                      key={i}
                      onClick={(e) => {
                        this.onPickTimeUnit(e, unit, this.generateExpression);
                      }}
                      active={this.state.frequencyUnit === unit}
                    >
                      {unit}
                    </MenuItem>
                  ))}
                </DropdownButton>
              </div>
            )}
            {(activeTab == "multiple" || activeTab == "singleAndMultiple") && (
              <div
                style={
                  activeTab == "singleAndMultiple" ? { marginLeft: 10 } : {}
                }
              >
                <DateComponent styleNameFactory={styleNameFactory}>
                  <DayOfWeek
                    value={recurringDayOfWeek}
                    onChange={(e) => {
                      this.selectDayOfWeek(e, this.generateExpression);
                    }}
                  />
                  <DayOfMonth
                    value={recurringDayOfMonth}
                    onChange={(e) => {
                      this.selectDayOfMonth(e, this.generateExpression);
                    }}
                  />
                  <Month
                    value={recurringMonth}
                    onChange={(e) => {
                      this.selectMonth(e, this.generateExpression);
                    }}
                  />
                </DateComponent>
              </div>
            )}
            {activeTab == "multiple" && this.state.frequencyUnit == "hours" && (
              <div>
                <div {...styleNameFactory("label")}>Starting Minute:</div>
                <Select
                  options={fixedMinutesOptions}
                  onChange={(e) =>
                    this.changeFrequencyStartingMinute(
                      e.value,
                      this.generateExpression
                    )
                  }
                  defaultValue={this.state.startingfixedMinutes}
                  value={this.state.startingfixedMinutes}
                />
              </div>
            )}
          </div>
        </div>

        {activeTab == "multiple" && (
          <div>
            <div {...styleNameFactory("label2")}>
              <label>
                <input
                  type="checkbox"
                  checked={this.state.elementCheck}
                  onChange={() => this.handleSourceChange(this)}
                />
              </label>
              &nbsp;Within a time frame
            </div>
            {this.state.elementCheck && (
              <div {...styleNameFactory("row", "hours-range")}>
                <div>
                  <div {...styleNameFactory("label")}>Starting at: </div>

                  <TimeInput
                    styleNameFactory={styleNameFactory}
                    options={hoursOptions}
                    value={hoursFrom}
                    onChange={this.onHoursChange(
                      "hoursFrom",
                      this.generateExpression
                    )}
                    {...timeInputProps}
                  />
                </div>
                <div>
                  <div {...styleNameFactory("label")}>
                    {/* <input
                      type="checkbox"
                      checked={this.state.endAtCheck}
                      onChange={(e) => this.handleEndingAt(e.target.checked)}
                      style={{ height: 10, marginRight: -3 }}
                    />{" "} */}
                    Ending at:
                  </div>
                  <TimeInput
                    styleNameFactory={styleNameFactory}
                    options={hoursOptions}
                    value={hoursTo}
                    onChange={this.onHoursChange(
                      "hoursTo",
                      this.generateExpression
                    )}
                    {...timeInputProps}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
