import React, { Component } from "react";
import TimePicker from "rc-time-picker";
import images from "../images";
import {
  Button,
  FormGroup,
  ControlLabel,
  Checkbox,
  FormControl,
  DropdownButton,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import moment from "moment";
import range from "lodash/range";
import DataProfileFieldStyle from "../../styles/dataprofilefieldstyle";
import SettingsStyle from "../../styles/settingsstyle";
import CronBuilder from "../CronBuilder";
import Select from "../CronBuilder/components/components/Select";
import { If, Then, Else } from "react-if";

function getColorByBgColor(bgColor) {
  if (!bgColor) {
    return "";
  }
  return parseInt(bgColor.replace("#", ""), 16) > 0xffffff / 2
    ? "#000"
    : "#fff";
}

const urlregex =
  /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
const fileregex = /[-a-zA-Z0-9_.@()-]+\.[^.]+$/;

const toOptions = (_values) => {
  return _values.map(String).map((value) => ({
    value,
    label: value,
  }));
};

const recurringMinutes = (_values) => {
  var values1 = [];
  for (var prop in _values) {
    if (_values[prop] % 5 == 0) {
      values1.push({ value: _values[prop].toString(), label: _values[prop] });
    }
  }
  return values1;
};

var cronTimeZoneOptions = [];
cronTimeZoneOptions.push({
  value: "UTC",
  label: "(GMT+00:00) UTC-Coordinated Universal Time",
});
const recurringHoursOptions = toOptions(range(1, 24));
const recurringMinutesOptions = recurringMinutes(range(5, 60));

const timeZones = () => {
  let tzs = require("../recipe/timezones.json");
  return tzs;
};
export default class EditSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      targetType: "recipe",
      eventType: "webhook",
      sysSelected: false,
      webhookurl: "",
      selecteditem: [],
      modal: false,
      objectType: null,
      curVersion: null,
      selectedTab: 0,
      selectedDeployment: null,
      selectedRecipe: null,
      selectedEnvironment: null,
      subscriptionName: "",
      filepattern: "",
      abcMode: true,
      isCreating: false,
      selectedTemplate: "",

      selectedSource: "",
      dir: "",
      isfile: false,
      isrecurring: false,
      iswebhook: false,

      eventdate: "",
      type: "",
      action: "",
      filename: "",
      objectTypeChanged: false,
      frequency: "",
      frequencyUnit: "Hours",
      dailySchedule: moment().hour(6).minute(0),
      isSavingTrigger: false,

      //modal switches page 1 / 2
      modalSwitch: false,
      cronExpression: "0 9-17/1 * * 1-5 *",
      cronExplain: "",
      timeZone: moment.tz.guess(),
      startingAt: null,
    };

    this.handleToggleEventType = this.handleToggleEventType.bind(this);
    this.handleSubNameChange = this.handleSubNameChange.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.cancelCreation = this.cancelCreation.bind(this);
    this.changeListMode = this.changeListMode.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
  }

  componentWillMount() {
    const { selectedEvent } = this.props;
    if (selectedEvent) {
      this.setState({
        selectedTemplate: selectedEvent.templateName,
      });
    }

    if (selectedEvent && selectedEvent.repeatingSchedule) {
      this.setState({
        frequency: selectedEvent && selectedEvent.repeatingSchedule.frequency,
        frequencyUnit:
          selectedEvent && selectedEvent.repeatingSchedule.frequencyUnit + "s",
        selectedDeployment: selectedEvent,
        selectedSource: "isrepeating",
        startingAt: selectedEvent.repeatingSchedule.startingAt
          ? moment
              .utc(selectedEvent.repeatingSchedule.startingAt, "HH:mm")
              .tz(moment.tz.guess())
          : null,
      });
    } else if (selectedEvent && selectedEvent.dailySchedule) {
      this.setState({
        selectedSource: "isdaily",
        selectedDeployment: selectedEvent,
        timeZone: selectedEvent.dailySchedule.timeZone || "UTC",
        dailySchedule: selectedEvent.dailySchedule.timeZone
          ? moment
              .utc(selectedEvent.dailySchedule.timeUTC, "HH:mm")
              .tz(selectedEvent.dailySchedule.timeZone)
          : moment(selectedEvent.dailySchedule.timeUTC, "HH:mm"),
      });
    } else if (selectedEvent && selectedEvent.cronExpression) {
      this.setState({
        selectedSource: "iscron",
        selectedDeployment: selectedEvent,
        cronExplain: selectedEvent && selectedEvent.cronExplain,
        cronExpression: selectedEvent && selectedEvent.cronExpression,
      });
    } else {
      this.setState({
        selectedDeployment: selectedEvent,
      });
    }
  }

  handleTemplateChange(e) {
    this.setState({ selectedTemplate: e });
  }

  cancelCreation() {
    const { appId } = this.props.match.params;
    this.props.history.push(`/a/${appId}/events`);
  }

  changeListMode() {
    let { abcMode } = this.state;
    abcMode = !abcMode;
    this.setState({ abcMode });
  }

  handleSubNameChange(e) {
    this.setState({ subscriptionName: e });
  }

  selectTab(e) {
    this.setState({ selectedTab: e });
  }

  handleSourceChange(s) {
    if (s == "isdaily") {
      this.setState({ timeZone: moment.tz.guess() });
    }
    this.setState({
      iswebhook: s === "iswebhook",
      isfile: s === "isfile",
      isrecurring: s === "isdaily" || s === "isrepeating" || s === "iscron",
      selectedSource: s,
    });
  }

  onTimePick = (v) => {
    this.setState({ dailySchedule: v });
  };

  onPickStartinAt = (v) => {
    this.setState({ startingAt: v });
  };

  changeTimeZone = (e) => {
    this.setState({ timeZone: e.value });
  };

  onPickTimeUnit = (v) => {
    this.setState({ frequencyUnit: v });
  };

  changeFrequency = (e) => {
    this.setState({ frequency: e.value });
  };

  deleteUnunsedWebhookSubscriptionsForEnv(envId) {
    const { redux, actions } = this.props;
    const { transformer, project } = redux;
    transformer.webhookSubscriptions.forEach((subscription) => {
      const a = subscription.deliveryUrl.split("/");
      const subEnvId = a[a.length - 1];
      if (String(envId) === subEnvId || a.length < 9) {
        actions.callDeleteWebhookSubscription(
          project.tenantInfo.Key,
          project.appData.uniquekey,
          subscription.subscriptionGuid
        );
      }
    });
  }

  initSaveTrigger = async () => {
    let schedule = {};
    if (this.state.selectedSource === "isdaily") {
      let userSpecifiedTZ = moment(this.state.dailySchedule).tz(
        this.state.timeZone
      );
      let dailySdl = this.state.dailySchedule.toString();
      let timeUTC = userSpecifiedTZ.toString();
      var res = dailySdl.replace(
        dailySdl.slice(
          dailySdl.indexOf("GMT") + 3,
          dailySdl.indexOf("GMT") + 8
        ),
        timeUTC.slice(timeUTC.indexOf("GMT") + 3, timeUTC.indexOf("GMT") + 8)
      );
      schedule = {
        dailySchedule: {
          timeUTC: moment.utc(new Date(res)).format("HH:mm"),
          timeZone: this.state.timeZone,
        },
      };
    } else if (this.state.selectedSource === "isrepeating") {
      schedule = {
        repeatingSchedule: {
          frequency: parseInt(this.state.frequency, 10),
          frequencyUnit: this.state.frequencyUnit
            .toLowerCase()
            .substring(0, this.state.frequencyUnit.length - 1),
        },
      };
      const { startingAt } = this.state;
      if (startingAt) {
        schedule.repeatingSchedule.startingAt = moment
          .utc(new Date(startingAt))
          .format("HH:mm");
      }
    } else if (this.state.selectedSource === "iscron") {
      schedule = {
        cronexpression: this.state.cronExpression,
        cronexplain: this.state.cronExplain,
      };
    }

    this.saveTrigger(null, schedule);
  };

  clearTrigger = () => {
    const { actions, redux, match } = this.props;
    const { project } = redux && redux;
    const { params } = match;
    const { appId } = params;
    const { TenantId } = project && project.tenantInfo;

    const { selectedDeployment } = this.state;

    this.setState({ isSavingTrigger: true });
    this.props.actions
      .callClearTrigger(
        selectedDeployment.baseId,
        selectedDeployment.environmentId
      )
      .then(() => {
        actions.callGetTriggerSubscriptions(appId);
        actions.callGetTriggerSummary(appId, TenantId);
        actions.callGetTriggerDetails(appId, TenantId, null, 1, 10);

        this.props.toggleModal();
      });
  };

  getValidationState() {
    if (urlregex.test(this.state.webhookurl)) return "success";
    else return "error";
    return null;
  }

  getValidationStateFilename() {
    if (fileregex.test(this.state.filepattern)) return "success";
    else return "error";
    return null;
  }

  //used for file upload events

  saveTrigger(filepattern, schedule) {
    const { project, config } = this.props.redux;
    const { appId } = this.props.match.params;
    const { selectedDeployment, selectedSource, selectedTemplate } = this.state;
    const { actions } = this.props;
    const { TenantId } = project && project.tenantInfo;

    const isfile = selectedSource === "isfile";
    const isrecurring =
      selectedSource === "isdaily" ||
      selectedSource === "isrepeating" ||
      selectedSource === "iscron";
    const iswebhook = selectedSource === "iswebhook";

    const directory = this.state.dir
      ? this.state.dir
      : `/${config.FTP_DIR}${project.tenantInfo.Key}/`;
    const eventdate = this.state.eventdate
      ? this.state.eventdate
      : new Date().toISOString();
    const action = this.state.action ? this.state.action : "create";
    const filename = this.state.filename ? this.state.filename : "sample.zip";
    const type = this.state.type ? this.state.type : "file";
    this.setState({ isSavingTrigger: true });
    this.props.actions
      .callSaveTrigger(
        project.tenantInfo.TenantId,
        {
          baseid: selectedDeployment.baseId,
          isfile,
          isrecurring,
          iswebhook,
          ...(filepattern && {
            directory,
            filepattern,
            action,
            filename,
            eventdate,
            type,
          }),
          ...schedule,
          environmentId: this.state.selectedDeployment.environmentId,
          templateName: selectedTemplate,
        },
        selectedDeployment.baseId
      )
      .then(() => {
        this.clearUIState();
        this.setState({ isSavingTrigger: false });
        this.props.toggleModal();
        actions.callGetTriggerSubscriptions(appId);
        actions.callGetTriggerSummary(appId, TenantId);
        actions.callGetTriggerDetails(appId, TenantId, null, 1, 10);
      })
      .catch((error) => {
        alert(error.response.data);
        this.setState({ isSavingTrigger: false });
      });
  }

  checkTrigger = () => {
    const data = this.findTriggerForEnvIdOrNot(
      this.state.selectedDeployment.environmentId
    );
    if (data) {
      if (data.isFile) {
        this.setState({
          isfile: true,
          dir: data.Directory,
          filepattern: data.FilePattern,
          eventdate: data.EventDate,
          action: data.Action,
          type: data.Type,
          filename: data.FileName,
          templateName: data.templateName,
        });
        this.handleSourceChange("isfile");
      } else if (data.IsWebhook) {
        this.handleSourceChange("iswebhook");
      } else {
        this.clearUIState();
      }
    } else {
      this.clearUIState();
    }
  };

  findTriggerForEnvIdOrNot = (envId) => {
    const { transformer } = this.props.redux;
    let data = null;
    if (envId) {
      data =
        transformer.allTriggers &&
        transformer.allTriggers.find((t) => {
          return t.EnvironmentId === envId;
        });
    } else {
      data =
        transformer.allTriggers &&
        transformer.allTriggers.find((t) => {
          return t.EnvironmentId === 0;
        });
    }
    return data;
  };

  clearUIState = () => {
    this.setState({
      dir: "",
      selectedSource: null,
      selectedDeployment: null,
      webhookurl: "",
      filepattern: "",
      objectType: null,
      objectTypeChanged: false,
      frequency: "",
      frequencyUnit: "",
      dailySchedule: moment().hour(0).minute(0),
      startingAt: null,
      selectedTemplate: "",
    });
  };

  handleToggleEventType(e) {
    this.setState({ eventType: e, objectType: null, filepattern: "" });
  }

  render() {
    const { project, config, transformer, events } = this.props.redux;
    const { availableTemplates } = this.props;
    const { selectedDeployment } = this.state;

    const timeUnitOptions = ["Minutes", "Hours"];

    var dailyTimeZoneOptions = timeZones();

    const styles = {
      icon: {
        height: 24,
        marginTop: -3,
        marginRight: 3,
        display: "inline-block",
      },
    };

    return (
      <div className="sub-modal-content">
        <div style={{ height: 20 }}>
          <div
            style={{
              position: "fixed",
              marginTop: -12,
              paddingTop: 14,
              background: "#ddd",
              zIndex: 1000,
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                margin: "10px 0px 0px 0px",
                fontSize: "20px",
                display: "inline-block",
              }}
            >
              <span>Edit Schedule</span>
            </div>
            <div style={{ display: "inline-block", marginLeft: 200 }}>
              <div>
                <div>
                  <Button
                    style={{ marginLeft: 10 }}
                    bsStyle="default"
                    bsSize="small"
                    className="sub-create-button"
                    disabled={this.state.isSavingTrigger}
                    onClick={() => this.props.toggleModal()}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    bsStyle="primary"
                    bsSize="small"
                    className="sub-create-button"
                    disabled={
                      !this.state.selectedDeployment ||
                      !this.state.selectedSource ||
                      this.state.isSavingTrigger
                    }
                    onClick={this.initSaveTrigger}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Heading Section Ends */}
        <br />
        {/*start of marginleft envelope*/}
        <div
          style={{
            height: "calc(100%-40px)",
          }}
        >
          {/* start of select recipe */}
          <div
            className="sub-create-area"
            style={{ transform: "translateX(-514px)" }}
          >
            <div style={{ height: "100%" }}>
              <div className="sub-type-env-trigger">
                <div
                  style={{ width: 450, marginBottom: 10, marginTop: 10 }}
                  className="rec-wiz-toggler"
                >
                  <div style={{ marginBottom: 40 }}>
                    <hr />
                    <ControlLabel>Selected Deployment</ControlLabel>
                    <br />

                    <div style={{ marginTop: 5 }}>
                      <div>
                        <div
                          style={{
                            position: "relative",
                            width: 356,
                            paddingBottom: 5,
                          }}
                        >
                          <div style={{ marginLeft: 0, fontSize: 13 }}>
                            <span style={{ fontWeight: "bold" }}>
                              {selectedDeployment &&
                                selectedDeployment.transformerName}
                            </span>
                            <div style={{ fontSize: 11, marginTop: 4 }}>
                              <span
                                style={{
                                  padding: "1px 5px 1px 5px",
                                  fontWeight: "bold",
                                  borderRadius: 2,
                                  borderColor: "darkgray",
                                  background: "lightgray",
                                }}
                              >
                                {selectedDeployment &&
                                  selectedDeployment.environmentName}
                              </span>
                              <span> Environment</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <strong>Template Name</strong>
                    <br />
                    {availableTemplates && availableTemplates.length > 0 && (
                      <div
                        style={{
                          display: "inline-block",
                          marginRight: 10,
                          position: "relative",
                        }}
                        className="dropdown-button-wrap"
                      >
                        {
                          <DropdownButton
                            title={
                              this.state.selectedTemplate != ""
                                ? this.state.selectedTemplate.toUpperCase()
                                : "Select Template"
                            }
                            style={{ fontSize: 12, fontWeight: "bold" }}
                            id={"template-dropdown"}
                            onSelect={(e) => {
                              this.handleTemplateChange(e);
                            }}
                          >
                            {availableTemplates.length > 0 ? (
                              availableTemplates.map((t) => {
                                return (
                                  <MenuItem
                                    key={t}
                                    eventKey={t}
                                    active={this.state.selectedTemplate === t}
                                  >
                                    {t.toUpperCase()}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem
                                key={"loading"}
                                disabled={true}
                                eventKey={"loading"}
                              >
                                <Loading />
                              </MenuItem>
                            )}
                          </DropdownButton>
                        }
                      </div>
                    )}
                  </div>

                  {/* schdule types */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "100%",
                      overflowY: "auto",
                    }}
                  >
                    {/* if trigger modal */}
                    <div
                      style={{
                        display: "inline-block",
                        width: "fit-content",
                      }}
                    >
                      <label htmlFor="Trigger Events">
                        Automate Your Integration
                      </label>
                      <br />
                      <Checkbox
                        onChange={() => this.handleSourceChange("isdaily")}
                        checked={this.state.selectedSource === "isdaily"}
                      >
                        Daily Schedule
                      </Checkbox>
                      <Checkbox
                        onChange={() => this.handleSourceChange("isrepeating")}
                        checked={this.state.selectedSource === "isrepeating"}
                      >
                        Repeating Schedule
                      </Checkbox>
                      <Checkbox
                        onChange={() => this.handleSourceChange("iscron")}
                        checked={this.state.selectedSource === "iscron"}
                      >
                        Advanced
                      </Checkbox>
                    </div>
                  </div>
                </div>

                {/* delete button */}
                <div style={{ marginTop: 40 }}>
                  <Button
                    bsSize="small"
                    bsStyle="danger"
                    disabled={this.state.isSavingTrigger}
                    onClick={this.clearTrigger}
                  >
                    Delete Schedule
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* end of select recipe */}

          <div
            style={{
              marginLeft: "30vw",
              width: "40vw",
              marginTop: "-60px",
              border: "1px solid grey",
              padding: "1rem",
            }}
          >
            <label htmlFor="Trigger Events">Schedule Configuration</label>
            <br />
            {this.state.selectedSource === "isrepeating" && (
              <div style={{ display: "flex", marginRight: 100 }}>
                <div style={{ width: 148 }}>
                  <span className="labelz">Every</span>
                  <br />
                  <If
                    condition={
                      this.state.frequencyUnit == "hours" ||
                      this.state.frequencyUnit == "Hours"
                    }
                  >
                    <Then>
                      <Select
                        options={recurringHoursOptions}
                        onChange={this.changeFrequency}
                        value={this.state.frequency}
                      />
                    </Then>
                    <Else>
                      <Select
                        options={recurringMinutesOptions}
                        onChange={this.changeFrequency}
                        value={this.state.frequency}
                      />
                    </Else>
                  </If>
                </div>
                <div style={{ marginLeft: 16 }}>
                  <span className="labelz">Time Unit</span>
                  <br />
                  <DropdownButton
                    value={this.state.frequencyUnit}
                    bsStyle="default"
                    id="frequency-unit"
                    title={this.state.frequencyUnit || "Time Unit"}
                  >
                    {timeUnitOptions.map((unit, i) => (
                      <MenuItem
                        value={unit}
                        key={i}
                        onClick={() => this.onPickTimeUnit(unit)}
                        active={this.state.frequencyUnit === unit}
                      >
                        {unit}
                      </MenuItem>
                    ))}
                  </DropdownButton>
                </div>
                <div style={{ marginLeft: 10, display: "grid" }}>
                  <span className="labelz">Start first time at</span>
                  <TimePicker
                    showSecond={false}
                    defaultValue={moment().hour(0).minute(0)}
                    className="time-picker"
                    onChange={this.onPickStartinAt}
                    format={"h:mm a"}
                    use12Hours
                    value={this.state.startingAt}
                  />
                </div>
              </div>
            )}

            {this.state.selectedSource === "isdaily" && (
              <div style={{ display: "flex" }}>
                <span className="labelTZ">Time</span>
                <br />
                <div style={{ marginTop: 21, marginRight: 8, marginLeft: -26 }}>
                  <TimePicker
                    showSecond={false}
                    defaultValue={moment().hour(0).minute(0)}
                    className="time-picker"
                    onChange={this.onTimePick}
                    format={"h:mm a"}
                    use12Hours
                    value={this.state.dailySchedule}
                    disabled={false}
                  />
                </div>
                <div>
                  <span className="labelTZ">Time Zone</span>
                  <br />
                  <div style={{ width: 200, fontSize: 10 }}>
                    <Select
                      options={dailyTimeZoneOptions}
                      onChange={this.changeTimeZone}
                      value={this.state.timeZone}
                    />
                  </div>
                </div>
                <br />
              </div>
            )}

            {this.state.selectedSource === "iscron" && (
              <div style={{}}>
                <br />
                <CronBuilder
                  cronExpression={this.state.cronExpression}
                  onChange={(value) => {
                    this.setState({ cronExpression: value });
                    this.handleSourceChange("iscron");
                  }}
                  showResult={true}
                  getData={(cron) => {
                    this.setState({ cronExplain: cron.cronString });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
