import React from 'react'
import { Modal, Button } from 'react-bootstrap'
const { Body } = Modal;

const ExitCheckMod = ({show, hide, handleExit}) => {


  return(
    <div>

    <Modal show={show} onHide={hide} dialogClassName="confirm-delete-modal" backdrop="static">
            <Body style={{height: 'fit-content'}}>
              <div style={{textAlign: 'center', verticalAlign: 'middle',paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20,}}>
                <h2>Unsaved changes!</h2>
                   <div> 
                      <p>Save to keep your work.</p>
                  </div>
                <div style={{ marginTop: 20 }}>
                  <Button
                    style={{ marginLeft: 10 }}
                    bsStyle="primary"
                    onClick={()=>handleExit()}>
                    Got it
                  </Button>
                </div>
              </div>
            </Body>
          </Modal>
    </div>
  )
}

export default ExitCheckMod