import React, { Component } from 'react'

import images from '../images'


export default class ConnectedAnalytics extends Component {
  constructor() {
    super()
    this.state = {
      selected: null,
      selectedEnvironment: null,
      activitydata: []
    }

    this.handleBackToTop = this.handleBackToTop.bind(this)
    this.addConnection = this.addConnection.bind(this)
    this.editConnection = this.editConnection.bind(this)
    this.deleteConnection = this.deleteConnection.bind(this)
    this.startCreateConnection = this.startCreateConnection.bind(this)
    this.selectEnvironment = this.selectEnvironment.bind(this)
  }

  handleBackToTop() {
    const { appId } = this.props.match.params
    this.props.history.push(`/a/${appId}/ca/`)
  }

  startCreateConnection() {
    const { appId } = this.props.match.params
    this.props.history.push(`/a/${appId}/ca/new`)
  }

  addConnection(a, data) {
    const { appId, transId, tenantId } = this.props.match.params
    const { callCreateConnectedExp } = this.props.actions
    //    const sampledata = [...this.state.sampledata]

    const connectedExperienceData = [
      ...this.props.redux.transformer.connectedExperienceData
    ]

    if (connectedExperienceData.includes(data.name)) {
    } else {
      connectedExperienceData.push(data)
      callCreateConnectedExp(tenantId, appId, connectedExperienceData)
    }

    this.props.history.push(`/a/${appId}/ca/${transId}`)
    //push browser to details
  }

  deleteConnection(a, obj) {
    const { appId, tenantId } = this.props.match.params
    const { callCreateConnectedExp } = this.props.actions
    //    const sampledata = [...this.state.sampledata]

    const connectedExperienceData = [
      ...this.props.redux.transformer.connectedExperienceData
    ]

    //   const sampledata = [...this.state.sampledata]
    const position = connectedExperienceData.findIndex(f => obj === f)
    connectedExperienceData.splice(position, 1)
    callCreateConnectedExp(tenantId, appId, connectedExperienceData)

    this.handleBackToTop()
  }

  editConnection(a, remove, obj, position, itemposition, recipe) {
    //if remove is true, remove
    //if recipe if true, its a recipe, else a wizard

    if (recipe) {
      const project = { ...this.props.redux }
      const recipeBaseIds = project.connectedExperienceData

      if (remove) {
        recipeBaseIds.splice(itemposition, 1)
        //this.setState({sampledata})
      } else {
        if (!recipeBaseIds.includes(obj)) {
          recipeBaseIds.push(obj)
          //this.setState({sampledata})
        }
      }
    } else {
      const project = { ...this.props.redux }
      const wizardGuids = project.connectedExperienceData

      if (remove) {
        wizardGuids.splice(itemposition, 1)
        //this.setState({sampledata})
      } else {
        if (!wizardGuids.includes(obj)) {
          wizardGuids.push(obj)
          //..this.setState({sampledata})
        }
      }
    }
  }

  selectEnvironment(env) {
    this.setState({ selectedEnvironment: env })
  }

  render() {
    const { children, redux, actions } = this.props
    const { transformer } = this.props.redux
    const { selectedEnvironment, selected, activitydata } = this.state

    return (
      <div className="main-tab-content" style={{ paddingTop: 16 }}>
        {transformer ? (
          <div>
            {React.isValidElement(children)
              ? React.cloneElement(children, {
                  redux,
                  actions,
                  selectEnvironment: this.selectEnvironment,
                  selectedEnvironment: selectedEnvironment,
                  addConnection: this.addConnection,
                  selected: selected,
                  editConnection: this.editConnection,
                  deleteConnection: this.deleteConnection,
                  activitydata: activitydata
                })
              : null}
          </div>
        ) : (
          <div className="ajax-loader">
            <img alt="spinner" src={images.ajax_loader} />
          </div>
        )}
      </div>
    )
  }
}
