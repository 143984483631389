import * as types from "../actions/types";
import environment from "environment";
const CONFIG = {};

const getInitalAuthVars = () => {
    CONFIG.IS_INTEGRATION = environment.IS_INTEGRATION;
    CONFIG.AUTH0_CLIENT_ID = environment.AUTH0_CLIENT_ID;
    CONFIG.AUTH0_DOMAIN = environment.AUTH0_DOMAIN;
    CONFIG.GOOGLE_CAPTCHA_KEY = environment.GOOGLE_CAPTCHA_KEY;
    CONFIG.HOME_URL = environment.HOME_URL; 
    CONFIG.API_URL = environment.API_URL; // 'http://localhost:8083/v1/'; // 
    CONFIG.DATA_API_URL = environment.DATA_API_URL;//'http://localhost:8081/v1/'
    CONFIG.EXT_DATA_API_URL = environment.EXT_DATA_API_URL;
    CONFIG.FTP_URL = environment.FTP_URL;
    CONFIG.FTP_DIR = environment.FTP_DIR;
    CONFIG.BULK_UPLOAD_UI_URL = environment.BULK_UPLOAD_UI_URL;
    CONFIG.PS = environment.PS;
    CONFIG.INTERCOM_APP_ID = environment.INTERCOM_APP_ID;
    CONFIG.TRANS_API_URL = environment.TRANS_API_URL;//'http://localhost:8084/v1/';
    CONFIG.TRANS_API_URL2 = environment.TRANS_API_URL2;
    CONFIG.SF_AGENT_API_URL = environment.SF_AGENT_API_URL;
    CONFIG.MONITOR_API_URL = environment.MONITOR_API_URL;
    CONFIG.TEST_CONN_API_URL = environment.TEST_CONN_API_URL;
    CONFIG.RECIPE_MONITORING_API_URL = environment.RECIPE_MONITORING_API_URL;
}

if (Object.keys(CONFIG).length === 0 && CONFIG.constructor === Object) {
  // const env = process.env.DATAPROTECT_ENV==='production' ? 'PRODUCTION' : 'INTEGRATION'
  // console.log('Config is empty inside REDUCER.. Going to get urls for ' + env + '!');
  getInitalAuthVars();
}

const initialState = CONFIG;
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_CONFIG_CREDS:
      console.log('SET_CONFIG_CREDS FROM REDUCER.');
      return action.newCreds;
    default: return state;
  }
};
