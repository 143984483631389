import React from 'react'
import { Button } from 'react-bootstrap';
import * as Sources from './sourceIntros'

const styles={
  box:{
    marginRight:40,
    padding:20,
    border:'1px solid grey',
    background:'#ecefef',
    textAlign:'center',
    maxWidth:393
  }
}

const StartScreen = props => {
  const { wiz, match } = props;
  const { params } = match;
  const Source = Sources[wiz.source.type.split(' ').join('')]
  const Destination = Sources[wiz.destination.type.split(' ').join('')]

  return <div className="main-tab-content" 
        style={{marginTop:50, paddingTop:25, paddingBottom:50}}>
        <h4>Welcome to the Visual SQL Wizard</h4>
        <br />
        <div>
          <Source {...props} accountId={params.appId} />
          <br />
          <Destination {...props} accountId={params.appId} />
          <br /><br /><br />
          <div style={styles.box}>
            <div style={{marginBottom:10}}>When your source and target systems are ready:</div>
            <Button onClick={props.start} bsStyle="primary">
              Start Your Recipe
            </Button>
          </div>
        </div>
        <br /><br />
      </div>

}

export default StartScreen

