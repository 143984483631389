import React, { Component } from "react";
import { createClient } from "contentful";
import { DropdownButton, MenuItem, FormControl, Button } from "react-bootstrap";

const styles = {
  r: {
    display: "inline-block",
    verticalAlign: "top",
    height: 26,
    lineHeight: "28px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  envBox: {
    border: "1px solid black",
    marginRight: 4,
    marginTop: 3,
    marginLeft: 5,
    height: 20,
    width: 20,
  },
  checkBox: {
    marginRight: 4,
    marginTop: 3,
    marginLeft: 5,
    height: 20,
    width: 20,
  },
};

const contentfulClient = createClient({
  accessToken:
    "0f243ce9285ce061d169b5d337e547755168c3972d67dc957df8362b504ccdfd",
  space: "0qhqhn3xauc3",
});

const CONNECTOR_CONTENT_TYPE_ID = "connector";
class ConnectorBar extends Component {
  constructor() {
    super();
    this.wrapper = [];
    this.state = {
      contentfulTemplates: [],
      searchTerm: "",
      selectedFilter: "Relevance",
    };
  }
  clearSearch = () => {
    this.setState({ searchTerm: "" });
  };
  componentWillMount() {
    const { config } = this.props.redux;
    const contentfulTemplates = [];
    const isIntegration = config.IS_INTEGRATION;
    contentfulClient
      .getEntries({
        include: 3,
        content_type: CONNECTOR_CONTENT_TYPE_ID,
        order: "fields.title",
      })
      .then((entries) => {
        entries.items.map((o) => {
          if (o.fields.logo) {
            let imgUrl = o.fields.logo.fields.file.url;
            if (!imgUrl.startsWith("https://")) {
              o.fields.logo.fields.file.url = "https:" + imgUrl;
            }
          }
          if (!o.fields.relevance) {
            o.fields.relevance = 10;
          }
          if (!isIntegration) {
            if (o.fields.forIntegrationOnly == true) return;
          }
          if (o.fields.displayOnAppEnvironments) contentfulTemplates.push(o);
        });
      })
      .then(() => {
        contentfulTemplates.sort(function (a, b) {
          if (a.fields.relevance < b.fields.relevance) {
            return -1;
          }
          if (a.fields.relevance > b.fields.relevance) {
            return 1;
          }
          return 0;
        });
        this.setState({ contentfulTemplates });
      });
  }
  normalizeCredentialTypeNames(providerLabel) {
    let providerType = providerLabel;
    if (
      providerType === "AdapterSecret" ||
      providerType === "EnvironmentVariables"
    )
      providerType = null;
    return providerType;
  }
  getName(name) {
    if (name.length > 34) {
      return name.slice(0, 33) + "..";
    } else {
      return name;
    }
  }

  handleSorting(s) {
    this.setState({ selectedFilter: s });
    const { contentfulTemplates } = this.state;
    if (s == "Alphabatic") {
      contentfulTemplates.sort(function (a, b) {
        if (a.fields.title < b.fields.title) {
          return -1;
        }
        if (a.fields.title > b.fields.title) {
          return 1;
        }
        return 0;
      });
    } else {
      contentfulTemplates.sort(function (a, b) {
        if (a.fields.relevance < b.fields.relevance) {
          return -1;
        }
        if (a.fields.relevance > b.fields.relevance) {
          return 1;
        }
        return 0;
      });
    }
    this.setState({ contentfulTemplates });
  }

  render() {
    const { redux, changeConnector, providerConfig, showConnectorType } =
      this.props;
    const { main, project } = redux;
    const selectedConnector = providerConfig && providerConfig.type;
    const { contentfulTemplates, searchTerm } = this.state;
    const fullProviders = main.providers;
    let remainingProviders;
    if (showConnectorType == "reader") {
      remainingProviders = fullProviders.filter(
        (f) => f.readerName == "Reader"
      );
    } else {
      remainingProviders = fullProviders.filter(
        (f) => f.writerName == "Writer"
      );
    }
    let webSocketConnection =
      project.environmentCredentials &&
      project.environmentCredentials.find(
        (ec) => ec.credentialType == "LingkWebSocket"
      );
    if (webSocketConnection) {
      remainingProviders = fullProviders.filter(
        (f) => f.type != webSocketConnection.credentialType
      );
    }
    const connectorItems = [];
    if (contentfulTemplates.length > 0) {
      for (let i = 0; i < contentfulTemplates.length; i++) {
        const searched =
          searchTerm === "" ||
          contentfulTemplates[i].fields.title
            .toLowerCase()
            .includes(searchTerm.toLowerCase());

        if (this.props.newConnector) {
          let data = remainingProviders.filter(
            (el) => el.type === contentfulTemplates[i].fields.providerType
          );
          if (data.length > 0 && searched) {
            connectorItems.push(contentfulTemplates && contentfulTemplates[i]);
          }
        } else {
          if (searched) {
            connectorItems.push(contentfulTemplates && contentfulTemplates[i]);
          }
        }
      }
    }

    return (
      <div className="connector-bar">
        <FormControl
          type="text"
          placeholder="Search Connectors"
          style={{ width: 600, height: 40 }}
          value={searchTerm}
          onChange={(e) => this.setState({ searchTerm: e.target.value })}
        />
        <div style={{ marginTop: 10, display: "block" }}>
          <span>{connectorItems.length} sources</span>
          <div style={{ float: "right" }}>
            <span>
              Sort by <b>{this.state.selectedFilter}</b>
            </span>{" "}
            <DropdownButton
              id="environments"
              placeholder="Environment"
              bsStyle="default"
              bsSize="small"
              className="save-recipe"
              style={{
                width: 150,
                fontWeight: "bold",
                float: "right",
                background: "#dcdfdf",
              }}
            >
              <MenuItem onClick={() => this.handleSorting("Alphabatic")}>
                <strong>Alphabetic</strong>
              </MenuItem>
              <MenuItem onClick={() => this.handleSorting("Relevance")}>
                <strong>Relevance</strong>
              </MenuItem>
            </DropdownButton>
          </div>
        </div>

        {connectorItems && (
          <div ref={(ref) => (this.wrap = ref)}>
            {connectorItems.map((p, i) => {
              const connectorType = p.fields.providerType;
              const isSelected = connectorType === selectedConnector;
              return (
                <div
                  ref={(ref) => (this.wrapper[i] = ref)}
                  key={i}
                  className="connector-cell"
                  onClick={() => changeConnector(connectorType)}
                  style={isSelected ? { background: "white" } : {}}
                >
                  <div style={{ ...styles.r }}>
                    <img
                      style={
                        isSelected
                          ? { ...styles.checkBox, transform: "scale(1.5)" }
                          : { ...styles.checkBox }
                      }
                      src={p.fields.logo ? p.fields.logo.fields.file.url : ""}
                    ></img>
                  </div>
                  <div
                    style={
                      isSelected
                        ? {
                            ...styles.r,
                            marginLeft: 14,
                            transform: "scale(1.1)",
                            fontWeight: "bold",
                          }
                        : { ...styles.r, marginLeft: 6 }
                    }
                  >
                    {isSelected ? (
                      <span>{this.getName(p.fields.title)}</span>
                    ) : (
                      <span>{p.fields.title}</span>
                    )}
                  </div>
                  {p.fields.categories &&
                    p.fields.categories.map((cat) => {
                      return (
                        <div
                          className="chip"
                          style={{ float: "right", marginRight: 2 }}
                        >
                          {cat.fields.title}
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default ConnectorBar;
