import React, { Component } from "react";
import images from "../../components/images";
import AccountBox from "../../components/main/accountBox";
import { DropdownButton, MenuItem } from "react-bootstrap";
import Toggle from "react-toggle";
//import FirstProjectHelper              from 'components/main/firstprojecthelper';
import TemplateHelper from "./templateHelper";
import EE from "../utils/eventEmitter";

export default class AccountsList extends Component {
  constructor() {
    super();
    this.state = {
      orderAlpha: false,
      searchTerm: "",
      modalstate: false,
      template: null,
      wizardId: null,
      // hidehelpers: false,
    };

    this.triggerModal = this.triggerModal.bind(this);
    EE.on("choose-workspace", this.chooseFromQuickAction);
  }

  componentWillMount() {
    //reflect elevio show/hide preference
    // const hidinghelpers = JSON.parse(
    //   window.localStorage.getItem("hideHelpers")
    // );

    // if (hidinghelpers === true) {
    //   document.body.classList.add("hide-elevio");
    //   this.setState({ hidehelpers: true });
    // } else {
    //   document.body.classList.remove("hide-elevio");
    //   this.setState({ hidehelpers: false });
    // }
    const queryString = require("query-string");
    const parsed = queryString.parse(this.props.location.search);
    if (this.props.location.state && this.props.location.state.tenantName) {
      this.setState({
        searchTerm: this.props.location.state.tenantName,
      })
    }
    this.props.history.replace({});
    //store template info in state
    this.openTemplate(parsed.template);
  }

  chooseFromQuickAction = ({ why, arg }) => {
    const { redux } = this.props;
    const { main } = redux;
    if (why === "template") {
      this.openTemplate(arg);
    } else if (why === "wizard") {
      if (main.appList.length > 1) {
        this.setState({ wizardId: arg, modalstate: true });
      } else if (main.appList.length === 1) {
        this.props.history.push({
          pathname: `/a/${main.appList[0].Id}/wiz/start/${arg}`,
          state: {
            accountName: main.appList[0].name,
            accountKey: main.appList[0].uniquekey,
          },
        });
      }
    }
  };

  openTemplate = (template) => {
    const { redux } = this.props;
    const { main } = redux;
    if (template && main.appList.length > 1) {
      this.setState({ template, modalstate: true });
    } else if (template && main.appList.length === 1) {
      this.props.history.push({
        pathname: `/a/${main.appList[0].Id}/tf/new`,
        state: {
          template,
        },
      });
    }
  };

  triggerModal() {
    let { modalstate } = this.state;
    modalstate = !modalstate;
    this.setState({ modalstate });
  }

  // handleToggle = () => {
  //   let hidehelpers = this.state.hidehelpers;
  //   hidehelpers = !hidehelpers;
  //   this.setState({ hidehelpers });

  //   if (hidehelpers) {
  //     document.body.classList.add("hide-elevio");
  //     window.localStorage.setItem("hideHelpers", "true");
  //   } else {
  //     document.body.classList.remove("hide-elevio");
  //     window.localStorage.setItem("hideHelpers", "false");
  //   }
  // };

  render() {
    const { main } = this.props.redux;
    const styles = {
      sidebar: {
        position: "fixed",
        top: 0,
        backgroundColor: "#404040",
        height: "100%",
        marginTop: 50,
        color: "white",
        zIndex: 13,
        width: 260,
      },
      sideContent: {
        height: "calc(100% - 100px)",
        overflowY: "auto",
        overflowX: "hidden",
        width: "100%",
        paddingTop: 8,
        paddingLeft: 4,
        paddingRight: 20,
        paddingBottom: 40,
        margin: "2px 0",
      },
      search: {
        width: "150px",
        float: "right",
        margin: "0.9rem 0",
        borderRadius: 0,
        height: 30,
      },
      iconStyles: {
        width: 19,
        height: 19,
        verticalAlign: "top",
      },
      logo: {
        height: 20,
        display: "inline-block",
        margin: "auto",
        marginTop: -15,
      },
    };

    if (!main.appList) {
      return <div />;
    }

    const accountsArray = main.appList.map((account, index) => {
      let highlight = null;
      const t = main.tenantList.find(
        (tenant) => tenant.TenantId === account.tenantId
      );
      if (
        t &&
        t.Level &&
        main.accessLevels &&
        main.accessLevels.find((al) => al.level === t.Level)
      ) {
        highlight = main.accessLevels.find((al) => al.level === t.Level).color;
      }
      return (
        <AccountBox
          account={account}
          key={index}
          actions={this.props.actions}
          highlight={highlight}
          template={this.state.template}
          wizardId={this.state.wizardId}
          history={this.props.history}
        />
      );
    });

    if (main.appList.length < 1) {
      //fixme FirstProjectHelper
    } else {
      if (this.state.orderAlpha) {
        accountsArray.sort((a, b) => {
          if (
            a.props.account.name.toLowerCase() <
            b.props.account.name.toLowerCase()
          )
            return -1;
          else if (
            a.props.account.name.toLowerCase() >
            b.props.account.name.toLowerCase()
          )
            return 1;
          else if (
            a.props.account.tenantName.toLowerCase() >
            b.props.account.tenantName.toLowerCase()
          )
            return 1;
          return 0;
        });
      } else {
        accountsArray.sort((a, b) => {
          if (a.props.account.lastmodified < b.props.account.lastmodified)
            return 1;
          else if (a.props.account.lastmodified > b.props.account.lastmodified)
            return -1;
          return 0;
        });
      }
    }

    let searchHit = true;
    let shownaccounts = accountsArray;

    if (this.state.searchTerm) {
      shownaccounts = [];
      searchHit = false;
      const searchValue = this.state.searchTerm.toLowerCase();
      accountsArray.forEach((a, i) => {
        const name = a.props.account && a.props.account.name;
        const tenantName = a.props.account && a.props.account.tenantName;
        if (name && name.toLowerCase().indexOf(searchValue) > -1) {
          //accountsArray.unshift(accountsArray.splice(i, 1)[0])
          searchHit = true;
          shownaccounts.push(a);
        } else if (tenantName && tenantName.toLowerCase().indexOf(searchValue) > -1) {
          searchHit = true;
          shownaccounts.push(a);
        }
      });
    }

    return (
      <div style={styles.sidebar} className="sidebar">
        <div style={{ height: 48, width: "100%", fontSize: 17 }}>
          <div
            style={{
              display: "inline-block",
              padding: 15,
              paddingTop: 12,
              marginBottom: 3,
              color: "#ccc",
              fontSize: 16,
            }}
          >
            Your Projects
          </div>

          <svg
            className="add-ws"
            height="18"
            viewBox="0 0 24 24"
            onClick={() => this.props.history.push("/newaccount")}
          >
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>

          <div style={{ float: "right", width: "100%", marginTop: -10 }}>
            <input
              type="text"
              placeholder="Search..."
              className="search-form-control"
              onChange={(e) => {
                this.props.location.state && this.props.location.state.tenantName ?
                  this.setState({ searchTerm: "" }) :
                  this.setState({ searchTerm: e.target.value })
              }}
              style={{
                marginLeft: 19,
                width: 194,
                background: "#fff",
                color: searchHit ? "rgb(104, 104, 104)" : "rgb(164, 30, 34)",
              }}
              value={this.props.location.state && this.props.location.state.tenantName}
            />
            <DropdownButton
              pullRight
              className="sort-form-control"
              style={{ height: 29, marginTop: -2, marginLeft: -1, height: 30 }}
              title=""
              id="sort-dropdown"
            >
              <MenuItem
                eventKey="2"
                onClick={() => this.setState({ orderAlpha: false })}
              >
                <img
                  src={images.ic_sort_grey600_48dp}
                  style={styles.iconStyles}
                  role="presentation"
                />
                Sort By Date
              </MenuItem>
              <MenuItem
                eventKey="1"
                onClick={() => this.setState({ orderAlpha: true })}
              >
                <img
                  src={images.ic_sort_grey600_48dp}
                  style={styles.iconStyles}
                  role="presentation"
                />
                Sort Alphabetically
              </MenuItem>
            </DropdownButton>
          </div>
        </div>

        <div style={styles.sideContent} className="sidebar-content">
          {shownaccounts && shownaccounts.length > 0 ? (
            shownaccounts
          ) : (
            <div style={{ textAlign: "center", color: "#ccc" }}>
              <span>No match</span>
            </div>
          )}

          {/* <div
            className="helpers-toggle"
            style={{ marginTop: 10, marginLeft: 6, float: "left" }}
          >
            <span style={{ fontSize: 11, color: "#bbb" }}>Help icons</span>
            <br />
            <Toggle
              checked={this.state.hidehelpers}
              className="helpers-toggle-color"
              icons={{
                checked: (
                  <span className="toggle-label toggle-label-hide">hide</span>
                ),
                unchecked: (
                  <span className="toggle-label toggle-label-show">show</span>
                ),
              }}
              onChange={this.handleToggle}
            />
            <span style={{ height: 8 }} />
          </div> */}
        </div>

        {main.appList.length > 0 && (
          <TemplateHelper
            accountsArray={accountsArray}
            modalState={this.state.modalstate}
            triggerModal={this.triggerModal}
          />
        )}
      </div>
    );
  }
}
