import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import SideNav from "../components/account/sidenav";
import Loading from "../components/header/loading";
import * as indexDb from "../db/recipesDOA";
const AccountWrap = (props) => {
  const { redux } = props;
  const { main } = redux;
  if (!(main.tenantList && main.appList && main.accessLevels)) return <div />;
  return <Account {...props} />;
};
import { Prompt } from "react-router-dom";

export default AccountWrap;

class Account extends Component {
  componentWillMount() {
    const { actions, match, redux } = this.props;
    const { project, main } = redux;
    const { params } = match;
    let { tenantInfo } = project; // undefined on hard reload
    if (!tenantInfo) {
      const app = main.appList.find((a) => a.Id === parseInt(params.appId, 10));
      if (app) {
        const tenant = main.tenantList.find((t) => t.TenantId === app.tenantid);
        actions.setAppInfo(app, app.tenantid);
        tenantInfo = tenant;
      }
    }
    actions.callMainProjectInfo(params.appId, tenantInfo).then(() => {
      if (!redux.main.wizards) {
        actions.callGetProductizedIntegrationTypesForUser();
      }
      actions.callGetWizards(tenantInfo.TenantId, params.appId);
      actions.callGetEnvironments(tenantInfo.TenantId, params.appId);
      actions.callGetTransformerLogsFirst100Workspace(
        tenantInfo.TenantId,
        params.appId
      );
      actions.clearTransformer();
      actions.callGetRecommendedActions(tenantInfo.Level);
      actions.callGetConnectedExperiences(tenantInfo.TenantId, params.appId);
      actions.callGetDeploymentsForWorkspace(tenantInfo.TenantId, params.appId);
      actions.callGetFeatures(tenantInfo.TenantId);
      actions.callGetDataMappings(tenantInfo.TenantId, params.appId);
    });
    actions.callGetTenantCreds(params.appId);
    actions.callGetTransformers(params.appId).then(() => {
      indexDb.DeleteProjectRecipes(params.appId).then(() => {
        indexDb.AddRecipes(this.props.redux.project.transformers);
      });
    });
    //actions.callGetApiHubDetail(12) // 12 = Id of "All data elements"
    this.renderElevioColors();
  }
  componentWillUnmount() {
    this.props.actions.clearProjectData();
  }
  componentDidMount() {
    this.props.actions.setShowMenu(false);
  }
  renderElevioColors = () => {
    const { redux } = this.props;
    const { project, main } = redux;
    main.accessLevels.forEach((al) => {
      if (al.accessKey) {
        document.body.classList.remove(`${al.accessKey}-elevio`);
      }
    });
  };
  render() {
    const { children, redux, actions } = this.props;
    const { project, transformer, main } = redux;
    const { location } = this.props;
    let showSideNav = true;
    if (
      location.pathname.includes("health/details") ||
      location.pathname.includes("health/sfjobs")
    ) {
      showSideNav = false;
    }
    let path = window.location.href;
    let envID = path.substr(path.length - 3);
    let localSelectedEnv;
    if (location.pathname.includes("/env/") && envID != "env") {
      const se =
        project.environments &&
        project.environments.find((f) => f.environmentId == envID);
      if (se) {
        localSelectedEnv = se;
      }
    }
    const {
      appData,
      connectedExperienceData,
      environmentCredentials,
      selectedEnv,
    } = project;
    if (
      !(
        main &&
        main.tenantList &&
        main.wizards &&
        main.appList &&
        main.users &&
        appData
      )
    )
      return <div />;

    let headerColor = "#6C63FF";
    if (
      project &&
      project.tenantInfo &&
      project.tenantInfo.Level &&
      main &&
      main.accessLevels &&
      main.accessLevels.find((al) => al.level === project.tenantInfo.Level)
    ) {
      const color = main.accessLevels.find(
        (al) => al.level === project.tenantInfo.Level
      ).color;
      if (color) {
        headerColor = color;
      }
    }
    let renderElevioColors = false;
    if (
      project &&
      project.tenantInfo &&
      project.tenantInfo.Level &&
      main &&
      main.accessLevels &&
      main.accessLevels.find((al) => al.level === project.tenantInfo.Level)
    ) {
      renderElevioColors = true;
    }
    let showPrompt =
      this.props.redux.project && this.props.redux.project.showExitPrompt;
    const appList = this.props.redux.main.appList;
    const currentApp = appList.filter((app) => {
      return app.Id === appData.Id
    })

    const versionList = this.props.redux.transformer.versions;
    const pathname = this.props.location.pathname;
    let currentRecipeName = "";
    if (versionList && versionList.length > 0 && pathname === '/a/' + appData.Id + '/tf/' + versionList[0].BaseId) {
      currentRecipeName = versionList[0].Name;
    }

    return (
      <div
        style={{ height: "100%", position: "absolute", top: 0, width: "100%" }}
      >
        <Prompt
          message={(location, action) => {
            return location.pathname.startsWith("/a")
              ? true
              : showPrompt
                ? `You have unsaved changes, are you sure you want to leave the project?`
                : true;
          }}
        />
        <Helmet title="Project" />

        {renderElevioColors && <RenderElevioColors redux={redux} />}
        {showSideNav && (
          <div
            className="project-name-header"
            style={{ background: headerColor }}
          >
            <span
              className="bread-crumb"
              onClick={() => {
                this.props.history.push({
                  pathname: `/`,
                  state: { tenantName: currentApp[0].tenantName }
                })
              }}>{(appList && currentApp && currentApp[0].tenantName) || <Loading />}</span>
            <span>&nbsp;&nbsp;➤&nbsp;&nbsp;</span>


            <span
              className="bread-crumb"
              onClick={() => {
                this.props.history.push({
                  pathname: `/a/${appData.Id}/tf`,
                })
              }}>{(appData && appData.name) || <Loading />}</span>
            {/* Environment Name */}
            {localSelectedEnv &&
              <span>
                <span>&nbsp;&nbsp;➤&nbsp;&nbsp;</span>
                <span style={{ textDecoration: "underline" }}>
                  {(localSelectedEnv && localSelectedEnv.name) || <Loading />}

                </span>
              </span>
            }

            {/* recipe name */}
            {versionList && location.pathname.includes("/tf/") &&
              <span>
                <span>&nbsp;&nbsp;➤&nbsp;&nbsp;</span>
                <span style={{ textDecoration: "underline" }}>
                  {(versionList && currentRecipeName) || <Loading />}

                </span>
              </span>
            }
          </div>
        )}
        {showSideNav && <SideNav {...this.props} />}
        {React.isValidElement(children)
          ? React.cloneElement(children, { redux, actions })
          : null}
      </div>
    );
  }
}

Account.propTypes = {
  redux: PropTypes.object,
  actions: PropTypes.object,
  params: PropTypes.object,
  children: PropTypes.node,
};

class RenderElevioColors extends Component {
  componentWillMount() {
    const { redux } = this.props;
    const { project, main } = redux;
    const accessKey = main.accessLevels.find(
      (al) => al.level === project.tenantInfo.Level
    ).accessKey;
    if (accessKey) {
      document.body.classList.add(`${accessKey}-elevio`);
    }
  }
  render() {
    return <span />;
  }
}
