import React, { useState, useEffect, useRef, Fragment } from "react";
import { createClient } from "contentful";
import images from "../images";
import { Table, Button } from "react-bootstrap";
import * as indexDb from "../../db/recipesDOA";

const contentfulClient = createClient({
  accessToken:
    "0f243ce9285ce061d169b5d337e547755168c3972d67dc957df8362b504ccdfd",
  space: "0qhqhn3xauc3",
});

const CONNECTOR_CONTENT_TYPE_ID = "connector";
const RECIPE_CONTENT_TYPE_ID = "recipe";

export default (props) => {
  const { redux, match, location } = props;
  const { project } = redux;
  const { params } = match;
  const { appId } = params;
  const { environmentCredentials } = project;
  const { sessionEnvironment } = redux.environment;
  const { state } = location;

  const [connections, setConnections] = useState([]);

  const [source, setSource] = useState(null);
  const [dest, setDest] = useState(null);

  const [contentfulTemplates, setContentfulTemplates] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  useEffect(() => {
    contentfulClient
      .getEntries({
        include: 3,
        content_type: RECIPE_CONTENT_TYPE_ID,
        order: "fields.title",
      })
      .then((entries) => {
        let items = [];
        if (entries) {
          entries.items.map((o) => {
            o.fields.template = o.fields.RecipeContent ? o.fields.RecipeContent.replace("```\n", "") : recipeContentnew_plaintext.replace("```\n", "");
            o.fields.template = o.fields.RecipeContent ? o.fields.RecipeContent.replace("\n```", "") : recipeContentnew_plaintext.replace("```\n", "");

            items.push(o);
          });
        }

        setContentfulTemplates(items);
      });
    if (
      environmentCredentials &&
      environmentCredentials.length == 1 &&
      environmentCredentials[0].credentialType == "LocalFile"
    ) {
      let source = environmentCredentials[0];
      setSource(source);
      setConnections([source.credentialType]);
      getContentfulConnectorInfo(source);
    } else if (environmentCredentials) {
      let source = environmentCredentials.find((e) => e.wizard == "isSource");
      let destination = environmentCredentials.find(
        (e) => e.wizard == "isDestination"
      );
      let sd = [];
      if (source) {
        setSource(source);
        sd.push(source.credentialType);
      }
      if (destination) {
        setDest(destination);
        sd.push(destination.credentialType);
      }
      setConnections(sd);
      getContentfulConnectorInfo(source, destination);
    }
    if (state) {
      props.history.push({
        state: {},
      });
    }
  }, []);
  function getContentfulConnectorInfo(source, destination) {
    contentfulClient
      .getEntries({
        include: 3,
        content_type: CONNECTOR_CONTENT_TYPE_ID,
        order: "fields.title",
      })
      .then((entries) => {
        if (entries && entries.items) {
          let s, d;
          let sd = [];
          if (source && source.credentialType) {
            s = entries.items.find(
              (e) => e.fields.providerType == source.credentialType
            );
            if (s) sd.push("https:" + s.fields.logo.fields.file.url);
          }
          if (destination && destination.credentialType) {
            d = entries.items.find(
              (e) => e.fields.providerType == destination.credentialType
            );
            if (d) sd.push("https:" + d.fields.logo.fields.file.url);
          }

          setImageUrls(sd);
        }
      });
  }
  let templateItems = [];
  for (let i = 0; i < contentfulTemplates.length; i++) {
    let included, markSource, markDest;
    if (
      contentfulTemplates[i].fields &&
      contentfulTemplates[i].fields.connectors
    ) {
      for (
        let j = 0;
        j < contentfulTemplates[i].fields.connectors.length;
        j++
      ) {
        let connectorType =
          contentfulTemplates[i].fields.connectors[j].fields.providerType;
        let featuredRecipe = contentfulTemplates[i].fields.featuredRecipe;
        let s, d;
        if (source) {
          s = connectorType === source.credentialType && featuredRecipe;
          if (s) {
            markSource = true;
            included = true;
          }
        }
        if (dest) {
          d = connectorType === dest.credentialType && featuredRecipe;
          if (d) {
            markDest = true;
            included = true;
          }
        }

        if (markSource && markDest) {
          templateItems.push(
            <li
              key={i + Math.random()}
              className="apps-page__builder-item"
              onClick={() =>
                handleSubmit(
                  contentfulTemplates[i].fields.title,
                  contentfulTemplates[i].fields.template
                )
              }
            >
              {contentfulTemplates && contentfulTemplates[i].fields.title}
            </li>
          );
        }
        if (included) {
          templateItems.push(
            <div
              className="connector-cell"
              key={i + Math.random()}
              style={{ display: "block", height: 45 }}
              onClick={() =>
                handleSubmit(
                  contentfulTemplates[i].fields.title,
                  contentfulTemplates[i].fields.template
                )
              }
            >
              <span>
                <b>
                  {" "}
                  {contentfulTemplates && contentfulTemplates[i].fields.title}
                </b>
              </span>
              <br />
              <span style={{ fontSize: 12.5 }}>
                {" "}
                {contentfulTemplates &&
                  contentfulTemplates[i].fields.description}
              </span>
            </div>
          );
        }
      }
    }
  }
  function handleSubmit(recipeName, config) {
    const name = recipeName + ".lr";
    const tag = name;
    let description;
    if (!name) {
      return;
    }
    props.actions
      .callCreateTransformer(
        {
          name,
          config,
          description,
          environ: tag,
          version: 1,
          languageVersion: "2.0",
          processingType: "batch",
        },
        appId
      )
      .then((r) => {
        indexDb.AddRecipe(r);
        props.history.push({
          pathname: `/a/${appId}/tf/${r.BaseId}`,
        });
      });
  }
  return (
    <div className="parent-env">
      <div
        style={{
          top: "12%",
          position: "absolute",
          left: "34%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {connections.map((c, i) => {
            return (
              <Fragment>
                <img
                  src={imageUrls[i]}
                  height="80px"
                  style={{ margin: 10, borderRadius: 6 }}
                />
                {i == 0 && source && dest && (
                  <img
                    src={images.ic_add_grey600_48dp}
                    style={{ height: 45, marginTop: 25 }}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
        {source || dest ? (
          <Fragment>
            <span className="connection-wizard-header">
              {source && (
                <span>
                  {source.connectionName
                    ? source.connectionName
                    : source.providerLabel}{" "}
                </span>
              )}
              {source && dest && <span> and </span>}
              {dest && <span>{dest.connectionName}</span>}
              <span> integrations and automations</span>
            </span>
            <br />
            <div>
              <span
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                Getting Start with one of these recipes
              </span>
              {templateItems && templateItems.length > 0 ? (
                <ul className="apps-page__builder-list">{templateItems}</ul>
              ) : (
                <span>
                  <br />
                  No recipe suggestions available
                </span>
              )}
            </div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <Button
                bsStyle="default"
                onClick={() =>
                  props.history.push({
                    pathname: `/a/${appId}/tf/new`,
                  })
                }
                className="apps-page-buttons"
              >
                Browse recipes
              </Button>
              <Button
                bsStyle="default"
                onClick={() =>
                  props.history.push({
                    pathname: `/a/${appId}/env`,
                  })
                }
                style={{ marginLeft: 10 }}
              >
                Go to environment
              </Button>
            </div>
          </Fragment>
        ) : (
          <span className="wizard-connection-header-no-intg">
            No integration available. Please configure either source or
            destination connection
          </span>
        )}
      </div>
    </div>
  );
};
