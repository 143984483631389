import React from 'react'
import { Modal } from 'react-bootstrap'

const { Body } = Modal

const TemplateHelper = (props) => {
  const { modalState, triggerModal, accountsArray} = props
  return (
    <Modal show={modalState} onHide={triggerModal} dialogClassName="template-modal" backdrop="static">
      <Body>
        <div style={{background: 'rgb(64, 64, 64)', width: 260, color: '#fff', textAlign: 'center',paddingLeft: 20, paddingRight: 20, paddingTop: 6, paddingBottom: 6,}}>
          <h3>Select a project</h3>
          </div>
          <div style={{background: 'rgb(64, 64, 64)', textAlign: 'center',maxHeight: 400, minHeight: 200, paddingTop: 12, paddingBottom: 12,overflowY: 'auto'}}>
          <div style={{width: 260, margin: 'auto', textAlign: 'left'}}>
            {accountsArray}
          </div>
        </div>
      </Body>
    </Modal>
  )
}

export default TemplateHelper
