import React, { useState } from "react";
import { Link } from "react-router-dom";

import HistoryModal from "./dphHistoryModal";
import DPHEditModal from "./dphEditModal";
import CardLicensedPeriod from "./dphCardLicensedPeriod";
import CardMonthly from "./dphCardMonthly";

const DPHSettings = ({ data, notificationFrequency, props }) => {
  const { monthlyDPH, licensedPeriodDPH, tenantDPHAdditionalPurchases } = data;

  const [type, setType] = useState("LicensedPeriod");
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  return (
    <div className="container-fluid">
      {/* when dph is not subscribed */}
      {data && Object.keys(data).length === 0 ? null : (
        <section className="data-block">
          <div className="row">
            {/* yearly limit */}
            <CardLicensedPeriod
              setType={setType}
              data={{"licensedPeriodDPH": licensedPeriodDPH, "tenantDPHAdditionalPurchases": tenantDPHAdditionalPurchases}}
              editModal={editModal}
              setEditModal={setEditModal}
              notificationFrequency={notificationFrequency}
            />
            {/* monthly limit */}
            <CardMonthly
              setType={setType}
              data={monthlyDPH}
              showHistoryModal={showHistoryModal}
              setShowHistoryModal={setShowHistoryModal}
              editModal={editModal}
              setEditModal={setEditModal}
              notificationFrequency={notificationFrequency}
            />
          </div>

          {/* modals */}
          <HistoryModal
            isOpen={showHistoryModal}
            setIsOpen={setShowHistoryModal}
            data={{licensedPeriodDPH:licensedPeriodDPH, monthlyDPH: monthlyDPH}}
          />

          <DPHEditModal
            props={props}
            type={type}
            isOpen={editModal}
            data={data}
            setIsOpen={setEditModal}
            notificationFrequency={notificationFrequency}
          />
        </section>
      )}
    </div>
  );
};

export default DPHSettings;
