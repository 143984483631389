/* eslint-disable */
import ic_settings_white_48dp from "../assets/images/ic_settings_white_48dp.png";
import play from "../assets/images/play-icon.png";
import idea from "../assets/images/noun_Idea_1603446_cropped.png";
import playicon from "../assets/images/play_48dp.png";
import minimize from "../assets/images/ic_arrow_drop_down_white_48dp.png";
import codeicon from "../assets/images/baseline_code_black_48dp.png";
import pauseicon from "../assets/images/pause_48dp.png";
import addbox from "../assets/images/ic_add_box_black_48dp.png";
import addToLibrary from "../assets/images/ic_queue_black_48dp.png";
import schedule from "../assets/images/ic_schedule_white_48dp.png";
import eye from "../assets/images/eye.png";
import webhooks from "../assets/images/ic_new_releases_black_48dp.png";
import chevron_left from "../assets/images/ic_chevron_left_black_48dp.png";
import chevron_right from "../assets/images/ic_chevron_right_black_48dp.png";
import listening from "../assets/images/ic_hearing_black_48dp.png";
import unfold from "../assets/images/ic_unfold_more_black_48dp.png";
import fold from "../assets/images/ic_unfold_less_black_48dp.png";
import cancel_job from "../assets/images/cancel.png";
import cancel_job_black from "../assets/images/cancel_black_24dp.svg";
import start_job from "../assets/images/not_started.png";
import listeningwhite from "../assets/images/ic_hearing_white_48dp.png";
import webhookurl from "../assets/images/ic_link_black_48dp.png";
import database from "../assets/images/database.png";
import done from "../assets/images/ic_done_black_48dp.png";
import streaming from "../assets/images/streaming_48dp.png";
import batch from "../assets/images/batch_48dp.png";
import circle from "../assets/images/ic_yes_white_48dp.png";
import event from "../assets/images/ic_event_white_48dp.png";
import arrow from "../assets/images/ic_arrow_black_48dp.png";
import grid from "../assets/images/ic_grid_on_white_48dp.png";
import warning from "../assets/images/ic_warning_white_48dp.png";
import warningRed from "../assets/images/warning.png";
import Bullet from "../assets/images/bullet.svg";
import ic_view_headline from "../assets/images/ic_view_headline_white_48dp.png";
import edit from "../assets/images/ic_mode_edit_white_48dp.png";
import globe from "../assets/images/ic_globe_black_48dp.png";
import magnifier from "../assets/images/ic_search_white_48dp.png";
import logo_green from "../assets/images/logo-green.png";
import api_ico from "../assets/images/api_ico.png";
import sftp_ico from "../assets/images/sftp_ico.png";
import logo from "../assets/images/logo.svg";
import logo_black from "../assets/images/logo-black.png";
import logo_small from "../assets/images/logo1.svg";
import create from "../assets/images/create.png";
import lingk from "../assets/images/lingk.png";
import description from "../assets/images/description.png";
import descriptiontop from "../assets/images/descriptiontop.png";
import descriptiontopstar from "../assets/images/descriptiontopstar.png";
import piechart from "../assets/images/piechart.png";
import share from "../assets/images/share.png";
import shared from "../assets/images/shared.png";
import assignment from "../assets/images/assignment.png";
import ic_clear_black_48dp from "../assets/images/ic_clear_black_48dp.png";
import ic_settings_grey600_48dp from "../assets/images/ic_settings_grey600_48dp.png";
import ic_expand_more_white_48dp from "../assets/images/ic_expand_more_white_48dp.png";
import ic_view_quilt_white_48dp from "../assets/images/ic_view_quilt_white_48dp.png";
import ic_apps_white_48dp from "../assets/images/ic_apps_white_48dp.png";
import ic_filter_list_white_48dp from "../assets/images/ic_filter_list_white_48dp.png";
import ic_group_work_white_48dp from "../assets/images/ic_group_work_white_48dp.png";
import ic_person_grey600_48dp from "../assets/images/ic_person_grey600_48dp.png";
import ic_help_grey600_48dp from "../assets/images/ic_help_grey600_48dp.png";
import ic_settings_system_daydream_grey600_48dp from "../assets/images/ic_settings_system_daydream_grey600_48dp.png";
import ic_close_grey600_48dp from "../assets/images/ic_close_grey600_48dp.png";
import ic_sort_grey600_48dp from "../assets/images/ic_sort_grey600_48dp.png";
import ic_sort_white_48dp from "../assets/images/ic_sort_white_48dp.png";
import ic_menu from "../assets/images/menu.png";
import ic_list_black_48dp from "../assets/images/ic_list_black_48dp.png";
import ic_vpn_lock_black_48dp from "../assets/images/ic_vpn_lock_black_48dp.png";
import ic_file_upload_black_48dp from "../assets/images/ic_file_upload_black_48dp.png";
import ic_file_download_black_48dp from "../assets/images/ic_file_download_black_48dp.png";
import ic_add_grey600_48dp from "../assets/images/ic_add_grey600_48dp.png";
import ic_add_white from "../assets/images/ic_add_white_48dp.png";
import ic_remove_black from "../assets/images/ic_remove_black_48dp.png";
import ic_web_white_48dp from "../assets/images/ic_web_white_48dp.png";
import ic_web_black_48dp from "../assets/images/ic_web_black_48dp.png";
import ic_delete_white_48dp from "../assets/images/ic_delete_white_48dp.png";
import ftpimport from "../assets/images/ftpimport.png";
import ftpexport from "../assets/images/ftpexport.png";
import ic_settings_system_daydream_black_48dp from "../assets/images/ic_settings_system_daydream_black_48dp.png";
import ic_launch_black_48dp from "../assets/images/ic_launch_black_48dp.png";
import properties from "../assets/images/properties.png";
import ic_mode_edit_black_48dp from "../assets/images/ic_mode_edit_black_48dp.png";
import ic_settings_black_48dp from "../assets/images/ic_settings_black_48dp.png";
import consumed from "../assets/images/consumed.png";
import file_red from "../assets/images/file-red.png";
import file_green from "../assets/images/file-green.png";
import file_yellow from "../assets/images/file-yellow.png";
import file_grey from "../assets/images/file-grey.png";
import zip_red from "../assets/images/zip-red.png";
import zip_green from "../assets/images/zip-green.png";
import zip_yellow from "../assets/images/zip-yellow.png";
import zip_grey from "../assets/images/zip-grey.png";
import ic_help_black_48dp from "../assets/images/ic_help_black_48dp.png";
import copy_env from "../assets/images/copy.png";
import content_copy_black from "../assets/images/content_copy_black_24dp.svg";
import archive_env from "../assets/images/archive.png";
import delete_env from "../assets/images/delete.png";
import delete_env_var from "../assets/images/deleteBlack.png";
import checkbox_connector from "../assets/images/checkbox.svg";
import refresh_metadeta from "../assets/images/refresh.png";
import compare_file from "../assets/images/compare-24px.svg";
//gifs
import spinner from "../assets/images/light_blue_material_design_loading.gif";
import ajax_loader from "../assets/images/ajax_loader.gif";
import ajax_loader_small from "../assets/images/ajax-loader-small.gif";
import dash_rings from "../assets/images/dashboardloader.gif";

//integration icons
import PeopleSoft from "../assets/images/source-logo-peoplesoft.png";
import Banner from "../assets/images/source-logo-banner.png";
import Colleague from "../assets/images/source-logo-colleague.png";
import OneRoster from "../assets/images/source-logo-oneroster.png";
import Salesforce from "../assets/images/source-logo-salesforce.png";
import Canvas from "../assets/images/source-logo-canvas.png";
// import "REST API" from '../assets/images/source-logo-cloud.png';
// import 'Flat File' from '../assets/images/source-logo-file.png';
import CSV from "../assets/images/source-logo-file.png";
import BNED from "../assets/images/source-logo-bned.png";
import Skyfactor from "../assets/images/source-logo-skyfactor.png";
import Blackboard from "../assets/images/source-logo-blackboard.png";
import Oohlala from "../assets/images/source-logo-oohlala.png";
import Lingk from "../assets/images/logo-green.png";
import LingkAdapter from "../assets/images/logo-green.png";
import Ethos from "../assets/images/source-logo-ethos.png";
import PowerBI from "../assets/images/source-logo-powerbi.png";
import RedShift from "../assets/images/source-logo-redshift.png";
import Caliper from "../assets/images/source-logo-caliper.png";
import Email from "../assets/images/source-logo-email.png";
import FlatFile from "../assets/images/source-logo-file.png";
import LocalFile from "../assets/images/source-logo-file.png";
import BigQuery from "../assets/images/source-logo-bigquery.png";
import SFTP from "../assets/images/source-logo-sftp.png";
import Moodle from "../assets/images/source-logo-moodle.png";
import DocuSign from "../assets/images/docusign_1.png";
import SyncArrow from "../assets/images/sync-arrow.png";
import SaveFile from "../assets/images/save-24px.svg";
import StartJob from "../assets/images/not_started-24px.svg";
import Schedule from "../assets/images/schedule-24px.svg";
import ExecutionHistory from "../assets/images/restore-24px.svg";
import ExecutionHistoryTab from "../assets/images/restore-white-18dp.svg";
import Undo from "../assets/images/undo-24px.svg";
import Redo from "../assets/images/redo-24px.svg";
import MoreOptions from "../assets/images/more_vert-24px.svg";
import PicInPic from "../assets/images/picInpic.png";
import RecipeBarAdd from "../assets/images/side-bar-add.svg";
import OpenInNew from "../assets/images/open_in_new_black_24dp.svg";
import GoBack from "../assets/images/arrow_back_ios_black_24dp.svg";
import GoBackLeft from "../assets/images/go-back-left.png";
import BlackDropdown from "../assets/images/arrow_drop_down_black_24dp.svg";
import ExpandLess from "../assets/images/expand_less_black_24dp.svg";
import ExpandMore from "../assets/images/expand_more_black_24dp.svg";
import Brightspace from "../assets/images/Brightspace.png";
import NoAccess from "../assets/images/no-access.png";
import ArrowForward from "../assets/images/arrow_forward_black_24dp.svg";
import SchemaInPic from "../assets/images/schema_black_24dp.svg";
import Comments from "../assets/images/comments_disabled_black_24dp.svg";
import Tags from "../assets/images/tag-fill.svg";
import Certificate from "../assets/images/certificate.svg";
import RecipeLib from "../assets/images/recipe-lib.svg";
import Chat from "../assets/images/live-chat.svg";
import Zoom from "../assets/images/zoom.png";
import Swagger from "../assets/images/swagger.png";


const images = {
  Comments,
  SchemaInPic,
  ArrowForward,
  GoBackLeft,
  NoAccess,
  Brightspace,
  Bullet,
  ExpandLess,
  ExpandMore,
  BlackDropdown,
  OpenInNew,
  GoBack,
  RecipeBarAdd,
  PicInPic,
  ExecutionHistoryTab,
  Undo,
  Redo,
  logo_black,
  play,
  logo_small,
  idea,
  playicon,
  minimize,
  codeicon,
  pauseicon,
  addbox,
  addToLibrary,
  schedule,
  Schedule,
  ExecutionHistory,
  eye,
  webhooks,
  chevron_left,
  chevron_right,
  listening,
  unfold,
  fold,
  cancel_job,
  cancel_job_black,
  start_job,
  listeningwhite,
  webhookurl,
  database,
  done,
  streaming,
  batch,
  circle,
  event,
  arrow,
  grid,
  warning,
  warningRed,
  ic_view_headline,
  edit,
  globe,
  magnifier,
  logo_green,
  api_ico,
  sftp_ico,
  logo,
  create,
  lingk,
  description,
  descriptiontop,
  descriptiontopstar,
  piechart,
  share,
  shared,
  assignment,
  ic_clear_black_48dp,
  ic_settings_white_48dp,
  ic_settings_grey600_48dp,
  ic_expand_more_white_48dp,
  ic_view_quilt_white_48dp,
  ic_apps_white_48dp,
  ic_filter_list_white_48dp,
  ic_group_work_white_48dp,
  ic_person_grey600_48dp,
  ic_help_grey600_48dp,
  ic_settings_system_daydream_grey600_48dp,
  ic_close_grey600_48dp,
  ic_sort_grey600_48dp,
  ic_sort_white_48dp,
  ic_list_black_48dp,
  ic_menu,
  Tags,
  Certificate,
  RecipeLib,
  Chat,
  ic_vpn_lock_black_48dp,
  ic_file_upload_black_48dp,
  ic_file_download_black_48dp,
  ic_add_grey600_48dp,
  ic_add_white,
  ic_remove_black,
  ic_web_white_48dp,
  ic_web_black_48dp,
  ic_delete_white_48dp,
  ftpimport,
  ftpexport,
  ic_settings_system_daydream_black_48dp,
  ic_launch_black_48dp,
  properties,
  ic_mode_edit_black_48dp,
  ic_settings_black_48dp,
  consumed,
  file_red,
  file_green,
  file_yellow,
  file_grey,
  zip_red,
  zip_green,
  zip_yellow,
  zip_grey,
  ic_help_black_48dp,
  copy_env,
  content_copy_black,
  archive_env,
  delete_env,
  delete_env_var,
  checkbox_connector,
  refresh_metadeta,
  compare_file,
  //gifs
  spinner,
  ajax_loader,
  ajax_loader_small,
  dash_rings,

  //integration icons
  PeopleSoft,
  Banner,
  Colleague,
  OneRoster,
  Salesforce,
  Canvas,
  // 'REST API',
  // 'Flat File' ,
  CSV,
  BNED,
  Skyfactor,
  Blackboard,
  Oohlala,
  Lingk,
  LingkAdapter,
  Ethos,
  PowerBI,
  RedShift,
  Caliper,
  Email,
  FlatFile,
  LocalFile,
  BigQuery,
  SFTP,
  Moodle,
  DocuSign,
  SyncArrow,
  SaveFile,
  StartJob,
  MoreOptions,
  Zoom,
  Swagger
};

export default images;
