import React, { Component } from "react";
import { Modal, Button, FormControl } from "react-bootstrap";

import images from "../images";

const { Header, Body, Footer } = Modal;
const queryString = require("query-string");

export default class CreateFirstTenant extends Component {
  constructor() {
    super();
    this.state = {
      opened: true,
      orgInput: "",
      creatingOrg: false,
      appInput: "",
      creatingApp: false,
      selectedTenant: null,
    };
  }

  componentDidMount() {
    const { tenantList } = this.props;
    if (tenantList.length === 1) {
      this.setState({ selectedTenant: tenantList[0] });
    }
  }

  submit = () => {
    const { accessLevels } = this.props.redux.main;
    const { tenantList, actions } = this.props;
    if (!tenantList.length > 0) {
      this.setState({ creatingOrg: true });
      const userProfile = JSON.parse(localStorage.getItem("profile"));
      const accessKey =
        userProfile.user_metadata && userProfile.user_metadata.access;
      let level = 1;
      if (accessKey) {
        const accessLevel = accessLevels.find(
          (al) => accessKey === al.accessKey
        );
        if (accessLevel) {
          level = accessLevel.level;
        }
      }
      this.props.actions
        .callCreateNewTenant(this.state.orgInput, level)
        .then((t) => {
          this.setState({ creatingOrg: false, selectedTenant: t[0] });
          this.props.actions.callGetUsers();
          this.workspaceinput.focus();
        });
    } else {
      this.setState({ creatingApp: true });
      // if tenant already exists, and the form start on workspace
      // just add them to tenant 0, as this is probably a new user with a single tenant
      actions
        .callCreateApp(this.state.selectedTenant.TenantId, {
          name: this.state.appInput,
          description: this.state.appDescription,
        })
        .then((a) => {
          this.setState({ creatingApp: false });
          const parsed = queryString.parse(this.props.location.search);
          if (parsed && parsed.template) {
            this.props.history.push({
              pathname: `/a/${a[0].Id}/tf/new`,
              state: {
                template: parsed.template,
              },
            });
          } else {
            actions
              .callGetEnvironments(this.state.selectedTenant.TenantId, a[0].Id)
              .then((resp) => {
                this.props.history.push({
                  pathname: `/`,
                  state: {
                    showFirstTimeUserExp: true,
                  },
                });
              });
          }
        });
    }
  };

  render() {
    const { tenantList } = this.props;
    const isTenants = tenantList.length > 0;
    const { selectedTenant } = this.state;
    return (
      <div>
        <Modal show={this.state.opened} backdrop="static">
          <Header>
            <Modal.Title>
              {isTenants ? (
                <span>
                  {selectedTenant && (
                    <strong>{`${selectedTenant.Name}: `}</strong>
                  )}
                  <span>New Project</span>
                </span>
              ) : (
                <span>Your Organization</span>
              )}
            </Modal.Title>
          </Header>
          <Body>
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                height: tenantList.length > 1 ? 225 : 175,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "200%",
                  transform: `translateX(${isTenants ? "-50%" : 0})`,
                  transition: "all .35s",
                }}
              >
                <div
                  style={{ padding: 20, display: "inline-block", width: "50%" }}
                >
                  <div>
                    Lingk Organizations are used for managing team members and
                    organizing Projects.
                    <br />
                    <br />
                  </div>
                  <label
                    htmlFor="org"
                    className="labelz"
                    style={{ marginBottom: 5 }}
                  >
                    Choose a Name for your Organization
                  </label>
                  <FormControl
                    type="text"
                    tabIndex={this.state.orgInput === "" ? "" : "-1"}
                    value={this.state.orgInput}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (
                        val === "" ||
                        /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(val)
                      ) {
                        this.setState({ orgInput: val });
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    padding: 20,
                    display: "inline-block",
                    width: "50%",
                    verticalAlign: "top",
                  }}
                >
                  {tenantList.length > 1 && (
                    <div>
                      <select
                        className="form-control"
                        name="app"
                        value={
                          selectedTenant ? String(selectedTenant.TenantId) : ""
                        }
                        onChange={(e) => {
                          const t = tenantList.find(
                            (t) => t.TenantId === parseInt(e.target.value, 10)
                          );
                          this.setState({ selectedTenant: t });
                        }}
                      >
                        <option disabled>Select an Organization</option>
                        {tenantList.map((t) => {
                          return (
                            <option value={t.TenantId} key={t.TenantId}>
                              {t.Name}
                            </option>
                          );
                        })}
                      </select>
                      <br />
                    </div>
                  )}

                  <label
                    htmlFor="workspace"
                    className="labelz"
                    style={{ marginBottom: 5 }}
                  >
                    Create a new Project
                  </label>
                  <FormControl
                    type="text"
                    inputRef={(r) => (this.workspaceinput = r)}
                    tabIndex="-1"
                    value={this.state.appInput}
                    placeholder="Project Name"
                    onChange={(e) => {
                      const val = e.target.value;
                      if (
                        val === "" ||
                        /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(val)
                      ) {
                        this.setState({ appInput: val });
                      }
                    }}
                  />
                  <br />
                  <FormControl
                    componentClass="textarea"
                    tabIndex={this.state.appInput === "" ? "-1" : ""}
                    style={{ width: "100%" }}
                    placeholder="Project Description (optional)"
                    value={this.state.appDescription}
                    onChange={(e) => {
                      this.setState({ appDescription: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
          </Body>
          <Footer>
            <Button
              bsStyle="primary"
              onClick={this.submit}
              style={{ width: 60 }}
              disabled={
                (!this.state.orgInput && !isTenants) ||
                this.state.creatingOrg ||
                (isTenants && (!this.state.appInput || !selectedTenant))
              }
            >
              {!this.state.creatingOrg && !this.state.creatingApp ? (
                <span>{isTenants ? "Done" : "Next"}</span>
              ) : (
                <img
                  alt="small-spinner"
                  src={images.ajax_loader_small}
                  height="8"
                />
              )}
            </Button>
          </Footer>
        </Modal>
      </div>
    );
  }
}
