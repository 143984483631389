import React, { Component } from 'react'
import images from '../images'

export default class WizardSelector extends Component {
  render() {
    const { name, selectData, isSelected, obj, showModal } = this.props
    return (
      <div>
        <div
          onClick={e => selectData(null, obj, e)}
          style={isSelected ? styles.selectedbox : styles.box}
          className="transformer-box-list">
          <img
            role="presentation"
            src={images.ic_web_black_48dp}
            style={styles.icon}
          />
          <span style={styles.name}>{name}</span>
          <img
            style={styles.img}
            onClick={() => showModal(null)}
            className="recipe-view-preview"
            role="presentation"
            src={images.magnifier}
          />
        </div>
      </div>
    )
  }
}


const styles = {
  box: {
    display: 'inline-block',
    width: '300px',
    borderRadius: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    position: 'relative',
  },
  selectedbox: {
    display: 'inline-block',
    width: '300px',
    borderRadius: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    position: 'relative',
    transform: 'translateX(40px)',
    background: '#fff',
  },
  alreadyincluded: {
    display: 'inline-block',
    paddingBottom: 5,
    padding: 12,
    background: '#bbb',
    width: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    position: 'relative',
    height: '40px'
  },
  name: {
    position: 'absolute',
    top: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '80%'
  },
  alreadyincludedname: {
    position: 'absolute',
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '80%'
  },
  icon: {
    float: 'left',
    width: 20,
    height: 20,
    display: 'block',
    marginRight: '4px',
    marginLeft: 4,
    marginTop: 6
  },
  modalicon: {
    float: 'left',
    height: 40,
    display: 'block',
    marginRight: 4,
    marginTop: -7
  },
  img: {
    position: 'absolute',
    filter: 'invert(100%)',
    width: 20,
    right: -44,
    top: 8
  }
}
