import React, { Component } from "react";
import UserSettings from "./usersettings";
import SubscriptionSettings from "./subscriptionSettings";
import SubscriptionEdit from "./subscriptionEdit";
import SubscriptionBilling from "./subscriptionBilling";
import MyInvoices from "./myInvoices";
import PurchaseServices from "../recurlySubscription/purchaseServices";
import images from "../images";
import ConfirmDeleteModal from "../utils/confirmDeleteModal";
import InActiveTenant from "../../components/main/inActiveTenant";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";
import newThemes from "../recipe/editorThemes";
import DataProfileFieldStyle from "../../styles/dataprofilefieldstyle";
import * as indexDb from "../../db/recipesDOA";

import {
  Button,
  DropdownButton,
  Dropdown,
  MenuItem,
  Tabs,
  Tab,
  Modal,
} from "react-bootstrap";
import ProjectSettings from "./projectSettings";
import { hours, minutes } from "../../data/time";
import DPHSettings from "./dphSettings";
const orgOptions = [
  { title: "Organization Users" },
  { title: "Project Users" },
  // { title: "Subscriptions" },
  { title: "Data Processing Hours" },
  // { title: "Threshold" },
  // {title: 'Services'},
];

const deletemessage =
  "Projects created by this organization will be downgraded to limited projects. You may lose access to work within subscription-exclusive features.";

const UserOrgsWrap = (props) => {
  const { redux } = props;
  const { main } = redux;
  if (main.me && !main.me.isActive) {
    return (
      <div style={{ textAlign: "center", marginTop: 80 }}>
        The User is no longer active.
        <br />
        Contact your organization administrator to regain access to projects.{" "}
        <br />
        For additional help, please contact Lingk support using the "Help" tab
        on the right of the screen.
      </div>
    );
  }
  if (!main.tenantList || !main.me || !main.users) {
    return (
      <div className="ajax-loader">
        <img alt="spinner" src={images.ajax_loader} />
      </div>
    );
  }
  var activeTenantList = new Array();
  if (main.tenantList && main.tenantList.length > 0) {
    for (var tenant of main.tenantList) {
      if (tenant.isActive) {
        activeTenantList.push(tenant);
      }
    }
    if (activeTenantList.length == 0) {
      return (
        <div>
          <InActiveTenant />
        </div>
      );
    }
  }
  return <UserOrgs {...props} tenantList={activeTenantList} />;
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = (state) => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserOrgsWrap);
class UserOrgs extends Component {
  constructor() {
    super();
    this.state = {
      selectedTenantId: null,
      tenantInfo: null,
      savingDPT: false,
      threasholdHours: "00",
      threasholdMinutes: "00",
      isAdminInTenant: false,
      showNewOrg: false,
      billing: null,
      recurlyPlans: null,
      selectedOption: "Organization Users",
      subscription: null,
      redemptions: null,
      subMode: null,
      subAddons: null,
      modal: false,
      deleting: false,
      reactivating: false,
      curTheme: null,
    };
  }

  componentWillMount() {
    window.localStorage.setItem("notIndexed", true);
    const { redux, actions, location, tenantList } = this.props;
    const queryParams = new URLSearchParams(location.search);
    let subscriptionId = queryParams.get("subscription");
    let targetTenant = tenantList.find((t) => t.Key == subscriptionId);

    const { main } = redux;

    const redemptions = [];
    if (window.localStorage.getItem("recipeTheme")) {
      this.setState({ curTheme: window.localStorage.getItem("recipeTheme") });
    }
    if (subscriptionId && subscriptionId != "" && targetTenant) {
      this.setState({
        selectedTenantId: targetTenant.TenantId,
        isAdminInTenant: targetTenant.IdentityIsAdmin,
        selectedOption: "Subscriptions",
      });
      this.props.actions.callGetTenantInfo(targetTenant.TenantId).then((ti) => {
        this.setState({ tenantInfo: ti[0] });
      });

      // get threashold
      const threasholdHours = this.getThreasholdHours(
        this.state.tenantInfo.maxDataProcTime
      );

      const threasholdMinutes = this.getThreasholdMinutes(
        this.state.tenantInfo.maxDataProcTime
      );

      this.setState({
        threasholdHours,
        threasholdMinutes,
      });

      //get recurly plans
      actions.getRecurlyPlans().then((resp) => {
        const data = [];
        resp.map((p) => {
          if (!p.Code.includes("test")) {
            data.push(p);
          }
        });

        this.setState({ recurlyPlans: data });
      });

      //if user is admin, grab recurly sub
      if (targetTenant.IdentityIsAdmin) {
        //set Subscription Tab as default
        actions.getRecurlySubByAccount(targetTenant.Key).then((sub) => {
          if (sub[0].plan) {
            //if theres a sub
            if (location.hash.includes("subscription")) {
              this.setState({ selectedOption: "Subscriptions" });
            } else if (location.hash.includes("billing")) {
              this.setState({
                selectedOption: "Subscriptions",
                subMode: "billing",
              });
            }

            //get billing
            actions.getRecurlyBilling(sub[0].accountCode).then((b) => {
              this.setState({ billing: b });
            });

            const planCode = sub[0].plan.Code;
            actions.getRecurlyAddons(planCode).then((o) => {
              this.setState({ subscription: sub[0], subAddons: o });
            });

            actions.getRecurlyRedemptions(targetTenant.Key).then((r) => {
              if (Array.isArray(r) && r.length > 0) {
                r.map((i) => {
                  if (i.subUUID === sub[0].uuid) {
                    actions.getRecurlyCoupon(i.couponCode).then((c) => {
                      redemptions.push(c);
                    });
                  }
                });
                this.setState({ redemptions });
              }
            });
          } else {
            this.setState({ subscription: sub[0] });
          }
        });
      }
    } else if (tenantList.length > 0) {
      this.setState({
        selectedTenantId: tenantList[0].TenantId,
        isAdminInTenant: tenantList[0].IdentityIsAdmin,
      });

      // get licensedPeriod dph
      this.props.actions.getActiveTenantDph(tenantList[0].TenantId);

      // get notification frequencies
      this.props.actions.getNotificationFreqeuncies();

      this.props.actions
        .callGetTenantInfo(tenantList[0].TenantId)
        .then((ti) => {
          this.setState({ tenantInfo: ti[0] });
        });

      //get recurly plans
      actions.getRecurlyPlans().then((resp) => {
        const data = [];
        resp.map((p) => {
          if (!p.Code.includes("test")) {
            data.push(p);
          }
        });

        this.setState({ recurlyPlans: data });
      });

      //if user is admin, grab recurly sub
      if (tenantList[0].IdentityIsAdmin) {
        //set Subscription Tab as default
        actions.getRecurlySubByAccount(tenantList[0].Key).then((sub) => {
          if (sub[0].plan) {
            //if theres a sub
            if (location.hash.includes("subscription")) {
              this.setState({ selectedOption: "Subscriptions" });
            } else if (location.hash.includes("billing")) {
              this.setState({
                selectedOption: "Subscriptions",
                subMode: "billing",
              });
            }

            //get billing
            actions.getRecurlyBilling(sub[0].accountCode).then((b) => {
              this.setState({ billing: b });
            });

            const planCode = sub[0].plan.Code;
            actions.getRecurlyAddons(planCode).then((o) => {
              this.setState({ subscription: sub[0], subAddons: o });
            });

            actions.getRecurlyRedemptions(tenantList[0].Key).then((r) => {
              if (Array.isArray(r) && r.length > 0) {
                r.map((i) => {
                  if (i.subUUID === sub[0].uuid) {
                    actions.getRecurlyCoupon(i.couponCode).then((c) => {
                      redemptions.push(c);
                    });
                  }
                });
                this.setState({ redemptions });
              }
            });
          } else {
            this.setState({ subscription: sub[0] });
          }
        });
      }
    }
  }

  updateDPT = (tenantId) => {
    const { redux } = this.props;
    const { project } = redux;
    const { actions } = this.props;
    this.setState({ savingDPT: true });
    this.setState({ savingDPT: false });
    actions
      .callUpdateDPTApplication(this.state.selectedTenantId, {
        threasholdHours: this.state.threasholdHours,
        threasholdMinutes: this.state.threasholdMinutes,
      })
      .then(() => {
        this.setState({ savingDPT: false });
      });
  };

  getThreasholdHours(date) {
    if (date) {
      const time = date.split("T")[1];
      const hours = time.split(":")[0];
      return hours;
    } else return "00";
  }

  getThreasholdMinutes(date) {
    if (date) {
      const time = date.split("T")[1];
      const minutes = time.split(":")[1];
      return minutes;
    } else return "00";
  }

  liftSub = (sub) => {
    this.setState({ subscription: sub });
  };

  liftBilling = (billing) => {
    this.setState({ billing });
  };

  clearSub = () => {
    this.setState({
      tenantInfo: null,
      subAddons: null,
      subMode: null,
      subscription: null,
      redemptions: null,
      billing: null,
    });
  };

  switchTenant = (tenantId, isAdmin) => {
    this.setState({ selectedOption: "Organization Users" });
    const { actions } = this.props;
    const redemptions = [];

    this.clearSub();

    // get licensedPeriod dph
    this.props.actions.getActiveTenantDph(tenantId);

    actions.callGetTenantInfo(tenantId).then((ti) => {
      this.setState({ tenantInfo: ti[0] });
      if (isAdmin) {
        actions.getRecurlySubByAccount(ti[0].Key).then((sub) => {
          if (sub[0].plan) {
            //if theres a sub
            const planCode = sub[0].plan.Code;
            actions.getRecurlyAddons(planCode).then((o) => {
              this.setState({ subscription: sub[0], subAddons: o });
            });
            //get billing
            actions.getRecurlyBilling(sub[0].accountCode).then((b) => {
              this.setState({ billing: b });
            });
          } else {
            this.setState({ subscription: sub[0] });
          }

          // recipe threashold
          // const threasholdHours = this.getThreasholdHours(
          //   this.state.tenantInfo.maxDataProcTime
          // );

          // const threasholdMinutes = this.getThreasholdMinutes(
          //   this.state.tenantInfo.maxDataProcTime
          // );

          // this.setState({
          //   threasholdHours,
          //   threasholdMinutes,
          // });

          actions.getRecurlyRedemptions(ti[0].Key).then((r) => {
            if (Array.isArray(r) && r.length > 0) {
              r.map((i) => {
                if (i.subUUID === sub[0].uuid) {
                  actions.getRecurlyCoupon(i.couponCode).then((c) => {
                    redemptions.push(c);
                  });
                }
              });
              this.setState({ redemptions });
            }
          });
        });
      } else {
        this.setState({ selectedOption: "Organization Users" });
      }
    });

    this.setState({
      selectedTenantId: tenantId,
      isAdminInTenant: isAdmin,
    });
  };

  triggerModal = () => {
    let modal = this.state.modal;
    modal = !modal;
    this.setState({ modal });
  };

  terminateSubscription = () => {
    const { selectedTenantId, isAdminInTenant } = this.state;

    this.setState({ deleting: true });
    this.props.actions
      .cancelRecurlySubscription(this.state.subscription.uuid)
      .then((o) => {
        if (o) {
          this.setState({ modal: false, deleting: false });
          this.subMode(null);
          this.switchTenant(selectedTenantId, isAdminInTenant);
        } else {
          this.setState({ modal: false, deleting: false });
          this.subMode(null);
          alert(
            "Subscription cancel failed. Please reload the page and try again."
          );
        }
      });
  };

  reactivateSubscription = () => {
    const { selectedTenantId, isAdminInTenant } = this.state;

    this.setState({ reactivating: true });
    this.props.actions
      .reactivateRecurlySubscription(this.state.subscription.uuid)
      .then((o) => {
        if (o) {
          this.setState({ reactivating: false });
          this.subMode(null);
          this.switchTenant(selectedTenantId, isAdminInTenant);
        } else {
          this.setState({ reactivating: false });
          this.subMode(null);
          alert(
            "Subscription reactivation failed. Please reload the page and try again."
          );
        }
      });
  };
  changeTheme(k, v) {
    this.setState({ curTheme: v });
    window.localStorage.setItem("recipeTheme", v);
  }
  reactivateExpiredSubscription = () => {
    const { subscription, selectedTenantId, isAdminInTenant } = this.state;

    this.setState({ reactivating: true });
    //send to sub creation route that also replaces the tenant uuid
    this.props.actions
      .reactivateExpiredRecurlySubscription(subscription.uuid)
      .then((o) => {
        if (o === "no billing") {
          this.setState({ reactivating: false });
          alert("Please update your billing information.");
        } else {
          this.setState({ reactivating: false });
          this.subMode(null);
          this.switchTenant(selectedTenantId, isAdminInTenant);
        }
      });
  };

  subMode = (mode) => {
    const { subMode } = this.state;
    if (subMode === mode) {
      this.setState({ subMode: null });
      return;
    }
    this.setState({ subMode: mode });
  };

  render() {
    const { redux, tenantList } = this.props;
    const { main } = redux;
    const { project, appList } = main;

    let defaultAppId = null; 

    if (appList && appList.length) {
      defaultAppId = appList[0].Id;
    }
    
    if (project && project.appData) {
      indexDb.DeleteProjectRecipes(main.project.appData.Id);
    }
    let themeOptions = [];
    Object.keys(newThemes).forEach((t, i) => {
      themeOptions.push(
        <MenuItem
          eventKey={i}
          key={i}
          className={DataProfileFieldStyle.linkStyles}
          active={this.state.curTheme === t}
          onClick={() => this.changeTheme(t, newThemes[t])}
        >
          <strong>{newThemes[t]}</strong>
        </MenuItem>
      );
    });
    const {
      selectedOption,
      subMode,
      subscription,
      subAddons,
      redemptions,
      selectedTenantId,
      isAdminInTenant,
      recurlyPlans,
      deleting,
      reactivating,
      billing,
      tenantInfo,
    } = this.state;
    const tenantUsers =
      selectedTenantId &&
      main.users.filter((u) => {
        return u.tenantid === selectedTenantId;
      });
    return (
      <div
        className="form-horizontal"
        style={{ height: "calc(100% - 60px)", overflowY: "scroll" }}
      >
        <div className="users-sidebar">
          <div className="users-sidebar-title">User</div>
          <div
            className="user-tenant-tab"
            onClick={() =>
              this.setState({
                selectedOption: "themes",
                selectedTenantId: null,
              })
            }
          >
            <div
              className={
                selectedOption == "themes" ? "user-tenant-tab-selected" : ""
              }
              style={{ marginTop: 5 }}
              key={"themes"}
            >
              Themes
            </div>
          </div>
          <div className="users-sidebar-title" style={{ marginTop: 20 }}>
            Organizations
            <a href="https://www.lingk.io/requestdemo">
              <img
                src={images.RecipeBarAdd}
                style={{ marginRight: 4, float: "right", height: 27 }}
              />
            </a>
          </div>
          <div className="user-tenant-tabs">
            {tenantList &&
              tenantList.map((t) => {
                const selected = selectedTenantId === t.TenantId;
                return (
                  <div
                    className={`user-tenant-tab${
                      selected ? " user-tenant-tab-selected" : ""
                    }`}
                    onClick={() =>
                      this.switchTenant(t.TenantId, t.IdentityIsAdmin)
                    }
                    key={t.TenantId}
                  >
                    {t.Name}
                  </div>
                );
              })}
          </div>
          {/*<div style={{paddingTop:30,paddingLeft:16}}>
          {this.state.showNewOrg ? 
          <CreateNewOrg {...this.props} hide={()=>this.setState({showNewOrg:false})} 
            created={(t)=>this.setState({
              showNewOrg:false,
              selectedTenantId:t.TenantId
            })} /> :
          <Button onClick={()=>this.setState({showNewOrg:true})}
            bsStyle="primary" bsSize="small">
            New Organization
          </Button>}
        </div>*/}
        </div>
        {selectedOption == "themes" && (
          <div className="users-list-wrap" style={{ marginTop: 25 }}>
            <div style={{}} className="dropdown-button-wrap">
              <strong className="labelz" style={{ fontSize: "11px" }}>
                Select a Theme
              </strong>
              <br />
              <DropdownButton
                title={this.state.curTheme || "Default"}
                bsSize="small"
                pullRight
                bsStyle="default"
                style={{
                  background: "#ddd",
                  border: "1px solid #666",
                  width: 230,
                }}
                id="themes"
              >
                {themeOptions}
              </DropdownButton>
            </div>
          </div>
        )}
        {tenantUsers && (
          <div className="users-list-wrap" style={{ marginTop: 25 }}>
            <div
              className="user-tenant-option-tabs"
              style={{ marginLeft: 10, marginBottom: -6 }}
            >
              {orgOptions.map((o, i) => {
                const selected = this.state.selectedOption === o.title;

                if (o.title === "Subscriptions" && !isAdminInTenant) {
                  return;
                }

                if (o.title === "Threashold" && !isAdminInTenant) {
                  return;
                }

                if (o.title === "Data Processing Hours" && !isAdminInTenant) {
                  return;
                }

                if (
                  o.title === "Data Processing Hours" &&
                  Object.keys(main.dataProcessingHours).length === 0
                ) {
                  return;
                }

                return (
                  <div
                    className={`user-tenant-tab${
                      selected ? " user-tenant-option-tab-selected" : ""
                    }`}
                    style={{
                      background: selected ? "#eee" : null,
                      display: "inline-block",
                      fontWeight: selected ? "bold" : null,
                    }}
                    onClick={() => {
                      this.setState({
                        selectedOption: o.title,
                        subMode: null,
                      });
                    }}
                    key={i}
                  >
                    {o.title}
                  </div>
                );
              })}
            </div>

            {selectedOption === "Subscriptions" && isAdminInTenant && (
              <div className="sub-console-wrapper">
                {/* sub summary main */}
                <div className="subscription-list">
                  <SubscriptionSettings
                    {...this.props}
                    users={tenantUsers}
                    subscription={subscription}
                    subAddons={subAddons}
                    redemptions={redemptions}
                    reactivating={reactivating}
                    reactivateExpiredSubscription={
                      this.reactivateExpiredSubscription
                    }
                    reactivateSubscription={this.reactivateSubscription}
                    isAdmin={isAdminInTenant}
                    tenantId={selectedTenantId}
                    subMode={this.subMode}
                    subModeSelected={this.state.subMode}
                    {...this.props}
                    tenantInfo={tenantInfo}
                  />
                </div>
                {/* sub edit */}
                {subMode === "edit" && (
                  <div
                    className="subscription-list"
                    style={{ background: "#fff" }}
                  >
                    <SubscriptionEdit
                      switchTenant={this.switchTenant}
                      recurlyPlans={recurlyPlans}
                      subscription={subscription}
                      redemptions={redemptions}
                      isAdmin={isAdminInTenant}
                      tenantId={selectedTenantId}
                      billing={billing}
                      liftBilling={this.liftBilling}
                      subMode={this.subMode}
                      {...this.props}
                    />
                  </div>
                )}

                {/* billing edit */}
                {subMode === "billing" && (
                  <div
                    className="subscription-list"
                    style={{ background: "#fff" }}
                  >
                    <SubscriptionBilling
                      subscription={subscription}
                      subMode={this.subMode}
                      billing={billing}
                      liftBilling={this.liftBilling}
                      {...this.props}
                    />
                  </div>
                )}

                {/* services summary */}
                {subMode === "services" && (
                  <div
                    className="subscription-list"
                    style={{ background: "#fff" }}
                  >
                    <PurchaseServices
                      subscription={subscription}
                      subMode={this.subMode}
                      recurlyPlans={recurlyPlans}
                      billing={billing}
                      liftBilling={this.liftBilling}
                      {...this.props}
                    />
                  </div>
                )}

                {/* invoices */}
                {subMode === "invoices" && (
                  <div
                    className="subscription-list"
                    style={{ background: "#fff" }}
                  >
                    <MyInvoices
                      subscription={subscription}
                      subMode={this.subMode}
                      recurlyPlans={recurlyPlans}
                      billing={billing}
                      {...this.props}
                    />
                  </div>
                )}

                {subscription &&
                  subscription.state &&
                  subscription.state === "active" && (
                    <div style={{ float: "right" }}>
                      <div
                        className="cancel-sub"
                        style={{
                          marginRight: 10,
                          marginBottom: 100,
                          display: "inline-block",
                        }}
                        onClick={() => this.triggerModal()}
                      >
                        Cancel Subscription
                      </div>
                    </div>
                  )}
              </div>
            )}

            {/* data procession hours */}
            {selectedOption === "Data Processing Hours" && isAdminInTenant && (
              <div className="threashold-list">
                {main.dphLoading ? (
                  <div style={{ display: "flex" }}>
                    <span
                      style={{
                        marginTop: "20px",
                        fontSize: "18px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      {/*loading...*/}
                      <img alt="spinner" src={images.ajax_loader} />
                    </span>
                  </div>
                ) : (
                  <DPHSettings
                    props={this.props}
                    notificationFrequency={main.notificationFrequencies}
                    data={main.dataProcessingHours}
                  />
                )}
              </div>
            )}

            {/* {selectedOption === "Threashold" && isAdminInTenant && (
              <div className="threashold-list">
                // threashold 
                <div>
                  <label>Recipe Duration Notification</label>
                  <br />
                  <div style={{ display: "flex", gap: 20 }}>
                    <div>
                      Hours
                      <select
                        name="thresholdHours"
                        id="hours"
                        disabled={!isAdminInTenant}
                        value={this.state.threasholdHours}
                        onChange={(e) => {
                          this.setState({ threasholdHours: e.target.value });

                          if (e.target.value == 12) {
                            this.setState({ threasholdHours: "00" });
                          }
                        }}
                        className="form-control"
                        style={{ width: 100 }}
                      >
                        {hours.map((value, index) => (
                          <option value={value} key={index}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      Minutes
                      <select
                        name="thresholdMinutes"
                        id="minutes"
                        disabled={!isAdminInTenant}
                        value={this.state.threasholdMinutes}
                        onChange={(e) =>
                          this.setState({ threasholdMinutes: e.target.value })
                        }
                        className="form-control"
                        style={{ width: 100 }}
                      >
                        {minutes.map((value, index) => (
                          <option value={value} key={index}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <br />
                <Button
                  bsSize="small"
                  bsStyle="primary"
                  style={{ marginBottom: 20 }}
                  onClick={this.updateDPT}
                >
                  {!this.state.savingDPT ? (
                    "Update"
                  ) : (
                    <img
                      alt="spinner"
                      src={images.ajax_loader_small}
                      height="8"
                    />
                  )}
                </Button>
              </div>
            )} */}

            {selectedOption === "Organization Users" ? (
              <div className="users-list">
                <UserSettings
                  {...this.props}
                  users={tenantUsers}
                  isAdmin={isAdminInTenant}
                  tenantInfo={tenantInfo}
                  tenantId={selectedTenantId}
                />
              </div>
            ) : (
              selectedOption === "Project Users" && (
                <div className="users-list">
                  <ProjectSettings
                    {...this.props}
                    users={tenantUsers}
                    isAdmin={isAdminInTenant}
                    tenantInfo={tenantInfo}
                    tenantId={selectedTenantId}
                    selectedTenantId={selectedTenantId}
                    defaultAppId={defaultAppId}
                  />
                </div>
              )
            )}
          </div>
        )}

        <ConfirmDeleteModal
          fixed={true}
          modalState={this.state.modal}
          triggerModal={this.triggerModal}
          nameOfTarget="your subscription"
          buttonText="Cancel"
          deleting={deleting}
          deleteTarget={this.terminateSubscription}
          message={deletemessage}
        />
      </div>
    );
  }
}
//}

/*class CreateNewOrg extends Component {

  constructor() {
    super()
    this.state={
      orgInput:'',
      savingOrg:false
    }
  }

  componentDidMount(){
    this.inputRef.focus()
  }

  changeOrgName = (e) => {
    const val = e.target.value
    if(val==='' || /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(val)){
      this.setState({orgInput: val})
    }
  }

  createNewOrg = (name) => {
    this.setState({savingOrg:true})
    this.props.actions.callCreateNewTenant(name, 1)
    .then((ts)=>{
      this.setState({savingOrg:false})
      this.props.actions.callGetUsers()
      this.props.created(ts[ts.length-1])
    })
  }

  render(){
    const {orgInput, savingOrg} = this.state
    const disableCreateOrg = !orgInput || savingOrg

    return (<div style={{display:'inline-block'}}>
      <div style={{width:200,position:'relative'}}>
        <strong className="labelz" 
          style={{fontSize:'12px',position:'absolute',bottom:0,fontWeight:'normal'}}>
          Create New Organization
        </strong>
        <svg className="leave-create-new-environment"
          viewBox="0 0 60 60" onClick={this.props.hide}>
          <path style={{strokeWidth:5, strokeLinecap: 'round'}}
            d="M 15.674663,15.572746 L 44.587629,44.485711 M 45.118838,15.420972 L 15.522889,45.016920" />
        </svg>
      </div>
      <div>
        <FormControl type="input" label="New Org" value={orgInput}
          onChange={this.changeOrgName}
          style={{width:200,display:'inline-block'}}
          inputRef={(ref)=>{ this.inputRef=ref }}
          onKeyPress={(e)=>{
            if(e.key==='Enter') {
              e.preventDefault()
              if(!disableCreateOrg){
                this.createNewOrg(orgInput)
              }
            }
          }}
        />
        <Button
          bsSize="small" bsStyle="primary"
          onClick={()=>this.createNewOrg(orgInput)}
          style={{marginLeft:10, marginTop:-2, width:58}}
          disabled={disableCreateOrg}
        >
          {!this.state.savingOrg ? <span>Create</span> : 
          <img alt="small-spinner" src={images.ajax_loader_small} height="8" />} 
        </Button>
      </div>
      <div style={{marginTop:7,width:270}}>
        This Organization will be added as a new 30-day Free Trial account.
      </div>
    </div>)
  }
}*/
