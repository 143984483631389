import React from 'react'
import {Button} from 'react-bootstrap'
import images from '../images'

const CouponEntry = (props) => {

	const { isSubForm, disableCoupon, couponState, couponCode, updateCoupon, getCoupon, redeem, accountCode, subUUID, redeeming } = props

return(

<div style={{paddingTop:isSubForm?10:0, marginLeft:isSubForm?null:-20, marginTop:10,borderTop:isSubForm?'1px double #222':null, width: '100%'}}>
          <div className="signup-field" >
          <label>Enter Your Coupon</label>
          <input type="text" onChange={(e)=>updateCoupon(e.target.value)} value={couponCode} className="recurly-hosted-field curly-hosted-field" data-recurly="coupon" name="coupon"/>
          <Button style={{marginTop:-3, height:36, width: 40}} className="coupon-button" onClick={()=>getCoupon(couponCode)}>
          <img src={disableCoupon ? images.ic_clear_black_48dp : images.chevron_right} height="30" style={{marginTop:-3, marginLeft:disableCoupon ? -8 : -7}}/>
          </Button>
          </div>
          {couponState && 
            <div style={{marginLeft:20, display:'inline-block'}}>
              <div style={{display:'inline-block', textAlign:'center', verticalAlign:'middle'}}>
              {couponState !== 'Invalid' ? 
              <span style={{fontWeight:'bold',color:'#337ab7'}}>
              {couponState.discount_type === 'percent' &&
                  <span>{couponState.discount_percent}%</span>
                  }
                  {couponState.discount_type === 'dollars' &&
                  <span>-{(couponState.discount_in_cents.USD/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}</span>
                  }
                  {couponState.discount_type === 'free_trial' &&
                  <span>Free Trial Extension</span>
                  }
                  &nbsp;Discount
                  </span>
              : <span style={{color:'red'}}>This is not a valid coupon.</span>
               }
              </div>

              {redeem && couponState && couponState.discount_type && subUUID && accountCode &&
                <Button style={{marginLeft:20}} disabled={redeeming} bsStyle="primary" onClick={()=>redeem(couponCode, accountCode, subUUID)}>
                {redeeming ? 
                  <span>
                    <img alt="spinner" src={images.ajax_loader} />
                  </span>
                  : <span>Redeem it!</span>}
                </Button>
              }
              </div>
          }
          </div>
          )
}

export default CouponEntry     