import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { countryList, stateList } from "../recurlySubscription/lists";
import images from "../images";

const titleStyles = {
  fontSize: "22px",
  float: "left",
  marginBottom: "5px",
};

export default class SubscriptionBilling extends Component {
  constructor() {
    super();
    this.state = {
      countries: [],
      states: [],
      posting: false,
      clearing: false,
      error: null,
      firstName: null,
      lastName: null,
      address: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
    };
  }

  componentWillMount() {
    const countries = [];
    const states = [];
    this.props.actions
      .getRecurlyBilling(this.props.subscription.accountCode)
      .then((o) => {
        this.props.liftBilling(o);
      });

    const { billing } = this.props;
    const { firstName, lastName, address, zip, city, state, address2 } =
      billing;
    this.setState({ firstName, lastName, zip, address, city, state, address2 });
    countryList.map((o, i) => {
      if (billing && billing.country != "" && billing.country == o.code) {
        countries.push(
          <option key={i} value={o.code} selected>
            {o.name}
          </option>
        );
      } else {
        countries.push(
          <option key={i} value={o.code}>
            {o.name}
          </option>
        );
      }
    });

    stateList.map((o, i) => {
      if (billing && billing.state != "" && billing.state == o.abbreviation) {
        states.push(
          <option key={i} value={o.abbreviation} selected>
            {o.name}
          </option>
        );
      } else {
        states.push(
          <option key={i} value={o.abbreviation}>
            {o.name}
          </option>
        );
      }
    });
    this.setState({ countries, states });
  }

  componentDidMount() {
    const { config } = this.props.redux;
    const recurly = window.recurly;
    const production = "ewr1-TbNpJKrPxRf9ucNRAgZE3H";
    const integration = "ewr1-DcoLeuxCQ2kqViKIY9kukb";
    const isIntegration = config.IS_INTEGRATION;

    let envType = production;

    if (isIntegration) {
      envType = integration;
    }

    recurly.configure(envType);
  }

  submitForm() {
    this.setState({ error: null });
    window.recurly.token(this.formref, (err, token) => {
      if (err) {
        this.setState({ error: err.message });
        alert(err.message);
        return;
      }
      this.setState({ posting: true });
      this.updateBilling(token.id);
    });
  }

  updateBilling = (token) => {
    const { accountCode } = this.props.subscription;
    const { subMode, liftBilling } = this.props;

    this.props.actions.updateRecurlyBilling(accountCode, token).then((b) => {
      if (Array.isArray(b) && b[0].Message) {
        this.setState({ posting: false, error: b[0].Message });
        return;
      }

      subMode(null);

      this.props.actions.getRecurlyBilling(accountCode).then((o) => {
        liftBilling(o);
      });
    });
  };

  clearBilling = () => {
    const { accountCode } = this.props.subscription;

    this.setState({ posting: true });
    this.props.actions.clearRecurlyBilling(accountCode).then((o) => {
      this.setState({ posting: false });
      this.props.liftBilling(null);
      this.props.actions.getRecurlyBilling(accountCode).then((b) => {
        this.props.liftBilling(b);
      });
    });
  };

  render() {
    const { subscription, billing } = this.props;
    const { countries, states, error, posting } = this.state;
    return (
      <div style={{ position: "relative" }}>
        <div style={titleStyles}>Update Billing</div>
        <br />
        <br />
        {subscription ? (
          <div className="">
            <div className="">
              <b>Card on File:&nbsp;&nbsp;</b>
              <div
                style={{ display: "inline-block", margin: 20, marginLeft: 0 }}
              >
                {billing ? (
                  <div>
                    {billing.cardType ? (
                      <div style={{ verticalAlign: "bottom" }}>
                        <div style={{ display: "inline-block" }} className="">
                          {billing.firstName} {billing.lastName} /{" "}
                          {billing.cardType} ending {billing.lastFour} / expiry{" "}
                          {billing.month}/{billing.year}
                        </div>
                        {/* <svg
                          className="delete-billing"
                          style={{
                            display: posting ? "none" : "inline-block",
                            marginLeft: 6,
                            marginTop: -2,
                          }}
                          height="18"
                          viewBox="0 0 60 60"
                          onClick={() => this.clearBilling()}
                        >
                          <path
                            style={{ strokeWidth: 5, strokeLinecap: "round" }}
                            d="M 15.674663,15.572746 L 44.587629,44.485711 M 45.118838,15.420972 L 15.522889,45.016920"
                          />
                        </svg> */}
                      </div>
                    ) : (
                      <div className="">None</div>
                    )}
                  </div>
                ) : (
                  <div>
                    {/*loading...*/}
                    <img alt="spinner" src={images.ajax_loader} />
                  </div>
                )}
              </div>
            </div>

            <div style={{ marginLeft: -20, borderRadius: 2, width: 539 }}>
              <form ref={(r) => (this.formref = r)}>
                <div className="signup-field">
                  <label title="first_name">First Name</label>
                  <input
                    type="text"
                    className="recurly-hosted-field curly-hosted-field"
                    data-recurly="first_name"
                    id="first-name"
                    name="first_name"
                    value={this.state.firstName}
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                  />
                </div>
                <div className="signup-field">
                  <label title="last_name">Last Name</label>
                  <input
                    type="text"
                    className="recurly-hosted-field curly-hosted-field"
                    data-recurly="last_name"
                    id="last-name"
                    name="last_name"
                    value={this.state.lastName}
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                  />
                </div>

                <div
                  className="card-env"
                  style={{ textAlign: "left", border: "none" }}
                >
                  <div
                    style={{
                      marginBottom: 10,
                      marginTop: -10,
                      marginLeft: -10,
                    }}
                  >
                    <div data-recurly="card" name="card" />
                  </div>
                  <div className="signup-field">
                    <label>Address1</label>
                    <input
                      type="text"
                      className="recurly-hosted-field curly-hosted-field"
                      data-recurly="address1"
                      name="address1"
                      value={this.state.address}
                      onChange={(e) =>
                        this.setState({ address: e.target.value })
                      }
                    />
                  </div>
                  <div className="signup-field">
                    <label>Address2</label>
                    <input
                      type="text"
                      className="recurly-hosted-field curly-hosted-field"
                      data-recurly="address2"
                      name="address2"
                      value={this.state.address2}
                      onChange={(e) =>
                        this.setState({ address2: e.target.value })
                      }
                    />
                  </div>
                  <div className="signup-field">
                    <label>City</label>
                    <input
                      type="text"
                      className="recurly-hosted-field curly-hosted-field"
                      data-recurly="city"
                      name="city"
                      value={this.state.city}
                      onChange={(e) => this.setState({ city: e.target.value })}
                    />
                  </div>
                  <div className="signup-field">
                    <label>State</label>
                    <select
                      data-recurly="state"
                      className="curly-hosted-field"
                      name="state"
                    >
                      {states}
                    </select>
                  </div>
                  <div className="signup-field">
                    <label>Country</label>
                    <select
                      data-recurly="country"
                      className="curly-hosted-field"
                      name="country"
                    >
                      {countries}
                    </select>
                  </div>
                  <div className="signup-field">
                    <label>Postal</label>
                    <input
                      type="text"
                      className="recurly-hosted-field curly-hosted-field"
                      data-recurly="postal_code"
                      name="zip"
                      value={this.state.zip}
                      onChange={(e) => this.setState({ zip: e.target.value })}
                    />
                  </div>
                </div>
              </form>
            </div>

            {error && (
              <div style={{ marginBottom: 5, color: "red" }}>{error}</div>
            )}

            <div style={{ width: "100%" }}>
              <Button
                style={{ marginRight: 20, width: 100, display: "inline-block" }}
                bsSize="small"
                bsStyle="primary"
                disabled={posting}
                onClick={() => this.submitForm()}
              >
                {posting ? (
                  <img alt="spinner" src={images.ajax_loader} />
                ) : (
                  <span>
                    <b>Update</b>
                  </span>
                )}
              </Button>
            </div>
            {/*  */}
          </div>
        ) : (
          <div>
            {/*loading...*/}
            <img alt="spinner" src={images.ajax_loader} />
          </div>
        )}

        <br />
      </div>
    );
  }
}
