import React, { Component } from 'react';
import ReactBootstrapToggle from 'react-bootstrap-toggle';
import { Link } from 'react-router-dom'
import moment from 'moment';
import BoxAppStyles from '../../styles/boxconnectedappstyle.js';
import SideNavStyle from '../../styles/sidenavstyle.js';

export default class BoxDatafeed extends Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
  }

  onChange(v) {
    this.props.actions.callUpdateFeedState(this.props.appId,this.props.feedKey,v ? 'Paused' : 'Active');
  }

  render() {
    const { datafeed } = this.props.redux
    let usage = null;
    if (this.props.usage) {
      usage = (
        <span style={BoxAppStyles.category} key={0}>
          {this.props.usage}
        </span>
      );
    }
    let inURL = null;
    let toggle = null;
    if (this.props.url) {
      inURL = (
        <span>
          <span style={{display: 'inline-block', width:80}}>Invitation URL:</span>&nbsp;
          <span style={{display: 'inline-block', width:'calc(100% - 92px)'}}><Link to={this.props.url} target="_blank">
            {this.props.url}
          </Link></span>
        </span>
      );
    } else {
      const status = this.props.isActive ? 'Active' : 'Paused'
      toggle = (
        <span>
          <ReactBootstrapToggle
            on={<span><i className="fa fa-play" /> Start</span>}
            off={<span><i className="fa fa-pause" /> Pause</span>}
            active={!this.props.isActive}
            onChange={this.onChange} />
        </span>
      )
      inURL = (
        <span style={{marginRight:10}}>status: <strong>{status}</strong></span>
      )
    }
    return (
      <div style={BoxAppStyles.projectAppStyles}>
        <div style={BoxAppStyles.boxAppStyles}>
          <div style={BoxAppStyles.connectedAppBoxButtonContainerStyles}>
            <div style={BoxAppStyles.appLastModifiedStyles}>
              {moment(this.props.lastmodified, 'YYYY-MM-DDTHH:mm:ss.SZ').fromNow()}
            </div>
          </div>
          <div style={BoxAppStyles.feedTenantStyles}>
            <div style={BoxAppStyles.feedFromStyles}>
              <img
                role="presentation"
                src={this.props.iconName}
                style={SideNavStyle.iconStylesSmall}
              />&nbsp;
              {this.props.tenantName}
            </div>
            <div>
              <strong>{this.props.name}</strong>
            </div>
          </div>
          <div style={BoxAppStyles.connectedAppBoxStyles}>
            {/*<div style={BoxAppStyles.descriptionStyles}>
              {this.props.description.length <= 80 ?
               this.props.description : this.props.description.substring(0,80) + '....'}
            </div>*/}
            <div style={BoxAppStyles.connectedAppBoxLeftStyles}>
              {inURL}
            </div>
            <div style={{float:'left', fontSize: 12, marginTop: 3, color: 'rgb(64, 64, 64)', width: '100%'}}>
              <div style={{width:200, verticalAlign:'top', display:'inline-block'}}>
                {usage}
              </div>
              {!this.props.url && datafeed.otherTenants && datafeed.otherTenants[this.props.tenantId] &&
                datafeed.otherApps && datafeed.otherApps[this.props.appId] ?
                  <div style={{display:'inline-block'}}>
                    Endpoint: <strong>
                      {`${this.props.redux.config.EXT_DATA_API_URL}${datafeed.otherTenants[this.props.tenantId].Key}/${datafeed.otherApps[this.props.appId].uniquekey}`}
                    </strong>
                  </div>
              : null}
            </div>
          </div>
          {this.props.showSwitch ?
            <div style={{float:'right', clear:'right', marginBottom:3}}>
              {toggle}
            </div>
          : null}
        </div>
      </div>
    );
  }
}
