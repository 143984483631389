import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import NewSubscription from "./newSubscription";
import "rc-time-picker/assets/index.css";
import EditSubscription from "./EditSubscription";
import { useEffect } from "react";
const { Body } = Modal;

const EditSchedule = (props) => {
  const { isModal, setIsModal } = props;

  const [availableTemplates, setAvailableTemplates] = useState([]);

  useEffect(() => {
    const { actions, redux } = props;
    const identity = redux.main.me;
    if (identity.role == "tech-support") {
      actions.getDataMechanicsTemplates().then((data) => {
        if (data && data.length > 0) {
          let templates = [];
          data.map((template) => {
            templates.push(template.Name);
          });

          setAvailableTemplates(templates);
        }
      });
    }
  }, []);

  const toggleModal = () => {
    setIsModal((prev) => !prev);
  };

  return (
    <div>
      <Modal
        show={isModal}
        onHide={() => setIsModal((prev) => !prev)}
        dialogClassName="sub-create-modal"
        backdrop="static"
      >
        <Body
          style={{
            position: "relative",
            overflowY: "auto",
            borderRadius: 5,
            background: "#ddd",
          }}
        >
          <div
            style={{
              verticalAlign: "middle",
              maxHeight: "1000px",
              minHeight: "550px",
              paddingBottom: "2rem",
            }}
          >
            <EditSubscription
              {...props}
              toggleModal={toggleModal}
              availableTemplates={availableTemplates}
            />
          </div>
        </Body>
      </Modal>
    </div>
  );
};

export default EditSchedule;
