export const getProgressBarLabel = (usedDataProcHours, usedDataProcHoursPercentage) => {
    if (usedDataProcHours > 1) {
        return `${usedDataProcHoursPercentage}% (${usedDataProcHours} hours used)`;
    } else {
        return `${usedDataProcHoursPercentage}% (${usedDataProcHours} hour used)`;
    }
};

export const findNotificationFrequency = (notificationFrequency, id) => {
    const item = notificationFrequency.find((f) => f.id === id);
    return item && item?.frequency;
};

export const getAvailableDataProcHoursLabel = (usedDataProcHours, availableDataProcHours, dataProcHours) => {
    return usedDataProcHours > dataProcHours
        ? `${Math.abs(availableDataProcHours)} Hours Over`
        : `${availableDataProcHours} Hours Remaining`;
};

export const isThresholdOverdue = (usedDataProcHoursPercentage, thresholdPercentage) => {
    return usedDataProcHoursPercentage > thresholdPercentage;
}