import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import EditTransRunResult from './edittransRunResult'
import images from '../images'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";
class RecipeDemo extends Component {
  constructor() {
    super()
    this.state = {
      editorjson: null,
      executingFlag:false,
      eventData: null
    } 

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {

    // const { transformer, config, project } = this.props.redux
    // const { env, mode } = this.props

    // let data

    // if (mode === 'eventContext' 
    //     || Object.keys(transformer.eventContext.eventData).length === 0){
    //         if (Object.keys(transformer.eventContext.eventData).length === 0) {
    //           data = {
    //             action: 'create',
    //             fullPath: `/${config.FTP_DIR}${project.tenantInfo.Key}/${
    //               project.appData.uniquekey
    //             }/sample.zip`,
    //             eventDate: new Date().toISOString(),
    //             type: 'file',
    //             ...(env && { environmentName: env.name }),
    //             ...(env && { environmentId: String(env.environmentId) })
    //           }
    //           this.setEditorJson(data)
    //           this.props.actions.setTransformerContext({ eventData: data })
    //         } 

    //         else {
    //           data = {...transformer.eventContext.eventData}
    //           this.setEditorJson({
    //             data,
    //             ...(env && { environmentName: env.name }),
    //             ...(env && { environmentId: String(env.environmentId) })
    //           })
    //         }
    //   }

    //   this.setState({eventData:data})
    }

  componentWillReceiveProps(newProps) {
    const { transformer } = this.props.redux
    const { env, mode } = this.props
    const { eventData} = this.state

    const isNewEnv =
      newProps.env && newProps.env.name && newProps.env.environmentId
    
    if(eventData && isNewEnv){
    setTimeout(() => {
          this.setEditorJson({
            ...eventData,
            environmentName: newProps.env.name,
            environmentId: String(newProps.env.environmentId)
          })
        }, 10)
    }

    else if (
      (transformer.eventContext &&
      transformer.eventContext.eventData &&
      isNewEnv) ||
      (mode===null && newProps.mode==='eventContext')
    ) {
        setTimeout(() => {
          this.setEditorJson({
            ...transformer.eventContext.eventData,
            environmentName: newProps.env.name,
            environmentId: String(newProps.env.environmentId)
          })
        }, 10) // so this does not run simultaneously with componentDidMount    
    }

  }

  handleChange(a){


    let eventData = this.state.eventData;
    try {
     eventData = JSON.parse(a.getValue());
    } catch(error) {
      // console.log(error)
    }

    this.setState({eventData})

  }

  setEditorJson(eventData) {
    const eventvars = this.eventvars
    let editorjson = this.state.editorjson


    if(editorjson){
      editorjson.toTextArea()
    }

    if (eventvars) {
      editorjson = window.CodeMirror.fromTextArea(eventvars, {
        tabSize: 10,
        indentUnit: 2,
        indentWithTabs: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        viewportMargin: Infinity,
        mode: 'application/ld+json',
        lineWrapping: true,
        lineNumbers: true,
        autoRefresh: true
      })
      // editorjson.setSize(null, 300)
      editorjson.addKeyMap({
        Tab: function(cm) {
          if (cm.somethingSelected()) {
            editorjson.refresh()
            const sel = editorjson.getSelection('\n')
            // Indent only if there are multiple lines selected, or if the selection spans a full line
            if (
              sel.length > 0 &&
              (sel.indexOf('\n') > -1 ||
                sel.length === cm.getLine(cm.getCursor().line).length)
            ) {
              cm.indentSelection('add')
              return
            }
          }
          if (cm.options.indentWithTabs) cm.execCommand('insertTab')
          else cm.execCommand('insertSoftTab')
        },
        'Shift-Tab': function(cm) {
          cm.indentSelection('subtract')
        }
      })

      editorjson.on('change', (a)=>{
        this.handleChange(a)
        //this.setState({eventData:change})

        })
    } // end first one
    if (editorjson){
    editorjson.getDoc().setValue(JSON.stringify(eventData, null, 2))
    }

    /*setTimeout(()=>{
      editorjson.refresh();
    }, 10);*/
    this.setState({ editorjson })
    // this.setState({ eventData })
  }

  parseResults(data) {
    const transResult = []
    const kMaxResultSize = 100
    const resultSize =
      data.length > kMaxResultSize ? kMaxResultSize : data.length
    for (let k = 0; k < resultSize; k++) {
      transResult.push(
        <EditTransRunResult
          result={data[k].result}
          timer={data[k].timer}
          startTime={data[k].startTime}
          statement={data[k].statement}
          key={k}
        />
      )
    }
    return transResult
  }

  render() {
    const { transformer, } = this.props.redux
    
    if (!transformer) return <div />


    let height, buttons

    switch (this.props.mode) {
      case 'eventContext':
        height = '252px'
        buttons = (
          <div>
            <div style={styles.title}>Event Context</div>
            <Button
              bsSize="small"
              bsStyle="primary"
              onClick={() => {
                this.setState({executingFlag:true})
                this.props.startRecipe(
                  this.state.editorjson.getDoc().getValue(),
                  this.props.env
                )
              }}>
              Start Recipe
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              bsSize="small"
              bsStyle="primary"
              onClick={this.props.handleCancel}>
              Cancel
            </Button>
          </div>
        )
        break
      case 'runResults':
        height = '60%'
        buttons = (
          <div>
            <div style={styles.title}>Results</div>
            <Button
              bsSize="small"
              bsStyle="primary"
              onClick={this.props.handleCancel}>
              X
            </Button>
          </div>
        )
        break
      default:
        height = '0px'
    }

  //   console.log(transformer.ongoingLogsByBaseId)

  //   const thisTransLogCount = transformer.ongoingLogCountByBaseId.find(f=>f.baseId===transformer.versions[0].BaseId)
  //   const count = thisTransLogCount && thisTransLogCount.stepCount
  //   const thisTransLogs = transformer.ongoingLogsByBaseId && transformer.ongoingLogsByBaseId.find(f=>f.BaseId===transformer.versions[0].BaseId)
  //   const theLogs = thisTransLogs && thisTransLogs.executionLogs
  //   const areOngoingLogs = theLogs && theLogs.length && theLogs.length > 0

  //   const logsToShow = []
  //   if (theLogs){
  //   for(let i=0; i<theLogs.length; i++){
  //     const s = theLogs[i]
  //     if(s.completed){
  //       logsToShow.push(s)
  //       if(!s.succeeded){
  //         break;
  //       }
  //     } else {
  //       // push the first uncompleted one as well
  //       logsToShow.push(s)
  //       break;
  //     }
  //   }
  // }

    const count = transformer.ongoingExecutionLogCount
    const areOngoingLogs = transformer.ongoingExecutionLogs && transformer.ongoingExecutionLogs.length>0

    const logsToShow = []
    for(let i=0; i<transformer.ongoingExecutionLogs.length; i++){
      const s = transformer.ongoingExecutionLogs[i]
      if(s.completed){
        logsToShow.push(s)
        if(!s.succeeded){
          break;
        }
      } else {
        // push the first uncompleted one as well
        logsToShow.push(s)
        break;
      }
    }

    if(this.scroller && logsToShow.length>8 && this.state.executingFlag){
      setTimeout(()=>{
        this.scroller.scrollTop = this.scroller.scrollHeight
      },100)
    }

    // recipe NOT completed
    let RESULTS = (
      <div className="loading">
        <OngoingRecipeStep count={count}
          step={{ 
            statement: 'Starting Recipe',
            completed: areOngoingLogs,
            succeeded: areOngoingLogs,
            sortOrder: -1,
          }}
        />
        {logsToShow.map((s, i)=>{
          return (<OngoingRecipeStep step={s} key={i}
            count={count}
          />)
        })}
      </div>
    ) 

    // if the recipe has completed...
    if (transformer && transformer.executionCompleted) {
      setTimeout(()=>{
        if(this.scroller && this.state.executingFlag){
          this.setState({executingFlag:false})
          this.scroller.scrollTop = 0
        }
      },500)
      const data = this.parseResults(transformer.executionLogs)
      
      RESULTS = (
        <div>
          <div style={styles.resultsWrapper}>
            <div style={{display: 'inline-block',width: '33%',marginLeft: '1%'}}>
              <strong>Statement:</strong>
            </div>
            <div style={{ display: 'inline-block', width: '53%' }}>
              <strong>Result:</strong>
            </div>
            <div style={{ display: 'inline-block', width: '13%' }}>
              <strong>Timer:</strong>
            </div>
          </div>
          {data}
          {transformer.executionError ? (
            <div style={styles.errorWrap}>
              <div style={styles.error}>
                {transformer.executionErrorMessage}
              </div>
            </div>
          ) : null}
        </div>
      )
    }

    return (
      <div
        style={{ position: 'fixed', bottom:0, height, width: 'calc(100% - 65px)' }}
        className="recipe-console">
        <div style={{ width: '100%' }}>
          <div className="recipe-console-buttons-wrapper">
            <div style={styles.buttons}>{buttons}</div>
          </div>
        </div>
        <div
          className="recipe-console-content-wrapper"
          style={{ height: 'calc(100% - 42px)' }}>
          <div style={styles.recipeConsoleContent} 
            ref={ref=>this.scroller=ref}>
            {this.props.mode === 'runResults' && <div>
              {/* this.state.executionTab */}
              <form className="form-horizontal-yaml">{RESULTS}</form>
            </div>}
            {this.props.mode === 'eventContext' && <div
              className="edittrans-console-codemirror-wrap">
              <textarea
                className="form-control-yaml"
                type="text"
                ref={r => this.eventvars = r}
              />
            </div>}
          </div>
        </div>
      </div>
    )
  }
}

const OngoingRecipeStep = ({ step, count }) => {
  return (
    <div className="ongoing-recipe-step">
      {!step.completed && <img alt="spin" src={images.spinner} />}
      {!step.completed || (step.completed && step.succeeded) ? (
        <span style={step.completed ? { left: 16 } : { fontWeight: 'bold' }}
          className="ongoing-recipe-step-text">
          <span className="ongoing-recipe-step-statement">{`${step.statement}`}</span>
          {count && <span style={{float:'right'}}>{step.sortOrder+2}/{count+1}</span>}
        </span>
      ) : (
        <span style={{ left: 16, fontWeight: 'bold', color: '#eb5959' }}
          className="ongoing-recipe-step-text">
          {step.result || 'Error'}
        </span>
      )}
    </div>
  )
}

const styles = {
  title: {
    display: 'inline-block',
    fontWeight: 'bold',
    color: 'white',
    marginRight: '15px'
  },
  buttons: {
    marginLeft: 20,
    marginTop: 10,
    marginRight: 15,
    marginBottom: 2
  },
  errorWrap: {
    position: 'relative',
    float: 'left',
    width: '100%',
    padding: '0px 1px'
  },
  error: {
    background: '#EE99AA',
    color: 'white',
    fontWeight: 'bold',
    padding: '4px 8px',
    width: '100%'
  },
  recipeConsoleContent: {
    margin: '0px 20px',
    height: '100%',
    overflowY: 'scroll',
    outline: '1px solid #555',
    backgroundColor: 'white'
  },
  resultsWrapper: {
    marginBottom: 1,
    position: 'relative',
    float: 'left',
    backgroundColor: '#CCCDCF',
    marginRight: 10,
    fontSize: 14,
    width: '100%',
    padding: '6px 8px 8px 5px'
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = state => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipeDemo);