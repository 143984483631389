import React from 'react'
import { Modal, Button } from 'react-bootstrap'
const { Body } = Modal;

function getColorByBgColor(bgColor) {
  if (!bgColor) {
    return ''
  }
  return parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2 ? '#000' : '#fff'
}

const DeployMod = ({show, hide, handleDeploy, handleDeployAndVersion, envinfo}) => {


  return(
    <div>
      <Modal
        show={ show }
        onHide={ hide }
        restoreFocus={false}
        dialogClassName="confirm-delete-modal"
        backdrop="static"
      >
        <Body style={{height: 'fit-content'}}>
          <div style={{textAlign: 'center', verticalAlign: 'middle',paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20,}}>
          <h2>Deploy to "{envinfo && envinfo.name}" 
          </h2>
          <div>
          
          <p>Deployed recipes can <b>subscribe to events.</b></p>
          
          </div>
        <div style={{ marginTop: 20 }}>
          
          <Button bsStyle="default" onClick={ hide }>
            Cancel
          </Button>
          {/*}
          <Button style={{marginLeft:10}}value="newVersion" bsStyle='primary' onClick={handleDeployAndVersion}>
            Deploy + Version
          </Button>
        */}
          <Button style={{marginLeft:10}}value="newVersion" bsStyle='primary' onClick={()=>handleDeploy(false)}>
            Deploy
          </Button>
        </div>
        <br/>
        <span style={{fontSize:11}}>*Create a new version to continue editing after deploying</span>
        </div>
        </Body>
      </Modal>
    </div>
  )
}

export default DeployMod