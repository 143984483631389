import React, { Component } from 'react';
import HomeStyles from '../styles/homestyles.js';
import BoxDatafeed from '../components/datasync/boxdatafeed.js';
import ContentWsStyles from '../styles/contentwsstyle.js';
import images from '../components/images.js';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as projectActions from "../shared/redux/actions/projectActions";
import * as mainActions from "../shared/redux/actions/mainActions";
import * as transformerActions from "../shared/redux/actions/transformerActions";
import * as datafeedActions from "../shared/redux/actions/datafeedActions";
import * as wizardActions from "../shared/redux/actions/wizardActions";
import * as environmentActions from "../shared/redux/actions/environmentActions";
import * as eventsActions from "../shared/redux/actions/eventsActions";
class DataFeedInvitation extends Component {
  constructor() {
    super();
    this.state = {
      tenantInfo: {},
      selectedAppId:null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    const url = this.props.location.pathname
    const urlparts = url.split('/');
    this.props.actions.callGetApps();
    this.props.actions.callGetInvitedFeed(urlparts[2]);
  }
  handleSubmit(e) {
    e.preventDefault();
    const {redux} = this.props
    const {main} = redux
    const app = main.appList.find((a)=>{
      return a.Id === parseInt(this.state.selectedAppId, 10)
    })
    const url = this.props.location.pathname
    const urlparts = url.split('/');
    this.props.actions.callUpdateFeed(app.tenantId, app.Id, urlparts[2]);
    this.props.history.push('/');
  }
  render() {
    const { datafeed, main } = this.props.redux;
    const feed = datafeed.invitedFeed
    if (!feed || !main.appList) return <div />;
    if (feed.Redeemed) {
      return (<div className="form-horizontal">
        Project has already been associated.
      </div>);
    }
    const tenantId = feed.TenantIdFrom;
    return (
      <div>
        <form className="form-horizontal" onSubmit={this.handleSubmit}>
          <div style={HomeStyles.titleStyles}>
            Associate Data Feed With Project
          </div>
          <br />
          <br />
          <div style={HomeStyles.feed}>
            <BoxDatafeed
              appId={feed.AppIdFrom}
              id={feed.Id}
              tenantId={tenantId}
              label={'From'}
              name={feed.Name}
              dataProfileId={feed.DataProfileId}
              lastmodified={feed.LastModified}
              description={feed.Description}
              usage={feed.Usage}
              iconName={images.consumed}
              tenantName={datafeed.otherTenants[tenantId] ? datafeed.otherTenants[tenantId].Name : ''}
              showSwitch={false}
              {...this.props}
            />
          </div>
          <div style={ContentWsStyles.formElementTitle}>
            Choose Project
          </div>
          <div>
            <select className="form-control" name="app" value={this.state.selectedAppId}
              onChange={e=>this.setState({selectedAppId:e.target.value})}>
              <option value="" selected disabled>Projects</option>
              {main.appList && main.appList.map((a)=>{
                return (<option value={a.Id} key={a.uniquekey}>
                  {a.name}
                </option>)
              })}
            </select>
          </div>
          <br />
          <div style={ContentWsStyles.formElementTitle}>
            Intended Usage For Data
          </div>
          <div>
            <select className="form-control" name="usage" disabled>
              <option value={feed.Usage}>
                {feed.Usage}
              </option>
            </select>
          </div>
          <br />
          <div>
            <Link to="/">
              <input  type="button" value="Cancel" className="btn btn-sm btn-primary" />
            </Link>
            &nbsp;&nbsp;
            <button
              type="submit"
              value="Post"
              className="btn btn-sm btn-primary"
              disabled={!this.state.selectedAppId}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = state => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DataFeedInvitation);
