import React, { Component, Fragment } from "react";
import { Button, DropdownButton, MenuItem, FormControl } from "react-bootstrap";
import { GithubPicker } from "react-color";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import images from "../images";
import EmailMonitoring from "./emailMonitoring";
import ConfirmDeleteModal from "../utils/confirmDeleteModal";
import EnvVar from "./environmentVars";
import Connectors from "./Connector";
import MetaExplore from "./../recipe/metaExplore";

const colorPickerColors = [
  "#404040",
  "#334e24",
  "#1c5e67",
  "#412a2e",
  "#006B76",
  "#1273DE",
  "#004DCF",
  "#5300EB",
  "#EB9694",
  "#FAD0C3",
  "#FEF3BD",
  "#C1E1C5",
  "#BEDADC",
  "#C4DEF6",
  "#BED3F3",
  "#FFFFFF",
];

class EnvBar extends Component {
  constructor() {
    super();
    this.state = {
      selectedProvider: null,
      showDeleteDialog: false,
      updating: false,
      editEnvName: false,
      newEnvName: "",
      editEnvColor: false,
      newEnvColor: "",
      selectedForDelete: null,
      selectedConnectorKeyForDelete: null,
      modal: false,
      showEnv: true,
      showNewConnector: false,
      showEditConnector: false,
      boxMinHeight: null,
      showNotification: true,
      showConnectorType: "source",
      newUserExperience: false,
      isWizard: false,
      multipleConnector: [],
    };
  }
  componentWillReceiveProps(newProps) {
    if (!this.state.selectedProvider) {
      const newEnvCreds =
        newProps &&
        newProps.redux &&
        newProps.redux.project &&
        newProps.redux.project.environmentCredentials;
      if (newEnvCreds && newEnvCreds.length === 1) {
        const c = newEnvCreds[0];
        const providerType = this.normalizeCredentialTypeNames(c.providerLabel);
        this.setState({ selectedProvider: providerType });
      }
    }
  }

  changeEnvName = (e) => {
    if (/^$|^[a-z0-9_-]+$/i.test(e.target.value)) {
      this.setState({ newEnvName: e.target.value });
    }
  };

  normalizeCredentialTypeNames(providerLabel) {
    let providerType = providerLabel;
    if (providerType === "AdapterSecret") providerType = null;
    return providerType;
  }

  saveChangeToEnvName = (e) => {
    e.stopPropagation();
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    this.setState({ updating: true });
    actions
      .saveChangeToEnv(
        project.tenantInfo.TenantId,
        appId,
        env,
        this.state.newEnvName,
        "",
        env.isProd
      )
      .then(() => {
        this.props.deselect();
        this.setState({ updating: false, editEnvName: false });
      });
  };

  toggleColorEditor = (e) => {
    e.stopPropagation();
    this.setState({ editEnvColor: !this.state.editEnvColor });
  };

  saveChangeToEnvColor = (color) => {
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    this.setState({ updating: true });
    actions
      .saveChangeToEnv(
        project.tenantInfo.TenantId,
        appId,
        env,
        "",
        color.hex,
        env.isProd
      )
      .then(() => {
        this.props.deselect();
        this.setState({ updating: false, editEnvColor: false });
      });
  };

  warnBeforeDelete = (e, connectorKey, similarCredentials, isDefault) => {
    
    if (similarCredentials && similarCredentials.length > 1 && isDefault) {
      this.setState({
        multipleConnector: similarCredentials
      })
    }

    this.setState({
      selectedForDelete: e,
      selectedConnectorKeyForDelete: connectorKey,
      showNewConnector: false,
      // To remove irrelevant text from credential 
      // detail screen while deleting 
      // 
      // showEditConnector: false,    
    });
    this.triggerModal();

  };

  triggerModal = () => {
    let modal = this.state.modal;
    modal && this.setState({
      multipleConnector: []
    })

    modal = !modal;
    this.setState({ modal });
  };

  removeCredFromEnv = () => {
    const { actions, match, redux, env } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project, environment } = redux;
    const credType = this.state.selectedForDelete;
    const connectionKey = this.state.selectedConnectorKeyForDelete;
    if (credType == "LingkWebSocket") {
      actions.deleteWebsocketAgentCerts(
        project.tenantInfo.TenantId,
        appId,
        env.id
      );
    }
    actions.deleteCredentialFromEnvironment(
      project.tenantInfo.TenantId,
      appId,
      credType,
      connectionKey,
      env
    );
    actions.getAllCredentialsForEnvironment(
      project.tenantInfo.TenantId,
      appId,
      env.name
    );
    this.setState({
      showNewConnector: false,
      showEditConnector: false,
    });
    this.setState({
      selectedForDelete: null,
      selectedConnectorKeyForDelete: null,
      multipleConnector: []
    });
    this.triggerModal();
  };

  showNewConnectorComponent = (target) => {
    this.setState({ showNewConnector: true, showConnectorType: target });
  };

  componentDidUpdate(prevProps) {
    const { actions, match, redux, history } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    const areCreds = project && project.environmentCredentials;
    const env = this.props.selectedEnv;
    const { location } = history;
    const { state } = location;
    if (prevProps.selectedEnv != this.props.selectedEnv) {
      if (this.props.selectedEnv && !areCreds) {
        actions.getAllCredentialsForEnvironment(
          project.tenantInfo.TenantId,
          appId,
          env.name
        );
        actions.getMonitoringPreferences(
          project.tenantInfo.TenantId,
          appId,
          env.id
        );
        if ((state && !state.showFirstTimeUserExp) || !state) {
          this.setState({ showNewConnector: false, showEditConnector: false });
        }
      }
    }
  }

  setNewUserExp() {
    this.setState({ newUserExperience: false });
  }
  componentWillMount() {
    const { actions, match, redux, history } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    const { location } = history;
    const { state } = location;
    const isEnv = project && project.environments;
    if (state && state.showFirstTimeUserExp) {
      this.setState({ newUserExperience: true, showNewConnector: true });
      this.showNewConnectorComponent("reader");
    }
    const { sessionEnvironment } = this.props.redux.environment;

    if (!this.props.selectedEnv && sessionEnvironment && isEnv) {
      let env = isEnv.find((e) => e.id == sessionEnvironment);
      if (env && env.name)
        actions.getAllCredentialsForEnvironment(
          project.tenantInfo.TenantId,
          appId,
          env.name
        );
    }
  }
  render() {
    const { env, selectEnv, selected, redux, deleteEnv, editEnv } = this.props;
    const { project, main, environment } = redux;
    const {
      showEnv,
      updating,
      boxMinHeight,
      showNewConnector,
      editEnvColor,
      showEditConnector,
      newEnvColor,
      showNotification,
      newUserExperience,
      isWizard,
    } = this.state;
    const environmentVariables =
      project.environmentCredentials &&
      project.environmentCredentials.filter((ec) => {
        return ec.credentialType == "EnvironmentVariables";
      });
    const areCreds = project && project.environmentCredentials;
    const isNotificationConfig = environment && environment.monitoring;
    const adapterSecretCred =
      project.environmentCredentials &&
      project.environmentCredentials.find(
        (ec) => ec.credentialType === "AdapterSecret"
      );
    const adapterSecret = adapterSecretCred && adapterSecretCred.credentials;
    return (
      <div
        style={
          selected
            ? { marginTop: 45 }
            : {
                width: "85%",
                border: "1px solid transparent",
                position: "relative",
                display: "inline-block",
              }
        }
        className={selected ? "" : "env-bar-wrap"}
      >
        <div
          className={selected ? "" : "env-bar"}
          onClick={() => {
            this.setState({ editEnvColor: false });
            selectEnv();
          }}
        >
          {!selected && env.isProd && (
            <div className="env-is-prod">Production</div>
          )}

          {!selected && (
            <div
              className="env-bar-color"
              onClick={this.toggleColorEditor}
              style={{ background: env.color || "#404040", cursor: "pointer" }}
            />
          )}

          {!selected && editEnvColor && (
            <div
              style={{ position: "absolute", padding: 3, zIndex: 999 }}
              onClick={(e) => e.stopPropagation()}
            >
              <GithubPicker
                color={newEnvColor || env.color || "#404040"}
                triangle="top-left"
                colors={colorPickerColors}
                onChange={this.saveChangeToEnvColor}
                width={215}
              />
            </div>
          )}

          <div className={selected ? "" : "env-bar-title"}>
            {!selected && (
              <span style={{ fontWeight: "bold" }}>{env.name}</span>
            )}
            {!selected &&
              project.envOverview &&
              project.envOverview
                .filter((o) => o.EnvId == env.id)
                .map((oName, i) => (
                  <div key={i}>
                    {oName.CredCount ? (
                      <div>{oName.CredCount} Connector</div>
                    ) : null}
                    {oName.EnvCount ? (
                      <div>{oName.EnvCount} Environment Variables</div>
                    ) : null}
                    {oName.EmailCount ? (
                      <div>{oName.EmailCount} Notification Emails</div>
                    ) : null}
                    {/* <div className="env-bar-specs"><img src={images.copy_env} style={{width:21, position:'absolute', bottom:'5px', right:'69px'}} alt="copy" /></div> */}
                    <div className="env-bar-specs">
                      <img
                        src={images.delete_env}
                        style={{
                          width: 23,
                          position: "absolute",
                          bottom: "5px",
                          right: "13px",
                        }}
                        alt="delete"
                        onClick={deleteEnv}
                      />
                    </div>
                    <div className="env-bar-specs">
                      <img
                        src={images.edit}
                        style={{
                          height: 23,
                          position: "absolute",
                          bottom: "5px",
                          right: "40px",
                        }}
                        alt="edit"
                        onClick={editEnv}
                      />
                    </div>
                  </div>
                ))}
          </div>

          {((selected && !areCreds) || updating) && (
            <div className="env-bar-spinner">
              <img alt="small-spinner" src={images.ajax_loader_small} />
            </div>
          )}
        </div>

        <div style={{ width: "98%" }}>
          {areCreds && selected && !showNewConnector ? (
            <div>
              <CSSTransitionGroup
                transitionName="env-content"
                transitionEnterTimeout={250}
                transitionLeaveTimeout={250}
              >
                <div>
                  {!showEditConnector && (
                    <div style={{ marginBottom: 16 }}>
                      {" "}
                      <span style={{ fontWeight: "bold", padding: 6 }}>
                        Connections
                      </span>
                      {!newUserExperience && (
                        <Fragment>
                          {" "}
                          <Button
                            bsStyle="default"
                            bsSize="small"
                            style={{
                              width: 115,
                              fontWeight: "bold",
                              float: "right",
                              height: 36,
                              marginTop: -6,
                            }}
                            onClick={() =>
                              this.showNewConnectorComponent("writer")
                            }
                          >
                            Add Destination
                          </Button>
                          <Button
                            bsStyle="default"
                            bsSize="small"
                            style={{
                              width: 115,
                              fontWeight: "bold",
                              float: "right",
                              height: 36,
                              marginTop: -6,
                              marginRight: 5,
                            }}
                            onClick={() =>
                              this.showNewConnectorComponent("reader")
                            }
                          >
                            Add Source
                          </Button>{" "}
                          <Button
                            bsStyle="default"
                            bsSize="small"
                            style={{
                              width: 135,
                              fontWeight: "bold",
                              float: "right",
                              height: 36,
                              marginTop: -6,
                              marginRight: 5,
                            }}
                            onClick={() => {
                              this.showNewConnectorComponent("reader");
                              this.setState({
                                newUserExperience: true,
                                isWizard: true,
                              });
                            }}
                          >
                            Connections Wizard
                          </Button>
                        </Fragment>
                      )}
                    </div>
                  )}

                  {project.environmentCredentials.filter(
                    (ec) =>
                      ec.credentialType !== "AdapterSecret" ||
                      ec.credentialType !== "EnvironmentVariables"
                  ).length > 0 ? (
                    <div className="env-content">
                      <Connectors
                        {...this.props}
                        ref={(instance) => {
                          this.wrapperConnectors = instance;
                        }}
                        environmentVariables={environmentVariables}
                        adapterSecret={adapterSecret}
                        newConnector={false}
                        warnBeforeDelete={this.warnBeforeDelete}
                        showConnectorType={this.state.showConnectorType}
                        hideNewConnector={() =>
                          this.setState({
                            showNewConnector: false,
                            showEditConnector: false,
                          })
                        }
                        hideEditConnector={() =>
                          this.setState({
                            showEditConnector: false,
                          })
                        }
                        handleShowEditConnector={() =>
                          this.setState({
                            showEditConnector: true,
                          })
                        }
                        showNewConnector={this.state.showNewConnector}
                        newUserExperience={newUserExperience}
                        isWizard={isWizard}
                        showNewConnectorComponent={
                          this.showNewConnectorComponent
                        }
                        showCredBars={() =>
                          this.setState({
                            newUserExperience: false,
                            isWizard: false,
                          })
                        }
                      />
                    </div>
                  ) : (
                    <div style={{ marginLeft: 14 }}>
                      <br />
                      <span>No Connectors found for this Environment</span>
                      <br />
                      <br />
                    </div>
                  )}

                  {showEnv && !showNewConnector && !showEditConnector && (
                    <EnvVar
                      {...this.props}
                      environmentVariables={environmentVariables}
                    />
                  )}
                </div>
              </CSSTransitionGroup>
            </div>
          ) : (
            <span />
          )}
          {isNotificationConfig &&
            showNotification &&
            selected &&
            !showNewConnector &&
            !showEditConnector && (
              <div style={{ marginTop: 20 }}>
                <EmailMonitoring
                  {...this.props}
                  boxHeight={boxMinHeight}
                  setBoxMinHeight={(boxMinHeight) =>
                    this.setState({ boxMinHeight })
                  }
                />
              </div>
            )}
          {showEnv &&
            !showNewConnector &&
            !showEditConnector &&
            areCreds &&
            selected && (
              <Fragment>
                <span
                  style={{
                    fontWeight: "bold",
                    marginBottom: 15,
                    marginTop: 15,
                    display: "inline-block",
                  }}
                >
                  Metadata Explorer
                </span>
                <MetaExplore
                  redux={this.props.redux}
                  envCreds={areCreds}
                  actions={this.props.actions}
                  selectedEnvironment={this.props.selectedEnv}
                  onConnections={true}
                />
              </Fragment>
            )}
        </div>

        {showNewConnector && selected && (
          <div>
            <Connectors
              {...this.props}
              adapterSecret={adapterSecret}
              newConnector={true}
              hideNewConnector={() =>
                this.setState({
                  showNewConnector: false,
                })
              }
              handleShowEditConnector={() =>
                this.setState({
                  showEditConnector: true,
                })
              }
              hideEditConnector={() =>
                this.setState({
                  showEditConnector: false,
                })
              }
              showNewConnector={this.state.showNewConnector}
              showConnectorType={this.state.showConnectorType}
              newUserExperience={newUserExperience}
              isWizard={isWizard}
              showNewConnectorComponent={this.showNewConnectorComponent}
              showCredBars={() =>
                this.setState({ newUserExperience: false, isWizard: false })
              }
            />
          </div>
        )}

        <ConfirmDeleteModal
          modalState={this.state.modal}
          triggerModal={this.triggerModal}
          deleteTarget={this.removeCredFromEnv}
          message="This will remove the selected connector."
          multipleConnectorExists={this.state.multipleConnector.length > 1}
        />
      </div>
    );
  }
}

export default EnvBar;

class NewEnvName extends Component {
  componentDidMount() {
    this.inputRef.focus();
  }

  componentWillMount() {
    this.props.changeEnvName({ target: { value: this.props.env.name } });
  }

  render() {
    const { updateEnvironment, newEnvName, changeEnvName } = this.props;
    const disableCreateEnv = false;
    return (
      <FormControl
        type="input"
        label="New Environment"
        value={newEnvName}
        onChange={changeEnvName}
        style={{ width: 150, height: 25, display: "inline-block" }}
        inputRef={(ref) => {
          this.inputRef = ref;
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !disableCreateEnv) {
            updateEnvironment(newEnvName);
          }
        }}
      />
    );
  }
}
