
import { Link } from  'react-router-dom';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import images from '../images'
import ContentWsStyles from '../../styles/contentwsstyle';

const SVGComponent = props => <svg {...props}>{props.children}</svg>
const Rectangle = props => <rect {...props}>{props.children}</rect>

export default class DataDetails extends Component {

  constructor() {
    super();
    this.state = {
      showSecret:false,
      showDetails:false,
      resourceId:0,
      resourceLabel: ''
    };
    this.clickedClose = this.clickedClose.bind(this);
    this.openApi = this.openApi.bind(this);
  }

  openDataEntity(rsc) {
    const { project } = this.props.redux
    const types = project.apihubDetail.ResourceTypes
    types.map((type)=>{
      if(type.Name === rsc.resourceName) {
        this.setState({ showDetails: true, resourceId: type.Id, resourceLabel: rsc.resourceName });
      }
    })
  }

  clickedClose(){
    this.setState({ showDetails: false });
  }

  getResName(resourceName) {
    let ret = resourceName.replace(/\s/g, '').toLowerCase();
    if (ret.indexOf('facility') !== -1) {
      ret = ret.substring(0, ret.length - 1) + 'ies';
    } else if (ret !== 'staff' && ret.indexOf('address') == -1) {
      ret += 's';
    } else if (ret.indexOf('address') !== -1) {
      ret += 'es';
    }
    return ret;
  }

  openApi(rsc, method){
    const name = rsc.resourceName.replace(/\s/g, '_');
    let query='';
    switch(method){
      case 'GET':
        query = 'Get_'+this.getPluralForSwagger(name, true)
        break;
      case 'POST':
        query = 'Create_a_new_' + name
        break;
      case 'PUT':
        let prefix = 'Update_a_'
        if (name.includes('Address') || name.includes('Email') || name.includes('Telephone') || name.includes('Authentication')){
          prefix='Update_'
        } 
        query = prefix + name
        if (name === 'Institution') query='Update_an_institution'
        break;
      case 'DEL':
        let prefix2 = 'Delete_a_'
        if (name === 'Institution') prefix2='Delete_an_'
        query = prefix2 + name
      default: break;
    }
    this.props.history.push(`/a/${this.props.match.params.appId}/db/api?endpoint=${query}`);
    this.props.actions.setDataTabsSelected(2);
  }

  getPluralForSwagger(resourceName) {
    let ret = resourceName
    if (ret.indexOf('Facility') !== -1) {
      ret = ret.substring(0, ret.length - 1) + 'ies';
    } else if (ret !== 'Course_Section' && (ret !=='Staff' && ret.indexOf('Address') === -1)) {
      ret += 's';
    } else if (ret.indexOf('Address') !== -1) {
      ret += 'es';
    }
    return ret;
  }

  render() {
    const { project, config } = this.props.redux 
    const tableRows = [];
    let usageCount = 0;
    const sort = null;
    let maxcount = 0;

    if (!project.usage || project.usage === null
      || typeof project.usage === 'undefined') {
      return (<div />);
    }
    if (project.usage.length === -1) {
      return (<div className="main-tab-content"><br />
        <div style={ContentWsStyles.titleStyles}>Data Sets</div><br />
        <p>This project has no scenarios. Please associate this project with one or more scenarios in the details tab.</p>
        <p>Scenarios provide API and data management for projects. When more scenarios are associated with a project, more APIs and data are usable.</p><br />
      </div>);
    }
    for (let ii=0; ii<project.usage.length; ++ii) {
      const rsc = project.usage[ii].resourceCount;
      if (rsc > maxcount) {
        maxcount = rsc;
      }
    }
    project.usage.sort(function(a, b){
      if(a.resourceName < b.resourceName) return -1;
      if(a.resourceName > b.resourceName) return 1;
      return 0;
    });
    for (let ii=0; ii<project.usage.length; ++ii) {
      const rsc = project.usage[ii];
      if (rsc != null && rsc.hasOwnProperty('resourceName') &&
        rsc.hasOwnProperty('resourceCount')) {
        const rscPercentage = (maxcount !== 0) ? (rsc.resourceCount*150)/maxcount : 0;
        tableRows.push(<tr key={ii}>
          <td>{rsc.resourceName}</td>
          <td> <SVGComponent height="25" width="158">
            <Rectangle height="15" width="150" x="5" y="5"  fill="none" stroke="black" />
            <Rectangle height="15" width={rscPercentage} x="5" y="5" />
          </SVGComponent></td>
          <td>{rsc.resourceCount}</td>
          <td><Link to={{ pathname: `/a/${this.props.match.params.appId}/db/explore`, query:{target: this.getResName(rsc.resourceName)} }}>Explore</Link></td>
          <td><img alt="launch" style={{cursor:'pointer'}} width="24" 
            src={images.ic_launch_black_48dp} 
            onClick={this.openDataEntity.bind(this, rsc)} />
          </td>
          <td className="database-api-buttons" width="161">
            <div style={{background:'#0f6ab4'}} onClick={this.openApi.bind(this,rsc,'GET')}>GET</div>
            <div style={{background:'#10a54a'}} onClick={this.openApi.bind(this,rsc,'POST')}>POST</div>
            <div style={{background:'#c5862b'}} onClick={this.openApi.bind(this,rsc,'PUT')}>PUT</div>
            <div style={{background:'#a41e22'}} onClick={this.openApi.bind(this,rsc,'DEL')}>DEL</div>
          </td>
        </tr>);
        if (rsc.resourceCount !== 0) {
          usageCount += 1;
        }
      }
    }

    
    const apiKey = project.tenantCreds && project.tenantCreds.TykApiKey ?  project.tenantCreds.TykApiKey : 'In Process';
    const apiSecret = project.tenantCreds && project.tenantCreds.TykSecretKey ?  project.tenantCreds.TykSecretKey : 'In Process';
    const url = config.EXT_DATA_API_URL + '@self';
    return (
      <div>
        
        <div style={{margin:'30px 0 4px 0', position:'relative'}}>
          <div style={{fontSize:'15px',display:'inline-block',
            lineHeight:'15px'}}>
            API Keys
          </div>
          <br/>
          <div style={{background:'#EEE',width:550,padding: 10, outline:'1px solid #CCC'}}>
          
          <div style={{display:'inline-block',marginLeft:4}}>
            <label htmlFor="key">API Key</label>
            <div style={{marginBottom:6}}>
              <input style={{display:'inline-block'}} type="text" name="apiKey" size="60" readOnly value={apiKey} />
            </div>
            <label htmlFor="secret">Secret</label>
            <div style={{marginBottom:6}}> 
              <input onBlur={()=>this.setState({showSecret:false})} 
                style={{display:'inline-block'}} readOnly value={apiSecret}
                type={this.state.showSecret ? 'text' : 'password'} name="apiSecret" size="60"
              /> 
              <Button bsSize="small" bsStyle="primary" 
                onClick={()=>this.setState({showSecret:!this.state.showSecret})}
                style={{height:25,verticalAlign:'top',padding:'1px 10px',marginLeft:5}}>
                  Show
              </Button>
            </div>
            <label htmlFor="endpoint">API Endpoint</label>
            <div style={{marginBottom:6}}><a href={url}>{url}</a></div>
            { project && project.tenantCreds && project.tenantCreds.TykApiKey
              && project.tenantCreds.TykSecretKey &&
              <div>
              {!project.tenantCreds.TykApiKey || !project.tenantCreds.TykSecretKey ? 
              <div>
                <p>Your API Credentials are being provisioned. You will be </p>
                <p>notified when they are available (less than 24 hours).</p>
                <br />
                <p>Use the "Help" button to ask any questions.</p>
              </div>
            : null }
            </div>
          }
          </div>
          </div>
        </div>
      </div>
    );
  }
}
