import React, { useState, useEffect, useRef } from "react";
import images from "../../images";
import { Table } from "react-bootstrap";
import moment from "moment";
import NewWindow from "react-new-window";

export default (props) => {
  const failedRefInput = useRef();
  const successRefInput = useRef();

  const { actions, redux, match } = props;
  const { main } = redux;
  const { params } = match;
  const { appList } = main;
  const { appId } = params;
  const [activeJobId, setActiveJobId] = useState(1);
  const [activeJobs, setActiveJobs] = useState([]);
  const [openFailResults, setOpenFailResults] = useState(false);
  const [openSuccessResults, setOpenSuccessResults] = useState(false);
  const [loading, setLoading] = useState(true);
  const [jobLength, setJobsLength] = useState(0);
  const [connectionKey, setConnectionKey] = useState("");
  const queryParams = new URLSearchParams(location.search);
  let envId;
  if (queryParams.has("env")) {
    envId = queryParams.get("env");
  }

  let header = [];
  if (activeJobs && activeJobs.length > 0) {
    activeJobs.map((r) => {
      let row = r;
      Object.keys(row).forEach((k) => {
        let ret = k.replace("Number ", "");
        if (!header.includes(ret)) {
          header.push(ret);
        }
      });
    });
  }
  let tenant;
  if (appList && appList.length > 0) {
    tenant = appList.find((a) => a.Id == appId);
  }
  useEffect(() => {
    let stepGuid;
    if (queryParams.has("step")) {
      stepGuid = queryParams.get("step");
    }

    if (
      stepGuid &&
      tenant &&
      tenant.tenantId &&
      activeJobs &&
      activeJobs.length == 0
    ) {
      actions
        .callGetSelectedStepLogs(tenant.tenantId, stepGuid)
        .then((data) => {
          let { connectionKey } = data;
          if (!connectionKey || connectionKey == "") {
            connectionKey = "defaultConnection";
          }
          setConnectionKey(connectionKey);
          const jobIds = data.extendedProperties;
          if (jobIds && jobIds != "") {
            let all = [];
            let obj = JSON.parse(jobIds);
            obj.map((o) => {
              all.push(o.sf__jobId);
            });
            setJobsLength(all.length);
            if (all && all.length > 0) {
              all.map((a, k) => {
                getJobInfo(tenant.tenantId, a, connectionKey);
              });
            }
          }
        });
    }

    const failInterval = setInterval(() => {
      if (
        failedRefInput.current &&
        failedRefInput.current.released &&
        openFailResults
      ) {
        setOpenFailResults(false);
      }
    }, 1000);

    const successInterval = setInterval(() => {
      if (
        successRefInput.current &&
        successRefInput.current.released &&
        openSuccessResults
      ) {
        setOpenSuccessResults(false);
      }
    }, 1000);

    let jobs = [...activeJobs];
    jobs = jobs.sort((a, b) => {
      let date1 = getDateFromString(a["Created Date"]);
      let date2 = getDateFromString(b["Created Date"]);
      const x = new Date(date1);
      const y = new Date(date2);
      if (x > y) return 1;
      if (x < y) return -1;
      return 0;
    });
    if (jobs && jobs.length > 1 && jobs.length == jobLength) {
      setActiveJobs(jobs);
      setJobsLength(0);
    }

    return () => {
      clearInterval(failInterval);
      clearInterval(successInterval);
    };
  }, [tenant, openFailResults, activeJobs]);

  function getDateFromString(str) {
    let res = str.split(" ");
    res[0] = getMonthFromString(res[0]).toString();
    res[1] = res[1].substring(0, res[1].length - 3);
    let date = res.join(" ");
    return date;
  }
  function getMonthFromString(mon) {
    var d = Date.parse(mon + "1, 2012");
    if (!isNaN(d)) {
      return new Date(d).getMonth() + 1;
    }
    return -1;
  }

  function getJobInfo(tenant, job, connectionKey) {
    actions
      .getSummaryofJob(tenant, appId, envId, connectionKey, job)
      .then((data) => {
        if (data) {
          data["Created Date"] = moment(data["Created Date"]).format(
            "MMM Do, h:mm:ss a"
          );
          data["Number Records Succeeded"] =
            data["Number Records Succeeded"] == 0 ? (
              data["Number Records Succeeded"]
            ) : (
              <span
                className="span-as-link"
                onClick={() => {
                  setOpenSuccessResults(true);
                  setActiveJobId(job);
                }}
              >
                {data["Number Records Succeeded"]}
              </span>
            );

          data["Number Records Failed"] =
            data["Number Records Failed"] == 0 ? (
              data["Number Records Failed"]
            ) : (
              <span
                className="span-as-link"
                onClick={() => {
                  setOpenFailResults(true);
                  setActiveJobId(job);
                }}
              >
                {data["Number Records Failed"]}
              </span>
            );
          setLoading(false);
          setActiveJobs((oldArray) => [...oldArray, data]);
        } else {
          setLoading(false);
        }
      });
  }

  function getRowData(row) {
    return Object.keys(row).map(function (key) {
      return <td key={key}>{row[key]}</td>;
    });
  }

  return (
    <div style={{ marginBottom: 10 }}>
      <div className="sf-jobs">
        <span style={{ fontSize: 14 }} className="sf-header">
          {" "}
          Salesforce Bulk Jobs Info
        </span>{" "}
        {activeJobs && activeJobs.length > 0 ? (
          <div className="sf-jobs-table">
            <Table className="logs-printer-table" bordered>
              <thead>
                <tr key={1}>
                  {header &&
                    header.length > 0 &&
                    header.map((h, i) => (
                      <th key={i} style={{ whiteSpace: "nowrap" }}>
                        {h}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {activeJobs.map((a, i) => {
                  return (
                    <tr value={i} key={i}>
                      {getRowData(a)}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : loading ? (
          <img alt="spinner" src={images.ajax_loader} height="15" />
        ) : (
          <span>No data found.</span>
        )}
      </div>
      )
      {openFailResults && (
        <NewWindow
          copyStyles={false}
          name={`SF Jobs Failed Results`}
          title={`SF Jobs Failed Results`}
          features={{ noopener: "_blank" }}
          ref={failedRefInput}
          url={`/a/${appId}/health/sfjobs/failed?jobId=${activeJobId}&env=${envId}&connectionKey=${connectionKey}`}
        />
      )}
      {openSuccessResults && (
        <NewWindow
          copyStyles={false}
          ref={successRefInput}
          name={`SF Jobs Succeeded Results`}
          title={`SF Jobs Succeeded Results`}
          features={{ noopener: "_blank" }}
          url={`/a/${appId}/health/sfjobs/successful?jobId=${activeJobId}&env=${envId}&connectionKey=${connectionKey}`}
        />
      )}
    </div>
  );
};
