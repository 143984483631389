import React, { Component } from "react";
import images from "../images";
import {
  Button,
  FormGroup,
  FormControl,
  Table,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";

const datatypes = ["String", "Number"];
const styles = {
  r: {
    width: "8%",
    marginLeft: "4%",
  },
  del: {
    width: "8%",
    marginLeft: "2%",
    marginTop: 7,
  },
  envBox: {
    border: "1px solid black",
    marginRight: 4,
    marginTop: 10,
    marginLeft: 5,
    height: 22,
    width: 22,
  },
  checkBox: {
    marginRight: 4,
    marginTop: 10,
    marginLeft: 5,
    height: 22,
    width: 22,
  },
};

class EnvName extends Component {
  componentDidMount() {
    this.inputRef.focus();
  }

  render() {
    const { ename, onChange, customForm } = this.props;
    return (
      <FormControl
        required
        type="text"
        placeholder="key"
        style={customForm ? { width: 130 } : {}}
        value={ename}
        onChange={(e) => onChange(e.target.value)}
        inputRef={(ref) => {
          this.inputRef = ref;
        }}
      />
    );
  }
}

class EnvValue extends Component {
  componentDidMount() {
    this.inputRef.focus();
  }
  render() {
    const { value, onChange, sensitive, dataType, customForm } = this.props;
    let datatype = "text";
    let disableValue = false;
    if (dataType == "Number") {
      datatype = "number";
    }
    if (dataType == "Empty String") {
      disableValue = true;
    }
    if (sensitive == true) {
      datatype = "password";
    }
    return (
      <FormControl
        type={datatype}
        placeholder="value"
        value={value}
        style={customForm ? { width: 130 } : {}}
        onChange={(e) => onChange(e.target.value)}
        inputRef={(ref) => {
          this.inputRef = ref;
        }}
        disabled={disableValue}
      />
    );
  }
}
class ManageEnvVars extends Component {
  constructor() {
    super();
    this.state = {
      selectedSensitive: "",
      selectedBlankString: "",
      envVars: null,
      disableSave: false,
    };
  }

  componentWillMount() {
    const { ev } = this.props;
    if (ev && ev.length > 0) {
      this.setState({ envVars: ev[0].credentialsJson.envVars });
    }
  }

  getValidationState() {
    let data = this.state.envVars.filter((el) => !el.name || el.name == "");
    if (data && data.length === 0) {
      if (this.props.customForm) this.props.enable();
      else this.setState({ disableSave: false });
    } else {
      if (this.props.customForm) this.props.disable();
      else this.setState({ disableSave: true });
    }
  }

  dataChangeHandle(v, type, ev) {
    if (type == "Value") {
      let value = v;
      if (ev.datatype == "Number") {
        value = parseInt(v, 10);
      }
      this.setState({
        envVars: this.state.envVars.map((el) =>
          el.id === ev.id ? { ...el, value } : el
        ),
      });
    } else if (type == "Name") {
      let name = v;
      this.setState(
        {
          envVars: this.state.envVars.map((el) =>
            el.id === ev.id ? Object.assign({}, el, { name }) : el
          ),
        },
        () => {
          this.getValidationState();
        }
      );
    } else if (type == "Sensitive") {
      let sensitive = !v;
      this.setState({
        envVars: this.state.envVars.map((el) =>
          el.id === ev.id ? Object.assign({}, el, { sensitive }) : el
        ),
      });
    } else if (type == "Blank") {
      let data = this.state.envVars.filter((el) => el.id === ev.id);
      if (data[0].datatype === "Empty String") {
        let datatype = "";
        this.setState({
          envVars: this.state.envVars.map((el) =>
            el.id === ev.id ? Object.assign({}, el, { datatype }) : el
          ),
        });
        return;
      }
      let datatype = v;
      let value = "";
      let sensitive = false;
      this.setState({
        envVars: this.state.envVars.map((el) =>
          el.id === ev.id
            ? Object.assign({}, el, { datatype, value, sensitive })
            : el
        ),
      });
    } else if (type == "DT") {
      let datatype = v;
      let value = ev.value;
      if (v === "String") {
        value = value.toString();
      } else if (v === "Number") {
        value = parseInt(value, 10);
      }
      this.setState({
        envVars: this.state.envVars.map((el) =>
          el.id === ev.id ? Object.assign({}, el, { datatype, value }) : el
        ),
      });
    }
  }

  populateEV() {
    let envElements = [];
    const { envVars } = this.state;
    const { customForm } = this.props;
    if (envVars && envVars.length > 0) {
      envVars.map((e) => {
        let isBlank = false;
        if (e.datatype === "Empty String") {
          isBlank = true;
        }
        return envElements.push(
          <tr>
            <td>
              <EnvName
                ename={e.name}
                customForm={customForm}
                onChange={(v) => this.dataChangeHandle(v, "Name", e)}
              />
            </td>

            <td>
              <EnvValue
                value={e.value}
                customForm={customForm}
                sensitive={e.sensitive}
                dataType={e.datatype}
                onChange={(v) => this.dataChangeHandle(v, "Value", e)}
              />
            </td>

            <td>
              <DropdownButton
                id="datatypes"
                placeholder="data type"
                title={e.datatype || "data type"}
                bsStyle="default"
                style={{ width: 160 }}
                className="environments-dropdown"
              >
                {datatypes.map((p, i) => (
                  <MenuItem
                    value={e.datatype || p}
                    key={i}
                    onClick={(v) => this.dataChangeHandle(p, "DT", e)}
                  >
                    {p}
                  </MenuItem>
                ))}
              </DropdownButton>
            </td>

            <td
              onClick={() => this.dataChangeHandle(e.sensitive, "Sensitive", e)}
            >
              {!e.sensitive ? (
                <div style={{ ...styles.envBox, background: "#DFE0E0" }} />
              ) : (
                <img
                  style={{ ...styles.checkBox }}
                  src={images.checkbox_connector}
                ></img>
              )}
            </td>
            <td
              onClick={() => this.dataChangeHandle("Empty String", "Blank", e)}
            >
              {!isBlank ? (
                <div style={{ ...styles.envBox, background: "#DFE0E0" }} />
              ) : (
                <img
                  style={{ ...styles.checkBox }}
                  src={images.checkbox_connector}
                ></img>
              )}
            </td>
            <td>
              <img
                key={e.id}
                src={images.delete_env_var}
                style={{ width: 28, height: 28 }}
                alt="delete"
                onClick={() => this.handleDelete(e.id)}
              />
            </td>
          </tr>
        );
      });
    }
    return envElements;
  }

  handleDelete = (id) => {
    const newEV = this.state.envVars.filter((ev) => {
      return ev.id !== id;
    });
    this.setState(
      {
        envVars: [...newEV],
      },
      () => {
        this.getValidationState();
      }
    );
  };

  addNewSensitiveVar = (e) => {
    e.preventDefault();
    const { envVars } = this.state;
    let newVar = {};
    if (envVars && envVars.length > 0) {
      let maxId = envVars.map((ev) => ev.id).sort((a, b) => a - b)[
        envVars.length - 1
      ];
      newVar = {
        id: maxId + 1,
        sensitive: true,
        datatype: "String",
      };
      this.setState({
        envVars: [...this.state.envVars, newVar],
        disableSave: true,
      });
      if (this.props.customForm) this.props.disable();
    } else {
      let vars = [];
      newVar["id"] = 1;
      newVar["sensitive"] = true;
      newVar["datatype"] = "String";
      vars.push(newVar);
      this.setState({ envVars: vars, disableSave: true });
      if (this.props.customForm) this.props.disable();
    }
  };

  addNewDefaultVar = (e) => {
    e.preventDefault();
    const { envVars } = this.state;
    let newVar = {};
    if (envVars && envVars.length > 0) {
      let maxId = envVars.map((ev) => ev.id).sort((a, b) => a - b)[
        envVars.length - 1
      ];
      newVar = {
        id: maxId + 1,
        datatype: "String",
      };
      this.setState({
        envVars: [...this.state.envVars, newVar],
        disableSave: true,
      });
      if (this.props.customForm) this.props.disable();
    } else {
      let vars = [];
      newVar["id"] = 1;
      newVar["datatype"] = "String";
      vars.push(newVar);
      this.setState({ envVars: vars, disableSave: true });
      if (this.props.customForm) this.props.disable();
    }
  };

  backToInitial = (e) => {
    // const {ev} = this.props
    // if (ev.length > 0 ) {
    //   this.setState({ envVars: ev[0].credentialsJson.envVars })
    // } else {
    //   this.setState({ envVars: {} })
    // }
    this.props.hideManageEnv();
  };

  saveEnvVars = (e) => {
    const { env, redux, match, actions, customForm, hideNewConnector } =
      this.props;
    const { params } = match;
    const { project, main } = redux;
    const { appId } = params;
    const { envVars } = this.state;
    let creds = {};
    creds["envVars"] = envVars;
    const providerConfig = main.providers.find(
      (p) => p.type === "EnvironmentVariables"
    );
    actions
      .postCredentials(
        project.tenantInfo.TenantId,
        appId,
        providerConfig,
        creds,
        env.environmentId,
        false,
        "environmentvariables",
        "EnvironmentVariables",
        "environmentvariables"
      )
      .then(() => {
        if (customForm) hideNewConnector();
        actions.getAllCredentialsForEnvironment(
          project.tenantInfo.TenantId,
          appId,
          env.name
        );
      });
  };

  render() {
    const { customForm, redux } = this.props;
    const { project, main } = redux;
    let elevio_category_id;
    if (customForm) {
      const environmentConfig = main.providers.find((p) => {
        return p.type == "EnvironmentVariables";
      });
      elevio_category_id = environmentConfig.elevioArticleId;
    }
    let envVars = null;
    envVars = this.populateEV();
    return (
      <div style={!customForm ? { height: 500 } : {}}>
        <div style={customForm ? { display: "flex" } : null}>
          <div
            style={{
              background: "#EEE",
              marginRight: 20,
              marginLeft: customForm ? null : 30,

              marginTop: customForm ? 10 : "",
              width: customForm ? "40vw" : "",
              height: customForm ? "75vh" : "80%",
              overflowY: "auto",
            }}
          >
            <Table striped bordered condensed hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th>Data Types</th>
                  <th>Sensitive Value</th>
                  <th>Blank String</th>
                </tr>
              </thead>
              <tbody>{envVars}</tbody>
            </Table>
          </div>
          {elevio_category_id && customForm ? (
            <div
              style={{
                float: "right",
                marginTop: 9,
                border: "1px solid #dfe0e0",
              }}
            >
              {" "}
              <div
                style={{
                  width: "35vw",
                  height: "75vh",
                  float: "right",
                  overflow: "auto",
                  border: "1px solid #dfe0e0",
                  padding: 15,
                  background: "white",
                }}
              >
                <elevio-element
                  data-type="article"
                  data-id={elevio_category_id}
                ></elevio-element>{" "}
              </div>
            </div>
          ) : null}
        </div>
        <div style={{ marginBottom: 50 }}>
          <Button
            bsStyle="default"
            bsSize="small"
            style={{
              width: 175,
              fontWeight: "bold",
              marginLeft: customForm ? 200 : 400,
              position: "absolute",
              height: 36,
              marginTop: 10,
            }}
            onClick={(e) => this.addNewSensitiveVar(e)}
          >
            <img
              src={images.addbox}
              style={{ height: 20, width: 20, marginRight: 5 }}
            ></img>
            Add a sensitive variable
          </Button>
          <Button
            bsStyle="default"
            bsSize="small"
            style={{
              width: 140,
              fontWeight: "bold",
              marginLeft: customForm ? 400 : 600,
              position: "absolute",
              height: 36,
              marginTop: 10,
            }}
            onClick={(e) => this.addNewDefaultVar(e)}
          >
            <img
              src={images.addbox}
              style={{ height: 20, width: 20, marginRight: 5 }}
            ></img>
            Add a variable
          </Button>
          {!customForm && (
            <div style={{ display: "inline-block" }}>
              <Button
                bsStyle="primary"
                bsSize="small"
                style={{
                  width: 115,
                  fontWeight: "bold",
                  left: "1%",
                  position: "absolute",
                  height: 36,
                }}
                onClick={(e) => this.backToInitial(e)}
              >
                Cancel
              </Button>
              <Button
                bsStyle="primary"
                bsSize="small"
                style={{
                  width: 115,
                  fontWeight: "bold",
                  right: "7%",
                  position: "absolute",
                  height: 36,
                }}
                onClick={(e) => this.saveEnvVars(e)}
                disabled={this.state.disableSave}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export { ManageEnvVars, EnvName, EnvValue };
