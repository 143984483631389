import React, { Component } from 'react';
import { Button, DropdownButton } from 'react-bootstrap';
import BoxWizard from './boxwizard.js'
import images from '../images';
import SettingsStyle from '../../styles/settingsstyle';
import ConfirmDeleteModal from '../utils/confirmDeleteModal'


export default class WizardList extends Component {
  constructor() {
    super();
    this.state = {
      type:null,
      orderAlpha: false,
      typeDropDownOpened: false,
      typeDropDownForceOpen: false,
      selectedForDelete: null,
      isDeleting: null,
      modal: false,
      sortType: 1,
    };
    this.newWizard = this.newWizard.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.sortAlphabetically = this.sortAlphabetically.bind(this);
    this.sortTimeWise = this.sortTimeWise.bind(this);
    this.selectType = this.selectType.bind(this);
    this.onToggleType = this.onToggleType.bind(this);
    this.sortTrans = this.sortTrans.bind(this);

    this.handleSelectEdit = this.handleSelectEdit.bind(this);
    this.warnBeforeDelete = this.warnBeforeDelete.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
    this.deleteRecipe = this.deleteRecipe.bind(this);
  }

  onToggleType(v){
    window.setTimeout(()=>{
      if(!this.state.typeDropDownForceOpen){
        this.setState({typeDropDownOpened: v})
      }
    },10)
  }
  selectType(){
    this.setState({typeDropDownForceOpen: true})
    window.setTimeout(()=>{
      this.setState({typeDropDownForceOpen: false})
    },100)
  }
  handleClick(event) {
    if (!event.target.checked || event.target.value === 'ALL') {
      this.setState({type: null});
    } else {
      this.setState({type: event.target.value});
    }
  }
  newWizard() {
    const { appId } = this.props.match.params
    this.props.history.push(`/a/${appId}/wiz/new`);
  }

  sortTrans(){
    let {sortType} = this.state
    sortType++
    if (sortType > 1){
      sortType = 0
    }
    this.setState({sortType})
  }

  sortAlphabetically() {
    this.setState({orderAlpha: true});
  }

  sortTimeWise() {
    this.setState({orderAlpha: false});
  }

  handleSelectEdit(a, baseId, piGuid, typeId, appId, c){
    c.stopPropagation()
     this.props.history.push({
        pathname:`/a/${appId}/wiz/${baseId}`,
        state: {piGuid, appId, typeId}
      })
  }

  warnBeforeDelete(a,e,c){
    c.stopPropagation()
    this.setState({selectedForDelete: e})
    this.triggerModal()
  }

  deleteRecipe(){
    const { appId } = this.props.match.params
    const { project } = this.props.redux
    const piGuid = this.state.selectedForDelete.ProductizedIntegrationGuid

    this.props.actions.callDeleteWizard(project.tenantInfo.TenantId, appId, piGuid)
    this.triggerModal()
    this.setState({selectedForDelete:null,
                    isDeleting: piGuid})
  }

  triggerModal(){
    let modal = this.state.modal
    modal = !modal
    this.setState({modal})
  }

  render() {
    const { project, main } = this.props.redux

    // wizard types
    if(!(main && main.wizards)) return <div />

    let transRows = [];
    const types = {};
    if (project.wizards && project.wizards.length > 0) {
      const objs = [...project.wizards];
      for (let i = 0; i < objs.length; i++) {
        const wiz = main.wizards.find(w=>w.typeId===objs[i].IntegrationTypeId)
        if (!this.state.type || wiz.title === this.state.type) {
          transRows.push(<BoxWizard
            history={this.props.history}
            wiz={wiz}
            baseId={objs[i].TransformerBaseId}
            name={objs[i].Name}
            handleSelectEdit={this.handleSelectEdit}
            warnBeforeDelete={this.warnBeforeDelete}
            isDeleting={this.state.isDeleting}
            obj={objs[i]}
            typeId={objs[i].IntegrationTypeId}
            appId={this.props.match.params.appId}
            piGuid={objs[i].ProductizedIntegrationGuid}
            key={i} lastModified={objs[i].LastModified}
            configs={objs[i].Configuration}
          />);
        }
        types[wiz.title] = 1;
      }
      if (this.state.sortType < 1) {
        transRows.sort((a, b)=>{
          if (a.props.name < b.props.name) return -1;
          else if (a.props.name > b.props.name) return 1;
          return 0;
        });
      } else {
        transRows.sort((a, b)=>{
          if (a.props.lastModified < b.props.lastModified) return 1;
          else if (a.props.lastModified > b.props.lastModified) return -1;
          return 0;
        });
      }
    } else {

      transRows= (<div className="none-env">
                <span style={{ fontSize: 16 }}>No Integration Wizards.</span>
                <br />
                <Button
                  bsSize="small"
                  bsStyle="primary"
                  style={{ marginTop: 6 }}
                  onClick={this.newWizard} >
                  New Wizard
                </Button>
              </div>);
    }

    const typeFilter = [];
    typeFilter.push(<div key="all" style={{marginLeft:18}}>
      <input className="form-control-checkbox" defaultChecked
      onClick={this.handleClick} type="radio" name="type" value="ALL" />
        ALL</div>);

    for (const key in types) {
      if (types.hasOwnProperty(key)) {
        typeFilter.push(<div key={key} style={{marginLeft:18}}><input className="form-control-checkbox" defaultChecked={false}
          onClick={this.handleClick} type="radio" name="type" value={key} />{key}</div>);
      }
    }

    return (
      <div className="parent-env" style={{ marginTop:50, paddingTop:15, paddingBottom:50 }}>
      <div className="main-tab-content">
        <div
          style={{
            margin: '0px 0px 15px 0px',
            fontSize: '20px',
            display: 'inline-block'
          }}>
          Integration Wizards
        </div>
        {project.wizards && project.wizards.length > 0 &&
          <div className="main-create-btn-env">
          <Button bsSize="small" bsStyle="primary" style={{marginRight: 10}}
            onClick={this.newWizard} 
            disabled={!project.wizards || !project.availableWizardTypes}>
            New Wizard
          </Button>  

          { project.wizards && project.wizards.length > 0 ?
          
          <div style={{display:'inline-block',float:'right',marginRight:28}}>
            
            <Button bsSize="small"  onClick={this.sortTrans} style={{background: '#ddd', marginRight: 10, width: 70, border: '1px solid #666'}}>
              <img src={images.ic_sort_grey600_48dp}
                style={SettingsStyle.iconStyles} alt="alpha" />
                {this.state.sortType ? 
                <span>Date</span>
                :
                <span>Abc</span>
                }
            </Button>
            <div className="wizard-type-dropdown">
              <DropdownButton onToggle={this.onToggleType} open={this.state.typeDropDownOpened} onSelect={this.selectType} title="Type"
                bsSize="small" bsStyle="default"
                style={{marginRight:4, height: 31, background: '#ddd', border: '1px solid #666'}} id="type">
                {typeFilter}
              </DropdownButton>
            </div>
          </div>
        : null }

          </div>
        }     
        
        {project.wizards ? <div>
          {transRows}
        </div> : <div className="ajax-loader">
          <img alt="spinner" src={images.ajax_loader} />
        </div>}

        <ConfirmDeleteModal 
          modalState={this.state.modal}
          triggerModal={this.triggerModal}
          nameOfTarget={this.state.selectedForDelete !== null && this.state.selectedForDelete.Name}
          deleteTarget={this.deleteRecipe}
        />

      </div>
      </div>
    );
  }
}
