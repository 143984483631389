import React, { Component } from 'react';
import images from '../images'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as projectActions from '../../shared/redux/actions/projectActions';
import * as mainActions from '../../shared/redux/actions/mainActions';
import * as transformerActions from '../../shared/redux/actions/transformerActions';
import * as datafeedActions from '../../shared/redux/actions/datafeedActions';
import * as wizardActions from '../../shared/redux/actions/wizardActions';
import * as environmentActions from '../../shared/redux/actions/environmentActions';
import * as eventsActions from '../../shared/redux/actions/eventsActions';
class AccountBox extends Component {
  doTransition = (e, newPath, state) => {
    const { actions, account, template, wizardId } = this.props
    const { applicationid, tenantid } = account
    actions.setAppInfo(account, tenantid)
    e.stopPropagation()
    setTimeout(() => {
      if (template) {
        this.props.history.push({
          pathname: `/a/${applicationid}${newPath}/tf/new`,
          state: {
            template
          }
        })
      } else if (wizardId) {
        this.props.history.push({
          pathname: `/a/${account.Id}/wiz/start/${wizardId}`,
          state: {
            accountName: account.name,
            accountKey: account.uniquekey,
          }
        })
      } else {
        this.props.history.push({
          pathname: `/a/${applicationid}${newPath}/dash`,
          state
        })
      }
    }, 10)
  }

  render() {
    const styles = {
      foldOut: {
        position: 'absolute',
        right: -80,
      },
      lastModified: {
        overflow: 'hidden',
        border: '0px',
        paddingBottom: 6,
        //color: '#f0f0f0',
        fontSize: 12,
        textAlign: 'right',
        float: 'right',
        width: '100%'
      },
      category: {
        padding: '2px 4px 0px 4px',
        //backgroundColor:'#dddddd',
        fontSize: 10,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        height: 20,
        maxWidth: '100%',
        display: 'inline-block',
        verticalAlign: 'top'
      },
      appName: {
        verticalAlign: 'top',
        width: '100%',
        fontSize: 13,
        //fontWeight:'bold',
        //color: 'rgb(51, 122, 183)',
        float: 'left',
      },
      description: {
        width: '100%',
        fontSize: '11px',
        marginTop: '3px'
      },
      tag: {
        float: 'left',
        fontSize: 12,
        marginTop: 7,
        //color: '#404040',
        maxWidth: '100%'
      },
      highlight: {
        display: 'inline-block',
        width: 4,
        height: 18,
        marginRight: 8,
        verticalAlign: 'top'
      }
    }
    const { highlight } = this.props
    const { name, integrationCount, tenantName } = this.props.account
    return (

      <div className="account-box" onClick={e => this.doTransition(e, '', null)}>
        <div style={styles.appName}>
          {highlight ? <div style={{ ...styles.highlight, background: highlight }} />
            :
            <div style={{ ...styles.highlight, background: '#fff' }} />
          }
          <div style={{
            width: 206,
            overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
          }}>
            {name}
          </div>
        </div>
        {tenantName ? <div style={{ maxWidth: '100%', overflow: 'hidden', marginTop: -10, marginLeft: 9, float: 'left' }}>
          <div style={styles.tag}>
            <div style={styles.category}>{tenantName}</div>
          </div>
        </div>
          :
          <div style={{ maxWidth: '100%', overflow: 'hidden', marginTop: 0, marginLeft: 9, float: 'left' }}>
            <img alt="spinner" src={images.ajax_loader} />
          </div>
        }

        <div className="al-foldout">
          {/*}
          <div>
            {moment(lastmodified, 'YYYY-MM-DDTHH:mm:ss.SZ').fromNow()}
          </div>
        */}

          {tenantName ?
            <div style={{ fontSize: 11, marginTop: 26, }}>
              <img
                role="presentation"
                src={images.description}
                height="12px"
                style={{ display: 'inline-block', marginTop: -3 }}

              />
              <span>
                &nbsp;{integrationCount && integrationCount}</span>
            </div>
            :
            <div style={{ fontSize: 11, marginTop: 26, }}>
              <img
                role="presentation"
                src={images.description}
                height="12px"
                style={{ display: 'inline-block', marginTop: -3 }}
              />
            </div>}
        </div>

      </div>



    );
  }
}
const mapStateToProps = state => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...mainActions,
    ...projectActions,
    ...transformerActions,
    ...datafeedActions,
    ...wizardActions,
    ...environmentActions,
    ...eventsActions,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountBox);