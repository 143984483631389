import React, { PureComponent } from "react";
import { parseTimeValue, getValues, getValue } from "../../utils";
import Select from "./Select";

export default class TimeInput extends PureComponent {
  onChange = (onChange) => {
    return (value) => {
      if (value instanceof Array) {
        onChange(getValues(value));
      } else {
        onChange(getValue(value));
      }
    };
  };

  render() {
    const { styleNameFactory, value, onChange, isDisable } = this.props;
    return (
      <Select
        {...this.props}
        {...styleNameFactory("input")}
        value={parseTimeValue(value)}
        onChange={this.onChange(onChange)}
        disabled={isDisable}
      />
    );
  }
}
