

import React, {PureComponent} from 'react'
import noop from 'lodash/noop'

export default class Tab extends PureComponent {
    static defaultProps = {
        children: null,
        onClick: noop
    };

    render() {
        const {isActive, children, styleNameFactory, onClick} = this.props;
        return (
            <button
                type="button"
                {...styleNameFactory('tab', {active: isActive})}
                onClick={onClick}
            >
                {children}
            </button>
        )
    }
}
