import React, { Component } from 'react';

import ContentWsStyles from '../../styles/contentwsstyle.js';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";
 class AddUser extends Component {
  constructor() {
    super();
    this.state={
      email:'',
      pass1:'',
      pass2:'',
      access:null,
      tenant:null
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillMount(){
    const {redux} = this.props
    const {main} = redux
    const pathArray = this.props.location.pathname.split('/');
    const tenant = main.tenantList && main.tenantList.find((t)=>{
      return t.TenantId===parseInt(pathArray[2], 10)
    })
    if(tenant){
      this.setState({tenant})
    } else {
      this.props.history.push('/user')
    }

  }
  callCreateUser(username, password, isOwner) {
    const {tenant} = this.state
    this.props.actions.callCreateUser(tenant.TenantId, {
      username,
      password,
      admin: isOwner,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    const username = this.state.email;
    const password = this.state.pass1;
    const password2 = this.state.pass2;
    if (!username && !password) return;
    if (password !== password2) {
      alert('Passwords must match.');
      return;
    }
    if (!this.validateEmail(username)) {
      alert('Username should be a valid email address.');
      return;
    }
    if (!this.validatePassword(password)) {
      alert('Password should have minimum 8 characters at least 1 Uppercase Alphabet, 1 Lowercase Alphabet and 1 Number.');
      return;
    }
    const isOwner = this.state.access==='Administrator';
    const isUser = this.state.access==='Developer';
    if (!isOwner && !isUser) return;

    this.callCreateUser(username, password, isOwner);
    this.props.history.push('/user');
  }
  validatePassword(password) {
    const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
    return re.test(password);
  }
  validateEmail(email) {
    const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }
  render() {
    const {tenant} = this.state
    return (
      <div>
        <form className="form-horizontal" onSubmit={this.handleSubmit}>
          <div style={{padding: '20px 12px'}}>
            <div style={ContentWsStyles.titleStyles}>
              {`Add User${tenant?` (${tenant.Name})`:''}`}
            </div>
            <div style={ContentWsStyles.formElementTitle}> Email</div>
            <div>
              <input
                className="form-control"
                type="text"
                placeholder="Email"
                //ref="username"
                value={this.state.email}
                onChange={e=>this.setState({email:e.target.value})}
              />
            </div>
            <div style={ContentWsStyles.formElementTitle}>Password</div>
            <div>
              <input
                className="form-control"
                type="password"
                placeholder="Password"
                value={this.state.pass1}
                onChange={e=>this.setState({pass1:e.target.value})}
              />
            </div>
            <div style={ContentWsStyles.formElementTitle}> Retype Password</div>
            <div>
              <input
                className="form-control"
                type="password"
                placeholder="Password"
                value={this.state.pass2}
                onChange={e=>this.setState({pass2:e.target.value})}
              />
            </div>
            <div style={ContentWsStyles.formElementTitle}>Access</div>
            <div className="checkbox">
              <label htmlFor="add-user" className="form-control-left">
                <input
                  className="form-control-left"
                  type="radio"
                  id="r1"
                  name="access"
                  checked={this.state.access==='Administrator'}
                  onChange={()=>this.setState({access:'Administrator'})}
                />
                &nbsp;
                Administrator
              </label>
            </div>
            {/*<div className="checkbox" style={{ color: 'grey' }}>
              <label htmlFor="access" className="form-control-left">
                <input
                  className="form-control-left"
                  type="radio"
                  name="access"
                  disabled
                />&nbsp;
                Data Observer (coming soon)
              </label>
            </div>*/}
            <div className="checkbox">
              <label htmlFor="data-dev" className="form-control-left">
                <input
                  className="form-control-left"
                  type="radio"
                  id="r2"
                  name="access"
                  checked={this.state.access==='Developer'}
                  onChange={()=>this.setState({access:'Developer'})}
                />&nbsp;
                Developer
              </label>
            </div>
            <div className="checkbox">
              <label htmlFor="data-dev" className="form-control-left">
                <input
                  className="form-control-left"
                  type="radio"
                  id="r3"
                  name="access"
                  checked={this.state.access==='Operations'}
                  onChange={()=>this.setState({access:'Operations'})}
                />&nbsp;
                Operations
              </label>
            </div>
            <div className="checkbox">
              <label htmlFor="data-dev" className="form-control-left">
                <input
                  className="form-control-left"
                  type="radio"
                  id="r4"
                  name="access"
                  checked={this.state.access==='Support'}
                  onChange={()=>this.setState({access:'Support'})}
                />&nbsp;
                Support
              </label>
              <br />
              <br />
            </div>
            <div>
              <button
                type="submit"
                value="Post"
                className="btn btn-sm btn-primary"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = state => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);