import React, {Component} from 'react'
import MetadataDropdown from '@lingk/sync/build/metadataDropdown'
import {Checkbox} from 'react-bootstrap'
import * as inputs from '@lingk/sync/build/lingk'
import _columns from './columns'
import images from '../images'


const colors = {
  ok: '#e2f8e2',
  error:'#fcc8c8',
  warning:'#fcd1ab'
}

class InputCell extends Component {
  componentDidMount(){
    this.inputRef.focus()
  }
  render(){
    const {value, onChange, onKeyDown, col} = this.props
    return (<input className="data-editor" value={value}
      onChange={(e)=>onChange(e.target.value)} onKeyDown={onKeyDown}
      ref={(ref)=>this.inputRef=ref}
      style={{
        border:'1px solid rgb(33, 133, 208)',height:26,
        width:_columns[col].width,
        textAlign:'left',paddingLeft:7
      }}
    />)
  }
}

class TextareaCell extends Component {
  constructor(){
    super()
    this.state={
      textareaHeight:26
    }
  }
  componentDidMount(){
    this.inputRef.focus()
    this.checkRows()
  }
  checkRows = () => {
    this.setState({textareaHeight:this.inputRef.scrollHeight+2})
  }
  render(){
    const {value, onChange, onKeyDown, col} = this.props
    return (<textarea className="data-editor" value={value}
      onChange={(e)=>onChange(e.target.value)} 
      onKeyDown={(e)=>{
        if(e.keyCode!==13) onKeyDown(e)
      }}
      ref={(ref)=>this.inputRef=ref}
      rows="1" data-min-rows="1"
      onKeyUp={this.checkRows}
      style={{
        border:'2px solid rgb(33, 133, 208)',
        width:_columns[col].width+1,
        maxWidth:_columns[col].width+1,
        minWidth:_columns[col].width+1,
        textAlign:'left',
        paddingLeft:'0 7px',
        height:this.state.textareaHeight,
        minHeight:this.state.textareaHeight,
        maxHeight:this.state.textareaHeight,
        overflow: 'hidden',
        resize:'none',zIndex:99,
        position:'absolute',top:-1,left:-1,right:0,
        boxSizing:'padding-box'
      }}
    />)
  }
}

const CheckboxCell = (props) => {
  const {disabled, onChange, cell} = props
  return (<div><Checkbox
    disabled={disabled}
    style={{margin:'0 10px',height:22}}
    defaultChecked={cell.value==='✔'}
    onChange={(e)=>onChange(e.target.checked)}
  /></div>)
}

const getBackgroundColor = (rowColor, column) => {
  let color = ''
  if(column.name==='Type' && rowColor==='typeWarning'){
    color = colors['warning']
  } else {
    color = colors[rowColor] || ''
  }
  return color
}

const CellRenderer = (props) => {
  const {row,col,cell,children,editing,attributesRenderer,updated,
    sheetRowIndex,sheetColumnIndex,rowColors,readOnly,headerWidths,
    ...rest
  } = props
  const _c = _columns[col]
  let style;
  if(row&&col&&cell&&editing&&updated&&attributesRenderer&&sheetRowIndex&&sheetColumnIndex){
    style={}
  }
  style = {
    height:27,
    textAlign:'left',
    lineHeight:'22px',
    position:'relative'
  }
  const bg = getBackgroundColor(rowColors[row], _c)
  if(bg){
    style.background=bg
  } else if(readOnly){
    style.background='white'
  }
  style.width = headerWidths[col]
  if(!editing){
    style.paddingLeft = 8
  }
  if(_c.readOnly){
    style.color='#555555'
  }
  if(readOnly){
    cell.readOnly = true
    style.color='black' 
  }
  return (
    <td {...rest} style={style} 
    onClick={()=>{
      if(!editing && !_c.readOnly) {
        props.onDoubleClick()
      }
    }}>
      {children}
    </td>
  )
}

const MetadataCell = (props) => {
  const {column, metadata, provider, disabled, showApiNames, fullVal, onDelete, onKeyDown} = props
  let fieldPropLabel = column.isField?'label':'name'
  let fieldPropType = column.isField?'name':'type'
  return (<div onKeyDown={(e)=>{
    if(e.keyCode===8){
      //onDelete()
    }
    if(e.keyCode===9){//tab
      onKeyDown(e)
    }
  }}>
    <MetadataDropdown inputs={inputs} type="brand"
      style={{width:column.width,
        borderRadius:0,height:26,lineHeight:'14px'}} 
      minWidth={260} 
      fieldPropLabel={fieldPropLabel} 
      fieldPropType={fieldPropType} 
      showApiNames={showApiNames}
      onSelect={(v,b,full)=>{
        props.onCommit(full)
      }}
      values={[metadata]} 
      label={column.name+'s'} 
      inputs={inputs} 
      sectionLabelSuffix={column.isField?'Fields':'Objects'}
      sectionLabels={[provider]}
      disabled={disabled || !metadata}
      selectedValues={[fullVal||{}]}
      autoOpen
    />
  </div>)
}

const EnvCheckIcons = ({result, checking}) => {
  return (<div style={{display:'inline-block',verticalAlign:'bottom',width:23}}>
    {checking ? 
      <img alt="spinner" src={images.ajax_loader} 
        style={{height:16, marginBottom:6}}
      /> : 
    result ? <span>
      {result === '200 OK' ? <svg fill="#019644" height="20" viewBox="0 0 24 24" width="20">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
      </svg> :
      <svg fill="#EA5A5A" height="20" viewBox="0 0 24 24" width="20">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
      </svg>}
    </span>:<span/>}
  </div>)
}

const DeleteRow = ({onDelete}) => {
  return (<div style={{height:27,paddingTop:5}}>
    <svg className="delete-mapper-row" viewBox="0 0 60 60"
      onClick={onDelete}>
      <path style={{strokeWidth:5, strokeLinecap: 'round'}}
      d="M 15.674663,15.572746 L 44.587629,44.485711 M 45.118838,15.420972 L 15.522889,45.016920" />
    </svg>
  </div>)
}

export {CheckboxCell, getBackgroundColor, CellRenderer, MetadataCell, EnvCheckIcons, DeleteRow, InputCell, TextareaCell}