import React, { Component } from "react";

import { Button, FormControl, Modal, Checkbox } from "react-bootstrap";
import Draggable from "react-draggable";
import EnvBar from "./envBars";
import images from "../images";
import ConfirmDeleteModal from "../utils/confirmDeleteModal";
const { Header, Body, Footer, Title } = Modal;

export default class Environments extends Component {
  constructor() {
    super();
    this.envBar = React.createRef();
    this.state = {
      selectedEnv: null,
      newEnvName: "",
      savingEnv: false,
      showCreateNewEnv: false,
      yPositions: [],
      envToBeDeleted: null,
      envToEdit: null,
      modal: false,
      showEditEnv: false,
      dependenciesExist: true,
      loader: true,
      dependencies: {},
    };
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
  }

  componentWillUnmount() {
    this.setState({ selectEnv: null });

    const { history, actions } = this.props;
    const { location } = history;
    const { state } = location;
    if (state && !state.showFirstTimeUserExp) {
      actions.updateSessionEnvironment(null);
      this.props.actions.clearCredentialsForEnvironment();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { redux } = this.props;
    const { project } = redux;
    let path = window.location.href;
    let envID = path.substr(path.length - 3);
    if (envID === "env") {
      if (this.envBar && this.envBar.current)
        this.envBar.current.setNewUserExp();
      if (this.state.selectedEnv != prevState.selectEnv) {
        this.setState({ selectedEnv: null });
        this.props.actions.clearCredentialsForEnvironment();
        this.props.actions.clearEnvironmentMonitoringPreferences();
        this.props.actions.setSelectedEnv(null);
      }
    } else if (
      this.state.selectedEnv != prevState.selectedEnv &&
      envID != "env"
    ) {
      const se =
        project.environments &&
        project.environments.find((f) => f.environmentId == envID);
      if (se) {
        this.setState({ selectedEnv: se });
      }
    } else if (!this.state.selectedEnv && envID != "env") {
      const se =
        project.environments &&
        project.environments.find((f) => f.environmentId == envID);
      if (se) {
        this.setState({ selectedEnv: se });
      }
    }
  }

  componentDidMount() {
    const { sessionEnvironment } = this.props.redux.environment;
    const { redux, actions } = this.props;
    const { project } = redux;
    let path = window.location.href;
    let envID = path.substr(path.length - 3);
    if (sessionEnvironment || envID != "env") {
      const se =
      project.environments &&
      project.environments.find((f) => f.environmentId == envID);
      if (se) {
        actions.updateSessionEnvironment(se.id);
        this.setState({ selectedEnv: se });
      }
    }else{
      this.props.actions.clearCredentialsForEnvironment();
    }
  }

  switchEnv(envId) {
    const { actions, match, redux } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    const { environments } = project;
    let env = environments && environments.find((e) => e.id == envId);
    this.setState({ selectedEnv: env });
    if (env && env.name) actions.setSelectedEnv(env.name);
    actions.getAllCredentialsForEnvironment(
      project.tenantInfo.TenantId,
      appId,
      env.name
    );
    actions.getMonitoringPreferences(
      project.tenantInfo.TenantId,
      appId,
      env.id
    );
    actions.setSelectedEnv(env.name);
  }

  loadEnvironment = (env) => {
    this.setState({ selectedEnv: env });
    const { actions, match, redux } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    actions.setSelectedEnv(env.name);
    actions.getAllCredentialsForEnvironment(
      project.tenantInfo.TenantId,
      appId,
      env.name
    );
    actions.getMonitoringPreferences(
      project.tenantInfo.TenantId,
      appId,
      env.id
    );
    this.props.history.push({
      pathname: "/a/" + this.props.match.params.appId + "/env/" + env.id,
    });
  };

  createEnvironment = (envName, isProd) => {
    const { actions, match, redux } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    this.setState({ savingEnv: true });
    actions
      .createNewEnvironment(project.tenantInfo.TenantId, appId, envName, isProd)
      .then((newEnv) => {
        this.setState({
          savingEnv: false,
          newEnvName: "",
          //selectedEnv:allEnvs.find(e=>e.name===envName),
          showCreateNewEnv: false,
          selectedEnv: { ...newEnv, environmentId: newEnv.id },
        });
        this.loadEnvironment(newEnv);
      });
  };

  editEnvironment = (envName, isProd, env) => {
    const { actions, match, redux } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    this.setState({ savingEnv: true });
    actions
      .saveChangeToEnv(
        project.tenantInfo.TenantId,
        appId,
        env,
        envName,
        "",
        isProd
      )
      .then(() => {
        this.setState({
          savingEnv: false,
          newEnvName: "",
          selectedEnv: null,
          showEditEnv: false,
        });
      });
  };

  deleteEnvironment = () => {
    const { actions, match, redux } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project, environment } = redux;
    const env = this.state.envToBeDeleted;
    this.setState({ envToBeDeleted: null });
    actions.deleteEnvironment(
      project.tenantInfo.TenantId,
      appId,
      env.environmentId
    );
    actions.deleteMonitoringPreferences({
      tenantid: project.tenantInfo.TenantId,
      appid: parseInt(appId),
      environmentid: env.environmentId,
    });
    this.triggerModal();
  };

  handleDragStart(e, v, i) {
    this.setState({ currentlyBeingDragged: i });
    this.elementReorders = [];
    const yPositions = [];
    this.props.redux.project.environments.forEach((env, idx) => {
      this.elementReorders[idx] = 0;
      yPositions[idx] = 0;
    });
    this.setState({ yPositions });
    this.finalDelta = 0;
  }

  handleDrag(e, v) {
    const i = this.state.currentlyBeingDragged;
    const overlap = 15;
    const height = 32;
    // in its own place
    if (v.y > overlap - height && v.y < height - overlap) {
      this.elementReorders = this.elementReorders.map(() => 0);
      this.finalDelta = 0;
    } else if (v.y >= height - overlap) {
      // dragged down
      for (let j = 1; j < this.elementReorders.length; j++) {
        if (v.y >= j * height - overlap && v.y < (j + 1) * height - overlap) {
          this.finalDelta = j;
          for (let k = 0; k < this.elementReorders.length; k++) {
            this.elementReorders[k] = k > i && k - i - 1 < j ? -1 : 0;
          }
        }
      }
    } else if (v.y <= overlap - height) {
      //dragged up
      for (let jj = 1; jj < this.elementReorders.length; jj++) {
        if (
          v.y <= jj * -height + overlap &&
          v.y > (jj + 1) * -height + overlap
        ) {
          this.finalDelta = -jj;
          for (let kk = 0; kk < this.elementReorders.length; kk++) {
            this.elementReorders[kk] = kk < i && i - kk - 1 < jj ? 1 : 0;
          }
        }
      }
    }

    const yPositions = this.elementReorders.map((n) => n * height);
    if (!this.arraysEqual(yPositions, this.state.yPositions)) {
      this.setState({ yPositions });
    }
  }

  arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = arr1.length; i--; ) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }
  handleDeletion = (e, env) => {
    e.stopPropagation();
    this.setState({ envToBeDeleted: env, loader: true });
    this.triggerModal();
    const { actions, match, redux } = this.props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;

    actions
      .getEnvironmentDependencies(
        project.tenantInfo.TenantId,
        appId,
        env.environmentId
      )
      .then((data) => {
        if (
          !_.isEmpty(data.dataMappings) ||
          !_.isEmpty(data.deployments) ||
          !_.isEmpty(data.deployments) ||
          !_.isEmpty(data.triggers)
        ) {
          this.setState({
            dependenciesExist: true,
            loader: false,
            dependencies: data,
          });
        } else {
          this.setState({ dependenciesExist: false, loader: false });
        }
      });
  };
  handleDragEnd(e) {
    const i = this.state.currentlyBeingDragged;
    if (this.finalDelta) {
      const { actions, match, redux } = this.props;
      const { params } = match;
      const { appId } = params;
      const { project } = redux;
      //this.props.changeEntityOrder(this.props.resourceGroupIndex, i, i+this.finalDelta)
      actions.changeEnvironmentIndexes(
        project.tenantInfo.TenantId,
        appId,
        i,
        i + this.finalDelta
      );
    }
    this.setState({
      yPositions: this.elementReorders.map(() => 0),
      currentlyBeingDragged: null,
    });
    e.stopPropagation();
  }

  triggerModal() {
    let modal = this.state.modal;
    modal = !modal;
    this.setState({ modal });
  }

  render() {
    const { redux, match } = this.props;
    const { project } = redux;
    const { selectedEnv, showCreateNewEnv, savingEnv, showEditEnv, envToEdit } =
      this.state;
    const fullWidth = 983;
    const { params } = match;
    const { appId } = params;
    return (
      <div className="parent-env">
        <div className="main-tab-content" style={{ paddingTop: 15 }}>
          <div className="environments-header" style={{ width: fullWidth }}>
            {!selectedEnv && (
              <div
                style={{
                  margin: "50px 0 15px 0",
                  fontSize: "20px",
                  display: "inline-block",
                }}
              >
                Manage Environments
              </div>
            )}
            {showCreateNewEnv && !selectedEnv && (
              <CreateNewEnv
                hideCreateNewEnv={() =>
                  this.setState({ showCreateNewEnv: false })
                }
                showCreateNewEnv={showCreateNewEnv}
                savingEnv={savingEnv}
                environments={project.environments}
                createEnvironment={this.createEnvironment}
              />
            )}
            {showEditEnv && !selectedEnv && (
              <CreateNewEnv
                hideCreateNewEnv={() => this.setState({ showEditEnv: false })}
                showEditEnv={showEditEnv}
                savingEnv={savingEnv}
                environments={project.environments}
                createEnvironment={this.createEnvironment}
                envToEdit={envToEdit}
                editEnvironment={this.editEnvironment}
              />
            )}

            {!showCreateNewEnv && !selectedEnv && (
              <div className="main-create-btn-env">
                <Button
                  bsSize="small"
                  bsStyle="primary"
                  onClick={() => this.setState({ showCreateNewEnv: true })}
                >
                  {" "}
                  New Environment
                </Button>
              </div>
            )}

            {/* {selectedEnv && (
              <Button
                bsStyle="primary"
                bsSize="small"
                className="leave-single-environment"
                onClick={() => {
                  this.props.actions.clearCredentialsForEnvironment();
                  this.setState({ selectedEnv: null });
                  this.props.actions.callGetEnvironments(
                    project.tenantInfo.TenantId,
                    appId
                  );
                  this.props.history.push({
                    pathname: "/a/" + this.props.match.params.appId + "/env",
                  });
                }}
              >
                Go To Main Menu
              </Button>
            )} */}
          </div>

          {project.environments ? (
            <div style={{ marginTop: 0, width: "100%" }}>
              {project.environments.length > 0 ? (
                <div style={{ position: "relative" }}>
                  {project.environments.map((env, i) => {
                    const selected =
                      selectedEnv && selectedEnv.name === env.name;
                    if (selectedEnv && !selected) return <span key={i} />;
                    return (
                      <Draggable
                        axis="y"
                        bounds="parent"
                        key={i}
                        handle=".env-drag-handle"
                        position={{ x: 0, y: this.state.yPositions[i] }}
                        onStart={(e, v) => this.handleDragStart(e, v, i)}
                        onDrag={this.handleDrag}
                        onStop={this.handleDragEnd}
                      >
                        <div className="env-bar-dragon">
                          {!selected && project.environments.length > 1 && (
                            <div className="env-drag-handle">
                              <DragHandle />
                            </div>
                          )}
                          <EnvBar
                            ref={this.envBar}
                            {...this.props}
                            key={i}
                            selectedEnv={selectedEnv}
                            env={env}
                            selectEnv={() => {
                              if (!selected) {
                                this.props.actions.updateSessionEnvironment(
                                  env.id
                                );
                                this.loadEnvironment(env);
                              } else {
                                this.setState({ selectedEnv: null });
                                this.props.actions.updateSessionEnvironment(
                                  null
                                );
                                this.props.actions.clearEnvironmentMonitoringPreferences();
                                this.props.actions.clearCredentialsForEnvironment();
                              }
                            }}
                            deselect={() => {
                              this.setState({ selectedEnv: null });
                              this.props.actions.clearEnvironmentMonitoringPreferences();
                              this.props.actions.clearCredentialsForEnvironment();
                            }}
                            selected={selected}
                            redux={redux}
                            deleteEnv={(e) => {
                              this.handleDeletion(e, env);
                            }}
                            editEnv={(e) => {
                              e.stopPropagation();
                              this.setState({
                                showEditEnv: true,
                                envToEdit: env,
                              });
                            }}
                          />
                        </div>
                      </Draggable>
                    );
                  })}
                </div>
              ) : (
                <div style={{ marginTop: 6, marginBottom: 8 }}>
                  No Environments found. Please create a new Environment.
                </div>
              )}
            </div>
          ) : (
            <div style={{ margin: 15 }}>
              <img alt="spinner" src={images.ajax_loader} />
            </div>
          )}

          <ConfirmDeleteModal
            modalState={this.state.modal}
            triggerModal={this.triggerModal}
            nameOfTarget={
              this.state.envToBeDeleted !== null &&
              this.state.envToBeDeleted.name
            }
            deleteTarget={this.deleteEnvironment}
            dependenciesExist={this.state.dependenciesExist}
            message="It is associated with following entities. Please resolve the conflicts first."
            warningMsg="The environment can't be deleted"
            loader={this.state.loader}
            dependencies={this.state.dependencies}
          />
        </div>
      </div>
    );
  }
}

const DragHandle = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M9,3H11V5H9V3M13,3H15V5H13V3M9,7H11V9H9V7M13,7H15V9H13V7M9,11H11V13H9V11M13,11H15V13H13V11M9,15H11V17H9V15M13,15H15V17H13V15M9,19H11V21H9V19M13,19H15V21H13V19Z" />
    </svg>
  );
};

class CreateNewEnv extends Component {
  constructor() {
    super();
    this.state = {
      newEnvName: "",
      prod: false,
      submitDisabled: true,
      disableEditProd: null,
    };
  }

  componentDidMount() {
    const { showEditEnv, envToEdit } = this.props;
    if (showEditEnv) {
      this.setState({ newEnvName: envToEdit.name, prod: envToEdit.isProd });
    }
  }

  changeEnvName = (e) => {
    if (/^([a-zA-Z0-9_-]+\s?)*\s*$/.test(e.target.value)) {
      this.setState({ newEnvName: e.target.value, disableEditProd: false });
    }
  };

  render() {
    const {
      savingEnv,
      createEnvironment,
      editEnvironment,
      environments,
      hideCreateNewEnv,
      showCreateNewEnv,
      envToEdit,
      showEditEnv,
    } = this.props;
    const { newEnvName, prod, disableEditProd } = this.state;
    let disableCreateEnv = true;
    if (environments && environments.length > 0) {
      if (newEnvName && newEnvName != null) {
        disableCreateEnv =
          newEnvName && environments.find((o) => o.name === newEnvName)
            ? true
            : false;
      } else {
        disableCreateEnv = true;
      }
    } else {
      disableCreateEnv = !newEnvName ? true : false;
    }
    return (
      <Modal
        show={showCreateNewEnv || showEditEnv}
        onHide={hideCreateNewEnv}
        dialogClassName="new-env-add"
        backdrop="static"
      >
        <Header closeButton>
          {showEditEnv ? (
            <Title>{"Edit Environment"}</Title>
          ) : (
            <Title>{"Add Environment"}</Title>
          )}
        </Header>
        <Body style={{ background: "#eee", height: 160 }}>
          <div style={{ marginLeft: 15, paddingTop: 10 }}>
            <strong className="labelz" style={{ fontSize: 14 }}>
              Name
            </strong>
          </div>
          <FormControl
            type="input"
            label="New Environment"
            value={newEnvName}
            onChange={this.changeEnvName}
            style={{ width: 200, margin: "10px 15px" }}
            inputRef={(ref) => {
              this.inputRef = ref;
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !disableCreateEnv) {
                e.preventDefault();
                createEnvironment(newEnvName, prod);
              }
            }}
          />
          <div style={{ marginLeft: 15, marginTop: 20 }}>
            {showEditEnv && (
              <div style={{ display: "flex" }}>
                <div className="labelz">
                  <strong>Key:</strong>
                </div>
                &nbsp;
                <span>{envToEdit.uniquekey}</span>
              </div>
            )}
            <Checkbox
              checked={prod}
              onChange={() =>
                this.setState({
                  prod: !prod,
                  disableEditProd: showEditEnv ? true : false,
                })
              }
            >
              This is a production environment
            </Checkbox>{" "}
          </div>
        </Body>

        <Footer>
          <Button
            bsSize="small"
            bsStyle="primary"
            onClick={() =>
              showEditEnv
                ? editEnvironment(newEnvName, prod, envToEdit)
                : createEnvironment(newEnvName, prod)
            }
            style={{ float: "right" }}
            disabled={
              disableEditProd && newEnvName && newEnvName.length > 0
                ? false
                : disableCreateEnv
            }
          >
            Save
          </Button>

          <Button
            bsStyle="primary"
            onClick={hideCreateNewEnv}
            style={{ float: "left" }}
          >
            Cancel
          </Button>
        </Footer>
      </Modal>
    );
  }
}
