import { type } from "jquery";
import * as types from "../actions/types";

const initialState = {
  versions: null,
  eventContext: null,
  notificationEmail: { emails: [], loading: false, validationMessage: "" },
  showSaved: false,
  monitorLogs: [],
  allMonitorLogs: [],
  transformerLogsCount: null,
  trigger: null,
  allTriggers: null,
  executionLogs: [],
  executionCompleted: false,
  executionError: false,
  executionErrorMessage: "",
  triggerWebhooks: [],
  webhookSubscriptions: [],
  selectedTransformerLogs: null,
  selectedTransformerLogsDev: null,
  selectedTransformerLogsError: null,
  savedConfiguration: null,
  bundles: null,
  mapping: null,
  deployments: null,
  isDeploying: {},
  consoleIsOpen: false,
  wizardVersions: null,
  ongoingExecutionLogs: [],
  ongoingExecutionLogCount: null,
  disableTerminate: true,
  favicon: {
    icon: false,
    alertCount: null,
  },
  executingTransformers: [],
  ongoingLogsByBaseId: [],
  ongoingLogCountByBaseId: [],
  extendedProperties: [],
};

//fixme - ongoingLogCountByBaseId and ongoingLogsByBaseId
//are not being used. in order to have multithread recipe-running on ui
//we need the following all tied to baseId

// ongoingExecutionLogs: [],
// ongoingExecutionLogCount: null,
// executionLogs: [],
// executionCompleted: false,
// executionError: false,
// executionErrorMessage: '',

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CLEAR_TRANSFORMER_VERSIONS:
      return {
        ...state,
        versions: null,
      };
    case types.SET_TRANSFORMER_VERSIONS:
      let versions = [...action.data];
      versions = versions.sort((a, b) => {
        if (a.Version < b.Version) return 1;
        if (a.Version > b.Version) return -1;
        return 0;
      });
      return {
        ...state,
        versions,
      };
    case types.SET_TRANSFORMER_EMAIL:
      return {
        ...state,
        notificationEmail: {
          emails: [...action.emails],
          loading: action.loading,
        },
      };
    case types.ADD_NOTIFICATION_EMAIL:
      //email regex
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // email expression check
      if (emailRegex.test(action.email)) {
        // duplication check
        const isEmailExist = state.notificationEmail.emails.filter(
          (email, index) => email.toLowerCase() === action.email.toLowerCase()
        );

        if (isEmailExist.length === 1) {
          // email exist
          return {
            ...state,
            notificationEmail: {
              emails: state.notificationEmail.emails,
              loading: false,
              validationMessage: "Email is already exist",
            },
          };
        } else {
          // email does not exist
          const updatedList = [...state.notificationEmail.emails, action.email];
          return {
            ...state,
            notificationEmail: {
              emails: updatedList,
              loading: false,
              validationMessage: "",
            },
          };
        }
      } else {
        // invalid email
        return {
          ...state,
          notificationEmail: {
            emails: state.notificationEmail.emails,
            loading: false,
            validationMessage: "Please enter a valid email",
          },
        };
      }

    case types.DELETE_NOTIFICATION_EMAIL:
      // email list size should be greater than one
      if (state.notificationEmail.emails.length !== 1) {
        const deletedList = state.notificationEmail.emails.filter(
          (item, index) => index !== action.id
        );
        return {
          ...state,
          notificationEmail: {
            emails: deletedList,
            loading: false,
            validationMessage: "",
          },
        };
      } else {
        return {
          ...state,
          notificationEmail: {
            emails: state.notificationEmail.emails,
            loading: false,
            validationMessage: "One Email address is required",
          },
        };
      }

    case types.SET_TRANSFORMER_CONTEXT:
      return {
        ...state,
        eventContext: action.data,
      };
    case types.SET_ENABLE_TERMINATE_JOB:
      return {
        ...state,
        disableTerminate: action.data,
      };
    case types.SET_FAVICON:
      return {
        ...state,
        favicon: action.data,
      };
    case types.SET_TRANSFORMER_SHOWSAVED:
      return {
        ...state,
        showSaved: action.bool,
      };
    case types.SET_EXECUTING_TRANSFORMERS:
      return {
        ...state,
        executingTransformers: action.data,
      };
    case types.SET_ALL_TRANSFORMER_LOGS:
      const allOldData = state.allMonitorLogs;
      const alNewData = action.data;
      const allUpdatedData = allOldData.concat(alNewData);
      allUpdatedData.sort((a, b) => {
        if (a.startTime > b.startTime) return -1;
        if (a.startTime < b.startTime) return 1;
        return 0;
      });
      return {
        ...state,
        allMonitorLogs: allUpdatedData,
      };

    case types.SET_TRANSFORMER_LOGS:
      return {
        ...state,
        monitorLogs: action.data,
      };
    case types.SET_TRANSFORMER_LOGS_COUNT:
      return {
        ...state,
        transformerLogsCount: action.data,
      };
    case types.SET_TRANSFORMER_DEPLOYMENTS:
      return {
        ...state,
        deployments: action.data,
      };
    case types.SET_ONGOING_LOGS_BY_BASEID:
      return {
        ...state,
        ongoingLogsByBaseId: action.data,
      };
    case types.SET_ONGOING_LOGCOUNT_BY_BASEID:
      return {
        ...state,
        ongoingLogCountByBaseId: action.data,
      };
    case types.SET_TRANSFORMER_DEPLOY_STATUS:
      return {
        ...state,
        isDeploying: action.data,
      };
    case types.SET_CONSOLE_OPEN_STATUS:
      return {
        ...state,
        consoleIsOpen: action.bool,
      };
    case types.SET_TRIGGER:
      return {
        ...state,
        trigger: action.data,
      };
    case types.SET_ALL_TRIGGERS:
      return {
        ...state,
        allTriggers: action.data,
      };
    case types.SET_EXECUTION_LOGS:
      return {
        ...state,
        executionLogs:
          action.data && action.data.sort((a, b) => a.sortOrder - b.sortOrder),
      };
    case types.SET_ONGOING_EXECUTION_LOGS:
      return {
        ...state,
        ongoingExecutionLogs:
          action.data && action.data.sort((a, b) => a.sortOrder - b.sortOrder),
      };
    case types.SET_ONGOING_EXECUTION_LOG_COUNT:
      return {
        ...state,
        ongoingExecutionLogCount: action.data,
      };
    case types.SET_EXECUTION_COMPLETED:
      return {
        ...state,
        executionCompleted: action.bool,
      };
    case types.SET_EXECUTION_ERROR:
      return {
        ...state,
        executionError: action.bool,
      };
    case types.SET_EXECUTION_ERROR_MESSAGE:
      return {
        ...state,
        executionErrorMessage: action.errorMessage,
      };
    case types.SET_TRIGGER_WEBHOOKS:
      return {
        ...state,
        triggerWebhooks: action.data,
      };
    case types.SET_WEBHOOK_SUBSCRIPTIONS:
      return {
        ...state,
        webhookSubscriptions: action.data,
      };
    case types.SET_SELECTED_TRANSFORMER_LOGS:
      return {
        ...state,
        selectedTransformerLogs:
          action.data && action.data.sort((a, b) => a.sortOrder - b.sortOrder),
      };
    case types.SET_SELECTED_TRANSFORMER_LOGS_DEV:
      return {
        ...state,
        selectedTransformerLogsDev:
          action.data && action.data.sort((a, b) => a.sortOrder - b.sortOrder),
      };
    case types.SET_SELECTED_TRANSFORMER_LOGS_ERROR:
      return {
        ...state,
        selectedTransformerLogsError: action.data,
      };
    case types.CLEAR_TRANSFORMER:
      return {
        ...initialState,
        executingTransformers: action.data,
      };
    case types.SET_WIZ_VERSIONS:
      return {
        ...state,
        wizardVersions: action.data,
      };
    case types.SET_WIZ_SAVED_CONFIGURATION:
      return {
        ...state,
        savedConfiguration: action.data,
      };
    case types.SET_WIZ_BUNDLES:
      return {
        ...state,
        bundles: action.data,
      };
    case types.SET_WIZ_MAPPINGS:
      return {
        ...state,
        mapping: action.data,
      };
    case types.CLEAR_INTEGRATION:
      return {
        ...state,
        mapping: null,
        bundles: null,
        savedConfiguration: null,
      };
    case types.SET_EXTENDED_PROPERTIES:
      return {
        ...state,
        extendedProperties: action.data,
      };
    default:
      return state;
  }
};
