import * as types from "../actions/types";

const initialState = {
  monitoring: null,
  sessionEnvironment: null,
  envCerts: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_MONITORING_PREFERENCES:
      let monitoring = {}; // set to a null object, so the component knows to mount
      if (action.data && action.data.id && action.data.id !== 0) {
        monitoring = action.data;
      }
      return {
        ...state,
        monitoring,
      };
    case types.SET_ENV_WEBSOCKET_CERTS:
      return {
        ...state,
        envCerts: action.data,
      };
    case types.CLEAR_MONITORING_PREFERENCES:
      return {
        ...state,
        monitoring: null,
      };
    case types.SESSION_ENVIRONMENT_SELECTION:
      return {
        ...state,
        sessionEnvironment: action.data,
      };
    default:
      return state;
  }
};
