/* eslint-disable */
export const SETTINGS = {
  DEVELOPMENT: {
    S3: {
      CONFIG_URL: 'https://s3-us-west-2.amazonaws.com/data-protect-config/config.integration',
      CONFIG_AUTH: 'AKIAI2VZIG4LP56E5QIQ8Du7oyOYkdlGw8yoFB9fcoZNhT/KhRcm39D72RuV',
    },
    ANALYTICS: {
      UA: 'UA-61174605-4',
    },
  },
  INTEGRATION: {
    S3: {
      CONFIG_URL: 'https://s3-us-west-2.amazonaws.com/data-protect-config/config.integration',
      CONFIG_AUTH: 'AKIAI2VZIG4LP56E5QIQ8Du7oyOYkdlGw8yoFB9fcoZNhT/KhRcm39D72RuV',
    },
    ANALYTICS: {
      UA: 'UA-61174605-4',
    },
  },
  PRODUCTION: {
    S3: {
      CONFIG_URL: 'https://s3-us-west-2.amazonaws.com/data-protect-config/config.prod',
      CONFIG_AUTH: 'AKIAI2VZIG4LP56E5QIQ8Du7oyOYkdlGw8yoFB9fcoZNhT/KhRcm39D72RuV',
    },
    ANALYTICS: {
      UA: 'UA-61174605-5',
    },
  }
}
