import React, { Component } from "react";
import UserPicker from "./userPicker";

export default class Teamtab extends Component {
  render() {
    return (
      <div className="overview-tab-content" style={{ width: "50%" }}>
        <UserPicker {...this.props} />
      </div>
    );
  }
}
