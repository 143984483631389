//preetier-ignore

import React, { Component } from "react";

import { Button, Checkbox } from "react-bootstrap";
import "rc-time-picker/assets/index.css";
import images from "../images";
import EnvironmentDropdown from "../utils/EnvironmentDropdown";
import ScheduleMod from "./scheduleMod";

import SettingsStyle from "../../styles/settingsstyle";
import ScheduleListView from "./ScheduleListView";
import ScheduleCalendarView from "./ScheduleCalendarView";

export default class Calendar extends Component {
  constructor() {
    super();
    this.state = {
      selectedEnvironment: { name: "All Environments" },
      showModal: false,
      manual: false,
      isCalendarView: false,
    };

    this.handleModal = this.handleModal.bind(this);
    this.togglePause = this.togglePause.bind(this);
    this.handleEnvironemtChange = this.handleEnvironemtChange.bind(this);
  }

  componentWillMount() {
    const { actions, redux, match } = this.props;
    const { project } = redux && redux;
    const { params } = match;
    const { appId } = params;
    const { TenantId } = project && project.tenantInfo;

    actions.callGetActiveSchedules(appId, TenantId);
  }

  resetCalendarState = (editMode) => {
    this.setState({
      editMode,
      selectedEvent: null,
    });
  };

  handleModal() {
    let { showModal } = this.state;
    showModal = !showModal;
    this.setState({ showModal });
  }

  handleEnvironemtChange(env) {
    const { actions, redux, match } = this.props;
    const { project } = redux && redux;
    const { params } = match;
    const { appId } = params;
    const { TenantId } = project && project.tenantInfo;

    const { id } = env;

    this.setState({ selectedEnvironment: env });

    // active schedules
    id
      ? actions.callGetActiveSchedules(appId, TenantId, id)
      : actions.callGetActiveSchedules(appId, TenantId);
    
    // calendar view
    id
      ? actions.callGetTriggerDetailsWithoutFilter(appId, TenantId)
      : actions.callGetTriggerDetailsWithoutFilter(appId, TenantId);
     
  }

  togglePause() {
    const { selectedEnvironment } = this.state;
    const { actions } = this.props;
    const { project } = this.props.redux;
    const tenantId = project && project.tenantInfo.TenantId;
    const { appId } = this.props.match.params;
    const { environments } = project && project;

    const { id } = selectedEnvironment;

    const currentEnv =
      environments && environments.find((f) => f.id === selectedEnvironment.id);

    let pauseState = currentEnv && currentEnv.pauseSchedules;
    pauseState = !pauseState;

    const data = {
      pauseSchedules: pauseState,
    };

    actions
      .toggleEnvironmentPause(tenantId, appId, currentEnv, data)
      .then((data) => {
        if (id) {
          actions.callGetTriggerSummary(appId, tenantId, id);
          actions.callGetActiveSchedules(appId, tenantId, id);
          // actions.callGetTriggerDetailsWithoutFilter(appId, tenantId, id) ;
        } else {
          actions.callGetTriggerSummary(appId, tenantId);
          actions.callGetActiveSchedules(appId, tenantId);
          // actions.callGetTriggerDetailsWithoutFilter(appId, tenantId);
        }
      });
  }

  render() {
    const { selectedEnvironment, manual } = this.state;
    const { project, events } = this.props.redux;
    const { environments } = project;

    const pausedNumber = [];

    const currentEnv =
      environments && environments.find((f) => f.id === selectedEnvironment.id);

    environments &&
      environments.map((o, i) => {
        if (!pausedNumber.includes(o.id)) {
          if (o.pauseSchedules) {
            pausedNumber.push(i);
          }
        }
      });

    return (
      <div
        className="parent-env"
        style={{
          marginTop: 50,
          overflowY: null,
          paddingTop: 15,
          paddingBottom: 50,
        }}
      >
        <div className="main-tab-content" style={{ overflowY: null }}>
          <div
            style={{
              margin: "0px 0px 15px 0px",
              fontSize: "20px",
              display: "inline-block",
            }}
          >
            Schedules
          </div>

          <div className="main-create-btn-env" style={{ marginTop: -18 }}>
            <Button
              style={{ marginRight: 10, display: "inline-block" }}
              bsSize="small"
              bsStyle="primary"
              onClick={() => this.handleModal()}
            >
              New Schedule
            </Button>

            <Button
              bsSize="small"
              onClick={() => {
                this.setState({ isCalendarView: !this.state.isCalendarView });
              }}
              style={{
                marginRight: 10,
                background: "#ddd",
                width: 120,
                border: "1px solid #666",
              }}
            >
              <img
                src={
                  this.state.isCalendarView
                    ? images.ic_list_black_48dp
                    : images.ic_menu
                }
                style={{ ...SettingsStyle.iconStyles, marginRight: 3 }}
                alt="alpha"
              />
              {this.state.isCalendarView ? (
                <span>List View</span>
              ) : (
                <span>Schedule View</span>
              )}
            </Button>

            <div style={{ display: "inline-block", marginRight: 20 }}>
              <EnvironmentDropdown
                environments={project.environments}
                disabled={
                  !(project.environments && project.environments.length > 0)
                }
                includeAllOption
                uiEnvironment={selectedEnvironment}
                onSelect={this.handleEnvironemtChange}
              />

              <Button
                style={{
                  marginRight: 10,
                  marginLeft: 10,
                  display: "inline-block",
                  background:
                    currentEnv && currentEnv.pauseSchedules ? "#888" : null,
                }}
                bsSize="small"
                disabled={!selectedEnvironment.id}
                bsStyle={
                  currentEnv && currentEnv.pauseSchedules ? null : "primary"
                }
                onClick={() => this.togglePause()}
              >
                {currentEnv ? (
                  <img
                    className="pause-icon"
                    role="presentation"
                    src={
                      !currentEnv.pauseSchedules
                        ? images.pauseicon
                        : images.playicon
                    }
                    height="18px"
                  />
                ) : (
                  <img
                    className="pause-icon"
                    role="presentation"
                    src={
                      !selectedEnvironment.pauseSchedules
                        ? images.pauseicon
                        : images.playicon
                    }
                    height="18px"
                  />
                )}
              </Button>
              <div style={{ float: "right", marginTop: -3 }}>
                <span>
                  <b>{events.activeTriggers}</b> active schedules
                </span>
                <br />
                <span>
                  <b>{pausedNumber.length}</b> paused environments
                </span>
              </div>
            </div>
          </div>

          <div style={{ float: "right", marginRight: 42 }}>
            <Checkbox
              onChange={() => this.setState({ manual: !manual })}
              checked={this.state.manual}
              className="calendar-sort"
            >
              Unscheduled
            </Checkbox>
          </div>

          {this.state.isCalendarView ? (
            <ScheduleCalendarView
              {...this.props}
              manual={this.state.manual}
              environment={selectedEnvironment}
            />
          ) : (
            <ScheduleListView
              props={this.props}
              environment={selectedEnvironment}
            />
          )}
        </div>

        <ScheduleMod
          resetCalendarState={this.resetCalendarState}
          show={this.state.showModal}
          hide={this.handleModal}
          {...this.props}
        />
      </div>
    );
  }
}
