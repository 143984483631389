import React, { Component } from "react";
import { Button, FormControl } from "react-bootstrap";
import Toggle from "react-toggle";
import ReactTooltip from "react-tooltip";
import images from "../images";

//email regex
const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class UserInviteModal extends Component {
  constructor() {
    super();
    this.state = {
      invitingUserIds: [],
      invitingUserAdmins: {},
      isInvitingUsers: false,
      emailInput: "",
      invitingUserEmails: [],
      invitingUserEmailAdmins: {},
      validationError: "",
    };
    this.inviteUser = this.inviteUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.removeEmailUser = this.removeEmailUser.bind(this);
    this.updateInvitedUserAdmin = this.updateInvitedUserAdmin.bind(this);
    this.updateInvitedEmailUserAdmin =
      this.updateInvitedEmailUserAdmin.bind(this);
    this.finalInvite = this.finalInvite.bind(this);
    this.addEmailToSendList = this.addEmailToSendList.bind(this);
    this.checkIfUserExistsInThisOrg =
      this.checkIfUserExistsInThisOrg.bind(this);
  }
  // actions.getUsersForWorkspace(project.tenantInfo.TenantId, params.appId)
  componentWillMount() {
    const { actions, redux, storedUserInvites } = this.props;
    const { main } = redux;
    if (!main.users) {
      actions.callGetUsers();
    }
    // for making a new workspace
    if (storedUserInvites) {
      const invitingUserIds = [];
      const invitingUserAdmins = {};
      storedUserInvites.forEach((ui) => {
        invitingUserIds.push(ui.userId);
        invitingUserAdmins[ui.userId] = ui.isAdmin;
      });
      this.setState({ invitingUserIds, invitingUserAdmins });
    }
  }
  finalInvite() {
    const { actions, match, tenantId, selectedAppId } = this.props;
    const { params } = match;
    const appId = params.appId ? params.appId : selectedAppId
    const data = this.state.invitingUserIds.map((uid) => {
      return {
        userId: uid,
        isAdmin: this.state.invitingUserAdmins[uid],
      };
    });
    const emailData = this.state.invitingUserEmails.map((email) => {
      return { email, isAdmin: this.state.invitingUserEmailAdmins[email] };
    });

    // from the account details
    if (!this.props.newWorkspace) {
      this.setState({ isInvitingUsers: true });
      actions
        .inviteUsersToWorkspace(tenantId, appId, data.concat(emailData))
        .then(() => {
          this.setState({ isInvitingUsers: false });
          this.props.onClose();
        });
    } else {
      // new workspace
      this.props.storeUserInvites(data);
    }
  }
  updateInvitedUserAdmin(e, userId) {
    const invitingUserAdmins = { ...this.state.invitingUserAdmins };
    invitingUserAdmins[userId] = e.target.checked;
    this.setState({ invitingUserAdmins });
  }
  updateInvitedEmailUserAdmin(e, userEmail) {
    const invitingUserEmailAdmins = { ...this.state.invitingUserEmailAdmins };
    invitingUserEmailAdmins[userEmail] = e.target.checked;
    this.setState({ invitingUserEmailAdmins });
  }
  inviteUser(userId) {
    const invitingUserIds = [...this.state.invitingUserIds];
    invitingUserIds.push(userId);
    const invitingUserAdmins = { ...this.state.invitingUserAdmins };
    invitingUserAdmins[userId] = false;
    this.setState({ invitingUserIds, invitingUserAdmins });
  }
  removeUser(userId) {
    const invitingUserIds = [...this.state.invitingUserIds];
    const i = invitingUserIds.findIndex((iuid) => iuid === userId);
    invitingUserIds.splice(i, 1);

    const invitingUserAdmins = { ...this.state.invitingUserAdmins };
    if (invitingUserAdmins[userId]) delete invitingUserAdmins[userId];

    this.setState({ invitingUserIds, invitingUserAdmins });
  }
  removeEmailUser(userEmail) {
    const invitingUserEmails = [...this.state.invitingUserEmails];
    const i = invitingUserEmails.findIndex((iuid) => iuid === userEmail);
    invitingUserEmails.splice(i, 1);

    const invitingUserEmailAdmins = { ...this.state.invitingUserEmailAdmins };
    if (invitingUserEmailAdmins[userEmail])
      delete invitingUserEmailAdmins[userEmail];

    this.setState({ invitingUserEmails, invitingUserEmailAdmins });
  }
  addEmailToSendList() {
    const emailInput = this.state.emailInput;
    if (this.state.invitingUserEmails.find((u) => u.email === emailInput)) {
      this.setState({
        validationError: "You have already added this email to the invite list",
      });
      return;
    }
    const userExistsInThisOrg = this.checkIfUserExistsInThisOrg(emailInput);
    // ADD IF USER EXISTS IN THE ORG ALREAY
    if (userExistsInThisOrg.alreadyMember) {
      this.setState({
        validationError: "The user is already a member of this Project",
      });
      return;
    }
    if (userExistsInThisOrg.invited) {
      this.setState({
        validationError: "You have already added this email to the invite list",
      });
      return;
    }
    if (userExistsInThisOrg.uninvited) {
      this.setState({ emailInput: "" });
      this.inviteUser(userExistsInThisOrg.uninvited.id);
      return;
    }
    if (emailRegex.test(emailInput)) {
      const invitingUserEmails = [...this.state.invitingUserEmails];
      invitingUserEmails.push(emailInput);
      const invitingUserEmailAdmins = { ...this.state.invitingUserEmailAdmins };
      invitingUserEmailAdmins[emailInput] = false;
      this.setState({
        invitingUserEmails,
        emailInput: "",
        invitingUserEmailAdmins,
      });
    } else {
      this.setState({ validationError: "Please enter a valid email address" });
    }
  }
  checkIfUserExistsInThisOrg(email) {
    const { redux, tenantId } = this.props;
    const { main, project } = redux;
    const permissions = project.permissions || [];
    const tenantUsers =
      tenantId &&
      main.users.filter((u) => {
        return u.tenantid === tenantId;
      });
    const uninvitedTenantUsers =
      tenantUsers &&
      tenantUsers.filter((u) => {
        return permissions.find((p) => p.identityId === u.id) ? false : true;
      });
    const usersToInvite =
      this.state.invitingUserIds &&
      uninvitedTenantUsers &&
      uninvitedTenantUsers.filter((utu) => {
        return this.state.invitingUserIds.includes(utu.id) ? true : false;
      });
    const usersToShowAsUninvited =
      this.state.invitingUserIds &&
      uninvitedTenantUsers &&
      uninvitedTenantUsers.filter((utu) => {
        return this.state.invitingUserIds.includes(utu.id) ? false : true;
      });
    return {
      uninvited: usersToShowAsUninvited.find((utu) => utu.username === email),
      invited: usersToInvite.find((utu) => utu.username === email),
      alreadyMember: permissions.find((p) => p.Identity.username === email),
    };
  }
  render() {
    const { onClose, redux, tenantId, identityIsAdmin } = this.props;
    const { main, project } = redux;

    const permissions = project.permissions || [];

    const tenantUsers =
      tenantId &&
      main.users.filter((u) => {
        return u.tenantid === tenantId;
      });
    const uninvitedTenantUsers =
      tenantUsers &&
      tenantUsers
        .filter((u) => {
          return permissions.find((p) => p.identityId === u.id) ? false : true;
        })
        .filter((u) => main.me.id !== u.id);
    const usersToInvite =
      this.state.invitingUserIds &&
      uninvitedTenantUsers &&
      uninvitedTenantUsers.filter((utu) => {
        return this.state.invitingUserIds.includes(utu.id) ? true : false;
      });
    const usersToShowAsUninvited =
      this.state.invitingUserIds &&
      uninvitedTenantUsers &&
      uninvitedTenantUsers.filter((utu) => {
        return this.state.invitingUserIds.includes(utu.id) ? false : true;
      });

    return (
      <div>
        <div className="overlay-100" />
        <div
          className="lingk-dialog"
          style={{ height: 525, width: 450, marginLeft: -200, marginTop: -250 }}
        >
          <h4 style={{ marginBottom: 22 }}>Invite users to this Project</h4>
          <div
            style={{ overflowY: "auto", height: "71.5%" }}
            className="invite-users-content-wrap"
          >
            {((usersToInvite && usersToInvite.length > 0) ||
              (this.state.invitingUserEmails &&
                this.state.invitingUserEmails.length > 0)) && (
                <div>
                  <div style={{ maxWidth: "450px" }}>
                    <strong className="tool-tip-title">About access roles:</strong>
                    <div>
                      <p style={{ fontSize: "12px" }}><strong style={{ fontSize: "14px", fontWeight: "bold" }}>Admin: </strong>
                        Admin have the highest level of access to an Organization/Project. <br />ie. Admin can <strong>view/invite/edit/remove</strong> other users having any of the access role.</p>

                      <p style={{ fontSize: "12px" }}><strong style={{ fontSize: "14px", fontWeight: "bold" }}>User: </strong>
                        User have the lowest level of access to an Organization/Project. <br />ie. User can only <strong>view</strong> other users and <strong>invite</strong> others with user access role only.</p>
                    </div>
                  </div>
                  <div>Users to invite:</div>
                  {usersToInvite.map((uti, i) => {
                    const invitedUserIsAdmin =
                      this.state.invitingUserAdmins[uti.id];
                    return (
                      <UserToInvite
                        key={i}
                        user={uti}
                        idProp="id"
                        nameProp="username"
                        invitedUserIsAdmin={invitedUserIsAdmin}
                        identityIsAdmin={identityIsAdmin}
                        removeUser={this.removeUser}
                        updateInvitedUserAdmin={this.updateInvitedUserAdmin}
                      />
                    );
                  })}
                  {this.state.invitingUserEmails.map((userEmail) => {
                    const invitedUserIsAdmin =
                      this.state.invitingUserEmailAdmins[userEmail];
                    return (
                      <UserToInvite
                        key={userEmail}
                        user={{ email: userEmail }}
                        idProp="email"
                        nameProp="email"
                        invitedUserIsAdmin={invitedUserIsAdmin}
                        identityIsAdmin={identityIsAdmin}
                        removeUser={this.removeEmailUser}
                        updateInvitedUserAdmin={this.updateInvitedEmailUserAdmin}
                      />
                    );
                  })}
                  <br />
                </div>
              )}

            <div>
              <div>Invite new members by Email:</div>
              <div style={{ marginBottom: 12 }}>
                <FormControl
                  type="text"
                  className="form-control"
                  style={{
                    width: 282,
                    display: "inline-block",
                    marginRight: 9,
                  }}
                  onChange={(e) => {
                    this.setState({
                      emailInput: e.target.value.replace(/ /g, "").toLowerCase(),
                      validationError: "",
                    });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.addEmailToSendList();
                  }}
                  value={this.state.emailInput}
                />
                <Button
                  bsStyle="primary"
                  bsSize="small"
                  disabled={!this.state.emailInput}
                  style={{ verticalAlign: "top", marginTop: 2 }}
                  onClick={this.addEmailToSendList}
                >
                  Add
                </Button>
                {this.state.validationError && (
                  <div style={{ fontSize: "11px", color: "#ea5a5a" }}>
                    {this.state.validationError}
                  </div>
                )}
              </div>
            </div>

            {usersToShowAsUninvited && usersToShowAsUninvited.length > 0 && (
              <div style={{ marginTop: 18 }}>
                <div>Invite members from this Organization:</div>
                {usersToShowAsUninvited.map((utu, i) => {
                  return (
                    <div key={i} className="uninvited-tenant-user">
                      <div
                        className="uninvited-tenant-user-add"
                        onClick={() => this.inviteUser(utu.id)}
                      >
                        +
                      </div>
                      <div className="uninvited-tenant-user-name">
                        {utu.username}
                      </div>
                    </div>
                  );
                })}
                <br />
              </div>
            )}

            {/*(!usersToInvite || usersToInvite.length === 0) && (!usersToShowAsUninvited || usersToShowAsUninvited.length === 0) && <div style={{marginBottom:20}}>All users in your Organization already have access to this Workspace</div>*/}
          </div>

          <hr style={{ position: "absolute", bottom: "5rem", width: "90%" }} />
          <div style={{ position: "absolute", width: "90%", bottom: "2rem" }}>
            <Button
              bsStyle="default"
              disabled={this.state.isInvitingUsers}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              bsStyle="primary"
              onClick={this.finalInvite}
              disabled={
                !(
                  (usersToInvite && usersToInvite.length > 0) ||
                  (this.state.invitingUserEmails &&
                    this.state.invitingUserEmails.length > 0)
                ) || this.state.isInvitingUsers
              }
              style={{ marginLeft: ".5rem", float: "right", width: 60 }}
            >
              {!this.state.isInvitingUsers ? (
                "Invite"
              ) : (
                <img
                  alt="small-spinner"
                  src={images.ajax_loader_small}
                  height="8"
                />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const UserToInvite = ({
  user,
  idProp,
  nameProp,
  removeUser,
  updateInvitedUserAdmin,
  invitedUserIsAdmin,
  identityIsAdmin,
}) => {
  return (
    <div key={user[idProp]} className="invited-tenant-user">
      <ReactTooltip place={"right"} />
      <div
        className="invited-tenant-user-remove"
        onClick={() => removeUser(user[idProp])}
      >
        -
      </div>
      <div className="invited-tenant-user-content">
        <div>{user[nameProp]}</div>
        <div className="invited-tenant-user-admin-toggle"
          data-tip={!identityIsAdmin ? "You can only invite members with user role." : null}>
          <Toggle
            className="custom-toggle-btn"
            checked={identityIsAdmin ? invitedUserIsAdmin : false}
            disabled={!identityIsAdmin}
            icons={{
              checked: (
                <span className="toggle-label toggle-label-admin">admin</span>
              ),
              unchecked: (
                <span className="toggle-label toggle-label-user">user</span>
              ),
            }}
            onChange={(e) => updateInvitedUserAdmin(e, user[idProp])}
          />
        </div>
      </div>
    </div>
  );
};
