import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
const { Body, Title, Header, Footer } = Modal;
import { camelCase } from "lodash";

import images from "../images";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const DPHEditModal = ({
  props,
  data,
  type,
  isOpen,
  setIsOpen,
  notificationFrequency,
}) => {
  let notificationFrequencyId, thresholdPercentage, notificationEmails;

  if (type === "LicensedPeriod") {
    notificationFrequencyId = data.licensedPeriodDPH.notificationFrequencyId;
    thresholdPercentage = data.licensedPeriodDPH.thresholdPercentage;
    notificationEmails = data.licensedPeriodDPH.notificationEmails;
  } else if (type === "Monthly") {
    notificationFrequencyId = data.monthlyDPH?.months[data.monthlyDPH?.months?.length - 1]?.notificationFrequencyId;
    thresholdPercentage = data.monthlyDPH?.months[data.monthlyDPH?.months?.length - 1]?.thresholdPercentage;
    notificationEmails = data.monthlyDPH?.notificationEmails;
  }

  const { actions } = props;

  const [threshold, setThreshold] = useState(thresholdPercentage);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [thresholdError, setThresholdError] = useState("");
  const [emails, setEmails] = useState(notificationEmails || []);
  const [emailFrequency, setEmailFrequency] = useState(
      notificationFrequencyId
  );

  // refreshing the state on type change
  useEffect(() => {
    setEmail("");
    setError("");
    setThresholdError("");
    setEmails(notificationEmails);
    setEmailFrequency(notificationFrequencyId);
    setThreshold(thresholdPercentage);
  }, [type]);

  const handleThresholdChange = (e) => {
    setThresholdError("");
    const value = e.target.value;

    if (!(value >= 0 && value <= 100))
      setThresholdError("Value must be between 0 and 100");

    if (!value) setThresholdError("Value can't be empty");

    setThreshold(value);
  };

  // add email in email list
  const addEmail = () => {
    setError("");

    if (emailRegex.test(email)) {
      // duplication check
      const isEmailExist =
        emails && emails.filter((e) => e.toLowerCase() === email.toLowerCase());

      if (isEmailExist && isEmailExist.length > 0) {
        setError("Email id already exists!!!");
        return;
      }
      let newArray = [email];
      if (emails) {
          newArray.push(...emails);
      }
      setEmails(newArray);
      setEmail("");
    } else {
      setError("Please enter a valid email");
    }
  };

  const removeEmail = (id) => {
    if (emails.length !== 1) {
      const newArray = emails.filter((item, index) => index !== id);

      setEmails(newArray);
    } else {
      setError("One email address is required");
    }
  };

  const handleSubmit = () => {
    const { tenantId } = data;

    const req = {
      notificationEmails: emails,
      notificationFrequencyId: Number(emailFrequency),
      threshold: threshold / 100,
    };

    actions.updateTenantDph(req, tenantId, camelCase(type));
    setIsOpen(false);
  };

  return (
    isOpen && (
      <div>
        <Modal
          bsSize="large"
          show={true}
          onHide={() => setIsOpen(false)}
          backdrop="static"
        >
          <Header closeButton>
            <Title>
              <strong>{`Edit ${type} DPH`}</strong>
            </Title>
          </Header>
          <Body style={{ margin: 20 }}>
            <div className="container-fluid">
              <div style={{ display: "flex", gap: 20 }}>
                {/* left */}
                <div style={{ flex: 1 }}>
                  {/* Number of threshold */}
                  <div style={{ marginBottom: "20px" }}>
                    <label htmlFor="emailThreshold">Threshold(%)</label>
                    <input
                      id="emailThreshold"
                      className="form-control"
                      type="text"
                      placeholder="Threashold in %"
                      value={threshold}
                      onChange={(e) => handleThresholdChange(e)}
                    />
                    <div
                      style={{
                        fontSize: "12px",
                        color: "#ea5a5a",
                        marginTop: 2,
                      }}
                    >
                      {thresholdError}
                    </div>
                  </div>

                  {/* frequency */}
                  <div>
                    <label htmlFor="emailFrequency">Notification Frequency</label>
                    <select
                      id="emailFrequency"
                      className="form-control"
                      value={emailFrequency}
                      onChange={(e) => setEmailFrequency(e.target.value)}
                    >
                      {notificationFrequency.length > 0 &&
                          notificationFrequency.map((f) => {
                          if (type === "LicensedPeriod") {
                            return (
                              <option key={f.id} value={f.id}>
                                {f.frequency}
                              </option>
                            );
                          } else {
                            if (f.days <= 30) {
                              return (
                                <option key={f.id} value={f.id}>
                                  {f.frequency}
                                </option>
                              );
                            }
                          }
                        })}
                    </select>
                  </div>
                </div>

                {/* right */}
                <div style={{ flex: 1 }}>
                  <div>
                    {/* email input */}
                    <div>
                      <label htmlFor="email">Email</label>
                      <div style={{ display: "flex" }}>
                        <input
                          id="email"
                          className="form-control"
                          type="text"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button
                          bsSize="small"
                          bsStyle="primary"
                          style={{ marginLeft: "25px" }}
                          onClick={() => addEmail()}
                        >
                          Add Email
                        </Button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "#ea5a5a",
                        marginTop: 2,
                      }}
                    >
                      {error}
                    </div>
                    {/* email list */}
                    <div
                      style={{
                        border: "1px solid gray",
                        marginTop: 20,
                        maxHeight: 200,
                        overflowY: "auto",
                      }}
                    >
                      {emails &&
                        emails.map((e, i) => (
                          <div
                            style={{
                              margin: 0,
                              padding: 5,
                              borderBottom: "1px solid lightgray",
                            }}
                            key={i}
                          >
                            <div style={{ display: "flex" }}>
                              <p style={{ margin: "5px 10px" }}>{e}</p>
                              <div
                                onClick={() => removeEmail(i)}
                                style={{
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                  marginLeft: "auto",
                                  padding: 2,
                                  cursor: "pointer",
                                }}
                                title="Delete"
                              >
                                <span>
                                  <img
                                    src={images.delete_env_var}
                                    alt="delete"
                                    width={20}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Body>

          <Footer>
            <Button bsSize="small" onClick={() => setIsOpen(false)}>
              Close
            </Button>
            <Button
              bsSize="small"
              bsStyle="primary"
              disabled={thresholdError.length > 0}
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </Footer>
        </Modal>
      </div>
    )
  );
};

export default DPHEditModal;
