import { db } from "../index";

export function AddRecipes(data) {
  // console.log("adding", data);
  if (data) {
    return db.recipes.bulkAdd(data);
  }
}

export function AddRecipe(data) {
  // console.log("adding", data);
  if (data) {
    return db.recipes.add(data);
  }
}

export async function DeleteProjectRecipes(appID) {
  let a = parseInt(appID, 10);
  const recipes = await db.recipes.where("appId").equals(a).toArray();
  return recipes.map((r) => {
    return db.recipes.delete(r.id);
  });
}

export function DeleteAllProjectsRecipes() {
  return db.recipes.clear();
}
export async function DeleteSingleRecipe(baseId) {
  const recipe = await db.recipes.where("BaseId").equals(baseId).toArray();
  return db.recipes.delete(recipe[0].id);
}

export async function GetRecipeByBaseId(baseId) {
  let a = await db.recipes.where("BaseId").equals(baseId).toArray();
  return a;
}

export async function GetAllRecipes(appId) {
  let a = parseInt(appId, 10);
  return await db.recipes.where("appId").equals(a).toArray();
}

export async function UpdateRecipeName(
  baseId,
  name,
  tag,
  desc,
  processingType,
  maxDataProcTime
) {
  const recipe = await db.recipes.where("BaseId").equals(baseId).toArray();
  let updated = await db.table("recipes").update(recipe[0].id, {
    Name: name,
    Environ: tag,
    Description: desc,
    processingType: processingType,
    maxDataProcTime: maxDataProcTime,
  });
  if (updated) {
    return updated;
  }
}
//
export async function UpdateRecipeEditflag(baseId, LastModified) {
  const recipe = await db.recipes.where("BaseId").equals(baseId).toArray();
  let updated = await db
    .table("recipes")
    .update(recipe[0].id, { edited: false, LastModified: LastModified });
  if (updated) {
    return updated;
  }
}
export async function UpdateRecipeConfig(indexId, content) {
  // console.log("updating")
  let updated = await db.table("recipes").update(indexId, { Config: content });
  if (updated) {
    let edited = await MarkRecipeEdited(indexId);
    return edited;
  }
}

export async function MarkRecipeEdited(indexId) {
  return db
    .transaction("rw", db.recipes, async () => {
      await db.recipes.where("id").equals(indexId).modify({ edited: true });
      const edited = await db.recipes.where({ id: indexId }).toArray();
      return edited;
    })
    .catch((error) => {
      console.error("Generic error: " + error);
      return error;
    });
}
