import React, { Component } from "react";
import { Button, FormGroup, FormControl, InputGroup } from "react-bootstrap";
import images from "../images";

const nameregex = /[^A-Za-z0-9_-]/;

export default class NewWebhook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      webhookurl: "",
      objectName: "mynewobject",
      privatePref: true,
      webhookdescription: "",
      editmode: false,
      isCreating: false,
      isDeleting: false,
    };

    this.createObject = this.createObject.bind(this);
    this.deleteObject = this.deleteObject.bind(this);
    this.cancelCreate = this.cancelCreate.bind(this);
    this.clearUIState = this.clearUIState.bind(this);
    this.changeName = this.changeName.bind(this);
  }

  componentWillMount() {
    const array = this.props.location.pathname;

    const splitarray = array.split("/");

    if (splitarray.includes("edit")) {
      this.setState({ editmode: true });
    }
  }

  componentDidMount() {
    const { objId } = this.props.match.params;
    const { webhookTypes } = this.props.redux.events;
    const objectNumber = [];

    //objects need an obj ID to edit

    if (objId) {
      const editTarget = objId.split(".");
      const target = editTarget.slice(2);
      const tar = target.join("");
      this.setState({
        webhookurl: "",
        objectName: tar,
        webhookdescription: "",
      });
    } else {
      webhookTypes &&
        webhookTypes.map((o) => {
          if (o.private) {
            objectNumber.push(o);
          }
        });

      const objectName = `mynewobject${objectNumber.length + 1}`;
      this.setState({ objectName });
    }
  }

  changeName(e) {
    //fixme disable spacebar
    this.setState({ objectName: e.target.value });
  }

  deleteObject() {
    const { objId, appId } = this.props.match.params;
    const { project } = this.props.redux;
    const { actions } = this.props;
    const tenantKey = project.tenantInfo.Key;
    const appKey = project.appData.uniquekey;

    this.setState({ isDeleting: true });
    actions.deleteObjectType(tenantKey, appKey, objId).then(() => {
      this.props.history.push(`/a/${appId}/events/obj`);
    });
  }

  createObject() {
    const { actions } = this.props;
    const { project } = this.props.redux;
    const { appId } = this.props.match.params;
    const { objectName, privatePref } = this.state;
    const tenantKey = project.tenantInfo.Key;
    const appKey = project.appData.uniquekey;
    const { objId } = this.props.match.params;

    let objName;

    if (objId) {
      const editTarget = objId.split(".");
      objName = `${editTarget[0]}.${editTarget[1]}.${objectName}`;
    } else {
      objName = `${tenantKey}.${appKey}.${objectName}`;
    }

    const data = {
      name: objName,
      private: privatePref,
    };

    //   "title": "ObjectType",
    //   "type": "object",
    //   "properties": {
    //     "name": {
    //       "description": "The name of the object type.",
    //       "maxLength": 60,
    //       "type": "string"
    //     },
    //     "private": {
    //       "description": "Whether other applications can see this object type.",
    //       "type": "boolean"
    //     }
    //   },
    //   "required": [
    //     "name",
    //     "private"
    //   ],
    //   "additionalProperties": false
    // }

    if (!objId) {
      this.setState({ isCreating: true });
      actions.createObjectType(tenantKey, appKey, data).then(() => {
        this.props.history.push(`/a/${appId}/events/obj`);
      });
    } else {
      this.setState({ isCreating: true });
      actions.updateObjectType(tenantKey, appKey, data, objId).then(() => {
        this.props.history.push(`/a/${appId}/events/obj`);
      });
    }
  }

  clearUIState() {
    this.setState({ webhookurl: "", objectName: "", webhookdescription: "" });
  }

  cancelCreate() {
    const { appId } = this.props.match.params;

    this.clearUIState();
    this.props.history.push(`/a/${appId}/events/obj`);
  }

  getValidationState() {
    if (this.state.objectName !== "" && !nameregex.test(this.state.objectName))
      return "success";
    else return "error";
  }

  render() {
    const { webhookTypes } = this.props.redux.events;

    const { objectName, isCreating, isDeleting } = this.state;
    const { project } = this.props.redux;
    const { objId } = this.props.match.params;
    const tenantKey = project.tenantInfo.Key;
    const appKey = project.appData.uniquekey;

    let allset = false;
    let nameInUse = false;

    let editTarget;
    let tar;

    if (objId) {
      editTarget = objId.split(".");
      let target = editTarget;
      let t = target.slice(2);
      tar = t.join("");
    }

    webhookTypes &&
      webhookTypes.map((o) => {
        let name = o.name.split(".");
        let t = name.slice(2);
        const existName = t.join("");

        if (objectName === existName && objectName !== tar) {
          nameInUse = true;
          allset = false;
        }
      });

    if (!nameregex.test(objectName) && objectName !== "") {
      if (!nameInUse) {
        allset = true;
      }
    }

    return (
      <div className="overview-tab-content" style={{ position: "relative" }}>
        <div className="">
          {objId ? (
            <div
              style={{
                margin: "10px 0 15px 0",
                fontSize: "20px",
                display: "inline-block",
              }}
            >
              Edit "{objectName}"
            </div>
          ) : (
            <div
              style={{
                margin: "10px 0 15px 0",
                fontSize: "20px",
                display: "inline-block",
              }}
            >
              New Object
            </div>
          )}
          <br />
          {/*main envelope*/}
          <div>
            <div
              className="obj-create-env"
              style={{
                position: "relative",
                display: "inline-block",
                maxWidth: 500,
                height: 238,
              }}
            >
              <strong
                className="labelz"
                style={{ width: "auto", fontSize: "11px" }}
              >
                Object Name
              </strong>

              <br />
              <div
                style={{
                  display: "inline-block",
                  marginTop: 2,
                  marginBottom: -6,
                }}
              >
                <FormGroup
                  controlId="formBasicText"
                  style={{ height: 30 }}
                  validationState={this.getValidationState()}
                >
                  <InputGroup>
                    {editTarget ? (
                      <InputGroup.Addon>
                        {editTarget[0]}.{editTarget[1]}.
                      </InputGroup.Addon>
                    ) : (
                      <InputGroup.Addon>
                        {tenantKey}.{appKey}.
                      </InputGroup.Addon>
                    )}
                    <FormControl
                      type="text"
                      placeholder="Object Name"
                      value={this.state.objectName}
                      onChange={(e) => {
                        this.changeName(e);
                      }}
                    />
                    <FormControl.Feedback />
                  </InputGroup>
                </FormGroup>
                {nameregex.test(objectName) && (
                  <div style={{ marginTop: -8 }}>
                    <span style={{ fontSize: 11, color: "red" }}>
                      *Alphabetic and numeric characters allowed.
                    </span>
                  </div>
                )}
                {nameInUse && (
                  <div style={{ marginTop: -8 }}>
                    <span style={{ fontSize: 11, color: "red" }}>
                      *This name is already in use.
                    </span>
                  </div>
                )}
              </div>
              <br />
              <strong
                className="labelz"
                style={{ width: "auto", fontSize: "11px" }}
              >
                Event Listener
              </strong>

              <br />
              <div style={{ display: "inline-block" }}>
                <span>
                  https://www.lingkapis.com/v1/{tenantKey}/{appKey}/events
                </span>
              </div>

              <div style={{ position: "absolute", right: 0, bottom: 70 }}>
                {objId ? (
                  <Button
                    style={{ marginLeft: 10, float: "right" }}
                    bsStyle="primary"
                    bsSize="small"
                    disabled={!allset || isDeleting}
                    className="sub-create-button"
                    onClick={() => this.createObject()}
                  >
                    {this.state.isCreating ? (
                      <span>
                        &nbsp;&nbsp;
                        <img
                          alt="small-spinner"
                          src={images.ajax_loader_small}
                          height="8"
                        />
                        &nbsp;&nbsp;
                      </span>
                    ) : (
                      <span>Save</span>
                    )}
                  </Button>
                ) : (
                  <Button
                    style={{ marginLeft: 10, float: "right" }}
                    bsStyle="primary"
                    bsSize="small"
                    disabled={!allset || isCreating}
                    className="sub-create-button"
                    onClick={() => this.createObject()}
                  >
                    {this.state.isCreating ? (
                      <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                        <img
                          alt="small-spinner"
                          src={images.ajax_loader_small}
                          height="8"
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                      </span>
                    ) : (
                      <span>Create Object</span>
                    )}
                  </Button>
                )}
                <Button
                  style={{ marginLeft: 10, float: "right" }}
                  bsStyle="default"
                  bsSize="small"
                  disabled={isDeleting || isCreating}
                  className="sub-create-button"
                  onClick={() => this.cancelCreate()}
                >
                  Cancel
                </Button>
              </div>

              {objId && (
                <div style={{ position: "absolute", left: 0, bottom: 70 }}>
                  <Button
                    bsStyle="danger"
                    bsSize="small"
                    disabled={isDeleting}
                    className="sub-create-button"
                    onClick={() => this.deleteObject()}
                  >
                    Delete Object
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="objects-how-to">
          <div
            style={{
              margin: "0px 0 15px 0",
              fontSize: "20px",
              display: "block",
            }}
          >
            Example Payload
          </div>

          <div className="obj-examples">
            <pre style={{ width: 500 }}>
              {`"eventObject": {
  "course": {
    "courseCode": "24617.1101"
  }
},
"objectType":`}{" "}
              "{tenantKey}.{appKey}.{objectName}
              {`,
"verb": "finish"`}
            </pre>
          </div>
        </div>
      </div>
    );
  }
}
