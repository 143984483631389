import React, { Component } from 'react';

import images from '../images';

export default class Environments extends Component {

  constructor() {
    super();
    this.state = {
    }
  }

  createFromTemplate = () => {
    this.props.history.push({
    pathname: '/a/' + this.props.match.params.appId + '/tf/new'
  });
}

  createFromWizard = () => {
    this.props.history.push({
    pathname: '/a/' + this.props.match.params.appId + '/wiz/new'
  });
}

  render(){
    return(
      <div className="parent-env" style={{ marginTop:50, paddingTop:15, paddingBottom:50 }}>
      <div className="main-tab-content" >

        <div
          style={{
            margin: '0px 0px 15px 0px',
            fontSize: '20px',
            display: 'inline-block'
          }}>
          Choose an Experience
        </div>
        <br/>

        <div className="method-selector" onClick={()=>this.createFromTemplate()}>
        <h4>Recipe Editor</h4>

        <div className="icon-enveloper">
        <img
            height="72px"
            role="presentation"
            src={images.codeicon}
          />
          </div>

          <div className="recipe-create-desc">
          <span>Select from a library of templates to start your hands-on experience and enjoy the full power of recipes. Suggested for those familiar with SQL seeking more advanced integration capabilities.</span>
          </div>
        </div>

        <div className="method-selector" onClick={()=>this.createFromWizard()}>
        <h4>Salesforce Data Loader</h4>

        <div className="icon-enveloper">
        <img
            style={{filter: 'invert(100%)'}}
            role="presentation"
            src={images.ic_web_white_48dp}
          />
          </div>

          <div className="recipe-create-desc">
          <div  style={{verticalAlign: 'middle'}}>
          <span>A guided experience for quickly creating and configuring recipes. Suggested starting point for everyone.</span>
          </div>
          </div>

        </div>


        </div>
        </div>
      
      )
  }
}

