import React, { Component, Fragment } from "react";
import { countryList, stateList } from "./lists";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import CouponEntry from "./couponEntry";
import images from "../images";

import MyPaymentForm from "./functionalRecurly";
import {bindActionCreators} from "redux";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";
import {connect} from "react-redux";

function getAllIndexes(arr, val) {
  var indexes = [],
    i = -1;
  while ((i = arr.indexOf(val, i + 1)) != -1) {
    indexes.push(i);
  }
  return indexes;
}

class RecurlyForm extends Component {
  constructor() {
    super();
    this.state = {
      countries: [],
      states: [],
      orgInput: "",
      docuSignConnectorsTypes: [
        { key: "DocuSign Pre-Fill Extension", value: false },
        { key: "DocuSign Connector for Ellucian Colleague", value: false },
        { key: "DocuSign Connector for Oracle PeopleSoft", value: false },
        { key: "DocuSign Connector for Workday Student", value: false },
        { key: "DocuSign Connector for CampusNexus", value: false },
        { key: "DocuSign Connector for Jenzabar", value: false },
        { key: "Other", value: false },
      ],
    };
  }

  componentWillMount() {
    const countries = [];
    const states = [];

    countryList.map((o, i) => {
      countries.push(
        <option key={i} value={o.code}>
          {o.name}
        </option>
      );
    });

    stateList.map((o, i) => {
      states.push(
        <option key={i} value={o.abbreviation}>
          {o.name}
        </option>
      );
    });

    this.setState({ countries, states });
  }

  componentDidMount() {
    const { config } = this.props.redux;
    const recurly = window.recurly;
    const production = "ewr1-WOsD9pNkfswzPzJl4lc2kA";
    const integration = "ewr1-DcoLeuxCQ2kqViKIY9kukb";
    const isIntegration = config.IS_INTEGRATION;
    let envType = production;

    if (isIntegration) {
      envType = integration;
    }
    recurly.configure(envType);
  }

  handleDSconnectors(e, d) {
    let value = !d.value;
    let updatedData = this.state.docuSignConnectorsTypes.map((el) =>
      el.key == d.key ? Object.assign({}, el, { value }) : el
    );
    this.setState({
      docuSignConnectorsTypes: updatedData,
    });
    this.props.updateForm("docuSignConnectors", updatedData);
  }

  render() {
    const {
      isSubForm,
      recurlyAddons,
      selectedAddons,
      selectedPlan,
      handleAddons,
      orgInput,
      getCoupon,
      couponState,
      disableCoupon,
      couponCode,
      updateCoupon,
      updateForm,
      addAdditions,
      subtractAdditions,
      additions,
      discount,
      firstName,
      lastName,
      emailOne,
      emailTwo,
      requireBilling,
      phone,
      jobTitle,
      showBillingInfo,
      handleShowBillingInfo,
      config
    } = this.props;
    const { countries, states, docuSignConnectorsTypes } = this.state;
    const addons = [];
    const includedAddons = [];
    recurlyAddons &&
      recurlyAddons.sort((a, b) => {
        if (a.UnitAmountInCents.USD < b.UnitAmountInCents.USD) return -1;
        else if (a.UnitAmountInCents.USD > b.UnitAmountInCents.USD) return 1;
        return 0;
      });

    recurlyAddons &&
      recurlyAddons.map((o, i) => {
        const quantity = getAllIndexes(additions, o.Name);
        let price = o.UnitAmountInCents.USD;
        const included = !(price > 0);
        price = price * discount;
        const selected = selectedAddons && selectedAddons.includes(o);
        const showQuantity = o.DisplayQuantityOnHostedPage.Bool === true;

        if (!included && quantity.length > 1) {
          price = price * quantity.length;
        }

        const formatPrice = (price / 100).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        if (included) {
          includedAddons.push(
            <div
              key={i}
              style={{
                display: "inline-block",
                color: "#337ab7",
                fontWeight: "bold",
              }}
            >
              <span htmlFor={`addon-${o.Code}`}>{o.Name}</span>
            </div>
          );
        } else {
          addons.push(
            <div
              key={i}
              style={{ margin: 2, marginBottom: 7, display: "block" }}
            >
              <span>
                <input
                  style={{ display: "inline-block" }}
                  onClick={() => {
                    handleAddons(o);
                  }}
                  value="1"
                  type="checkbox"
                  data-recurly="addon"
                  name="addon"
                  data-recurly-addon={o.Code}
                  id={`addon-${o.Code}`}
                />
              </span>

              <span
                style={{
                  marginLeft: 6,
                  display: "inline-block",
                  width: "auto",
                }}
                htmlFor={`addon-${o.Code}`}
              >
                &nbsp;{o.Name}&nbsp;
              </span>

              {showQuantity && selected && (
                <span className="add-subtract-addon">
                  ({quantity ? quantity.length : "1"})&nbsp;&nbsp;
                  <Button
                    style={{
                      marginTop: -3,
                      marginRight: 2,
                      height: 20,
                      width: 16,
                    }}
                    onClick={() => subtractAdditions(o.Name)}
                  >
                    <img
                      src={images.ic_remove_black}
                      height="18px"
                      style={{ marginLeft: -9, marginTop: -14 }}
                    />
                  </Button>
                  <Button
                    bsStyle="primary"
                    style={{ marginTop: -3, height: 20, width: 16 }}
                    onClick={() => addAdditions(o.Name)}
                  >
                    <img
                      src={images.ic_add_white}
                      height="18px"
                      style={{ marginLeft: -9, marginTop: -14 }}
                    />
                  </Button>
                </span>
              )}
            </div>
          );
        }
      });

    return (
      <div
        style={{
          width: isSubForm ? 540 : null,
          margin: isSubForm ? "auto" : null,
        }}
      >
        {/* form begin */}

        <form
          style={{ width: isSubForm ? "fit-content" : null, textAlign: "left" }}
          onSubmit={this.props.submitForm}
          ref={this.props.setFormRef}
        >
          <div
            style={{
              display: showBillingInfo ? "none" : null,
              marginLeft: isSubForm ? null : -20,
            }}
          >
            <div className="signup-field">
              <label title="account_company_name">
                Organization Name<span style={styles.required}>*</span>
              </label>
              <input
                autoComplete="off"
                type="text"
                className="recurly-hosted-field curly-hosted-field"
                id="account_company_name"
                value={orgInput}
                name="company"
                onChange={(e) => {
                  const val = e.target.value;
                  if (
                    val === "" ||
                    /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(val)
                  ) {
                    this.props.updateForm("orgInput", val);
                  }
                }}
              />
            </div>

            <div className="signup-field">
              <label title="phone">
                Phone<span style={styles.required}>*</span>
              </label>
              <input
                type="text"
                onChange={(e) => updateForm("phone", e.target.value)}
                value={phone}
                placeholder="xxx-xxx-xxxx"
                className="recurly-hosted-field curly-hosted-field"
                data-recurly="phone"
                id="phone"
                name="phone"
              />
            </div>

            <br />
            <div className="signup-field">
              <label title="first_name">
                First Name<span style={styles.required}>*</span>
              </label>
              <input
                type="text"
                onChange={(e) => updateForm("firstName", e.target.value)}
                value={firstName}
                className="recurly-hosted-field curly-hosted-field"
                data-recurly="first_name"
                id="first-name"
                name="first_name"
              />
            </div>
            <div className="signup-field">
              <label title="last_name">
                Last Name<span style={styles.required}>*</span>
              </label>
              <input
                type="text"
                onChange={(e) => updateForm("lastName", e.target.value)}
                value={lastName}
                className="recurly-hosted-field curly-hosted-field"
                data-recurly="last_name"
                id="last-name"
                name="last_name"
              />
            </div>
            <br />
            <div className="signup-field">
              <label title="email">
                Work Email<span style={styles.required}>*</span>
              </label>
              <input
                type="text"
                onChange={(e) => updateForm("emailOne", e.target.value)}
                value={emailOne}
                className="recurly-hosted-field curly-hosted-field"
                data-recurly="email"
                id="email-name"
                name="email"
              />
            </div>
            <div className="signup-field">
              <label title="email">
                Confirm Work Email<span style={styles.required}>*</span>
              </label>
              <input
                type="text"
                onChange={(e) => updateForm("emailTwo", e.target.value)}
                value={emailTwo}
                className="recurly-hosted-field curly-hosted-field"
                data-recurly="email-confirm"
                id="email-name"
                name="email"
              />
            </div>
            <br />
            <div className="signup-field-job">
              <label title="first_name">
                Job title
                <span style={styles.required}>*</span>
              </label>
              <input
                type="text"
                onChange={(e) => updateForm("jobTitle", e.target.value)}
                value={jobTitle}
                className="recurly-hosted-field curly-hosted-field"
                data-recurly="job_title"
                id="job-title"
                name="job_title"
              />
            </div>
            <div className="signup-field-captcha">
              <ReCAPTCHA
                sitekey={config.GOOGLE_CAPTCHA_KEY}
                onChange={(e) => updateForm("captcha", e)}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 45,
              }}
            >
              <MyPaymentForm
                form={this.props.form}
                submitForm={this.props.submitForm}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const styles = {
  required: {
    color: "red",
  },
};

const mapStateToProps = (state) => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
      {
        ...mainActions,
        ...projectActions,
        ...transformerActions,
        ...datafeedActions,
        ...wizardActions,
        ...environmentActions,
        ...eventsActions,
      },
      dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecurlyForm);
