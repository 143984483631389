import React, { Component } from "react";
import { Button, DropdownButton, MenuItem, Modal } from "react-bootstrap";
import moment from "moment";
import "codemirror/lib/codemirror.css";
import MapTable from "./mapTable";
import images from "../images";
import EnvironmentDropdown from "../utils/EnvironmentDropdown";
import WizardRunConsole from "./wizardRunConsole";
import CheckInMod from "../recipe/checkinmod";
import { MyContext } from "./wizardTabs";
import Trigger from "./../recipe/trigger";
import CustomTabs from "./../recipe/tabs";
import MonitorWizard from "./../recipe/monitortransformer";

const { Body, Title, Header, Footer } = Modal;

// SKIP ON SERVER-SIDE RENDERING
let codemirror = null;
if (typeof window !== "undefined" && typeof window.navigator !== "undefined") {
  codemirror = require("react-codemirror2");
  require("codemirror/mode/yaml/yaml");
}

export default class WizardConfig extends Component {
  static contextType = MyContext;
  constructor() {
    super();
    this.state = {
      wiz: null,
      showRecipe: false,
      showDeleteDialog: false,
      selectedEnvironment: "",
      selectedWizardVersion: null,
      selectedWizardVersionIndex: 0,
      deploying: false,
      showSchedule: false,
      data: [],
      activeIndex: 0,
      piGuid: null,
      typeId: null,
    };
  }
  renderPIdata = (data) => {
    const { redux } = this.props;
    const { transformer, project } = redux;
    const { sessionEnvironment } = this.props.redux.environment;
    let selectedEnvironment = null;
    let selectedWizardVersion = null;
    if (data && data[0]) {
      selectedWizardVersion = data[0];
    }
    const transId =
      selectedWizardVersion && selectedWizardVersion.transformerId;
    const thisTransformer =
      transId &&
      transformer.versions &&
      transformer.versions.find((ver) => ver.Id === transId);
    const PI = data[0];
    const configs = PI.configuration;
    configs.title = PI.name;
    configs.lastModified = PI.lastModified;
    configs.lastModifiedBy = PI.lastModifiedBy;
    if (configs && configs.environment) {
      selectedEnvironment =
        project.environments &&
        project.environments.find((e) => e.name === configs.environment);
    }

    if (thisTransformer && thisTransformer.Config && sessionEnvironment) {
      selectedEnvironment =
        project.environments &&
        project.environments.find(
          (f) => f.environmentId === sessionEnvironment
        );
    }
    const wiz =
      redux.main &&
      redux.main.wizards &&
      redux.main.wizards.find((w) => String(this.state.typeId) === String(w.typeId));
    this.setState({ wiz, selectedEnvironment, selectedWizardVersion });
    if (thisTransformer && thisTransformer.Id && thisTransformer.Name) {
      const name = thisTransformer.Name;
      const config = thisTransformer.Config;
      const environ = thisTransformer.Environ;
      const processingType = thisTransformer.processingType || "batch";
      const version = thisTransformer.Version;
      const baseId = thisTransformer.BaseId;
      const id = thisTransformer.Id;
      this.props.actions.callUpdateTransformer(
        {
          // transformer data
          name,
          config,
          environ,
          processingType,
          version,
          baseId,
          id,
        },
        {},
        thisTransformer.appId,
        thisTransformer.BaseId
      );
    }
  };

  componentWillMount() {
    const { redux, actions, match, location } = this.props;
    const { project } = redux;
    const { params } = match;
    const { appId, transId } = params;
    const { state } = location;

    if (parseInt(transId, 10)) {
      actions.callGetDeploymentsForTransformer(appId, transId);
      actions.callGetAllTriggers(transId);
    }
    if (!(state && state.piGuid && state.typeId)) {
      // hard refresh
      if (parseInt(transId, 10)) {
        actions
          .callGetTransformerVersions(transId) // this is the baseId
          .then((r) => {
            this.setState({
              piGuid: r.GeneratedFromPi,
              typeId: r.GeneratedFromPiTypeId,
            });
            actions
              .callGetPiConfig(
                project.tenantInfo.TenantId,
                appId,
                r.GeneratedFromPi
              )
              .then((data) => {
                this.renderPIdata(data);
              });
          });
      } else {
        // transId === 0 means that there is no recipe yet
        this.props.history.push({
          pathname: `/a/${appId}/wiz`,
        });
      }
    } else {
      // coming from boxwizard.js
      if (parseInt(transId, 10)) {
        actions.callGetTransformerVersions(transId);
      }
      this.setState({ piGuid: state.piGuid, typeId: state.typeId });
      actions
        .callGetPiConfig(project.tenantInfo.TenantId, appId, state.piGuid)
        .then((data) => {
          this.renderPIdata(data);
        });
    }
  }

  /*componentDidMount() {
    this.setState({ boxyHeight: window.innerHeight - 96 })
  }*/
  handleRemoveExecutionTab() {
    let executionLogsTab = this.state.data.find(
      (d) => d.tab == "Execution Log"
    );
    if (executionLogsTab && executionLogsTab.id) {
      //already present then remove
      let newData = this.state.data.filter(
        (item) => item.id !== executionLogsTab.id
      );
      this.setState({
        data: newData,
        activeIndex: newData.length - 1,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { transformer } = this.props.redux;
    const uiEnvironment = this.state.selectedEnvironment;
    const {
      showDeleteDialog,
      selectedWizardVersion,
      selectedWizardVersionIndex,
      selectedEnvironment,
    } = this.state;
    if (
      prevProps.redux.transformer.monitorLogs[0] !=
        transformer.monitorLogs[0] ||
      prevProps.redux.transformer.selectedTransformerLogs !=
        transformer.selectedTransformerLogs
    ) {
      let executionLogTab = this.state.data.find(
        (d) => d.tab == "Execution Log"
      );

      if (executionLogTab && executionLogTab.id) {
        let index = this.state.data.findIndex((d) => d.tab === "Execution Log");
        let component = (
          <MonitorWizard
            {...this.props}
            showLastRecipeLog={true}
            recipeId={this.props.match.params.transId}
          />
        );
        let i;
        this.setState({
          data: this.state.data.map((el) =>
            el.id === executionLogTab.id ? { ...el, component } : el
          ),
          activeIndex: index,
        });
      }
    } else if (
      prevProps.redux.transformer.ongoingExecutionLogs !=
        transformer.ongoingExecutionLogs ||
      prevProps.redux.transformer.executionErrorMessage !=
        transformer.executionErrorMessage ||
      prevProps.redux.transformer.executionCompleted !=
        transformer.executionCompleted ||
      prevProps.redux.transformer.disableTerminate !=
        transformer.disableTerminate ||
      prevState.selectedEnvironment != this.state.selectedEnvironment
    ) {
      let runTab = this.state.data.find((d) => d.tab == "Run Wizard");
      if (runTab && runTab.id) {
        let index = this.state.data.findIndex((d) => d.tab === "Run Wizard");
        let component = (
          <WizardRunConsole
            params={this.props.match.params}
            {...this.props}
            env={uiEnvironment}
            opened={true}
            wiz={this.state.wiz}
            thisWizard={selectedWizardVersion}
            thisWizardIndex={selectedWizardVersionIndex}
            handleRemoveExecutionTab={() => this.handleRemoveExecutionTab()}
          />
        );
        this.setState({
          data: this.state.data.map((el) =>
            el.id === runTab.id ? { ...el, component } : el
          ),
        });
        if (this.state.activeIndex != index) {
          this.setState({ activeIndex: index });
        }
      }
    }
  }

  changeVersion = (i) => {
    const { redux, actions, match } = this.props;
    const { params } = match;
    const { transformer, project } = redux;
    const wv = transformer.wizardVersions[i];
    this.setState({
      selectedWizardVersion: wv,
      selectedWizardVersionIndex: i,
    });
    actions
      .changePiVersion(project.tenantInfo.TenantId, params.appId, wv)
      .then(() => {
        const envName = wv.configuration && wv.configuration.environment;
        const env = project.environments.find((e) => e.name === envName);
        this.setState({ selectedEnvironment: env });
      });
  };

  handleRunIntg = () => {
    const uiEnvironment = this.state.selectedEnvironment;
    if (!uiEnvironment) {
      alert("Please select an environment");
      return;
    }
    const {
      showDeleteDialog,
      selectedWizardVersion,
      selectedWizardVersionIndex,
      selectedEnvironment,
    } = this.state;
    this.props.actions
      .clearOngoingExecutionData(parseInt(this.props.match.params.transId))
      .then(() => {
        let runWizTab = this.state.data.find((d) => d.tab == "Run Wizard");
        if (runWizTab && runWizTab.id) {
          //already present then remove
          let newData = this.state.data.filter(
            (item) => item.id !== runWizTab.id
          );
          this.setState({
            data: newData,
            activeIndex: newData.length - 1,
          });
        } else {
          let newData = [...this.state.data];
          const id = new Date().valueOf();
          this.setState({
            data: newData.concat({
              tab: "Run Wizard",
              component: (
                <WizardRunConsole
                  params={this.props.match.params}
                  {...this.props}
                  env={uiEnvironment}
                  wiz={this.state.wiz}
                  thisWizard={selectedWizardVersion}
                  thisWizardIndex={selectedWizardVersionIndex}
                  isExecuting={true}
                  handleRemoveExecutionTab={() =>
                    this.handleRemoveExecutionTab()
                  }
                />
              ),
              id: id,
              closeable: true,
            }),
            activeIndex: newData.length,
          });
        }
      });
  };
  addExecutionLog = () => {
    const { transformer } = this.props.redux;
    let executionLogsTab = this.state.data.find(
      (d) => d.tab == "Execution Log"
    );
    if (executionLogsTab && executionLogsTab.id) {
      //already present then remove
      let newData = this.state.data.filter(
        (item) => item.id !== executionLogsTab.id
      );
      this.setState({
        data: newData,
        activeIndex: newData.length - 1,
      });
    } else {
      let newData = [...this.state.data];
      let resultsTab = this.state.data.find((d) => d.tab == "Run Wizard");
      if (resultsTab && resultsTab.id) {
        //remove results tab if present
        if (
          transformer.executionCompleted &&
          transformer.executionLogs.length > 0
        ) {
          newData = this.state.data.filter((item) => item.id !== resultsTab.id);
        }
      }
      const id = new Date().valueOf();
      this.setState({
        data: newData.concat({
          tab: "Execution Log",
          component: (
            <MonitorWizard
              {...this.props}
              showLastRecipeLog={true}
              recipeId={this.props.match.params.transId}
            />
          ),
          id: id,
          closeable: true,
        }),
        activeIndex: newData.length,
      });
    }
  };
  handleFinalDeploy = () => {
    const { selectedWizardVersion, selectedEnvironment } = this.state;
    const { actions, match, redux } = this.props;
    const { params } = match;
    const { transformer, project } = redux;
    const transId = selectedWizardVersion.transformerId;
    let trans = null;
    if (transId) {
      trans =
        transId &&
        transformer.versions &&
        transformer.versions.find((ver) => ver.Id === transId);
    } else {
      trans = transformer.versions[transformer.versions.length - 1];
    }
    const env =
      project &&
      project.environments.find((e) => {
        return e.name === (selectedEnvironment && selectedEnvironment.name);
      });
    const baseId = params.transId;
    this.setState({ deploying: true });
    actions
      .deployTransformerToEnvironment(
        project.tenantInfo.TenantId,
        params.appId,
        baseId,
        trans.Id,
        env.environmentId
      )
      .then(() => {
        this.setState({ deploying: false });
      });
  };

  deleteRecipe = () => {
    const { match, redux } = this.props;
    // const {  } = this.context;
    const { params } = match;
    this.props.actions.callDeleteWizard(
      redux.project.tenantInfo.TenantId,
      params.appId,
      this.state.piGuid
    );
    this.props.history.push(`/a/${params.appId}/wiz`);
  };

  restart = (newVersion) => {
    const { appId, transId } = this.props.match.params;
    const transformerBaseId = parseInt(transId);
    const { selectedWizardVersion, selectedWizardVersionIndex } = this.state;
    const state = {
      piGuid: this.state.piGuid,
      typeId: this.state.typeId,
      piIndex: selectedWizardVersionIndex,
      ...(!newVersion && {
        updateWizardVersionId:
          selectedWizardVersion && selectedWizardVersion.id,
      }),
      ...(transformerBaseId && { transformerBaseId }),
    };
    this.props.history.push({
      pathname: `/a/${appId}/wiz/${transId}/configure`,
      state: state,
    });
  };

  onFinishRunOnce = () => {
    const { match, actions, redux } = this.props;
    const { params } = match;
    const { project } = redux;
    actions.callGetTransformerLogs(
      project.tenantInfo.TenantId,
      params.transId,
      "",
      100
    );
  };

  handleShowCheckIn = () => {
    this.setState({ showcheckin: true });
  };

  showSchedulesModal = () => {
    let showSchedule = this.state.showSchedule;
    showSchedule = !showSchedule;
    this.setState({ showSchedule });
  };

  handleCloseCheckIn = () => {
    this.setState({ showcheckin: false });
  };

  handleCheckIn = () => {
    const newVersion = true;
    this.restart(newVersion);
  };
  showRecipeTab = (recipe) => {
    let recipeViewTab = this.state.data.find((d) => d.tab == "Recipe");
    if (recipeViewTab && recipeViewTab.id) {
      //already present then remove
      let newData = this.state.data.filter(
        (item) => item.id !== recipeViewTab.id
      );
      this.setState({
        data: newData,
        activeIndex: newData.length - 1,
      });
    } else {
      let newData = [...this.state.data];
      const CodeMirror = codemirror && codemirror.Controlled;

      const id = new Date().valueOf();
      this.setState({
        data: newData.concat({
          tab: "Recipe",
          component: (
            <CodeMirror
              value={recipe}
              options={{
                lineNumbers: true,
                theme: "default",
                mode: "yaml",
                readOnly: true,
              }}
            />
          ),
          id: id,
          closeable: true,
        }),
        activeIndex: newData.length,
      });
    }
  };

  render() {
    const { redux, actions } = this.props;
    const {
      showDeleteDialog,
      selectedWizardVersion,
      selectedWizardVersionIndex,
      selectedEnvironment,
    } = this.state;
    const { transformer, project } = redux;
    const { executingTransformers } = transformer;
    const piEnv = selectedEnvironment && selectedEnvironment.name;
    const transId =
      selectedWizardVersion && selectedWizardVersion.transformerId;
    let thisTransformer = null;
    if (transId) {
      // new style
      thisTransformer =
        transId &&
        transformer.versions &&
        transformer.versions.find((ver) => ver.Id === transId);
    } else {
      // old version
      /*thisTransformer =
        transformer.versions &&
        transformer.versions[transformer.versions.length - 1]*/
    }

    const uiEnvironment = this.state.selectedEnvironment;

    const versionIsDeployed =
      thisTransformer &&
      this.state.selectedEnvironment &&
      transformer.deployments &&
      transformer.deployments.find((d) => {
        return (
          d.transId === thisTransformer.Id &&
          this.state.selectedEnvironment.environmentId === d.environmentId
        );
      });

    const recipe = thisTransformer && thisTransformer.Config;
    const curVersion = transformer.wizardVersions
      ? transformer.wizardVersions.length - selectedWizardVersionIndex
      : 0;

    return (
      <div className="parent-env">
        <div style={styles.main}>
          <div
            className="main-tab-content"
            style={{
              position: "relative",
              paddingRight: 10,
              paddingTop: 16,
              height: "100%",
            }}
          >
            <div>
              <div style={{ display: "inline-block", verticalAlign: "top" }}>
                <EnvironmentDropdown
                  environments={project.environments}
                  deployments={transformer.deployments}
                  uiEnvironment={uiEnvironment}
                  disabled={!recipe}
                  versions={transformer.versions}
                  onSelect={(env) =>
                    this.setState({ selectedEnvironment: env })
                  }
                  wizardVersions={transformer.wizardVersions}
                  actions={actions}
                />
              </div>

              <div style={styles.toolbarItem} className="dropdown-button-wrap">
                <DropdownButton
                  onSelect={this.changeVersion}
                  title={`Version ${
                    transformer.wizardVersions
                      ? transformer.wizardVersions.length -
                        selectedWizardVersionIndex
                      : 0
                  }`}
                  bsSize="small"
                  bsStyle="default"
                  id="versions"
                >
                  {transformer.wizardVersions &&
                    transformer.wizardVersions.map((v, i) => {
                      return (
                        <MenuItem
                          eventKey={i}
                          key={i}
                          active={selectedWizardVersionIndex === i}
                        >
                          <strong>
                            {transformer.wizardVersions.length - i}
                          </strong>
                          &nbsp;&nbsp;
                          <span>-</span>&nbsp;&nbsp;
                          <span>{moment(v.lastModified).format("l")}</span>
                        </MenuItem>
                      );
                    })}
                </DropdownButton>
              </div>

              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  marginLeft: 20,
                }}
              >
                <Button
                  id="run-recipe-btn"
                  bsSize="small"
                  style={{ width: 91 }}
                  bsStyle="default"
                  onClick={this.handleShowCheckIn}
                >
                  New Version
                </Button>
              </div>
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  marginRight: 10,
                  float: "right",
                }}
              >
                <Button
                  id="run-recipe-btn"
                  bsSize="small"
                  style={{ width: 80 }}
                  bsStyle="default"
                  onClick={this.showSchedulesModal}
                >
                  Schedule
                </Button>
              </div>
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  marginRight: 10,
                  float: "right",
                }}
              >
                <Button
                  id="run-recipe-btn"
                  bsSize="small"
                  style={{ width: 101 }}
                  bsStyle="default"
                  onClick={() => this.addExecutionLog()}
                  disabled={
                    executingTransformers && executingTransformers.length > 1
                      ? true
                      : false
                  }
                >
                  Execution Log
                </Button>
              </div>
              {recipe && (
                <div
                  className="view-recipe-in-wizard"
                  style={{
                    float: "right",
                    marginTop: 4,
                  }}
                  onClick={() => this.showRecipeTab(recipe)}
                >
                  View Recipe
                </div>
              )}

              <div style={styles.toolbarItem}>
                <Button
                  bsStyle="primary"
                  bsSize="small"
                  onClick={() => this.restart(false)}
                  disabled={versionIsDeployed ? true : false}
                >
                  Edit Configuration
                </Button>
              </div>

              {recipe && (
                <div style={styles.toolbarItem}>
                  <Button
                    bsStyle="primary"
                    bsSize="small"
                    onClick={() => {
                      this.handleRunIntg();
                    }}
                    disabled={
                      executingTransformers && executingTransformers.length > 1
                        ? true
                        : false
                    }
                  >
                    Run Integration
                  </Button>
                </div>
              )}

              {recipe && (
                <div style={styles.deployer}>
                  {piEnv && !versionIsDeployed && (
                    <Button
                      bsSize="small"
                      bsStyle="primary"
                      style={{ width: 90, marginBottom: -6 }}
                      onClick={this.handleFinalDeploy}
                    >
                      {!this.state.deploying ? (
                        <span>Deploy Now</span>
                      ) : (
                        <img
                          alt="small-spinner"
                          src={images.ajax_loader_small}
                          height="8"
                        />
                      )}
                    </Button>
                  )}
                  {versionIsDeployed ? (
                    <strong style={{ fontSize: "12px" }}>Deployed</strong>
                  ) : (
                    <span />
                  )}
                </div>
              )}

              <br />
            </div>
            <MapTable
              bundles={transformer.bundles}
              mapping={transformer.mapping}
            />
            <br />
            <br />
            {this.state.data && this.state.data.length > 0 && (
              <CustomTabs
                data={this.state.data}
                activeIndex={this.state.activeIndex}
                handleOnCloseTab={(id, newIndex) => {
                  try {
                    if (
                      this.state.data &&
                      this.state.data.length > 1 &&
                      newIndex == -1
                    ) {
                      this.setState({
                        data: [],
                      });
                    } else {
                      this.setState({
                        data: this.state.data.filter((item) => item.id !== id),
                        activeIndex: newIndex,
                      });
                    }
                  } catch (e) {
                    null;
                  }
                }}
              />
            )}

            {/*
          <Button bsSize="small" bsStyle="danger"
            onClick={()=>this.setState({showDeleteDialog:true})}
            style={{width:121}}>
              Delete Integration
          </Button>
          <br /><br />

          {showDeleteDialog ?
            <div style={dialogStyles}>
              <h4>
                Are you sure you want to delete this Integration?
              </h4>
              <hr />
              <div style={{ marginTop: '1rem' }}>
                <Button
                  bsStyle="primary"
                  onClick={() => this.setState({showDeleteDialog: false})}>
                  Cancel
                </Button>
                <Button bsStyle="danger"
                  style={{ marginLeft: '.5rem', float:'right' }}
                  onClick={this.deleteRecipe}>
                  Delete
                </Button>
              </div>
            </div>
          : null}
        */}

            {/* {this.state.wiz && selectedWizardVersion && uiEnvironment && (
              <WizardRunConsole
                params={this.props.match.params}
                {...this.props}
                env={uiEnvironment}
                opened={showRunDialog}
                handleCancel={() => this.setState({ showRunDialog: false })}
                wiz={this.state.wiz}
                thisWizard={selectedWizardVersion}
                thisWizardIndex={selectedWizardVersionIndex}
              />
            )} */}

            {this.state.showSchedule && (
              <Modal
                show={this.state.showSchedule}
                onHide={this.showSchedulesModal}
                backdrop="static"
                dialogClassName="recipe-schedule-section"
              >
                <Header closeButton>
                  <Title style={{ fontWeight: "bold" }}>Schedules</Title>
                </Header>
                <Body style={{ height: 460, overflowY: "auto" }}>
                  <Trigger {...this.props} />
                </Body>
              </Modal>
            )}

            <CheckInMod
              show={this.state.showcheckin}
              hide={this.handleCloseCheckIn}
              handleCheckIn={this.handleCheckIn}
              nextVer={curVersion + 1}
              curVersion={curVersion}
            />

            {showDeleteDialog && <div className="overlay-100" />}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  main: {
    background: "#bbb",
    width: "100%",
    height: "calc(100% - 50px)",
    marginTop: 45,
  },
  toolbarItem: {
    display: "inline-block",
    verticalAlign: "top",
    marginLeft: 20,
  },
  deployer: {
    display: "inline-block",
    verticalAlign: "bottom",
    marginBottom: 6,
    marginLeft: 20,
    marginTop: 1,
  },
};
