import React from 'react'
import { Modal } from 'react-bootstrap'
import NewSubscription from './newSubscription'
import 'rc-time-picker/assets/index.css'
const { Body } = Modal;

export default class ScheduleMod extends React.Component {
  constructor() {
    super();
    this.state = {
      availableTemplates: []
    }
  }
  componentDidMount() {
    const { actions, redux } = this.props;
    const identity = redux.main.me;
    if (identity.role == "tech-support") {
      actions
        .getDataMechanicsTemplates()
        .then((data) => {
          if (data && data.length > 0) {
            let templates = []
            data.map((template) => {
              templates.push(template.Name)
            })
            this.setState({ availableTemplates: templates })
          }
        })
    }
  }
  render() {


    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.hide} backdrop="static"
          dialogClassName="sub-create-modal">
          <Body style={{ position: 'relative', overflowY: 'auto', borderRadius: 5, background: '#ddd', minHeight: '550px' }}>
            <div style={{ verticalAlign: 'middle', height: '100%', paddingBottom: "2rem" }}>
              <NewSubscription
                triggerModal={this.props.hide}
                resetCalendarState={this.props.resetCalendarState}
                selectedEvent={this.props.selectedEvent}
                availableTemplates={this.state.availableTemplates}
                editMode={this.props.editMode}
                toggleEditMode={this.props.toggleEditMode}
                triggerMode={true}
                {...this.props} />
            </div>
          </Body>
        </Modal>
      </div>
    )
  }
}