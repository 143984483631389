import React from "react";
import images from "../images";

const WebhookItems = (props) => {
  return (
    <div
      className={props.sys ? "sys-webhook-item" : "webhook-item"}
      key={props.index}
      onClick={() =>
        props.sendToEdit(
          null,
          props.object.name,
          props.sys,
          props.identityIsAdmin
        )
      }
    >
      <span>{props.object.name}</span>
      {!props.sys && (
        <div style={{ position: "absolute", right: 8, top: 6 }}>
          <img
            className="object-edit-icon"
            role="presentation"
            src={images.ic_settings_white_48dp}
            style={styles.settings}
          />
        </div>
      )}
    </div>
  );
};

export default WebhookItems;

const styles = {
  settings: {
    height: 18,
    marginTop: -5,
    marginRight: 3,
  },
  settingsInvert: {
    height: 18,
    marginTop: -5,
    marginRight: 3,
    filter: "invert(60%)",
  },
  icon: {
    height: 15,
    marginTop: -5,
    marginRight: 3,
  },
  iconInvert: {
    height: 15,
    marginTop: -5,
    marginRight: 3,
    filter: "invert(100%)",
  },
};
