import React, { Component } from 'react';
import 'react-datasheet/lib/react-datasheet.css';
import images from '../images'
//import CommentBar from '../analytics/commentbar'
import DomPurify from 'dompurify'
import MarkdownIt from 'markdown-it';

let codemirror = null;
if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
  codemirror = require('react-codemirror2')
  require('codemirror/mode/markdown/markdown')
  require('codemirror/mode/javascript/javascript')
}
const CodeMirror = codemirror && codemirror.Controlled

export default class Mapper extends Component {

  constructor() {
    super();
    this.state={
      editDescription:false
    }
    this.md = new MarkdownIt();
  }

  createMarkup(desc) {
    return {
      __html: DomPurify.sanitize(desc)
    };
  }

  render() {

    let description;
    if (this.props.description) {
      description = this.createMarkup(this.md.render(this.props.description));
    }
    
    return(<div style={{width:'100%',marginBottom:40}}>

      <div style={{margin:'30px 0 8px 0', fontSize:'16px', 
        display:'inline-block',lineHeight:'16px'}}>
        Data Mapping Description
      </div>
      {!this.props.readOnly && <div style={{position:'relative', display:'inline-block', verticalAlign:'top'}}>
        {!this.state.editDescription ?
          <div style={{position:'absolute',top:28,left:6,cursor:'pointer'}}
            onClick={()=>this.setState({editDescription:true})}>
            <img src={images.create} style={{width:20}} alt="create" />
          </div> :
          <div className="description-icon" 
            style={{position:'absolute',top:28,left:6,cursor:'pointer'}}
            onClick={()=>this.setState({editDescription:false})}>
            <img src={images.ic_clear_black_48dp} style={{width:20}} alt="clear" />
          </div>
        }
      </div>}

      <div style={{
        marginBottom: 50, display:this.state.editDescription ? 'block' : 'none', 
        height: 'auto', width:'100%'}}>
        {CodeMirror && <CodeMirror value={this.props.description}
          options={{lineNumbers:true, theme:'default', mode:'markdown'}} 
          onBeforeChange={this.props.changeDescription} />}
      </div>
      <div style={{
        background:'#efefef',padding:'8px 12px', width:'100%', outline:'1px solid #CCC',
        display:this.state.editDescription ? 'none' : 'block', minHeight:46}}>
        <div dangerouslySetInnerHTML={description} />
      </div>

    </div>)
  }
}

