import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
//--------------------------------
// Containers
//--------------------------------
import AppWrapper from "../containers/appWrapper";
//--------------------------------
// Pages
//--------------------------------
import Main from "../pages/main";
import DataFeedInvitation from "../pages/datafeedinvitation";
import Resources from "../pages/resources";
import DSOauthRedirect from "../components/wizard/docuSignOauthRedirect";

//--------------------------------
// auth0 cross origin verification
//--------------------------------
import CrossOriginVerification from "../helpers/crossOriginVerification";
//--------------------------------
// Account Components
//--------------------------------
import NewAccount from "../components/account/newaccount";

import RegisterPublic from "../components/recurlySubscription/registerPublic";

//public recipe demo
import RecipeDemo from "../components/recipe/recipeDemo";
//--------------------------------
// User Components
//--------------------------------
import UserOrgs from "../components/user/userorgs";
import SwaggerComponent from "../components/header/swagger";
import EditUser from "../components/user/edituser";
import AddUser from "../components/user/adduser";
//--------------------------------
// Wizard Components
//--------------------------------
import SFOauthRedirect from "../components/wizard/sfOauthRedirect";
import BSOauthRedirect from "../components/wizard/bsOauthRedirect";
import ZoomOAuthRedirect from "../components/wizard/zoomOAuthRedirect";

import SharedDataMapping from "../components/mapper/shared";
import AccountParent from "../pages/accountParent";
import GenericNotFound from "../components/pageNotFound";
import CanvasOAuth2OAuthRedirect from "../components/wizard/canvasOAuth2OAuthRedirect";

/*
 * dimension1 <== userID
 * dimension2 <== tenantKey
 * dimension3 <== projectKey
 */

function logPageView(previousRoute, nextRoute) {
  const oldPathname = previousRoute.history.location.pathname;
  const newPathname = nextRoute.history.location.pathname;
  if (oldPathname === newPathname) return;
  const profile = JSON.parse(window.localStorage.getItem("profile"));
  if (profile) {
    const { user_id } = profile;
    // user can change the title
    setTimeout(() => {
      ReactGA.ga("send", {
        hitType: "pageview",
        page: window.history.location.pathname,
        title: document.title,
        dimension1: user_id,
      });
    }, 0);
  }
}

export const getRoutes = () => (
  <AppWrapper>
    <Switch>
      <Route path="/404" component={GenericNotFound} />
      <Route exact name="main" path="/" component={Main} />
      <Route name="newaccount" path="/newaccount" component={NewAccount} />
      <Route name="oauth" path="/sfoauth" component={SFOauthRedirect} />
      <Route name="oauth" path="/dsoauth" component={DSOauthRedirect} />
      <Route name="oauth" path="/bsoauth" component={BSOauthRedirect} />
      <Route name="oauth" path="/zoomOauth" component={ZoomOAuthRedirect} />
      <Route name="oauth" path="/canvasOauth" component={CanvasOAuth2OAuthRedirect} />

      <Route
        name="crossOriginVerification"
        path="/verify"
        component={CrossOriginVerification}
      />
      <Route
        name="mapper"
        path="/datamapping/:mapperGuid"
        component={SharedDataMapping}
      />
      <Route name="swagger" path="/swagger" component={SwaggerComponent} />
      <Route name="usersettings" path="/user" component={UserOrgs} />
      <Route name="adduser" path="/adduser/:tenantId" component={AddUser} />
      <Route name="edituser" path="/edituser" component={EditUser} />
      <Route
        name="invitations"
        path="/invitations/:feedKey"
        component={DataFeedInvitation}
      />
      <Route name="resources" path="/resources" component={Resources} />

      <Route name="register" path="/register" render={() => (<Redirect to="/registerTrial" />)} />
      <Route name="register" path="/registerYear" render={() => (<Redirect to="/registerTrial" />)} />
      <Route name="register" path="/registerTrial" component={RegisterPublic} />

      <Route
        name="recipedemo"
        path="/recipe/:exampleId"
        component={RecipeDemo}
      />
      <Route name="a" path="/a/:appId" component={AccountParent} />
    </Switch>
  </AppWrapper>
);
