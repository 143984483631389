import _columns from './columns'

function sheetToGrid(sheet, showApiNames) {
  const grid = sheet && sheet.map((row,r)=>{
    return row.map((column,c)=>{
      let v = ''
      if(column){
        if(_columns[c].isObject){
          v = showApiNames ? column.type : column.name
        } else if(_columns[c].isField){
          if(column.parents && column.parents.length>0){
            column.parents.forEach((p)=>{
              v += p
              v += ': '
            })
          }
          v += showApiNames ? (column.name||'') : (column.label||'')
        } else {
          v = column.value
        }
      }
      if(_columns[c].name==='Type'){
        const previous = sheet[r][c-1]
        if(previous){
          v = previous.type
        }
      }
      if(_columns[c].comp==='checkbox'){
        v = (column && column.value) ? '✔':''
      }
      return {
        value:v,
        readOnly:_columns[c].name==='Type',
        sheetRowIndex:r,
        sheetColumnIndex:c
      }
    })
  })
  return grid || [[]]
}

function makeRowColors(grid){
  const rowColors = grid.map((row)=>{
    let color = ''
    if(row && row.length>0){
      const type1 = row[2].value
      const type2 = row[5].value
      if(type1&&type2&&type1!==type2){
        color = 'typeWarning'
      }
      if(!color&&row[0].value&&row[1].value&&row[3].value&&row[4].value){
        color = 'ok'
      }
    }
    return color
  })
  return rowColors
}

// convert the sheet (array of arrays) to a mapping to be stored in DB
// mappings is an an array of objects, key:value
// key being the key in columns.js
function sheetToMappings(sheet){
  const mappings = sheet && sheet.map((row)=>{
    const obj = {}
    row.forEach((v,c)=>{
      obj[_columns[c].key] = v
    })
    return obj
  })
  return JSON.stringify(mappings)
}
// convert the mappings from DB [{}] back to sheet [[]]
function mappingsToSheet(mappings){
  let parsedMappings = null
  try{parsedMappings=JSON.parse(mappings)}catch(e){parsedMappings=null}
  const sheet = parsedMappings && parsedMappings.map((row)=>{
    return _columns.map((_c)=>{
      return row[_c.key]
    })
  })
  return sheet
}

export {sheetToGrid, makeRowColors, sheetToMappings, mappingsToSheet}