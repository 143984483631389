const logoStyles = {
  'REST API': {},
  Canvas: {},
  Lingk: {width:66, height:30},
  Blackboard: {width:60},
  Salesforce: {width:66},
  'Flat File': {},
  SFTP: {},
  CSV: {},
  Banner: {width:66},
  Colleague: {width:66},
  PeopleSoft: {width:72, height:20},
  S3: {},
  LingkAdapter: {width:66, height:30},
  'Lingk Adapter': {width:66, height:30},
  BigQuery: {width:52},
  GoogleBigQuery: {width:52},
}

export default logoStyles
