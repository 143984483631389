const ContentWsStyles = {
  contentStyle: {
    marginTop: 65,
    marginLeft: 85,
    marginRight: 50,
    color: 'black',
  },
  contentStyleRecipe: {
    paddingTop: 65,
    paddingLeft: 120,
    paddingRight: 50,
    color: 'black',
    position: 'relative',
    height: '100%',
    overflowY: 'hidden',
  },
  contentStyleWide: {
    marginTop: 65,
    marginLeft: 220,
    marginRight: 50,
    color: 'black',
  },
  contentStyleForZipFiles: {
    marginLeft: 220,
    color: 'black',
    float: 'left',
  },
  divLeftStyle: {
    width: 600,
    marginLeft: 25,
    display: 'inline-block',
  },
  divRightStyle: {
    display: 'inline-block',
  },
  panelLeftStyle: {
    width: 220,
    display: 'inline-block',
  },
  panelRightStyle: {
    marginLeft: 40,
    display: 'inline-block',
  },
  panelRightStyle2: {
    marginLeft: 40,
    display: 'inline-block',
    width: 260,
  },
  datafeedDetail: {
    borderStyle: 'solid',
    borderWidth: 1,
    paddingLeft: 5,
  },
  picStyle: {
    width: '641px',
    height: '262px',
  },
  sidebarTopstyle: {
    paddingTop: 50,
    marginLeft: 10,
    marginRight: 10,
  },
  sidebarBoldText: {
    fontWeight: 'bold',
    marginLeft: 10,
    marginRight: 10,
  },
  sidebarStyle: {
    marginLeft: 10,
    marginRight: 10,
  },
  sidebarPanelStyle: {
    backgroundColor: 'white',
    marginLeft: 10,
    marginRight: 10,
  },
  examplePanelStyle: {
    width: 425,
    marginLeft: 25,
  },
  errorPanelStyle: {
    backgroundColor: '#FFD700',
  },
  cursorStyle: {
    cursor: 'pointer',
  },
  indentStyle: {
    marginLeft: 45,
    cursor: 'pointer',
  },
  lineStyle: {
    color: 'black',
    height: 1,
    borderColor: 'black',
    backgroundColor: 'black',
  },
  navStyle: {
    marginLeft: 500,
  },
  hrefButton: {
    color: 'white',
  },
  titleStyles: {
    fontSize: '22px',
    marginBottom: 5,
  },
  formElementTitle: {
    marginTop: 5,
    fontWeight: 'bold',
  },
  leftRapper: {
    margin: 0,
    display: 'inline-block',
    width: '80vw',
  },
  rightRapper: {
    marginTop: 0,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  fullWrapper: {
    display: 'inline-block',
    width: '90vw',
    marginBottom: '8px',
  },
};

export default ContentWsStyles;
