import React, { Component } from "react";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "moment-timezone";
import range from "lodash/range";
import { If, Then, Else } from "react-if";
import { DropdownButton, MenuItem, Button, FormControl } from "react-bootstrap";
import images from "../images";
import EnvironmentDropdown from "../utils/EnvironmentDropdown";
import CronBuilder from "../CronBuilder";
import Select from "../CronBuilder/components/components/Select";
import Loading from "../header/loading";

const toOptions = (_values) => {
  return _values.map(String).map((value) => ({
    value,
    label: value,
  }));
};

const recurringMinutes = (_values) => {
  var values1 = [];
  for (var prop in _values) {
    if (_values[prop] % 5 == 0) {
      values1.push({ value: _values[prop].toString(), label: _values[prop] });
    }
  }
  return values1;
};

const timeZones = () => {
  let tzs = require("./timezones.json");
  return tzs;
};

const recurringHoursOptions = toOptions(range(1, 24));
const recurringMinutesOptions = recurringMinutes(range(5, 60));
export default class Trigger extends Component {
  constructor() {
    super();
    this.state = {
      selectedSource: null,
      dir: "",
      isfile: false,
      isrecurring: false,
      iswebhook: false,
      filepattern: "",
      eventdate: "",
      type: "",
      action: "",
      filename: "",
      objectType: null,
      objectTypeChanged: false,
      frequency: "",
      frequencyUnit: "Hours",
      dailySchedule: moment().hour(0).minute(0),
      selectedEnv: null,
      selectedEnvId: null,
      isClearingTrigger: false,
      isSavingTrigger: false,
      cronExpression: "0 9-17/1 * * 1-5 *",
      cronExplain: "",
      timeZone: moment.tz.guess(),
      startingAt: null,
      curTime: null,
      selectedTemplate: "",
      showTemplateSelection: false,
    };
  }

  componentDidMount() {
    // const {project} = this.props.redux
    // const {sessionEnvironment} = this.props.redux.environment

    // if (sessionEnvironment){
    //   const selectedEnv = project.environments &&
    //   project.environments.find(f=>f.environmentId===sessionEnvironment)
    //   this.setState({selectedEnv})
    // }
    // else{
    //   if(project.environments && project.environments.length===1){
    //     this.setState({selectedEnvironment:project.environments[0]})
    //   }
    // }

    function tConvert(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    }

    setInterval(() => {
      this.setState({
        curTime: tConvert(moment(new Date()).format("HH:mm")),
      });
    }, 1000);
    this.checkTrigger();
    //this.props.actions.callGetEventObjectTypes(project.tenantInfo.Key, project.appData.uniquekey)
    //this.props.actions.callGetWebhookSubscriptions(project.tenantInfo.TenantId, this.props.match.params.appId)
  }

  handleTemplateChange(e) {
    this.setState({ selectedTemplate: e })
  }

  onTimePick = (v) => {
    this.setState({ dailySchedule: v });
  };

  onPickTimeUnit = (v) => {
    this.setState({ frequencyUnit: v });
  };

  onPickStartinAt = (v) => {
    this.setState({ startingAt: v });
  };

  changeFrequency = (e) => {
    this.setState({ frequency: e.value });
  };

  findTriggerForEnvIdOrNot(envId) {
    const { transformer } = this.props.redux;
    let data = null;
    if (envId) {
      data =
        transformer.allTriggers &&
        transformer.allTriggers.find((t) => {
          return t.EnvironmentId === envId;
        });
    } else {
      data =
        transformer.allTriggers &&
        transformer.allTriggers.find((t) => {
          return t.EnvironmentId === 0;
        });
    }
    return data;
  }

  clearUIState = () => {
    this.setState({
      dir: "",
      selectedSource: null,
      filepattern: "",
      objectType: null,
      objectTypeChanged: false,
      frequency: "",
      dailySchedule: moment().hour(0).minute(0),
      startingAt: null,
      selectedTemplate: ""
    });
  };

  checkTrigger(envId) {
    const data = this.findTriggerForEnvIdOrNot(envId);
    if (data) {
      if (data.IsRecurring) {
        this.setState({
          isrecurring: true,
          selectedTemplate: data.templateName,
        });
        if (data.CronExpression) {
          if (data.CronExpression.split(" ").length != 6) {
            this.setState({
              cronExpression: data.CronExpression + " *",
            });
          } else {
            this.setState({
              cronExpression: data.CronExpression,
            });
          }
          this.handleSourceChange("iscron");
        } else if (data.dailySchedule) {
          this.setState({
            timeZone: data.dailySchedule.timeZone || "UTC",
            dailySchedule: data.dailySchedule.timeZone
              ? moment
                .utc(data.dailySchedule.timeUTC, "HH:mm")
                .tz(data.dailySchedule.timeZone)
              : moment(data.dailySchedule.timeUTC, "HH:mm"),
          });
          this.handleSourceChange("isdaily");
        } else if (data.repeatingSchedule) {
          this.setState({
            frequency: data.repeatingSchedule.frequency.toString(),
            frequencyUnit:
              data.repeatingSchedule.frequencyUnit.charAt(0).toUpperCase() +
              data.repeatingSchedule.frequencyUnit.slice(1) +
              "s",
            startingAt: data.repeatingSchedule.startingAt
              ? moment
                  .utc(data.repeatingSchedule.startingAt, "HH:mm")
                  .tz(moment.tz.guess())
              : null,
          });
          this.handleSourceChange("isrepeating");
        } else {
          this.clearUIState();
        }
      } else if (data.IsFile) {
        this.setState({
          isfile: true,
          dir: data.Directory,
          filepattern: data.FilePattern,
          eventdate: data.EventDate,
          action: data.Action,
          type: data.Type,
          filename: data.FileName,
          selectedTemplate: data.templateName,
        });
        this.handleSourceChange("isfile");
        /*} else if(data.IsWebhook){
        this.handleSourceChange('iswebhook')*/
      } else {
        this.clearUIState();
      }
    } else {
      this.clearUIState();
    }
    this.setState({ isClearingTrigger: false });
  }

  saveTrigger(filepattern, schedule) {
    const { project, transformer, config } = this.props.redux;
    const { selectedTemplate } = this.state;
    const directory = this.state.dir
      ? this.state.dir
      : `/${config.FTP_DIR}${project.tenantInfo.Key}/`;
    const eventdate = this.state.eventdate
      ? this.state.eventdate
      : new Date().toISOString();
    const action = this.state.action ? this.state.action : "create";
    const filename = this.state.filename ? this.state.filename : "sample.zip";
    const type = this.state.type ? this.state.type : "file";
    this.setState({ isSavingTrigger: true });
    this.props.actions
      .callSaveTrigger(
        project.tenantInfo.TenantId,
        {
          baseid: transformer.versions[0].BaseId,
          isfile: this.state.isfile,
          isrecurring: this.state.isrecurring,
          iswebhook: false,
          ...(filepattern && {
            directory,
            filepattern,
            action,
            filename,
            eventdate,
            type,
          }),
          ...schedule,
          environmentId: this.state.selectedEnvId,
          templateName: selectedTemplate ? selectedTemplate : ""
        },
        this.props.match.params.transId
      )
      .then(() => {
        this.props.onSave();
        this.setState({ isSavingTrigger: false });
      })
      .catch((error) => {
        alert(error.response.data);
        this.setState({ isSavingTrigger: false });
      });
  }

  handleFilePatternChange = (e) => {
    this.setState({ filepattern: e.target.value });
  };

  /*handleMinChange(e) {
    this.setState({min: e.target.value});
  }

  handleHrChange(e) {
    this.setState({ hr: e.target.value });
  }

  handleDayChange(e) {
    this.setState({ day: e.target.value });
  }*/

  handleSourceChange(s) {
    this.setState({
      iswebhook: false,
      isfile: s === "isfile",
      isrecurring: s === "isdaily" || s === "isrepeating" || s === "iscron",
      selectedSource: s,
    });
  }

  clearTrigger = () => {
    const { transformer } = this.props.redux;
    /*if(this.state.selectedEnvId){
      this.deleteUnunsedWebhookSubscriptionsForEnv(this.state.selectedEnvId)
    }*/
    this.setState({ isClearingTrigger: true });
    this.props.actions
      .callClearTrigger(
        transformer.versions[0].BaseId,
        this.state.selectedEnvId
      )
      .then(() => {
        this.checkTrigger();
      });
  };

  changeTimeZone = (e) => {
    this.setState({ timeZone: e.value });
  };

  /*deleteUnunsedWebhookSubscriptionsForEnv(envId) {
    const { redux, actions } = this.props
    const { transformer, project } = redux
    transformer.webhookSubscriptions.forEach((subscription) => {
      const a = subscription.deliveryUrl.split('/')
      const subEnvId = a[a.length - 1]
      if(String(envId)===subEnvId || a.length<9){
        actions.callDeleteWebhookSubscription(
          project.tenantInfo.Key,
          project.appData.uniquekey,
          subscription.subscriptionGuid
        )
      }
    })
  }*/

  handleSave = (e) => {
    let schedule = {};
    let filepattern;
    e.preventDefault();
    /*const trigger = this.findTriggerForEnvIdOrNot(this.state.selectedEnvId)
    const subscribed = transformer.webhookSubscriptions.find((subscription)=>{
      return subscription.subscriptionGuid === (trigger && trigger.WebhookSubscriptionGuid)
    })
    const objectType = subscribed && !this.state.objectTypeChanged ?
      subscribed.objectTypeFilter : this.state.objectType*/
    if (this.state.isfile) {
      filepattern = this.state.filepattern;
      if (!filepattern) {
        alert("Please specify file pattern");
        return;
      }
      //this.deleteUnunsedWebhookSubscriptionsForEnv(this.state.selectedEnvId)
      this.saveTrigger(filepattern, schedule);
    } else if (this.state.isrecurring) {
      if (this.state.selectedSource === "isdaily") {
        let userSpecifiedTZ = moment(this.state.dailySchedule).tz(
          this.state.timeZone
        );
        let dailySdl = this.state.dailySchedule.toString();
        let timeUTC = userSpecifiedTZ.toString();
        var res = dailySdl.replace(
          dailySdl.slice(
            dailySdl.indexOf("GMT") + 3,
            dailySdl.indexOf("GMT") + 8
          ),
          timeUTC.slice(timeUTC.indexOf("GMT") + 3, timeUTC.indexOf("GMT") + 8)
        );
        schedule = {
          dailySchedule: {
            timeUTC: moment.utc(new Date(res)).format("HH:mm"),
            timeZone: this.state.timeZone,
          },
        };
      } else if (this.state.selectedSource === "isrepeating") {
        const { startingAt } = this.state;

        schedule = {
          repeatingSchedule: {
            frequency: parseInt(this.state.frequency, 10),
            frequencyUnit: this.state.frequencyUnit
              .toLowerCase()
              .substring(0, this.state.frequencyUnit.length - 1),
          },
        };
        if (startingAt) {
          schedule.repeatingSchedule.startingAt = moment
            .utc(new Date(startingAt))
            .format("HH:mm");
        }
      } else if (this.state.selectedSource === "iscron") {
        schedule = {
          cronexpression: this.state.cronExpression,
          cronexplain: this.state.cronExplain,
        };
      }
      //this.deleteUnunsedWebhookSubscriptionsForEnv(this.state.selectedEnvId)
      this.saveTrigger(filepattern, schedule);
      /*} else if(this.state.iswebhook) {
      
      
      if(!objectType){
        alert('Select an Object Type');
        return;
      }
      const verbFilter = '*'
      const data = {
        tenantKeyFilter: project.tenantInfo.Key,
        projectKeyFilter: project.appData.uniquekey,
        objectTypeFilter: objectType,
        verbFilter,
        deliveryUrl: config.TRANS_API_URL + `${tenantId}/${appId}/webhooks/environments/${this.state.selectedEnvId}`
      }
      const triggerData = {
        baseid:transformer.versions[0].BaseId,
        isfile:false,
        isrecurring:false,
        iswebhook: true,
        environmentId: this.state.selectedEnvId
      }
      if(subscribed && (subscribed.objectTypeFilter !== objectType ||
        subscribed.verbFilter !== verbFilter)){
        this.props.actions.callDeleteWebhookSubscriptionAndCreateNewWebhookSubscription(
          project.tenantInfo.TenantId, subscribed.subscriptionGuid, project.tenantInfo.Key, project.appData.uniquekey, data, triggerData)
      } else {
        //this action autocreates a trigger after webhook subscription creation
        this.props.actions.callCreateWebhookSubscription(project.tenantInfo.TenantId, project.tenantInfo.Key, project.appData.uniquekey, data, triggerData)
      }*/
    } else {
      alert("Choose a trigger method");
    }
  };

  /*fakeNew = () => {
    const {actions, redux, params} = this.props
    const {project, transformer} = redux
    actions.callCreateWebhookSubscription(project.tenantInfo.TenantId, params.appId, this.state.selectedEnvId, transformer.versions[0].BaseId, {objectTypeFilter:'lingk.api.transformer',verbFilter:'*'})
  }*/

  render() {
    const { transformer, project, config, main } = this.props.redux;
    const { availableTemplates } = this.props;
    const identity = main.me;
    if (!(transformer.versions && transformer.versions[0])) {
      if (
        transformer.savedConfiguration &&
        transformer.savedConfiguration.title
      ) {
        return (
          <div className="main-tab-content">
            <br />
            <span>No deployment options available at this time</span>
          </div>
        );
      }
      return (
        <div className="ajax-loader">
          <img alt="spinner" src={images.ajax_loader} />
        </div>
      );
    }
    let dir = this.state.dir;
    if (!dir) {
      dir =
        "/" +
        config.FTP_DIR +
        project.tenantInfo.Key +
        "/" +
        project.appData.uniquekey;
    }
    /*const trigger = this.findTriggerForEnvIdOrNot(this.state.selectedEnvId)
    const subscribed = transformer.webhookSubscriptions.find((subscription)=> {
      return subscription.subscriptionGuid === (trigger && trigger.WebhookSubscriptionGuid)
    })
    const objectType = subscribed &&
      !this.state.objectTypeChanged ? subscribed.objectTypeFilter : this.state.objectType*/
    const timeUnitOptions = ["Minutes", "Hours"];

    var cronTimeZoneOptions = [];
    cronTimeZoneOptions.push({
      value: "UTC",
      label: "(GMT+00:00) UTC-Coordinated Universal Time",
    });

    var dailyTimeZoneOptions = timeZones();

    let disable = true;
    if (this.state.selectedSource === "isdaily") {
      if (this.state.dailySchedule) {
        disable = false;
      }
    } else if (this.state.selectedSource === "isrepeating") {
      if (this.state.frequency) {
        const f = this.state.frequency;
        const u = this.state.frequencyUnit;
        if (
          u &&
          f &&
          ((u === "Minutes" && f >= 5 && f <= 60) ||
            (u === "Hours" && f > 0 && f <= 24))
        ) {
          disable = false;
        }
      }
    } else if (this.state.selectedSource === "isfile") {
      disable = false;
    } else if (this.state.selectedSource === "iscron") {
      disable = false;
    }

    const deployed = this.state.selectedEnvId ? true : false;

    const noDeployments =
      !(project.environments && project.environments.length > 0) ||
      !(
        transformer &&
        transformer.deployments &&
        transformer.deployments.length > 0
      );
    const uiEnvironment = this.state.selectedEnv;
    const filteredEnvironments =
      project.environments &&
      project.environments.filter((e) => {
        const dep =
          transformer.deployments &&
          transformer.deployments.find((td) => {
            return e.environmentId === td.environmentId;
          });
        return dep ? true : false;
      });
    const { url } = this.props.match;
    return (
      <div
        className="main-tab-content"
        style={
          url.includes("wiz")
            ? { paddingBottom: 60 }
            : { paddingBottom: 60, overflowY: "scroll" }
        }
      >
        <br />

        {noDeployments ? (
          <strong className="labelz">
            <span>Please Deploy a Recipe or Wizard to use Scheduling</span>
          </strong>
        ) : (
          <EnvironmentDropdown
            uiEnvironment={uiEnvironment}
            versions={transformer.versions}
            deployments={transformer.deployments}
            environments={filteredEnvironments}
            disabled={noDeployments}
            wizardVersions={transformer.wizardVersions}
            showThisEnvironmentName={this.state.selectedEnvAndVersionName}
            showThisTitle="Deployed Versions"
            onSelect={(env, trans) => {
              this.setState({
                selectedEnv: env, //`${env.name} - Version  ${trans.Version}`,
                selectedEnvAndVersionName: `${env.name} - Version  ${trans.Version}`,
                selectedEnvId: env.environmentId,
              });
              this.checkTrigger(env.environmentId);
            }}
          />
        )}

        {availableTemplates && availableTemplates.length > 0 &&
          <div
            style={{
              display: "inline-block",
              marginRight: 10,
              position: "relative",
            }}
            className="dropdown-button-wrap"
          >
            {<DropdownButton
              title={this.state.selectedTemplate != "" ? this.state.selectedTemplate.toUpperCase() : "Select Template"}
              style={{ fontSize: 12, fontWeight: "bold" }}
              id={"template-dropdown"}
              onSelect={(e) => { this.handleTemplateChange(e) }}
            >
              {availableTemplates.length > 0 ? (availableTemplates.map((t) => {
                return (<MenuItem key={t} eventKey={t} active={this.state.selectedTemplate === t}>{t.toUpperCase()}</MenuItem>)
              })) : (<MenuItem key={"loading"} disabled={true} eventKey={"loading"}><Loading /></MenuItem>)}
            </DropdownButton>}
          </div>
        }
        <form
          className="form-horizontal-yaml"
          style={
            url.includes("wiz")
              ? {}
              : {
                height: this.state.selectedSource === "iscron" ? "auto" : "",
                display: this.state.selectedSource === "iscron" && "flex",
                flexDirection: this.state.selectedSource === "iscron" && "row"
              }
          }
        >

          <div style={{ marginBottom: 20 }}>
            <br />
            <br />
            <label htmlFor="Trigger Events">Schedule Your Integration</label>
            <br />
            <div style={{ height: 8, width: 8 }} />
            {/*<div>
              <input className="form-control-checkbox"
                type="radio" name="isfile" checked={this.state.selectedSource==='isfile'}
                onChange={()=>this.handleSourceChange('isfile')} disabled={!deployed} />
              File Upload Event
            </div>
            {this.state.selectedSource==='isfile' && <div>
              <FormControl type="input" className="form-control-trigger"
                placeholder="File Pattern (*filename*)"
                value={this.state.filepattern}
                onChange={this.handleFilePatternChange} disabled={!deployed} />
              <div className="form-control-label4">Directory:&nbsp;{dir}</div>
            </div>
            <br />*/}
            <div>
              <input
                className="form-control-checkbox"
                type="radio"
                name="isdaily"
                checked={this.state.selectedSource === "isdaily"}
                onChange={() => this.handleSourceChange("isdaily")}
                disabled={!deployed}
              />
              Daily Schedule
            </div>
            {this.state.selectedSource === "isdaily" && (
              <div style={{ marginLeft: 18 }}>
                <TimePicker
                  showSecond={false}
                  defaultValue={moment().hour(0).minute(0)}
                  className="time-picker"
                  onChange={this.onTimePick}
                  format={"h:mm a"}
                  use12Hours
                  value={this.state.dailySchedule}
                  disabled={!deployed}
                />
              </div>
            )}
            <br />

            <div>
              <input
                className="form-control-checkbox"
                type="radio"
                name="isrepeating"
                checked={this.state.selectedSource === "isrepeating"}
                onChange={() => this.handleSourceChange("isrepeating")}
                disabled={!deployed}
              />
              Repeating Schedule
            </div>

            {this.state.selectedSource === "isrepeating" && (
              <div style={{ marginLeft: 18, display: "flex" }}>
                <div style={{ width: 148 }}>
                  <span className="labelz">Every</span>
                  <br />
                  <If
                    condition={
                      this.state.frequencyUnit == "Hours" ||
                      this.state.frequencyUnit == "hours"
                    }
                  >
                    <Then>
                      <Select
                        options={recurringHoursOptions}
                        onChange={this.changeFrequency}
                        value={this.state.frequency}
                      />
                    </Then>
                    <Else>
                      <Select
                        options={recurringMinutesOptions}
                        onChange={this.changeFrequency}
                        value={this.state.frequency}
                      />
                    </Else>
                  </If>
                </div>
                <div style={{ marginLeft: 16 }}>
                  <span className="labelz">Time Unit</span>
                  <br />
                  <DropdownButton
                    value={this.state.frequencyUnit}
                    bsStyle="default"
                    id="frequency-unit"
                    title={this.state.frequencyUnit || "Time Unit"}
                  >
                    {timeUnitOptions.map((unit, i) => (
                      <MenuItem
                        value={unit}
                        key={i}
                        onClick={() => this.onPickTimeUnit(unit)}
                        active={this.state.frequencyUnit === unit}
                      >
                        {unit}
                      </MenuItem>
                    ))}
                  </DropdownButton>
                </div>
                <div style={{ marginLeft: 10, display: "grid" }}>
                  <span className="labelz">Start first time at</span>
                  <TimePicker
                    showSecond={false}
                    defaultValue={moment().hour(0).minute(0)}
                    className="time-picker"
                    onChange={this.onPickStartinAt}
                    format={"h:mm a"}
                    use12Hours
                    value={this.state.startingAt}
                    disabled={!deployed}
                  />
                </div>
                <div style={{ display: "grid" }}>
                  <span className="labelz">Current Time</span>
                  <span style={{ marginLeft: 10, marginTop: -10 }}>
                    {this.state.curTime}
                  </span>
                </div>
              </div>
            )}
            <br />

            <div>
              <input
                className="form-control-checkbox"
                type="radio"
                name="iscron"
                checked={this.state.selectedSource === "iscron"}
                onChange={() => this.handleSourceChange("iscron")}
                disabled={!deployed}
              />
              Advanced
            </div>
            <br />

            {/*<div style={{marginBottom:8}}><input className="form-control-checkbox"  ref="isrecur"
                  type="radio" name="isfile" checked={this.state.isrecurring}
                  onChange={()=>this.handleSourceChange('isrecurring')}  disabled={!deployed}/>Recurring Time-Based Event</div>
            <div className="form-control-label1">Minute&nbsp;&nbsp;
              <input type="text"  ref="minute" placeholder="0-59 or *" value={this.state.min}
                   onChange={this.handleMinChange}  disabled={!deployed}/>
            </div><br />
            <div className="form-control-label2">Hour&nbsp;&nbsp;
              <input type="text" ref="hour" placeholder="0-23 or *" value={this.state.hr}
                   onChange={this.handleHrChange}  disabled={!deployed}/>
            </div><br />
            <div className="form-control-label3"  disabled="true">Day&nbsp;&nbsp;
              <input  type="text"  ref="day" placeholder="1-31 or *" value={this.state.day}
                  onChange={this.handleDayChange}  disabled={!deployed}/>
            </div><br />*/}

            {/*transformer.triggerWebhooks && transformer.triggerWebhooks.length > 0 && <div>
              <div style={{marginBottom:8}}>
                <input className="form-control-checkbox"
                    type="radio" name="isfile" checked={this.state.selectedSource==='iswebhook'}
                    onChange={()=>this.handleSourceChange('iswebhook')} disabled={!deployed} />
                Custom Webhook
              </div>
              {this.state.selectedSource==='iswebhook' &&
              <div className="webhook-dropdown-menu-wrapper">
                <DropdownButton id="webhook-subscriptions" placeholder="Events" bsStyle="default"
                  title={objectType||'Select Object Type'}>
                  {transformer.triggerWebhooks.map((item, i)=>
                    <MenuItem value={item.name} key={i}
                      onClick={()=>this.setState({objectType:item.name, objectTypeChanged:true})}
                      active={objectType===item.name}>
                      {item.name}
                    </MenuItem>
                  )}
                </DropdownButton>
              </div>}
              <br />
            </div>*/}

            {this.state.selectedSource == "isdaily" && (
              <div>
                <span className="labelz">Time Zone</span>
                <br />
                <div style={{ width: 250, fontSize: 12 }}>
                  <Select
                    options={dailyTimeZoneOptions}
                    onChange={this.changeTimeZone}
                    value={this.state.timeZone}
                  />
                </div>
              </div>
            )}
            <br />

            {/*{this.state.selectedSource == "iscron" && (*/}
            {/*  <div>*/}
            {/*    <span className="labelz">Time Zone</span>*/}
            {/*    <br />*/}
            {/*    <div*/}
            {/*      style={{*/}
            {/*        width: 300,*/}
            {/*        fontSize: 13,*/}
            {/*        background: "white",*/}
            {/*        textAlign: "center",*/}
            {/*        height: 35,*/}
            {/*        lineHeight: 2.5,*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      {cronTimeZoneOptions[0].label}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
            <br />
            <span>
              <Button
                bsSize="small"
                bsStyle="primary"
                onClick={this.clearTrigger}
                disabled={!deployed}
                style={{ width: 92, marginBottom: 20 }}
              >
                {!this.state.isClearingTrigger ? (
                  <span>Clear Trigger</span>
                ) : (
                  <img
                    alt="small-spinner"
                    src={images.ajax_loader_small}
                    height="8"
                  />
                )}
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                bsSize="small"
                bsStyle="primary"
                onClick={this.handleSave}
                disabled={!deployed || disable || this.state.isSavingTrigger}
                style={{ width: 47, marginBottom: 20 }}
              >
                {!this.state.isSavingTrigger ? (
                  <span>Save</span>
                ) : (
                  <img
                    alt="small-spinner"
                    src={images.ajax_loader_small}
                    height="8"
                  />
                )}
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {transformer.showSaved ? <span>Trigger Saved</span> : null}
            </span>
          </div>
          <div style={{ padding: "0.5rem", marginLeft: "10%" }}>
            {this.state.selectedSource === "iscron" && (
              <div style={{ marginLeft: 18 }}>
                <CronBuilder
                  cronExpression={this.state.cronExpression}
                  onChange={(value) => {
                    this.setState({ cronExpression: value });
                    this.handleSourceChange("iscron");
                  }}
                  showResult={true}
                  getData={(cron) => {
                    {
                      this.setState({ cronExplain: cron.cronString });
                    }
                  }}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}
