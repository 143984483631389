import React, { PureComponent } from "react";
import Tab from "./Tab";

export default class MultipleSwitcher extends PureComponent {
  static defaultProps = {
    single: "Fixed-time",
    multiple: "Recurring",
    singleAndMultiple: "Recurring Fixed-time",
  };

  render() {
    const {
      styleNameFactory,
      single,
      multiple,
      singleAndMultiple,
      isMultiple,
      onChange,
      activeTab,
    } = this.props;
    return (
      <div>
        <div {...styleNameFactory("row", "inline")} data-multiple-switcher>
          <Tab
            styleNameFactory={styleNameFactory}
            isActive={activeTab == "multiple"}
            onClick={() => onChange("multiple")}
            style={{ width: 100 }}
          >
            {multiple}
          </Tab>
          <Tab
            styleNameFactory={styleNameFactory}
            isActive={activeTab == "single"}
            onClick={() => onChange("single")}
            style={{ width: 100 }}
          >
            {single}
          </Tab>
          <Tab
            styleNameFactory={styleNameFactory}
            isActive={activeTab == "singleAndMultiple"}
            onClick={() => onChange("singleAndMultiple")}
            style={{ width: 150 }}
          >
            {singleAndMultiple}
          </Tab>
        </div>
      </div>
    );
  }
}
