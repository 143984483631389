import React from 'react'
import moment from 'moment'

const styles = {
  icon:{
    cursor:'pointer',
    fill:'#484848',
  },
  header:{
    borderBottom:'1px solid grey',
    background:'#999999',
    color:'white',
    width:'100%',
    padding: '5px 0 2px 10px',
  },
  text:{
    paddingLeft:10
  }
}

const HistoryBox = ({lastModified, lastModifiedBy, redux, type, wrapStyle, boxStyle, iconStyle}) => {
  const user = redux && redux.project && redux.project.permissions && redux.project.permissions.find(u=>u.identityId===lastModifiedBy)
  if(!(user && user.Identity && user.Identity.username || lastModifiedBy=='2147483647')){
    return <div />
  }
  var username = 'api-request@lingk.io'  //Public Recipe API have no user context
  if (user && user.Identity && user.Identity.username){
     username = user.Identity.username
  }
  return (<div className="history-box-wrap" style={wrapStyle||{}}>
    <svg style={{...styles.icon, ...iconStyle}} 
      width="24" height="24" viewBox="0 0 24 24">
      <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
    </svg>
    <div className="history-box-box" style={boxStyle||{}}>
      <p style={styles.header}>{`${type} last modified by:`}</p>
      <p style={styles.text}>{username}</p>
      <p style={styles.text}>{moment(lastModified).format('MMM Do Y, h:mm a')}</p>
    </div>
  </div>)
}

export default HistoryBox


