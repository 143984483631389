import React, { Component } from 'react'
import images from '../images'
import RecurlyForm from './recurlyForm'
import PurchaseServicesModal from './purchaseServicesModal'
import PurchasePreviewModal from '../user/purchasePreviewModal'
import {Button,MenuItem} from 'react-bootstrap'

function getAllIndexes(arr, val) {
    var indexes = [], i = -1;
    while ((i = arr.indexOf(val, i+1)) != -1){
        indexes.push(i);
    }
    return indexes;
}

const titleStyles = {
  fontSize: '22px',
  float: 'left',
  marginBottom: '5px',
}

class PurchaseServices extends Component {

  constructor(){
    super()
    this.state={
      //billing: null,

      selectedPlan: null,
      selectedAddons: [],
      additions: [],
      recurlyAddons:null,

      recurlyPlans:null,
      
      creatingOrg: false,

      couponCode: '',
      couponState:null,


      posting: false,
      postingModal: false,
      previewModal: false,
      preview: null,
      modal: false,
      // subKey: '',
      disableCoupon: false,

    }

    this.submitForm = this.submitForm.bind(this)
    // this.submitFormPreview = this.submitFormPreview.bind(this)
  }


componentWillMount(){
  // const {accountCode, uuid} = this.props.match.params

  //   this.props.actions.getRecurlyBilling(accountCode)
  //   .then((b)=>{
  //       this.setState({userBilling:b})
  //           })

    const { recurlyPlans, subscription } = this.props

    this.props.actions.getRecurlyBilling(subscription.accountCode)
      .then((o)=>{
        //this.setState({billing:o})
        this.props.liftBilling(o)
      })

          const data = []
            recurlyPlans.map((p)=>{
          
              if (p.Code.includes('service')){
                  data.push(p)
                  }
              })

          // const planCode = this.props.location.query.plan && this.props.location.query.plan
          // if (planCode){
          //   const p = data.find(f=>f.Code===planCode)
          //   this.props.actions.getRecurlyAddons(planCode)
          //     .then((d)=>{
          //       this.setState({recurlyAddons:d, 
          //         selectedPlan: p, 
          //         recurlyPlans:data, 
          //         selectedAddons:[]})
          //     })
          // }
         // else{
          
          const plan = data[0]
          this.props.actions.getRecurlyAddons(plan.Code)
        .then((d)=>{
          console.log(d)
              this.setState({recurlyAddons:d, 
                selectedPlan: plan, 
                selectedAddons:[]})
            })
         
}



setFormRef = (ref) => {
      this.form = ref;
  }

getCoupon = () => {
  let { disableCoupon } = this.state
  if (disableCoupon){
    this.setState({
      couponCode:'',
      couponState:null,
      disableCoupon: false
    })
    return
  }
  
  this.props.actions.getRecurlyCoupon(this.state.couponCode)
  .then((a)=>{
    // console.log(a)
    if (a==='Invalid'){
      this.setState({couponState: a})
    } else{
      this.setState({couponState: a,
      disableCoupon: true})
    }

    })
}

updateCoupon = (e) => {
  if (this.state.disableCoupon){
    return
  }

  this.setState({couponCode:e})
}


handleAddons = (addon) => {
  let { selectedAddons,additions } = this.state

  if (!selectedAddons.includes(addon)){
    selectedAddons.push(addon)
    additions.push(addon.Name)
  }
  else{
      while (getAllIndexes(additions, addon.Name).length > 0){
        const indexes = getAllIndexes(additions, addon.Name)
        additions.splice(indexes[0],1) 
      }

    const i = selectedAddons.findIndex(f=>f.Code===addon.Code)
    selectedAddons.splice(i,1)

  }

  this.setState({selectedAddons,additions})

  }

  postNewSubscription = (post) => {
  

  const { selectedPlan, selectedAddons, 
    additions, couponCode } = this.state

  const {subscription, billing} = this.props

  const addons = selectedAddons.map((o)=>{
    const quantity = getAllIndexes(additions, o.Name)
    return {
      addonCode: o.Code,
      addonQuantity: quantity.length
    }
  })

  const data = {
  accountCode: subscription.accountCode,
  addOns: addons,
  planCode: selectedPlan.Code,
  couponCode: couponCode
  }


  if (addons.length > 0 && billing && billing.cardType){
    if(post){
             this.setState({postingModal: true})
             this.props.actions.recurlyServicesPurchase(data)
             .then((p)=>{
              this.triggerPreviewModal()
                if (Array.isArray(p) && p[0].Message){
                  if(p[0].Field.includes('coupon')){
                    alert(couponCode + ' is not a valid coupon.')
                  }
                  console.log('fail')
                  this.setState({previewModal:false})
                  this.setState({postingModal:false})
                  return
                }

                if(Array.isArray(p) && p[0].Error){
                  alert(p[0].Error)
                  this.setState({previewModal:false})
                  this.setState({postingModal:false})
                  return
                }
                //if success
                this.setState({postingModal:false,previewModal:false,modal: true, posting:false})
              })
            }
      else{   
             //  this.setState({posting: true})
             //  this.props.actions.previewRecurlyPurchase(data)
             // .then((p)=>{
              // console.log(p)
              this.setState({preview: 'p', posting: false, previewModal:true})
              // })
            }

             
    }
  else{
    if(addons.length < 1){
    alert('Please select a service.')
    }
    else{
    alert('Please add a payment method.')
    }
    this.setState({posting:false})
  }
  }

  
// probably not using this
submitForm() {

  window.recurly.token(this.form, (err, token) => {
    this.setState({posting: true})
    
    if (err) {
      this.createNewSubscription('')
      return
    }
      this.createNewSubscription(token.id)
  })
}

// probably not using this 
createNewSubscription = (token) => {

  const { selectedPlan, selectedAddons, 
    additions, couponCode } = this.state

  const { subscription } = this.props

 const addons = selectedAddons.map((o)=>{
    const quantity = getAllIndexes(additions, o.Name)
    return {
      addonCode: o.Code,
      addonQuantity: quantity.length
    }
  })

  const data = {
  accountCode: subscription.accountCode,
  addOns: addons,
  planCode: selectedPlan.Code,
  token: token,
  couponCode: couponCode,
  }


  if (addons.length > 0){
             this.props.actions.createRecurlyPurchaseLoggedIn(data)
             .then((p)=>{
                if (Array.isArray(p) && p[0].Message){
                  if(p[0].Field.includes('coupon')){
                    alert(couponCode + ' is not a valid coupon.')
                  }
                  this.setState({posting:false})
                  return
                }
                
                console.log('success')
              })
    }
  else{
    alert('Please select a service.')
    this.setState({posting:false})
  }
}


triggerModal = () => {
  let modal = this.state.modal
  modal = !modal
  this.setState({ modal })
}

triggerPreviewModal = () => {
  let { previewModal } = this.state
  previewModal = !previewModal
  this.setState({ previewModal })
}

handleSelectPlan = (e) => {

  const selectedPlan = this.props.recurlyPlans.find(f=>f.Code === e.Code)
  this.setState({selectedPlan, recurlyAddons:null})
    this.props.actions.getRecurlyAddons(selectedPlan.Code)
    .then((data)=>{
      // console.log(data)
      this.setState({recurlyAddons:data, selectedAddons:[], additions:[]})
  })
 
}

updateForm = (type, e) => {
  this.setState({[type]:e})
}


subtractAdditions = (e) => {
  let { additions } = this.state

    const target = additions.findIndex(f=>f === e)
    const quantity = getAllIndexes(additions, e)

    if (target !== -1 && quantity.length > 1) {
      additions.splice(target, 1)
      this.setState({additions})
    }

}

addAdditions = (e) => {
  let { additions } = this.state

    additions.push(e)

    this.setState({additions})
}

  render() {

    const { 
            selectedPlan,
            recurlyAddons, 
            selectedAddons, 
            orgInput,
            firstName,
            lastName,
            phone,
            emailOne,
            emailTwo,
            posting,
            disableCoupon,
            couponState,
            subKey,
            modal,
            preview,
            postingModal,
            previewModal,
            additions,
            couponCode  } = this.state

            const { subMode, recurlyPlans, billing } = this.props

            const plans = []
            //const price = selectedPlan && (selectedPlan.UnitAmountInCents.USD/100).toLocaleString("en-US", {style:"currency", currency:"USD"})
            let totalPrice = selectedPlan && selectedPlan.UnitAmountInCents.USD

    if (recurlyPlans){

      recurlyPlans.map((o,i)=>{

        // const cost = (o.UnitAmountInCents.USD/100).toLocaleString("en-US", {style:"currency", currency:"USD"})

        plans.push(
          <MenuItem value={o.Name} key={i}
          onClick={()=>this.handleSelectPlan(o)}>
          <strong>{o.Name}</strong>
          {/*<strong>{o.Name} {cost}</strong>*/}
        </MenuItem>
        )
      })
    }

    if (selectedAddons && selectedAddons.length > 0){

      selectedAddons.sort((a,b)=>{
        if (a.UnitAmountInCents.USD < b.UnitAmountInCents.USD) return -1
        else if (a.UnitAmountInCents.USD > b.UnitAmountInCents.USD) return 1
        return 0
      })

      selectedAddons.map((o)=>{
        let price = o.UnitAmountInCents.USD
        const quantity = getAllIndexes(additions, o.Name)
        if (quantity !== -1){
          price = price * quantity.length
         totalPrice+=price
        }
      })
    }

    const discount = couponState && couponState.discount_type && 
                      couponState.discount_type === 'percent' ? ((100 - couponState.discount_percent) / 100) : 1
    const fixeddiscount = couponState && couponState.discount_type && couponState.discount_type === 'dollars' ? couponState.discount_in_cents.USD : 0

    let finalPrice = totalPrice*discount-fixeddiscount

    if (finalPrice < 0){
      finalPrice = 0
    }


    return (
     <div style={{position:'relative'}}>
     <div style={titleStyles}>Lingk Services</div>
        <br/><br/>
        <div>
          <div>
          {recurlyPlans ?
          <div>
          {/*}
          <div style={{position:'relative', textAlign:'center'}}>
          <img alt="logo" src={images.logo_green} style={{marginTop:6}} height="36" /> 
            <h3>Purchase Services</h3>
          </div>
          <br/>
        */}

          
          <div>
          <br/>
          <div className="">
          Some customers may want help with planning their project and utilizing Lingk for their use cases. 
          Customers who purchase Quick Start Implementation Services receive up to 5 hours consulting via web conference time, a project blueprint and example integration and data processing recipes.
          </div>

          <div className="payment-header">
          </div>
          {/*}
          <div style={{}}>
          <DropdownButton bsStyle={selectedPlan?'default':'primary'} id="plans" placeholder="Plans"
          title={selectedPlan && selectedPlan.Name || 'Select Plan'}>
            {plans}
          </DropdownButton>
          </div>
        */}


          <div style={{textAlign:'left', marginTop:-15}}>
           <div>
             <RecurlyForm 
                 isSubForm={false}
                 subtractAdditions={this.subtractAdditions}
                 addAdditions={this.addAdditions}
                 spinnerOpts={spinnerOpts}
                 additions={additions}
                 couponCode={couponCode}
                 discount={discount}
                 updateCoupon={this.updateCoupon}
                 getCoupon={this.getCoupon}
                 couponState={couponState}
                 disableCoupon={disableCoupon}
      
                 updateForm={this.updateForm}
                 firstName={firstName}
                  lastName={lastName}
                  emailOne={emailOne}
                  emailTwo={emailTwo}
                  phone={phone}
                 orgInput={orgInput}

                 submitForm={this.submitForm} 
                 setFormRef={this.setFormRef}
                 selectedPlan={selectedPlan}
                 selectedAddons={selectedAddons}
                 handleAddons={this.handleAddons}
                 recurlyAddons={recurlyAddons}
                 />
           </div>


      

      <div className="">
       <b>Card on File:&nbsp;&nbsp;</b>
       <div style={{display: 'inline-block',margin:20, marginLeft:0}}>
       { billing ?
        <div>
            { billing.cardType ?
              <div style={{verticalAlign:'bottom'}}>
              <div style={{display:'inline-block'}} className="">{billing.firstName} {billing.lastName} / {billing.cardType} ending {billing.lastFour}</div> 
                {/*}
                <svg className="delete-billing" style={{display:posting?'none':'inline-block', marginLeft:6, marginTop:-2}} height="18" viewBox="0 0 60 60" 
                onClick={()=>this.clearBilling()}>
                <path style={{strokeWidth:5, strokeLinecap: 'round'}}
                  d="M 15.674663,15.572746 L 44.587629,44.485711 M 45.118838,15.420972 L 15.522889,45.016920" />
                </svg> 
                */} 
              </div>
              :
              <div className=""><a style={{cursor: 'pointer'}} onClick={()=>subMode('billing')}><span>Add a Payment Method</span></a></div> 
            }
        </div>
        :
        <div>
          {/*loading...*/}
          <img alt="spinner" src={images.ajax_loader} />
          </div>
       }
       </div>
       </div>        
          </div>  
          <br/>

          </div>
          
          <div style={{width: 560, textAlign:'left'}}>
          
          <Button bsStyle="primary" style={{width: 120}} disabled={posting} bsSize="small" onClick={()=>{this.postNewSubscription(false)}}>
          {posting ? <img alt="spinner" src={images.ajax_loader} /> : <b>Payment Preview</b> }
          </Button>
          </div>
          </div>
          :
          
          <div>
          {/*loading...*/}
          <img alt="spinner" src={images.ajax_loader} />
          </div>
        }
        <br />
        </div>
        </div>
        <PurchaseServicesModal
        selectedPlan={selectedPlan}
        modal={modal}
        triggerModal={this.triggerModal}
        subKey={subKey}
        /> 

        <PurchasePreviewModal
        selectedPlan={selectedPlan}
        posting={postingModal}
        finalPrice={finalPrice}
        modal={previewModal}
        purchase={this.postNewSubscription}
        preview={preview}
        triggerModal={this.triggerPreviewModal}
        />  
            
      </div>
    )
  }
}

const spinnerOpts = {
  lines: 11, // The number of lines to draw
  length: 30, // The length of each line
  width: 12, // The line thickness
  radius: 25, // The radius of the inner circle
  scale: 0.2, // Scales overall size of the spinner
  corners: 1, // Corner roundness (0..1)
  color: '#444', // CSS color or array of colors
  fadeColor: 'transparent', // CSS color or array of colors
  speed: 4, // Rounds per second
  rotate: 0, // The rotation offset
  animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  zIndex: 0, // The z-index (defaults to 2000000000)
  className: 'spinner', // The CSS class to assign to the spinner
  top: '50%', // Top position relative to parent
  left: '50%', // Left position relative to parent
  //shadow: '0 0 1px transparent', // Box-shadow for the lines
  position: 'absolute' // Element positioning
};



export default PurchaseServices
