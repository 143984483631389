import React from 'react'
import images from '../images'
import { Button, Modal } from 'react-bootstrap'

const { Body } = Modal

const PurchasePreviewModal = (props) => {

    const { modal, triggerModal, posting, purchase, preview, message, finalPrice } = props

    return (

          <Modal show={modal} onHide={triggerModal} backdrop='static' keyboard={false}>
            <Body style={{height: 'fit-content'}}>
              
            <div style={{marginTop: 0, textAlign:'center',padding:40, paddingBottom: 0}}>
              <h2>{(finalPrice/100).toLocaleString("en-US", {style:"currency", currency:"USD"})} USD</h2>
              <div className=""></div>
              {message ?
              <span>{message}</span>
              :
              <span>This amount will be charged to your stored payment method.</span>
              }

              <div style={{padding:10, margin:30, marginTop:10}}>
                <Button onClick={()=>{triggerModal()}} bsStyle="default" bsSize="small">
                <b>Cancel</b>
                </Button>
                <div style={{display:'inline-block', marginLeft:20}}>
                <Button style={{width: 100}} bsStyle="primary" disabled={posting} bsSize="small" onClick={()=>{purchase(true)}}>
                {posting ? <img alt="spinner" src={images.ajax_loader} /> : <b>Submit Order</b> }
                </Button>
                </div>
              </div>

              

              
            </div>
            
            </Body>
          </Modal>
          )
}

export default PurchasePreviewModal
