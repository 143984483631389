import axios from 'axios';

const methods = ['get', 'post', 'put', 'patch', 'delete'];
let __CLIENT__ = true
function formatUrl(path, endpoint) {
  return endpoint + path;
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  return Promise.reject(response.data);
}

function parseJSON(response) {
  return response.data;
}

function fetchCreator(method) {
  return (url, { data, endpoint, ...options } = {}) => {
    const fetchOptions = options;
    fetchOptions.headers = fetchOptions.headers || {};
    fetchOptions.headers.Accept = 'application/json';
    if (__CLIENT__) {
      const token = window.localStorage.getItem('userToken');
      fetchOptions.headers.Authorization = `Bearer ${token}`;
    }
    if (data) {
      fetchOptions.data = JSON.stringify(data);
    }
    fetchOptions.method = method;
    return axios(formatUrl(url, endpoint), fetchOptions)
      .then(checkStatus)
      .then(parseJSON);
  };
}

export default class ApiClient {
  constructor({ csrf = null } = {}) {
    methods.forEach((method) => {
      this[method] = fetchCreator(method, { csrf });
    });
  }
  /*
   * There's a V8 bug where, when using Babel, exporting classes with only
   * constructors sometimes fails. Until it's patched, this is a solution to
   * "apiClient is not defined" from issue #14.
   * https://github.com/erikras/react-redux-universal-hot-example/issues/14
   *
   * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
   *
   * Remove it at your own risk.
   */
  empty() {}
}
