import React, { useEffect } from "react";
import SummaryScheduleListView from "./SummaryScheduleListView";
import DetailScheduleListView from "./DetailScheduleListView";

function ScheduleListView({ props, environment }) {
  return (
    <div>
      {/* summary view */}
      <SummaryScheduleListView props={props} environment={environment} />

      {/* detail view */}
      <DetailScheduleListView props={props} environment={environment} />
    </div>
  );
}

export default ScheduleListView;
