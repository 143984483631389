import React, { useEffect } from 'react';
import { Tab, Row, Col, Nav, NavItem, Grid, Badge } from 'react-bootstrap';
import SwaggerUI from 'swagger-ui-react';
import { connect } from "react-redux";
import images from "../images";

import "swagger-ui-react/swagger-ui.css"

const SwaggerComponent = (props) => {
  const { redux } = props;
  const { config } = redux;
  const identity = redux && redux.main && redux.main.me && redux.main.me;
  const userToken = `Bearer ${localStorage.getItem("userToken")}`

  const apiServices = [
    {
      "name": "monitoring-service",
      "label": "Monitoring Service",
      "url": config.MONITOR_API_URL + "swagger/doc.json",
      "disable": false
    },
    {
      "name": "recipe-monitoring-service",
      "label": "Recipe Monitoring Service",
      "url": config.RECIPE_MONITORING_API_URL + "swagger/doc.json",
      "disable": true // enable when recipe monitoring service is ready
    },
    {
      "name": "transformer-api",
      "label": "Transformer API",
      "url": config.TRANS_API_URL + "swagger/doc.json",
      "disable": true // enable when transformer api is ready
    },
    {
      "name": "dataprotect-api",
      "label": "Dataprotect API",
      "url": config.DATA_API_URL + "swagger/doc.json",
      "disable": true // enable when dataprotect api is ready
    },
    {
      "name": "salesforce-agent-api",
      "label": "Salesforce Agent API",
      "url": config.SF_AGENT_API_URL + "swagger/doc.json",
      "disable": false
    },
  ]

  // intercept requests to add auth header
  const requestInterceptor = (request) => {
    request.headers['Authorization'] = `Bearer ${localStorage.getItem("userToken")}`;
    request.headers['Accept'] = 'application/json';
    return request;
  }

  return (
    <div className="swagger-ui">
      {identity && identity.role == "tech-support" ? (
        <>
          {/* swagger heading */}
          <div className="container-fluid">
            <div className="swagger-ui-heading">
              <div style={{ fontSize: "24px", display: "flex", alignItems: "center" }}>
                <img role="presentation" width="75" height="75" src={images.Swagger} style={{ marginRight: "1rem" }} />
                <h1 style={{ paddingLeft: "16px", fontWeight: "bold" }}>Lingk Swagger UI</h1>
                <p style={{ verticalAlign: "top", marginLeft: "8px", marginTop: "-12px" }}><Badge>{config.DATAPROTECT_ENV}</Badge></p>
              </div>
            </div>
          </div>

          {/*API Services Menu */}
          <div style={{ width: "calc(100% - 16px)" }}>
            <Tab.Container id="left-tabs-example" defaultActiveKey={apiServices[0].name} style={{ backgroundColor: "white", padding: "2rem" }}>
              <Row className="clearfix">

                <Col sm={2}>
                  <h4 style={{ paddingRight: "1rem", paddingLeft: "1rem" }}><b>API Services</b></h4>
                  <Nav bsStyle="pills" stacked>
                    {apiServices.map((service, index) => {
                      return (
                        <NavItem key={service.name} eventKey={service.name} disabled={service.disable}>{service.label}</NavItem>
                      )
                    })}
                  </Nav>
                </Col>

                {/*Swagger content */}
                <Col sm={10} style={{ borderStyle: "groove", borderRadius: "8px" }}>
                  <Tab.Content animation>
                    {apiServices.map((service, index) => {
                      if (!service.disable) {
                        return (
                          <Tab.Pane eventKey={service.name} key={service.name}>
                            <SwaggerUI
                              url={service.url}
                              requestInterceptor={requestInterceptor}
                              onComplete={(swaggerUi) => {
                                swaggerUi.preauthorizeApiKey('ApiKeyAuth', userToken);
                              }}
                            />
                          </Tab.Pane>
                        )
                      }
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>;
          </div>
        </>
      ) : (
        <>
          {/*Unauthorized View */}
          <div className="swagger-ui" style={{ display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center", fontSize: "16px" }}>
            <div>
              <img role="presentation" width="100" height="100" src={images.warningRed} />
            </div>
            <h1><strong>Access Not Allowed!</strong></h1>
            <p>Oops, you don't have the required access to view Swagger UI for developer APIs.</p>
          </div>
        </>
      )}
    </div>
  )
}


const mapStateToProps = (state) => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
  },
});

export default connect(mapStateToProps)(SwaggerComponent)