import React, { Component } from 'react'
import Helmet from 'react-helmet';
import images from '../images'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";
const queryString = require("query-string");

class SFOauthRedirect extends Component {

  constructor(){
    super()
    this.state={
      text:'Completing Authorization Process'
    }
  }

  componentDidMount(){
    const {actions} = this.props
    this.interval = setInterval(()=>{
      const {text} = this.state
      if(text.length<48){
        this.setState({text: text+'.'})
      } else {
        this.setState({text: 'Completing Authorization Process'})
      }
    }, 200)
    const parsed = queryString.parse(this.props.location.search);
    const authCode = parsed.code;
    const authState = parsed.state;
    const stateArray = authState.split(' ')
    if(authCode && authState){
      actions.postAuthCode(authCode, stateArray[0], parseInt(stateArray[2],10))
      .then(()=>{
        clearInterval(this.interval)
        this.setState({text: 'Authorization Complete! Close this window.'})
        window.opener.postMessage({lingkOauthMessage:'complete'}, window.opener.location)
      })
      .catch(()=>{
        clearInterval(this.interval)
        this.setState({text: 'Authorization Failed. Please Retry.'})
        window.opener.postMessage({lingkOauthMessage:'failed'}, window.opener.location)
      })
    } else {
      clearInterval(this.interval)
      this.setState({text: 'Authorization Failed. Please Retry.'})
      window.opener.postMessage({lingkOauthMessage:'failed'}, window.opener.location)
    }
  }

  componentWillUnmount(){
    clearInterval(this.interval);
  }

  render() {
    return (
      <div style={{paddingTop:50, }}>
        <Helmet title="Oauth Redirect" />
        <div style={{padding:40}}>
          <img
            role="presentation"
            width="60"
            height="48"
            src={images['Salesforce']}
          />
          <span style={{marginLeft:14}}>{this.state.text}</span>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = state => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SFOauthRedirect);
