import React from "react";
import images from "../images";

var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardLoading: true,
      embeddingFail: false,
    };
  }

  onDashboardLoad = (payload) => {};

  onError = () => {
    this.setState({ dashboardLoading: false, embeddingFail: true });
  };
  embedDashboard = () => {
    var options = {
      url: this.props.redux.project.dashboard,
      container: document.getElementById("dashboardContainer"),
      scrolling: "yes",
      locale: "en-US",
    };
    let dashboard = QuickSightEmbedding.embedDashboard(options);
    dashboard.on("error", this.onError);
    // dashboard.on("load", this.onDashboardLoad);
  };
  componentDidMount() {
    const { TenantId } = this.props.redux.project.tenantInfo;
    this.props.actions.callGetAnalyticsDashboard(TenantId).then(() => {
      this.setState({ dashboardLoading: false });
      this.embedDashboard();
    });
  }
  render() {
    const { dashboardLoading, embeddingFail } = this.state;
    var a = dashboardLoading && !embeddingFail;
    var b = !dashboardLoading && !embeddingFail;
    var c = !dashboardLoading && embeddingFail;
    return (
      <div className="parent-env">
        <div style={{ marginTop: 45 }}>
          {a && (
            <div style={{ textAlign: "center", marginTop: "200px" }}>
              <img src={images.dash_rings} height="40px" width="40px" />
            </div>
          )}
          {b && (
            <div
              id="dashboardContainer"
              style={{ height: "85vh", width: "84vw" }}
            ></div>
          )}
          {c && (
            <div style={{ textAlign: "center", marginTop: "70px" }}>
              Your dashboard failed to load. Please contact Lingk support via
              live chat or support@lingk.io
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Overview;
