import React, { Component } from 'react';

import { Table, Button } from 'react-bootstrap';
import Toggle from 'react-toggle';
import UserPicker from '../account/userPicker';
import images from '../images';
import UserInviteModal from '../account/userInviteModal';
import {bindActionCreators} from "redux";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";
import {connect} from "react-redux";

const titleStyles = {
  fontSize: '22px',
  float: 'left',
  marginBottom: '5px',
}

const title2hStyles = {
  fontSize: '18px',
  top: 0,
  fontWeight: "bold"
}
const dialogStyles = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  width: 300,
  height: 180,
  marginTop: -90,
  marginLeft: -150,
  boxSizing: 'border-box',
  borderRadius: 3,
  padding: '2rem',
  backgroundColor: '#fff',
  display: 'block',
  boxShadow: '0 1px 15px 0 rgba(0,0,0,.1),0 0 0 0 transparent',
  zIndex: 2000,
  border: '1px solid #c9d7df',
}

class ProjectSettings extends Component {

  constructor() {
    super();
    this.state = {
      userIdToBeDeleted: null,
      deletingUser: false,
      invitingUsers: false,
      selectedAppId: null,
    }
    this.callEditUser = this.callEditUser.bind(this);
    this.callDeleteUser = this.callDeleteUser.bind(this);
    this.switchProject = this.switchProject.bind(this);
  }

  isSelected(tenantId) {
    return
  }
  componentDidMount() {
    const { main } = this.props.redux;
    const appList = main.appList;
    const { selectedTenantId } = this.props;
    this.props.actions.setShowMenu(false)
    const index = appList && appList.findIndex(project => {
      return project.tenantid === selectedTenantId
    })
    if (index !== -1) {
      this.switchProject(appList[index])
    }
  }

  callDeleteUser(userId) {
    this.setState({ userIdToBeDeleted: userId })
  }

  actuallyDeleteUser() {
    this.setState({ deletingUser: true })
    this.props.actions.callDeleteUser(
      this.props.tenantId, this.state.userIdToBeDeleted
    ).then(() => {
      this.setState({ userIdToBeDeleted: null, deletingUser: false })
    })
  }

  callEditUser(userid) {
    this.props.history.push({
      pathname: '/edituser',
      state: {
        user: userid,
        tenantId: this.props.tenantId
      }
    })
  }

  updateUserAdmin(e, userId) {
    const { actions } = this.props
    this.setState({ updatingUserAdminId: userId, updatingUserAdminState: e.target.checked })
    actions.updateTenantAdminState(
      this.props.tenantId, userId, e.target.checked
    ).then(() => {
      this.setState({ updatingUserAdminId: null, updatingUserAdminState: null })
    })
  }

  switchProject = (project) => {
    const { actions } = this.props
    const { applicationid } = project
    actions.setAppInfo(project, applicationid)
    this.setState({ selectedAppId: applicationid })
    actions.getUsersForWorkspace(project.tenantid, applicationid); // project.permissions
    actions.callGetAppInfo(applicationid); // project.permissions

  }

  render() {
    const { project, main } = this.props.redux
    const { users, tenantInfo, selectedTenantId } = this.props
    const appList = main.appList;
    const { updatingUserAdminState, updatingUserAdminId, selectedAppId } = this.state;
    const tableRows = [];
    const tableHeaderRow = [];
    const allowedProps = ['username', 'admin'];

    if (users === [] || !users || users === null
      || typeof users === 'undefined') {
    } else {
      const colArray = [];
      const dataObjHeader = users[0];
      for (const prop in dataObjHeader) {
        if (dataObjHeader.hasOwnProperty(prop) && allowedProps.indexOf(prop) >= 0) {
          colArray.push(<th key={prop}>
            {prop === 'username' ? 'Members' : prop === 'admin' ? 'Role' : prop}
          </th>);
        }
      }
      if (this.props.isAdmin) {
        colArray.push(<th key="delete">Action</th>);
      }
      tableHeaderRow.push(<tr key="colArray">{colArray}</tr>);

      for (let ii = 0; ii < users.length; ++ii) {
        const user = users[ii];
        const dataArray = [];
        for (const prop in user) {
          if (user.hasOwnProperty(prop) && allowedProps.indexOf(prop) >= 0) {
            if (prop === 'admin') {
            } else {
              dataArray.push(<td key={prop + ii}>{user[prop]}</td>);
            }
          }
        }
        const isUpdatingAdmin = user.id === updatingUserAdminId
        dataArray.push(<td key="edit_" style={{
          paddingLeft: "6%",
        }}><Toggle
            className="custom-toggle-btn"
            checked={isUpdatingAdmin ? updatingUserAdminState : user.admin}
            icons={{
              checked: <span className="toggle-label toggle-label-admin">admin</span>,
              unchecked: <span className="toggle-label toggle-label-user">user</span>
            }}
            onChange={e => this.updateUserAdmin(e, user.id)}
            disabled={user.id === main.me.id || !this.props.isAdmin}
          /></td>);
        if (this.props.isAdmin) {
          dataArray.push(<td key="delete_" style={{
            display: "flex",
            paddingLeft: "25%",
          }}><Button bsSize="small" bsStyle="primary"
            onClick={this.callDeleteUser.bind(null, user['id'])}
            disabled={user.id === main.me.id}>
              Remove</Button></td>);
        }
        tableRows.push(<tr key={ii + '_array'}>{dataArray}</tr>);
      }
    }

    return (

      <div style={{}}>
        <div style={{ position: 'relative' }}>
          {tenantInfo && <div style={{ position: 'absolute', left: 0 }}>
            Org Name: <strong>{tenantInfo.Name}</strong>
          </div>}

          {tenantInfo && <div style={{ position: 'absolute', right: 0 }}>
            Org Key: <strong>{tenantInfo.Key}</strong>
          </div>}
          <br /><br />
        </div>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "stretch",
          alignContent: "stretch",
        }}>
          <div style={{ width: "25%", }}>
            <div className="user-project-tabs">
              <div style={title2hStyles}>Projects</div>
              <br />
              {appList &&
                appList.map((project, index) => {
                  const selected = selectedAppId === project.Id;

                  if (project.tenantId == selectedTenantId) {
                    return (
                      <div
                        className={`user-project-tab${selected ? " user-project-tab-selected" : ""
                          }`}
                        onClick={() =>
                          this.switchProject(project)
                        }
                        key={project.Id}
                      >
                        {project.name}
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          <div style={{
            borderLeft: "1px dotted grey",
          }}></div>


          <div style={{ width: "73%", }}>
            {this.props.isAdmin ? (
              <div style={{ position: 'relative' }}>
                {this.state.userIdToBeDeleted ? <div className="overlay-100" /> : null}
                <div style={{
                  display: "flex", justifyContent: "space-between",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "stretch",
                  alignContent: "stretch",
                }}>
                  <div>
                    <h5><label htmlFor="manage">Manage Team</label></h5>
                  </div>
                  <div style={{}}>
                    <Button bsSize="small" bsStyle="primary" style={{}}
                      onClick={() => this.setState({ invitingUsers: true })}>
                      Invite Members
                    </Button>
                  </div>
                </div>
                <Table bordered responsive>
                  <thead>
                    {tableHeaderRow}
                  </thead>
                  <tbody>{tableRows}</tbody>
                </Table>

                {this.state.userIdToBeDeleted ?
                  <div style={dialogStyles}>
                    <h4>
                      Are you sure you want to remove this User?
                    </h4>
                    <hr />
                    <div style={{ marginTop: '1rem' }}>
                      <Button
                        bsStyle="primary"
                        onClick={() => this.setState({ userIdToBeDeleted: null })}
                        disabled={this.state.deletingUser}
                      >
                        Cancel
                      </Button>
                      <Button bsStyle="danger"
                        style={{ marginLeft: '.5rem', float: 'right', width: 78 }}
                        onClick={() => this.actuallyDeleteUser()}
                        disabled={this.state.deletingUser}
                      >
                        {!this.state.deletingUser ? 'Remove' :
                          <img alt="small-spinner" src={images.ajax_loader_small} height="8" />}
                      </Button>
                    </div>
                  </div>
                  : null}
                {this.state.invitingUsers && <UserInviteModal {...this.props}
                  onClose={() => this.setState({ invitingUsers: false })}
                  tenantId={this.props.tenantId}
                  selectedAppId={project.appData.Id} // new account setting flow
                  identityIsAdmin={project.appData.identityIsAdmin}
                />
                }
              </div>
            ) : (
              <div style={{ position: 'relative', widows: "100px" }}>
                {selectedAppId ? (
                  <UserPicker {...this.props} />
                ) : (
                  <div style={{}}>
                    Select a project to manage users!
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

    )
  }
}


const mapStateToProps = (state) => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
      {
        ...mainActions,
        ...projectActions,
        ...transformerActions,
        ...datafeedActions,
        ...wizardActions,
        ...environmentActions,
        ...eventsActions,
      },
      dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);
