import React, { Component } from "react";
import InvoiceLineItem from "./invoiceLineItem";
import ReactToPrint from "react-to-print";
import images from "../images";
import moment from "moment";

const titleStyles = {
  fontSize: "22px",
  float: "left",
  marginBottom: "5px",
};

export default class MyInvoices extends Component {
  constructor() {
    super();
    this.state = {
      invoices: [],
      noInvoices: false,
    };
  }

  componentWillMount() {
    const { actions, subscription } = this.props;

    actions
      .getRecurlyInvoices(subscription.accountCode, subscription.uuid)
      .then((i) => {
        const invoices = [];
        if (!Array.isArray(i)) {
          this.setState({ noInvoices: true });
        } else {
          i.map((o) => {
            invoices.push(o);
          });
          this.setState({ invoices });
        }
      });
  }

  render() {
    const { invoices } = this.state;
    let subtotal = invoices.reduce(
      (a, v) => (v.subtotalInCents ? (a = a + v.subtotalInCents) : (a = a + 0)),
      0
    );
    let total = invoices.reduce(
      (a, v) => (v.totalInCents ? (a = a + v.totalInCents) : (a = a + 0)),
      0
    );
    let balance = invoices.reduce(
      (a, v) => (v.balanceInCents ? (a = a + v.balanceInCents) : (a = a + 0)),
      0
    );
    let amountDue = total - balance
    const subtotalInCents = (subtotal / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    const totalInCents = (total / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    const balanceInCents = (balance / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    const amountDueInCents = (amountDue / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    const lineItems =
      invoices &&
      invoices.map((o, i) => {
        return (
          <div key={i}>
            <div style={{ float: "right" }}>
              <ReactToPrint
                trigger={() => <a href="#">Print Invoice</a>}
                content={() => this[`printcomp${i}`]}
                pageStyle="react-to-print"
              />
            </div>

            <div ref={(r) => (this[`printcomp${i}`] = r)}>
              <h4>{moment(o.CreatedAt).format("LL")}</h4>
              <InvoiceLineItem item={o} key={i} />
            </div>
          </div>
        );
      });

    return (
      <div style={{ position: "relative" }}>
        <div style={titleStyles}>My Invoices</div>
        <br />
        <br />
        <br />
        {invoices.length > 0 ? (
          <div className="">
            <div>{lineItems}</div>
            <div style={{ display: "grid" }}>
              <span>Subtotal {subtotalInCents} </span>
              <span>
                <b>Total {totalInCents}</b>
              </span>
              <span style={{ borderBottom: "1px solid #dddddd", width: 160 }}>
                Paid {balanceInCents}
              </span>
              <span>Estimated Amount Due {amountDueInCents}</span>
            </div>
          </div>
        ) : (
          <div>
            {/*loading...*/}
            <img alt="spinner" src={images.ajax_loader} />
          </div>
        )}
        <br />
      </div>
    );
  }
}
