import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import ContentWsStyles from "../../styles/contentwsstyle";
import UserInviteModal from "./userInviteModal";
import images from "../images";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as projectActions from "../../shared/redux/actions/projectActions";
import * as mainActions from "../../shared/redux/actions/mainActions";
import * as transformerActions from "../../shared/redux/actions/transformerActions";
import * as datafeedActions from "../../shared/redux/actions/datafeedActions";
import * as wizardActions from "../../shared/redux/actions/wizardActions";
import * as environmentActions from "../../shared/redux/actions/environmentActions";
import * as eventsActions from "../../shared/redux/actions/eventsActions";

class NewAccount extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      description: "",
      selectedTenantId: "",
      invitingUsers: false,
      storedUserInvites: null,
      subscription: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.storeUserInvites = this.storeUserInvites.bind(this);
  }

  storeUserInvites(userInvites) {
    this.setState({ invitingUsers: false, storedUserInvites: userInvites });
  }

  componentDidMount() {
    const { redux } = this.props;
    const { main } = redux;
    var activeTenantList = new Array();
    if (main.tenantList && main.tenantList.length > 0) {
      for (var tenant of main.tenantList) {
        if (tenant.isActive) {
          activeTenantList.push(tenant);
        }
      }
      if (activeTenantList.length == 0) {
        return;
      }
    }
    const tenantList =
      activeTenantList &&
      activeTenantList.filter((t) => {
        return t.IdentityIsAdmin;
      });
    if (tenantList && tenantList.length > 0) {
      this.setState({ selectedTenantId: tenantList[0].TenantId });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const appname = this.state.name;
    let description = this.state.description;

    if (description === "") {
      description = "No description.";
    }
    //var category = ReactDOM.findDOMNode(this.refs.category).value.trim();
    if (!appname || !description) {
      alert("Please fill out all fields");
      return;
    }
    const apihubs = [
      {
        // removing ApiHubs (default to all)
        Applications: null,
        Id: 12,
        Name: "All Data Elements",
        ResourceTypes: null,
        Type: 1,
      },
    ];
    this.setState({ creating: true });
    this.props.actions
      .callCreateApp(this.state.selectedTenantId, {
        name: appname,
        description,
        category: "Other",
        apihubs,
      })
      .then((apps) => {
        if (this.state.storedUserInvites) {
          const createdApp = apps[apps.length - 1];
          this.props.actions.inviteUsersToWorkspace(
            this.state.selectedTenantId,
            createdApp.Id,
            this.state.storedUserInvites
          );
        }
        this.setState({ creating: false });
        this.props.history.push({
          pathname: "/",
        });
      });
  }

  render() {
    const { redux } = this.props;
    const { main } = redux;
    var activeTenantList = new Array();
    if (main.tenantList && main.tenantList.length > 0) {
      for (var tenant of main.tenantList) {
        if (tenant.isActive) {
          activeTenantList.push(tenant);
        }
      }
      if (activeTenantList.length == 0) {
        return (
          <div>
            {/* <InActiveTenant/> */}
            <div>need to show inactive tenant</div>
          </div>
        );
      }
    }
    const tenantList =
      activeTenantList &&
      activeTenantList.filter((t) => {
        return t.IdentityIsAdmin;
      });
    return (
      <div>
        <form
          className="form-horizontal"
          onSubmit={this.handleSubmit}
          style={{ marginTop: 72 }}
        >
          <div style={{ fontSize: "20px" }}>Add Project</div>

          <div style={ContentWsStyles.formElementTitle}>Your Organizations</div>
          <select
            className="form-control"
            name="app"
            value={String(this.state.selectedTenantId)}
            onChange={(e) =>
              this.setState({ selectedTenantId: parseInt(e.target.value, 10) })
            }
          >
            <option selected disabled>
              Organizations
            </option>
            {tenantList &&
              tenantList.map((t) => {
                return (
                  <option value={t.TenantId} key={t.TenantId}>
                    {t.Name}
                  </option>
                );
              })}
          </select>

          <div style={ContentWsStyles.formElementTitle}> Name</div>
          <div>
            <input
              className="form-control"
              type="text"
              placeholder="Project name"
              onChange={(e) => this.setState({ name: e.target.value })}
              value={this.state.name}
            />
          </div>

          <div style={ContentWsStyles.formElementTitle}> Description</div>
          <div>
            <textarea
              rows="5"
              cols="100"
              placeholder="Description"
              onChange={(e) => this.setState({ description: e.target.value })}
              value={this.state.description}
            />
            <br />
            <a href="http://commonmark.org/help/" target="blank">
              Formatting Help
            </a>
          </div>

          {/*<div style={ContentWsStyles.formElementTitle}>Lifecycle Category</div>
        <div>
            <select className="form-control" name="category" ref="category">
                <option value="Recruitment">1: Recruitment</option>
                <option value="Admission">2: Admission</option>
                <option value="Registration">3: Registration</option>
                <option value="Academic Progression">4: Academic Progression</option>
                <option value="Student Life">5: Student Life</option>
                <option value="Alumni Relations">6: Alumni Relations</option>
                <option value="Graduation">7: Graduation</option>
                <option value="Other">Other</option>
            </select>
        </div>
      <br/>*/}
          <br />
          <Button
            bsStyle="primary"
            onClick={() => this.setState({ invitingUsers: true })}
            disabled={!this.state.selectedTenantId}
          >
            Invite Users
          </Button>
          <br />
          <br />
          <br />

          <div>
            <button
              type="submit"
              value="Post"
              className="btn btn-sm btn-primary"
              disabled={
                !this.state.name ||
                !this.state.selectedTenantId ||
                this.state.creating
              }
              style={{ width: 47 }}
            >
              {!this.state.creating ? (
                "Save"
              ) : (
                <img
                  alt="small-spinner"
                  src={images.ajax_loader_small}
                  height="8"
                />
              )}
            </button>
            &nbsp;&nbsp;
            <Link to="/">
              <input
                type="button"
                value="Cancel"
                className="btn btn-sm btn-primary"
                disabled={this.state.creating}
              />
            </Link>
          </div>
          {/*<div style={ContentWsStyles.formElementTitle}> Choose Your Repository Connections </div>
        <div  style={HomeStyles.connectionListStyles}>
            <div style={HomeStyles.connectionContainerStyles}>
                <div><img  style={HomeStyles.connectionIconStyles} src={images.api_ico}/></div>
                <ReactBootstrapToggle
                    on={<span>On</span>}
                    off={<span>Off</span>}
                    active={true}
                    />
            </div>
            <div style={HomeStyles.connectionContainerStyles}>
                <div><img  style={HomeStyles.connectionIconStyles} src={images.sftp_ico}/></div>
                <ReactBootstrapToggle
                    on={<span>On</span>}
                    off={<span>Off</span>}
                    active={true}
                    />
            </div>
          </div>*/}
        </form>

        {this.state.invitingUsers && (
          <UserInviteModal
            {...this.props}
            newWorkspace
            onClose={() =>
              this.setState({ invitingUsers: false, storedUserInvites: null })
            }
            storeUserInvites={this.storeUserInvites}
            tenantId={this.state.selectedTenantId}
            storedUserInvites={this.state.storedUserInvites}
          />
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...mainActions,
      ...projectActions,
      ...transformerActions,
      ...datafeedActions,
      ...wizardActions,
      ...environmentActions,
      ...eventsActions,
    },
    dispatch
  ),
});
const mapStateToProps = (state) => ({
  redux: {
    config: state.config,
    auth: state.auth,
    main: state.main,
    project: state.project,
    transformer: state.transformer,
    datafeed: state.datafeed,
    wizard: state.wizard,
    environment: state.environment,
    events: state.events,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAccount);
