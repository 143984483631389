import React, { Component } from "react";
import WizardConfig from "./wizardConfig";
import { Route, Switch } from "react-router-dom";
import WizardFormWrapper from "./wizardFormWrapper";

class WizardTabParent extends Component {
  componentWillUnmount() {
    this.props.actions.clearTransformer();
  }
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/a/:appId/wiz/:transId"
          render={(routerProps) => (
            <WizardConfig
              {...routerProps}
              redux={this.props.redux}
              actions={this.props.actions}
            />
          )}
        />
        <Route
          path="/a/:appId/wiz/:transId/configure"
          render={(routerProps) => (
            <WizardFormWrapper
              {...routerProps}
              redux={this.props.redux}
              actions={this.props.actions}
            />
          )}
        />
      </Switch>
    );
  }
}

export default WizardTabParent;
