import React from "react";

import { Button, Modal, Table } from "react-bootstrap";
import images from "../images";
import { Fragment } from "react";
import Deps from "../environments/dependenciesTable";
const { Body } = Modal;

const ConfirmDeleteModal = (props) => {
  const {
    modalState,
    fixed,
    triggerModal,
    nameOfTarget,
    deleteTarget,
    message,
    buttonText,
    deleting,
    dependenciesExist,
    warningMsg,
    loader,
    dependencies,
    multipleConnectorExists,
  } = props;

  return (
    <Modal
      show={modalState}
      onHide={triggerModal}
      backdrop={fixed ? "static" : null}
      keyboard={fixed ? false : true}
      dialogClassName="confirm-delete-modal"
    >
      <Body style={{ height: "fit-content" }}>
        <div
        // style={{
        //   textAlign: "center",
        //   verticalAlign: "middle",
        //   paddingLeft: 20,
        //   paddingRight: 20,
        //   paddingTop: 20,
        //   paddingBottom: 20,
        // }}
        >
          <div
            style={{
              textAlign: "center",
              verticalAlign: "middle",
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 20,
              paddingBottom: 10,
            }}
          >
            {loader ? (
              <img alt="spinner" src={images.ajax_loader} />
            ) : (
              <Fragment>
                {dependenciesExist ? (
                  <h1 style={{ fontSize: 18 }}>
                    <img
                      src={images.warningRed}
                      height="21"
                      style={{ marginTop: -5 }}
                    />
                    {warningMsg}
                  </h1>
                ) : multipleConnectorExists ? (
                  <div>
                    <h2>This is default connection!</h2>
                    <h4>Please mark different connection as a default connection in order to delete this connection.<br /></h4>
                  </div>
                ) : (
                  <h2>Are you sure?</h2>
                )}
                {dependenciesExist ? (
                  message
                ) : (
                  <div>
                    {nameOfTarget && <p>This will delete "{nameOfTarget}"</p>}
                  </div>
                )}
              </Fragment>
            )}
          </div>
          {dependenciesExist && !loader && <Deps dependencies={dependencies} />}
          <div
            style={{
              textAlign: "center",
              verticalAlign: "middle",
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 0,
              paddingBottom: 20,
            }}
          >
            <div style={{ marginTop: 20 }}>
              <Button
                bsStyle="default"
                disabled={buttonText ? deleting : null}
                onClick={() => triggerModal()}
              >
                Back
              </Button>
              {!multipleConnectorExists &&
                <Button
                  style={{ marginLeft: 10 }}
                  bsStyle="danger"
                  disabled={buttonText ? deleting : null || dependenciesExist}
                  onClick={() => deleteTarget()}
                >
                  {buttonText ? <span>Cancel</span> : <span>Delete</span>}
                </Button>
              }
            </div>
          </div>
        </div>
      </Body>
    </Modal>
  );
};

export default ConfirmDeleteModal;
