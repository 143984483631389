const isClient =
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement

function initElevio(actions){
  if(isClient){

    !function(e,l,v,i,o,n){e[i]||(e[i]={}),e[i].account_id=n;var g,h;g=l.createElement(v),g.type='text/javascript',g.async=1,g.src=o+n,h=l.getElementsByTagName(v)[0],h.parentNode.insertBefore(g,h);e[i].q=[];e[i].on=function(z,y){e[i].q.push([z,y])}}(window,document,'script','_elev','https://cdn.elev.io/sdk/bootloader/v4/elevio-bootloader.js?cid=','5a6908511451b');

    window._elev.on('load', function(_elev) {
      _elev.setSettings({
        docked_position: 'floor',
        side: 'right',
        loggedin_only: true
      });
      _elev.setTranslations({ search: "What would you like to do today?" });
      const lsp = localStorage.getItem('profile');
      if(lsp){
        const profile = JSON.parse(lsp)
        if(profile && profile.email){
          actions.getElevioEmailHash(profile.email)
          .then((r)=>{
            _elev.setUser({
              email: profile.email,
              user_hash: r,
            })
          })
        }
      }
    });

  }
}

export default initElevio