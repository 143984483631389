const HeaderStyle = {
  headerStyles: {
    width: '100%',
    color: 'white',
    paddingRight: 10,
    paddingLeft: 10,
  },
  logoStyles: {
    marginTop: 12,
    float: 'left',
    // width:100,
    marginLeft: 12,
    cursor: "pointer",
  },
  tabStyles: {
    position: 'absolute',
    textAlign: 'center',
    listStyle: 'none',
    left: 175,
    padding: 0,
    lineHeight: '24px',
    marginTop: '0px',
    float: 'left',
  },
  itemStyles: {
    height: '40px',
    margin: '0px',
    padding: '10px 10px 0 10px',
    border: 0,
    background: '#205F68',
    display: 'inline-block',
  },
  buttonStyles: {
    fontSize: '12px',
    lineHeight: '20px',
    textAlign: 'center',
    transform: 'transformZ(0)',
    display: 'inline-block',
    padding: '5px 0',
    paddingLeft: 11,
    paddingRight: 11,
    verticalAlign: 'bottom',
    cursor: 'pointer',
    fontWeight: 700,
  },
  selectedItemStyles: {
    height: '50px',
    marginTop: '0px',
    padding: '10px 10px 5px 10px',
    border: 0,
    background: '#465C3A',
    display: 'inline-block',
  },
  selectedButtonStyles: {
    borderRadius: 15,
    backgroundColor: '#254216',
    fontSize: '12px',
    lineHeight: '20px',
    textAlign: 'center',
    transform: 'transformZ(0)',
    display: 'inline-block',
    padding: '5px 0',
    paddingLeft: 11,
    paddingRight: 11,
    verticalAlign: 'bottom',
    cursor: 'pointer',
    fontWeight: 700,
  },
  iconStyles: {
    width: 19,
    height: 19,
    verticalAlign: 'top',
  },
  countStyles: {
    color: '#dddddd',
    fontSize: '11px',
  },
  accountStyles: {
    marginRight: 20,
  },
  userPicStyles: {
    float: 'right',
    marginRight: 20,
    marginTop: 10,
    width: 32,
    height: 32,
    borderRadius: '50%',
    display: 'block',
    backgroundSize: '32px 32px',
    backgroundImage: 'url(resources/person.png)',
    cursor: 'pointer'
  },
  usernameStyles: {
    float: 'right',
    marginRight: 20,
    marginTop: 17,
    fontSize: '14px',
  },
  envStyles: {
    float: 'right',
    marginRight: 40,
    marginTop: 10,
    fontSize: '14px',
  },
  settingStyles: {
    float: 'right',
    marginRight: 20,
    marginTop: 20,
    cursor: 'pointer',
  },
  aStyles: {
    color: 'white',
    textDecoration: 'none',
  },
  topbarStyles: {
    position: 'fixed',
    top: 0,
    backgroundColor: '#205F68',
    height: 50,
    width: '100%',
    zIndex: '1001',
  },
};

export default HeaderStyle;
