import React, { Component, useState, useEffect } from "react";

import {
  RecurlyProvider,
  Elements,
  CardElement,
  useRecurly,
} from "@recurly/react-recurly";
import { Button } from "react-bootstrap";

export default class PaymentHome extends Component {
  render() {
    const production = "ewr1-WOsD9pNkfswzPzJl4lc2kA";
    const integration = "ewr1-DcoLeuxCQ2kqViKIY9kukb";
    const isIntegration =
      window.location &&
      (window.location.host === "dataprotect.lingkapps.com" ||
        window.location.host === "localhost:3000");
    let config = production;
    if (isIntegration) {
      config = integration;
    }
    return (
      <div>
        {/* Checking for the window's existence because this can only be run client-side */}
        {typeof window !== "undefined" && (
          <RecurlyProvider publicKey={config}>
            <Elements>
              <MyPaymentForm
                pk={config}
                submitForm={this.props.submitForm}
                form={this.props.form}
              />
            </Elements>
          </RecurlyProvider>
        )}
      </div>
    );
  }
}

function MyPaymentForm(props) {
  const { form, submitForm, pk } = props;
  const recurly = useRecurly();
  function handleSubmit() {
    recurly.token(form, (err, token) => {
      if (err) {
        submitForm(null, err);
      } else {
        submitForm(token, null);
      }
    });
  }
  return (
    <div>
      <Button
        large="large"
        bsSize="large"
        style={{ height: 55, borderRadius: 50, paddingLeft: 80, paddingRight: 80, background: "#6C63FF", color: "#fff" }}
        onClick={() => {
          handleSubmit();
        }}
      >
        <b>Sign Up</b>
      </Button>
    </div>
  );
}
