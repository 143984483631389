import React from 'react';
import images from '../images'
import moment from 'moment'


const styles = {
  box:{
    marginBottom: 16,
    overflow: 'hidden',
    marginRight: 16,
    height: 115,
    cursor: 'pointer',
    float: 'left',
    paddingBottom: 5,
    padding: 12,
    display: 'inline-block',
    verticalAlign:'middle',
    width: 220,
    position: 'relative',
  },
  name:{
    width: 'calc(100% - 24px)',
    textDecoration: 'underline',
    color:'#337ab7',
    fontWeight:'bold',
    maxHeight: 38,
    overflow: 'hidden'
  },
  lastModified:{
    overflow: 'hidden',
    whiteSpace:'nowrap',
    paddingTop: 6,
    color: '#404040',
    fontSize:12,
    width:'100%',
  },
  icons: {
    position: 'absolute',
    textAlign: 'center',
    left:0,
    top: 63, width:138,
  },
  arrow:{
    display: 'inline-block', 
    width: 28, 
    height: 46, 
    backgroundSize: 'contain', 
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center', 
    backgroundImage: `url("${images.arrow}")`
  }
}

const MapperBox = ({mapper, appId, history}) => {
  const {title, sourceProvider, targetProvider, lastModified, id} = mapper
  return (<div style={styles.box} className="connexp-box"
    onClick={() => {
      history.push(`/a/${appId}/map/${id}`)
    }}>
    <div style={styles.name}>
      {title}
    </div>

    <div style={styles.icons}>
      <Icon type={sourceProvider} style={{position: 'absolute', top: 4, left: 75}} />
      <div
        role="presentation"
        style={styles.arrow}
      />
      <Icon type={targetProvider} style={{position: 'absolute', top: 4, left: 75}} />
    </div>

    <div style={styles.lastModified}>
      Modified: <strong>{moment(lastModified).format('MMM Do Y, h:mm a')}</strong>
    </div>
  </div>)
}

const Icon = (props) => {
  const {type} = props
  let imgType = type
  if(type==='Custom'){
    imgType = 'globe'
  }
  return (<div
    role="presentation"
    style={{ display: 'inline-block', width: 46, height: 46, backgroundSize: 'contain', backgroundRepeat: 'no-repeat',backgroundPosition: 'center', backgroundImage: `url("${images[imgType]}")` }}
  />)
}

export default MapperBox
