import React, { Component } from "react";
import { PieChart } from "react-d3-components";
import moment from "moment";
import { DropdownButton, MenuItem } from "react-bootstrap";
import EnvironmentDropdown from "../utils/EnvironmentDropdown";
import images from "../images";

const hoursOptions = [
  { label: "Last 24 hours", h: 24 },
  { label: "Last 48 hours", h: 48 },
  { label: "Last week", h: 168 },
  { label: "Last month", h: 720 },
];
const timeFilters = [
  { label: "Past Hour", key: "pastHour" },
  { label: "Past 24 Hours", key: "past24Hours" },
  { label: "This Week", key: "thisWeek" },
  { label: "Past Week", key: "pastWeek" },
];
const typeOptions = [
  { label: "Any", t: "any" },
  { label: "Wizard", t: "wizard" },
  { label: "Batch Recipe", t: "2.0" },
  { label: "Streaming Recipe", t: "3.0" },
];

const statusOptions = [
  "All",
  "Successes",
  "Errors",
  "In Progress",
  "Partial Success",
];

const styles = {
  r: {
    display: "inline-block",
    verticalAlign: "top",
    width: "36%",
    paddingLeft: 6,
    height: 23,
    lineHeight: "23px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  envBox: {
    border: "1px solid black",
    marginRight: 4,
    marginTop: 3,
    marginLeft: 5,
    height: 16,
    width: 16,
    borderRadius: 3,
  },
};

export default class MonitorTransformer extends Component {
  constructor() {
    super();
    this.state = {
      selectedEnvironment: { name: "All Environments" },
      selectedTime: { label: "This Week", key: "thisWeek" },
      selectedType: { label: "Any", t: "any" },
      selectedStatus: "All",
      logs: [],
    };
  }
  componentDidMount() {
    const { actions, match, redux } = this.props;
    const { project } = redux;
    const { params } = match;
    let { tenantInfo } = project;
    actions
      .callGetTransformerLogsForWorkspace(
        tenantInfo.TenantId,
        params.appId,
        0,
        "all",
        "thisWeek",
        "",
        ""
      )
      .then((data) => {
        this.setState({ logs: data });
      });
  }

  getExecutions = (ho) => {
    const { actions, match, redux } = this.props;
    const { project } = redux;
    const { params } = match;
    let { tenantInfo } = project;
    this.setState({ selectedTime: ho });
    actions
      .callGetTransformerLogsForWorkspace(
        tenantInfo.TenantId,
        params.appId,
        0,
        "all",
        ho.key,
        "",
        ""
      )
      .then((data) => {
        this.setState({ logs: data });
      });
  };

  render() {
    const { redux, match } = this.props;
    const { params } = match;
    const { selectedEnvironment, selectedStatus, selectedType, selectedTime } =
      this.state;
    const { project } = redux;
    const succeeded = [];
    const failed = [];
    const inProgress = [];
    const partialSuccess = [];
    const all = []; // for list
    if (this.state.logs) {
      this.state.logs.forEach((tl) => {
        if (
          selectedEnvironment.name === "All Environments" ||
          selectedEnvironment.environmentId === tl.environmentId
        ) {
          // env filter
          if (
            selectedType.t === "any" ||
            (selectedType.t === "wizard" &&
              tl.executionMetadata.generatedFromPi) ||
            selectedType.t === tl.executionMetadata.languageVersion
          ) {
            // type filter
            if (tl.completed === false && tl.succeeded === false) {
              inProgress.push(tl);
            } else {
              if (tl.succeeded) {
                if (tl.statusText.includes("partial")) {
                  partialSuccess.push(tl);
                } else {
                  succeeded.push(tl);
                }
              } else {
                failed.push(tl);
              }
            }
            all.push(tl);
          }
        }
      });
    }
    let runs = [];
    switch (selectedStatus) {
      case "Successes":
        runs = succeeded;
        break;
      case "Errors":
        runs = failed;
        break;
      case "In Progress":
        runs = inProgress;
        break;
      case "Partial Success":
        runs = partialSuccess;
        break;
      default:
        runs = all;
        break;
    }
    const pieData = {
      label: "Data Pipelines",
      values: [
        { x: "Success", y: succeeded.length },
        { x: "Error", y: failed.length },
        { x: "In Progress", y: inProgress.length },
      ],
    };
    const noPieData =
      succeeded.length + failed.length + inProgress.length === 0;
    return (
      <div>
        <div style={{ position: "relative" }}>
          {this.state.logs ? (
            <div className="">
              {/*}
          <div style={{fontSize:'18px',display:'inline-block',
            verticalAlign:'top',lineHeight:'30px'}}>
            Monitoring
          </div>
        */}
              <div>
                <div
                  id="workspace-pie-chart"
                  style={{
                    padding: "30px 0px 13px 0px",
                    display: "inline-block",
                    width: 230,
                  }}
                >
                  {!noPieData ? (
                    <PieChart
                      data={pieData}
                      width={240}
                      height={100}
                      margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
                      outerRadius={50}
                      innerRadius={0.1}
                      sort={null}
                    />
                  ) : (
                    <EmptyPie width={200} height={100} />
                  )}
                  <div
                    style={{
                      marginTop: 22,
                      marginLeft: 0,
                      textAlign: "center",
                    }}
                  >
                    <div>
                      Jobs: <strong>{runs.length}</strong>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <span>
                        Successes: <strong>{succeeded.length}</strong>
                      </span>
                      <span style={{ marginLeft: 12 }}>
                        Errors: <strong>{failed.length}</strong>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    width: 150,
                  }}
                >
                  <div style={{ marginTop: 5 }}>
                    <strong className="labelz" style={{ fontSize: "11px" }}>
                      Filter by Time
                    </strong>
                    <br />
                    <DropdownButton
                      title={selectedTime.label || "Select Time"}
                      bsStyle="default"
                      bsSize="small"
                      className="environments-dropdown"
                      style={{ width: 150, fontWeight: "bold" }}
                      id="timez"
                    >
                      {timeFilters.map((ho) => {
                        return (
                          <MenuItem
                            value={ho.key}
                            key={ho.key}
                            active={selectedTime.key === ho.key}
                            onClick={() => this.getExecutions(ho)}
                          >
                            {ho.label}
                          </MenuItem>
                        );
                      })}
                    </DropdownButton>
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <EnvironmentDropdown
                      environments={project.environments}
                      disabled={
                        !(
                          project.environments &&
                          project.environments.length > 0
                        )
                      }
                      includeAllOption
                      uiEnvironment={selectedEnvironment}
                      onSelect={(env) =>
                        this.setState({ selectedEnvironment: env })
                      }
                      showThisTitle="Filter by Environment"
                    />
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <strong className="labelz" style={{ fontSize: "11px" }}>
                      Filter by Type
                    </strong>
                    <br />
                    <DropdownButton
                      title={selectedType.label || "Select Type"}
                      bsStyle="default"
                      bsSize="small"
                      className="environments-dropdown"
                      style={{ width: 150, fontWeight: "bold" }}
                      id="typez"
                    >
                      {typeOptions.map((to) => {
                        return (
                          <MenuItem
                            value={to.t}
                            key={to.t}
                            active={selectedType.t === to.t}
                            onClick={() => this.setState({ selectedType: to })}
                          >
                            {to.label}
                          </MenuItem>
                        );
                      })}
                    </DropdownButton>
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <strong className="labelz" style={{ fontSize: "11px" }}>
                      Filter by Status
                    </strong>
                    <br />
                    <DropdownButton
                      title={selectedStatus || "Select Status"}
                      bsStyle="default"
                      bsSize="small"
                      className="environments-dropdown"
                      style={{ width: 150, fontWeight: "bold" }}
                      id="statuses"
                    >
                      {statusOptions.map((s) => {
                        return (
                          <MenuItem
                            value={s}
                            key={s}
                            active={selectedStatus === s}
                            onClick={() => this.setState({ selectedStatus: s })}
                          >
                            {s}
                          </MenuItem>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    borderRight: "1px solid #bbb",
                    width: 465,
                    marginLeft: 15,
                    borderLeft: "1px solid #DDD",
                    height: 230,
                  }}
                >
                  <div
                    style={{
                      height: 23,
                      width: "100%",
                      background: "#999",
                      color: "white",
                    }}
                  >
                    <div style={{ ...styles.r, width: "8%" }}>Env</div>
                    <div
                      style={{
                        ...styles.r,
                        borderLeft: "1px solid white",
                        width: "56%",
                      }}
                    >
                      Name
                    </div>
                    <div style={{ ...styles.r, borderLeft: "1px solid white" }}>
                      Time
                    </div>
                  </div>
                  <div
                    style={{ overflowY: "auto", height: "calc(100% - 23px)" }}
                  >
                    {runs.map((run, i) => (
                      <Result
                        r={run}
                        key={i}
                        project={project}
                        appId={params.appId}
                        history={this.props.history}
                        filter={selectedTime.key}
                      />
                    ))}
                    {
                      runs.length === 0 && (
                        <div style={{ padding: "8px 14px" }}>
                          No Executions with these filters
                        </div>
                      )
                      // (
                      //   <div
                      //     style={{
                      //       padding: "10px 14px",
                      //       fontSize: "12px",
                      //       color: "grey",
                      //     }}
                      //   >
                      //     <span>{`Showing ${runs.length} Execution${
                      //       runs.length === 1 ? "" : "s"
                      //     } `}</span>
                      //     <span style={{ fontStyle: "oblique" }}>
                      //       (out of max 100)
                      //     </span>
                      //   </div>
                      // )
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginBottom: 20, marginLeft: 4 }}>
              <img alt="spinner" src={images.ajax_loader} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const errorNotifyStyle = {
  height: 14,
  width: 14,
  background: "#d9534f",
  color: "white",
  borderRadius: "50%",
  fontWeight: "bold",
  display: "inline-block",
  verticalAlign: "top",
  marginRight: 6,
  fontSize: "12px",
  marginTop: 4,
  paddingLeft: 5,
  lineHeight: "16px",
}; // hover #c9302c ?

const Result = ({ r, project, appId, history, filter }) => {
  const env =
    project &&
    project.environments &&
    project.environments.find((e) => {
      return e.environmentId === r.environmentId;
    });
  if (!env) return <span />;
  let failed = true;
  let isInProgress = true;
  if (r.succeeded) {
    if (r.completed) {
      isInProgress = false;
    } else {
      isInProgress = true;
    }
    failed = false;
  } else {
    if (r.completed) {
      failed = true;
      isInProgress = false;
    } else {
      failed = false;
      isInProgress = true;
    }
  }

  return (
    <div
      className="workspace-monitor-result"
      onClick={() => {
        if (r.executionMetadata.generatedFromPi == "wiz") {
          history.push({
            pathname: `/a/${appId}/wiz/health`,
            state: {
              recipeId: `${r.executionMetadata.baseId}`,
              guid: `${r.executionGUID}`,
              filter: `${filter}`,
            },
          });
        } else {
          history.push({
            pathname: `/a/${appId}/health`,
            search: `?executionGuid=${r.externalExecutionId}&filter=${filter}`,
          });
        }
      }}
    >
      <div style={{ ...styles.r, width: "8%" }}>
        <div style={{ ...styles.envBox, background: env.color || "#404040" }} />
      </div>
      <strong
        style={{ ...styles.r, borderLeft: "1px solid #DDD", width: "56%" }}
      >
        {failed && <div style={errorNotifyStyle}>!</div>}
        {isInProgress && <img style={{ marginRight: "5px" }} alt="spinner" src={images.ajax_loader} />}
        <span>{r.executionMetadata && r.executionMetadata.recipeName}</span>
      </strong>
      <span
        style={{
          ...styles.r,
          borderLeft: "1px solid #DDD",
          color: failed ? "red" : "black",
        }}
      >
        {moment(r.startTime).format("MMM Do, h:mm:ss a")}
      </span>
    </div>
  );
};

const EmptyPie = ({ width, height }) => {
  return (
    <div style={{ paddingLeft: 18 }}>
      <svg width={width} height={height}>
        <g transform="translate(100, 50)">
          <circle
            cx="0"
            cy="0"
            r="48"
            stroke="#BBB"
            strokeWidth="1"
            fill="white"
          />
        </g>
        <g transform="translate(100, 50)">
          <text dy="6px" x="27" y="0" textAnchor="end" fontSize="10px">
            No Records
          </text>
        </g>
      </svg>
    </div>
  );
};
