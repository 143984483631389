import React, { Component } from 'react';
import HomeStyles                      from '../styles/homestyles';
import PropTypes from 'prop-types';


export default class Login extends Component {

  constructor(props){
    super(props)
    const {location} = props
    let redirect = null
    // if we are logging in on the /sfoauth page, keep the code via state
    if(location && (location.pathname==='/sfoauth' ||location.pathname==='/sfjobs'|| location.pathname==='/dsoauth') && location.search){
      redirect = location.pathname + location.search

    }
    this.state={redirect, loggingIn: false}
  }

  componentWillMount(){
    const loggingIn = window.location && window.location.href.includes('#access_token=')
    
    if (loggingIn){
      this.setState({loggingIn: true})
    }
    else{
      this.setState({loggingIn: false})
    }
  }

  componentDidMount(){
    const {auth} = this.props

    if(auth && !auth.loggedIn() && !this.loggingIn()){
      auth.login()
    }
  }

  loggingIn = () => window.location && window.location.href.includes('#access_token=')

  render() {
    const { auth, location } = this.props
    const {redirect} = this.state

let message
    
if (location && location.query && location.query.success && location.query.message) {
       const success = JSON.parse(location.query.success) 
       //message = urlmessage && urlmessage.split('%20')
       if (success){
        message = "Success! Log in with your new password."
       }
       else{
        message = "Authentication error. This URL has expired."
       }
     }


    return (
      <div>
      {message && 
        <div style={{position: 'fixed',
        top: 0, zIndex: '10', color: '#fff', height: 50, background: '#205F68',fontSize: 16, 
        padding: 14, textAlign: 'center', width: '100%'}}>
        <span style={{verticalAlign:'middle'}}>{message}</span>
        </div>
      }

      <div style={HomeStyles.loginBoxStyles} className="title-login" onClick={() => auth.login(redirect ? {auth:{params:{state:btoa(redirect)}}} : null)}>
        <div>
        <h1 className="title-login-lingk">
          Lingk
        </h1>
        <br />
        {/*<ReactSpinner config={spinnerOpts}/>*/}
        </div>
      </div>
      </div>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.object,
};


const spinnerOpts = {
  lines: 11, // The number of lines to draw
  length: 30, // The length of each line
  width: 12, // The line thickness
  radius: 25, // The radius of the inner circle
  scale: 0.2, // Scales overall size of the spinner
  corners: 1, // Corner roundness (0..1)
  color: '#444', // CSS color or array of colors
  fadeColor: 'transparent', // CSS color or array of colors
  speed: 3, // Rounds per second
  rotate: 0, // The rotation offset
  animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  zIndex: 0, // The z-index (defaults to 2000000000)
  className: 'spinner', // The CSS class to assign to the spinner
  top: '90%', // Top position relative to parent
  left: '42%', // Left position relative to parent
  //shadow: '0 0 1px transparent', // Box-shadow for the lines
  position: 'absolute' // Element positioning
};
