import * as types from "./types";
import * as util from "@lingk/sync/build/metadataFunctions";
import axios from "axios";

export function getMonitoringPreferences(tenantId, appId, environmentId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`notification/getconfig/${tenantId}/${appId}/${environmentId}`, {
        endpoint: config.MONITOR_API_URL,
      })
      .then((res) => {
        dispatch({
          type: types.SET_MONITORING_PREFERENCES,
          data: res,
        });
        return res;
      })
      .catch(() => {
        dispatch({
          type: types.SET_MONITORING_PREFERENCES,
          data: {},
        });
      });
  };
}

export function updateMonitoringPreferences(data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.post("notification/configure", {
      endpoint: config.MONITOR_API_URL,
      data,
    });
  };
}

export function deleteMonitoringPreferences(data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.delete("notification/deleteconfig", {
      endpoint: config.MONITOR_API_URL,
      data,
    });
  };
}

export function clearEnvironmentMonitoringPreferences() {
  return {
    type: types.CLEAR_MONITORING_PREFERENCES,
  };
}

export function updateSessionEnvironment(envId) {
  return {
    type: types.SESSION_ENVIRONMENT_SELECTION,
    data: envId,
  };
}

export function gencerts(tenantId, appId, envId) {
  return (dispatch, getState, api) => {
    let data = {};
    const { config } = getState();
    return api.post(`${tenantId}/${appId}/${envId}/generatecerts`, {
      endpoint: config.TRANS_API_URL,
      data,
    });
  };
}

export function getWebsocketAgentCerts(tenantId, appId, envId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/${envId}/certs`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_ENV_WEBSOCKET_CERTS,
          data,
        });
        return data;
      });
  };
}

export function deleteWebsocketAgentCerts(tenantId, appId, envId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.delete(`${tenantId}/${appId}/${envId}/certs`, {
      endpoint: TRANS_API_URL,
    });
  };
}
export function downloadcerts(tenantId, appId, envId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`${tenantId}/${appId}/${envId}/downloadcerts/keystore`, {
        endpoint: config.TRANS_API_URL,
      })
      .then((data) => {
        downloadAs(data, "keystore.jks");
        api
          .get(`${tenantId}/${appId}/${envId}/downloadcerts/truststore`, {
            endpoint: config.TRANS_API_URL,
          })
          .then((d) => {
            downloadAs(d, "truststore.jks");
          });
      });
  };
}

export function downloadExecutionLogs(tenantId, recipeId, executionId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`${tenantId}/${appId}/${envId}/downloadcerts/keystore`, {
        endpoint: config.TRANS_API_URL,
      })
      .then((data) => {
        downloadAs(data, "keystore.jks");
        api
          .get(`${tenantId}/${appId}/${envId}/downloadcerts/truststore`, {
            endpoint: config.TRANS_API_URL,
          })
          .then((d) => {
            downloadAs(d, "truststore.jks");
          });
      });
  };
}

//actions.callGetTransformerLogsForEnvironment(tenantInfo.TenantId, params.appId, 79)
export function callGetTransformerLogsForEnvironment(tenantId, appId, envId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .get(`${tenantId}/${appId}/logs/environments/${envId}?limit=100`, {
        endpoint: TRANS_API_URL,
      })
      .then((data) => {
        console.log(data);
      });
  };
}

export function callGetEnvironments(tenantId, accountId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`${tenantId}/${accountId}/environments`, {
        endpoint: config.TRANS_API_URL,
      })
      .then((data) => {
        const envs = data.map((e) => {
          dispatch(getEnvironmentOverview(e.tenantId, e.appId, e.name));
          return {
            ...e,
            environmentId: e.id,
          };
        });
        dispatch({
          type: types.SET_TRANS_ENVIRONMENTS,
          data: envs,
        });
        return envs;
      });
  };
}
export function getEnvironmentDependencies(tenantId, appId, envId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`${tenantId}/${appId}/environment/deps/${envId}`, {
        endpoint: config.TRANS_API_URL,
      })
      .then((data) => {
        return data;
      });
  };
}
export function getEnvironmentOverview(tenantId, appId, envName) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`${tenantId}/${appId}/environments/${envName}/overview`, {
        endpoint: config.TRANS_API_URL,
      })
      .then((data) => {
        dispatch({
          type: types.SET_ENVIRONMENTS_OVERVIEW,
          data: data,
        });
      });
  };
}

export function createNewEnvironment(tenantId, appId, name, isProd) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .post(`${tenantId}/${appId}/environments`, {
        endpoint: config.TRANS_API_URL,
        data: {
          name: name,
          isProd: isProd,
        },
      })
      .then((env) => {
        dispatch(callGetEnvironments(tenantId, appId));
        return env;
      });
  };
}

export function deleteEnvironment(tenantId, appId, envId) {
  return (dispatch, getState, api) => {
    const { config, project } = getState();
    const envs = project.environments.filter((e) => {
      return e.id !== envId;
    });
    dispatch({
      type: types.SET_TRANS_ENVIRONMENTS,
      data: envs,
    });
    return api
      .delete(`${tenantId}/${appId}/environments/${envId}`, {
        endpoint: config.TRANS_API_URL,
      })
      .then(() => {
        return dispatch(callGetEnvironments(tenantId, appId));
      });
  };
}

export function saveChangeToEnv(tenantId, appId, env, envName, color, isProd) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .put(`${tenantId}/${appId}/environments/${env.environmentId}`, {
        endpoint: config.TRANS_API_URL,
        data: {
          id: env.environmentId,
          name: envName || env.name,
          color: color || env.color || "#404040",
          isProd: isProd,
          index: env.index,
        },
      })
      .then(() => {
        return dispatch(callGetEnvironments(tenantId, appId));
      });
  };
}

export function changeEnvironmentIndexes(tenantId, appId, iStart, iEnd) {
  return (dispatch, getState, api) => {
    const { config, project } = getState();
    const envs = [...project.environments];
    envs.splice(iEnd, 0, envs.splice(iStart, 1)[0]);
    const envIndexes = envs.map((env, i) => {
      return {
        id: env.id,
        index: i,
      };
    });
    dispatch({
      type: types.SET_TRANS_ENVIRONMENTS,
      data: envs,
    });
    return api
      .put(`${tenantId}/${appId}/environmentorder`, {
        endpoint: config.TRANS_API_URL,
        data: envIndexes,
      })
      .then(() => {
        return dispatch(callGetEnvironments(tenantId, appId));
      });
  };
}

export function setSelectedEnv(envName) {
  return (dispatch, getState, api) => {
    dispatch({
      type: types.SET_SELECTED_ENV,
      data: envName,
    });
  };
}

export function getFileSchemaCountForEnvironment(
  tenantId,
  appId,
  providerConfig,
  env,
  creds,
  isIntro
) {
  const { type, credentials, credentialType } =
    providerConfig;
  return (dispatch, getState, api) => {
    if (!isIntro) {
      dispatch(setSingleCredJson(creds, type, credentialType));
    }
    const { config } = getState();
    return api
      .get(`${tenantId}/${accountId}/environments/${envName}/fileSchemaCount`, {
        endpoint: config.TRANS_API_URL,
      })
      .then((data) => {
        const count = data.count;
        data.push(emptyLocalFile);
        dispatch({
          type: types.SET_ENV_CREDS,
          data,
        });
        return data;
      });
  };
}

export function getAllCredentialsForEnvironment(
  tenantId,
  accountId,
  envName,
  skipRedux
) {
  return (dispatch, getState, api) => {
    if (!skipRedux) {
      dispatch({
        type: types.SET_ENV_CREDS,
        data: null,
      });
    }
    const { config } = getState();
    return api
      .get(`${tenantId}/${accountId}/environments/${envName}/credentials`, {
        endpoint: config.TRANS_API_URL,
      })
      .then((data) => {
        const localFileIndex = data.findIndex(
          (x) => x.credentialType === "LocalFile"
        );
        const emptyLocalFile = {
          credentialType: "LocalFile",
          credentials: "",
          credentialsJson: {},
          providerLabel: "LocalFile",
        };
        if (localFileIndex > -1) {
          data[localFileIndex] = emptyLocalFile;
        } else {
          data.push(emptyLocalFile);
        }
        dispatch({
          type: types.SET_ENV_CREDS,
          data,
        });
        return data;
      });
  };
}

export function toggleEnvironmentPause(tenantId, appId, environment, data) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api
      .put(`${tenantId}/${appId}/pauseenvironment/${environment.id}`, {
        endpoint: TRANS_API_URL,
        data,
      })
      .then((d) => {
        dispatch(callGetEnvironments(tenantId, appId));
      });
  };
}

export function clearOauthCreds(providerType) {
  return (dispatch, getState) => {
    const { project } = getState();
    const creds = project.environmentCredentials.map((c) => {
      if (c.providerLabel === providerType) {
        return {
          ...c,
          credentialsJson: {
            ...(c && c.credentialsJson),
            userName: "_",
          },
        };
      }
      return c;
    });
    dispatch({
      type: types.SET_ENV_CREDS,
      data: creds,
    });
  };
}

export function checkOauthCreds(
  tenantId,
  appId,
  envName,
  providerType,
  connectionKey
) {
  return (dispatch, getState, api) => {
    const { config, project } = getState();
    return api
      .get(
        `${tenantId}/${appId}/credentials/${providerType}/${envName}/${connectionKey}`,
        {
          endpoint: config.TRANS_API_URL,
        }
      )
      .then((data) => {
        const creds = project.environmentCredentials.map((c) => {
          if (c.providerLabel === providerType) {
            return data;
          }
          return c;
        });
        if (creds.some((c) => c.providerLabel != providerType)) {
          creds.push(data);
        }
        dispatch({
          type: types.SET_ENV_CREDS,
          data: creds,
        });
        return data;
      });
  };
}

export function addNewCredentialToEnvironment(providerConfig) {
  const { type, credentialType } = providerConfig;
  return (dispatch, getState) => {
    const { project } = getState();
    const envCreds = [...project.environmentCredentials];
    envCreds.push({
      credentialType,
      credentials: {},
      credentialsJson: {},
      providerLabel: type,
      wasJustAdded: true,
    });
    dispatch({
      type: types.SET_ENV_CREDS,
      data: envCreds,
    });
  };
}

export function setAdapterSecret(adapterSecret) {
  return (dispatch, getState) => {
    const { project } = getState();
    const envCreds = [...project.environmentCredentials];
    envCreds.push({
      credentialType: "AdapterSecret",
      credentials: adapterSecret,
      providerLabel: "AdapterSecret",
    });
    dispatch({
      type: types.SET_ENV_CREDS,
      data: envCreds,
    });
  };
}

export function setEnvCreds(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ENV_CREDS,
      data,
    });
  };
}

export function clearCredentialsForEnvironment() {
  return {
    type: types.SET_ENV_CREDS,
    data: null,
  };
}

export function removeJustAddedCreds() {
  return (dispatch, getState) => {
    const { project } = getState();
    const envCreds = [...project.environmentCredentials];
    const actualCreds = envCreds.filter((e) => !e.wasJustAdded);
    dispatch({
      type: types.SET_ENV_CREDS,
      data: actualCreds,
    });
  };
}

export function setSingleCredJson(
  creds,
  providerType,
  credentialType,
  connectionKey
) {
  return (dispatch, getState) => {
    const { project } = getState();
    const envCreds = [...project.environmentCredentials];
    const i = envCreds.findIndex((ec) => {
      return ec.key === connectionKey;
    });
    if (i >= 0) {
      envCreds[i] = {
        credentialType,
        credentialsJson: creds,
        providerLabel: providerType,
        wasJustAdded: true,
        key: connectionKey,
      };
    } else {
      envCreds.push({
        credentialType,
        credentialsJson: creds,
        providerLabel: providerType,
        wasJustAdded: true,
        key: connectionKey,
      });
    }
    dispatch({
      type: types.SET_ENV_CREDS,
      data: envCreds,
    });
  };
}

export function checkProviderMetadata(
  tenantId,
  appId,
  providerConfig,
  env,
  creds,
  isIntro,
  connectorKey,
  connectorName
) {
  const { type, metadataEndpoint, credentials, credentialType } =
    providerConfig;
  return (dispatch) => {
    if (!isIntro) {
      dispatch(setSingleCredJson(creds, type, credentialType));
    }
    let metadata = null;
    const isOauth = credentials && credentials[0] === "Oauth";
    const isLocalFile = credentialType === "LocalFile";
    return (
      dispatch(tryCheckConnection(tenantId, appId, providerConfig, creds, env))
        .then(() => {
          const schemaGuid = creds.schemaGuid; // for flat file schema
          const paginate = { limit: 20, offset: 0 }; // for flat file schema
          return dispatch(
            callGetProviderMetadata(
              tenantId,
              appId,
              providerConfig,
              creds.rootUrl,
              isOauth,
              env.name,
              schemaGuid,
              creds,
              connectorKey,
              paginate
            )
          );
        })
        // .then((m) => {
        //   metadata = m;
        //   if (isOauth || isIntro || isLocalFile) return Promise.resolve(null);
        //   return dispatch(
        //     postCredentials(
        //       tenantId,
        //       appId,
        //       providerConfig,
        //       creds,
        //       env.environmentId,
        //       false,
        //       connectorKey,
        //       connectorName,
        //       connectorKey
        //     )
        //   );
        // })
        // .then(() => {
        //   if (metadataEndpoint) {
        //     // only parse metadata if metadata endpoint exists
        //     return util.metadataFunctions(metadata, type);
        //   }
        //   return null;
        // })
        .catch((e) => {
          throw e;
        })
    );
  };
}

export function checkProviderMetadataCount(
  tenantId,
  appId,
  providerConfig,
  env,
  creds
) {
  const { type, metadataEndpoint, credentials, credentialType } =
    providerConfig;
  return (dispatch) => {
    let metadata = null;
    const isLocalFile = credentialType === "LocalFile";
    return (
      dispatch(tryCheckConnection(tenantId, appId, providerConfig, creds, env))
        .then(() => {
          return dispatch(
            callGetProviderMetadataCount(
              tenantId,
              appId,
              providerConfig,
              env.name,
              creds
            )
          );
        })
        .catch((e) => {
          throw e;
        })
    );
  };
}


// "fake" credentials for demo purposes
function isDemoCreds(creds, providerType) {
  if (creds && providerType) {
    switch (providerType) {
      case "Blackboard":
        if (
          creds.key === "6c53d83209834b0e982c0984a5c11bdc" &&
          creds.secret === "16d2ba71f8fa4f13a74f2264c47f6244"
        )
          return true;
        return false;
      case "Canvas":
        if (creds.accessToken === "7d4fb8b1afb540049ad2a7268b78da07")
          return true;
        return false;
      default:
        return false;
    }
  }
  return false;
}
export function tryCheckConnection(
  tenantId,
  appId,
  providerConfig,
  creds,
  env,
  connectorKey
) {
  const { type, credentialType, metadataApi, skipCheckConnection } =
    providerConfig;
  return (dispatch, getState, api) => {
    if (skipCheckConnection || isDemoCreds(creds, type)) {
      return Promise.resolve(null);
    }
    const { config } = getState();
    if (
      metadataApi === "TRANS_API_URL" &&
      providerConfig.type === "LingkWebSocket"
    ) {
      var envId = env.environmentId;
      return api.post(
        `${tenantId}/${appId}/${envId}/testconnection/${type.toLowerCase()}`,
        {
          endpoint: config.TRANS_API_URL,
          data: {
            credentialType: credentialType,
            credentialsJson: creds,
            providerLabel: type,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
    if (metadataApi === "TEST_CONN_API_URL") {
      return api.post(
        `${tenantId}/${appId}/testconnection/${type.toLowerCase()}`,
        {
          endpoint: config.TEST_CONN_API_URL,
          data: {
            credentialType: credentialType,
            credentialsJson: creds,
            providerLabel: type,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
    if (metadataApi === "SF_AGENT_API_URL") {
      return api.get(
        `${tenantId}/${appId}/environments/${env.name}/${connectorKey}/testconnection`,
        {
          endpoint: config.SF_AGENT_API_URL,
        }
      );
    }
    if (credentialType === "Ethos") {
      return api.post(
        `${tenantId}/${appId}/${env.id}/testconnection/${type.toLowerCase()}`,
        {
          endpoint: config.TRANS_API_URL,
          data: {
            credentialType,
            credentialsJson: creds,
            providerLabel: type,
            key: connectorKey,
          },
        }
      );
    }
    return api.post(
      `${tenantId}/${appId}/testconnection/${type.toLowerCase()}`,
      {
        endpoint: config.TRANS_API_URL,
        data: {
          credentialType,
          credentialsJson: creds,
          providerLabel: type,
        },
      }
    );
  };
}

function addLeadingSlashToSftpPath(credsJson) {
  const creds = { ...credsJson };
  if (creds.path && creds.path[0] !== "/") {
    creds.path = "/" + creds.path;
  }
  if (creds.rootUrl) {
    creds.endpoint = creds.rootUrl;
  }
  if (creds.databaseType) {
    creds.databaseType = creds.databaseType.toLowerCase();
  }
  return creds;
}

export function callGetProviderMetadata(
  tenantId,
  appId,
  providerConfig,
  rootUrl,
  isOauth,
  envName,
  schemaGuid,
  creds,
  connectorKey,
  paginate
) {
  const { metadataEndpoint, metadataApi, type, credentialType } =
    providerConfig;
  const isFlatFile = credentialType === "LocalFile";
  if (!metadataEndpoint) {
    return () => Promise.resolve(null);
  }
  return (dispatch, getState, api) => {
    const { config } = getState();
    // 'testconnection ' GET route is for adapter only. Should be called 'adaptermetadata'
    let isAdapter = false;
    let urlSuffix = metadataEndpoint;
    if (urlSuffix === "adaptermetadata") {
      urlSuffix = `adaptermetadata?url=${rootUrl}`;
      isAdapter = true;
    }
    /*if(urlSuffix==='fileschema'){
      urlSuffix = `fileschema?schemaGuid=${schemaGuid}`
    }*/
    if (urlSuffix === "schema/swagger/moodle" && creds.swaggerUrl) {
      urlSuffix = `schema/swagger/moodle?swaggerUrl=${creds.swaggerUrl}`;
    }
    if (metadataApi === "TEST_CONN_API_URL") {
      const credentialsJson = addLeadingSlashToSftpPath(creds);
      return api.post(`${tenantId}/${appId}/${metadataEndpoint}`, {
        endpoint: config[metadataApi],
        data: {
          credentialType: credentialType,
          credentialsJson: credentialsJson,
          providerLabel: type,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
    } else {
      if (isOauth) {
        return api.get(
          `${tenantId}/${appId}/environments/${envName}/${connectorKey}/${urlSuffix}`,
          {
            endpoint: config[metadataApi],
          }
        );
      } else if (isAdapter || type === "Ethos") {
        return api.get(
          `${tenantId}/${appId}/environments/${envName}/${urlSuffix}`,
          {
            endpoint: config[metadataApi],
          }
        );
      } else if (isFlatFile) {
        return api.get(
          `${tenantId}/${appId}/environments/${envName}/${urlSuffix}?limit=${paginate.limit}&offset=${paginate.offset}`,
          {
            endpoint: config[metadataApi],
          }
        );
      }
      return api.get(`${tenantId}/${appId}/${urlSuffix}`, {
        endpoint: config[metadataApi],
      });
    }
  };
}


export function callGetProviderMetadataCount(
  tenantId,
  appId,
  providerConfig,
  envName,
) {
  const { metadataEndpoint, metadataApi, type, credentialType } =
    providerConfig;
  const isFlatFile = credentialType === "LocalFile";
  if (!metadataEndpoint) {
    return () => Promise.resolve(null);
  }
  return (dispatch, getState, api) => {
    const { config } = getState();
    // 'testconnection ' GET route is for adapter only. Should be called 'adaptermetadata'
    let urlSuffix = metadataEndpoint;
    if (isFlatFile) {
      return api.get(
        `${tenantId}/${appId}/environments/${envName}/fileschemacount`,
        {
          endpoint: config[metadataApi],
        }
      );
    }
    return api.get(`${tenantId}/${appId}/${urlSuffix}`, {
      endpoint: config[metadataApi],
    });

  };
}

// mark localfile connector isSource/isDest

export function markLocalFileSrcOrDest(wizard) {
  return (dispatch, getState, api) => {
    const { project } = getState();
    const envCreds = [...project.environmentCredentials];
    const i = envCreds.findIndex((ec) => {
      return ec.credentialType === "LocalFile";
    });
    const cred = envCreds.find((ec) => {
      return ec.credentialType === "LocalFile";
    });
    if (cred) {
      envCreds[i] = {
        credentialType: cred.credentialType,
        credentialsJson: cred.credentials,
        providerLabel: cred.type,
        key: cred.connectorKey,
        ApiAccessible: cred.APIAcess,
        connectionName: cred.connectorName,
        defaultConnection: cred.defaultConnection,
        wizard: wizard,
      };
    }
    dispatch({
      type: types.SET_ENV_CREDS,
      data: envCreds,
    });
  };
}

export function postCredentials(
  tenantId,
  appId,
  providerConfig,
  credentials,
  envId,
  APIAcess,
  orginalKey,
  connectorName,
  connectorKey,
  defaultConnection,
  wizard
) {
  const { type, credentialType } = providerConfig;
  return (dispatch, getState, api) => {
    const { config, project } = getState();
    return api
      .post(
        `${tenantId}/${appId}/environments/${envId}/${orginalKey}/credentials`,
        {
          endpoint: config.TRANS_API_URL,
          data: {
            credentialType,
            credentialsJson: credentials,
            providerLabel: type,
            ApiAccessible: APIAcess,
            connectionName: connectorName,
            key: connectorKey,
            defaultConnection,
          },
        }
      )
      .then(() => {
        const { project } = getState();
        const envCreds = [...project.environmentCredentials];
        const i = envCreds.findIndex((ec) => {
          return ec.key === connectorKey;
        });
        if (i >= 0) {
          envCreds[i] = {
            credentialType,
            credentialsJson: credentials,
            providerLabel: type,
            key: connectorKey,
            ApiAccessible: APIAcess,
            connectionName: connectorName,
            defaultConnection: defaultConnection,
            wizard: wizard,
          };
        } else {
          envCreds.push({
            credentialType,
            credentialsJson: credentials,
            providerLabel: type,
            key: connectorKey,
            ApiAccessible: APIAcess,
            connectionName: connectorName,
            defaultConnection: defaultConnection,
            wizard: wizard,
          });
        }
        dispatch({
          type: types.SET_ENV_CREDS,
          data: envCreds,
        });
      });
  };
}

export function generateNewAdapterSecret(tenantId, accountId, envName) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { TRANS_API_URL } = config;
    return api.post(
      `${tenantId}/${accountId}/credentials/AdapterSecret/environment/${envName}/generate`,
      {
        endpoint: TRANS_API_URL,
      }
    );
  };
}

export function getOauthUrl(
  tenantId,
  appId,
  typeGuid,
  orgType,
  envName,
  connectionKey
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.get(
      `${tenantId}/${appId}/environments/${envName}/oauthurl/${typeGuid}/${orgType}?key=${connectionKey}`,
      {
        endpoint: config.SF_AGENT_API_URL,
      }
    );
  };
}

export function getDocuSignOauthUrl(
  tenantId,
  appId,
  typeGuid,
  orgType,
  envName,
  connectionKey
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api.get(
      `${tenantId}/${appId}/environments/${envName}/oauthurl/${typeGuid}/${orgType}?key=${connectionKey}`,
      {
        endpoint: config.TRANS_API_URL,
      }
    );
  };
}
export function setCsvFields(
  tenantId,
  appId,
  tenantKey,
  appKey,
  metadata,
  contents,
  providerType,
  envId
) {
  let d = null;
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .post(`${tenantId}/${appId}/${envId}/uploadpolicy`, {
        endpoint: config.TRANS_API_URL,
        data: { name: "localfile", resources: metadata, providerType },
      })
      .then((data) => {
        d = data;
        return dispatch(
          uploadFilesToS3(tenantKey, appKey, envId, metadata, data, contents)
        );
      })
      .then(() => {
        return dispatch(
          postCredentials(
            tenantId,
            appId,
            { type: "LocalFile", credentialType: "LocalFile" },
            d.guids,
            envId,
            false
          )
        );
      })
      .then(() => {
        return d;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function deleteFlatFile(tenantId, appId, envId, fileName) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .delete(`${tenantId}/${appId}/${envId}/deletefile/${fileName}`, {
        endpoint: config.TRANS_API_URL,
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getDataMechanicsTemplates() {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get("config-templates", {
        endpoint: config.TRANS_API_URL,
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log("Error occured while fetching DM templates", err);
      });
  };
}

export function callFetchFileSchemas(tenantId, appId, envName, paginate) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`${tenantId}/${appId}/environments/${envName}/fileschema?limit=${paginate.limit}&offset=${paginate.offset}`, {
        endpoint: config.TRANS_API_URL,
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function callFetchFileSchemaCount(tenantId, appId, envName) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`${tenantId}/${appId}/environments/${envName}/fileschemacount`, {
        endpoint: config.TRANS_API_URL,
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function uploadFilesToS3(
  tenantKey,
  appKey,
  envId,
  metadata,
  d,
  contents
) {
  const args = contents.map((fileContent, i) => {
    return {
      tenantKey,
      appKey,
      envId,
      fileContent,
      d,
      fileName: metadata[i].name,
    };
  });
  return () => {
    return Promise.all(args.map(singleFileToS3));
  };
}

export function singleFileToS3({
  tenantKey,
  appKey,
  envId,
  d,
  fileContent,
  fileName,
}) {
  /*if(!d.guids.files[fileName]){
    return () => Promise.resolve(null)
  }*/
  let exist = fileName.includes("+");
  if (exist) {
    fileName = encodeURIComponent(fileName);
  }
  const p = JSON.parse(atob(d.policy));
  const pFind = (s) =>
    p.conditions.find((pf) => Object.keys(pf).includes(s))[s];
  const fd = new FormData();
  fd.append("key", `${tenantKey}/${appKey}/${envId}/${fileName}`);
  fd.append("policy", d.policy);
  fd.append("acl", pFind("acl"));
  fd.append("success_action_status", pFind("success_action_status"));
  fd.append("x-amz-credential", pFind("x-amz-credential"));
  fd.append("x-amz-algorithm", pFind("x-amz-algorithm"));
  fd.append("x-amz-date", pFind("x-amz-date"));
  fd.append("x-amz-signature", d.signature);
  fd.append("file", fileContent);
  return axios({
    method: "post",
    url: `https://${pFind("bucket")}.s3.amazonaws.com/`,
    data: fd,
    config: { headers: { "Content-Type": "multipart/form-data" } },
  });
}

export function downloadFileFromAws(
  tenantId,
  appId,
  tenantKey,
  appKey,
  envId,
  file
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    return api
      .get(`${tenantId}/${appId}/${envId}/downloadpolicy/${file.name}`, {
        endpoint: config.TRANS_API_URL,
      })
      .then((d) => {
        downloadAs(d, file.name);
      });
  };
}

export function downloadExecutionLogsFromS3(
  tenantId,
  appId,
  envId,
  date,
  recipeId,
  executionGUID
) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const fileName = executionGUID + ".zip"
    return api
      .get(`${tenantId}/${appId}/${envId}/executionlogs/${date}/${recipeId}/${fileName}`, {
        endpoint: config.TRANS_API_URL,
      })
      .then((d) => {
        downloadAs(d, fileName);
      });
  };
}

const downloadAs = (url, name) => {
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    })
    .then((response) => {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = name;
      a.click();
    })
    .catch((err) => {
      console.log("error", err);
    });
};