import React, { useState, useEffect, useRef, Fragment } from "react";
import images from "../images";
import CSSTransitionGroup from "react-addons-css-transition-group";
import LogResultTable from "./logResultTable";
import NewWindow from "react-new-window";
import { Link, useLocation, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import moment from "moment";

function ExecutionLogsNewWindow(props) {
  const jobsRef = useRef();
  const location = useLocation();
  const { match, redux } = props;
  const { project } = redux;
  const { params } = match;
  const { appId } = params;
  const [logs, setLogs] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [openJobsInfo, setOpenJobsInfo] = useState(false);
  const [externalExecutionId, setExternalExecutionId] = useState("");
  const [environmentId, setEnvironmentId] = useState("");
  const [recipe, setRecipe] = useState(null);

  const env =
    project &&
    project.environments &&
    project.environments.find((e) => e.id == environmentId);
  const [stepId, setStepId] = useState("");
  const [executionDetails, setExecutionDetails] = useState({});
  let isWiz;
  let timer;
  let succeeded;

  function formatDate(date) {
    let splitstr = date.split('T')
    let splitDate = splitstr[0].split('-')
    return splitDate[2] + splitDate[1] + splitDate[0]
  }

  function downloadLogsFromS3(execution) {    
    const { actions, match, redux } = props;
    const { params } = match;
    const { appId } = params;
    const { project } = redux;
    return actions.downloadExecutionLogsFromS3(
      project.tenantInfo.TenantId,
      appId,
      execution.environmentId,
      formatDate(execution.finishTime),
      execution.transId,
      execution.executionGUID
    );
  };

  if (executionDetails && executionDetails.executionMetadata) {
    isWiz =
      executionDetails.executionMetadata.generatedFromPi == "" ||
      !executionDetails.executionMetadata.generatedFromPi
        ? false
        : true;
    timer = new Date(executionDetails.timer * 1000)
      .toISOString()
      .substr(11, 11);
    if (timer.substr(0, 2) == "00") {
      timer = timer.substr(3, timer.length);
    }
    if (timer.substr(0, 1) == "0") {
      timer = timer.substr(1, timer.length);
    }

    if (
      executionDetails.statusText != "" &&
      executionDetails.statusText.includes("partial")
    ) {
      succeeded = executionDetails.statusText;
    } else {
      succeeded = executionDetails.succeeded.toString();
    }
  }
  useEffect(() => {
    const { actions, redux } = props;
    const queryParams = new URLSearchParams(location.search);
    const { project } = redux;
    let executionGuid;
    if (queryParams.has("executionGuid")) {
      executionGuid = queryParams.get("executionGuid");
    }
    if (queryParams.has("env")) {
      let envId = queryParams.get("env");
      setEnvironmentId(envId);
    }

    actions
      .fetchExecutionDatabyGuid(project.tenantInfo.TenantId, executionGuid)
      .then((data) => {
        if (data && data.length > 0) setExecutionDetails(data[0]);
        if (executionGuid) {
          setExternalExecutionId(executionGuid);
          actions
            .callGetSelectedTransformerLogsDEV(
              project.tenantInfo.TenantId,
              executionGuid,
              "",
              ""
            )
            .then((data) => {
              setShowLoader(false);
              setLogs(data);
            });
          if (data[0] && data[0].executionMetadata) {
            let baseId = parseInt(data[0].executionMetadata.baseId, 10);
            actions.callGetTransformers(appId).then((data) => {
              let recipe = data.find((d) => d.BaseId == baseId);
              setRecipe(recipe);
            });
          }
        }
      });
    const jobInterval = setInterval(() => {
      if (jobsRef.current && jobsRef.current.released && openJobsInfo) {
        setOpenJobsInfo(false);
      }
    }, 1000);
    return () => {
      clearInterval(jobInterval);
    };
  }, [openJobsInfo]);

  return (
    <CSSTransitionGroup
      transitionName="logs"
      transitionEnterTimeout={250}
      transitionLeaveTimeout={250}
    >
      {!showLoader ? (
        <div
          className="logs"
          style={{
            maxHeight: "100%",
            display: "inline-block",
          }}
        >
          {logs && logs.length > 0 && (
            <div className="fix-execution">
            <ReactTooltip place={"bottom"} />
              <div
                style={{
                  marginBottom: 1,
                  position: "relative",
                  float: "left",
                  backgroundColor: "#efefef",
                  marginRight: 10,
                  fontSize: 14,
                  width: "100%",
                  padding: "6px 8px 8px 5px",
                }}
              >
                {succeeded == "true" ? (
                  <span
                    className="notifyStylePopWindow"
                    style={{ background: "green" }}
                  />
                ) : succeeded == "false" ? (
                  <span
                    className="notifyStylePopWindow"
                    style={{ background: "#d9534f" }}
                  >
                    <span className="PopupExclamation">!</span>
                  </span>
                ) : (
                  succeeded &&
                  succeeded != "" &&
                  succeeded.includes("partial") && (
                    <span
                      className="notifyStylePopWindow"
                      style={{ background: "yellow" }}
                    />
                  )
                )}
                {moment(executionDetails.startTime).format("MMM Do, h:mm:ss a")}
                &nbsp;&nbsp;&nbsp;
                <strong>Recipe:</strong>&nbsp;
                {executionDetails && executionDetails.executionMetadata && (
                  <Fragment>
                    <Link
                      to={{
                        pathname: isWiz
                          ? `/a/${props.match.params.appId}/wiz/${executionDetails.executionMetadata.baseId}`
                          : `/a/${props.match.params.appId}/tf/${executionDetails.executionMetadata.baseId}`,
                      }}
                      params={{
                        piGuid: isWiz
                          ? executionDetails.executionMetadata.generatedFromPi
                          : null,
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {recipe && recipe.Name}
                    </Link>
                    <span> ({executionDetails.executionMetadata.baseId})</span>{" "}
                  </Fragment>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <strong>Total Timer Id:</strong> {timer} {}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <strong>Recipe Version:</strong>&nbsp;
                {executionDetails.recipeVersionNumber}&nbsp;&nbsp;&nbsp;&nbsp;
                {env && env.name && (
                  <Fragment>
                    {" "}
                    <strong>Environment</strong>&nbsp;&nbsp;
                    <div
                      className="env-dropdown-color-box"
                      style={{
                        background: env.color || "#404040",
                        border: "1px solid black",
                        marginRight: 4,
                        marginLeft: 2,
                      }}
                    />
                    <span>{env.name}</span>
                  </Fragment>
                )}{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <strong>Id:</strong> {executionDetails.externalExecutionId}{" "}                
                <img                
                data-tip={"Download Execution logs"}
                src={images.ic_file_download_black_48dp}
                height={23}
                style={{ opacity: "0.6", marginLeft: 3, marginRight: 10, cursor: "pointer"}}                
                onClick={() => {
                  downloadLogsFromS3(executionDetails)
                }}
              />


              </div>

              <div
                style={{
                  marginBottom: 1,
                  position: "relative",
                  float: "left",
                  backgroundColor: "#CCCDCF",
                  marginRight: 10,
                  fontSize: 14,
                  width: "100%",
                  padding: "6px 8px 8px 5px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    width: "30%",
                    marginLeft: "1%",
                  }}
                >
                  <strong>Statement:</strong>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    width: "42%",
                    marginLeft: "1%",
                  }}
                >
                  <strong>Result:</strong>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    width: "11%",
                    marginLeft: "1%",
                  }}
                >
                  <strong>Rows Read:</strong>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    width: "13%",
                    marginLeft: "1%",
                  }}
                >
                  <strong>Timer:</strong>
                </div>
              </div>
            </div>
          )}

          {logs && logs.length > 0 ? (
            <div
              className="logs-scroller-tab"
              style={{
                maxHeight: "88vh",
                marginBottom: 20,
                marginTop: 34,
              }}
            >
              {logs.map((log, i) => {
                const isSchema = log.statement.includes("printschema");
                const isTable =
                  !isSchema &&
                  (log.statement.includes("print") ||
                    log.statement.includes("PRINT"));
                const jobIds = log.extendedProperties;

                return (
                  <div className="selected-transformer-result-logs" key={i}>
                    <div
                      style={{
                        display: "inline-block",
                        width: "30%",
                        marginLeft: "1%",
                      }}
                    >
                      {log.statement}
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "42%",
                        wordWrap: "break-word",
                        marginLeft: "1%",
                        verticalAlign: "bottom",
                      }}
                    >
                      {isTable ? (
                        <LogResultTable result={log.result} />
                      ) : isSchema ? (
                        <pre>{log.result}</pre>
                      ) : jobIds && jobIds != "" ? (
                        <span>
                          {`${log.result}`}&nbsp;(
                          <span
                            className="span-as-link"
                            onClick={() => {
                              setOpenJobsInfo(true);
                              setStepId(log.stepGuid);
                            }}
                          >
                            details
                          </span>
                          )
                        </span>
                      ) : (
                        `${log.result}`
                      )}
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "11%",
                        marginLeft: "1%",
                      }}
                    >
                      {log.recordsRead > 0 && log.recordsRead}
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "13%",
                        marginLeft: "1%",
                      }}
                    >
                      {log.timer.toFixed(5)}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <span>No Data Found.</span>
          )}
        </div>
      ) : (
        <img
          alt="spinner"
          src={images.ajax_loader}
          height="18"
          style={{ margin: 10, marginLeft: 100 }}
        />
      )}
      {openJobsInfo && (
        <NewWindow
          copyStyles={false}
          ref={jobsRef}
          name={`SF Jobs ${externalExecutionId}`}
          title={`SF Jobs ${externalExecutionId}`}
          features={{ noopener: "_blank" }}
          url={`/a/${appId}/health/sfjobs?step=${stepId}&env=${environmentId}`}
        />
      )}
    </CSSTransitionGroup>
  );
}

export default withRouter(ExecutionLogsNewWindow);
