import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ConfirmDeleteModal from "../utils/confirmDeleteModal";
import images from "../images";
import clipboard from "../utils/clipboard";

function getColorByBgColor(bgColor) {
  if (!bgColor) {
    return "";
  }
  return parseInt(bgColor.replace("#", ""), 16) > 0xffffff / 2
    ? "#000"
    : "#fff";
}

export default class Subscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      selectedForDelete: null,
      isDeleting: null,
      deleteIsObject: true,
      viewMode: 0,
      listMode: true,
      fieldCopied: null,
    };

    this.newSubscription = this.newSubscription.bind(this);
    this.deleteSubscription = this.deleteSubscription.bind(this);
    this.warnBeforeDelete = this.warnBeforeDelete.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
    this.changeViewMode = this.changeViewMode.bind(this);
    this.changeListMode = this.changeListMode.bind(this);
  }

  componentWillMount() {
    const { actions, redux, match } = this.props;
    const { params } = match;
    const { project } = redux && redux;
    const { appId } = params;

    actions.callGetTriggerSubscriptions(appId);
    if (project && project.tenantInfo)
      actions.callGetWebhookSubscriptions(
        project.tenantInfo.TenantId,
        params.appId
      );

    //updateTab()
  }

  newSubscription() {
    const { appId } = this.props.match.params;
    this.props.history.push(`/a/${appId}/events/sub/new`);
  }

  warnBeforeDelete(a, e, isObject) {
    this.setState({ selectedForDelete: e, deleteIsObject: isObject });
    this.triggerModal();
  }

  changeViewMode() {
    let { viewMode } = this.state;

    viewMode += 1;

    if (viewMode > 1) {
      viewMode = 0;
    }

    this.setState({ viewMode });
  }

  sendToCreate = (o) => {
    console.log(o);
  };

  getObjName = (o) => {
    let index = o.lastIndexOf(".");
    let objName = o.substring(index + 1, o.length);
    return objName;
  };
  changeListMode() {
    let { listMode } = this.state;
    listMode = !listMode;
    this.setState({ listMode });
  }

  triggerModal() {
    let modal = this.state.modal;
    modal = !modal;
    this.setState({ modal });
  }

  deleteSubscription() {
    const { selectedForDelete, deleteIsObject } = this.state;
    const { actions } = this.props;
    const { project } = this.props.redux;
    const tenantId = project.tenantInfo.TenantId;
    const { appId } = this.props.match.params;

    if (deleteIsObject) {
      actions.callDeleteWebhookSubscription(
        tenantId,
        appId,
        selectedForDelete.data.subscriptionGuid
      );
      this.triggerModal();
      this.setState({ selectedForDelete: null, isDeleting: selectedForDelete });
    } else {
      this.triggerModal();
      this.setState({ selectedForDelete: null, isDeleting: selectedForDelete });
      const func = () => {
        actions
          .callClearTriggerSubscription(
            selectedForDelete.BaseId,
            selectedForDelete.EnvironmentId
          )
          .then(() => {
            actions.callGetTriggerSubscriptions(appId);
          });
      };

      func();
    }
  }

  render() {
    const { events } = this.props.redux;
    const { webhookSubscriptions, allTriggers } = events && events;
    const { project } = this.props.redux;
    const { viewMode, listMode, isDeleting, deleteIsObject } = this.state;

    let subscribedObjects = [];
    let subscribedEnvironments = [];
    let subscribedTriggers = [];
    let subscribedTriggerEnvironments = [];

    let categories = [];
    let triggerCategories = [];

    if (viewMode === 0) {
      //triggers

      allTriggers &&
        allTriggers.map((o) => {
          if (
            !subscribedTriggers.includes(o.FilePattern) &&
            o.FilePattern !== ""
          ) {
            subscribedTriggers.push(o.FilePattern);
          }
        });

      if (subscribedTriggers.length > 0) {
        subscribedTriggers.map((o, i) => {
          triggerCategories.push(
            <div className="object-category-envelope" key={i}>
              <div className="objects-title">
                Subscribed to File Upload:
                <img
                  role="presentation"
                  src={images.ic_file_upload_black_48dp}
                  style={{ height: 18, marginTop: -5, marginRight: 3 }}
                />
                <b
                  style={{ cursor: "pointer" }}
                  onClick={() => this.sendToCreate(o)}
                >
                  {o}
                </b>
              </div>

              <div className="subscribers">
                {allTriggers.map((s, ii) => {
                  const transinfo =
                    project &&
                    project.transformers &&
                    project.transformers.find((f) => f.BaseId === s.BaseId);
                  const envinfo =
                    project &&
                    project.environments &&
                    project.environments.find((f) => f.id === s.EnvironmentId);
                  const transVersion =
                    project &&
                    project.allDeployments &&
                    project.allDeployments.find(
                      (f) =>
                        f.baseId === s.BaseId &&
                        f.environmentId === s.EnvironmentId
                    );

                  if (s.FilePattern === o) {
                    if (s.BaseId) {
                      //if it's a recipe sub
                      return (
                        <div
                          key={ii}
                          className={
                            isDeleting &&
                            !deleteIsObject &&
                            isDeleting.BaseId === s.BaseId
                              ? "subscription-item sub-deleting"
                              : "subscription-item"
                          }
                        >
                          <img
                            src={images.ic_clear_black_48dp}
                            onClick={() =>
                              this.warnBeforeDelete(null, s, false)
                            }
                            className="delete-sub-icon"
                            alt="clear"
                          />

                          <div
                            style={{ display: "inline-block", marginRight: 4 }}
                          >
                            <span style={styles.subscriber}>
                              {transinfo && transinfo.Name}
                            </span>
                            &nbsp;
                            <span
                              style={{ display: "inline-block", fontSize: 10 }}
                            >
                              ver.{" "}
                              {transVersion && transVersion.transformerVersion}
                            </span>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              width: "fit-content",
                            }}
                          >
                            <span
                              style={{ fontSize: 12, display: "inline-block" }}
                            >
                              -&nbsp;
                              <span
                                style={{
                                  padding: "1px 5px 1px 5px",
                                  marginRight: 10,
                                  fontWeight: "bold",
                                  marginRight: 10,
                                  borderRadius: 2,
                                  background: envinfo && envinfo.color,
                                  color: getColorByBgColor(
                                    envinfo && envinfo.color
                                  ),
                                }}
                              >
                                {envinfo && envinfo.name}
                              </span>
                            </span>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              background: "#404040",
                              left: 0,
                              bottom: 0,
                              width: 22,
                              height: 30,
                            }}
                          >
                            <div
                              style={{
                                marginTop: 6,
                                marginRight: -2,
                                textAlign: "center",
                              }}
                            >
                              <img
                                className="subscriber-icon"
                                role="presentation"
                                src={images.description}
                                style={styles.icon}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                })}
              </div>
            </div>
          );
        });
      }

      //objects

      webhookSubscriptions &&
        webhookSubscriptions.map((o) => {
          if (!subscribedObjects.includes(o.data.objectTypeFilter)) {
            subscribedObjects.push(o.data.objectTypeFilter);
          }
        });

      subscribedObjects.sort((a, b) => {
        if (a < b) return -1;
        else if (a > b) return 1;
        return 0;
      });

      subscribedObjects &&
        subscribedObjects.map((o, i) => {
          categories.push(
            <div className="object-category-envelope" key={i}>
              <div className="objects-title">
                Subscribed to Object:{" "}
                <b
                  style={{ cursor: "pointer" }}
                  onClick={() => this.sendToCreate(o)}
                >
                  {this.getObjName(o)}
                </b>
              </div>
              <div style={{ display: "flex" }}>
                {o}
                <img
                  src={images.content_copy_black}
                  className="image-copy"
                  onClick={() => {
                    clipboard.copy({
                      "text/plain": o,
                    });
                    this.setState({ fieldCopied: o });
                    setTimeout(() => {
                      this.setState({ fieldCopied: null });
                    }, 30000);
                  }}
                />
                {this.state.fieldCopied == o && <div>copied!</div>}
              </div>
              <div className="subscribers">
                {webhookSubscriptions.map((s, ii) => {
                  const transinfo =
                    project &&
                    project.transformers &&
                    project.transformers.find((f) => f.BaseId === s.baseId);
                  const envinfo =
                    project &&
                    project.environments &&
                    project.environments.find((f) => f.id === s.environmentId);
                  const transVersion =
                    project &&
                    project.allDeployments &&
                    project.allDeployments.find(
                      (f) =>
                        f.baseId === s.baseId &&
                        f.environmentId === s.environmentId
                    );

                  if (s.data.objectTypeFilter === o) {
                    if (!s.baseId) {
                      // if it's a webhook url subscription
                      return (
                        <div
                          key={ii}
                          className={
                            isDeleting &&
                            deleteIsObject &&
                            isDeleting.data &&
                            isDeleting.data.subscriptionGuid ===
                              s.data.subscriptionGuid
                              ? "subscription-item sub-deleting"
                              : "subscription-item"
                          }
                        >
                          <img
                            src={images.ic_clear_black_48dp}
                            onClick={() => this.warnBeforeDelete(null, s, true)}
                            className="delete-sub-icon"
                            alt="clear"
                          />

                          <div
                            style={{
                              marginTop: 0,
                              marginLeft: 0,
                              marginRight: 20,
                              width: "fit-content",
                            }}
                          >
                            <span style={styles.subscriberUrl}>
                              {s.data.deliveryUrl}
                            </span>
                            &nbsp;&nbsp;
                            <span style={{ fontSize: 12 }}>
                              (External Webhook)
                            </span>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              background: "rgb(68,120,178)",
                              left: 0,
                              bottom: 0,
                              width: 22,
                              height: 30,
                            }}
                          >
                            <div
                              style={{
                                marginTop: 6,
                                marginRight: -2,
                                textAlign: "center",
                              }}
                            >
                              <img
                                className="subscriber-icon"
                                role="presentation"
                                src={images.webhookurl}
                                style={styles.iconInvert}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                    // if it's a recipe subscription
                    else {
                      return (
                        <div
                          key={ii}
                          className={
                            isDeleting &&
                            deleteIsObject &&
                            isDeleting.data &&
                            isDeleting.data.subscriptionGuid ===
                              s.data.subscriptionGuid
                              ? "subscription-item sub-deleting"
                              : "subscription-item"
                          }
                        >
                          <img
                            src={images.ic_clear_black_48dp}
                            onClick={() => this.warnBeforeDelete(null, s, true)}
                            className="delete-sub-icon"
                            alt="clear"
                          />

                          <div
                            style={{ display: "inline-block", marginRight: 4 }}
                            className="recipe-name"
                            onClick={() =>
                              this.props.history.push(
                                `/a/${appId}/tf/${s.baseId}`
                              )
                            }
                          >
                            <span style={styles.subscriber}>
                              {transinfo && transinfo.Name}
                            </span>
                            &nbsp;
                            <span
                              style={{ display: "inline-block", fontSize: 10 }}
                            >
                              ver.{" "}
                              {transVersion && transVersion.transformerVersion}
                            </span>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              width: "fit-content",
                            }}
                          >
                            <span
                              style={{ fontSize: 12, display: "inline-block" }}
                            >
                              -&nbsp;
                              <span
                                style={{
                                  padding: "1px 5px 1px 5px",
                                  marginRight: 10,
                                  fontWeight: "bold",
                                  borderRadius: 2,
                                  background: envinfo && envinfo.color,
                                  color: getColorByBgColor(
                                    envinfo && envinfo.color
                                  ),
                                }}
                              >
                                {envinfo && envinfo.name}
                              </span>
                            </span>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              background: "#404040",
                              left: 0,
                              bottom: 0,
                              width: 22,
                              height: 30,
                            }}
                          >
                            <div
                              style={{
                                marginTop: 6,
                                marginRight: -2,
                                textAlign: "center",
                              }}
                            >
                              <img
                                className="subscriber-icon"
                                role="presentation"
                                src={images.description}
                                style={styles.icon}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                })}
              </div>
            </div>
          );
        });
    }
    //if environment viewMode
    else if (viewMode === 1) {
      //triggers

      allTriggers &&
        allTriggers.map((o) => {
          if (
            !subscribedTriggerEnvironments.includes(o.EnvironmentId) &&
            o.FilePattern !== ""
          ) {
            if (!o.BaseId && !subscribedTriggerEnvironments.includes(0)) {
              subscribedTriggerEnvironments.push(0);
            } else if (o.BaseId) {
              subscribedTriggerEnvironments.push(o.EnvironmentId);
            }
          }
        });

      subscribedTriggerEnvironments.sort((a, b) => {
        if (a < b) return 1;
        else if (a > b) return -1;
        return 0;
      });

      subscribedTriggerEnvironments &&
        subscribedTriggerEnvironments.map((o, i) => {
          const envinfo =
            project &&
            project.environments &&
            project.environments.find((f) => f.id === o);

          triggerCategories.push(
            <div
              className="object-category-envelope"
              key={i}
              style={{
                background: o === 0 ? "#ccc" : envinfo && envinfo.color,
                color:
                  o === 0
                    ? "#222"
                    : getColorByBgColor(envinfo && envinfo.color),
              }}
            >
              {o === 0 ? (
                <span className="object-env-title">Webhooks</span>
              ) : (
                <span className="object-env-title">
                  {envinfo && envinfo.name}
                </span>
              )}

              <div className="subscribers">
                {allTriggers.map((s, ii) => {
                  const transinfo =
                    project &&
                    project.transformers &&
                    project.transformers.find((f) => f.BaseId === s.BaseId);

                  //if its a webhookURL
                  if (o === 0) {
                    if (!s.BaseId) {
                      return (
                        <div
                          key={ii}
                          className={
                            isDeleting &&
                            deleteIsObject &&
                            isDeleting.data &&
                            isDeleting.data.subscriptionGuid ===
                              s.data.subscriptionGuid
                              ? "subscription-item sub-deleting"
                              : "subscription-item"
                          }
                        >
                          <img
                            src={images.ic_clear_black_48dp}
                            onClick={() =>
                              this.warnBeforeDelete(null, s, false)
                            }
                            className="delete-sub-icon"
                            alt="clear"
                          />

                          <div
                            style={{ display: "inline-block", marginRight: 6 }}
                          >
                            <span style={styles.subscriberUrl}>
                              {s.data.deliveryUrl}
                            </span>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              width: "fit-content",
                            }}
                          >
                            (
                            <span
                              style={{ display: "inline-block", fontSize: 12 }}
                            >
                              {s.FilePattern}
                            </span>
                            )
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              background: "rgb(68,120,178)",
                              left: 0,
                              bottom: 0,
                              width: 22,
                              height: 30,
                            }}
                          >
                            <div
                              style={{
                                marginTop: 6,
                                marginRight: -2,
                                textAlign: "center",
                              }}
                            >
                              <img
                                className="subscriber-icon"
                                role="presentation"
                                src={images.webhookurl}
                                style={styles.iconInvert}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  } else {
                    if (s.EnvironmentId === o) {
                      return (
                        <div
                          key={ii}
                          className={
                            isDeleting &&
                            !deleteIsObject &&
                            isDeleting.BaseId === s.BaseId
                              ? "subscription-item sub-deleting"
                              : "subscription-item"
                          }
                        >
                          <img
                            src={images.ic_clear_black_48dp}
                            onClick={() =>
                              this.warnBeforeDelete(null, s, false)
                            }
                            className="delete-sub-icon"
                            alt="clear"
                          />

                          <div
                            style={{ display: "inline-block", marginRight: 6 }}
                          >
                            <span style={styles.subscriber}>
                              {transinfo && transinfo.Name}
                            </span>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              width: "fit-content",
                            }}
                          >
                            <span
                              style={{ display: "inline-block", fontSize: 12 }}
                            >
                              ({s.FilePattern})
                            </span>
                          </div>

                          {s.BaseId ? (
                            <div
                              style={{
                                position: "absolute",
                                background: "#404040",
                                left: 0,
                                bottom: 0,
                                width: 22,
                                height: 30,
                              }}
                            >
                              <div
                                style={{
                                  marginTop: 6,
                                  marginRight: -2,
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  className="subscriber-icon"
                                  role="presentation"
                                  src={images.description}
                                  style={styles.icon}
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      );
                    }
                  }
                })}
              </div>
            </div>
          );
        });

      //objects

      webhookSubscriptions &&
        webhookSubscriptions.map((o) => {
          if (!subscribedEnvironments.includes(o.environmentId)) {
            if (!o.baseId && !subscribedEnvironments.includes(0)) {
              subscribedEnvironments.push(0);
            } else if (o.baseId) {
              subscribedEnvironments.push(o.environmentId);
            }
          }
        });

      subscribedEnvironments.sort((a, b) => {
        if (a < b) return 1;
        else if (a > b) return -1;
        return 0;
      });

      subscribedEnvironments &&
        subscribedEnvironments.map((o, i) => {
          const envinfo =
            project &&
            project.environments &&
            project.environments.find((f) => f.id === o);

          categories.push(
            <div
              className="object-category-envelope"
              key={i}
              style={{ background: o === 0 ? "#fff" : null, color: "#000" }}
            >
              {o === 0 ? (
                <span className="object-env-title">Webhooks</span>
              ) : (
                <div style={{ fontSize: 17 }}>
                  <span>Subscribed on</span>
                  <span
                    className="object-env-title"
                    style={{
                      background: envinfo && envinfo.color,
                      fontWeight: "bold",
                      borderRadius: 2,
                      color: getColorByBgColor(envinfo && envinfo.color),
                      padding: 5,
                    }}
                  >
                    {envinfo && envinfo.name}
                  </span>
                </div>
              )}

              <div className="subscribers">
                {webhookSubscriptions.map((s, ii) => {
                  const transinfo =
                    project &&
                    project.transformers &&
                    project.transformers.find((f) => f.BaseId === s.baseId);

                  //if its a webhookURL
                  if (o === 0) {
                    if (!s.baseId) {
                      return (
                        <div
                          key={ii}
                          className={
                            isDeleting &&
                            deleteIsObject &&
                            isDeleting.data &&
                            isDeleting.data.subscriptionGuid ===
                              s.data.subscriptionGuid
                              ? "subscription-item sub-deleting"
                              : "subscription-item"
                          }
                        >
                          <img
                            src={images.ic_clear_black_48dp}
                            onClick={() => this.warnBeforeDelete(null, s, true)}
                            className="delete-sub-icon"
                            alt="clear"
                          />

                          <div
                            style={{ display: "inline-block", marginRight: 6 }}
                          >
                            <span style={styles.subscriberUrl}>
                              {s.data.deliveryUrl}
                            </span>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              width: "fit-content",
                            }}
                          >
                            (
                            <span
                              style={{ display: "inline-block", fontSize: 12 }}
                            >
                              {s.data.objectTypeFilter}
                            </span>
                            )
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              background: "rgb(68,120,178)",
                              left: 0,
                              bottom: 0,
                              width: 22,
                              height: 30,
                            }}
                          >
                            <div
                              style={{
                                marginTop: 6,
                                marginRight: -2,
                                textAlign: "center",
                              }}
                            >
                              <img
                                className="subscriber-icon"
                                role="presentation"
                                src={images.webhookurl}
                                style={styles.iconInvert}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  } else {
                    if (s.environmentId === o) {
                      return (
                        <div
                          key={ii}
                          className={
                            isDeleting &&
                            deleteIsObject &&
                            isDeleting.data &&
                            isDeleting.data.subscriptionGuid ===
                              s.data.subscriptionGuid
                              ? "subscription-item sub-deleting"
                              : "subscription-item"
                          }
                        >
                          <img
                            src={images.ic_clear_black_48dp}
                            onClick={() => this.warnBeforeDelete(null, s, true)}
                            className="delete-sub-icon"
                            alt="clear"
                          />

                          <div
                            style={{ display: "inline-block", marginRight: 6 }}
                          >
                            <span style={styles.subscriber}>
                              {transinfo && transinfo.Name}
                            </span>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              width: "fit-content",
                            }}
                          >
                            <span
                              style={{ display: "inline-block", fontSize: 12 }}
                            >
                              ({s.data.objectTypeFilter})
                            </span>
                          </div>

                          {s.baseId ? (
                            <div
                              style={{
                                position: "absolute",
                                background: "#404040",
                                left: 0,
                                bottom: 0,
                                width: 22,
                                height: 30,
                              }}
                            >
                              <div
                                style={{
                                  marginTop: 6,
                                  marginRight: -2,
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  className="subscriber-icon"
                                  role="presentation"
                                  src={images.description}
                                  style={styles.icon}
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      );
                    }
                  }
                })}
              </div>
            </div>
          );
        });
    }

    if (!webhookSubscriptions) {
      return (
        <div className="overview-tab-content">
          <div
            style={{
              margin: "10px 0 15px 0",
              fontSize: "20px",
              display: "inline-block",
            }}
          >
            Subscriptions
          </div>
          <br />
          <img alt="spinner" src={images.ajax_loader} />
        </div>
      );
    }

    const thereAreSubs = categories.length > 0 || triggerCategories.length > 0;
    const { appId } = this.props.match.params;

    return (
      <div className="overview-tab-content">
        <div
          style={{
            margin: "10px 0px 10px 0px",
            fontSize: "20px",
            display: "inline-block",
          }}
        >
          Subscriptions
        </div>

        {thereAreSubs && (
          <div className="main-create-btn-env">
            <Button
              bsSize="small"
              bsStyle="primary"
              style={{ marginRight: 10 }}
              onClick={this.newSubscription}
            >
              New Subscription
            </Button>
            {/*}
           <div style={{float: 'right', marginRight:16}}>
            <Button bsSize="small" onClick={this.changeViewMode} style={{background: '#ddd', border: '1px solid #666'}}>
              <img src={images.ic_sort_grey600_48dp}
                style={SettingsStyle.iconStyles} alt="num" />
                {viewMode === 0 && 'Subscriptions'}
                {viewMode === 1 && 'Environments'}
            </Button>
            </div>
          */}
          </div>
        )}

        <div style={{ position: "relative", marginTop: -1 }}>
          {!thereAreSubs && (
            <div className="none-env" style={{ marginTop: 10 }}>
              <span style={{ fontSize: 16 }}>No Subscriptions.</span>
              <br />
              <Button
                bsSize="small"
                bsStyle="primary"
                style={{ marginTop: 6 }}
                onClick={this.newSubscription}
              >
                New Subscription
              </Button>
            </div>
          )}

          {categories.length > 0 && (
            <div
              style={{
                marginRight: 60,
                verticalAlign: "text-top",
                display: "inline-block",
              }}
            >
              <div>{categories}</div>
            </div>
          )}
          {triggerCategories.length > 0 && (
            <div
              style={{
                marginBottom: 20,
                marginRight: 20,
                verticalAlign: "text-top",
                display: "block",
              }}
            >
              <div>{triggerCategories}</div>
            </div>
          )}
        </div>

        <ConfirmDeleteModal
          modalState={this.state.modal}
          message="Delete this subscription."
          triggerModal={this.triggerModal}
          deleteTarget={this.deleteSubscription}
        />
      </div>
    );
  }
}

const styles = {
  icon: {
    filter: "invert(0%)",
    height: 18,
    marginTop: -3,
    marginRight: 3,
    display: "inline-block",
  },
  iconInvert: {
    filter: "invert(100%)",
    height: 18,
    marginTop: -3,
    marginRight: 3,
    display: "inline-block",
  },
  subscriber: {
    marginLeft: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  subscriberUrl: {
    marginLeft: 5,
    textDecoration: "underline",
    wordBreak: "break-all",
  },
};
