import * as types from './types';

export function callGetDatafeeds(tenantId, appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.get(`${tenantId}/dataprofilegrants/${appId}`, {
      endpoint: API_URL,
    })
    .then((data) => {
      dispatch({
        type: types.SET_FEEDLIST,
        data,
      });
      const { datafeed } = getState();
      const tenants = {}
      const apps = {}
      datafeed.feedList.map((feed) => {
        if (!(feed.TenantId in tenants)) {
          dispatch(callGetOtherTenantInfo(feed.TenantId));
          tenants[feed.TenantId] = true;
        }
        if (!(feed.TenantIdFrom in tenants)) {
          dispatch(callGetOtherTenantInfo(feed.TenantIdFrom));
          tenants[feed.TenantIdFrom] = true;
        }
        if (!(feed.AppId in apps)) {
          dispatch(callGetOtherAppInfo(feed.AppId));
          apps[feed.AppId] = true;
        }
        if (!(feed.AppIdFrom in apps)) {
          dispatch(callGetOtherAppInfo(feed.AppIdFrom));
          apps[feed.AppIdFrom] = true;
        }
      });
    });
  };
}

export function callGetDatafeedDetails(feedId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.get(`dataprofilegrants/id/${feedId}`, {
      endpoint: API_URL,
    })
    .then((data) => {
      console.log(data);
    });
  };
}

export function callGetOtherTenantInfo(tenantId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.get(`tenantinfo/${tenantId}`, {
      endpoint: API_URL,
    })
    .then((data) => {
      const { datafeed } = getState();
      const { otherTenants } = datafeed;
      dispatch({
        type: types.SET_OTHER_TENANT,
        data,
        otherTenants,
        tenantId,
      });
    });
  };
}

export function callGetOtherAppInfo(appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.get(`appinfo/${appId}`, {
      endpoint: API_URL,
    })
    .then((data) => {
      const { datafeed } = getState();
      const { otherApps } = datafeed;
      dispatch({
        type: types.SET_OTHER_APP,
        data,
        otherApps,
        appId,
      });
    });
  };
}

export function callCreateDatafeed(tenantId, data, appId) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.post(`${tenantId}/dataprofilegrants/create`, {
      endpoint: API_URL,
      data,
    })
    .then(() => {
      dispatch(callGetDatafeeds(tenantId, appId));
    });
  };
}

export function callGetInvitedFeed(feedKey) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.get(`dataprofilegrants/key/${feedKey}`, {
      endpoint: API_URL,
    })
    .then((data) => {
      dispatch({
        type: types.SET_INVITED_FEED,
        data,
      });
      dispatch(callGetOtherTenantInfo(data.TenantId));
    });
  };
}

export function callUpdateFeed(tenantId, appId, feedKey) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.post(`${tenantId}/dataprofilegrants/${appId}/update/${feedKey}`, {
      endpoint: API_URL,
    })
  };
}

export function callUpdateFeedState(appId, feedKey, state) {
  return (dispatch, getState, api) => {
    const { config } = getState();
    const { API_URL } = config;
    return api.post(`dataprofilegrants/${appId}/update/${feedKey}/${state}`, {
      endpoint: API_URL,
    })
    .then((res) => {
      const { datafeed } = getState();
      const { feedList } = datafeed;
      var data = [...feedList];
      feedList.map((feed, index) => {
        if (feed.Key == feedKey) {
          data[index] = res;
        }
      });
      dispatch({
        type: types.SET_FEEDLIST,
        data,
      });
    });
  };
}


export function clearDatafeeds() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_DATAFEEDS,
    });
  };
}
