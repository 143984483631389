import React from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";

const { Header, Body, Footer, Title } = Modal;

const SaveOverwriteMod = ({
  show,
  hide,
  handleOverwrite,
  diff,
  redux,
  handlePullLatest,
}) => {
  let username;
  const user =
    redux &&
    redux.project &&
    redux.project.permissions &&
    diff &&
    diff.LastModifiedBy &&
    redux.project.permissions.find((u) => u.identityId === diff.LastModifiedBy);
  if (user && user.Identity && user.Identity.username) {
    username = user.Identity.username;
  }
  return (
    <div>
      <Modal show={show} onHide={hide} backdrop="static">
        <Header>
          <Title>Oops!</Title>
        </Header>
        <Body>
          <div style={{ display: "inline-block", marginLeft: 20 }}>
            <h4>It looks like someone else may have worked on this recipe.</h4>
            {diff && diff.LastModified && (
              <p>
                Last save: {moment(diff.LastModified).format("MMM Do, h:mm a")}
                <br />
                Last modified by: {username}
              </p>
            )}
          </div>
          &emsp;&emsp;&emsp;
        </Body>
        <Footer>
          <Button bsStyle="default" onClick={hide}>
            Cancel
          </Button>
          <Button
            value="newVersion"
            bsStyle="primary"
            onClick={handleOverwrite}
          >
            Overwrite Server
          </Button>
          <Button
            value="newVersion"
            bsStyle="primary"
            onClick={handlePullLatest}
          >
            Get Latest From Server
          </Button>
        </Footer>
      </Modal>
    </div>
  );
};

export default SaveOverwriteMod;
